// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'

import logger from 'utils/MiscUtils/main'
import type {AllProjectStatuses, InitialProjectStatuses} from 'components/projects/ProjectEditForm'
import type {Alert} from 'components/types'
import type {Values, SubmitValues, FormikProps, Mode} from '../ProjectFormModalView'
import ProjectFormModalView from '../ProjectFormModalView'
import type {
  ProjectFormModalFragmented_organization$key as Organization,
} from './__generated__/ProjectFormModalFragmented_organization.graphql'
import type {
  ProjectFormModalFragmented_circle$key as Circle,
} from './__generated__/ProjectFormModalFragmented_circle.graphql'
import type {
  ProjectFormModalFragmented_tension$key as Tension,
} from './__generated__/ProjectFormModalFragmented_tension.graphql'

type Props<SubmitResponse> = $ReadOnly<{
  disabled: boolean,
  initialValues: Values,
  submit: SubmitValues => Promise<SubmitResponse>,
  onSuccess: () => Promise<void>,
  modalTitle: string,
  mode: Mode,
  renderLeftFooterButton: FormikProps => Node,
  close: () => void,
  statuses: InitialProjectStatuses | AllProjectStatuses,
  organization: Organization,
  circle: Circle | null,
  tension: ?Tension | null,
}>

export type {FormikProps, SubmitValues, Values, Mode}

const organizationFragment = graphql`
  fragment ProjectFormModalFragmented_organization on Organization {
    viewer {
      ...ProjectFormModalView_viewer
    }
    
    ...ProjectFormModalView_organization
  }
`

const circleFragment = graphql`
  fragment ProjectFormModalFragmented_circle on Circle {
    ...ProjectFormModalView_circle
  }
`

const tensionFragment = graphql`
  fragment ProjectFormModalFragmented_tension on Tension {
    ...ProjectFormModalView_tension
  }
`

function ProjectFormModalFragmented<SubmitResponse: ?{+errors: $ReadOnlyArray<Alert>, ...}>({
  circle: circleKey,
  organization: organizationKey,
  tension: tensionKey,
  ...formProps
}: Props<SubmitResponse>): null | Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const circle = useFragment(circleFragment, circleKey)
  const tension = useFragment(tensionFragment, tensionKey)

  const {mode, initialValues} = formProps

  if (mode === 'RoleAndPerson' && !circle) {
    logger.error('circleDatabaseId must be provided in Role-Person mode')
    return null
  }

  if (mode === 'CircleAndRole' && !initialValues.personId) {
    logger.error('personDatabaseId must be provided in Circle-Role mode')
    return null
  }

  return (
    <ProjectFormModalView
      organization={organization}
      circle={circle}
      tension={tension}
      viewer={organization.viewer}
      {...formProps}
    />
  )
}

ProjectFormModalFragmented.defaultProps = {
  disabled: false,
  onSuccess: (): Promise<void> => Promise.resolve(),
  renderLeftFooterButton: (): null => null,
  tension: null,
  circle: null,
}

export default ProjectFormModalFragmented
