/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChecklistList_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileChecklistsView_person$ref: FragmentReference;
declare export opaque type ProfileChecklistsView_person$fragmentType: ProfileChecklistsView_person$ref;
export type ProfileChecklistsView_person = {|
  +id: string,
  +$fragmentRefs: ChecklistList_viewer$ref,
  +$refType: ProfileChecklistsView_person$ref,
|};
export type ProfileChecklistsView_person$data = ProfileChecklistsView_person;
export type ProfileChecklistsView_person$key = {
  +$data?: ProfileChecklistsView_person$data,
  +$fragmentRefs: ProfileChecklistsView_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileChecklistsView_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChecklistList_viewer"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cb828268b78fbc472dfd5b42a2f0be8f';

module.exports = node;
