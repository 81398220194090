// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'
import type {DeleteMetricInput as Input} from './__generated__/DeleteMetricMutation.graphql'

const mutation = graphql`
  mutation DeleteMetricMutation(
    $input: DeleteMetricInput!
  ) {
    deleteMetric(input: $input) {
      deletedMetricId
    }
  }
`

export default (input: MutationInput<Input>, organizationId: ?string): Promise<mixed> => {
  const variables = {input}

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedMetricId',
  }, organizationId && {
    type: 'RANGE_DELETE',
    parentID: organizationId,
    connectionKeys: [{
      key: 'GlobalMetrics_metrics',
      filters: {
        global: 'GLOBAL_ONLY',
      },
    }],
    pathToConnection: ['organization', 'metrics'],
    deletedIDFieldName: 'deletedMetricId',
  }].filter(Boolean)

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse: {
      deleteMetric: {
        deletedMetricId: input.metricId,
      },
    },
  })
}
