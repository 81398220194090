// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'
import {graphql, useFragment} from 'react-relay'

import CheckboxOutline from 'components/ui/icons/md/CheckboxOutline'
import ActionCompletionCheckbox from './ActionCompletionCheckbox'
import Description from './Description'
import MutationIcons from './MutationIcons'
import Spinner from './Spinner'
import type {
  Body_action$key as Action,
} from './__generated__/Body_action.graphql'
import styles from './index.scss'

type Props = {
  action: ?Action,
  readonly: boolean,
  finishEditing: () => void,
  isEditing: boolean,
  onDelete: () => void,
  onDescriptionChange: string => void,
  onTabPress: (string, 'BACK' | 'FORWARD') => void,
  startEditing: () => void,
}

const actionFragment = graphql`
  fragment Body_action on Action {
    id
    isUpdating
    completedAt
    description(format: HTML)
    rawDescription: description(format: PLAIN)
    
    ...ActionCompletionCheckbox_action
  }
`

function Body({
  action: actionKey,
  finishEditing,
  readonly,
  isEditing,
  onDelete,
  onDescriptionChange,
  onTabPress,
  startEditing,
}: Props): Element<"div"> {
  const action = useFragment(actionFragment, actionKey)

  const isNew = !action
  const isUpdating = action?.isUpdating
  const isSaved = !isNew
  const isEditable = !readonly && !isEditing && isSaved && !isUpdating
  const isCompleted = !!action?.completedAt

  const className = classNames('project__action', {
    'project__action--completed': isCompleted,
    'project__action--disabled': isUpdating || readonly,
    'project__action--in-edit': isEditing,
    'project__action--new': isNew,
  })

  const description = action?.description || I18n.t('actions.react.new_action')
  const rawDescription = action?.rawDescription || I18n.t('actions.react.new_action')

  return (
    <div className={className}>
      <div className="project__action-button">
        {action && (
          <ActionCompletionCheckbox
            action={action}
          />
        )}
        {isNew && (
          <CheckboxOutline className={styles.newItemCheckedIcon} />
        )}
        <Description
          actionId={action?.id}
          value={description}
          rawValue={rawDescription}
          editing={isEditing}
          isPlaceholder={isNew}
          startEditing={startEditing}
          finishEditing={finishEditing}
          onChange={onDescriptionChange}
          onTabPress={onTabPress}
        />
        {isUpdating && (
          <Spinner />
        )}
      </div>
      <div>
        {isEditable && (
          <MutationIcons onEdit={startEditing} onDelete={onDelete} />
        )}
      </div>
    </div>
  )
}

export default Body
