// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, QueryRenderer} from 'react-relay'
import environment from 'environment'
import {withErrorCheck} from 'decorators'
import Preload from 'components/ui/Preload'
import type {Variables, Response} from './types'
import TacticalMeetingMetricsView from './TacticalMeetingMetricsView'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  circleDatabaseId: string,
}>

const TacticalMeetingMetricsQuery = graphql`
  query TacticalMeetingMetricsQuery($circleDatabaseId: String!, $orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      circle(databaseId: $circleDatabaseId) {
        ...TacticalMeetingMetricsView_circle
      }
    }
  }
`

function ViewRenderer({organization: org}: Response) {
  return (org && org.circle)
    ? <TacticalMeetingMetricsView circle={org.circle} />
    : <Preload />
}

const renderQuery = withErrorCheck(ViewRenderer)

function TacticalMeetingMetrics({orgDatabaseId, circleDatabaseId}: Props): Node {
  const variables: Variables = {orgDatabaseId, circleDatabaseId}

  return (
    <QueryRenderer
      environment={environment}
      query={TacticalMeetingMetricsQuery}
      variables={variables}
      render={renderQuery}
    />
  )
}

export default TacticalMeetingMetrics
