// @flow
import type {Node} from 'react'
import React, {useState, useCallback} from 'react'
import environment from 'environment'
import {graphql, QueryRenderer} from 'react-relay'
import {withErrorCheck} from 'decorators'
import OrganizationHistoryView from './OrganizationHistoryView'
import type {
  OrganizationHistoryQueryVariables as Variables,
  OrganizationHistoryQueryResponse as Response,
} from './__generated__/OrganizationHistoryQuery.graphql'

const organizationHistoryQuery = graphql`
  query OrganizationHistoryQuery(
    $orgDatabaseId: String!,
    $count: Int!,
    $cursor: String,
  ){
    organization(databaseId: $orgDatabaseId) {
      ...OrganizationHistoryView_organization @arguments(count: $count, cursor: $cursor)
    }
  }
`

const PAGE_SIZE = 25

type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

function OrganizationHistory({orgDatabaseId}: Props): Node {
  const [cursor, setCursor] = useState<string | null>(null)
  const variables: Variables = {orgDatabaseId, cursor, count: PAGE_SIZE}

  const renderView = useCallback((response: ?Response) => {
    const organization = response?.organization

    if (!organization)
      return null

    return (
      <OrganizationHistoryView
        organization={organization}
        updateCursor={setCursor}
        pageSize={PAGE_SIZE}
        currentCursor={cursor}
      />
    )
  }, [cursor])

  return (
    <QueryRenderer
      environment={environment}
      query={organizationHistoryQuery}
      variables={variables}
      render={withErrorCheck(renderView)}
    />
  )
}

export default OrganizationHistory
