// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import AddChecklistItemMutation, {type Response} from 'mutations/AddChecklistItemMutation'
import ChecklistFormModal from 'components/checklistItems/ChecklistFormModal'
import {allCircleMembersId} from 'constants/fakeId'

import type {
  CreateChecklistItemModalQuery as Query,
} from './__generated__/CreateChecklistItemModalQuery.graphql'

type Props = $ReadOnly<{
  orgId: string,
  circleDatabaseId: string,
  close: () => void,
  onSubmit: Response => void,
}>

const query = graphql`
  query CreateChecklistItemModalQuery($orgId: ID!, $circleDatabaseId: String!) {
    organization: node(id: $orgId) {
      ... on Organization {
        circle(databaseId: $circleDatabaseId) {
          id
          ...ChecklistFormModal_circle
        }
      }
    }
  }
`

const initialValues = {
  description: '',
  roleId: allCircleMembersId,
  frequency: 'Weekly',
  customFrequency: null,
  link: '',
  privateToCircle: false,
}

function CreateChecklistItemModal({
  orgId,
  circleDatabaseId,
  close,
  onSubmit,
}: Props): null | Node {
  const {organization} = useLazyLoadQuery<Query>(query, {
    orgId,
    circleDatabaseId,
  })
  const circle = organization?.circle

  if (!circle)
    return null

  const submit = async (values, circleId) => {
    // for some reason, Flow doesn't like passing values as is,
    //  so `{...values}` is kinda workaround to make Flow happy
    const response = await AddChecklistItemMutation({...values}, circleId)

    onSubmit(response)

    return response
  }

  return (
    <ChecklistFormModal
      includeEachCircleMember
      initialValues={initialValues}
      circle={circle}
      close={close}
      submit={submit}
      modalTitle={I18n.t('metrics.add_checklist_item')}
    />
  )
}

CreateChecklistItemModal.defaultProps = {
  onSubmit: () => {},
}

export default CreateChecklistItemModal
