// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'

export enum Severity { Warning = 'warning', Info = 'info', Danger = 'danger' }

type Props = {
  centered: boolean,
  message: string,
  severity: ?Severity,
}

function Alert({centered, message, severity}: Props): Element<"div"> {
  const alertClassName = classNames('ui-alert', {
    'ui-alert--warning': severity === Severity.Warning,
    'ui-alert--info': severity === Severity.Info,
    'ui-alert--danger': severity === Severity.Danger,
  })

  const messageClassName = classNames('ui-alert__message', {
    'ui-alert__message--centered': centered,
  })

  return (
    <div className={alertClassName} role="alert">
      <div className={messageClassName}>
        {message}
      </div>
    </div>
  )
}

Alert.defaultProps = {
  severity: null,
  centered: false,
}

export default Alert
