/**
 * @flow
 * @relayHash a0b9a4d408a326da4bb9cb915d96ae7a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Metric_metric$ref = any;
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type UpdateMetricInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  metricId: string,
  description: string,
  frequency?: ?Frequency,
  customFrequencyId?: ?string,
  roleId?: ?string,
  link?: ?any,
  privateToCircle?: ?boolean,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateMetricMutationVariables = {|
  input: UpdateMetricInput
|};
export type UpdateMetricMutationResponse = {|
  +updateMetric: ?{|
    +metric: ?{|
      +$fragmentRefs: Metric_metric$ref
    |}
  |}
|};
export type UpdateMetricMutation = {|
  variables: UpdateMetricMutationVariables,
  response: UpdateMetricMutationResponse,
|};
*/


/*
mutation UpdateMetricMutation(
  $input: UpdateMetricInput!
) {
  updateMetric(input: $input) {
    metric {
      ...Metric_metric
      id
    }
  }
}

fragment Metric_metric on Metric {
  id
  databaseId
  description
  frequency
  localizedFrequency
  link
  localizedRoleName
  isGlobal
  appliesToAllMembers
  viewerCanSeeEditIcon
  privateToCircle
  position
  viewerCanReorder
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  role {
    id
  }
  people(first: 30) {
    edges {
      node {
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMetricPayload",
        "kind": "LinkedField",
        "name": "updateMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Metric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Metric_metric"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMetricPayload",
        "kind": "LinkedField",
        "name": "updateMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Metric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "frequency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedFrequency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedRoleName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGlobal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appliesToAllMembers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanSeeEditIcon",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanReorder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onRestrictedPlan",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomFrequency",
                "kind": "LinkedField",
                "name": "customFrequency",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "universalUnits",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 30
                  }
                ],
                "concreteType": "PersonConnection",
                "kind": "LinkedField",
                "name": "people",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "people(first:30)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a0b9a4d408a326da4bb9cb915d96ae7a",
    "metadata": {},
    "name": "UpdateMetricMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54349d310afb657586344b39124bfce2';

module.exports = node;
