/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AmendmentForm_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateAmendmentBody_organization$ref: FragmentReference;
declare export opaque type CreateAmendmentBody_organization$fragmentType: CreateAmendmentBody_organization$ref;
export type CreateAmendmentBody_organization = {|
  +id: string,
  +$fragmentRefs: AmendmentForm_organization$ref,
  +$refType: CreateAmendmentBody_organization$ref,
|};
export type CreateAmendmentBody_organization$data = CreateAmendmentBody_organization;
export type CreateAmendmentBody_organization$key = {
  +$data?: CreateAmendmentBody_organization$data,
  +$fragmentRefs: CreateAmendmentBody_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAmendmentBody_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AmendmentForm_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cbf0b182c8895023d154a1ed9e6cd465';

module.exports = node;
