// @flow
import React, {type Node} from 'react'
import jsonApiFetch from 'utils/jsonApiFetch'
import {useAiChatContext} from 'contexts/AiChatContext'
import className from 'classnames'
import {type MessageType} from '../MessageType'
import styles from './index.scss'

type Props = {
  message: MessageType,
  messagesPath: string
}

function ThumbsUpDown({message, messagesPath}: Props): Node {
  const messagePath = `${messagesPath}/${message.id}?ai_chat_id=${message.ai_chat_id}`
  const {setMessages} = useAiChatContext()

  const sendRating = async (value) => {
    const sendValue = message.user_rating === value ? 0 : value
    const data = await jsonApiFetch('PATCH', messagePath, {
      id: message.id,
      ai_chat_id: message.ai_chat_id,
      message: {user_rating: sendValue},
    })
    setMessages(data.messages)
  }
  const thumbClass = (rating) => className(styles.thumb, {[styles.selected]: message.user_rating === rating})
  const thumbsUpClasses = thumbClass(1)
  const thumbsDownClasses = thumbClass(-1)

  return (
    <div className={styles.container}>
      <button
        onClick={() => sendRating(1)}
        type="button"
        className={thumbsUpClasses}
      >
        <i className="fa fa-thumbs-up" />
      </button>
      <button
        onClick={() => sendRating(-1)}
        type="button"
        className={thumbsDownClasses}
      >
        <i className="fa fa-thumbs-down" />
      </button>
      <span className={styles.thumbText}>{I18n.t('ai.was_this_helpful')}</span>
    </div>
  )
}

export default ThumbsUpDown
