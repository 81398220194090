/**
 * @flow
 * @relayHash 56782bc8747c71de8e909168c6a7757a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConfigureJiraSectionInner_organization$ref = any;
export type ConfigureJiraSectionQueryVariables = {|
  orgDatabaseId: string
|};
export type ConfigureJiraSectionQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: ConfigureJiraSectionInner_organization$ref
  |}
|};
export type ConfigureJiraSectionQuery = {|
  variables: ConfigureJiraSectionQueryVariables,
  response: ConfigureJiraSectionQueryResponse,
|};
*/


/*
query ConfigureJiraSectionQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...ConfigureJiraSectionInner_organization
    id
  }
}

fragment ConfigureJiraSectionInner_organization on Organization {
  isJiraEnabled
  onRestrictedPlan
  ...ToggleJiraButton_organization
}

fragment ToggleJiraButton_organization on Organization {
  id
  isJiraEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureJiraSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigureJiraSectionInner_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfigureJiraSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isJiraEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "56782bc8747c71de8e909168c6a7757a",
    "metadata": {},
    "name": "ConfigureJiraSectionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09abc00860656437815bd70870624574';

module.exports = node;
