/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectEditForm_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalView_organization$ref: FragmentReference;
declare export opaque type ProjectFormModalView_organization$fragmentType: ProjectFormModalView_organization$ref;
export type ProjectFormModalView_organization = {|
  +$fragmentRefs: ProjectEditForm_organization$ref,
  +$refType: ProjectFormModalView_organization$ref,
|};
export type ProjectFormModalView_organization$data = ProjectFormModalView_organization;
export type ProjectFormModalView_organization$key = {
  +$data?: ProjectFormModalView_organization$data,
  +$fragmentRefs: ProjectFormModalView_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalView_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectEditForm_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fa206337688c9b3a9973cdecab66d502';

module.exports = node;
