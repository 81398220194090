// @flow
import type {Element, Node} from 'react'
import React from 'react'

import AppLink from 'components/ui/AppLink'
import {header, linkToNote} from './index.scss'

type Props = {
  children: Node,
  samePageTarget: boolean,
  url: string,
}

const newTabParams = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

function Header({children, samePageTarget, url}: Props): Element<"div"> {
  const optionalParams = {
    ...(samePageTarget ? null : newTabParams),
  }

  return (
    <div className={header}>
      <AppLink
        className={linkToNote}
        href={url}
        {...optionalParams}
      >
        <h4>{children}</h4>
      </AppLink>
    </div>
  )
}

Header.defaultProps = {
  samePageTarget: false,
}

export default Header
