// @flow
import type {Element, Node} from 'react'
import React, {Children} from 'react'

import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
  rightSection?: Node[],
  leftSection?: Node[],
  'test-id': string | null
}>

function stopPropagation(event: SyntheticMouseEvent<HTMLButtonElement>) {
  event.stopPropagation()
}

function ListRow({
  children,
  leftSection,
  rightSection,
  'test-id': testId,
}: Props): Element<"div"> {
  return (
    <div className={styles.container} test-id={testId}>
      {leftSection && (
        <div className={styles.leftSection}>
          <div className={styles.rightSection}>
            {Children.map(leftSection, (child) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div className={styles.leftSectionItem} {...accessibleOnClickProps(stopPropagation)}>
                {child}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
      {rightSection && (
        <div className={styles.rightSection}>
          {Children.map(rightSection, (child) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div {...accessibleOnClickProps(stopPropagation)} className={styles.rightSectionItem}>
              {child}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

ListRow.defaultProps = {
  'test-id': null,
}

export default ListRow
