// @flow
import {graphql} from 'react-relay'

import requestSubscription from './requestSubscription'
import type {
  CombinedMeetingEventSubscriptionVariables as Variables,
  CombinedMeetingEventSubscriptionResponse as Response,
} from './__generated__/CombinedMeetingEventSubscription.graphql'

const subscription = graphql`
  subscription CombinedMeetingEventSubscription($meetingDatabaseId: String!) {
    combinedMeetingEvent(meetingDatabaseId: $meetingDatabaseId) {
      ... on AgendaItemChanged {
        person {
          id
        }
  
        type: __typename
      }
      
      ... on AgendaItemAdded {
        type: __typename
      }

      ... on CurrentAgendaItemChanged {
        type: __typename
        
        meeting {
          currentAgendaItem {
            id
            databaseId
          }
        }
      }

      ... on AgendaItemUpdated {
        type: __typename
      }

      ... on AgendaItemCompleted {
        agendaItem {
          isCompleted
        }
      }

      ...on InviteesUpdatedEvent {
        type: __typename
      }
    }
  }
`

function CombinedMeetingEventSubscription(variables: Variables, onNext: ?(Response) => void): any {
  return requestSubscription<Variables, Response>(subscription, variables, onNext)
}

export default CombinedMeetingEventSubscription
