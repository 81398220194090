// @flow
import type {Element, Node} from 'react'
import React, {Children} from 'react'
import classNames from 'classnames'

import styles from './index.scss'

type Props = $ReadOnly<{
  middleSection: ?() => Node[] | null,
  rightButton: ?() => Node | null,
  additionalSection: ?() => Node,
  children: Node,
  className: string,
  spacing: 'none' | 'xSmall' | 'small' | 'medium' | 'large' | 'huge',
  headingLevel: 1 | 2 | 3 | 4 | 5 | 6,
  underlined: boolean,
}>

function Heading({
  middleSection,
  rightButton,
  additionalSection,
  children,
  className,
  spacing,
  headingLevel,
  underlined,
}: Props): Element<"div"> {
  const headerClassName = classNames(styles.header, className, {
    [styles.xSmallSpacing]: spacing === 'xSmall',
    [styles.smallSpacing]: spacing === 'small',
    [styles.mediumSpacing]: spacing === 'medium',
    [styles.largeSpacing]: spacing === 'large',
    [styles.hugeSpacing]: spacing === 'huge',
    [styles.underlined]: underlined,
  })

  const HeadingTag = `h${headingLevel}`

  return (
    <div className={headerClassName}>
      {children && (
        <HeadingTag className={styles.mainSection}>
          {children}
          {additionalSection && (
            <div className={styles.additionalSection}>
              {additionalSection()}
            </div>
          )}
        </HeadingTag>
      )}
      <div className={styles.rightSection}>
        {middleSection && (
          <div className={styles.middleSection}>
            {Children.map(middleSection(), (child) => (
              child && (
                <div className={styles.middleSectionItem}>
                  {child}
                </div>
              )
            ))}
          </div>
        )}
        {rightButton && (
          <div className={styles.rightButton}>
            {rightButton()}
          </div>
        )}
      </div>
    </div>
  )
}

Heading.defaultProps = {
  additionalSection: null,
  middleSection: null,
  rightButton: null,
  children: null,
  className: '',
  spacing: 'none',
  headingLevel: 1,
  underlined: false,
}

export default Heading
