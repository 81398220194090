// @flow
import type {Element, Node} from 'react'
import React from 'react'

import useUniqueId from 'utils/hooks/useUniqueId'
import DefinitionTooltip from 'components/ui/DefinitionTooltip'
import PremiumCallout, {type CalloutType} from 'components/ui/PremiumCallout'
import styles from './index.scss'

type Props = $ReadOnly<{
  input: Node,
  label: string,
  tooltip: string | null,
  premiumCalloutType?: CalloutType,
  renderBelowInput?: () => Node,
}>

/* eslint jsx-a11y/label-has-for:0 */
/* eslint jsx-a11y/label-has-associated-control:0 */
function Label({input, label, tooltip, premiumCalloutType, renderBelowInput}: Props): Element<"label"> {
  const [id] = useUniqueId()

  const renderInputWithContainer = () => (
    <div className={styles.inputContainer}>
      {input}
      {renderBelowInput && renderBelowInput()}
    </div>
  )

  return (
    <label className={styles.container}>
      <div className={styles.label}>
        {label}
        {tooltip && !premiumCalloutType && (
          <DefinitionTooltip definition={tooltip} target={id} />
        )}
        {premiumCalloutType && (
          <PremiumCallout callout={premiumCalloutType} enabled darkIcon />
        )}
      </div>
      {renderBelowInput ? renderInputWithContainer() : input}
    </label>
  )
}

Label.defaultProps = {
  tooltip: null,
}

export default Label
