window.React = require('react');
window.PropTypes = require('prop-types');
window.ReactDOM = require('react-dom');
window.$ = require('jquery');
window.jQuery = require('jquery');

// pieces of JS used by rails views & react_component rails integration
window.LazyKeyMetrics = require('legacy/rest/KeyMetrics/index.lazy').default;
window.LazyMyAuthority = require('legacy/jquery/myAuthority.lazy').default;

window.OfficeHourButtonComponent = require('components/ui/OfficeHours/OfficeHourButtonComponent').default;
window.AddToCalendarButton = require('components/ui/OfficeHours/AddToCalendarButton').default;
window.PremiumCalloutComponent = require('components/ui/PremiumCallout').default;
window.RenderDateComponent = require('legacy/components/RenderDateComponent').default;

window.BannerComponent = require('legacy/components/BannerComponent').default;
window.ErrorWarningComponent = require('legacy/components/ErrorWarningComponent').default;
window.HabitBannerComponent = require('legacy/components/HabitBannerComponent').default;
window.VotingComponent = require('legacy/components/VotingComponent').default;
window.SearchProjectsComponent = require('legacy/components/SearchProjectsComponent').default;
window.ImportComponent = require('legacy/components/ImportComponent').default;
window.ExportComponent = require('legacy/components/ExportComponent').default;
window.HolaspiritImportComponent = require('legacy/components/HolaspiritImportComponent').default;
window.RenderDateRangeComponent = require('legacy/components/RenderDateRangeComponent').default;

window.setupTinyMCEConfiguration = require('legacy/rest/setupTinyMCEConfiguration').default;

window.SubscriptionListView = require('legacy/backbone/SubscriptionListView').default;
