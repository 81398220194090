// @flow
import type {ComponentType, Element, Node} from 'react'
import React, {Suspense, lazy} from 'react'
import {useFragment, graphql} from 'react-relay'

import type {
  WYSIWYGViewerWithFeatureFlag_organization$key as OrganizationKey,
} from './__generated__/WYSIWYGViewerWithFeatureFlag_organization.graphql'

import type {Props as WYSIWYGViewerProps} from '../WYSIWYGViewer'

const WYSIWYGViewer = lazy(() => import(
  /* webpackChunkName: "WYSIWYGViewer" */
  '../WYSIWYGViewer'
))

type Props<FallbackProps> = $ReadOnly<{
  ...WYSIWYGViewerProps,
  organization: OrganizationKey,
  fallbackProps: FallbackProps,
  fallbackComponent: ComponentType<FallbackProps> | string,
}>

const organizationFragment = graphql`
  fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
    isWysiwygEnabled
  }
`

function WYSIWYGViewerWithFeatureFlag<FallbackProps>({
  organization: organizationKey,
  fallbackComponent: FallbackComponent,
  fallbackProps,
  ...viewerProps
}: Props<FallbackProps>): Node | Element<"div"> {
  const organization = useFragment(organizationFragment, organizationKey)

  const fallback = (
    <div className="user-content">
      <FallbackComponent {...fallbackProps} />
    </div>
  )

  if (!organization || !organization.isWysiwygEnabled)
    return fallback

  return (
    <Suspense fallback={fallback}>
      <WYSIWYGViewer {...viewerProps} />
    </Suspense>
  )
}

export default WYSIWYGViewerWithFeatureFlag
