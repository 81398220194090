// @flow
import {createNotification, defaultOptions} from 'utils/localNotifications'
import type {Options} from 'utils/localNotifications'
import util from './main'

function commitMessage(type: 'DANGER' | 'INFO', message: string, options: Options = defaultOptions) {
  createNotification({message, type}, options)
}

export default {
  danger(message: string, options: Options = defaultOptions) {
    commitMessage('DANGER', message, options)
  },
  notice(message: string, options: Options = defaultOptions) {
    commitMessage('INFO', message, options)
  },
  fail(message: string, options: Options = defaultOptions) {
    commitMessage('DANGER', message, options)
    util.error(message)
  },
}
