/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TensionGuide_viewer$ref: FragmentReference;
declare export opaque type TensionGuide_viewer$fragmentType: TensionGuide_viewer$ref;
export type TensionGuide_viewer = {|
  +id: string,
  +hasCirclesWithGovernanceOn: boolean,
  +$refType: TensionGuide_viewer$ref,
|};
export type TensionGuide_viewer$data = TensionGuide_viewer;
export type TensionGuide_viewer$key = {
  +$data?: TensionGuide_viewer$data,
  +$fragmentRefs: TensionGuide_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TensionGuide_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCirclesWithGovernanceOn",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3bda11b20d2267af8ac6e8b6487da3c3';

module.exports = node;
