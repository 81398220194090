// @flow
import type {Node, Context} from 'react'
import React, {useMemo, createContext} from 'react'
import {isEmpty, move} from 'ramda'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

import NoteCard from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/NoteCard'
import type {DragHandleProps} from 'react-beautiful-dnd/src/view/draggable/draggable-types'
import NoItems from '../FrequencySubsection/NoItems'
import type {AbstractMetric} from '../types'

import style from './style.scss'

type Props<T> = {
  frequency: string,
  items: T[],
  renderItem: T => Node,
  onReorder: (string, number, Array<T>) => Promise<any>,
}

export const DragHandleContext = (createContext<DragHandleProps | null>(null): Context<DragHandleProps | null>)

function ReorderableFrequencySubsection<T: AbstractMetric>({frequency, items, renderItem, onReorder}: Props<T>): Node {
  const sortedGlobalItems = useMemo(() => (
    items.filter((item) => item.isGlobal).sort((a, b) => a.position - b.position)
  ), [items])
  const sortedNonGlobalItems = useMemo(() => (
    items.filter((item) => !item.isGlobal).sort((a, b) => a.position - b.position)
  ), [items])

  const onDragEnd = (result, list) => {
    if (!result.destination || (result.source.index === result.destination.index))
      return

    const optimisticList = move(result.source.index, result.destination.index, list)
      .map((item, index) => ({...item, position: index}))

    if (result.destination)
      onReorder(result.draggableId, result.destination.index, optimisticList)
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#d5dbe1' : 'transparent',
    padding: isDragging ? '0 1em' : 0,
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const renderDraggableItem = (item, index, array) => {
    const isOnRestrictedPlan = item.organization.onRestrictedPlan
    const isDragDisabled = array.length === 1 || isOnRestrictedPlan || !item.viewerCanReorder

    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <DragHandleContext.Provider value={provided.dragHandleProps || null}>
              {renderItem(item)}
            </DragHandleContext.Provider>
          </div>
        )}
      </Draggable>
    )
  }

  const renderDroppableFields = () => (
    <div>
      {!isEmpty(sortedNonGlobalItems) && (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, sortedNonGlobalItems)}>
          <Droppable droppableId={`droppable${frequency}items`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortedNonGlobalItems.map(renderDraggableItem)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!isEmpty(sortedGlobalItems) && (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, sortedGlobalItems)}>
          <Droppable droppableId={`droppable${frequency}Globalitems`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {!isEmpty(sortedNonGlobalItems) && (
                <div className={style.globalItemsSpacer}>{I18n.t('metrics.global')}</div>
                )}
                {sortedGlobalItems.map(renderDraggableItem)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  )

  return (
    <NoteCard test-id={`section-${frequency}`}>
      <h3>{frequency}</h3>
      {isEmpty(items) ? <NoItems /> : renderDroppableFields() }
    </NoteCard>
  )
}

export default ReorderableFrequencySubsection
