// @flow
import type {Element} from 'react'
import React from 'react'
import {isNil, isEmpty, either} from 'ramda'

import PrivateIcon from 'components/ui/PrivateIcon'
import EditItemButton from 'components/ui/Inputs/EditItemButton'

import type {AbstractMetric} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection/types'
import style from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection/MetricItem/style.scss'

type Circle = {
  id: string,
  localizedName: string,
}

type Props = $ReadOnly<{
  item: AbstractMetric,
  circle: Circle,
  onEdit: ?() => void,
  disabled: boolean,
  tooltip: string,
}>

const isBlank = either(isNil, isEmpty)

function PersonItem({item, circle, onEdit, disabled, tooltip}: Props): Element<"div"> {
  const id = `person-metric-item-${item.databaseId}-${circle.id}`

  const renderPlainDescription = () => (
    <div className="org-nav-role-metric-item__title">
      {item.description}
    </div>
  )

  const renderDescriptionWithLink = () => (
    <a
      className="org-nav-role-metric-item__title"
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {item.description}
    </a>
  )

  const renderDescription = () => (
    isBlank(item.link)
      ? renderPlainDescription()
      : renderDescriptionWithLink()
  )

  const renderCircle = () => (
    circle ? `${I18n.t('policy_change_list_presenter.in')} ${circle.localizedName}` : null
  )

  return (
    <div id={id} className="org-nav-role-metric-item">
      <div className="org-nav-role-metric-item__content">
        <div className="org-nav-role-metric-item__title">
          <span id={`orgnav-role-metric-item-name-${id}`}>
            <b>{item.localizedRoleName}</b>
          </span>
        </div>
        {renderDescription()}
        <div className={style.descriptionContainer}>
          {renderCircle()}
          {item.privateToCircle && (
            <PrivateIcon privacyText={I18n.t('metrics.private_to_circle')} />
          )}
        </div>
      </div>
      <div className={style.controlButtons}>
        {onEdit && (
          <EditItemButton
            onClick={onEdit}
            disabled={disabled}
            tooltip={{body: tooltip}}
            viewContext="tab"
          />
        )}
      </div>
    </div>
  )
}

PersonItem.defaultProps = {
  onEdit: null,
  disabled: false,
  tooltip: '',
}

export default PersonItem
