// @flow
import type {Node} from 'react'
import React from 'react'

import Pencil from 'components/ui/icons/fa/Pencil'
import IconButton from 'components/ui/Inputs/IconButton'
import BoltSolid from 'components/ui/icons/fa/BoltSolid'
import TensionBoxContainer, {type MarginBottom} from '../TensionBoxContainer'
import styles from './index.scss'

export type {MarginBottom}

type Props = $ReadOnly<{
  openForm?: ?() => void,
  marginBottom: MarginBottom,
}>

function NoneTensionInfo({openForm, marginBottom}: Props): Node {
  return (
    <TensionBoxContainer marginBottom={marginBottom}>
      <div className={styles.container}>
        <BoltSolid className={styles.boltContainer} />
        <span className={styles.none}>{I18n.t('shared.none')}</span>
        {openForm && (
          <div className={styles.editContainer}>
            <IconButton
              onClick={openForm}
              testId="show-edit-tension-box"
              title={I18n.t('shared.edit')}
            >
              <Pencil />
            </IconButton>
          </div>
        )}
      </div>
    </TensionBoxContainer>
  )
}

export default NoneTensionInfo
