// @flow
import type {Element} from 'react'
import React, {useCallback} from 'react'

import ShowProjectsAs from 'components/projects/ShowProjectsAs'
import GroupProjectsBy, {type GroupBy} from 'components/projects/GroupProjectsBy'
import Heading from 'components/ui/Heading'
import SortDropdown from 'components/pages/Projects/ProjectsView/PageHeader/SortDropdown'
import {availableFields} from 'utils/projects/sortByFields'
import type {SortBy} from 'components/pages/Projects/types'
import AddProjectButton from 'components/pages/Projects/ProjectsView/PageHeader/AddProjectButton'

import styles from './index.scss'

type Props = $ReadOnly<{
  groupBy: GroupBy,
  setGroupBy: (GroupBy) => void,
  showAs: 'list' | 'grid',
  setShowAs: ('list' | 'grid') => void,
  sortBy: SortBy,
  setSortBy: Function | string,
  projectsGridEnabled: boolean,
  canShowGridView: boolean,
  openCreator: () => void,
  viewerCanAddProject: boolean | null,
}>

function ProjectsHeader({
  groupBy,
  setGroupBy,
  showAs,
  setShowAs,
  sortBy,
  setSortBy,
  projectsGridEnabled,
  canShowGridView,
  openCreator,
  viewerCanAddProject,
} : Props): Element<"div"> {
  const dropdowns = useCallback(() => {
    const filterStatusInGrid = (item: string): boolean => (
      item !== 'status' || showAs !== 'grid'
    )

    return ([
      projectsGridEnabled && canShowGridView && (
        <ShowProjectsAs
          key="show-as"
          showAs={showAs}
          setShowAs={setShowAs}
        />
      ),
      <SortDropdown
        key="sort-by"
        currentItem={sortBy}
        items={availableFields.filter(filterStatusInGrid)}
        onUpdateSortFn={setSortBy}
      />,
      <GroupProjectsBy
        key="group-by"
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        values={['all', 'role']}
      />,
    ])
  }, [projectsGridEnabled, canShowGridView, showAs, setShowAs, sortBy, setSortBy, groupBy, setGroupBy])

  return (
    <div className={styles.header}>
      <Heading
        spacing="xSmall"
        headingLevel={2}
        underlined
        middleSection={dropdowns}
        // eslint-disable-next-line react/no-unstable-nested-components
        rightButton={() => (
          viewerCanAddProject && <AddProjectButton openCreator={openCreator} />
        )}
      />
    </div>
  )
}

export default ProjectsHeader
