// @flow
import React, {type Node, useMemo, useEffect} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import idOrDatabaseId, {type UseAsValue} from 'utils/MiscUtils/idOrDatabaseId'
import Select, {type Option} from 'components/ui/forms/Select'
import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import type {FormikProps} from 'formik'

import type {People, Person} from './types'

export type {
  Option,
}

type Props = $ReadOnly<{
  formikProps?: FormikProps<any>,
  label: string,
  people: People,
  useAsValue: UseAsValue,
  nonePerson: Option | null,
  eachRoleFiller: Option | null,
  hideLabel: boolean,
  eachCircleMember: Option | null,
  value: string | null,
  containerClassName?: string,
  onChange: (FakeEvent, boolean | string | null) => void,
  onBlur: (FakeEvent) => void | () => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
}>

const personToOption = (useAsValue: UseAsValue) => (person: Person) => ({
  value: idOrDatabaseId(useAsValue, person),
  label: person.name,
})

function PeopleSelector({
  formikProps,
  useAsValue,
  people,
  disabled,
  hideLabel,
  nonePerson,
  eachRoleFiller,
  eachCircleMember,
  containerClassName,
  ...selectProps
}: Props): Node {
  const options = useMemo(() => {
    const gqlPeopleOptions = people.map(personToOption(useAsValue))
    const nonGQLOptions: Array<Option> = []

    if (nonePerson)
      nonGQLOptions.push(nonePerson)

    if (people.length > 1 && eachRoleFiller)
      nonGQLOptions.push(eachRoleFiller)

    if (people.length > 1 && eachCircleMember)
      nonGQLOptions.push(eachCircleMember)

    return [...nonGQLOptions, ...gqlPeopleOptions]
  }, [people, useAsValue, eachRoleFiller, eachCircleMember, nonePerson])

  const initialValue = options.length === 1 || nonePerson
    ? options[0].value
    : null

  useEffect(() => {
    if (nonePerson && people.length === 1 && formikProps)
      formikProps.setFieldValue('personId', options[1].value)
    // Including 'formikProps' will cause infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [people, nonePerson, options])

  return (
    <Select
      hideLabel={hideLabel}
      initialValue={initialValue}
      disabled={disabled}
      options={options}
      containerClassName={containerClassName}
      {...selectProps}
    />
  )
}

PeopleSelector.defaultProps = {
  hideLabel: false,
  disabled: false,
  clearable: false,
  nonePerson: null,
  eachRoleFiller: null,
  eachCircleMember: null,
  'test-id': null,
  onBlur: () => {},
}

export default (createFragmentContainer(PeopleSelector, {
  people: graphql`
    fragment PeopleSelector_people on Person @relay(plural: true) {
      id
      databaseId
      name
    }
  `,
}): RelayFragmentContainer<typeof(PeopleSelector)>)
