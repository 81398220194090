// @flow
import type {Node} from 'react'
import React from 'react'
import {QueryRenderer, graphql} from 'react-relay'
import environment from 'environment'
import withErrorCheck from 'decorators/withErrorCheck'
import SetupModeBanner from './SetupModeBanner'
import type {GQLResponse} from './types'

const query = graphql`
  query SetupModeBannerContainerQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...SetupModeBanner_organization
    }
  }
`

type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

function SetupModeBannerContainer({orgDatabaseId}: Props): Node {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{orgDatabaseId}}
      render={withErrorCheck((renderProps: ?GQLResponse) => (
        renderProps && renderProps.organization ? (
          <SetupModeBanner
            organization={renderProps.organization}
          />
        ) : null
      ))}
    />
  )
}

export default SetupModeBannerContainer
