/**
 * @flow
 * @relayHash b16845c4f6b94114e00a8a9fa63705c0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  circleId: string,
  description: string,
  note?: ?string,
  parentGoalOrTarget?: ?string,
  personId?: ?string,
  privateToCircle: boolean,
  projectId?: ?string,
  roleId?: ?string,
  tagNames?: ?$ReadOnlyArray<string>,
  tensionId?: ?string,
  trigger: boolean,
  triggerEvent?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddProjectActionMutationVariables = {|
  input: AddActionInput
|};
export type AddProjectActionMutationResponse = {|
  +addAction: ?{|
    +actionEdge: ?{|
      +node: ?{|
        +id: string,
        +description: string,
        +rawDescription: string,
        +position: ?number,
        +trigger: boolean,
        +privateToCircle: boolean,
        +isUpdating: ?boolean,
        +viewerCanUpdate: boolean,
        +circle: ?{|
          +id: string
        |},
        +person: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type AddProjectActionMutation = {|
  variables: AddProjectActionMutationVariables,
  response: AddProjectActionMutationResponse,
|};
*/


/*
mutation AddProjectActionMutation(
  $input: AddActionInput!
) {
  addAction(input: $input) {
    actionEdge {
      node {
        id
        description(format: HTML)
        rawDescription: description(format: PLAIN)
        position
        trigger
        privateToCircle
        viewerCanUpdate
        circle {
          id
        }
        person {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddActionPayload",
    "kind": "LinkedField",
    "name": "addAction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionEdge",
        "kind": "LinkedField",
        "name": "actionEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "HTML"
                  }
                ],
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"HTML\")"
              },
              {
                "alias": "rawDescription",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "PLAIN"
                  }
                ],
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"PLAIN\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trigger",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUpdating",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProjectActionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProjectActionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "b16845c4f6b94114e00a8a9fa63705c0",
    "metadata": {},
    "name": "AddProjectActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '95acbbabbf6bf0407be78bffa53db5a6';

module.exports = node;
