/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SectionBody_organization$ref = any;
type SectionHeader_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Section_organization$ref: FragmentReference;
declare export opaque type Section_organization$fragmentType: Section_organization$ref;
export type Section_organization = {|
  +id: string,
  +constitutionRatifiers: ?string,
  +$fragmentRefs: SectionBody_organization$ref & SectionHeader_organization$ref,
  +$refType: Section_organization$ref,
|};
export type Section_organization$data = Section_organization;
export type Section_organization$key = {
  +$data?: Section_organization$data,
  +$fragmentRefs: Section_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Section_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constitutionRatifiers",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SectionBody_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SectionHeader_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7343e19872c01efdc008fbadd67faf4f';

module.exports = node;
