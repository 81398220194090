// @flow
import React, {useRef, useEffect} from 'react'
import jquery from 'jquery'
import type {Node} from 'react'

type Props = {
  targetRef: { current: null | HTMLElement },
  children: Node,
  containerSelector: string,
}

function BootstrapPopover({targetRef, children, containerSelector}: Props): Node {
  const contentRef = useRef()

  useEffect(() => {
    if (targetRef && targetRef.current) {
      jquery(targetRef.current).popover({
        content: () => jquery(contentRef.current).clone(),
        container: containerSelector,
        html: true,
        trigger: 'hover',
        placement: 'auto',
      })
    }
  }, [targetRef, containerSelector])

  return (
    <div className="d-none">
      <div ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default BootstrapPopover
