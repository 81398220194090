// @flow
import type {Node} from 'react'
import React from 'react'
import IconButton from 'components/ui/IconButton'
import Add from 'components/ui/icons/md/Add'
import styles from './index.scss'

type Props = $ReadOnly<{
  openCreator: () => void,
}>

function AddProjectButton({openCreator}: Props): Node {
  return (
    <IconButton
      className={styles.addButton}
      onClick={openCreator}
      buttonText={I18n.t('projects.my_projects.add_project')}
      icon={<Add />}
    />
  )
}

export default AddProjectButton
