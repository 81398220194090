// @flow strict
import {useMemo, useState} from 'react'
import getIDGenerator from 'utils/getIDGenerator'

function useUniqueId(): Array<string> {
  const generator = useMemo(getIDGenerator, [])
  const [uniqueId] = useState<string>(generator())

  return [uniqueId]
}

export default useUniqueId
