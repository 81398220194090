import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const Metric = GlassFrogModel.extend({ }, {
  resourceName: 'metrics',
  defaults: {
    circle_id: null,
    role_id: null,
  },
  placeholderText() {
    return I18n.t('admin.global_items.metric')
  },
})

export default Metric
