// @flow
import type {ElementConfig, Node} from 'react'
import React, {useCallback} from 'react'
import typeof Input from 'components/ui/forms/Input'
import typeof Textarea from 'components/ui/forms/Textarea'

import RoleAutocomplete from 'components/ui/RoleAutocomplete'

type Props<Component> = {
  input: Component,
  orgDatabaseId: string,
  circleDatabaseId: string | null,
  ...ElementConfig<Component>
}

// This component has a lot FlowFixMe cuz Flow can handle this
//  Input OR Textarea cases. So interface (aka Props) is fully correct and
//  both Textarea and Input will get correct props
function InputWithRoleAutocomplete<Component: Input | Textarea>({
  input: InputComponent,
  orgDatabaseId,
  circleDatabaseId,
  ...inputProps
}: Props<Component>): Node {
  const {onChange} = inputProps
  const handleAutocompleteChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (onChange)
      onChange(event, value)
  }, [onChange])

  return (
    <RoleAutocomplete
      onChange={handleAutocompleteChange}
      orgDatabaseId={orgDatabaseId}
      circleDatabaseId={circleDatabaseId}
    >
      {/* $FlowFixMe[incompatible-type] */}
      {/* $FlowFixMe[prop-missing] */}
      <InputComponent {...inputProps} />
    </RoleAutocomplete>
  )
}

export default InputWithRoleAutocomplete
