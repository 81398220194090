// @flow strict-local
import {useState, useCallback} from 'react'

type CreatingChecklistItem = $ReadOnly<{
  type: 'CREATE_METRIC',
}>

type NoneType = $ReadOnly<{
  type: 'NONE'
}>

type State = CreatingChecklistItem | NoneType

const noneState: NoneType = {type: 'NONE'}

function useModalsState(): [State, { closeModal: () => void, openCreator: () => void }] {
  const [state, setState] = useState<State>(noneState)

  const closeModal = useCallback(() => setState(noneState), [setState])

  const openCreator = useCallback(() => {
    setState({type: 'CREATE_METRIC'})
  }, [])

  return [state, {closeModal, openCreator}]
}

export default useModalsState
