import * as R from 'ramda'
import util from 'utils/MiscUtils'

const TensionActions = {
  TENSION_SAVE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data

    if (this.hasOrWillSave(type, id)) {
      this.store.setData(type, id, data)
      return this.apiAdapter.apiPatch(type, id, data).then(
        this.handleWith({
          type: 'DEFAULT_UPDATE_SUCCESS',
          snapshotData: this.store.getData(type, id),
        }),
      )
    }

    // set immediate
    this.store.setData(type, id, data)
    const snapshot = this.store.getData(type, id)

    return this.apiAdapter.apiPost(type, data, {localId: id}).then(
      this.handleWith({
        type: 'TENSION_CREATE_SUCCESS',
        snapshotData: snapshot,
        callbacks: action.callbacks,
      }),
    )
  },

  TENSION_CREATE_SUCCESS(action) {
    const type = action.request.type
    const responseData = action.response.data
    const localId = action.request.options.localId || util('localId required')

    const serverId = responseData[type][0].id
    this.apiAdapter.apiResolveLocalId(localId, serverId)

    const proposalId = this.store.getData('tensions', localId, 'proposal', 'id')

    if (proposalId) {
      const proposal = this.store.getModel('proposal', proposalId)
      const callbacks = action.callbacks || {}

      this.store.setData('proposals', proposalId, 'links', {tension: serverId})
      if (proposal.isNew()) {
        const proposalData = R.merge(proposal.attributes, {tension_id: serverId})
        this.apiAdapter.apiPost('proposals', proposalData, {localId: proposalId})
          .then(this.handleWith({type: 'PROPOSAL_CREATE_SUCCESS', snapshotData: proposalData}))
          .then(callbacks.success, callbacks.error)
      } else {
        this.apiAdapter.apiPatch('proposals', proposalId, {tension_id: serverId})
      }
    }

    return this.mergeResponseDataIntoStore(action)
  },

  TENSION_PROCESS(action) {
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data
    const pathArray = ['tensions', id, 'processing']

    return this.apiAdapter.apiPost('tensions', data, {pathArray})
      .then(
        this.handleWith({
          type: 'TENSION_PROCESS_SUCCESS',
          tension_id: id,
        }),
      )
  },

  TENSION_PROCESS_SUCCESS(action) {
    const tensionData = action.response.data.tension
    tensionData._recently_triaged = true
    const res = this.store.setData('tensions', action.tension_id, tensionData)
    return res
  },

  TENSION_FLAG_TRIAGED(action) {
    return this.store.setData('tensions', action.tension_id, {_recently_triaged: true})
  },

  TENSION_CLEANUP(action) {
    const tensionsIds = action.data.processedTensionsIds
    const store = this.store
    R.forEach((tensionId) => {
      store.setData('tensions', tensionId, {_recently_triaged: false})
    })(tensionsIds)
  },
}

export default TensionActions
