import _ from 'underscore'
import util from 'utils/MiscUtils'

import DataRequest from './DataRequest'

const GetData = {
  getData() {
    const dr = DataRequest.fromArguments(_.toArray(arguments), this)

    if (dr.options.debug) {
      // eslint-disable-next-line no-debugger
      debugger
    }

    return this.sendOut(dr, this._getDataForRequest(dr))
  },

  _getDataForRequest(dr) {
    switch (dr.requestType) {
      case DataRequest.requestType.FORWARD_LOOKUP:
        return this._doForwardLookup(dr)
      case DataRequest.requestType.REVERSE_LOOKUP:
        return this._doReverseLookup(dr)
      case DataRequest.requestType.SINGLE_RESOURCE:
        return this._getAttrData(dr.type, dr.id, dr.dataSpec || '*', dr.options)
      case DataRequest.requestType.COLLECTION:
        return this._getCollData(dr.type, dr.dataSpec, dr.options)
      case DataRequest.requestType.UNDEFINED:
        return undefined
      case DataRequest.requestType.NULL:
        return null
      default:
        util.error('invalid requestType')
    }
  },

  sendOut(dr, output) {
    if (dr.options.apply && _.isFunction(dr.options.apply) && !_.isUndefined(output))
      return dr.options.apply(output)

    if (dr.options.default && _.isUndefined(output))
      return dr.options.default

    return output
  },

  getMetadata(type, id, entry) {
    const entries = this._metadata[type][id]

    if (!entries)
      return undefined

    return entry ? entries[entry] : entries
  },

  // NOTE: RubyMine says unused, but this is called from `gups_preload` on the rails side
  setMetadata(type, id, entry, value) {
    this._metadata[type][id] = this._metadata[type][id] || {}
    this._metadata[type][id][entry] = value
  },

  // private

  _getRelatedData(dr) {
    const resource = this.getResource(dr.type, dr.id, dr.options)
    const forwardLookupRequested = dr.options.reverseLookup === false
    const reverseLookupRequested = dr.options.reverseLookup === true
    const forwardLookupAvailable = resource && gf.r.hasRelationship(dr.relName, resource)
    const shouldDoForwardLookup = forwardLookupRequested
      || (forwardLookupAvailable && !reverseLookupRequested)

    return shouldDoForwardLookup ? this._doForwardLookup(dr) : this._doReverseLookup(dr)
  },

  _doReverseLookup(dr) {
    const model = this.getModel(dr.type, dr.id, dr.options)

    if (!model)
      return undefined

    return this._getBacklinkedDataForModel(model, dr)
  },

  _forwardLookupIds(dr) {
    const resource = this.getResource(dr.type, dr.id, dr.options)

    if (!resource)
      return undefined

    const relIds = this._getRelationshipData(dr.type, dr.id, dr.relName, dr.options)

    if (dr.relId && !(_.isArray(relIds) && _.include(relIds, dr.relId)) && !this.getMetadata(dr.relName, dr.relId, 'removed'))
      util.warn(`id ${dr.relId} not found in relation ${dr.relName} of ${dr.type}:${dr.id}`)

    return dr.relId ? dr.relId : relIds
  },

  _doForwardLookup(dr) {
    const idOrIds = this._forwardLookupIds(dr)

    if (dr.dataSpec == 'id' || dr.dataSpec == 'ids') {
      // FIXME: we could typecheck that we're returning array or single id as expected here
      return idOrIds
    }

    // eslint-disable-next-line no-nested-ternary
    const ids = _.isArray(idOrIds) ? idOrIds : (idOrIds ? [idOrIds] : [])
    const rData = _.compact(_.map(ids, function (id) {
      return this._getRelatedAttrData(dr, dr.relName, id, dr.dataSpec, dr.options)
    }, this))

    return this.isNormalizedPluralType(dr.relName) && !dr.relId ? rData : rData[0]
  },

  _getRelatedAttrData(dr, type, id, dataSpec, options) {
    const model = this.getModel(type, id)
    return this._getAttrDataForModel(model, dataSpec, options)
  },

  _getAttrData(type, id, dataSpec, options) {
    const model = this.getModel(type, id, options)
    return this._getAttrDataForModel(model, dataSpec, options)
  },

  _getCollData(type, spec) {
    const coll = this.getCollection(type)
    return _.map(coll.models, function (m) {
      return this._getAttrDataForModel(m, spec)
    }, this)
  },

  _getAttrDataForModel(model, spec, options) {
    if (!model)
      return undefined

    if (_.isArray(spec)) {
      const res = {}
      _.each(spec, function (k) {
        res[k] = this._getAttrDataForModel(model, k, options)
      }, this)
      return res
    }

    if (spec == 'id' || spec == 'ids')
      return model.id || model._localId

    if (spec == '*') {
      const attrs = _.clone(model.attributes)

      if (!attrs.id && model._localId)
        attrs.id = model._localId

      return attrs
    }

    // FIXME: should get attributes & check hasOwnProperty, in case null values.
    if (model.attributes.hasOwnProperty(spec))
      return model.get(spec)

    if (model[spec])
      return model[spec].call(model)

    if (spec[0] === '_') {
      // if asking for client data we don't have, just return null
      return null
    }
    // otherwise warn that we couldn't find the requested thing
    const thing = JSON.stringify(spec)
    util.warn(`store couldn't find attribute '${thing}'.  If ${thing} is a relationship, you may need to add an argument to your data request, try '*' `)
    return undefined
  },

  _getBacklinkedDataForModel(model, dr) {
    const relName = dr.relName
    const dataSpec = dr.dataSpec
    const options = dr.options
    const blOptions = {}

    if (options.reverseLookup && options.reverseLookup !== true)
      blOptions.fromAttr = options.reverseLookup

    if (dataSpec == 'id')
      return model.getBacklinkedIds(relName, blOptions)[0]

    if (dataSpec == 'ids')
      return model.getBacklinkedIds(relName, blOptions)

    if (!this.isNormalizedPluralType(relName)) {
      const rModel = model.getBacklinkedModels(relName, blOptions)[0]
      return this._getAttrDataForModel(rModel, dataSpec, options)
    }

    if (this.isNormalizedPluralType(relName)) {
      const models = model.getBacklinkedModels(relName, blOptions)
      return _.map(models, function (rModel) {
        return this._getAttrDataForModel(rModel, dataSpec, options)
      }, this)
    }

    util.error('error getting backlinks', model, relName)
  },

  _getRelationshipData(type, id, relName, options) {
    const model = this.getModel(type, id, options)
    const relData = this._getModelRelationshipData(model)

    if (relName == '*')
      return relData

    return relData && relData[relName]
  },

  _getModelRelationshipData(model) {
    return _.clone(model.attributes.links)
  },
}

export default GetData
