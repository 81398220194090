// @flow
import type {Node} from 'react'
import React from 'react'

import {MarkdownTip} from 'components/ui/FrogTipBox'

type Props = {
  rule: string,
  holacracyTip: string,
  holacracyBottomLink?: string,
  nonHolacracyTip: string,
}

function SectionTip(props: Props): Node {
  const holacracyTip = () => (
    <MarkdownTip
      text={props.holacracyTip}
      bottomLink={props.holacracyBottomLink}
    />
  )

  const nonHolacracyTip = () => (
    <MarkdownTip
      text={props.nonHolacracyTip}
    />
  )

  const renderTip = () => {
    if (props.rule === 'HOLACRACY')
      return holacracyTip()

    return nonHolacracyTip()
  }

  return renderTip()
}

export default SectionTip
