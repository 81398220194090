// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import environment from 'environment'
import {RelayEnvironmentProvider} from 'react-relay'
import LoadingDots from 'components/ui/LoadingDots'
import TacticalMeetingProjects from 'components/pages/TacticalMeeting/TacticalMeetingView/Steps/TacticalMeetingProjects'
import TacticalMeetingTriage from 'components/pages/TacticalMeeting/TacticalMeetingView/Steps/TacticalMeetingTriage'

type Props = $ReadOnly<{
  circleDatabaseId: string,
  meetingDatabaseId: string,
  orgDatabaseId: string,
  tab: string,
}>

const componentMap = {
  projects: TacticalMeetingProjects,
  triage: TacticalMeetingTriage,
}

function RelayWrapper(props: Props): Node {
  const TabComponent = componentMap[props.tab]

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<LoadingDots />}>
        <TabComponent {...props} />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default RelayWrapper
