import {debounce} from 'underscore'

const slickFormAutoSave = debounce(performAutoSave, 1000)

function performAutoSave(event) {
  const inputElement = event.target
  const form = inputElement.closest('form')
  form.requestSubmit()
}

function updatePersonDropdown(event) {
  setTimeout(() => {
    const form = $(event.target).closest('form')
    const peopleData = JSON.parse(form[0].dataset.peopleData)
    const roleId = parseInt($(form).find('[name$="role_id]"]')[0].value, 10) || -1
    const personReactSelect = $(form).find('[name$="person_id]"]').closest('[data-react-class]')[0]
    const currentProps = JSON.parse(personReactSelect.dataset.reactProps)
    const filteredPeople = []

    peopleData.forEach((person) => {
      if (person.roles.includes(roleId))
        filteredPeople.push({label: person.name, value: person.id})
    })

    currentProps.options = filteredPeople
    personReactSelect.dataset.reactProps = JSON.stringify(currentProps)

    ReactRailsUJS.mountComponents(personReactSelect.parentElement)
  }, 250)
}

export default {slickFormAutoSave, updatePersonDropdown}
