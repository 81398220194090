// @flow
import type {Element, Node} from 'react'
import React from 'react'

type Props = {
  title: ?Node,
  children: Node,
}

function Section({title, children}: Props): Element<"div"> {
  return (
    <div className="org-nav-section">
      {title && (
      <h3 className="org-nav-section__title">
        {title}
      </h3>
      )}
      <div className="org-nav-section__content">
        {children}
      </div>
    </div>
  )
}

Section.defaultProps = {
  title: null,
}

export default Section
