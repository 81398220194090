// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'
import liftNodes from 'utils/GraphQL/typedLiftNodes'

import BasicMetricFormModal from '../BasicMetricFormModal'
import type {ValidValues, Values} from '../BasicMetricFormModal'
import type {Organization} from '../../checklistItems/BasicChecklistFormModal/types'

import type {
  ViewerMetricFormModal_viewer$key as ViewerKey,
} from './__generated__/ViewerMetricFormModal_viewer.graphql'

type Props = $ReadOnly<{
  initialValues: Values,
  submit: (ValidValues) => Promise<Object>,
  modalTitle: string,
  disabledCircle: boolean,
  organization: Organization,
  viewer: ViewerKey,
  renderLeftFooterButton: (boolean) => Node,
  close: () => void,
}>

const viewerFragment = graphql`
  fragment ViewerMetricFormModal_viewer on Person {
    circles {
      edges {
        node {
          id

          ...CircleSelector_circles

          supportedRole {
            id
          }
        }
      }
    }

    ...WithCurrentRoleSelector_person
  }
`

function ViewerMetricFormModal({
  initialValues,
  submit,
  modalTitle,
  disabledCircle,
  organization,
  viewer: viewerKey,
  renderLeftFooterButton,
  close,
}: Props): Node {
  const viewer = useFragment(viewerFragment, viewerKey)
  const circles = liftNodes(viewer.circles)

  return (
    <BasicMetricFormModal
      selectCircle
      disabledCircle={disabledCircle}
      initialValues={initialValues}
      modalTitle={modalTitle}
      circles={circles}
      organization={organization}
      close={close}
      globalItem={false}
      renderLeftFooterButton={renderLeftFooterButton}
      submit={submit}
      viewer={viewer}
    />
  )
}

ViewerMetricFormModal.defaultProps = {
  renderLeftFooterButton: (): null => null,
  disabledCircle: false,
}

export default ViewerMetricFormModal
