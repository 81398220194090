// @flow
import React, {type Node, lazy, Suspense, useCallback} from 'react'
import {type RelayFragmentContainer, graphql, createFragmentContainer} from 'react-relay'

import flash from 'utils/MiscUtils/flash'
import constants from 'utils/projects/constants'
import AddProjectMutation from 'mutations/AddProjectMutation'
import ModalLoader from 'components/ui/ModalLoader'
import type {
  ProjectProcessModal_organization as Organization,
} from './__generated__/ProjectProcessModal_organization.graphql'
import type {
  ProjectProcessModal_tension as Tension,
} from './__generated__/ProjectProcessModal_tension.graphql'

const LazyCreateProjectModal = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CreateProjectModal" */
  'components/projects/CreateProjectModal'
))

type Props = {
  isOpen: boolean,
  onClose: () => void,
  organization: Organization,
  tension: Tension,
}

const successCreating = async () => flash.notice(I18n.t('tensions.react.process_into.success.project'))

function ProjectProcessModal({isOpen, onClose, tension, organization}: Props): Node {
  const circle = tension.impactedRole?.circle || tension.circle

  const initialProjectData = {
    personId: tension.person?.id || '',
    roleId: tension.impactedRole?.id || constants.individualActionId,
    note: I18n.t('tensions.react.resolve_tension', {tension_body: tension.body}),
  }

  const updateMutation = useCallback(async (values) => {
    const response = await AddProjectMutation(values)
    return response.addProject
  }, [])

  return (
    <Suspense fallback={<ModalLoader />}>
      {isOpen && (
        <LazyCreateProjectModal
          initialValues={initialProjectData}
          addMutation={updateMutation}
          mode="CircleAndRole"
          close={onClose}
          organization={organization}
          tension={tension}
          circle={circle}
          onSuccess={successCreating}
        />
      )}
    </Suspense>
  )
}

export default (createFragmentContainer(ProjectProcessModal, {
  tension: graphql`
    fragment ProjectProcessModal_tension on Tension {
      id
      databaseId
      body

      circle {
        id
        databaseId

        ...CreateProjectModal_circle
      }

      person {
        id
      }

      impactedRole {
        id

        circle {
          id
          databaseId

          ...CreateProjectModal_circle
        }
      }
      
      ...CreateProjectModal_tension
    }
  `,
  organization: graphql`
    fragment ProjectProcessModal_organization on Organization {
      ...CreateProjectModal_organization
    }
  `,
}): RelayFragmentContainer<typeof(ProjectProcessModal)>)
