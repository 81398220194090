import _ from 'underscore'
import Backbone from 'backbone'
import stringUtils from 'underscore.string'

import util from 'utils/MiscUtils'
import GlassFrogCollection from './GlassFrogCollection'
import SetData from './storeСoncerns/SetData'
import ChangedData from './storeСoncerns/ChangedData'
import EventHandling from './storeСoncerns/EventHandling'
import GetData from './storeСoncerns/GetData'

function GlassFrogStore() {
  this.initialize.apply(this, arguments)
}

_.extend(GlassFrogStore, {
  _resourceNames: [],

  registerModelClass(resourceName, resourceClass) {
    const collectionName = GlassFrogStore._resourceNameToCollectionName(resourceName)

    if (!window[collectionName])
      this._createCollectionClass(collectionName, resourceClass)

    this._resourceNames.push(resourceName)
  },

  // private
  _createCollectionClass(collectionName, resourceClass) {
    window[collectionName] = GlassFrogCollection.extend({
      model: resourceClass,
    })
  },

  _resourceNameToCollectionName(resourceName) {
    return `${stringUtils.classify(_.singularize(resourceName))}Collection`
  },
})

_.extend(GlassFrogStore.prototype, {
  getCollection(rawType) {
    if (!rawType)
      util.error('type required')

    const type = this.normalizeTypeToPlural(rawType)
    const collection = this._collections[type]

    if (!collection) {
      util.error(
        `no collection of type:${rawType}. you may need to create a ${_.singularize(rawType)} model.`,
      )
    }

    return collection
  },

  initialize(opts) {
    this.apiAdapter = (opts && opts.apiAdapter) || util.error('apiAdapter required')
    this.dispatcher = (opts && opts.dispatcher) || util.error('dispatcher required')
    this.isStore = true
    this._resourceTypes = this.constructor._resourceNames
    this._collections = {}
    this._pluralToSingular = {}
    this._singularToPlural = {}
    this._localIdToServerIdMap = {}
    this._localIdToBackboneCidMap = {}
    this._metadata = {}

    this._setupCollections()
  },

  hasCollection(rawType) {
    const type = this.normalizeTypeToPlural(rawType)
    return !!this._collections[type]
  },

  hasResource(type, id) {
    return !!this.getModel(type, id)
  },

  getModel(type, rawId) {
    if (!type)
      util.error('type required')

    if (!rawId)
      util.error('id required')

    const verifiedLocalId = this._resolveLocalIdToServerOrCid(rawId) || rawId
    const id = util.isLocalId(rawId) ? verifiedLocalId : rawId

    return this.getCollection(type).get(id)
  },

  getResource(type, rawId, options) {
    const model = this.getModel(type, rawId, options)
    return model ? _.clone(model.attributes) : model
  },

  getRelatedModel(type, id, relType, opts) {
    if (!id)
      return null

    const relId = this.getData(type, id, relType, 'id', opts)
    return relId ? this.getModel(relType, relId) : null
  },

  _resolveLocalIdToServerOrCid(localId) {
    return this._localIdToServerIdMap[localId] || this._localIdToBackboneCidMap[localId]
  },

  // this is uses by the InlineTableView (backbone)
  // should go away once that component is gone
  resolveCidToLocalId(cid) {
    return _.invert(this._localIdToBackboneCidMap)[cid]
  },

  resolveLocalId(localId) {
    return this._localIdToServerIdMap[localId]
  },

  // it's nice to support normalization to expected singular/plural,
  // so that stuff doesn't break all over because of mismatches.
  // but calling _.singularize and _.pluralize are slow-- definitely don't want
  // to call them all over the place.  so we create some lookup tables at
  // collections setup time, and reference them here.

  normalizeTypeToSingular(type) {
    if (type == 'supported_roles' || type == 'supported_role')
      return 'role'

    if (type == 'supporting_circles' || type == 'supporting_circle')
      return 'circle'

    return this._pluralToSingular[type] || type
  },

  normalizeTypeToPlural(type) {
    if (type == 'supported_roles' || type == 'supported_role')
      return 'roles'

    if (type == 'supporting_circles' || type == 'supporting_circle')
      return 'circles'

    return this._singularToPlural[type] || type
  },

  isNormalizedPluralType(type) {
    return (type == 'people') || (this.normalizeTypeToPlural(type) == type)
  },

  isSaved(type, id) {
    const model = this.getModel(type, id)
    return model && model.id
  },

  isLoaded(type, id) {
    const model = this.getModel(type, id)
    return model && model.id
  },

  // private

  _setupCollections() {
    _.each(this._resourceTypes, function (resourceType) {
      if (!this._collections[resourceType]) {
        this._addCollection(resourceType)
        this._metadata[resourceType] = {}
      }

      const plural = resourceType
      const singular = _.singularize(resourceType)

      this._singularToPlural[singular] = plural
      this._pluralToSingular[plural] = singular
    }, this)
  },

  _addCollection(modelType) {
    const collectionClassName = GlassFrogStore._resourceNameToCollectionName(modelType)
    const newCollection = new window[collectionClassName](null, {}, this)
    this._collections[modelType] = newCollection
  },
})

_.extend(GlassFrogStore.prototype, SetData)
_.extend(GlassFrogStore.prototype, GetData)
_.extend(GlassFrogStore.prototype, ChangedData)

GlassFrogStore.prototype.initializeWithoutEventHandling = GlassFrogStore.prototype.initialize
_.extend(GlassFrogStore.prototype, Backbone.Events, EventHandling)

export default GlassFrogStore
