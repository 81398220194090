// @flow
import {
  enUS as en,
  arSA as ar,
  de,
  es,
  fr,
  hi,
  ja,
  it,
  nl,
  pl,
  pt,
  ru,
  zhCN,
} from 'date-fns/locale'

import getCurrentLocale from 'utils/getCurrentLocale'
import type {Locale} from '../locales'

// have to be up to date with lib/glassfrog/i18n.rb
const locales = {
  en,
  ar,
  de,
  es,
  fr,
  hi,
  it,
  nl,
  pl,
  pt,
  ru,
  'zh-CN': zhCN,
  ja,
}

const localeString: Locale = getCurrentLocale()
export const locale = locales[localeString]
locale.code = localeString

export const configWithLocale = {locale}
