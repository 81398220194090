// @flow
import type {Locale} from './locales'
import {locales, productionLocales} from './locales'

export default function getCurrentLocale(productionOnly: boolean = false): Locale {
  const localesScope = productionOnly ? productionLocales : locales

  // eslint-disable-next-line no-underscore-dangle
  return localesScope.find((locale) => locale === window.__applicationStartArgs?.locale) || 'en'
}
