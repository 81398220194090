// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import classNames from 'classnames'
import LoadingDots from 'components/ui/LoadingDots'
import styles from './index.scss'

type Props = {
  message: string | null,
  disabled: boolean,
  loading: boolean,
  onClick: () => void,
}

function ExportButtonComponent({message, disabled, loading, onClick}: Props): Node {
  const exportButtonClasses = () => classNames(styles.exportButton, {
    spinner: message,
    disabled,
  })

  return (
    <Fragment>
      <button
        className={exportButtonClasses()}
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        <div className={styles.buttonInner}>
          <div className={styles.buttonText}>
            {I18n.t('admin.organizations.exports.export')}
          </div>
          {loading && <LoadingDots sm />}
        </div>
      </button>

      {message && (
        <div>
          {message}
        </div>
      )}
    </Fragment>
  )
}

export default ExportButtonComponent
