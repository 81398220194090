// @flow
import {graphql} from 'react-relay'
import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  SetOrganizationRuleInput as Input,
  SetOrganizationRuleMutationResponse as Response,
} from './__generated__/SetOrganizationRuleMutation.graphql'

type Variables = $ReadOnly<{
  input: MutationInput<Input>,
}>

const mutation = graphql`
  mutation SetOrganizationRuleMutation($input: SetOrganizationRuleInput!) {
    setOrganizationRule(input: $input) {
      organization {
        ...RulesSections_organization
      }
    
      rule {
        ...Section_rule
      }
    }
  }
`

export default (id: string, input: MutationInput<Input>): Promise<Response> => {
  const variables: Variables = {
    input,
  }

  return createMutationPromise({
    mutation,
    variables,
  })
}

export type {Response}
