// @flow
import type {Element} from 'react'
import React, {useCallback, useEffect, useMemo, useRef, useState, Suspense} from 'react'
import {graphql, useFragment} from 'react-relay'

import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import usePersistentState from 'utils/hooks/usePersistentState'
import OrganizationSubscription from 'subscriptions/OrganizationSubscription'
import AddProjectMutation from 'mutations/AddProjectMutation'
import UpdateProjectMutation from 'mutations/UpdateProjectMutation'
import DeleteProjectMutation from 'mutations/DeleteProjectMutation'
import ProjectsGroupsOrGrid from 'components/projects/ProjectsGroupsOrGrid'
import CreateProjectModal from 'components/projects/CreateProjectModal'
import UpdateProjectModal from 'components/projects/UpdateProjectModal'
import useModalsState from 'components/projects/useModalsState'
import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import {type GroupBy} from 'components/projects/GroupProjectsBy'
import TacticalMeetingProjectsHeader from './TacticalMeetingProjectsHeader'
import TacticalMeetingContainer from './../../ui/TacticalMeetingContainer'
import styles from './index.scss'
import type {
  TacticalMeetingProjectsView_role$key as RoleKey,
} from './__generated__/TacticalMeetingProjectsView_role.graphql'

type Props = $ReadOnly<{
  roleKey: RoleKey,
  refetchQuery: () => Promise<null>,
}>

const roleFragment = graphql`
  fragment TacticalMeetingProjectsView_role on Role  
  {
    databaseId

    organization {
      databaseId
      isHotwiredProjectsEnabled
      
      ...CreateProjectModal_organization
    }

    innerCircle {
      id
      databaseId

      supportedRole {
        id
      }

      ...ProjectsGroupsOrGrid_circle
      ...CreateProjectModal_circle
    }

    projects(first: 250, source: INTERNAL) {
      edges {
        node {
          id
          ...ProjectsGroupsOrGrid_projects
          ...UpdateProjectModal_project
        }
      }
    }

    ...TacticalMeetingProjectsHeader_role
  }
`

export default function TacticalMeetingProjectsView({roleKey, refetchQuery}: Props): Element<"div"> {
  const role = useFragment(roleFragment, roleKey)
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef()

  useEffect(() => {
    if (!headerRef.current)
      return

    const {height} = headerRef.current.getBoundingClientRect()
    setHeaderHeight(height)
  }, [])

  const roleDatabaseId = role.databaseId

  const [groupBy, setGroupBy] = usePersistentState<GroupBy>(
    'person',
    `${roleDatabaseId}-tactical-project-group-by`,
  )

  const orgDatabaseId = role.organization.databaseId

  useSubscriptionRefetch(
    OrganizationSubscription,
    {orgDatabaseId},
    refetchQuery,
    {orgDatabaseId, roleDatabaseId},
    (data) => (data.organizationEvent?.type === 'OrganizationProjectChange'),
  )

  const [modalsState, {openEditor, openCreator, closeModal}] = useModalsState()
  const circleProjects = useMemo(() => liftNodes(role.projects).filter(Boolean), [role])
  const editingProject = circleProjects.find((project) => (
    modalsState.type === 'EDITING_PROJECT' && project.id === modalsState.projectId
  ))

  const initialProjectValues = {supportedRoleId: role.innerCircle?.supportedRole?.id}

  const addMutation = useCallback(async (values) => {
    const response = await AddProjectMutation(values)
    return response.addProject
  }, [])

  const updateMutation = useCallback(async (values) => {
    const response = await UpdateProjectMutation(values)
    return response.updateProject
  }, [])

  if (role.organization.isHotwiredProjectsEnabled) {
    return (
      <div id="tactical-meeting-projects-tab">
        <div ref={headerRef}>
          <turbo-frame
            id="projects-tab"
            src={`/organizations/${orgDatabaseId}/roles/${role.databaseId}/projects${window.location.search}`}
          />
        </div>
      </div>
    )
  }

  return (
    <div id="tactical-meeting-projects-tab">
      <div ref={headerRef} className={styles.header}>
        <TacticalMeetingProjectsHeader
          role={role}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          openCreator={openCreator}
        />
      </div>
      <TacticalMeetingContainer>
        <ProjectsGroupsOrGrid
          groupBy={groupBy}
          openEditor={openEditor}
          projects={circleProjects}
          roleNameMode="WITH_LINKED_FROM"
          showAs="grid"
          stickyHeaderOffset={headerHeight}
          circle={role.innerCircle}
        />
      </TacticalMeetingContainer>
      {modalsState.type === 'CREATE_PROJECT' && (
        <CreateProjectModal
          circle={role.innerCircle}
          organization={role.organization}
          initialValues={initialProjectValues}
          mode="RoleAndPerson"
          addMutation={addMutation}
          close={closeModal}
        />
      )}
      {modalsState.type === 'EDITING_PROJECT' && editingProject && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <UpdateProjectModal
              mode="RoleAndPerson"
              updateMutation={updateMutation}
              deleteMutation={DeleteProjectMutation}
              project={editingProject}
              close={closeModal}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
    </div>
  )
}

export const UnwrappedTacticalMeetingProjectsView = TacticalMeetingProjectsView
