import React, {useState} from 'react'
import FrogBotIcon from 'components/ui/icons/FrogBotIcon'
import ChatWindow from './ChatWindow'
import styles from './index.scss'

type Props = {
  chatsPath: string,
  messagesPath: string,
  superUser?: boolean,
  organizationId: string,
}

function AiChat({chatsPath, messagesPath, superUser, organizationId}: Props) {
  const [isChatOpen, setChatOpen] = useState(false)

  const openButton = () => (
    <button
      type="button"
      className={styles.openChatButton}
      onClick={() => setChatOpen(true)}
      test-id="open-chat-button"
    >
      <FrogBotIcon />
    </button>
  )

  return (
    <div className={styles.chatContainer}>
      {!isChatOpen && openButton()}
      {isChatOpen && (
        <ChatWindow
          chatsPath={chatsPath}
          messagesPath={messagesPath}
          superUser={superUser}
          close={() => setChatOpen(false)}
          organizationId={organizationId}
        />
      )}
    </div>

  )
}

AiChat.defaultProps = {
  superUser: false,
}
export default AiChat
