/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EditAmendmentBody_amendment$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Section_amendment$ref: FragmentReference;
declare export opaque type Section_amendment$fragmentType: Section_amendment$ref;
export type Section_amendment = {|
  +id: string,
  +$fragmentRefs: EditAmendmentBody_amendment$ref,
  +$refType: Section_amendment$ref,
|};
export type Section_amendment$data = Section_amendment;
export type Section_amendment$key = {
  +$data?: Section_amendment$data,
  +$fragmentRefs: Section_amendment$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Section_amendment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAmendmentBody_amendment"
    }
  ],
  "type": "Amendment",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ccdb816b3c2b9373ceb5e9f31da99eb5';

module.exports = node;
