// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  UpdateMetricInput as Input,
  UpdateMetricMutationResponse as Response,
} from './__generated__/UpdateMetricMutation.graphql'

const mutation = graphql`
  mutation UpdateMetricMutation($input: UpdateMetricInput!) {
    updateMetric(input: $input) {
      metric {
        ...Metric_metric
      }
    }
  }
`

const UpdateMetricMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {input}

  return createMutationPromise({mutation, variables})
}

export type {Response}

export default UpdateMetricMutation
