// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {propEq} from 'ramda'

import {type Option, type Value} from 'components/ui/EtcMenu/EtcSelector'
import TinyMultiSelectDropdown from 'components/ui/TinyMultiSelectDropdown'

type Props = {|
  'test-id': ?string,
  label: (?Option) => Node,
  highlightedLabel: (?Option) => Node,
  onSelect: Option => void,
  options: Option[],
  selectedValue: Value,
  caret: boolean,
|}

function TinySelectDropdown({label, highlightedLabel, onSelect, options, selectedValue, caret, 'test-id': testId}:
  Props): Node {
  const selectedValues: Value[] = [
    options.find(propEq('value', selectedValue)),
  ]
    .filter(Boolean)
    .map(({value}) => value)

  const multiLabel = useCallback((selectedOptions: Option[]) => (
    label(selectedOptions[0])
  ), [label])

  const multiHighlightedLabel = useCallback((selectedOptions: Option[]) => (
    highlightedLabel(selectedOptions[0])
  ), [highlightedLabel])

  return (
    <TinyMultiSelectDropdown
      label={multiLabel}
      highlightedLabel={multiHighlightedLabel}
      onSelect={onSelect}
      options={options}
      selectedValues={selectedValues}
      caret={caret}
      test-id={testId}
    />
  )
}

TinySelectDropdown.defaultProps = {
  caret: false,
  'test-id': null,
}

export default TinySelectDropdown
