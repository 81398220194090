import React, {useEffect, useState} from 'react'
import styles from './index.scss'

function Thinking() {
  const [currentComponent, setCurrentComponent] = useState(0)

  useEffect(() => {
    function delay(duration) {
      return new Promise((resolve) => {
        setTimeout(resolve, duration)
      })
    }

    const transitions = [
      {delay: 500, componentIndex: 1},
      {delay: 8000, componentIndex: 2},
      {delay: 5000, componentIndex: 1},
      {delay: 9000, componentIndex: 3},
      {delay: 5000, componentIndex: 1},
    ]

    Promise.resolve()
      .then(() => transitions.reduce((previousPromise, {delay: d, componentIndex}) => previousPromise
        .then(() => delay(d))
        .then(() => setCurrentComponent(componentIndex)), Promise.resolve()))
  }, [])

  const typing = () => (
    <span className={styles.thinking}>
      <span className={styles.first}>{'.'}</span>
      <span className={styles.second}>{'.'}</span>
      <span className={styles.third}>{'.'}</span>
    </span>
  )

  function Pause() {
    return ''
  }

  function Typing() {
    return typing()
  }

  function ResearchMessage() {
    return I18n.t('ai.im_fetching_data')
  }

  function StillLookingMessage() {
    return I18n.t('ai.im_still_looking')
  }

  const components = [Pause, Typing, ResearchMessage, StillLookingMessage]

  const CurrentComponent = components[currentComponent]

  return <CurrentComponent />
}

export default Thinking
