// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import UpdateTacticalOutputMutation from 'mutations/UpdateTacticalOutputMutation'
import OutputForm, {type SubmitValues} from '../../../OutputForm'
import type {
  EditOutput_output as Output,
} from './__generated__/EditOutput_output.graphql'
import type {
  EditOutput_meeting as Meeting,
} from './__generated__/EditOutput_meeting.graphql'

type Props = $ReadOnly<{
  meeting: Meeting,
  output: Output,
  onSuccess: () => void,
}>

function EditOutput({output, meeting, onSuccess}: Props): Node {
  const submit = (values: SubmitValues) => (
    UpdateTacticalOutputMutation({
      ...values,
      outputId: output.id,
    }).then(() => {})
  )

  return (
    <OutputForm
      meeting={meeting}
      output={output}
      submit={submit}
      circle={meeting.circle}
      onSuccess={onSuccess}
    />
  )
}

export default (createFragmentContainer(EditOutput, {
  output: graphql`
    fragment EditOutput_output on TacticalOutput {
      id
      
      ...OutputForm_output
    }
  `,
  meeting: graphql`
    fragment EditOutput_meeting on TacticalMeeting {
      ...OutputForm_meeting
      
      circle {
        ...OutputForm_circle
      }
    }
  `,
}): RelayFragmentContainer<typeof(EditOutput)>)
