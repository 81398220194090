// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'
import type {
  AddChecklistItemMutationResponse as Response,
  AddChecklistItemInput as Input,
} from './__generated__/AddChecklistItemMutation.graphql'

const mutation = graphql`
  mutation AddChecklistItemMutation(
    $input: AddChecklistItemInput!
  ) {
    addChecklistItem(input: $input) {
      checklistItemEdge {
        node {
          id
          frequency

          role {
            id
          }

          ...ChecklistItem_item
        }
      }
    }
  }
`

const AddChecklistItemMutation = (input: MutationInput<Input>, circleId: string): Promise<Response> => {
  const variables = {input}
  const roleId = input.roleId

  const circleAddConfig = () => ({
    type: 'RANGE_ADD',
    parentID: circleId,
    connectionInfo: [
      {
        key: 'Circle_checklistItems',
        rangeBehavior: 'append',
      },
      {
        key: 'TacticalMeetingChecklistItemsView_checklistItems',
        rangeBehavior: 'append',
      },
    ],
    edgeName: 'checklistItemEdge',
  })

  const roleAddConfig = () => ({
    type: 'RANGE_ADD',
    parentID: roleId,
    connectionInfo: [
      {
        key: 'Role_checklistItems',
        rangeBehavior: 'append',
        filters: {
          global: 'NON_GLOBAL_ONLY',
        },
      },
    ],
    edgeName: 'checklistItemEdge',
  })

  const configs = input.reportedBy === 'CIRCLE_MEMBERS'
    ? [circleAddConfig()]
    : [roleAddConfig(), circleAddConfig()]

  return createMutationPromise({mutation, variables, configs})
}

export type {Response}
export default AddChecklistItemMutation
