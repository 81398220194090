// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import MetricsSection from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import NoItemsMessage from 'components/ui/NoItemsMessage'

import type {
  ChecklistsItemsViewerSection_checklistItems$key as ChecklistItemKey,
} from './__generated__/ChecklistsItemsViewerSection_checklistItems.graphql'

import PersonChecklistItem from './PersonChecklistItem'

type Props = $ReadOnly<{
  checklistItems: ChecklistItemKey,
  showNoItemsMessage: boolean,
}>

const checklistItemsFragment = graphql`
  fragment ChecklistsItemsViewerSection_checklistItems on ChecklistItem @relay(plural: true) {
    frequency
    localizedFrequency
    id

    appliesToAllMembers
    databaseId
    description
    link
    localizedRoleName
    isGlobal
    privateToCircle
    position

    organization {
      id
      onRestrictedPlan
    }

    customFrequency {
      id
      name
      universalUnits
    }

    ...PersonChecklistItem_item
  }
`

function ChecklistsItemsViewerSection({
  checklistItems: checklistItemsKey,
  showNoItemsMessage,
}: Props): Node {
  const checklistItems = useFragment(checklistItemsFragment, checklistItemsKey)

  if (showNoItemsMessage && checklistItems.length === 0)
    return <NoItemsMessage message={I18n.t('metrics.no_checklist_items')} />

  return (
    <MetricsSection
      hideIfEmpty
      items={checklistItems}
      reorderable={false}
      renderItem={(item) => (
        <PersonChecklistItem
          key={item.id}
          item={item}
        />
      )}
    />
  )
}

export default ChecklistsItemsViewerSection
