import util from 'utils/MiscUtils'
import AgendaHelper from 'legacy/GlassFrog/utills/AgendaHelper'

const AgendaActions = {
  AGENDA_ITEM_CREATE(action) {
    const meetingType = action.meetingType || util.error('meetingType required')
    const meetingId = action.meetingId || util.error('meetingId required')
    const personId = action.personId || util.error('personId required')
    const label = action.label
    const data = {
      meeting_id: meetingId,
      label,
      person_id: personId,
    }

    return this.apiAdapter.apiPost('agenda_items', data).then(
      this.handleWith({type: 'AGENDA_ITEM_CREATE_SUCCESS', meetingType, meetingId}),
    )
  },

  AGENDA_ITEM_CREATE_SUCCESS(action) {
    const status = action.response.status
    util.assert(status == 200, 'AGENDA_ITEM_CREATE_SUCCESS cant deal with non 200 status...')

    const requestedAt = action.request.options.headers['X-GF-REQUESTED-AT'] || util.error('expected request time')
    this.mergeResponseDataIntoStore(action, {skipIfChangedSince: requestedAt})

    // if no agenda_item is currently selected, set current_agenda_item_id to the newly
    // created items id. this would parallel what the server does, but we don't currently
    // get the info that the server has done that in the POST response.
    const meetingType = action.meetingType || util.error('meetingType required')
    const meetingId = action.meetingId || util.error('meetingId required')
    let currentAgendaItemId = AgendaHelper.currentAgendaItemId(this.store, meetingType, meetingId)

    if (!currentAgendaItemId)
      currentAgendaItemId = action.response.data.agenda_items[0].id

    try {
      this.store.setData(meetingType, meetingId, 'links', {current_agenda_item: currentAgendaItemId})
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },

  AGENDA_ITEM_COMPLETION_TOGGLE(action) {
    const type = 'agenda_items'
    const id = action.agendaItemId
    const data = {
      completed: !this.store.getData(type, id, 'completed'),
    }

    this.store.setData(type, id, data)
    return this.apiAdapter.apiPatch(type, id, data)
  },

  AGENDA_ITEM_SELECT(action) {
    const type = action.meetingType || util.error('meetingType required')
    const id = action.meetingId || util.error('meetingId required')
    const data = {
      links: {
        current_agenda_item: action.agendaItemId,
      },
    }
    this.store.setData(type, id, data)
    return this.apiAdapter.apiPatch(type, id, data)
  },
}

export default AgendaActions
