// @flow strict
import {useCallback} from 'react'

function useVoidCallback<CbResponse>(
  promiseCallback: () => Promise<CbResponse>,
): () => void {
  return useCallback(() => {
    promiseCallback()
  }, [promiseCallback])
}

export default useVoidCallback
