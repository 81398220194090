/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type List_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Actions_project$ref: FragmentReference;
declare export opaque type Actions_project$fragmentType: Actions_project$ref;
export type Actions_project = {|
  +viewerCanAddActions: boolean,
  +$fragmentRefs: List_project$ref,
  +$refType: Actions_project$ref,
|};
export type Actions_project$data = Actions_project;
export type Actions_project$key = {
  +$data?: Actions_project$data,
  +$fragmentRefs: Actions_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Actions_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanAddActions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "List_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '82d664c2ddff606f230bb89ba8121ba4';

module.exports = node;
