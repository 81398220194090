/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CircleRoleSelector_circle$ref: FragmentReference;
declare export opaque type CircleRoleSelector_circle$fragmentType: CircleRoleSelector_circle$ref;
export type CircleRoleSelector_circle = {|
  +supportedRole: ?{|
    +id: string,
    +isExpanded: boolean,
    +$fragmentRefs: RoleSelector_roles$ref,
  |},
  +roles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: RoleSelector_roles$ref
      |}
    |}>
  |},
  +$refType: CircleRoleSelector_circle$ref,
|};
export type CircleRoleSelector_circle$data = CircleRoleSelector_circle;
export type CircleRoleSelector_circle$key = {
  +$data?: CircleRoleSelector_circle$data,
  +$fragmentRefs: CircleRoleSelector_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RoleSelector_roles"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CircleRoleSelector_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isExpanded",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f7f387bf91002415308ef48d561beaa';

module.exports = node;
