// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import MetricList from 'components/pages/Metrics/MetricList'

import type {
  ProfileMetricsView_organization$key as Organization,
} from './__generated__/ProfileMetricsView_organization.graphql'
import type {
  ProfileMetricsView_person$key as Person,
} from './__generated__/ProfileMetricsView_person.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  person: Person,
}>

const orgFragment = graphql`
  fragment ProfileMetricsView_organization on Organization {
    viewer {
      id
    }
    ...MetricList_organization
  }
`

const personFragment = graphql`
  fragment ProfileMetricsView_person on Person {
    id
    ...MetricList_viewer
  }
`

function ProfileMetricsView({organization: orgKey, person: personKey} : Props): Node {
  const organization = useFragment(orgFragment, orgKey)
  const viewer = organization.viewer
  const person = useFragment(personFragment, personKey)

  return <MetricList showAddButton={person.id === viewer.id} organization={organization} viewer={person} />
}

export default ProfileMetricsView
