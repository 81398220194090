// @flow
import type {Element} from 'react'
import React from 'react'
import ReactDatePicker from 'react-datepicker'

import convertPosixToLdml from 'utils/MiscUtils/convertPosixToLdml'
import styles from './index.scss'

type Props = {|
  className: ?string,
  disabled: ?boolean,
  id: ?string,
  minDate: ?Date,
  onChange: ?Date => void,
  selected: ?Date,
  placeholderAppend?: ?string,
|}

function DatePicker(props: Props): Element<"div"> {
  const dateFormat = convertPosixToLdml(I18n.t('date.formats.default'))
  const placeholderText = props.placeholderAppend
    ? `${dateFormat.toUpperCase()} ${props.placeholderAppend || ''}` : dateFormat.toUpperCase()

  return (
    <div className={styles.datePicker}>
      <ReactDatePicker
        className={props.className}
        disabled={props.disabled}
        id={props.id}
        minDate={props.minDate}
        onChange={props.onChange}
        selected={props.selected}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
      />
    </div>
  )
}

DatePicker.defaultProps = {
  className: null,
  disabled: false,
  id: null,
  minDate: null,
  onChange: () => {},
  selected: null,
  placeholderAppend: '',
}

export default DatePicker
