// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {UncontrolledTooltip} from 'reactstrap'

import useUniqueId from 'utils/hooks/useUniqueId'
import IconLink from 'components/ui/IconLink'
import ExternalLinkAlt from 'components/ui/icons/fa/ExternalLinkAlt'
import styles from './index.scss'

import type {Project} from './types'

type Props = $ReadOnly<{
  project: Project,
}>

const stopPropagation = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation()
}

function ProjectLinkIcon({project}: Props): Node {
  const [id] = useUniqueId()

  if (!project.link)
    return null

  return (
    <Fragment>
      <IconLink
        onClick={stopPropagation}
        target="_blank"
        rel="noopener noreferrer"
        id={id}
        href={project.link}
        linkText={I18n.t('projects.my_projects.link')}
        className={styles.container}
      >
        <ExternalLinkAlt className={styles.icon} aria-hidden="true" />
      </IconLink>
      <UncontrolledTooltip
        target={id}
        placement="top"
      >
        {project.link}
      </UncontrolledTooltip>
    </Fragment>
  )
}

export default (createFragmentContainer(ProjectLinkIcon, {
  project: graphql`
    fragment ProjectLinkIcon_project on Project {
      link
    }
  `,
}): RelayFragmentContainer<typeof(ProjectLinkIcon)>)
