// @flow
import type {Element} from 'react'
import React, {useCallback, useState, useEffect} from 'react'
import {last} from 'ramda'
import classNames from 'classnames'

import styles from './index.scss'

type Props = $ReadOnly<{
  nextPage: ?string,
  total: number,
  pageSize: number,
  onClick: (string) => void,
  currentPage: ?string,
  isRefetching: boolean,
}>

function Pagination({
  currentPage,
  nextPage,
  total,
  onClick,
  pageSize,
  isRefetching,
}: Props): null | Element<"div"> {
  const [pagesStack, setPagesStack] = useState([])

  useEffect(() => {
    if (currentPage === null)
      setPagesStack([])
  }, [currentPage])

  const previousePage = last(pagesStack)

  const goForward = useCallback(() => {
    if (!nextPage)
      return

    setPagesStack([...pagesStack, currentPage])

    onClick(nextPage)
  }, [nextPage, currentPage, pagesStack, setPagesStack, onClick])

  const goBack = useCallback(() => {
    if (previousePage === undefined)
      return

    setPagesStack(pagesStack.slice(0, -1))

    onClick(previousePage)
  }, [previousePage, pagesStack, setPagesStack, onClick])

  const currentPageNumber = pagesStack.length + 1
  const pagesTotal = Math.ceil(total / pageSize)

  if (pagesTotal < 2 && currentPage === null)
    return null

  const buttonClassName = (disabled: boolean) => (
    classNames(styles.paginationButton, {
      [styles.disabledButton]: disabled,
      [styles.activeButton]: !disabled,
    })
  )

  const prevButtonDisabled = isRefetching || previousePage === undefined
  const nextButtonDisabled = isRefetching || !nextPage

  const prevButtonClassName = classNames(buttonClassName(prevButtonDisabled), styles.prevArrow, {
    [styles.disabledPrevArrow]: prevButtonDisabled,
  })

  const nextButtonClassName = classNames(buttonClassName(nextButtonDisabled), styles.nextArrow, {
    [styles.disabledNextArrow]: nextButtonDisabled,
  })

  return (
    <div className={styles.paginationContainer}>
      <button
        className={prevButtonClassName}
        onClick={goBack}
        type="button"
        disabled={prevButtonDisabled}
        title={I18n.t('shared.previous_page')}
      />
      <span className={styles.pageInfo}>
        {`${currentPageNumber} / ${pagesTotal}`}
      </span>
      <button
        className={nextButtonClassName}
        onClick={goForward}
        type="button"
        disabled={nextButtonDisabled}
        title={I18n.t('shared.next_page')}
      />
    </div>
  )
}

Pagination.defaultProps = {
  isRefetching: false,
}

export default Pagination
