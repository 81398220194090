// @flow
import type {Node} from 'react'
import React, {useMemo, useCallback} from 'react'
import SortDropdown, {type Option} from 'components/ui/SortDropdown'

type Props = $ReadOnly<{
  showAs: 'list' | 'grid',
  setShowAs: ('list' | 'grid') => void,
}>

function ShowProjectsAs({
  showAs,
  setShowAs,
}: Props): Node {
  const options = useMemo(() => (
    [{
      value: 'list',
      label: I18n.t('projects.show_as.list'),
    }, {
      value: 'grid',
      label: I18n.t('projects.show_as.grid'),
    }]
  ), [])

  const onSelect = useCallback((option: Option) => {
    if (option.value !== 'list' && option.value !== 'grid')
      return

    setShowAs(option.value)
  }, [setShowAs])

  return (
    <SortDropdown
      sortByLabel={I18n.t('projects.show_as.label')}
      onSelect={onSelect}
      options={options}
      selectedValue={showAs}
    />
  )
}

export default ShowProjectsAs
