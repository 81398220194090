// @flow
import latinize from 'latinize'

const latinizeString = (string: string): string => {
  const mayBeString = (typeof string === 'string') ? string.toLowerCase() : string
  return latinize(mayBeString)
}

const compareFunction = (stringA: string, stringB: string): number => {
  const latinizedA = latinizeString(stringA)
  const latinizedB = latinizeString(stringB)

  if (latinizedA < latinizedB)
    return -1

  if (latinizedA > latinizedB)
    return 1

  return 0
}

export default latinizeString
export {compareFunction}
