import React from 'react'
import PropTypes from 'prop-types'
import {UncontrolledTooltip} from 'reactstrap'

function BadgeWithTooltip({targetId, title, color, tooltip}) {
  return (
    <span>
      <span
        id={targetId}
        className={`badge badge-${color}`}
      >
        {title}
      </span>
      <UncontrolledTooltip
        autohide={false}
        placement="right"
        target={targetId}
      >
        {tooltip}
      </UncontrolledTooltip>
    </span>
  )
}

BadgeWithTooltip.defaultProps = {
  color: 'primary',
}

BadgeWithTooltip.propTypes = {
  targetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default BadgeWithTooltip
