// @flow
import type {Element} from 'react'
import React from 'react'

type Props = $ReadOnly<{
  ...
}>

function DefaultAvatar(props: Props): Element<"svg"> {
  return (
    <svg
      {...props}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="path-1" cx="17" cy="17" r="15" />
      </defs>
      <g
        id="Icon-/-Placeholder-/-Selected-Person-/-LightBlue-Ring"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <circle id="Path" stroke="#F6FDFF" strokeWidth="1.5" cx="17" cy="17" r="15.75" />
        <g id="_ionicons_svg_md-person" transform="translate(2.000000, 2.000000)">
          <g transform="translate(3.000000, 5.000000)" />
        </g>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="#E8F4F9" xlinkHref="#path-1" />
        <path
          d="M17,20 C20.3003125,20 23,17.0749323 23,13.5 C23,9.92506771 20.3003125,7 17,7 C13.6996875,7 11,9.92506771 11,13.5 C11,17.0749323 13.6996875,20 17,20 Z M17.5,23 C13.3595052,23 5,25.025125 5,29 L5,32 L30,32 L30,29 C30,25.025125 21.6404948,23 17.5,23 Z"
          id="Shape"
          fill="#1092A4"
          fillRule="nonzero"
          mask="url(#mask-2)"
        />
      </g>
    </svg>
  )
}

export default DefaultAvatar
