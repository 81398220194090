// @flow
import type {Node} from 'react'
import React, {useCallback, useState} from 'react'
import {useOrganizationContext} from 'contexts/OrganizationContext'
import useToggle from 'utils/hooks/useToggle'
// import logger from 'utils/MiscUtils/main'
import AiSuggestion from './AiSuggestion'

const CLOSE_DELAY = 200 // ms
const STAY_CLOSED_DELAY = 2000 // ms

export type AiSuggestInputWrapperProps = $ReadOnly<{
  aiSuggestProps: {
    context: {
      type: string,
      id: string,
    },
    promptKey: string,
    setValue: string => void,
    data: Object,
  } | void,
  value: ?string,
  onBlur?: (any) => void,
  onFocus?: (any) => void,
  focus?: () => void,
  isAiEnabled?: boolean,
  orgDatabaseId?: string,
}>

type ReturnValue
 = {
  renderBelowInput: () => Node,
  wrappedOnFocus: (any) => void,
  wrappedOnBlur: (any) => void,
  isSuggestVisible: boolean,
}

function fromPropsOrContext(props, context, key): any {
  if (typeof (props[key]) !== 'undefined')
    return props[key]
  if (context && (typeof (context[key]) !== 'undefined'))
    return context[key]
  return null
}

export default function useAiSuggestInputWrapper({
  aiSuggestProps,
  onFocus,
  onBlur,
  orgDatabaseId,
  focus,
  isAiEnabled,
}: AiSuggestInputWrapperProps): ReturnValue {
  const organizationContext = useOrganizationContext()
  const [blurTimeout, setBlurTimeout] = useState(null)
  const [openEnabled, setOpenEnabled] = useState(true)

  const [
    isSuggestOpen,
    {
      close: closeSuggest,
      open: openSuggest,
    },
  ] = useToggle(false)

  /* the blur timeout stuff is to prevent the suggest from closing when the user clicks on a suggestion */
  const queClose = useCallback(() => {
    setBlurTimeout(setTimeout(closeSuggest, CLOSE_DELAY))
  }, [closeSuggest])

  const cancelClose = useCallback(() => {
    clearTimeout(blurTimeout)
  }, [blurTimeout])

  const wrappedOnFocus = useCallback((e) => {
    // console.log('wrappedOnFocus', e)
    cancelClose()
    if (openEnabled)
      openSuggest()
      // console.log('open enabled - openning suggest')

    if (onFocus)
      onFocus(e)
  }, [cancelClose, openSuggest, onFocus, openEnabled])

  const wrappedOnBlur = useCallback((e) => {
    queClose()
    if (onBlur)
      onBlur(e)
  }, [queClose, onBlur])

  const onClose = useCallback(() => {
    if (focus)
      focus()
    setOpenEnabled(false)
    setTimeout(() => {
      setOpenEnabled(true)
    }, STAY_CLOSED_DELAY)
    closeSuggest()
  }, [focus, closeSuggest])

  const orgProps = {databaseId: orgDatabaseId, isAiEnabled}
  const orgId = fromPropsOrContext(orgProps, organizationContext, 'databaseId')
  const aiEnabled = fromPropsOrContext(orgProps, organizationContext, 'isAiEnabled')
  const isSuggestVisible = !!(aiEnabled && aiSuggestProps?.promptKey && isSuggestOpen)
  const renderBelowInput = useCallback(() => {
    if (!isSuggestVisible || !aiSuggestProps)
      return null

    const {
      context,
      promptKey,
      setValue,
      data,
    } = aiSuggestProps
    return (
      <AiSuggestion
        onClose={onClose}
        cancelClose={cancelClose}
        queClose={queClose}
        orgDatabaseId={orgId}
        context={context}
        promptKey={promptKey}
        setValue={setValue}
        data={data}
      />
    )
  }, [
    isSuggestVisible, aiSuggestProps, cancelClose, queClose, orgId, onClose])

  return {renderBelowInput, wrappedOnFocus, wrappedOnBlur, isSuggestVisible}
}
