// @flow strict

type Connection<Node> = $ReadOnly<{
  count?: number,
  edges: ?$ReadOnlyArray<?$ReadOnly<{
    node: ?Node,
    cursor?: string,
  }>>,
  pageInfo?: $ReadOnly<{
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    endCursor: ?string,
  }>,
}>

export default function typedLiftNodes<Node>(connection: ?Connection<Node>): $ReadOnlyArray<Node> {
  if (!connection)
    return []

  if (!connection.edges)
    return []

  return connection.edges.map((edge) => edge?.node).filter(Boolean)
}
