/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MeetingBanner_circle$ref: FragmentReference;
declare export opaque type MeetingBanner_circle$fragmentType: MeetingBanner_circle$ref;
export type MeetingBanner_circle = {|
  +databaseId: string,
  +$refType: MeetingBanner_circle$ref,
|};
export type MeetingBanner_circle$data = MeetingBanner_circle;
export type MeetingBanner_circle$key = {
  +$data?: MeetingBanner_circle$data,
  +$fragmentRefs: MeetingBanner_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeetingBanner_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2607f73b38d1e36e3e0fee8d6c37e782';

module.exports = node;
