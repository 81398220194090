// @flow
import type {ComponentType} from 'react'
import React, {Component, Fragment} from 'react'
import {withRouter} from 'react-router'
import type {ReactRouterContext} from 'utils/AppRouter'
import AppRouter from 'utils/AppRouter'

class RouterSetup extends Component<ReactRouterContext> {
  componentDidMount() {
    this.setup()
  }

  componentDidUpdate() {
    this.setup()
  }

  setup = () => {
    AppRouter.instance.setupRouterContext(this.props)
  }

  render() { return null }
}

const RouterSetupWithRouter = withRouter(RouterSetup)

const withAppRouterSetup = <Props: {...}>(
  WrappedComponent: ComponentType<Props>,
): ComponentType<Props> => {
  function Wrapper(props: Props) {
    return (
      <Fragment>
        <WrappedComponent
          {...props}
        />
        <RouterSetupWithRouter />
      </Fragment>
    )
  }

  Wrapper.displayName = `withRouterSetup(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
  Wrapper.WrappedComponent = WrappedComponent

  return Wrapper
}

export default withAppRouterSetup
