/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectEditForm_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalView_circle$ref: FragmentReference;
declare export opaque type ProjectFormModalView_circle$fragmentType: ProjectFormModalView_circle$ref;
export type ProjectFormModalView_circle = {|
  +$fragmentRefs: ProjectEditForm_circle$ref,
  +$refType: ProjectFormModalView_circle$ref,
|};
export type ProjectFormModalView_circle$data = ProjectFormModalView_circle;
export type ProjectFormModalView_circle$key = {
  +$data?: ProjectFormModalView_circle$data,
  +$fragmentRefs: ProjectFormModalView_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalView_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectEditForm_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '562cc2bc5c3e1790e722b7bacdcdef1f';

module.exports = node;
