// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import {useFragment, graphql} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import Box from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/Box'

import type {
  ChecklistListBody_viewer$key as ViewerKey,
} from './__generated__/ChecklistListBody_viewer.graphql'

import ChecklistsItemsViewerSection from './ChecklistsItemsViewerSection'

type Props = $ReadOnly<{
  viewer: ViewerKey,
}>

const viewerFragment = graphql`
  fragment ChecklistListBody_viewer on Person {
    checklistItems(first: 100) @connection(key: "Viewer_checklistItems", filters: []) {
      edges {
        node {
          ...ChecklistsItemsViewerSection_checklistItems
        }
      }
    }
  }
`

function ChecklistListBody({viewer: viewerKey}: Props): Node {
  const viewer = useFragment(viewerFragment, viewerKey)

  const checklistItems = useMemo(() => (
    liftNodes(viewer.checklistItems)
  ), [viewer])

  return (
    <Box.Body>
      <ChecklistsItemsViewerSection
        showNoItemsMessage
        checklistItems={checklistItems}
      />
    </Box.Body>
  )
}

export default ChecklistListBody
