// @flow
type EventData = $ReadOnly<{
  name: string,
  value: string | null,
}>

export type FakeEvent = $ReadOnly<{
  target: $ReadOnly<{
    name: string,
    value: string | null,
  }>,
}>

export default ({name, value}: EventData): FakeEvent => ({
  target: {
    name,
    value,
  },
})
