// @flow
type Frequency = ('Monthly' | 'Quarterly' | 'Weekly')
type CustomFrequency = $ReadOnly<{
  id: string,
  name: string,
  universalUnits: number,
}>

const frequencies: Array<Frequency> = ['Weekly', 'Monthly', 'Quarterly']
export const frequencyToUnits = {
  Weekly: 7,
  Monthly: 30.42,
  Quarterly: 3 * 30.42,
}

export default frequencies
export type {Frequency, CustomFrequency}
