// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import RoleTemplateFormModal from 'components/roleTemplates/RoleTemplateFormModal'
import UpdateRoleTemplateMutation from 'mutations/UpdateRoleTemplateMutation'
import type {
  EditRoleTemplateModalView_organization$key as Organization,
} from './__generated__/EditRoleTemplateModalView_organization.graphql'

import type {
  EditRoleTemplateModalView_roleTemplate$key as RoleTemplate,
} from './__generated__/EditRoleTemplateModalView_roleTemplate.graphql'

type Props = $ReadOnly<{
  roleTemplate: RoleTemplate,
  organization: Organization,
  close: () => void,
}>

const roleTemplateFragment = graphql`
  fragment EditRoleTemplateModalView_roleTemplate on RoleTemplate {
    id
    name
    purpose
    domains
    accountabilities
  }
`

const organizationFragment = graphql`
  fragment EditRoleTemplateModalView_organization on Organization {
    ...RoleTemplateFormModal_organization
  }
`

function EditRoleTemplateModalView({
  roleTemplate: roleTemplateKey,
  organization: organizationKey,
  close,
}: Props): Node {
  const roleTemplate = useFragment(roleTemplateFragment, roleTemplateKey)
  const organization = useFragment(organizationFragment, organizationKey)

  const initialValues = {
    name: roleTemplate.name,
    purpose: roleTemplate.purpose || '',
    domains: roleTemplate.domains || [],
    accountabilities: roleTemplate.accountabilities || [],
  }

  const submit = (values) => (
    UpdateRoleTemplateMutation({
      ...values,
      roleTemplateId: roleTemplate.id,
    })
  )

  return (
    <RoleTemplateFormModal
      modalTitle={I18n.t('side_nav.settings_section.role_templates.edit_role_template')}
      initialValues={initialValues}
      organization={organization}
      close={close}
      submit={submit}
    />
  )
}

export default EditRoleTemplateModalView
