// @flow
import type {Node} from 'react'
import React from 'react'
import {useLazyLoadQuery, graphql} from 'react-relay'

import UpdateChecklistItemMutation, {type Response} from 'mutations/UpdateChecklistItemMutation'
import DeleteChecklistItemMutation from 'mutations/DeleteChecklistItemMutation'
import ChecklistFormModal from 'components/checklistItems/ChecklistFormModal'
import {allCircleMembersId} from 'constants/fakeId'
import type {
  ChecklistItemEditModalQuery as Query,
} from './__generated__/ChecklistItemEditModalQuery.graphql'

type Props = {
  circleDatabaseId: string,
  checklistItemId: string,
  close: () => void,
  onSubmit: Response => void,
  orgDatabaseId: string,
}

const editModalQuery = graphql`
  query ChecklistItemEditModalQuery(
    $orgDatabaseId: String!,
    $circleDatabaseId: String!,
    $checklistItemId: ID!
  ) {
    organization(databaseId: $orgDatabaseId) {
      circle(databaseId: $circleDatabaseId) {
        ...ChecklistFormModal_circle
      }
    }

    checklistItem: node(id: $checklistItemId) {
      ... on ChecklistItem {
        description
        frequency
        link
        appliesTo
        privateToCircle

        role {
          id
        }

        customFrequency {
          id
          name
          universalUnits
        }
      }
    }
  }
`

function ChecklistItemEditModal({
  circleDatabaseId,
  checklistItemId,
  close,
  onSubmit,
  orgDatabaseId,
}: Props): null | Node {
  const {organization, checklistItem} = useLazyLoadQuery<Query>(editModalQuery, {
    orgDatabaseId,
    circleDatabaseId,
    checklistItemId,
  })

  if (!organization?.circle || !checklistItem)
    return null

  const initialValues = {
    description: checklistItem.description || '',
    roleId: checklistItem.appliesTo === 'CIRCLE_MEMBERS'
      ? allCircleMembersId
      : checklistItem.role?.id,
    frequency: checklistItem.frequency,
    customFrequency: checklistItem.customFrequency,
    link: checklistItem.link,
    privateToCircle: checklistItem.privateToCircle,
  }

  const submit = async (values) => {
    const response = await UpdateChecklistItemMutation({
      ...values,
      checklistItemId,
    })

    onSubmit(response)

    return response
  }

  const deleteChecklist = async () => {
    /* eslint-disable-next-line no-alert */
    if (!window.confirm(I18n.t('metrics.delete_checklist_item_confirmation')))
      return

    await DeleteChecklistItemMutation(checklistItemId)
    close()
  }

  return (
    <ChecklistFormModal
      includeEachCircleMember
      initialValues={initialValues}
      circle={organization.circle}
      submit={submit}
      close={close}
      modalTitle={I18n.t('metrics.edit_checklist_item')}
      renderLeftFooterButton={(isSubmitting) => (
        <button
          className="btn btn-secondary btn-lg"
          disabled={isSubmitting}
          onClick={deleteChecklist}
          type="submit"
        >
          {I18n.t('react.components.edit_dialog.delete')}
        </button>
      )}
    />
  )
}

ChecklistItemEditModal.defaultProps = {
  onSubmit: () => {},
}

export default ChecklistItemEditModal
