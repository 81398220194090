// @flow
import type {Element, Node} from 'react'
import React from 'react'

import styles from './index.scss'

type Props = $ReadOnly<{
  children: $ReadOnlyArray<Node>
}>

function HorizontalGroup({children}: Props): Element<"div"> {
  return (
    <div className={styles.container}>{children}</div>
  )
}

export default HorizontalGroup
