import util from 'utils/MiscUtils'

// NOTE! you should not pass objects (models) as part of actions the way we're doing here-
// the interface should be straight data.
//
// However, since these actions only exist as a bridge to a legacy situation,
// we'll probably get away with it until they go away.  LWH
const LegacyBackboneActions = {
  BB_SYNC_CREATE(action) {
    const model = action.model
    const type = model.type() || util.error('resourceType required')

    // no store.setData() -- create is pessemistic we'll wait for the server to respond.
    return this.apiAdapter.apiPost(type, model.attributes).then(
      this.handleWith({type: 'DEFAULT_CREATE_SUCCESS', model: action.model}),
    )
  },

  BB_SYNC_UPDATE(action) {
    const model = action.model
    const type = model.type() || util.error('resourceType required')
    const id = model.id
    const apiData = model.changedAttributes()

    return this.apiAdapter.apiPatch(type, id, apiData).then(
      this.handleWith({type: 'DEFAULT_UPDATE_SUCCESS', snapshotData: this.store.getData(type, id)}),
      this.handleWith({type: 'DEFAULT_SERVER_ERROR'}),
    ).then(
      action.options.success,
      action.options.error,
    )
  },

  BB_SYNC_DELETE(action) {
    const model = action.model
    const type = model.type() || util.error('resourceType required')
    const id = model.id

    return this.apiAdapter.apiDelete(type, id).then(
      this.handleWith({type: 'DEFAULT_DELETE_SUCCESS'}),
    )
  },

  BB_SYNC_READ(action) {
    gf.app.apiFetcher.getModelWithFetch(action.model.type(), action.model.id)
  },
}

export default LegacyBackboneActions
