// @flow
import {useCallback, useEffect, useRef} from 'react'
import {type FormikProps} from 'formik'

function useFormikAutosave<Values>(formik: FormikProps<Values>, debounceMS: number = 1000) {
  const timeout = useRef(null)

  const isFormDirty = formik.dirty
  const submitForm = formik.submitForm

  const save = useCallback(() => {
    if (isFormDirty)
      submitForm()
  }, [isFormDirty, submitForm])

  const debouncedSubmit = useCallback(() => {
    const newTimeoutId = setTimeout(() => {
      save()
    }, debounceMS)
    if (timeout.current)
      clearTimeout(timeout.current)

    timeout.current = newTimeoutId
  }, [save, debounceMS])

  useEffect(() => debouncedSubmit(), [formik.values, debouncedSubmit])

  useEffect(() => (
    () => {
      if (timeout.current)
        clearTimeout(timeout.current)

      submitForm()
    }
  ), [submitForm])
}

export default useFormikAutosave
