import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import {parseISO, formatDistanceToNow} from 'date-fns'
import {format} from 'date-fns-tz'

import {configWithLocale} from 'utils/dateFnsLocale'

const RenderDateComponent = createReactClass({
  displayName: 'RenderDateComponent',

  propTypes: {
    rawDate: PropTypes.string.isRequired,
    fromNow: PropTypes.bool.isRequired,
    format: PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      fromNow: false,
      format: 'PP',
    }
  },

  render() {
    if (!this.props.rawDate)
      return null

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = parseISO(this.props.rawDate)
    const formatted = this.props.fromNow
      ? I18n.t('shared.date_ago', {date: formatDistanceToNow(date, configWithLocale)})
      : format(date, this.props.format, {timeZone: timezone, ...configWithLocale})

    return (
      <span>
        {formatted}
      </span>
    )
  },
})

export default RenderDateComponent
