// @flow
import type {Node} from 'react'
import React, {Suspense, useState, useEffect, useCallback, lazy} from 'react'
import {QueryRenderer, graphql} from 'react-relay'
import type {GraphQLTaggedNode} from 'react-relay'

import environment from 'environment'
import logger from 'utils/MiscUtils/main'
import {usePersistentState} from 'utils/hooks'
import OrganizationContextProviderFragment from 'contexts/OrganizationContextProviderFragment'
import {setControls as setGlobalControls} from './globalControl'
import Placeholder from './Placeholder'

import type {
  SideBarQueryResponse as Response,
  SideBarQueryVariables as Variables,
} from './__generated__/SideBarQuery.graphql'

const SideBarView = lazy(() => import(
  /* webpackChunkName: "SidebarView" */
  /* webpackPrefetch: true */
  './SidebarView'
))

export const sideBarQuery: GraphQLTaggedNode = graphql`
  query SideBarQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...OrganizationContextProviderFragment_organization      
      ...SidebarView_organization
    }
  }
`

type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

type RendererProps = $ReadOnly<{
  // eslint-disable-next-line react/no-unused-prop-types
  error: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  props: ?Response,
  ...
}>

function SideBar({orgDatabaseId}: Props): Node {
  const [searchAutofocus, setSearchAutofocus] = useState(false)
  const [isOpen, setIsOpen] = usePersistentState<boolean>(true, 'side-bar-is-open')

  const toggleSidebar = useCallback(() => {
    setSearchAutofocus(false)
    setIsOpen(!isOpen)
  }, [setSearchAutofocus, setIsOpen, isOpen])

  useEffect(() => {
    setGlobalControls({toggleSidebar, isSidebarOpen: () => isOpen})
  }, [isOpen, toggleSidebar])

  const render = useCallback(({props: response, error}: RendererProps) => {
    if (error) {
      logger.warn('error:', error)
      return null
    }

    const placeholder = <Placeholder isOpen={isOpen} toggleSidebar={toggleSidebar} />

    const organization = response?.organization
    if (!organization)
      return placeholder

    return (
      <Suspense fallback={placeholder}>
        <OrganizationContextProviderFragment organization={organization}>
          <SideBarView
            organization={organization}
            searchAutofocus={searchAutofocus}
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
          />
        </OrganizationContextProviderFragment>
      </Suspense>
    )
  }, [searchAutofocus, isOpen, toggleSidebar])

  const variables: Variables = {orgDatabaseId}
  return (
    <QueryRenderer
      environment={environment}
      fetchPolicy="store-and-network"
      query={sideBarQuery}
      variables={variables}
      render={render}
    />
  )
}

export default SideBar
