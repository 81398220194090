// @flow
import type {Node} from 'react'
import React from 'react'
import usePeriodicRerender from 'utils/hooks/usePeriodicRerender'
import {parseISO, isAfter} from 'date-fns'
import AddEventToCalendarButton from 'components/ui/AddEventToCalendarButton'
import styles from './index.scss'

type Props = $ReadOnly<{
  startsAt: string,
  endsAt: string,
  url: string,
}>

function AddToCalendarButton({startsAt: startsAtStr, endsAt: endsAtStr, url}: Props): null | Node {
  usePeriodicRerender()

  const currentDate = new Date()
  const startsAt = parseISO(startsAtStr)
  const endsAt = parseISO(endsAtStr)

  if (isAfter(currentDate, startsAt))
    return null

  return (
    <AddEventToCalendarButton
      label={I18n.t('holacracy_help.office_hours.add_to_calendar')}
      title={I18n.t('holacracy_help.office_hours.title')}
      startsAt={startsAt}
      endsAt={endsAt}
      location={url}
      size="sm"
      className={styles.button}
    />
  )
}

export default AddToCalendarButton
