/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ModularGrid_organization$ref = any;
type V4ConstitutionSection_organization$ref = any;
export type MigrationStatus = "COMPLETED" | "ERROR" | "NONE" | "REQUESTED" | "RUNNING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConstitutionTab_organization$ref: FragmentReference;
declare export opaque type ConstitutionTab_organization$fragmentType: ConstitutionTab_organization$ref;
export type ConstitutionTab_organization = {|
  +databaseId: string,
  +isOnV4: boolean,
  +isOnV5: boolean,
  +v4migrationStatus: MigrationStatus,
  +v5migrationStatus: MigrationStatus,
  +resourcePath: any,
  +viewerCanEditConstitution: boolean,
  +viewerCanViewConstitution: boolean,
  +anchorRole: {|
    +resourcePath: any
  |},
  +$fragmentRefs: V4ConstitutionSection_organization$ref & ModularGrid_organization$ref,
  +$refType: ConstitutionTab_organization$ref,
|};
export type ConstitutionTab_organization$data = ConstitutionTab_organization;
export type ConstitutionTab_organization$key = {
  +$data?: ConstitutionTab_organization$data,
  +$fragmentRefs: ConstitutionTab_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourcePath",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConstitutionTab_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnV4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnV5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v4migrationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v5migrationStatus",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanEditConstitution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanViewConstitution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "anchorRole",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "V4ConstitutionSection_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ModularGrid_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '503a9ad965c1f7d4a4ef7a2132e076de';

module.exports = node;
