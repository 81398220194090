import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const Project = GlassFrogModel.extend({
  urlRoot: '/api/v3/projects',
}, {
  resourceName: 'projects',
  defaults: {
  },
})

export default Project
