// @flow
import type {Node} from 'react'
import React, {Fragment, Suspense} from 'react'

import useToggle from 'utils/hooks/useToggle'
import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import ViewerMetricEditModal from 'components/metrics/ViewerMetricEditModal'

import PersonItem from 'components/pages/shared/abstractMetrics/PersonItem'

type Props = $ReadOnly<{
  item: Object,
  circle: Object,
}>

function PersonMetricItemWrapper({item, circle}: Props): Node {
  const [isEditModalOpen, {
    close: closeEditModal,
    open: openEditModal,
  }] = useToggle()

  const isRenderModal = item.viewerCanSeeEditIcon && !item.isGlobal

  return (
    <Fragment>
      {isRenderModal && isEditModalOpen && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <ViewerMetricEditModal
              close={closeEditModal}
              orgDatabaseId={circle.organization.databaseId}
              metricId={item.id}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
      <PersonItem
        item={item}
        circle={circle}
        onEdit={item.viewerCanSeeEditIcon ? openEditModal : null}
        disabled={item.isGlobal}
        tooltip={I18n.t('metrics.tip_editable_by_you')}
      />
    </Fragment>
  )
}

export default PersonMetricItemWrapper
