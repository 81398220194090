import _ from 'underscore'
import $ from 'jquery'
import Pusher from 'pusher-js'
import util from 'utils/MiscUtils'

function GlassFrogCommunication() {
  this.initialize.apply(this, arguments)
}

_.extend(GlassFrogCommunication.prototype, {
  initialize(opts) {
    this.env = (opts && opts.environment) || 'test'
    this.dispatcher = opts.dispatcher || util.error('dispatcher required')

    if (this.env == 'test') {
      this.disabled = true
    } else {
      this.appKey = (opts && opts.appKey) || util.error('appKey required')
      this.pusher = new Pusher(this.appKey, {
        authEndpoint: '/pusher/auth',
        disableStats: true,
      })
    }

    this.channels = {}
  },

  /*
   context: private
   name: Channel name
   @: some ID
   */
  subscribe(opts) {
    const channelName = this.channelName(opts)
    return this.subscribeChannel(channelName)
  },

  subscribeChannel(channelName) {
    if (this.disabled) {
      return {
        bind() {
        },
      }
    }

    if (!this.channels[channelName]) {
      const channel = this.pusher.subscribe(channelName)
      this.channels[channelName] = channel
      channel.bind('instruction_set', this.handleInstructionSet.bind(this))

      // If our connection to Pusher breaks, alert the user.
      this.pusher.connection.bind('state_change', (states) => {
        const state = states.current

        switch (state) {
          case 'connecting':
            // Wait for connection success or failure; don't do anything.
            break
          case 'connected':
            // eslint-disable-next-line no-console
            console.log('Websocket connection established.')
            break
          case 'disconnected':
            if (util.isFirefox()) {
              // See https://www.pivotaltracker.com/story/show/149453527
              // eslint-disable-next-line no-console
              console.log('Websocket disconnected.')
            } else {
              // eslint-disable-next-line no-console
              console.log(`Websocket connection state has changed to ${state}.`)
            }
            break
          default:
            // Our websocket connection to Pusher failed for some reason. Warn the user.
            // eslint-disable-next-line no-console
            console.log(`Websocket connection state has changed to ${state}.`)
        }
      })
    }

    return this.channels[channelName]
  },

  channelName(opts) {
    if (opts.id)
      return `${opts.prefix || 'private'}-${this.env}-${opts.name}@${opts.id}`
    return `${opts.prefix || 'private'}-${this.env}-${opts.name}`
  },

  handleInstructionSet(instructions) {
    instructions.forEach(this.handleInstruction.bind(this))
  },

  handleInstruction(instruction) {
    if (this.instructionIsHostChange(instruction) && gf.app.isGovernanceMeetingHost()) {
      this.dispatcher.dispatch({
        type: 'SWITCH_FROM_HOST_TO_OBSERVER',
        instruction,
      })
    } else {
      this.dispatcher.dispatch({
        type: 'APPLY_GENERIC_INSTRUCTION',
        instruction,
      })
    }
  },

  instructionIsHostChange(inst) {
    return inst.op == 'replace'
      && inst.path.match(/governance_meetings\/\d+\/links/)
      && inst.value
      && inst.value.current_host
  },
})

$(window).on('beforeunload', function () {
  // fixes this FF issue with sockets http://stackoverflow.com/questions/14140414/websocket-interrupted-while-page-is-loading-on-firefox-for-socket-io
  if (util.isFirefox() && this.gf.comm && this.gf.comm.pusher)
    this.gf.comm.pusher.disconnect()
})

export default GlassFrogCommunication
