// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {useFragment, graphql} from 'react-relay'

import MeetingBanner from '../MeetingBanner'
import type {
  TacticalMeetingBanner_circle$key as CircleKey,
} from './__generated__/TacticalMeetingBanner_circle.graphql'

type Props = {|
  circle: CircleKey,
  pathname: string,
|}

const circleFragment = graphql`
  fragment TacticalMeetingBanner_circle on Circle {
    localizedName
    viewerIsMember

    activeTacticalMeeting {
      checkinPath
      checklistsPath
      checkoutPath
      metricsPath
      projectsPath
      triagePath
      typeLabel

      ...MeetingBanner_meeting
    }

    ...MeetingBanner_circle
  }
`

function TacticalMeetingBanner({circle: circleKey, pathname}: Props): null | Node {
  const circle = useFragment(circleFragment, circleKey)
  const meeting = circle.activeTacticalMeeting

  const baseBannerCaption = I18n.t(
    'announcements.react.custom_meeting_started.content',
    {circle_name: circle.localizedName, type_label: meeting?.typeLabel},
  )

  const invitedCaption = I18n.t('announcements.react.tactical_meeting_started.invited')

  const bannerCaption = circle.viewerIsMember ? baseBannerCaption : `${invitedCaption} ${baseBannerCaption}`

  const meetingStartedCaption = I18n.t('announcements.react.tactical_meeting_started.action')
  const className = 'banner__tactical-meeting'

  const isOnMeetingPage = useCallback(() => {
    if (!meeting)
      return false

    const meetingTabs = [
      meeting.checkinPath,
      meeting.checklistsPath,
      meeting.checkoutPath,
      meeting.metricsPath,
      meeting.projectsPath,
      meeting.triagePath,
    ]
    return meetingTabs.includes(pathname) || pathname.includes('orgnav/tactical_meetings')
  }, [pathname, meeting])

  if (meeting) {
    return (
      <MeetingBanner
        className={className}
        meetingStartedCaption={meetingStartedCaption}
        bannerCaption={bannerCaption}
        circle={circle}
        meeting={meeting}
        isOnMeetingPage={isOnMeetingPage}
      />
    )
  }

  return null
}

export default TacticalMeetingBanner
