// @flow
import type {Element, Node} from 'react'
import React, {useMemo} from 'react'

import useRolesForAutocomplete from 'utils/hooks/useRolesForAutocomplete'
import MentionEngine from './MentionEngine'
import {roleAutocomplete} from './index.scss'

type Props = {
  children: Node,
  circleDatabaseId: string | null,
  onChange: SyntheticInputEvent<HTMLInputElement> => void,
  orgDatabaseId: string,
}

const toTributeItem = (items) => items.map((item) => ({
  key: item.name,
  value: item.linkText,
}))

function RoleAutocomplete({
  children,
  onChange,
  circleDatabaseId,
  orgDatabaseId,
}: Props): Element<"div"> {
  const state = useRolesForAutocomplete(orgDatabaseId, circleDatabaseId)

  const items = useMemo(() => (
    toTributeItem(state)
  ), [state])

  return (
    <div className={roleAutocomplete}>
      <MentionEngine
        onChange={onChange}
        items={items}
      >
        {children}
      </MentionEngine>
    </div>
  )
}

RoleAutocomplete.defaultProps = {
  circleDatabaseId: null,
}

export default RoleAutocomplete
