// @flow
import type {Node} from 'react'
import React, {useCallback, useState} from 'react'
import type {Props as InputProps} from 'components/ui/forms/Input'
import Input from 'components/ui/forms/Input'
// import logger from 'utils/MiscUtils/main'

import useAiSuggestInputWrapper from '../useAiSuggestInputWrapper'

type Props = $ReadOnly<{
  aiSuggestProps?: {
    context: {
      type: string,
      id: string,
    },
    promptKey: string,
    setValue?: string => void,
    data: Object,
  },
  isAiEnabled?: boolean,
  orgDatabaseId?: string,
  ...$Rest<InputProps, {}>,
}>

function InputWithAiSuggest({
  aiSuggestProps,
  value,
  onFocus,
  onBlur,
  isAiEnabled,
  orgDatabaseId,
  ...inputProps
}: Props): Node {
  const [shadowValue, setShadowValue] = useState(value)
  const setValue = aiSuggestProps?.setValue

  const useShadowValue = !setValue
  if (inputProps.onChange && aiSuggestProps && !setValue)
    throw new Error('onChange is not supported when setValue is not provided')

  const fwdSetValue = setValue || setShadowValue

  const fwdProps = aiSuggestProps ? {
    ...aiSuggestProps,
    setValue: fwdSetValue,
  } : aiSuggestProps

  const {
    renderBelowInput, wrappedOnFocus, wrappedOnBlur,
  } = useAiSuggestInputWrapper({
    value,
    onFocus,
    onBlur,
    isAiEnabled,
    orgDatabaseId,
    aiSuggestProps: fwdProps,
  })

  const captureShadowValue = useCallback((event) => {
    setShadowValue(event.target.value)
  }, [])

  return (
    <Input
      value={useShadowValue ? shadowValue : value}
      onChange={useShadowValue ? captureShadowValue : inputProps.onChange}
      onBlur={wrappedOnBlur}
      onFocus={wrappedOnFocus}
      renderBelowInput={renderBelowInput}
      autocomplete="off"
      {...inputProps}
    />
  )
}

export default InputWithAiSuggest
