// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './index.scss'

/* eslint-disable react/button-has-type */

type Props = $ReadOnly<{
  buttonText: string,
  icon: Node,
  onClick: SyntheticMouseEvent<HTMLButtonElement> => mixed,
  className: string,
  type: ?string,
  ...
}>

// <VisuallyHidden /> usage make
//    <IconButton buttonText="My awesome button" />
//  be equal to
//    <button>My awesome button</button>
//  for screen reader
function IconButton({icon, buttonText, className, onClick, type, ...props}: Props): Element<"button"> {
  return (
    <button
      {...props}
      type={type}
      title={buttonText}
      className={classNames(styles.container, className)}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

IconButton.defaultProps = {
  type: 'button',
}

export default IconButton
