/**
 * @flow
 * @relayHash 05a9d8718e0abdcc35ae142390dfd1aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateActionPositionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  actionId: string,
  position: number,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateActionPositionMutationVariables = {|
  input: UpdateActionPositionInput
|};
export type UpdateActionPositionMutationResponse = {|
  +updateActionPosition: ?{|
    +action: ?{|
      +project: ?{|
        +actions: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +position: ?number,
            |}
          |}>
        |}
      |}
    |}
  |}
|};
export type UpdateActionPositionMutation = {|
  variables: UpdateActionPositionMutationVariables,
  response: UpdateActionPositionMutationResponse,
|};
*/


/*
mutation UpdateActionPositionMutation(
  $input: UpdateActionPositionInput!
) {
  updateActionPosition(input: $input) {
    action {
      project {
        actions {
          edges {
            node {
              id
              position
            }
          }
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ActionConnection",
  "kind": "LinkedField",
  "name": "actions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Action",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateActionPositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActionPositionPayload",
        "kind": "LinkedField",
        "name": "updateActionPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateActionPositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActionPositionPayload",
        "kind": "LinkedField",
        "name": "updateActionPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "05a9d8718e0abdcc35ae142390dfd1aa",
    "metadata": {},
    "name": "UpdateActionPositionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '38e773aebe5e504d3f207128c320d9cb';

module.exports = node;
