// @flow
import type {Node} from 'react'
import React from 'react'
import {useToggle} from 'utils/hooks'
import LoadingDots from 'components/ui/LoadingDots'
import classNames from 'classnames'
import styles from './index.scss'

type Props = $ReadOnly<{
  color: 'primary' | 'secondary' | 'reset',
  children: string,
  onClick: ?(SyntheticMouseEvent<*>) => Object,
  className?: string,
  disabled?: boolean,
  confirmMsg?: ?string,
}>

function ButtonWithProcessing({color, children, disabled, onClick, className, confirmMsg}: Props): Node {
  const [isProcessing, {toggle}] = useToggle(false)

  const wrappedOnClick = (e: SyntheticMouseEvent<*>) => {
    /* eslint no-alert: 0 */
    const confirmSuccess = !confirmMsg || (confirmMsg && window.confirm(confirmMsg))

    if (onClick && confirmSuccess) {
      toggle()
      onClick(e)
    }
  }

  const buttonClass = classNames(className, styles.buttonClass)

  return (
    <button
      className={`btn btn-${color} ${buttonClass}`}
      disabled={disabled || isProcessing}
      onClick={wrappedOnClick}
      type="button"
    >
      <div className={styles.buttonInner}>
        <div className={styles.buttonText}>
          {children}
        </div>
        {isProcessing && <LoadingDots white sm />}
      </div>
    </button>
  )
}

export default ButtonWithProcessing
