/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistItem_item$ref: FragmentReference;
declare export opaque type ChecklistItem_item$fragmentType: ChecklistItem_item$ref;
export type ChecklistItem_item = {|
  +id: string,
  +databaseId: string,
  +description: string,
  +frequency: ?Frequency,
  +localizedFrequency: string,
  +link: ?any,
  +localizedRoleName: string,
  +isGlobal: boolean,
  +appliesToAllMembers: boolean,
  +viewerCanSeeEditIcon: boolean,
  +privateToCircle: boolean,
  +position: number,
  +viewerCanReorder: boolean,
  +organization: {|
    +id: string,
    +onRestrictedPlan: boolean,
  |},
  +customFrequency: ?{|
    +id: string,
    +name: string,
    +universalUnits: number,
  |},
  +people: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +name: string
      |}
    |}>
  |},
  +role: ?{|
    +id: string
  |},
  +$refType: ChecklistItem_item$ref,
|};
export type ChecklistItem_item$data = ChecklistItem_item;
export type ChecklistItem_item$key = {
  +$data?: ChecklistItem_item$data,
  +$fragmentRefs: ChecklistItem_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChecklistItem_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFrequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedRoleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliesToAllMembers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanSeeEditIcon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanReorder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onRestrictedPlan",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFrequency",
      "kind": "LinkedField",
      "name": "customFrequency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "universalUnits",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 30
        }
      ],
      "concreteType": "PersonConnection",
      "kind": "LinkedField",
      "name": "people",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "people(first:30)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ChecklistItem",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '080d2e620d3edecda7266ae72ae85c62';

module.exports = node;
