// @flow
import type {Element} from 'react'
import React from 'react'
import styles from './index.scss'

function SearchPlaceholder(): Element<"div"> {
  return (
    <div className={styles.search}>
      &nbsp;
    </div>
  )
}

export default SearchPlaceholder
