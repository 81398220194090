// @flow
import type {ComponentType, Node} from 'react'
import React, {Suspense, lazy, useCallback, useRef} from 'react'
import type {Props as WYSIWYGProps} from 'components/ui/forms/WYSIWYGTextarea'

import useAiSuggestInputWrapper from 'components/ai/AiSuggest/useAiSuggestInputWrapper'

import styles from './index.scss'

const WYSIWYGTextarea: ComponentType<*> | string = lazy(() => import(
  /* webpackChunkName: "WYSIWYGTextarea" */
  'components/ui/forms/WYSIWYGTextarea'
))

type Props<FallbackProps> = $ReadOnly<{
  name: string,
  label: string,
  forceAllow?: boolean,
  isWysiwygEnabled: boolean,
  fallbackProps?: FallbackProps | {},
  fallbackComponent: ComponentType<FallbackProps> | string,
  aiSuggestProps: {
    context: {
      type: string,
      id: string,
    },
    promptKey: string,
    setValue: string => void,
    data: Object,
  } | void,
  isAiEnabled?: boolean,
  organization: Object,
  orgDatabaseId?: string,
  ...$Rest<WYSIWYGProps, {}>
}>

function WYSIWYGTextareaWithAiSuggest<FallbackProps>({
  aiSuggestProps,
  value,
  onFocus,
  onBlur,
  onChange,
  isAiEnabled,
  isWysiwygEnabled,
  forceAllow,
  orgDatabaseId,
  name,
  label,
  fallbackComponent: FallbackComponent,
  fallbackProps = {},
  ...props
}: Props<FallbackProps>): Node {
  const fallbackSetValue = useCallback((val) => {
    onChange({target: {name, value: val}})
  }, [name, onChange])

  const setValue = aiSuggestProps?.setValue ? aiSuggestProps.setValue : fallbackSetValue
  const editorRef = useRef(null)

  const focus = useCallback(() => {
    if (editorRef.current)
      editorRef.current.focus()
  }, [])

  const {
    renderBelowInput, wrappedOnFocus, wrappedOnBlur,
    isSuggestVisible,
  } = useAiSuggestInputWrapper({
    aiSuggestProps: {
      context: (aiSuggestProps || {}).context,
      promptKey: (aiSuggestProps || {}).promptKey,
      setValue,
      data: aiSuggestProps?.data,
    },
    focus,
    value,
    onFocus,
    onBlur,
    isAiEnabled,
    orgDatabaseId,
  })

  const fallback = (() => (
    <FallbackComponent
      value={value}
      name={name}
      onChange={onChange}
      label={label}
      {...fallbackProps}
      {...props}
    />
  ))()

  if (!props.organization.isWysiwygEnabled && !forceAllow)
    return fallback

  return (
    <Suspense fallback={fallback}>
      <WYSIWYGTextarea
        value={value}
        name={name}
        label={label}
        onChange={onChange}
        onFocus={wrappedOnFocus}
        onBlur={wrappedOnBlur}
        renderBelowEditor={renderBelowInput}
        innerContainerClassName={isSuggestVisible ? styles.editorContainer : ''}
        innerRef={editorRef}
        {...props}
      />
    </Suspense>
  )
}

export default WYSIWYGTextareaWithAiSuggest
