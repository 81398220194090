// @flow
import React, {useLayoutEffect} from 'react'
import {createPortal} from 'react-dom'

import {useToggle} from 'utils/hooks'
import useUniqueId from 'utils/hooks/useUniqueId'
import LoadingDots from 'components/ui/LoadingDots'
import styles from './index.scss'

function ModalLoader(): any | null {
  const [canShow, {open}] = useToggle(false)
  const [id] = useUniqueId()

  useLayoutEffect(() => {
    const node = document.createElement('div')
    node.setAttribute('id', id)
    if (document.body)
      document.body.appendChild(node)
    open()

    return () => {
      if (document.body)
        document.body.removeChild(node)
    }
  }, [open, id])

  const domNode = document.getElementById(id)
  if (!canShow || !domNode)
    return null

  return createPortal(
    (
      <div className={styles.loader} test-id="modal-loader">
        <div className={styles.dots}>
          <LoadingDots lg />
        </div>
      </div>
    ),
    domNode,
  )
}

export default ModalLoader
