import React from 'react'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import {filter, compose, isNil, prop, not, head} from 'ramda'
import GupsMixin from 'legacy_support/GupsMixin'

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */

const BannerComponent = createReactClass({
  displayName: 'BannerComponent',

  mixins: [GupsMixin],

  onClick() {
    this.setState({hideClicked: true})
    const url = (
      `/org/${gf.app.currentOrganizationId()}/people/${gf.app.currentPersonId()
      }/hide_announcements`
    )
    $.ajax(url, {type: 'post'})
  },

  getDataMap() {
    return {
      notice: ['announcements', '*', {
        apply: compose(head, this.notNullShowAtFilter),
        fetch: false,
      }],
    }
  },

  getInitialLocalState() {
    return {
      hideClicked: false,
    }
  },

  wrongLocale() {
    return this.state.notice.locale
      ? I18n.locale !== this.state.notice.locale
      : false
  },

  notNullShowAtFilter: filter(compose(not, isNil, prop('show_at'))),

  render() {
    if (!this.state.notice || this.state.hideClicked)
      return null

    const audience = this.state.notice.audience

    if (audience === 'premium' && gf.app.orgOnRestrictedPlan())
      return null

    if (audience === 'free' && !gf.app.orgOnRestrictedPlan())
      return null

    if (audience === 'v5 organizations' && !gf.app.orgOnV5())
      return null

    if (this.wrongLocale())
      return null

    return (
      <div className="banner">
        <div
          dangerouslySetInnerHTML={{__html: this.state.notice.message}}
          className="banner__content test-banner-content"
        />
        <a href="#" onClick={this.onClick} className="banner__close test-banner-close">
          <i className="fa fa-close" />
        </a>
      </div>
    )
  },
})

export default BannerComponent
