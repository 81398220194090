/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleAccountabilitySelector_role$ref: FragmentReference;
declare export opaque type RoleAccountabilitySelector_role$fragmentType: RoleAccountabilitySelector_role$ref;
export type RoleAccountabilitySelector_role = {|
  +isLinked: boolean,
  +purpose: ?string,
  +accountabilities: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +description: string,
      |}
    |}>
  |},
  +$refType: RoleAccountabilitySelector_role$ref,
|};
export type RoleAccountabilitySelector_role$data = RoleAccountabilitySelector_role;
export type RoleAccountabilitySelector_role$key = {
  +$data?: RoleAccountabilitySelector_role$data,
  +$fragmentRefs: RoleAccountabilitySelector_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleAccountabilitySelector_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purpose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountabilityConnection",
      "kind": "LinkedField",
      "name": "accountabilities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountabilityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Accountability",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "format",
                      "value": "PLAIN"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": "description(format:\"PLAIN\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Role",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a17c8f6f5b34ef0c3dd27381be0effef';

module.exports = node;
