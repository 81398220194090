// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateOrganizationInput as Input,
  UpdateOrganizationMutationResponse as Response,
} from './__generated__/UpdateOrganizationMutation.graphql'

const mutation = graphql`
  mutation UpdateOrganizationMutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...ConstitutionTab_organization
      }
    }
  }
`

const UpdateOrganizationMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  return createMutationPromise({mutation, variables})
}

export default UpdateOrganizationMutation
