// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {Input, Response} from './types'

const mutation = graphql`
  mutation AddTensionMutation($input: AddTensionInput!) {
    addTension(input: $input) {
      tensionEdge {
        node {
          id
          databaseId

          agendaItems {
            edges {
              node {
                ...AgendaTensionEditForm_agendaItem
              }
            }
          }
        }
      }
    }
  }
`

export type {
  Input,
  Response,
}

export default (input: MutationInput<Input>, personId: ?string): Promise<Response> => {
  const variables = {input}

  const isAgendaItem = input.meetingType && input.roleId

  const configs = [
    personId && isAgendaItem && {
      type: 'RANGE_ADD',
      parentID: personId,
      edgeName: 'tensionEdge',
    },
    personId && !isAgendaItem && {
      type: 'RANGE_ADD',
      parentID: personId,
      connectionInfo: [{
        key: 'InboxNotifications_unprocessedTensions',
        rangeBehavior: 'append',
      }],
      edgeName: 'tensionEdge',
    },
  ].filter(Boolean)

  return createMutationPromise({
    mutation,
    variables,
    configs,
    onCompleted: (response, errors) => {
      if (errors)
        util.error('There was a problem', errors)
    },
  })
}
