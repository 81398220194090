/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateProjectModal_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileProjectsView_organization$ref: FragmentReference;
declare export opaque type ProfileProjectsView_organization$fragmentType: ProfileProjectsView_organization$ref;
export type ProfileProjectsView_organization = {|
  +databaseId: string,
  +projectsGridEnabled: boolean,
  +$fragmentRefs: CreateProjectModal_organization$ref,
  +$refType: ProfileProjectsView_organization$ref,
|};
export type ProfileProjectsView_organization$data = ProfileProjectsView_organization;
export type ProfileProjectsView_organization$key = {
  +$data?: ProfileProjectsView_organization$data,
  +$fragmentRefs: ProfileProjectsView_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileProjectsView_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectsGridEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateProjectModal_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '70a2f2f63ac9fd0eea59978e2330345e';

module.exports = node;
