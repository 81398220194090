// @flow
import type {Element} from 'react'
import React from 'react'

type Props = {
  organizationId: string,
}

function OrganizationHiddenInput({organizationId}: Props): Element<"input"> {
  return (
    <input
      id="organization_id"
      name="organization_id"
      type="hidden"
      value={organizationId}
    />
  )
}

export default OrganizationHiddenInput
