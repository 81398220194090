/**
 * @flow
 * @relayHash a75358bec7f8f0ae21657af6dba10989
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganizationContextProviderFragment_organization$ref = any;
type ProcessingModals_organization$ref = any;
type ProcessingModals_tension$ref = any;
type ProcessingModals_viewer$ref = any;
export type TensionProcessContainerQueryVariables = {|
  orgDatabaseId: string,
  tensionDatabaseId: string,
|};
export type TensionProcessContainerQueryResponse = {|
  +organization: ?{|
    +tension: ?{|
      +$fragmentRefs: ProcessingModals_tension$ref
    |},
    +viewer: {|
      +$fragmentRefs: ProcessingModals_viewer$ref
    |},
    +$fragmentRefs: ProcessingModals_organization$ref & OrganizationContextProviderFragment_organization$ref,
  |}
|};
export type TensionProcessContainerQuery = {|
  variables: TensionProcessContainerQueryVariables,
  response: TensionProcessContainerQueryResponse,
|};
*/


/*
query TensionProcessContainerQuery(
  $orgDatabaseId: String!
  $tensionDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...ProcessingModals_organization
    ...OrganizationContextProviderFragment_organization
    tension(databaseId: $tensionDatabaseId) {
      ...ProcessingModals_tension
      id
    }
    viewer {
      ...ProcessingModals_viewer
      id
    }
    id
  }
}

fragment ActionFormModalView_tension on Tension {
  ...UpdateTensionInlineBox_tension
  ...TensionInfo_tension
}

fragment ActionProcessModal_tension on Tension {
  body
  circle {
    id
  }
  impactedRole {
    id
    circle {
      id
    }
  }
  ...CreateActionModal_tension
}

fragment ActionProcessModal_viewer on Person {
  ...CreateActionModal_viewer
}

fragment AgendaProcessModal_tension on Tension {
  databaseId
  organization {
    databaseId
    id
  }
}

fragment CircleAndRoleSelector_viewer on Person {
  circles(first: 100) {
    edges {
      node {
        id
        supportedRole {
          id
          isDisplayedAsCircle
          ...RoleSelector_roles
        }
      }
    }
  }
  ...PersonRoleSelector_person
  ...PersonCircleSelector_person
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment CreateActionModal_tension on Tension {
  id
  ...ActionFormModalView_tension
}

fragment CreateActionModal_viewer on Person {
  id
}

fragment CreateProjectModal_circle on Circle {
  ...ProjectFormModalFragmented_circle
}

fragment CreateProjectModal_organization on Organization {
  ...ProjectFormModalFragmented_organization
}

fragment CreateProjectModal_tension on Tension {
  id
  ...ProjectFormModalFragmented_tension
}

fragment OrganizationContextProviderFragment_organization on Organization {
  id
  databaseId
  name
  isWysiwygEnabled
  onRestrictedPlan
  isOnV5
  isOnV4
  isROIEnabled
  isAiEnabled
  showChecklists: featureEnabled(name: "checklists")
  showHistory: featureEnabled(name: "history")
  showMetrics: featureEnabled(name: "metrics")
  showNotes: featureEnabled(name: "notes")
  showPolicies: featureEnabled(name: "policies")
  showProjects: featureEnabled(name: "projects")
  checklistsLabel: termFor(name: "checklists")
  historyLabel: termFor(name: "history")
  metricsLabel: termFor(name: "metrics")
  notesLabel: termFor(name: "notes")
  policiesLabel: termFor(name: "policies")
  projectsLabel: termFor(name: "projects")
}

fragment PeopleSelector_people on Person {
  id
  databaseId
  name
}

fragment PersonCircleSelector_person on Person {
  circles(first: 100) {
    edges {
      node {
        ...CircleSelector_circles
        id
      }
    }
  }
}

fragment PersonRoleSelector_person on Person {
  roles {
    edges {
      node {
        circle {
          supportedRole {
            isDisplayedAsCircle
            id
          }
          id
        }
        ...RoleSelector_roles_4EQjyB
        id
      }
    }
  }
}

fragment ProcessingModals_organization on Organization {
  databaseId
  ...CreateProjectModal_organization
  ...ProjectProcessModal_organization
}

fragment ProcessingModals_tension on Tension {
  databaseId
  proposal {
    databaseId
    id
  }
  ...TensionProcessModal_tension
  ...ActionProcessModal_tension
  ...ProjectProcessModal_tension
  ...AgendaProcessModal_tension
}

fragment ProcessingModals_viewer on Person {
  databaseId
  ...ActionProcessModal_viewer
}

fragment ProjectEditForm_circle on Circle {
  ...ProjectOwnerSelector_circle
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectEditForm_organization on Organization {
  databaseId
  isGoalsEnabled
  isROIEnabled
  supportableGoalsAndTargets
  onRestrictedPlan
  showAccountabilities: featureEnabled(name: "accountabilities")
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment ProjectEditForm_viewer on Person {
  ...ProjectOwnerSelector_viewer
  roles {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectFormModalFragmented_circle on Circle {
  ...ProjectFormModalView_circle
}

fragment ProjectFormModalFragmented_organization on Organization {
  viewer {
    ...ProjectFormModalView_viewer
    id
  }
  ...ProjectFormModalView_organization
}

fragment ProjectFormModalFragmented_tension on Tension {
  ...ProjectFormModalView_tension
}

fragment ProjectFormModalView_circle on Circle {
  ...ProjectEditForm_circle
}

fragment ProjectFormModalView_organization on Organization {
  ...ProjectEditForm_organization
}

fragment ProjectFormModalView_tension on Tension {
  ...UpdateTensionInlineBox_tension
  ...TensionInfo_tension
}

fragment ProjectFormModalView_viewer on Person {
  ...ProjectEditForm_viewer
  ...UpdateTensionInlineBox_viewer
}

fragment ProjectOwnerSelector_circle on Circle {
  ...RoleAndPersonSelector_circle
}

fragment ProjectOwnerSelector_viewer on Person {
  ...CircleAndRoleSelector_viewer
}

fragment ProjectProcessModal_organization on Organization {
  ...CreateProjectModal_organization
}

fragment ProjectProcessModal_tension on Tension {
  id
  databaseId
  body
  circle {
    id
    databaseId
    ...CreateProjectModal_circle
  }
  person {
    id
  }
  impactedRole {
    id
    circle {
      id
      databaseId
      ...CreateProjectModal_circle
    }
  }
  ...CreateProjectModal_tension
}

fragment RoleAccountabilitySelector_role on Role {
  isLinked
  purpose
  accountabilities {
    edges {
      node {
        id
        description(format: PLAIN)
      }
    }
  }
}

fragment RoleAndPersonSelector_circle on Circle {
  individualAction {
    id
  }
  supportedRole {
    id
    isExpanded
    assignments {
      edges {
        node {
          person {
            id
            ...PeopleSelector_people
          }
          id
        }
      }
    }
    ...RoleSelector_roles
  }
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        isIndividualAction
        assignments {
          edges {
            node {
              person {
                id
                ...PeopleSelector_people
              }
              id
            }
          }
        }
        ...RoleSelector_roles
      }
    }
  }
  members {
    edges {
      node {
        id
        ...PeopleSelector_people
      }
    }
  }
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment TensionEditForm_organization on Organization {
  databaseId
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment TensionEditForm_viewer on Person {
  ...PersonRoleSelector_person
}

fragment TensionGuide_tension on Tension {
  id
  databaseId
  impactedRole {
    governanceEnabled
    id
  }
}

fragment TensionGuide_viewer on Person {
  id
  hasCirclesWithGovernanceOn
}

fragment TensionInfo_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment TensionInfo_tension on Tension {
  createdAt
  body
  agendaLabel
  impactedRole {
    localizedName
    id
  }
  person {
    id
    name
    isActive
  }
  organization {
    ...TensionInfo_organization
    id
  }
}

fragment TensionProcessModal_tension on Tension {
  ...TensionGuide_tension
  ...TensionInfo_tension
  organization {
    viewer {
      ...TensionGuide_viewer
      id
    }
    id
  }
}

fragment UpdateTensionInlineBox_tension on Tension {
  id
  databaseId
  body
  organization {
    databaseId
    ...TensionEditForm_organization
    id
  }
  impactedRole {
    id
  }
}

fragment UpdateTensionInlineBox_viewer on Person {
  databaseId
  ...TensionEditForm_viewer
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tensionDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "tensionDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFocus",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "governanceEnabled",
  "storageKey": null
},
v8 = {
  "alias": "nameWithMode",
  "args": [
    {
      "kind": "Literal",
      "name": "with",
      "value": "CIRCLE_FOR_CORE_ROLES"
    }
  ],
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinked",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purpose",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountabilityConnection",
  "kind": "LinkedField",
  "name": "accountabilities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountabilityEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Accountability",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "format",
                  "value": "PLAIN"
                }
              ],
              "kind": "ScalarField",
              "name": "description",
              "storageKey": "description(format:\"PLAIN\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "checklists"
  }
],
v17 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "history"
  }
],
v18 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "metrics"
  }
],
v19 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "notes"
  }
],
v20 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "policies"
  }
],
v21 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "projects"
  }
],
v22 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v15/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": (v22/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "individualAction",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isExpanded",
          "storageKey": null
        },
        (v23/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v3/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeFocusRoles",
          "value": true
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isIndividualAction",
                  "storageKey": null
                },
                (v23/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v3/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(includeFocusRoles:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v22/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TensionProcessContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProcessingModals_tension"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProcessingModals_viewer"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProcessingModals_organization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationContextProviderFragment_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TensionProcessContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "supportedRole",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v3/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGovernanceEnabled",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "circles(first:100)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v4/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": "nameWithMode",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "with",
                                "value": "CIRCLE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": "localizedName(with:\"CIRCLE\")"
                          },
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGoalsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isROIEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "supportableGoalsAndTargets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": "showAccountabilities",
            "args": [
              {
                "kind": "Literal",
                "name": "name",
                "value": "accountabilities"
              }
            ],
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"accountabilities\")"
          },
          (v14/*: any*/),
          (v4/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV5",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV4",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAiEnabled",
            "storageKey": null
          },
          {
            "alias": "showChecklists",
            "args": (v16/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"checklists\")"
          },
          {
            "alias": "showHistory",
            "args": (v17/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"history\")"
          },
          {
            "alias": "showMetrics",
            "args": (v18/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"metrics\")"
          },
          {
            "alias": "showNotes",
            "args": (v19/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"notes\")"
          },
          {
            "alias": "showPolicies",
            "args": (v20/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"policies\")"
          },
          {
            "alias": "showProjects",
            "args": (v21/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"projects\")"
          },
          {
            "alias": "checklistsLabel",
            "args": (v16/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"checklists\")"
          },
          {
            "alias": "historyLabel",
            "args": (v17/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"history\")"
          },
          {
            "alias": "metricsLabel",
            "args": (v18/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"metrics\")"
          },
          {
            "alias": "notesLabel",
            "args": (v19/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"notes\")"
          },
          {
            "alias": "policiesLabel",
            "args": (v20/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"policies\")"
          },
          {
            "alias": "projectsLabel",
            "args": (v21/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"projects\")"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Proposal",
                "kind": "LinkedField",
                "name": "proposal",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "impactedRole",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "agendaLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasCirclesWithGovernanceOn",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a75358bec7f8f0ae21657af6dba10989",
    "metadata": {},
    "name": "TensionProcessContainerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e0d6aabd7ad7ccd53011980553a7134';

module.exports = node;
