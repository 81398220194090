// @flow strict
import {useState, useCallback} from 'react'

type ReturnValue = [
  boolean,
  {|
    open: () => void,
    close: () => void,
    toggle: () => void,
  |},
]
export default function useToggle(initialIsOpen: boolean = false): ReturnValue {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  const open = useCallback(() => setIsOpen(true), [setIsOpen])

  const controls = {toggle, close, open}

  return [isOpen, controls]
}
