// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'

import environment from 'environment'
import Preload from 'components/ui/Preload'
import ProfileProjects from './ProfileProjects'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

function ProjectsTab({orgDatabaseId, personDatabaseId} : Props): Node {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<Preload />}>
        <ProfileProjects
          orgDatabaseId={orgDatabaseId}
          personDatabaseId={personDatabaseId}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default ProjectsTab
