/**
 * @flow
 * @relayHash e885514161a5afd718b51ae54472578e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditRoleTemplateModalView_roleTemplate$ref = any;
export type UpdateRoleTemplateInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  roleTemplateId: string,
  name?: ?string,
  purpose?: ?string,
  domains?: ?$ReadOnlyArray<string>,
  accountabilities?: ?$ReadOnlyArray<string>,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateRoleTemplateMutationVariables = {|
  input: UpdateRoleTemplateInput
|};
export type UpdateRoleTemplateMutationResponse = {|
  +updateRoleTemplate: ?{|
    +roleTemplate: ?{|
      +$fragmentRefs: EditRoleTemplateModalView_roleTemplate$ref
    |}
  |}
|};
export type UpdateRoleTemplateMutation = {|
  variables: UpdateRoleTemplateMutationVariables,
  response: UpdateRoleTemplateMutationResponse,
|};
*/


/*
mutation UpdateRoleTemplateMutation(
  $input: UpdateRoleTemplateInput!
) {
  updateRoleTemplate(input: $input) {
    roleTemplate {
      ...EditRoleTemplateModalView_roleTemplate
      id
    }
  }
}

fragment EditRoleTemplateModalView_roleTemplate on RoleTemplate {
  id
  name
  purpose
  domains
  accountabilities
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRoleTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRoleTemplatePayload",
        "kind": "LinkedField",
        "name": "updateRoleTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTemplate",
            "kind": "LinkedField",
            "name": "roleTemplate",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditRoleTemplateModalView_roleTemplate"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRoleTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRoleTemplatePayload",
        "kind": "LinkedField",
        "name": "updateRoleTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTemplate",
            "kind": "LinkedField",
            "name": "roleTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purpose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domains",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountabilities",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "e885514161a5afd718b51ae54472578e",
    "metadata": {},
    "name": "UpdateRoleTemplateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9d553e88910296167b3c77544da84ef';

module.exports = node;
