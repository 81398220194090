// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import classNames from 'classnames'

import AppLink from 'components/ui/AppLink'
import usePersistentState from 'utils/hooks/usePersistentState'
import ArrowRoundForward from 'components/ui/icons/ios/ArrowRoundForward'
import CloseButton from '../../../../CloseButton'
import type {
  MeetingBanner_circle as Circle,
} from './__generated__/MeetingBanner_circle.graphql'
import type {
  MeetingBanner_meeting as Meeting,
} from './__generated__/MeetingBanner_meeting.graphql'

type Props = {|
  circle: Circle,
  meeting: Meeting,
  isOnMeetingPage: (string) => boolean,
  bannerCaption: string,
  meetingStartedCaption: string,
  className: string,
|}

function MeetingBanner({circle, meeting, isOnMeetingPage, bannerCaption, meetingStartedCaption, className}
  : Props): Node {
  const [dismissedMeetingId, setDismissedMeetingId] = usePersistentState<?string>(
    null,
    `dismissed_${circle.databaseId}_circle_${meeting.type}_meeting`,
  )

  const notOnMeetingPage = !isOnMeetingPage(meeting.joinMeetingPath)
  const notDismissed = meeting.id !== dismissedMeetingId

  const canShow = notOnMeetingPage && notDismissed

  const dismiss = useCallback(() => {
    if (meeting)
      setDismissedMeetingId(meeting.id)
  }, [setDismissedMeetingId, meeting])

  const containerClassName = classNames('banner', className)

  if (canShow) {
    return (
      <div
        aria-labelledby="banner-label"
        className={containerClassName}
        role="region"
      >
        <div
          className="banner__content test-banner-content"
          id="banner-label"
        >
          {bannerCaption}
          &nbsp;
          <AppLink
            className="banner__content-reaction"
            href={meeting.joinMeetingPath}
          >
            {meetingStartedCaption}
            <ArrowRoundForward />
          </AppLink>
        </div>
        <CloseButton onClick={dismiss} />
      </div>
    )
  }

  return null
}

export default (createFragmentContainer(MeetingBanner, {
  circle: graphql`
    fragment MeetingBanner_circle on Circle {
      databaseId
    }
  `,
  meeting: graphql`
    fragment MeetingBanner_meeting on Meeting {
      id
      joinMeetingPath
      type
    }
  `,
}): RelayFragmentContainer<typeof(MeetingBanner)>)
