// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import CombinedMeetingEventSubscription from 'subscriptions/CombinedMeetingEventSubscription'
import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import Preload from 'components/ui/Preload'
import TacticalMeetingTriageView from './TacticalMeetingTriageView'
import type {
  TacticalMeetingTriageQuery as Query,
  TacticalMeetingTriageQueryVariables as Variables,
} from './__generated__/TacticalMeetingTriageQuery.graphql'

const query = graphql`
  query TacticalMeetingTriageQuery($meetingDatabaseId: String!, $orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      meeting(databaseId: $meetingDatabaseId) {
        databaseId

        ...TacticalMeetingTriageView_meeting
      }
    }
  }
`

function TacticalMeetingTriage(props: Variables): Node {
  const [{organization}, refetchQuery] = useRefetchableLazyLoadQuery<Query>(query, props)

  useSubscriptionRefetch(
    CombinedMeetingEventSubscription,
    {meetingDatabaseId: props.meetingDatabaseId},
    refetchQuery,
    {},
    (data) => (
      data.combinedMeetingEvent?.type === 'InviteesUpdatedEvent'
    ),
  )

  const meeting = organization?.meeting

  if (!meeting)
    return <Preload />

  return (
    <TacticalMeetingTriageView meetingKey={meeting} refetchQuery={refetchQuery} />
  )
}

export default TacticalMeetingTriage
