// @flow
import React, {forwardRef, useEffect} from 'react'
import {graphql, useFragment} from 'react-relay'

import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import useToggle from 'utils/hooks/useToggle'
import TensionInfo from 'components/tensions/TensionInfo'
import NoneTensionInfo from 'components/tensions/ui/NoneTensionInfo'
import CombinedMeetingEventSubscription from 'subscriptions/CombinedMeetingEventSubscription'
import WaitingOnAgendaItemMsg from './WaitingOnAgendaItemMsg'
import AgendaTensionEditForm from './AgendaTensionEditForm'

import type {
  TacticalMeetingTension_meeting$key as MeetingKey,
} from './__generated__/TacticalMeetingTension_meeting.graphql'

type Props = $ReadOnly<{
  meetingKey: MeetingKey,
  refetchQuery: () => Promise<null>,
}>

const meetingFragment = graphql`
    fragment TacticalMeetingTension_meeting on Meeting {
      databaseId

      organization {
        databaseId

        viewer {
          ...TensionInfo_viewer
        }
      }
      
      currentAgendaItem {
        id
        viewerCanCreateTension

        tension {
          body
          viewerCanUpdate
          ...TensionInfo_tension
        }

        ...AgendaTensionEditForm_agendaItem

      }
      
      ...AgendaTensionEditForm_meeting
    }
  `

const TacticalMeetingTension: React$AbstractComponent<Props, mixed> = forwardRef(({
  meetingKey,
  refetchQuery,
}: Props, ref) => {
  const meeting = useFragment(meetingFragment, meetingKey)

  useSubscriptionRefetch(
    CombinedMeetingEventSubscription,
    {meetingDatabaseId: meeting.databaseId},
    refetchQuery,
    {},
    (data) => (
      ['CurrentAgendaItemChanged', 'AgendaItemUpdated'].includes(data.combinedMeetingEvent?.type)
    ),
  )

  const [
    isFormOpen,
    {
      open: openForm,
      close: closeForm,
    },
  ] = useToggle()

  const currentAgendaItem = meeting.currentAgendaItem

  useEffect(() => {
    closeForm()
  }, [currentAgendaItem?.id, closeForm])

  const canCreateTension = !currentAgendaItem?.tension
    && currentAgendaItem?.viewerCanCreateTension

  const canUpdateTension = currentAgendaItem?.tension
    && currentAgendaItem.tension.viewerCanUpdate

  const openFormWithGate = canCreateTension || canUpdateTension
    ? openForm
    : null

  if (!currentAgendaItem)
    return <WaitingOnAgendaItemMsg />

  const renderForm = (close) => (
    <AgendaTensionEditForm
      key={currentAgendaItem.id}
      ref={ref}
      close={close}
      agendaItem={currentAgendaItem}
      meeting={meeting}
    />
  )

  if (isFormOpen)
    return renderForm(closeForm)

  if (!currentAgendaItem.tension || !currentAgendaItem.tension.body)
    return <NoneTensionInfo marginBottom="NONE" openForm={openFormWithGate} />

  return (
    <TensionInfo
      key={currentAgendaItem.id}
      marginBottom="NONE"
      tension={currentAgendaItem.tension}
      viewer={meeting.organization.viewer}
      renderTensionForm={canUpdateTension ? renderForm : null}
    />
  )
})

export default TacticalMeetingTension
