// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import frogBoxStyles from 'components/ui/FrogTipBox/index.scss'
import Header from './Header'
import Content from './Content'
import {noteCard, noteCardNoBorder, noteCardNoPadding, content} from './index.scss'

type Props = {
  children: Node,
  noBorder: boolean,
  noPadding: boolean,
  'test-id': ?string,
  editButton?: Node,
}

function NoteCard({children, noBorder, noPadding, 'test-id': testId, editButton}
  : Props): Element<"div"> {
  const noteCardClass = classNames(noteCard, {
    [noteCardNoBorder]: noBorder,
    [noteCardNoPadding]: noPadding,
  })

  return (
    <div test-id={testId} className={noteCardClass}>
      <div className={content}>
        {children}
      </div>
      {editButton && (
        <div className={frogBoxStyles.editButton}>
          {editButton}
        </div>
      )}
    </div>
  )
}

NoteCard.defaultProps = {
  noBorder: false,
  noPadding: false,
  'test-id': null,
}

export default NoteCard
export {Header, Content}
