/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Metric_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MetricsSection_circle$ref: FragmentReference;
declare export opaque type MetricsSection_circle$fragmentType: MetricsSection_circle$ref;
export type MetricsSection_circle = {|
  +$fragmentRefs: Metric_circle$ref,
  +$refType: MetricsSection_circle$ref,
|};
export type MetricsSection_circle$data = MetricsSection_circle;
export type MetricsSection_circle$key = {
  +$data?: MetricsSection_circle$data,
  +$fragmentRefs: MetricsSection_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsSection_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Metric_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'aaac477cf100bcc6f9667afbcd008956';

module.exports = node;
