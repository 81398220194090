// @flow
import type {Event} from '../types'

const ics = require('ics')

function formatDatetime(datetime) {
  return [
    datetime.getUTCFullYear(),
    datetime.getUTCMonth() + 1,
    datetime.getUTCDate(),
    datetime.getUTCHours(),
    datetime.getUTCMinutes(),
  ]
}

async function generateIcsFile(event: Event): Promise<any> {
  const eventData = {
    start: formatDatetime(event.startDatetime),
    startInputType: 'utc',
    end: formatDatetime(event.endDatetime),
    endInputType: 'utc',
    title: event.title,
    description: event.description || '',
    location: event.location || '',
    url: (event.location || '').trim(),
  }

  return new Promise((resolve, reject) => {
    ics.createEvent(eventData, (error, value) => {
      if (error) {
        reject(error)
        return
      }

      resolve(value)
    })
  })
}

export default generateIcsFile
