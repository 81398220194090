// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import classNames from 'classnames'
import getIDGenerator from 'utils/getIDGenerator'
import type {Option} from 'components/types'
import InputContainer from '../InputContainer'
import styles from './index.scss'

type Props = $ReadOnly<{
  name: string,
  options: $ReadOnlyArray<Option>,
  value: string | null,
  onChange: (SyntheticInputEvent<HTMLInputElement>, string) => void,
  onBlur: (SyntheticFocusEvent<HTMLInputElement>) => void,
  warning: string | null,
  disabled: boolean,
}>

const labelClassName = (value: string | null, option: Option, isValid: boolean) => (
  classNames(styles.label, {
    [styles.warning]: option.value === value && !isValid,
  })
)

const randomGenerator = getIDGenerator()

/* eslint jsx-a11y/label-has-for:0 */
function RadioButton({name, options, value, onChange, onBlur, warning, disabled}: Props): Node {
  const handleChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    onChange(event, newValue)
  }, [onChange])

  const isValid = warning === null

  const renderOption = useCallback((option) => {
    const inputId = `radio-button-${randomGenerator()}`

    return (
      <label className={styles.item} key={option.value} htmlFor={inputId}>
        <input
          disabled={option.disabled || disabled}
          className={styles.input}
          type="radio"
          name={name}
          id={inputId}
          value={option.value}
          checked={value === option.value}
          onChange={handleChange}
          onBlur={onBlur}
          test-id={option.testId}
        />
        <span className={labelClassName(value, option, isValid)}>{option.label}</span>
      </label>
    )
  }, [disabled, handleChange, isValid, name, onBlur, value])

  return (
    <InputContainer>
      <div className={styles.items}>
        {options.map((option) => renderOption(option))}
      </div>
    </InputContainer>
  )
}

RadioButton.defaultProps = {
  onBlur: () => {},
  disabled: false,
  warning: null,
}

export default RadioButton
