import ReactDOM from 'react-dom'
import $ from 'jquery'
import _ from 'underscore'
import util from 'utils/MiscUtils'

import GlassFrogCommunication from './GlassFrogCommunication'
import ApiAdapter from './ApiAdapter'
import ApiFetcher from './ApiFetcher'
import GlassFrogStore from './GlassFrogStore'
import Dispatcher from './Dispatcher'

function GlassFrogApp() {
  GlassFrogApp.prototype.initialize.apply(this, arguments)
}

_.extend(GlassFrogApp.prototype, {
  config: {},
  _controllers: {},

  initialize(config) {
    _.each(config, $.proxy(function (val, key) {
      this[key] = val
    }, this))
    this._reservedIds = {}

    this.apiAdapter = new ApiAdapter()
    this.dispatcher = new Dispatcher({app: this})

    this.comm = new GlassFrogCommunication({
      appKey: this.pusherKey,
      environment: this.pusherEnv,
      dispatcher: this.dispatcher,
    })
    gf.comm = this.comm
    gf.comm.subscribe({name: 'organization', id: this.current_organization_id})
    gf.comm.subscribe({name: 'general'})

    this.store = new GlassFrogStore({
      apiAdapter: this.apiAdapter,
      dispatcher: this.dispatcher,
    })

    this.apiAdapter.updateConfig({
      defaultErrorHandler: function (reqRes) {
        if (reqRes.response.processed) {
          util.warn('response already processed', reqRes)
          return reqRes
        }

        const action = _.extend({
          type: 'DEFAULT_SERVER_ERROR',
        }, reqRes)
        this.dispatcher.dispatch(action)
        reqRes.response.processed = true
      }.bind(this),
    })

    this.apiFetcher = new ApiFetcher({
      apiAdapter: this.apiAdapter,
      store: this.store,
    })

    gf.db = this.store
    this.setupComm()
  },

  apiV3Path(path) {
    return `/api/v3/org/${this.currentOrganizationId()}${path}`
  },

  updateConfig(config) {
    _.each(config, $.proxy(function (val, key) {
      this[key] = val
    }, this))

    // FIXME: is there a timing reason for this to be here instead of initialize?
    $.ajaxSetup({
      headers: {
        'X-GF-REQUESTED-LOCALE': I18n.locale,
      },
    })
    this.setupComm()
  },

  setupComm() {
    if (!this._commSubscribed && this.currentCircleId() !== -1) {
      this.comm.subscribe({name: 'circle', id: this.currentCircleId()})
      this._commSubscribed = true
    }
  },

  currentGovernanceMeetingHost() {
    return gf.db.getData('governance_meetings', this.currentGovernanceMeetingId(), 'currentHostId')
  },

  isGovernanceMeetingHost() {
    return this.currentGovernanceMeetingHost() == this.currentPersonId()
  },
  isAsyncProposalsEnabled() {
    return this.async_proposals_enabled || false
  },
  currentPersonIsAdmin() {
    return this.current_person_is_admin || false
  },
  currentOrganizationId() {
    return this.current_organization_id || -1
  },
  currentOrganizationGid() {
    return this.current_organization_gid // GraphQL ID
  },
  currentGovernanceMeetingId() {
    return this.current_governance_meeting_id || -1
  },
  currentPersonId() {
    return this.current_person_id || -1
  },
  orgOnRestrictedPlan() {
    return !!this.org_on_restricted_plan
  },
  orgOnV5() {
    return !!this.org_on_v5
  },
  currentCircleId() {
    return -1
  },
  throttled() {
    return !!this.throttleEnabled
  },
  trueCurrentPersonId() {
    return this.true_current_person_id || -1
  },

  // To make sure controllers are only used once per page and not reused as components,
  // the element_id will be assumed to be a string of the DOM element ID
  initController(ControllerClass, elementId, params = []) {
    const elem = document.getElementById(elementId)
    if (elem)
      this._controllers[elementId] = new ControllerClass(_.extend(params, {el: $(`#${elementId}`)[0]}))
    else
      throw new Error(`Element ${elementId} not found while initializing ${ControllerClass}`)

    return this._controllers[elementId]
  },
  getController(elementId) {
    return this._controllers[elementId]
  },
  teardownController() {
    let controller
    let id
    if (_.isString(arguments[0])) {
      id = arguments[0]
      controller = this._controllers[id]
    }
    if (controller) {
      controller.teardown()
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(controller)
      ReactDOM.unmountComponentAtNode(node)
    }
    delete this._controllers[id]
  },
  teardown() {

  },

  requestReserveIdBlocks(opts) {
    opts = _.defaults(opts || {}, {
      blocksize: 5,
      klasses: ['role', 'policy', 'domain', 'accountability'],
    })

    const url = (
      `/org/${gf.app.currentOrganizationId()}/proposals/reserve_ids?classes=${
        opts.klasses.join(',')}&blocksize=${opts.blocksize}`
    )

    return $.ajax({type: 'get', url})
      .done(_.partial(util.mergeBuckets, this._reservedIds))
  },
})

export default GlassFrogApp
