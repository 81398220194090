// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'

import Checkmark from 'components/ui/icons/ios/Checkmark'
import type {KeyboardCallback} from 'components/proposals/types'
import styles from './index.scss'

type Props = {
  checked: boolean,
  disabled: boolean,
  id: ?string,
  label: ?string,
  onChange: boolean => void,
  onKeyPress?: ?KeyboardCallback,
  tabIndex: number,
}

/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
function Checkbox({id, checked, disabled, label, onChange, onKeyPress, tabIndex}: Props): Element<"label"> {
  const className = classNames(styles.checkbox, {
    [styles.checkboxChecked]: checked,
    [styles.checkboxDisabled]: disabled,
  })

  return (
    <label
      className={className}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      htmlFor={id}
    >
      <input
        id={id}
        checked={checked}
        className={styles.checkboxInput}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        readOnly={disabled}
        type="checkbox"
      />
      <div className={styles.checkboxIcon}>
        <Checkmark />
      </div>
      {label && (
        <div className={styles.checkboxTitle}>{label}</div>
      )}
    </label>
  )
}

Checkbox.defaultProps = {
  disabled: false,
  id: null,
  label: null,
  onKeyPress: null,
  tabIndex: -1,
}

export default Checkbox
