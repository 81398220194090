// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import {Card} from 'components/ui/Accordion'
import EditAmendmentBody from './EditAmendmentBody'
import type {
  Section_amendment as Amendment,
} from './__generated__/Section_amendment.graphql'

type Props = $ReadOnly<{
  amendment: Amendment,
  disabled: boolean,
}>

function Section({amendment, disabled}: Props): Node {
  const amendmentId = amendment.id

  return (
    <Card
      test-id={amendmentId}
      index={amendmentId}
      disabled={disabled}
    >
      <EditAmendmentBody amendment={amendment} disabled={disabled} />
    </Card>
  )
}

export default (createFragmentContainer(Section, {
  amendment: graphql`
    fragment Section_amendment on Amendment {
      id

      ...EditAmendmentBody_amendment
    }
  `,
}): RelayFragmentContainer<typeof(Section)>)
