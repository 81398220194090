/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateProjectModal_circle$ref = any;
type CreateProjectModal_tension$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectProcessModal_tension$ref: FragmentReference;
declare export opaque type ProjectProcessModal_tension$fragmentType: ProjectProcessModal_tension$ref;
export type ProjectProcessModal_tension = {|
  +id: string,
  +databaseId: string,
  +body: string,
  +circle: ?{|
    +id: string,
    +databaseId: string,
    +$fragmentRefs: CreateProjectModal_circle$ref,
  |},
  +person: ?{|
    +id: string
  |},
  +impactedRole: ?{|
    +id: string,
    +circle: ?{|
      +id: string,
      +databaseId: string,
      +$fragmentRefs: CreateProjectModal_circle$ref,
    |},
  |},
  +$fragmentRefs: CreateProjectModal_tension$ref,
  +$refType: ProjectProcessModal_tension$ref,
|};
export type ProjectProcessModal_tension$data = ProjectProcessModal_tension;
export type ProjectProcessModal_tension$key = {
  +$data?: ProjectProcessModal_tension$data,
  +$fragmentRefs: ProjectProcessModal_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateProjectModal_circle"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectProcessModal_tension",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "impactedRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateProjectModal_tension"
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1b8461b8f5ff59c62aa1bfec8c36182';

module.exports = node;
