/**
 * @flow
 * @relayHash 4a46f3a1241a0c6d0a03b22c6ecea7cd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProjectStatusInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  projectId: string,
  status: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateProjectStatusMutationVariables = {|
  input: UpdateProjectStatusInput
|};
export type UpdateProjectStatusMutationResponse = {|
  +updateProjectStatus: ?{|
    +project: {|
      +id: string,
      +status: string,
    |}
  |}
|};
export type UpdateProjectStatusMutation = {|
  variables: UpdateProjectStatusMutationVariables,
  response: UpdateProjectStatusMutationResponse,
|};
*/


/*
mutation UpdateProjectStatusMutation(
  $input: UpdateProjectStatusInput!
) {
  updateProjectStatus(input: $input) {
    project {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProjectStatusPayload",
    "kind": "LinkedField",
    "name": "updateProjectStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "4a46f3a1241a0c6d0a03b22c6ecea7cd",
    "metadata": {},
    "name": "UpdateProjectStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4d7a39ecb1a66ecf9601deb8ed34368';

module.exports = node;
