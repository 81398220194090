/**
 * @flow
 * @relayHash ad8d4e2a16ee9d49dbd1b9d24ef730a2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FrequencySelector_organization$ref = any;
type ViewerMetricFormModal_viewer$ref = any;
export type CreateViewerMetricQueryVariables = {|
  orgId: string
|};
export type CreateViewerMetricQueryResponse = {|
  +organization: ?{|
    +id?: string,
    +onRestrictedPlan?: boolean,
    +viewer?: {|
      +id: string,
      +$fragmentRefs: ViewerMetricFormModal_viewer$ref,
    |},
    +$fragmentRefs: FrequencySelector_organization$ref,
  |}
|};
export type CreateViewerMetricQuery = {|
  variables: CreateViewerMetricQueryVariables,
  response: CreateViewerMetricQueryResponse,
|};
*/


/*
query CreateViewerMetricQuery(
  $orgId: ID!
) {
  organization: node(id: $orgId) {
    __typename
    ... on Organization {
      id
      onRestrictedPlan
      ...FrequencySelector_organization
      viewer {
        id
        ...ViewerMetricFormModal_viewer
      }
    }
    id
  }
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment FrequencySelector_organization on Organization {
  customFrequencies(first: 100) {
    edges {
      node {
        id
        name
        universalUnits
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment ViewerMetricFormModal_viewer on Person {
  circles {
    edges {
      node {
        id
        ...CircleSelector_circles
        supportedRole {
          id
        }
      }
    }
  }
  ...WithCurrentRoleSelector_person
}

fragment WithCurrentRoleSelector_person on Person {
  roles {
    edges {
      node {
        id
        circle {
          supportedRole {
            isDisplayedAsCircle
            id
          }
          id
        }
        ...RoleSelector_roles_4EQjyB
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orgId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onRestrictedPlan",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateViewerMetricQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ViewerMetricFormModal_viewer"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FrequencySelector_organization"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateViewerMetricQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CustomFrequencyConnection",
                "kind": "LinkedField",
                "name": "customFrequencies",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFrequencyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomFrequency",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "universalUnits",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "customFrequencies(first:100)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "FrequencySelector_customFrequencies",
                "kind": "LinkedHandle",
                "name": "customFrequencies"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleConnection",
                    "kind": "LinkedField",
                    "name": "circles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CircleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isGovernanceEnabled",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Circle",
                                "kind": "LinkedField",
                                "name": "circle",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "supportedRole",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v2/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isFocus",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "governanceEnabled",
                                "storageKey": null
                              },
                              {
                                "alias": "nameWithMode",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "with",
                                    "value": "CIRCLE"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": "localizedName(with:\"CIRCLE\")"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ad8d4e2a16ee9d49dbd1b9d24ef730a2",
    "metadata": {},
    "name": "CreateViewerMetricQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f130d96164d3c0e99238239a8248c34';

module.exports = node;
