// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {DropdownItem} from 'reactstrap'
import generateIcsFile from 'utils/calendarHelpers/generateIcsFile'
import {type Event} from 'utils/calendarHelpers/types'
import downloadBlob from 'utils/MiscUtils/downloadBlob'

type Props = $ReadOnly<{
  event: Event,
  label: string,
}>

function IcsEventButton({event, label}: Props): Node {
  const onClickHandler = useCallback(async (e) => {
    e.preventDefault()

    const content = await generateIcsFile(event)
    const blob = new Blob([content], {type: 'text/calendar'})
    downloadBlob(blob, `${event.title}.ics`)
  }, [event])

  return (
    <DropdownItem onClick={onClickHandler}>
      {label}
    </DropdownItem>
  )
}

export default IcsEventButton
