// @flow
import type {Node} from 'react'
import React, {Fragment, useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import PremiumCallout from 'components/ui/PremiumCallout'
import Section from './Section'
import CustomGovernanceCircles from './CustomGovernanceCircles'
import type {
  RulesSections_organization$key as Organization,
} from './__generated__/RulesSections_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  readonly: boolean,
}>

const organizationFragment = graphql`
  fragment RulesSections_organization on Organization {
    name
    constitutionRatifiers
    onRestrictedPlan
    isModularAdoptionByCircleEnabled

    circles {
      edges {
        node {
          isGovernanceEnabled
          ...CustomGovernanceCircles_circles
        }
      }
    }

    rules {
      ruleKey
      ruleValue
      ...Section_rule
    }
    
    viewer {
      routes {
        constitutionUrl
        articleTargets {
          article1
          article2
          article3
          article4
          article5
        }
      }
    }

    ...Section_organization
  }
`

// used to pass options data to Section
function Option() {
  return null
}

const optionEnablesGovernance = (option) => option === 'HOLACRACY'
  || option === 'MODULAR_ADOPTION_BY_CIRCLE'

function RulesSections({organization: organizationKey, readonly}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const rules = organization.rules.filter(Boolean)
  const getRule = (ruleKey: string) => rules.find((rule) => rule.ruleKey === ruleKey)

  const preambleRule = getRule('preamble')
  const orgStructRule = getRule('org_struct')
  const dutiesRule = getRule('duties')
  const tacticalRule = getRule('tactical')
  const powerShiftRule = getRule('power_shift')
  const governanceRule = getRule('governance')

  const constitutionUrl = organization.viewer.routes.constitutionUrl
  const articleTargets = organization.viewer.routes.articleTargets
  const preambleRatifiers = organization.constitutionRatifiers ? `(${organization.constitutionRatifiers})` : ''

  const descriptionWithLink = (description: string, link: string) => (
    `${description} [${I18n.t('admin.organizations.org_rules.rules.holacracy.see_details')}](${link})`
  )

  const circles = liftNodes(organization.circles)

  const renderCirclesWithGovernance = (governanceEnabled: boolean, key: string) => (
    <CustomGovernanceCircles governanceEnabled={governanceEnabled} circles={circles} key={key} />
  )

  const onSwitchingGovernance = useCallback((currentState, nextState) => {
    if (currentState === 'MODULAR_ADOPTION_BY_CIRCLE' && nextState !== 'MODULAR_ADOPTION_BY_CIRCLE') {
      const circlesState = !optionEnablesGovernance(nextState)
      const amount = circles.filter((circle) => circle.isGovernanceEnabled === circlesState).length

      const text = optionEnablesGovernance(nextState)
        ? I18n.t('admin.organizations.org_rules.rules.modular_adoption_by_circle.switch_to_governance', {amount})
        : I18n.t('admin.organizations.org_rules.rules.modular_adoption_by_circle.switch_to_management', {amount})

      // eslint-disable-next-line no-alert
      return amount > 0 ? window.confirm(text) : true
    }

    if (optionEnablesGovernance(currentState) && !optionEnablesGovernance(nextState))
      // eslint-disable-next-line no-alert
      return window.confirm(I18n.t('admin.organizations.org_rules.confirm_turning_off_article_5'))

    return true
  }, [circles])

  return (
    <Fragment>
      {preambleRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={preambleRule}
        >
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.preamble.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.preamble.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.preamble.holacracy.label')}
            description={I18n.t('admin.organizations.org_rules.rules.preamble.holacracy.description', {
              org_name: organization.name, ratifiers: preambleRatifiers,
            })}
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.preamble.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.preamble.existing.description')}
          />
        </Section>
      )}
      {orgStructRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={orgStructRule}
        >
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.org_struct.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.org_struct.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.holacracy.article_1_title')}
            description={
              descriptionWithLink(
                I18n.t('admin.organizations.org_rules.rules.org_struct.holacracy'),
                `${constitutionUrl}${articleTargets.article1}`,
              )
            }
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.org_struct.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.org_struct.existing.description')}
          />
        </Section>
      )}
      {dutiesRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={dutiesRule}
        >
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.duties.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.duties.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.holacracy.article_2_title')}
            description={
              descriptionWithLink(
                I18n.t('admin.organizations.org_rules.rules.duties.holacracy'),
                `${constitutionUrl}${articleTargets.article2}`,
              )
            }
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.duties.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.duties.existing.description')}
          />
        </Section>
      )}
      {tacticalRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={tacticalRule}
        >
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.tactical.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.tactical.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.holacracy.article_3_title')}
            description={
              descriptionWithLink(
                I18n.t('admin.organizations.org_rules.rules.tactical.holacracy'),
                `${constitutionUrl}${articleTargets.article3}`,
              )
            }
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.tactical.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.tactical.existing.description')}
          />
        </Section>
      )}
      {powerShiftRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={powerShiftRule}
        >
          <Option
            value="CENTRALIZED"
            label={I18n.t('admin.organizations.org_rules.rules.power_shift.centralized.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.power_shift.centralized.description')}
          />
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.power_shift.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.power_shift.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.holacracy.article_4_title')}
            description={
              descriptionWithLink(
                I18n.t('admin.organizations.org_rules.rules.power_shift.holacracy'),
                `${constitutionUrl}${articleTargets.article4}`,
              )
            }
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.power_shift.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.power_shift.existing.description')}
          />
        </Section>
      )}
      {governanceRule && (
        <Section
          organization={organization}
          readonly={readonly}
          rule={governanceRule}
          onSave={onSwitchingGovernance}
        >
          <Option
            value="UNIVERSAL"
            label={I18n.t('admin.organizations.org_rules.rules.governance.universal.label')}
            defaultText={I18n.t('admin.organizations.org_rules.rules.governance.universal.description')}
          />
          <Option
            value="HOLACRACY"
            label={I18n.t('admin.organizations.org_rules.rules.holacracy.article_5_title')}
            description={
              descriptionWithLink(
                I18n.t('admin.organizations.org_rules.rules.governance.holacracy'),
                `${constitutionUrl}${articleTargets.article5}`,
              )
            }
          />
          <Option
            value="MODULAR_ADOPTION_BY_CIRCLE"
            label={I18n.t('admin.organizations.org_rules.rules.modular_adoption_by_circle.label')}
            description={I18n.t('admin.organizations.org_rules.rules.governance.modular_adoption_by_circle')}
            disabled={organization.onRestrictedPlan}
            testId="modular-adoption-by-circle-option"
            premiumCallout={organization.onRestrictedPlan
              ? (
                <PremiumCallout
                  callout="circle_settings_governance"
                  enabled
                  darkIcon
                />
              )
              : null
          }
              // eslint-disable-next-line react/no-unstable-nested-components
            footer={() => (
              organization.isModularAdoptionByCircleEnabled ? (
                <Fragment>
                  {renderCirclesWithGovernance(false, 'gov_off')}
                  {renderCirclesWithGovernance(true, 'gov_on')}
                </Fragment>
              ) : null
            )}
          />
          <Option
            value="EXISTING"
            label={I18n.t('admin.organizations.org_rules.rules.governance.existing.label')}
            description={I18n.t('admin.organizations.org_rules.rules.governance.existing.description')}
          />
        </Section>
      )}
    </Fragment>
  )
}

export default RulesSections
