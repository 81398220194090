// @flow
import {useState, useEffect} from 'react'

function usePeriodicRerender(period: number = 15000): null | number {
  const [renderId, setRenderId] = useState(null)

  useEffect(() => {
    const timeout = window.setTimeout(() => setRenderId(Math.random()), period)
    return () => window.clearTimeout(timeout)
  })

  return renderId
}

export default usePeriodicRerender
