// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import Security from 'components/ui/icons/fa/Security'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import type {Output, OutputType} from './types'
import styles from './index.scss'

type Props = $ReadOnly<{
  output: Output,
}>

const typeLabel = (type: OutputType): string => ({
  ACTION: I18n.t('tactical_meetings.react.action'),
  PROJECT: I18n.t('tactical_meetings.react.project'),
  TRIGGER: I18n.t('tactical_meetings.react.trigger'),
  TENSION: I18n.t('tactical_meetings.react.governance_item'),
  INFORMATION: I18n.t('tactical_meetings.react.information'),
  '%future added value': '',
}[type])

function OutputDescription({output}: Props): Node {
  return (
    <Fragment>
      <div className="label">
        {typeLabel(output.type)}
      </div>
      <div className={styles.description}>
        {output.privateToCircle && (
          <span className={styles.privateIcon}>
            <Security />
          </span>
        )}
        <WYSIWYGViewerWithFeatureFlag
          value={output.description}
          organization={output.organization}
          fallbackProps={{
            children: output.description,
          }}
          fallbackComponent="span"
        />
      </div>
    </Fragment>
  )
}

export default (createFragmentContainer(OutputDescription, {
  output: graphql`
    fragment OutputDescription_output on TacticalOutput {
      type
      description
      privateToCircle
      
      organization {
        ...WYSIWYGViewerWithFeatureFlag_organization
      }
    }
  `,
}): RelayFragmentContainer<typeof(OutputDescription)>)
