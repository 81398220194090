// @flow
import type {Element} from 'react'
import React, {Fragment, useCallback, useReducer, useMemo} from 'react'
import {graphql, useFragment} from 'react-relay'
import classNames from 'classnames'

import AppLink from 'components/ui/AppLink'
import style from './index.scss'

import type {
  CustomGovernanceCircles_circles$key as Circles,
} from './__generated__/CustomGovernanceCircles_circles.graphql'

const circlesFragment = graphql`
  fragment CustomGovernanceCircles_circles on Circle @relay(plural: true) {
    id
    localizedName
    resourcePath
    isGovernanceEnabled
  }
`

const sameGovernanceState = (state) => (circle) => circle.isGovernanceEnabled === state

type Props = $ReadOnly<{
  circles: Circles,
  governanceEnabled: boolean,
  containerClassName: string,
  labelClassName: string,
}>

function CustomGovernanceCircles({
  governanceEnabled,
  circles: circlesKey,
  containerClassName,
  labelClassName,
}: Props): null | Element<"div"> {
  const circles = useFragment(circlesFragment, circlesKey)
  const [showAll, switchShowAll] = useReducer((state) => !state, false)

  const containerClassNames = classNames(style.container, containerClassName)
  const labelClassNames = classNames(style.label, labelClassName)

  const circlesToRender = useMemo(
    () => circles.filter(sameGovernanceState(governanceEnabled)),
    [circles, governanceEnabled],
  )

  const label = governanceEnabled
    ? I18n.t('admin.organizations.org_rules.circle_with_governance_on_with_count', {amount: circlesToRender.length})
    : I18n.t('admin.organizations.org_rules.circle_with_governance_off_with_count', {amount: circlesToRender.length})

  const onLinkClick = useCallback((event: Event) => {
    event.preventDefault()
    switchShowAll()
  }, [switchShowAll])

  const renderCircle = useCallback((circle, index, arr) => (
    <Fragment>
      <AppLink key={circle.id} target="_blank" href={circle.resourcePath}>{circle.localizedName}</AppLink>
      {arr.length > index + 1 ? (<span>{', '}</span>) : null}
    </Fragment>
  ), [])

  const renderButton = useCallback(() => (
    <AppLink href="#" onClick={onLinkClick}>
      {showAll ? ` (${I18n.t('shared.hide_circles')})` : I18n.t('shared.view_circles')}
    </AppLink>
  ), [showAll, onLinkClick])

  if (!circlesToRender.length)
    return null

  return (
    <div className={containerClassNames}>
      <span className={labelClassNames}>
        {label}
        {': '}
      </span>
      {showAll && circlesToRender.map(renderCircle)}
      {renderButton()}
    </div>
  )
}

CustomGovernanceCircles.defaultProps = {
  governanceEnabled: false,
  containerClassName: '',
  labelClassName: '',
}

export default CustomGovernanceCircles
