import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const Policy = GlassFrogModel.extend({
}, {
  resourceName: 'policies',
  defaultDomainName() {
    return I18n.t('policies.no_domain')
  },
})

export default Policy
