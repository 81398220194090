// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import RoleTemplateFormModal from 'components/roleTemplates/RoleTemplateFormModal'
import AddRoleTemplateMutation from 'mutations/AddRoleTemplateMutation'
import type {
  CreateRoleTemplateModal_organization$key as Organization,
} from './__generated__/CreateRoleTemplateModal_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  close: () => void,
}>

const organizationFragment = graphql`
  fragment CreateRoleTemplateModal_organization on Organization {
    id

    ...RoleTemplateFormModal_organization
  }
`

function CreateRoleTemplateModal({organization: organizationKey, close}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const initialValues = {
    name: '',
    purpose: '',
    domains: [],
    accountabilities: [],
  }

  const submit = (values) => AddRoleTemplateMutation({...values}, organization.id)

  return (
    <RoleTemplateFormModal
      modalTitle={I18n.t('side_nav.settings_section.role_templates.add_role_template')}
      initialValues={initialValues}
      organization={organization}
      close={close}
      submit={submit}
    />
  )
}

export default CreateRoleTemplateModal
