/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateActionModal_tension$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActionProcessModal_tension$ref: FragmentReference;
declare export opaque type ActionProcessModal_tension$fragmentType: ActionProcessModal_tension$ref;
export type ActionProcessModal_tension = {|
  +body: string,
  +circle: ?{|
    +id: string
  |},
  +impactedRole: ?{|
    +id: string,
    +circle: ?{|
      +id: string
    |},
  |},
  +$fragmentRefs: CreateActionModal_tension$ref,
  +$refType: ActionProcessModal_tension$ref,
|};
export type ActionProcessModal_tension$data = ActionProcessModal_tension;
export type ActionProcessModal_tension$key = {
  +$data?: ActionProcessModal_tension$data,
  +$fragmentRefs: ActionProcessModal_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionProcessModal_tension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "impactedRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateActionModal_tension"
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd72b7532279190f8f3d2ddab9e3c337';

module.exports = node;
