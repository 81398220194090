import util from 'utils/MiscUtils'

function walk(node, fn, parent = null, maxDepth = -1, currentDepth = 1) {
  if (!node)
    util.error('node required')

  if (maxDepth > 0 && currentDepth >= maxDepth)
    return

  fn(node, parent, currentDepth)

  if (Array.isArray(node.children)) {
    node.children.forEach((child) => {
      walk(child, fn, node, maxDepth, currentDepth + 1)
    })
  }
}

function walkAncestors(node, fn) {
  fn(node)

  if (node.parent)
    walkAncestors(node.parent, fn)
}

function depth(node) {
  let maxDepth = 1
  walk(node, (n, p, d) => {
    if (d > maxDepth)
      maxDepth = d
  })
  return maxDepth
}

function descendants(node) {
  const descendantList = []
  walk(node, (n) => {
    if (n === node)
      return

    descendantList.push(n)
  })
  return descendantList
}

const isAncestor = (node, maybeAncestor) => {
  let isAnc = false

  walkAncestors(node, (nodeAncestor) => {
    if (nodeAncestor === maybeAncestor)
      isAnc = true
  })

  return isAnc
}

export {
  walk,
  depth,
  descendants,
  walkAncestors,
  isAncestor,
}
