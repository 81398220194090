// @flow
import type {Node} from 'react'
import React from 'react'
import TensionBoxContainer from 'components/tensions/ui/TensionBoxContainer'

function WaitingOnAgendaItemMsg(): Node {
  return (
    <TensionBoxContainer marginBottom="NONE">
      {I18n.t('tactical_meetings.current.waiting_for_agenda_item')}
    </TensionBoxContainer>
  )
}

export default WaitingOnAgendaItemMsg
