import React, {type Node} from 'react'
import logger from 'utils/MiscUtils/main'

type Props = {
  children: Node,
}

type State = {
  error: ?Error,
}

class RootErrorBoundary extends React.Component<Props, State> {
  state = {error: null}

  static getDerivedStateFromError(error: Error): State {
    logger.warn(error)
    return {error}
  }

  render() {
    if (this.state.error)
      return null

    return this.props.children
  }
}

export default RootErrorBoundary
