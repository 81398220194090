// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import styles from './index.scss'

export type MarginBottom = 'NONE' | 'MEDIUM'

type Props = $ReadOnly<{
  children: Node,
  marginBottom: MarginBottom,
  'test-id': string | null,
}>

function TensionBoxContainer({children, marginBottom, 'test-id': testId}: Props): Element<"div"> {
  const containerClassname = classNames(styles.tensionInfo, {
    [styles.marginBottomMedium]: marginBottom === 'MEDIUM',
  })

  return (
    <div className={containerClassname} test-id={testId}>
      {children}
    </div>
  )
}

TensionBoxContainer.defaultProps = {
  'test-id': null,
}

export default TensionBoxContainer
