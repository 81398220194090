/**
 * @flow
 * @relayHash 07e2530b97cf0aa9703201e0c8fd21b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteAmendmentInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  amendmentId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteAmendmentMutationVariables = {|
  input: DeleteAmendmentInput
|};
export type DeleteAmendmentMutationResponse = {|
  +deleteAmendment: ?{|
    +deletedAmendmentId: string
  |}
|};
export type DeleteAmendmentMutation = {|
  variables: DeleteAmendmentMutationVariables,
  response: DeleteAmendmentMutationResponse,
|};
*/


/*
mutation DeleteAmendmentMutation(
  $input: DeleteAmendmentInput!
) {
  deleteAmendment(input: $input) {
    deletedAmendmentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAmendmentPayload",
    "kind": "LinkedField",
    "name": "deleteAmendment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAmendmentId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAmendmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAmendmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "07e2530b97cf0aa9703201e0c8fd21b7",
    "metadata": {},
    "name": "DeleteAmendmentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39aed17d2bc32a36123e832dd01b637c';

module.exports = node;
