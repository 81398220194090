// @flow
import type {Element} from 'react'
import React, {useCallback} from 'react'
import className from 'classnames'
import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import type {Option} from '../types'

type Props = {
  option: Option,
  isSelected: boolean,
  onSelect: Option => void,
}

function EtcSelectorOption({option, isSelected, onSelect}: Props): Element<"div"> {
  const onClick = useCallback(() => onSelect(option), [onSelect, option])

  const optionClassName = className('etc-selector-option', {
    'etc-selector-option--selected': isSelected,
  })

  return (
    <div
      className={optionClassName}
      {...accessibleOnClickProps(onClick)}
      role="menuitem"
      tabIndex={0}
    >
      <i className="etc-selector-option__check-icon fa fa-check" />
      <div className="etc-selector-option__label">
        {option.label}
        {option.subLabel && (
          <div className="etc-selector-option__sub-label">
            {option.subLabel}
          </div>
        )}
      </div>
    </div>
  )
}

export default EtcSelectorOption
