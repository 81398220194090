/**
 * @flow
 * @relayHash 345c8dd4f285553f19711d50a530270e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SetupModeBanner_organization$ref = any;
export type SetupModeBannerContainerQueryVariables = {|
  orgDatabaseId: string
|};
export type SetupModeBannerContainerQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: SetupModeBanner_organization$ref
  |}
|};
export type SetupModeBannerContainerQuery = {|
  variables: SetupModeBannerContainerQueryVariables,
  response: SetupModeBannerContainerQueryResponse,
|};
*/


/*
query SetupModeBannerContainerQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...SetupModeBanner_organization
    id
  }
}

fragment SetupModeBanner_organization on Organization {
  id
  viewerIsAdmin
  setupModeEnabled
  setupModeSuspendedInvitationsCount
  viewer {
    routes {
      editConstitutionPath
      setupModeArticleUrl
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetupModeBannerContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SetupModeBanner_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetupModeBannerContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setupModeEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setupModeSuspendedInvitationsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Routes",
                "kind": "LinkedField",
                "name": "routes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editConstitutionPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "setupModeArticleUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "345c8dd4f285553f19711d50a530270e",
    "metadata": {},
    "name": "SetupModeBannerContainerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '659ca5aed3c91cc87f4480c06eb21668';

module.exports = node;
