/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonAvatar_person$ref: FragmentReference;
declare export opaque type PersonAvatar_person$fragmentType: PersonAvatar_person$ref;
export type PersonAvatar_person = {|
  +avatarUrl: string,
  +name: string,
  +$refType: PersonAvatar_person$ref,
|};
export type PersonAvatar_person$data = PersonAvatar_person;
export type PersonAvatar_person$key = {
  +$data?: PersonAvatar_person$data,
  +$fragmentRefs: PersonAvatar_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonAvatar_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7f8cabd60ccfc403640c5f07d9c2ebd5';

module.exports = node;
