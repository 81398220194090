// @flow
import type {Element} from 'react'
import React from 'react'
import PremiumIcon from 'components/ui/icons/Premium'
import {UncontrolledTooltip} from 'reactstrap'
import useUniqueId from 'utils/hooks/useUniqueId'
import className from 'classnames'
import styles from './index.scss'
import type {CalloutType} from './types'

type Props = $ReadOnly<{
  callout: CalloutType,
  enabled: boolean,
  iconClassName?: string,
  target?: string,
  targetText?: string,
  darkIcon?: boolean,
}>

function PremiumCallout({
  callout,
  enabled,
  iconClassName,
  target,
  targetText,
  darkIcon,
}: Props): Element<"span"> | boolean {
  const textFor = (key: string) => I18n.t(`premium_callout.${callout}.${key}`)
  const hasTranslation = (key: string) => textFor(key) && !textFor(key).includes('premium_callout')

  const iconClass = className(iconClassName, styles.icon, {
    [styles.darkIcon]: darkIcon,
  })
  const tooltipContent = () => (
    <div test-id="premium-tooltip">
      <div className={styles.premiumContent}>
        <PremiumIcon />
        {I18n.t('premium_callout.title')}
      </div>
      {hasTranslation('header') && (
        <div className={styles.header}>
          {textFor('header')}
        </div>
      ) }
      {hasTranslation('description') && (
        <p>
          {textFor('description')}
        </p>
      ) }
      {hasTranslation('link_text') && (
        <p>
          {hasTranslation('link_url') ? (
            <a href={textFor('link_url')}>
              {textFor('link_text')}
            </a>
          ) : (
            <span>{textFor('link_text')}</span>
          ) }
        </p>
      ) }
    </div>
  )

  const [id] = useUniqueId()
  const tooltipDelay = {show: 250, hide: 10}

  return (
    enabled && (
      <span id={id} className={styles.container}>
        <span className={iconClass} test-id="premium-icon">
          {targetText || ''}
          <PremiumIcon />
        </span>
        <UncontrolledTooltip
          delay={tooltipDelay}
          innerClassName={styles.tooltipInner}
          placement="right"
          target={target || id}
          autohide={false}
        >
          <span>{tooltipContent()}</span>
        </UncontrolledTooltip>
      </span>
    )
  )
}

export default PremiumCallout
export type {CalloutType}
