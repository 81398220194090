// @flow
import type {ComponentType} from 'react'
import React from 'react'
import {__RouterContext as RouterContext} from 'react-router'
import type {ReactRouterContext} from 'utils/AppRouter'

// eslint-disable-next-line arrow-parens
const withMaybeRouter = <Props: {+context: ?ReactRouterContext, ...}>(
  WrappedComponent: ComponentType<Props>,
): ComponentType<Props> => {
  // eslint-disable-next-line react/function-component-definition
  const Wrapper = (props: Props) => (
    <RouterContext.Consumer>
      {(context: ?Object) => (
        <WrappedComponent
          {...props}
          context={context}
        />
      )}
    </RouterContext.Consumer>
  )

  Wrapper.displayName = `withMaybeRouter(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
  Wrapper.WrappedComponent = WrappedComponent

  return Wrapper
}

export default withMaybeRouter
