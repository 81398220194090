// @flow
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import DefinitionTooltip from 'components/ui/DefinitionTooltip'

const notRenderedTooltipSelector = '[data-react-class="Glassfrog.components.ui.DefinitionTooltip"]:empty'

const renderTooltip = (props, wrapper) => {
  const reactTooltip = React.createElement(DefinitionTooltip, props)

  ReactDOM.render(reactTooltip, wrapper)
}

const renderNotRenderedTooltip = (tooltipDomElement: HTMLElement) => {
  const stringifiedReactProps = tooltipDomElement.dataset.reactProps
  const reactProps = JSON.parse(stringifiedReactProps)

  renderTooltip(reactProps, tooltipDomElement)
}

const renderNotRenderedTooltips = () => {
  Array.from(document.querySelectorAll(notRenderedTooltipSelector))
    .forEach(renderNotRenderedTooltip)
}

export default renderNotRenderedTooltips
