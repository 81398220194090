// @flow
import type {Element, Node} from 'react'
import React from 'react'

import styles from './index.scss'

type Props = $ReadOnly<{
  rightBlock: Node,
  leftBlock: Node,
}>

function Footer({rightBlock, leftBlock}: Props): Element<"div"> {
  return (
    <div className={styles.container}>
      <div className={styles.rightBlock}>
        {rightBlock}
      </div>
      <div className={styles.leftBlock}>
        {leftBlock}
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rightBlock: null,
  leftBlock: null,
}

export default Footer
