/**
 * @flow
 * @relayHash e30462b056f604dc70280a6b102e486a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Output_output$ref = any;
export type PersonOwner = "EACH_MEMBER" | "NONE" | "PERSON";
export type RoleOwner = "INDIVIDUAL_INITIATIVE" | "ROLE";
export type UpdateTacticalOutputInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  outputId: string,
  roleId?: ?string,
  roleType: RoleOwner,
  personId?: ?string,
  personType: PersonOwner,
  description: string,
  outputType?: ?string,
  privateToCircle?: ?boolean,
  triggerEvent?: ?string,
  projectStatus?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateTacticalOutputMutationVariables = {|
  input: UpdateTacticalOutputInput
|};
export type UpdateTacticalOutputMutationResponse = {|
  +updateTacticalOutput: ?{|
    +output: ?{|
      +$fragmentRefs: Output_output$ref
    |}
  |}
|};
export type UpdateTacticalOutputMutation = {|
  variables: UpdateTacticalOutputMutationVariables,
  response: UpdateTacticalOutputMutationResponse,
|};
*/


/*
mutation UpdateTacticalOutputMutation(
  $input: UpdateTacticalOutputInput!
) {
  updateTacticalOutput(input: $input) {
    output {
      ...Output_output
      id
    }
  }
}

fragment EditOutput_output on TacticalOutput {
  id
  ...OutputForm_output
}

fragment OutputActions_output on TacticalOutput {
  id
}

fragment OutputAdditionalInfo_output on TacticalOutput {
  type
  trigger
  tensionBody
}

fragment OutputDescription_output on TacticalOutput {
  type
  description
  privateToCircle
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment OutputForm_output on TacticalOutput {
  type
  description
  privateToCircle
  trigger
  projectStatus
  owner {
    databaseId
    id
  }
  person {
    databaseId
    id
  }
}

fragment OutputOwner_output on TacticalOutput {
  type
  owner {
    localizedName
    isIndividualAction
    id
  }
}

fragment OutputPerson_output on TacticalOutput {
  person {
    name
    id
  }
}

fragment OutputProjectStatus_output on TacticalOutput {
  projectStatus
}

fragment Output_output on TacticalOutput {
  databaseId
  type
  error
  ...OutputAdditionalInfo_output
  ...OutputProjectStatus_output
  ...OutputDescription_output
  ...OutputPerson_output
  ...OutputActions_output
  ...OutputOwner_output
  ...EditOutput_output
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTacticalOutputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTacticalOutputPayload",
        "kind": "LinkedField",
        "name": "updateTacticalOutput",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TacticalOutput",
            "kind": "LinkedField",
            "name": "output",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Output_output"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTacticalOutputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTacticalOutputPayload",
        "kind": "LinkedField",
        "name": "updateTacticalOutput",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TacticalOutput",
            "kind": "LinkedField",
            "name": "output",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trigger",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tensionBody",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isWysiwygEnabled",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localizedName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isIndividualAction",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "e30462b056f604dc70280a6b102e486a",
    "metadata": {},
    "name": "UpdateTacticalOutputMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5de26a3a379140c37f4e02c67700d249';

module.exports = node;
