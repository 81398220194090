import _ from 'underscore'
import util from 'utils/MiscUtils'

const ActionHandlerUtils = {
  mergeResponseDataIntoStore(action, setDataOptions = {}) {
    // for the primary modified resource,
    // merge any new data from the server, excepting locally modified keys
    const primaryType = action.request.type || util.error('action must include request.type')
    const id = action.request.id || action.request.options.localId
    const snapshotData = action.snapshotData
    let primaryData = action.response.data[primaryType]
    const linkedData = action.response.data.linked

    if (id && action.request.method !== 'GET') {
      util.assert(primaryData.length <= 1, 'assuming single resource in response data')

      if (snapshotData) {
        const currentData = this.store.getData(primaryType, id)
        const serverData = primaryData[0]
        const keysWithLocalChanges = _.filter(_.keys(serverData), (key) => !_.isEqual(currentData[key], snapshotData[key]) && (key !== 'id'))

        primaryData = [_.omit(serverData, keysWithLocalChanges)]
      }
      this.store.setData(primaryType, id, primaryData[0], setDataOptions)
    } else {
      this.store.setData(primaryType, primaryData, setDataOptions)
    }

    if (linkedData)
      this.mergeLinkedDataIntoStore(primaryType, primaryData, linkedData, setDataOptions)
  },

  mergeLinkedDataIntoStore(primaryType, primaryData, linkedData, setDataOptions) {
    Object.keys(linkedData).forEach((linkedType) => {
      if (!linkedData.hasOwnProperty(linkedType))
        return
      // FIXME: need this for now since we don't support polymorphism well
      if (linkedType == 'meetings')
        return
      this.store.setData(linkedType, linkedData[linkedType], _.extend({
        relatedToType: primaryType,
        relatedToResources: primaryData,
      }, setDataOptions))
    })
  },

  handleWith(requestedAction) {
    return function (reqRes) {
      const finalAction = _.extend(
        {},
        reqRes || util.error('reqRes required'),
        requestedAction,
      )

      if (reqRes.response.processed) {
        util.debug('response already processed', reqRes)
        return reqRes
      }
      this.dispatcher.dispatch(finalAction)
      reqRes.response.processed = true

      // TODO: probably more correct to return result here, but it breaks specs..
      return reqRes
    }.bind(this)
  },

  hasOrWillSave(type, id) {
    return this.store.isSaved(type, id)
      || this.apiAdapter.apiHasOrWillRequest('POST', type, {}, {localId: id})
  },
}

export default ActionHandlerUtils
