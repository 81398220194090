import _ from 'underscore'
import util from 'utils/MiscUtils'

const DefaultActions = {
  DEFAULT_GET_SUCCESS(action) {
    const type = action.request.type || util.error('action must include request')

    if (!_.isArray(action.response.data[type]))
      util.error('invalid server response ', action.response.data, ` ${type} should be array`)

    this.mergeResponseDataIntoStore(action)
  },

  DEFAULT_CREATE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const data = action.data

    // no store.setData() -- create is pessimistic we'll wait for the server to respond.
    return this.apiAdapter.apiPost(type, data).then(
      this.handleWith({
        type: 'DEFAULT_CREATE_SUCCESS',
      }),
    )
  },

  DEFAULT_CREATE_SUCCESS(action) {
    const type = action.request.type
    const status = action.response.status
    const requestData = action.request.data
    const responseData = action.response.data
    const localId = action.request.options.localId

    if (localId) {
      const serverId = responseData[type][0].id
      this.apiAdapter.apiResolveLocalId(localId, serverId)
    }

    // eslint-disable-next-line default-case
    switch (status) {
      case 204:
        return this.store.setData(type, requestData)
      case 200:
        return this.mergeResponseDataIntoStore(action)
    }
  },

  DEFAULT_DELETE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const id = action.resourceId || util.error('resourceId required')

    return this.apiAdapter.apiDelete(type, id).then(
      this.handleWith({type: 'DEFAULT_DELETE_SUCCESS'}),
    )
  },

  DEFAULT_DELETE_SUCCESS(action) {
    const type = action.request.type || util.error('action must include request.type')
    const id = action.request.id || util.error('action must include request.id')

    if (this.store.getData(type, id))
      this.store.setData(type, id, null)
  },

  DEFAULT_UPDATE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data
    const animation = action.animation
    const updateStore = function () {
      this.store.setData(type, id, data)
    }.bind(this)

    if (animation)
      animation(updateStore)
    else
      updateStore()

    return this.apiAdapter.apiPatch(type, id, data).then(
      this.handleWith({
        type: 'DEFAULT_UPDATE_SUCCESS',
        snapshotData: this.store.getData(type, id),
      }),
    )
  },

  DEFAULT_UPDATE_SUCCESS(action) {
    const type = action.request.type || util.error('action must include request.type')
    const id = action.request.id || util.error('action must include request.id')
    const status = action.response.status

    // reset any error on the model since we just got a successful save
    this.store.setData(type, id, {
      _errorCode: null,
      _errorMessage: null,
    })

    if (status === 204)
      return // done!

    this.mergeResponseDataIntoStore(action)
  },

  DEFAULT_SERVER_ERROR(action) {
    const type = action.request.type
    const id = action.request.id
    const message = action.response.data.message
    const status = action.response.status
    const data = {
      id,
      _errorCode: status,
      _errorMessage: message,
    }

    if (type && id) {
      // FIXME, this feels like something missing in store API.
      if (this.store.getModel(type, id))
        this.store.setData(type, id, data)
      else
        this.store.setData(type, [data])
    }
  },

  DEFAULT_LOCAL_CREATE(action) {
    const data = _.isObject(action.data) ? action.data : util.error('valid data required')
    return this.store.setData(action.resourceType, data)
  },

  DEFAULT_LOCAL_UPDATE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data

    return this.store.setData(type, id, data)
  },

  DEFAULT_SAVE(action) {
    const type = action.resourceType || util.error('resourceType required')
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data || util.error('data required')
    const organizationId = action.organizationId

    if (this.hasOrWillSave(type, id)) {
      const resolvedId = util.isLocalId(id) ? (this.store.resolveLocalId(id) || id) : id

      this.store.setData(type, resolvedId, data)
      return this.apiAdapter.apiPatch(type, resolvedId, data).then(
        this.handleWith({type: 'DEFAULT_UPDATE_SUCCESS', snapshotData: this.store.getData(type, id)}),
      )
    }
    this.store.setData(type, id, data)
    return this.apiAdapter.apiPost(type, data, {localId: id, organizationId}).then(
      this.handleWith({type: 'DEFAULT_CREATE_SUCCESS', snapshotData: this.store.getData(type, id)}),
    )
  },
}

export default DefaultActions
