// @flow
import type {Element} from 'react'
import React from 'react'

import Markdown from 'components/ui/Markdown'
import styles from './index.scss'

function InlineMarkdown({text}: {text: string}): Element<"span"> {
  return (
    <span className={styles.userContent}>
      <Markdown
        source={text}
        disallowedTypes={['p']}
        unwrapDisallowed
      />
    </span>
  )
}

export default InlineMarkdown
