// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import {useToggle} from 'utils/hooks'
import ArrowUp from 'components/ui/icons/ios/ArrowUp'
import ArrowDown from 'components/ui/icons/ios/ArrowDown'
import styles from './index.scss'

type ControlledProps = $ReadOnly<{
  children: Node,
  className: ?string,
  showLessTitle: string,
  showMoreTitle: string,
  togglePosition: 'left' | 'right',
  onToggle: (isOpen: boolean) => any,
  isOpen: boolean,
}>

type UncontrolledProps = $ReadOnly<{
  children: Node,
  className: ?string,
  showLessTitle: string,
  showMoreTitle: string,
  togglePosition: 'left' | 'right',
}>

// This is a hack to mitigate I18n module loading issues.
const defaultShowLessTitle = () => I18n.t('shared.show_less')
const defaultShowMoreTitle = () => I18n.t('shared.show_more')

function ShowMore({
  children,
  className,
  showLessTitle,
  showMoreTitle,
  togglePosition,
}: UncontrolledProps): Node {
  const [isOpen, {toggle}] = useToggle(false)

  return (
    <ControlledShowMore
      className={className}
      onToggle={toggle}
      showMoreTitle={showMoreTitle}
      showLessTitle={showLessTitle}
      togglePosition={togglePosition}
      isOpen={isOpen}
    >
      {children}
    </ControlledShowMore>
  )
}

ShowMore.defaultProps = {
  className: null,
  showLessTitle: (defaultShowLessTitle(): string),
  showMoreTitle: (defaultShowMoreTitle(): string),
  togglePosition: 'left',
}

function ControlledShowMore({
  children,
  className,
  onToggle,
  showLessTitle,
  showMoreTitle,
  togglePosition,
  isOpen,
}: ControlledProps): Element<"div"> {
  const toggleClass = classNames(styles.button, {
    [styles.buttonRight]: togglePosition === 'right',
    [styles.buttonActive]: isOpen,
  })

  return (
    <div className={className}>
      <button type="button" className={toggleClass} onClick={onToggle}>
        <span test-id="show-more">
          {isOpen
            ? showLessTitle
            : showMoreTitle
          }
        </span>
        <div className={styles.icon}>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </div>
      </button>
      {isOpen && (
        <div className={styles.content}>
          {children}
        </div>
      )}
    </div>
  )
}

ControlledShowMore.defaultProps = {
  className: null,
  onToggle: () => {},
  showLessTitle: (defaultShowLessTitle(): string),
  showMoreTitle: (defaultShowMoreTitle(): string),
  togglePosition: 'left',
  isOpen: false,
}

export {ControlledShowMore}

export default ShowMore
