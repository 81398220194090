/**
 * @flow
 * @relayHash a9e379aeeb0607e29aa98e2aa131b992
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditRoleTemplateModalView_roleTemplate$ref = any;
export type AddRoleTemplateInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  name: string,
  purpose?: ?string,
  domains?: ?$ReadOnlyArray<string>,
  accountabilities?: ?$ReadOnlyArray<string>,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddRoleTemplateMutationVariables = {|
  input: AddRoleTemplateInput
|};
export type AddRoleTemplateMutationResponse = {|
  +addRoleTemplate: ?{|
    +roleTemplateEdge: ?{|
      +node: ?{|
        +$fragmentRefs: EditRoleTemplateModalView_roleTemplate$ref
      |}
    |}
  |}
|};
export type AddRoleTemplateMutation = {|
  variables: AddRoleTemplateMutationVariables,
  response: AddRoleTemplateMutationResponse,
|};
*/


/*
mutation AddRoleTemplateMutation(
  $input: AddRoleTemplateInput!
) {
  addRoleTemplate(input: $input) {
    roleTemplateEdge {
      node {
        ...EditRoleTemplateModalView_roleTemplate
        id
      }
    }
  }
}

fragment EditRoleTemplateModalView_roleTemplate on RoleTemplate {
  id
  name
  purpose
  domains
  accountabilities
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRoleTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddRoleTemplatePayload",
        "kind": "LinkedField",
        "name": "addRoleTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTemplateEdge",
            "kind": "LinkedField",
            "name": "roleTemplateEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditRoleTemplateModalView_roleTemplate"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddRoleTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddRoleTemplatePayload",
        "kind": "LinkedField",
        "name": "addRoleTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTemplateEdge",
            "kind": "LinkedField",
            "name": "roleTemplateEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "purpose",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domains",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountabilities",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a9e379aeeb0607e29aa98e2aa131b992",
    "metadata": {},
    "name": "AddRoleTemplateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7aeed23d4812c5fbf44aa264ed20295e';

module.exports = node;
