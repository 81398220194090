import {pathOr} from 'ramda'

// to make it not blow up in dev env
const sendReport = (args) => (
  typeof newrelic !== 'undefined'
    ? newrelic.noticeError(args)
    // eslint-disable-next-line no-console
    : console.log(args)
)

function trackMissedKeys(key) {
  const pathToKey = key.split('.')
  const keyTranslation = (locale) => pathOr(null, [locale, ...pathToKey])

  const currentLocaleKeyTranslation = keyTranslation(I18n.locale)(I18n.translations)
  const fallbackLocaleKeyTranslation = keyTranslation('en')(I18n.translations)

  const keyNotExist = currentLocaleKeyTranslation === null && fallbackLocaleKeyTranslation === null

  if (keyNotExist && !key.includes('v5') && !key.includes('premium_callout'))
    sendReport(`Missed translation for ${key}`)
}

export default trackMissedKeys
