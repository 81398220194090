/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActionCompletionCheckbox_action$ref: FragmentReference;
declare export opaque type ActionCompletionCheckbox_action$fragmentType: ActionCompletionCheckbox_action$ref;
export type ActionCompletionCheckbox_action = {|
  +id: string,
  +completedAt: ?any,
  +$refType: ActionCompletionCheckbox_action$ref,
|};
export type ActionCompletionCheckbox_action$data = ActionCompletionCheckbox_action;
export type ActionCompletionCheckbox_action$key = {
  +$data?: ActionCompletionCheckbox_action$data,
  +$fragmentRefs: ActionCompletionCheckbox_action$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionCompletionCheckbox_action",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    }
  ],
  "type": "Action",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '28b861d133324f07f19e47d932d981f2';

module.exports = node;
