import _ from 'underscore'
import $ from 'jquery'
import util from 'utils/MiscUtils'
import ActionHandlerUtils from './utills/ActionHandlerUtils'

import ActionActions from './actions/ActionActions'
import AgendaActions from './actions/AgendaActions'
import CommActions from './actions/CommActions'
import DefaultActions from './actions/DefaultActions'
import GovMtgActions from './actions/GovMtgActions'
import LegacyBackboneActions from './actions/LegacyBackboneActions'
import MeetingActions from './actions/MeetingActions'
import ProposalActions from './actions/ProposalActions'
import TensionActions from './actions/TensionActions'

function Dispatcher() {
  this.initialize.apply(this, arguments)
}

_.extend(Dispatcher, {
  _registeredActionHandlers: {},
  _registeredHelpers: {},

  registerActionHelpers(handlers) {
    _.extend(this._registeredHelpers, handlers)
  },

  registerActionHandlers(handlers) {
    _.extend(this._registeredActionHandlers, handlers)
  },
})

_.extend(Dispatcher.prototype, {
  initialize(options) {
    this.app = (options && options.app) || util.error('Dispatcher requires app')
    this._current = null
    this._queue = []
    this._actionHandlerContext = null
    this._actionHandlers = this.constructor._registeredActionHandlers
  },

  dispatch(action) {
    let returnValue
    try {
      if (this._current)
        util.error(`can't dispatch ${action.type} while ${this._current.type} is in progress`)
      else
        this._current = action

      if (!this._actionHandlerContext)
        this._initActionHandlerContext()

      returnValue = this._actionHandlerContext.store.withSingleChangeEvent(
        this._dispatch.bind(this, action),
      )
      this._logAction(action)
    } catch (err) {
      $('#js-error-500').show() // Alert the user that there's a problem

      // FIXME: without this setTimeout, errors in dispatches called from promises
      // (ie. in actions triggered by ajax responses from server)
      // sometimes fail to show up in Chrome console *at all*.
      // the setTimeout somehow brings those errors (& stack traces) back from the void.
      //
      // we might want to do something more sophisticated here later..
      // interaction of promises & error-handling is complex, might need to read up on it.
      // I've read that Bluebird.js does it better than jQuery.Deferred()
      // -LWH
      //
      setTimeout(() => {
        throw (err)
      }, 1)
    } finally {
      this._current = null
    }
    return returnValue
  },

  // private

  _dispatch(action) {
    const handler = this._getHandler(action) || util.error('no handler for action', action)
    return handler.call(this._actionHandlerContext, action)
  },

  _logAction(action) {
    let details = ''
    if (action.request && action.request.pathWithQuery)
      details = ` ${action.request.pathWithQuery}`
    else if (action.resourceType)
      details = ` ${action.resourceType}:${action.resourceId}`

    util.info(`action ${action.type}${details} completed.`, action)
  },

  _initActionHandlerContext() {
    // we put this one here so it gets called after gf.app is finished initializing
    this._actionHandlerContext = _.extend({
      app: this.app,
      dispatcher: this,
      store: this.app.store || util.error('Dispatcher requires store'),
      apiAdapter: this.app.apiAdapter || util.error('Dispatcher requires store'),
    }, this.constructor._registeredHelpers)
  },

  _getHandler(action) {
    return this._actionHandlers[action.type] || util.error(`No handler for action.type = ${action.type}`)
  },
})

Dispatcher.registerActionHelpers(ActionHandlerUtils)

Dispatcher.registerActionHandlers(ActionActions)
Dispatcher.registerActionHandlers(AgendaActions)
Dispatcher.registerActionHandlers(CommActions)
Dispatcher.registerActionHandlers(DefaultActions)
Dispatcher.registerActionHandlers(GovMtgActions)
Dispatcher.registerActionHandlers(LegacyBackboneActions)
Dispatcher.registerActionHandlers(MeetingActions)
Dispatcher.registerActionHandlers(ProposalActions)
Dispatcher.registerActionHandlers(TensionActions)

export default Dispatcher
