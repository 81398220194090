import $ from 'jquery'
import _ from 'underscore'

const allRoleFillersId = -2
const allCircleMembersId = -1
const individualActionId = 0
const noPersonId = 0
let roleForPersonMap = {}
let personForRoleMap = {}
let personNameMap = {}
let peopleData = []

function sanitize(string) {
  return string
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

function toOptions(data) {
  return _.sortBy(_(data).map((item) => [item.id, sanitize(item.name)]), (item) => item[1])
}

const RoleAndFillerOptions = {
  setupFromDom($el) {
    personForRoleMap = {}
    peopleData = $el.data('people')
    personForRoleMap[individualActionId] = toOptions(peopleData)

    roleForPersonMap = {}
    roleForPersonMap[noPersonId] = toOptions($el.data('roles'))

    $el.children().each(function () {
      const roleData = $(this).data('roles')
      const personData = $(this).data('people')

      if (roleData) {
        const personId = this.id.split('preload-person-roles-')[1]
        const roleOptions = toOptions(roleData)
        roleForPersonMap[personId] = roleOptions
      }
      if (personData) {
        const roleId = this.id.split('preload-role-fillers-')[1]
        const personOptions = toOptions(personData)
        if (personOptions.length > 1) {
          if (!roleForPersonMap[allRoleFillersId])
            roleForPersonMap[allRoleFillersId] = []

          roleForPersonMap[allRoleFillersId].push([
            parseInt(roleId, 10),
            RoleAndFillerOptions.roleName(roleId),
          ])
        }
        personForRoleMap[roleId] = personOptions
      }
    })

    personNameMap = _.reduce(peopleData, (memo, person) => {
      memo[person.id] = person.name
      return memo
    }, {})

    personNameMap[allRoleFillersId] = I18n.t('forms.each_role_filler')
    personNameMap[noPersonId] = I18n.t('proposals.role_edit.none')
    personNameMap[allCircleMembersId] = I18n.t('forms.each_circle_member')
  },

  individualActionText() {
    return gf.app.orgOnV5()
      ? I18n.t('forms.v5.individual_action')
      : I18n.t('forms.individual_action')
  },

  rolesForPerson(personId, skipFiltering) {
    if (skipFiltering)
      personId = 0

    const forPerson = roleForPersonMap[personId || noPersonId] || []
    if (personId == allRoleFillersId)
      return forPerson

    const individualActionOption = [individualActionId, this.individualActionText()]
    return personId
      ? [].concat(forPerson).concat([individualActionOption])
      : [individualActionOption].concat(forPerson)
  },

  people() {
    const selectPersonOption = [noPersonId, I18n.t('forms.select_person')]
    return [selectPersonOption].concat(toOptions(peopleData))
  },

  peopleForRole(roleId) {
    const forRole = personForRoleMap[roleId || individualActionId] || []
    const selectPersonOption = [noPersonId, I18n.t('forms.select_person')]
    if (roleId && forRole.length > 1)
      return [selectPersonOption].concat([[allRoleFillersId, I18n.t('forms.each_role_filler')]]).concat(forRole)
    if (roleId)
      return [selectPersonOption].concat(forRole)

    return [selectPersonOption, [allCircleMembersId, I18n.t('forms.each_circle_member')]].concat(forRole)
  },

  personName(personId) {
    return personId && _.has(personNameMap, personId.toString()) ? personNameMap[personId] : I18n.t('proposals.role_edit.none')
  },

  roleName(roleId) {
    if (roleId > 0) {
      const rolePair = _(roleForPersonMap[noPersonId]).find((pair) => pair[0] == roleId)
      if (rolePair)
        return rolePair[1]
    }
    return this.individualActionText()
  },
}

export default RoleAndFillerOptions
