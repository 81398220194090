// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'
import liftNodes from 'utils/GraphQL/typedLiftNodes'

import BasicChecklistFormModal from '../BasicChecklistFormModal'
import type {ValidValues, Values} from '../BasicChecklistFormModal'

import type {
  ChecklistFormModal_circle$key as CircleKey,
} from './__generated__/ChecklistFormModal_circle.graphql'

type Props<Response> = $ReadOnly<{
  includeEachCircleMember: boolean,
  initialValues: Values,
  submit: (ValidValues, string) => Promise<Response>,
  modalTitle: string,
  circle: CircleKey,
  renderLeftFooterButton: (boolean) => Node,
  close: () => void,
  globalItem?: boolean,
}>

const circleFragment = graphql`
  fragment ChecklistFormModal_circle on Circle {
    id

    supportedRole {
      id
      isExpanded
      localizedName

      parentRole {
        __typename
      }

      parentCircle: circle {
        id
      }
    }

    roles(includeHiddenRoles: true) {
      edges {
        node {
          id
          localizedName

          parentCircle: circle {
            id
          }
        }
      }
    }

    organization {
      id
      onRestrictedPlan
      ...FrequencySelector_organization
    }
  }
`

function ChecklistFormModal<Response>({
  includeEachCircleMember,
  initialValues,
  submit,
  modalTitle,
  circle: circleKey,
  renderLeftFooterButton,
  close,
  globalItem,
}: Props<Response>): Node {
  const circle = useFragment(circleFragment, circleKey)
  const organization = circle.organization

  const basicRolesScope = [
    circle.supportedRole?.parentRole ? circle.supportedRole : null,
    ...liftNodes(circle.roles),
  ].filter(Boolean)

  return (
    <BasicChecklistFormModal
      includeEachCircleMember={includeEachCircleMember}
      initialValues={initialValues}
      modalTitle={modalTitle}
      circle={circle}
      roles={basicRolesScope}
      organization={organization}
      close={close}
      globalItem={globalItem}
      renderLeftFooterButton={renderLeftFooterButton}
      submit={submit}
    />
  )
}

ChecklistFormModal.defaultProps = {
  renderLeftFooterButton: (): null => null,
}

export default ChecklistFormModal
