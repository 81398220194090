// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'
import rangeAddUpdater from 'utils/GraphQL/rangeAddUpdater'

import type {
  AddViewerChecklistItemMutationResponse as Response,
  AddChecklistItemInput as Input,
} from './__generated__/AddViewerChecklistItemMutation.graphql'

const mutation = graphql`
  mutation AddViewerChecklistItemMutation(
    $input: AddChecklistItemInput!
  ) {
    addChecklistItem(input: $input) {
      checklistItemEdge {
        node {
          id

          ...ChecklistsItemsViewerSection_checklistItems
        }
      }
    }
  }
`

const AddViewerChecklistItemMutation = (
  input: MutationInput<Input>,
  circleId: string,
  viewerId: string,
): Promise<Response> => {
  const variables = {input}

  const updater = rangeAddUpdater({
    connectionInfo: {
      key: 'Viewer_checklistItems',
    },
    parentId: viewerId,
    edgeName: 'checklistItemEdge',
    rootNode: 'addChecklistItem',
    addLocation: 'after',
  })

  return createMutationPromise({mutation, variables, updater})
}

export type {Response}
export default AddViewerChecklistItemMutation
