// @flow
import type {Element} from 'react'
import React from 'react'
import classnames from 'classnames'
import styles from './index.scss'

type Props = {|
  sm?: boolean,
  lg?: boolean,
  white?: boolean,
|}

function LoadingDots({sm, lg, white}: Props): Element<"div"> {
  const loadingDotsClass = classnames(styles.loadingDots, {
    [styles.loadingDotsSmall]: sm,
    [styles.loadingDotsLarge]: lg,
    [styles.loadingDotsWhite]: white,
  })

  return (
    <div className={loadingDotsClass} test-id="loading-dots">
      <div className={styles.loadingDotsDot1} />
      <div className={styles.loadingDotsDot2} />
      <div className={styles.loadingDotsDot3} />
      <div className={styles.loadingDotsDot4} />
      <div className={styles.loadingDotsDot5} />
      <div className={styles.loadingDotsDot6} />
      <div className={styles.loadingDotsDot7} />
      <div className={styles.loadingDotsDot8} />
      <div className={styles.loadingDotsDot9} />
      <div className={styles.loadingDotsDot10} />
      <div className={styles.loadingDotsDot11} />
      <div className={styles.loadingDotsDot12} />
    </div>
  )
}

LoadingDots.defaultProps = {
  sm: false,
  lg: false,
  white: false,
}

export default LoadingDots
