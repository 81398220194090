// @flow
import React, {type Node} from 'react'
import {useFragment, graphql} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import {type UseAsValue} from 'utils/MiscUtils/idOrDatabaseId'
import RoleSelector from 'components/roles/RoleSelector'
import type {OnRoleChange} from '../RoleSelector'
import type {
  CircleRoleSelector_circle$key as CircleKey,
} from './__generated__/CircleRoleSelector_circle.graphql'

type Props = $ReadOnly<{
  circle: CircleKey,
  useOnlyRolesFromCircle: boolean,
  circleValue: string | null,
  label: string,
  useIndividualActions: boolean,
  onRoleChange: ?OnRoleChange,
  useAsValue: UseAsValue,
  value: string | null,
  onChange: (FakeEvent, boolean | string | null) => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
}>

const circleFragment = graphql`
  fragment CircleRoleSelector_circle on Circle {

    supportedRole {
      id
      isExpanded

      ...RoleSelector_roles
    }

    roles {
      edges {
        node {
          ...RoleSelector_roles
        }
      }
    }
  }
`

function CircleRoleSelector({circle: circleKey, ...roleSelectorProps}: Props): Node {
  const circle = useFragment(circleFragment, circleKey)

  const roles = (circle.supportedRole && !circle.supportedRole.isExpanded)
    ? [circle.supportedRole]
    : liftNodes(circle.roles)

  return (
    <RoleSelector
      roles={roles}
      {...roleSelectorProps}
    />
  )
}

export default CircleRoleSelector
