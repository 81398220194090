// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  AddAmendmentInput as Input,
  AddAmendmentMutationResponse as Response,
} from './__generated__/AddAmendmentMutation.graphql'

const mutation = graphql`
  mutation AddAmendmentMutation($input: AddAmendmentInput!) {
    addAmendment(input: $input) {
      amendmentEdge {
        node {
          id
          viewerCanUpdate

          ...Section_amendment
          ...EditAmendmentBody_amendment
        }
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<mixed> => {
  const variables = {
    input,
  }

  const configs = [{
    type: 'RANGE_ADD',
    parentID: input.organizationId,
    connectionInfo: [{
      key: 'Amendments_amendments',
      rangeBehavior: 'append',
    }],
    edgeName: 'amendmentEdge',
  }]

  return createMutationPromise({mutation, variables, configs})
}

export type {Input, Response}
