import _ from 'underscore'
import {curry, reject, compose, prop} from 'ramda'
import util from 'utils/MiscUtils'
import getIDGenerator from 'utils/getIDGenerator'

const generateID = getIDGenerator()

const isAssociative = (x) => _.isArray(x) || _.isObject(x) || _.isArguments(x)

const SetData = {
  // TODO: Merge in my documentation on setData
  setData() {
    const args = _.toArray(arguments)
    const type = args.shift() || util.error('type required')
    const options = isAssociative(args[args.length - 2]) ? args.pop() : {}
    let data = args.pop()
    const id = args.length == 0 ? null : args.shift() || util.error('id required')
    const attrName = args.shift()
    const skipIfChangedSince = options.skipIfChangedSince
      && parseInt(options.skipIfChangedSince, 10)

    if (data && !options.skipDataProcessing)
      data = this._standardizeIncomingData(data)

    const shouldSkip = curry((time, modelType, modelId) => {
      if (!modelId)
        return false

      const model = this.getModel(modelType, modelId)
      return model && model.didChangeSince(parseInt(time, 10))
    })(skipIfChangedSince, type)

    if (_.isArray(data)) {
      data = reject(compose(shouldSkip, prop('id')), data)
      if (data.length == 0) {
        // if we filtered out everything, bail
        util.debug('ignoring stale setData request')
        return
      }
    } else if (shouldSkip(id || data.id)) {
      util.debug('ignoring stale setData request')
      return
    }

    if (id === null && _.isArray(data)) {
      // MULTI-ADD
      const result = this.getCollection(type).merge(data)

      // if we're told that this is data related to specific type & list of resources
      // (ie. it came in a 'linked' section from API request)
      // then record that in metadata so later we know not to re-request that data
      if (options.relatedToType && options.relatedToResources) {
        const primaryType = options.relatedToType
        const primaryData = options.relatedToResources

        _.each(primaryData, (resource) => {
          this.setRelationshipLoaded(primaryType, resource.id, type)
        })
      }
      return result
    } if (id === null && data.id) {
      // ADD EXISTING (ie. exists on server)
      return this.getCollection(type).add(data, {sync: false})
    } if (id === null && !data.id) {
      // CREATE NEW
      return this._addLocalModel(type, data)
        || util.error(`setData: _addLocalModel returned a falsey id given type ${type} and data: ${JSON.stringify(data)}`)
    } if (id && data == null) {
      // REMOVE
      this.getCollection(type).remove(this.getModel(type, id))
      this.setMetadata(type, id, 'removed', true)
    } else if (id && data) {
      // UPDATE
      let dataToSet = data
      if (attrName) {
        dataToSet = {}
        dataToSet[attrName] = data
      }
      const model = this.getModel(type, id) || util.error("couldn't find requested model to update")
      if (!model.id && util.isLocalId(id) && dataToSet.id) {
        if (util.isLocalId(data.id))
          util.error('invalid server id')

        this._localIdToServerIdMap[id] = dataToSet.id
      }

      if (dataToSet.links) {
        const origLinks = model.attributes.links || {}
        dataToSet = _.extend({}, dataToSet, {links: _.extend({}, origLinks, dataToSet.links)})
      }

      model.set(dataToSet)
    } else {
      util.error('invalid arguments to setData', arguments)
    }
  },

  setRelationshipLoaded(type, id, association) {
    this.setMetadata(type, id, `loaded_${association}`, true)
  },

  isRelationshipLoaded(type, id, association) {
    return this.getMetadata(type, id, `loaded_${association}`)
  },

  isResourceTypeLoaded(type) {
    return this._metadata[type].collection_loaded
  },

  setResourceTypeLoaded(type) {
    this._metadata[type].collection_loaded = true
  },

  setMetadata(type, id, entry, value) {
    if (!this._metadata[type][id])
      this._metadata[type][id] = {}

    this._metadata[type][id][entry] = value
  },

  _standardizeIncomingData(data) {
    if (_.isArray(data))
      return _.map(data, this._convertIdAttrsToLinks.bind(this))

    return this._convertIdAttrsToLinks(data)
  },

  _convertIdAttrsToLinks(data) {
    const result = _.clone(data)

    Object.keys(result).forEach((key) => {
      if (!result.hasOwnProperty(key))
        return

      // hack up to set links if we get _id attrs
      // for now, keep original _id attrs for compatibility
      const match = key.match(/^(\w+)_id$/)
      if (match && !(key == 'external_id')) {
        result.links = result.links || {}
        result.links[match[1]] = data[key]
      }
    })

    return result
  },

  // private
  _addLocalModel(type, data) {
    const localId = generateID()
    const coll = this.getCollection(type)

    // eslint-disable-next-line new-cap
    const model = new coll.model(data)

    model._localId = localId
    this._localIdToBackboneCidMap[localId] = model.cid
    coll.add(model, {sync: false})
    return localId
  },
}

export default SetData
