// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import Markdown from 'components/ui/Markdown'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import type {
  ProjectDescription_project$key as ProjectKey,
} from './__generated__/ProjectDescription_project.graphql'

type Props = $ReadOnly<{
  project: ProjectKey,
}>

const projectFragment = graphql`
  fragment ProjectDescription_project on Project {
    description(format: MARKDOWN)

    organization {
      ...WYSIWYGViewerWithFeatureFlag_organization
    }
  }
`

function ProjectDescription({project: projectKey}: Props): Node {
  const project = useFragment(projectFragment, projectKey)

  return (
    <WYSIWYGViewerWithFeatureFlag
      value={project.description}
      organization={project.organization}
      fallbackProps={{
        disallowedTypes: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        unwrapDisallowed: true,
        source: project.description,
      }}
      fallbackComponent={Markdown}
    />
  )
}

export default ProjectDescription
