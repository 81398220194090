// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import type {Output} from './types'

type Props = {|
  output: Output,
|}

function OutputPerson({output}: Props): Node {
  return (
    <Fragment>
      <div className="label">
        {I18n.t('shared.person')}
      </div>
      <div className="person">
        {output.person?.name || I18n.t('shared.none')}
      </div>
    </Fragment>
  )
}

export default (createFragmentContainer(OutputPerson, {
  output: graphql`
    fragment OutputPerson_output on TacticalOutput {
      person {
        name
      }
    }
  `,
}): RelayFragmentContainer<typeof(OutputPerson)>)
