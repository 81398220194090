// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateTacticalOutputInput as Input,
  UpdateTacticalOutputMutationResponse as Response,
} from './__generated__/UpdateTacticalOutputMutation.graphql'

const mutation = graphql`
  mutation UpdateTacticalOutputMutation($input: UpdateTacticalOutputInput!) {
    updateTacticalOutput(input: $input) {
      output {
        ...Output_output
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  return createMutationPromise({mutation, variables})
}
export type {Input, Response}
