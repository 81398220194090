/**
 * @flow
 * @relayHash 0e2117fd10666b03713b4d48d28a8257
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChecklistsItemsViewerSection_checklistItems$ref = any;
export type ChecklistReportedBy = "CIRCLE_MEMBERS" | "ROLE_FILLERS";
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type AddChecklistItemInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  description: string,
  frequency?: ?Frequency,
  customFrequencyId?: ?string,
  roleId: string,
  reportedBy: ChecklistReportedBy,
  link?: ?string,
  privateToCircle?: ?boolean,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddViewerChecklistItemMutationVariables = {|
  input: AddChecklistItemInput
|};
export type AddViewerChecklistItemMutationResponse = {|
  +addChecklistItem: ?{|
    +checklistItemEdge: ?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: ChecklistsItemsViewerSection_checklistItems$ref,
      |}
    |}
  |}
|};
export type AddViewerChecklistItemMutation = {|
  variables: AddViewerChecklistItemMutationVariables,
  response: AddViewerChecklistItemMutationResponse,
|};
*/


/*
mutation AddViewerChecklistItemMutation(
  $input: AddChecklistItemInput!
) {
  addChecklistItem(input: $input) {
    checklistItemEdge {
      node {
        id
        ...ChecklistsItemsViewerSection_checklistItems
      }
    }
  }
}

fragment ChecklistsItemsViewerSection_checklistItems on ChecklistItem {
  frequency
  localizedFrequency
  id
  appliesToAllMembers
  databaseId
  description
  link
  localizedRoleName
  isGlobal
  privateToCircle
  position
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  ...PersonChecklistItem_item
}

fragment PersonChecklistItem_item on ChecklistItem {
  id
  description
  link
  localizedRoleName
  isGlobal
  privateToCircle
  viewerCanSeeEditIcon
  circle {
    id
    databaseId
    localizedName
    supportedRole {
      isDisplayedAsCircle
      id
    }
    organization {
      databaseId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddViewerChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddChecklistItemPayload",
        "kind": "LinkedField",
        "name": "addChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItemEdge",
            "kind": "LinkedField",
            "name": "checklistItemEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChecklistItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ChecklistsItemsViewerSection_checklistItems"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddViewerChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddChecklistItemPayload",
        "kind": "LinkedField",
        "name": "addChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItemEdge",
            "kind": "LinkedField",
            "name": "checklistItemEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChecklistItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localizedFrequency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appliesToAllMembers",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localizedRoleName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGlobal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privateToCircle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "position",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "onRestrictedPlan",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFrequency",
                    "kind": "LinkedField",
                    "name": "customFrequency",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "universalUnits",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanSeeEditIcon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Circle",
                    "kind": "LinkedField",
                    "name": "circle",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localizedName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "supportedRole",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDisplayedAsCircle",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0e2117fd10666b03713b4d48d28a8257",
    "metadata": {},
    "name": "AddViewerChecklistItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24d378f41e6dc2b7955e116a30bd5467';

module.exports = node;
