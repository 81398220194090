import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const AgendaItem = GlassFrogModel.extend({
  defaults: {
    completed: false,
  },
}, {
  resourceName: 'agenda_items',
})

export default AgendaItem
