/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChecklistsItemsViewerSection_checklistItems$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistListBody_viewer$ref: FragmentReference;
declare export opaque type ChecklistListBody_viewer$fragmentType: ChecklistListBody_viewer$ref;
export type ChecklistListBody_viewer = {|
  +checklistItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: ChecklistsItemsViewerSection_checklistItems$ref
      |}
    |}>
  |},
  +$refType: ChecklistListBody_viewer$ref,
|};
export type ChecklistListBody_viewer$data = ChecklistListBody_viewer;
export type ChecklistListBody_viewer$key = {
  +$data?: ChecklistListBody_viewer$data,
  +$fragmentRefs: ChecklistListBody_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "checklistItems"
        ]
      }
    ]
  },
  "name": "ChecklistListBody_viewer",
  "selections": [
    {
      "alias": "checklistItems",
      "args": null,
      "concreteType": "ChecklistItemConnection",
      "kind": "LinkedField",
      "name": "__Viewer_checklistItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChecklistItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChecklistItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChecklistsItemsViewerSection_checklistItems"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd8aecf19717a45500eb4a2a64eade5e9';

module.exports = node;
