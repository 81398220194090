import React from 'react'
import classNames from 'classnames'

import ArrowBack from 'components/ui/icons/ios/ArrowBack'
import IconButton from 'components/SideBar/ui/IconButton'
import styles from './index.scss'

type Props = $ReadOnly<{
  isSideBarOpen: boolean,
  toggleSidebar: () => void,
}>

function OpenCloseButton({isSideBarOpen, toggleSidebar}: Props) {
  const alt = isSideBarOpen ? I18n.t('side_nav.close') : I18n.t('side_nav.open')

  const iconClass = classNames(styles.icon, {
    [styles.iconFlipped]: !isSideBarOpen,
  })

  return (
    <IconButton alt={alt} onClick={toggleSidebar} theme="dark">
      <ArrowBack className={iconClass} />
    </IconButton>
  )
}

export default OpenCloseButton
