/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FrequencySelector_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistFormModal_circle$ref: FragmentReference;
declare export opaque type ChecklistFormModal_circle$fragmentType: ChecklistFormModal_circle$ref;
export type ChecklistFormModal_circle = {|
  +id: string,
  +supportedRole: ?{|
    +id: string,
    +isExpanded: boolean,
    +localizedName: string,
    +parentRole: ?{|
      +__typename: string
    |},
    +parentCircle: ?{|
      +id: string
    |},
  |},
  +roles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +localizedName: string,
        +parentCircle: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +organization: {|
    +id: string,
    +onRestrictedPlan: boolean,
    +$fragmentRefs: FrequencySelector_organization$ref,
  |},
  +$refType: ChecklistFormModal_circle$ref,
|};
export type ChecklistFormModal_circle$data = ChecklistFormModal_circle;
export type ChecklistFormModal_circle$key = {
  +$data?: ChecklistFormModal_circle$data,
  +$fragmentRefs: ChecklistFormModal_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v2 = {
  "alias": "parentCircle",
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChecklistFormModal_circle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isExpanded",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "parentRole",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeHiddenRoles",
          "value": true
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(includeHiddenRoles:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onRestrictedPlan",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FrequencySelector_organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efe09eb18eee9003cd086dd646d04daf';

module.exports = node;
