import _ from 'underscore'
import $ from 'jquery'
import util from 'utils/MiscUtils'
import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'
import Instruction from 'legacy/GlassFrog/misc/Instruction'

const Proposal = GlassFrogModel.extend({
  initialize() {
    return GlassFrogModel.prototype.initialize.apply(this, arguments)
  },
  instructions() {
    return this.get('instructions') || []
  },

  isDraft() {
    return this.get('status') === 'draft'
  },

  isConflicted() {
    return this.get('status') === 'draft_with_conflicts'
  },

  isAccepted() {
    return this.get('status') === 'accepted'
  },

  isEscalated() {
    return this.get('status') === 'escalated'
  },

  isProposedOutside() {
    return this.get('status') === 'proposed_outside_meeting'
  },

  withdraw(options) {
    return this.postStatusEvent('withdraw_proposal', options)
  },

  propose_outside_meeting(options) {
    return this.postStatusEvent('propose_outside_meeting', options)
  },

  accept_in_meeting(options) {
    return this.postStatusEvent('accept_in_meeting', options)
  },

  containsElection() {
    return _.any(this.instructions(), (inst) => Instruction.getFlags(inst).forRoleElect)
  },

  sha1Checksum() {
    return util.sha1(this.checksumInput())
  },

  checksumInput() {
    return JSON.stringify(
      _.map(this.instructions(), (obj) => util.sortObject(obj)),
    )
  },

  saveOptions(meetingId, options) {
    if (!meetingId)
      return options

    return _.extend(
      {headers: {'x-gf-in-meeting': meetingId}},
      options || {},
    )
  },

  postStatusEvent(eventName, options) {
    const ajaxOptions = _.defaults(options || {}, {
      type: 'POST',
      url: `${this.url()}/status_events/${eventName}`,
      headers: {
        'X-GF-REQUESTED-LOCALE': I18n.locale,
      },
      data: {},
    })
    return $.ajax(ajaxOptions)
      .done((data) => {
        gf.db.getCollection('proposals').set(data.proposals, {remove: false})
      })
  },

  isViewReady() {
    return !!(this.get('_viewHasParts') && this.get('_viewIsFullyLoaded') && !this.get('_viewHasConflicts'))
  },

  urlRoot: '/api/v3/proposals',
}, {
  resourceName: 'proposals',
  defaults: {
  },
})

export default Proposal
