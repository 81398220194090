// @flow
import React, {type Node} from 'react'
import {flatten, addIndex, map, compose, reject, isNil} from 'ramda'
import classNames from 'classnames'
import styles from './index.scss'

type Props = {|
  +items: Array<?Node>,
  +className: string,
|}

const constructHTML = (detail, i) => {
  if (!detail)
    return null

  return i === 0
    ? [
      <span key={`detail${i}`}>{detail}</span>,
    ]
    : [
      <span key={`bullet${i}`} className={styles.separatorDot}>{'•'}</span>,
      <span key={`detail${i}`}>{detail}</span>,
    ]
}

const filterAndJoin = compose(
  flatten,
  addIndex(map)((detail, i) => (
    constructHTML(detail, i)
  )),
  reject(isNil),
)

function ItemDetailRow(props: Props): Node {
  const className = classNames(styles.itemDetailRow, props.className)

  return (
    <div
      className={className}
      test-id="item-detail-row"
    >
      {filterAndJoin(props.items)}
    </div>
  )
}

ItemDetailRow.defaultProps = {
  className: '',
}

export default ItemDetailRow
