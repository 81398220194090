import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const Role = GlassFrogModel.extend({
  isSupported() {
    return !!this.getLinkedId('supporting_circle')
  },
}, {
  resourceName: 'roles',
})

export default Role
