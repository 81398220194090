// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, QueryRenderer} from 'react-relay'
import environment from 'environment'
import {withErrorCheck} from 'decorators'
import Preload from 'components/ui/Preload'
import type {Variables, Response} from './types'
import TacticalMeetingChecklistItemsView from './TacticalMeetingChecklistItemsView'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  circleDatabaseId: string,
}>

const TacticalMeetingChecklistItemsQuery = graphql`
  query TacticalMeetingChecklistItemsQuery($circleDatabaseId: String!, $orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      circle(databaseId: $circleDatabaseId) {
        ...TacticalMeetingChecklistItemsView_circle
      }
    }
  }
`

function ViewRenderer({organization: org}: Response) {
  return (org && org.circle)
    ? <TacticalMeetingChecklistItemsView circle={org.circle} />
    : <Preload />
}

const renderQuery = withErrorCheck(ViewRenderer)

function TacticalMeetingChecklistItems({orgDatabaseId, circleDatabaseId}: Props): Node {
  const variables: Variables = {orgDatabaseId, circleDatabaseId}

  return (
    <QueryRenderer
      environment={environment}
      query={TacticalMeetingChecklistItemsQuery}
      variables={variables}
      render={renderQuery}
    />
  )
}

export default TacticalMeetingChecklistItems
