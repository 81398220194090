// @flow
import type {Node} from 'react'
import React from 'react'
import {Field} from 'formik'
import type {FormikProps} from 'formik'

import calcRoi from 'utils/projects/calcRoi'
import HorizontalGroup from 'components/ui/forms/HorizontalGroup'
import Input from 'components/ui/forms/Input'
import type {Values} from '..'

declare var gf: Object

type Props = $ReadOnly<{
  disabled: boolean,
  formikProps: FormikProps<Values>,
}>

function ProjectRoiInput({disabled, formikProps}: Props): Node {
  const roi = calcRoi(
    parseInt(formikProps.values.value, 10),
    parseInt(formikProps.values.effort, 10) || 1,
  )

  const valueTooltipText = gf.app.orgOnV5()
    ? I18n.t('projects.v5.value_tip')
    : I18n.t('projects.value_tip')

  return (
    <HorizontalGroup>
      <Field
        as={Input}
        disabled={disabled}
        label={I18n.t('projects.my_projects.value')}
        min="1"
        name="value"
        placeholder="10"
        step="1"
        test-id="project-value"
        tooltip={valueTooltipText}
        type="number"
        warning={formikProps.errors.value}
      />
      <Field
        as={Input}
        disabled={disabled}
        label={I18n.t('projects.my_projects.effort')}
        min="1"
        name="effort"
        placeholder="1"
        step="1"
        test-id="project-effort"
        tooltip={I18n.t('projects.effort_tip')}
        type="number"
        warning={formikProps.errors.effort}
      />
      <Field
        as={Input}
        disabled
        label={I18n.t('projects.my_projects.roi')}
        name="roi"
        test-id="project-roi"
        tooltip={I18n.t('projects.roi_tip')}
        value={roi || 'n/a'}
      />
    </HorizontalGroup>
  )
}

export default ProjectRoiInput
