/**
 * @flow
 * @relayHash 7e480aa5e3266dc847dfdc1f206e0e35
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileChecklistsView_organization$ref = any;
type ProfileChecklistsView_person$ref = any;
export type ProfileChecklistsQueryVariables = {|
  orgDatabaseId: string,
  personDatabaseId: string,
|};
export type ProfileChecklistsQueryResponse = {|
  +organization: ?{|
    +person: ?{|
      +$fragmentRefs: ProfileChecklistsView_person$ref
    |},
    +$fragmentRefs: ProfileChecklistsView_organization$ref,
  |}
|};
export type ProfileChecklistsQuery = {|
  variables: ProfileChecklistsQueryVariables,
  response: ProfileChecklistsQueryResponse,
|};
*/


/*
query ProfileChecklistsQuery(
  $orgDatabaseId: String!
  $personDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...ProfileChecklistsView_organization
    person(databaseId: $personDatabaseId) {
      ...ProfileChecklistsView_person
      id
    }
    id
  }
}

fragment ChecklistListBody_viewer on Person {
  checklistItems(first: 100) {
    edges {
      node {
        ...ChecklistsItemsViewerSection_checklistItems
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ChecklistList_organization on Organization {
  id
  onRestrictedPlan
  rules {
    ruleKey
    ruleValue
    id
  }
}

fragment ChecklistList_viewer on Person {
  ...ChecklistListBody_viewer
}

fragment ChecklistsItemsViewerSection_checklistItems on ChecklistItem {
  frequency
  localizedFrequency
  id
  appliesToAllMembers
  databaseId
  description
  link
  localizedRoleName
  isGlobal
  privateToCircle
  position
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  ...PersonChecklistItem_item
}

fragment PersonChecklistItem_item on ChecklistItem {
  id
  description
  link
  localizedRoleName
  isGlobal
  privateToCircle
  viewerCanSeeEditIcon
  circle {
    id
    databaseId
    localizedName
    supportedRole {
      isDisplayedAsCircle
      id
    }
    organization {
      databaseId
      id
    }
  }
}

fragment ProfileChecklistsView_organization on Organization {
  viewer {
    id
  }
  ...ChecklistList_organization
}

fragment ProfileChecklistsView_person on Person {
  id
  ...ChecklistList_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "personDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onRestrictedPlan",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileChecklistsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileChecklistsView_person"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileChecklistsView_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileChecklistsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Rule",
            "kind": "LinkedField",
            "name": "rules",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ruleKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ruleValue",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ChecklistItemConnection",
                "kind": "LinkedField",
                "name": "checklistItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChecklistItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChecklistItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "frequency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedFrequency",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appliesToAllMembers",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedRoleName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGlobal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "privateToCircle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "position",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomFrequency",
                            "kind": "LinkedField",
                            "name": "customFrequency",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "universalUnits",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerCanSeeEditIcon",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isDisplayedAsCircle",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "checklistItems(first:100)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Viewer_checklistItems",
                "kind": "LinkedHandle",
                "name": "checklistItems"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "7e480aa5e3266dc847dfdc1f206e0e35",
    "metadata": {},
    "name": "ProfileChecklistsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ba13d73d24b6196c576b3cf1d6bfb32';

module.exports = node;
