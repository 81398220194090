import Backbone from 'backbone'
import _ from 'underscore'
import {prop} from 'ramda'
import util from 'utils/MiscUtils'
import GlassFrogStore from './GlassFrogStore'

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */

const GlassFrogModel = Backbone.Model.extend({
  initialize() {
    // FIXME: be to get store passed in instead of creating dependency on global variable
    this.store = gf.app.store || util.error('global gf.app.store must be available')
    // FIXME: models shouldn't have access to a dispatcher, since the changes should all
    //  be initiated in actions above the model layer.  however to support views that don't
    //  use actions and call model.save/create/sync directly, we need to implement sync..
    //  and to be as consistent as possible we have sync dispatch actions.
    this.dispatcher = gf.app.dispatcher || util.error('global gf.app.dispacher must be available')
    const now = new Date().getTime()
    this._updatedAt = now
    this._linksUpdatedAt = now
  },

  couldHaveChangedSince(time) {
    return this._updatedAt >= time
  },

  didChangeSince(time) {
    if (!time || !this._updatedAt)
      return undefined

    return this._updatedAt > time
  },

  linksCouldHaveChangedSince(time) {
    return this._linksUpdatedAt >= time
  },

  getLinkedId(relName, options) {
    // assumes singular relation / returning value
    if (options && options.viaAttr)
      return this.attributes[options.viaAttr]

    const linkData = this.attributes.links

    if (linkData)
      return linkData[relName]

    util.warn("model doesn't have links", this)
    return undefined
  },

  getLinkedModels(relName, options) {
    const linkedIds = this.getLinkedIds(relName)
    const lModels = []
    for (const i in linkedIds) {
      if (!linkedIds.hasOwnProperty(i))
        continue

      const mod = this.store.getModel(relName, linkedIds[i], options)

      if (mod)
        lModels.push(mod)
    }
    return lModels
  },

  // Backlinked: a model that has an Relation that points back to "me"
  // we need this in some cases where the API doesn't provide forward links.
  //
  // An implementation note: when we do need it, we'll likely be looping over all
  // the models of a type for the whole org, and it will likely be happening inside
  // a getData() call or a component shouldUpdate check, which are things we really want to be fast.
  // Hence the hand-coded loop.  -- LWH
  getBacklinkedModels(relName, options) {
    const models = this.store.getCollection(relName).models
    const blRelNames = this._typesForBacklink()
    const blModels = []
    const id = this.id

    _.each(blRelNames, (blRelName) => {
      for (let i = 0; i < models.length; i++) {
        const attrs = models[i].attributes
        let linkedIds

        if (options && options.fromAttr) {
          linkedIds = attrs[options.fromAttr]
        } else {
          if (!attrs.links)
            continue

          linkedIds = attrs.links[blRelName]
        }

        if (linkedIds == null)
          continue

        if (linkedIds == undefined)
          util.error(`no relation ${blRelName} on ${relName}`)

        if (linkedIds == id) {
          blModels.push(models[i])
          continue
        }

        let j = linkedIds.length
        while (j--) {
          if (linkedIds[j] == id) {
            blModels.push(models[i])
            break
          }
        }
      }
    })
    return blModels
  },

  getBacklinkedIds(relName, options) {
    return _.map(this.getBacklinkedModels(relName, options), prop('id'))
  },

  getLinkedIds(relName) {
    // assumes plural relation / returning array
    const linkData = this.attributes.links

    if (linkData)
      return linkData[relName]

    util.warn("model doesn't have links", this)
    return undefined
  },

  _typesForBacklink() {
    switch (this.type()) {
      case 'governance_meetings':
      case 'tactical_meetings':
        return ['meeting']

      case 'person':
      case 'people':
        return ['person', 'people']

      case 'supporting_circle':
        return ['circle']

      default:
        return [this.store.normalizeTypeToSingular(this.type())]
    }
  },

  getBacklinkedModel(relName) {
    const models = this.getBacklinkedModels(relName)
    return models ? models[0] : null
  },

  getBacklinkedId(relName) {
    const ids = this.getBacklinkedIds(relName)
    return ids ? ids[0] : null
  },

  urlRoot() {
    return `/api/v3/${this.constructor.resourceName}`
  },
  toggle(attr) {
    this.set(attr, !this.get(attr))
  },

  type() {
    return this.constructor.resourceName
  },

  parse(data) {
    return data
  },

  sync(method, model, options) {
    const actionType = {
      create: 'BB_SYNC_CREATE',
      update: 'BB_SYNC_UPDATE',
      patch: 'BB_SYNC_UPDATE',
      delete: 'BB_SYNC_DELETE',
      read: 'BB_SYNC_READ',
    }[method] || util.error("can't sync method: ", method)

    this.dispatcher.dispatch({
      type: actionType,
      model,
      options,
    })
  },
})

GlassFrogModel.extend = function () {
  const klass = Backbone.Model.extend.apply(this, arguments)
  GlassFrogStore.registerModelClass(klass.resourceName, klass)
  return klass
}

export default GlassFrogModel
