// @flow
import type {Element} from 'react'
import React from 'react'
import {formatDistanceStrict, parseISO, isValid} from 'date-fns'

import util from 'utils/MiscUtils/main'
import {configWithLocale} from 'utils/dateFnsLocale'

type Props = $ReadOnly<{
  createdAt: string,
}>

function Age({createdAt}: Props): Element<"span"> {
  const maybeDate = parseISO(createdAt)
  const date = isValid(maybeDate) ? maybeDate : new Date()

  const age = util.capitalizeFirstLetter(
    formatDistanceStrict(date, new Date(), configWithLocale),
  )

  const normalizedAge = I18n.t('projects.my_projects.age_label', {age})

  return (
    <span>{normalizedAge}</span>
  )
}

export default Age
