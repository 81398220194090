/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomGovernanceCircles_circles$ref = any;
type Section_organization$ref = any;
type Section_rule$ref = any;
export type RuleValue = "CENTRALIZED" | "CUSTOM" | "EXISTING" | "HOLACRACY" | "MODULAR_ADOPTION_BY_CIRCLE" | "UNIVERSAL";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RulesSections_organization$ref: FragmentReference;
declare export opaque type RulesSections_organization$fragmentType: RulesSections_organization$ref;
export type RulesSections_organization = {|
  +name: string,
  +constitutionRatifiers: ?string,
  +onRestrictedPlan: boolean,
  +isModularAdoptionByCircleEnabled: boolean,
  +circles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +isGovernanceEnabled: boolean,
        +$fragmentRefs: CustomGovernanceCircles_circles$ref,
      |}
    |}>
  |},
  +rules: $ReadOnlyArray<{|
    +ruleKey: string,
    +ruleValue: RuleValue,
    +$fragmentRefs: Section_rule$ref,
  |}>,
  +viewer: {|
    +routes: {|
      +constitutionUrl: string,
      +articleTargets: {|
        +article1: string,
        +article2: string,
        +article3: string,
        +article4: string,
        +article5: string,
      |},
    |}
  |},
  +$fragmentRefs: Section_organization$ref,
  +$refType: RulesSections_organization$ref,
|};
export type RulesSections_organization$data = RulesSections_organization;
export type RulesSections_organization$key = {
  +$data?: RulesSections_organization$data,
  +$fragmentRefs: RulesSections_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RulesSections_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constitutionRatifiers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onRestrictedPlan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isModularAdoptionByCircleEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CircleConnection",
      "kind": "LinkedField",
      "name": "circles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CircleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Circle",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isGovernanceEnabled",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CustomGovernanceCircles_circles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rule",
      "kind": "LinkedField",
      "name": "rules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ruleKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ruleValue",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Section_rule"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Routes",
          "kind": "LinkedField",
          "name": "routes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "constitutionUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ConstitutionArticleTargets",
              "kind": "LinkedField",
              "name": "articleTargets",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "article1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "article2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "article3",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "article4",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "article5",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Section_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7212760745108a5e1a55978fab219949';

module.exports = node;
