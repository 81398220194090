/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TacticalOutputType = "ACTION" | "INFORMATION" | "PROJECT" | "TENSION" | "TRIGGER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputOwner_output$ref: FragmentReference;
declare export opaque type OutputOwner_output$fragmentType: OutputOwner_output$ref;
export type OutputOwner_output = {|
  +type: TacticalOutputType,
  +owner: ?{|
    +localizedName: string,
    +isIndividualAction: boolean,
  |},
  +$refType: OutputOwner_output$ref,
|};
export type OutputOwner_output$data = OutputOwner_output;
export type OutputOwner_output$key = {
  +$data?: OutputOwner_output$data,
  +$fragmentRefs: OutputOwner_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOwner_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localizedName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isIndividualAction",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0c990e9efac245a1e431a05ae13e779a';

module.exports = node;
