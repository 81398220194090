/**
 * @flow
 * @relayHash b2fe2d311f91475113fae10adca523b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TryDownloadableReportInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  options: any,
  organizationId: string,
  reportName: string,
  roleId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type TryDownloadableReportMutationVariables = {|
  input: TryDownloadableReportInput
|};
export type TryDownloadableReportMutationResponse = {|
  +tryDownloadableReport: ?{|
    +asyncRequired: ?boolean,
    +downloadUrl: ?string,
    +message: ?string,
  |}
|};
export type TryDownloadableReportMutation = {|
  variables: TryDownloadableReportMutationVariables,
  response: TryDownloadableReportMutationResponse,
|};
*/


/*
mutation TryDownloadableReportMutation(
  $input: TryDownloadableReportInput!
) {
  tryDownloadableReport(input: $input) {
    asyncRequired
    downloadUrl
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TryDownloadableReportPayload",
    "kind": "LinkedField",
    "name": "tryDownloadableReport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "asyncRequired",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TryDownloadableReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TryDownloadableReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b2fe2d311f91475113fae10adca523b7",
    "metadata": {},
    "name": "TryDownloadableReportMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b657e5d9d7ece49aae30ca5a201d26e';

module.exports = node;
