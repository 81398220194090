import {useMemo} from 'react'
import {filter, pipe, pluck, uniq, uniqBy} from 'ramda'
import type {Frequency, CustomFrequency} from 'constants/frequencies'
import type {UnifiedFrequency} from 'utils/MiscUtils/frequencyHelper'
import {unifyFrequencies, sortFrequencies} from 'utils/MiscUtils/frequencyHelper'
import type {AbstractMetric} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection/types'

type Groups<T> = $ReadOnlyArray<{
  frequency: UnifiedFrequency,
  metrics: $ReadOnlyArray<T>,
}>

const extractCustomFrequencies: <T>($ReadOnlyArray<T>) => $ReadOnlyArray<CustomFrequency> = pipe(
  pluck('customFrequency'),
  filter(Boolean),
  uniqBy(({id}) => id),
)

const extractFrequencies: <T>($ReadOnlyArray<T>) => $ReadOnlyArray<Frequency> = pipe(
  pluck('frequency'),
  filter(Boolean),
  uniq,
)

function useGroupedMetrics<T: AbstractMetric>(metrics: $ReadOnlyArray<T>):
  [Groups<T>, $ReadOnlyArray<UnifiedFrequency>] {
  return useMemo(() => {
    const customFrequencies = extractCustomFrequencies(metrics)
    const frequencies = extractFrequencies(metrics)

    const allFrequencies = unifyFrequencies(frequencies, customFrequencies)

    const sortedFrequencies = sortFrequencies(allFrequencies)

    const mappedMetrics = metrics.map((m) => ({
      ...m,
      frequencyId: m.customFrequency?.id || m.frequency,
    }))

    return sortedFrequencies.map((frequency) => ({
      frequency,
      metrics: mappedMetrics.filter((m) => m.frequencyId === frequency.id),
    })).filter((group) => group.metrics.length)
  }, [metrics])
}

export default useGroupedMetrics
