// @flow
import type {Element} from 'react'
import React, {useCallback} from 'react'

import PremiumCallout, {type CalloutType} from 'components/ui/PremiumCallout'
import styles from './index.scss'
import AppLink from '../ui/AppLink'

type OrganizationTabNames = 'basic_info'
  | 'core_roles'
  | 'admin/csv_imports/import_data'
  | 'settings'
  | 'rules'
  | 'role_templates'
  | 'integrations'
  | 'admin/saml_settings/edit'
  | 'exports'
  | 'metrics'

type ProfileTabNames = 'profile'
  | 'about'
  | 'subscriptions'
  | 'authority'
  | 'api'
  | 'integrations'
  | 'projects'
  | 'checklists'

type ReportTabNames = 'reports_meetings'
  | 'reports_printable_governance'
  | 'reports_projects'

type TabNames = OrganizationTabNames | ProfileTabNames | ReportTabNames

type Tab = $ReadOnly<{
  name: string,
  href: string,
  key: TabNames,
}>

type Props = $ReadOnly<{
  tabs: Tab[],
  currentTab: TabNames,
  highlightByColor: boolean,
  isOnRestricted: boolean,
}>

type RestrictedTabs = $ReadOnly<{
  [key: TabNames]: CalloutType
}>

const RESTRICTED_TABS:RestrictedTabs = {
  role_templates: 'role_templates',
  exports: 'admin_org_exports',
  'admin/saml_settings/edit': 'saml_settings',
  reports_meetings: 'reports_meetings',
  reports_projects: 'reports_projects',
  checklists: 'person_checklists',
  metrics: 'person_metrics',
}

function DefaultTabs({tabs, currentTab, highlightByColor, isOnRestricted}: Props): Element<"nav"> {
  const activeTabStyle = highlightByColor
    ? styles.tabsNavigationActiveItem
    : styles.tabsNavigationActiveItemColorless

  const activeTabStyles = useCallback((key: TabNames) => (
    key === currentTab
      ? activeTabStyle
      : null
  ), [currentTab, activeTabStyle])

  const renderRestrictedTab = useCallback((tab: Tab) => {
    const callout = RESTRICTED_TABS[tab.key]
    const id = `${callout}Callout`

    return (
      <span id={id}>
        <span>{tab.name}</span>
        <PremiumCallout callout={callout} target={id} enabled darkIcon />
      </span>
    )
  }, [])

  const renderTabLink = useCallback((tab: Tab) => (
    <li key={tab.key} className={styles.tabsNavigationItem} test-id={`tab-link-for-${tab.key}`}>
      {(isOnRestricted && RESTRICTED_TABS[tab.key] && renderRestrictedTab(tab))
        || (
        <AppLink className={activeTabStyles(tab.key)} href={tab.href}>
          {tab.name}
        </AppLink>
        )
      }
    </li>
  ), [activeTabStyles, renderRestrictedTab, isOnRestricted])

  return (
    <nav className={styles.tabsNavigation}>
      <ul className={styles.tabsNavigationList}>
        {tabs.map(renderTabLink)}
      </ul>
    </nav>
  )
}

DefaultTabs.defaultProps = {
  highlightByColor: true,
}

export default DefaultTabs
