import React from 'react'
import pako from 'pako'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import {filter, propEq, propOr, reduce, assoc, path, isEmpty, groupBy, prop, findLast} from 'ramda'
import classNames from 'classnames'
import util from 'utils/MiscUtils'
import ChartNodes from 'components/OrgNav/OrgChart/ChartNodes'
import ImportStepComponent from '../ImportStepComponent'

/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const ImportComponent = createReactClass({
  displayName: 'ImportComponent',

  propTypes: {
    requestedOrganizationId: PropTypes.string.isRequired,
  },

  getInitialState() {
    const isImportDisabled = this.props.requestedOrganizationId === '5'
    return {
      currentImport: null,
      currentInfo: {},
      timer: null,
      originalFilename: null,
      isImportDisabled,
    }
  },

  componentDidMount() {
    this.fetchImportData()
  },

  messagesFor(currentImport) {
    const importSteps = filter(
      propEq('type', 'import-steps'),
      propOr([], 'included')(currentImport),
    )
    const messages = reduce(
      (acc, step) => assoc(step.attributes.step, step.attributes.json, acc),
      {},
      importSteps,
    )
    return messages
  },

  replayStep(stepName) {
    if (!this.state.currentImport) {
      util.flash.notice('No current import.')
      return null
    }
    return $.ajax({
      type: 'PUT',
      url: `/admin/imports/${this.state.currentImport.data.id}`,
      data: {replay: stepName},
    })
      .done(this.doneOrQueryImportStatus)
      .fail(this.handleError)
  },

  fetchImportData() {
    const url = `/api/edge/organizations/${this.props.requestedOrganizationId}/latest-import`
    console.log(`fetching import data: ${url}`)
    $.ajax(url)
      .done(this.doneOrQueryImportStatus)
      .error(this.handleError)
  },

  doneOrQueryImportStatus(currentImport) {
    let shouldKeepPolling
    if (currentImport.nextStepHasStarted) {
      shouldKeepPolling = true
    } else {
      const state = this.stateForImport(currentImport)
      this.setState(state)
      shouldKeepPolling = !this.isStepComplete(state.currentInfo)
    }
    if (shouldKeepPolling) {
      setTimeout(() => {
        this.fetchImportData()
      }, 10e3)
    } else {
      ChartNodes.instance(this.props.requestedOrganizationId).reFetchData()
    }
  },

  isStepComplete(info) {
    if (!info)
      return false
    const completedAt = path(['attributes', 'completed-at'], info.currentStep)
    return !!(
      completedAt
      || isEmpty(path(['currentImport', 'included'], info))
    )
  },

  uploadFile(event) {
    event.preventDefault()

    const file = $('input[type=file]')[0].files[0]
    const reader = new FileReader()

    reader.onload = () => {
      const blob = this.compressFileContents(reader.result)

      this.sendCompressedFile(blob, file.name)
    }

    reader.readAsText(file)
  },

  compressFileContents(fileContents: String) {
    const compressed = pako.gzip(fileContents)

    return new Blob([compressed], {type: 'application/octet-stream'})
  },

  sendCompressedFile(blob: Blob, originalFileName: String) {
    const xhr = new XMLHttpRequest()
    const fd = new FormData($('#import-file-form')[0])

    fd.set('import_file', blob, originalFileName)

    xhr.onload = () => {
      console.log('new import file uploaded.')
      this.setState(this.stateForImport(xhr.response))
    }
    xhr.onerror = () => (this.handleError)

    xhr.open('POST', `/admin/organizations/${this.props.requestedOrganizationId}/imports`)
    xhr.send(fd)
  },

  handleError() {
    util.flash.notice('There was a problem on the server.')
  },

  stateForImport(currentImport) {
    currentImport = currentImport || {}
    const included = groupBy(prop('type'))(currentImport.included || [])
    const currentStepKey = path(['data', 'relationships', 'current-step', 'data'], currentImport) || {}
    const currentStep = findLast(propEq('id', String(currentStepKey.id)))(included[currentStepKey.type] || [])
    const info = {
      messages: this.messagesFor(currentImport),
      allowedSteps: path(['data', 'attributes', 'allowed-steps'], currentImport) || [],
      currentStepKey,
      currentStep,
      currentStepName: path(['attributes', 'step'], currentStep),
    }
    return {
      currentInfo: info,
      currentImport,
    }
  },

  holaspirit_api_fetch_in_progress() {
    return this.state.currentImport
      && this.state.currentImport.data
      && this.state.currentImport.data.attributes['holaspirit-api-fetch-in-progress']
  },

  importButtonClasses() {
    return classNames('btn', 'btn-primary', {
      disabled: this.state.isImportDisabled,
    })
  },

  renderImportFilename() {
    const filename = path(['data', 'attributes', 'original-filename'], this.state.currentImport)
    if (filename) {
      const originalFilename = path(['data', 'attributes', 'original-filename'], this.state.currentImport)
      return `Uploaded: ${originalFilename}. Now click on the "Set up" button to start importing from this file.`
    }
    return 'No file has been uploaded.'
  },

  renderImportComponents() {
    const info = this.state.currentInfo
    if (!this.holaspirit_api_fetch_in_progress()) {
      return (
        <div>
          <div className="actions">

            <ImportStepComponent
              title="Set up holarchy"
              caption="Creates circles and roles and includes domains and accountabilities"
              buttonText="Set up"
              stepName="set_up_holarchy"
              confirm="Are you sure? This will delete any existing roles and circles, along with
                  their associated data like meeting history, policies, metrics, checklist
                  items and projects."
              primaryClass="test-step-set-up-holarchy"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import policies"
              caption="Creates role and circle policies"
              buttonText="Import"
              stepName="import_policies"
              primaryClass="test-step-import-policies"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import notes"
              caption="Creates role and circle notes"
              buttonText="Import"
              stepName="import_role_notes"
              primaryClass="test-step-import-notes"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import metrics and checklist items"
              caption="Creates circle metrics and checklist items"
              buttonText="Import"
              stepName="import_metrics"
              primaryClass="test-step-import-metrics"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import people"
              caption="Adds people to the org without sending them welcome emails"
              buttonText="Import"
              stepName="import_people"
              primaryClass="test-step-import-people"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import role assignments"
              caption="Fills people into roles, which is important to do before importing projects"
              buttonText="Import"
              stepName="import_assignments"
              primaryClass="test-step-import-assignments"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import role projects"
              caption="Creates projects and associates them with people and roles"
              buttonText="Import"
              stepName="import_projects"
              primaryClass="test-step-import-projects"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import tensions"
              caption="Creates tensions (agenda items) and associates them with people"
              buttonText="Import"
              stepName="import_tensions"
              primaryClass="test-step-import-tensions"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import actions"
              caption="Creates actions and associates them with people, circles and projects"
              buttonText="Import"
              stepName="import_actions"
              primaryClass="test-step-import-actions"
              currentInfo={info}
              parent={this}
            />

            <ImportStepComponent
              title="Import meeting history"
              caption="Saves data for meeting history (only from Holaspirit)"
              buttonText="Import"
              stepName="import_holaspirit_meetings"
              primaryClass="test-step-import-holaspirit-meetings"
              currentInfo={info}
              parent={this}
            />
            <ImportStepComponent
              title="Send welcome emails to new users"
              caption="Sends emails with login info for folks who haven’t received emails yet."
              buttonText="Send emails"
              stepName="send_emails"
              primaryClass="test-step-import-emails"
              currentInfo={info}
              parent={this}
            />
          </div>
        </div>
      )
    }

    return null
  },

  renderHolaspiritImportWarning() {
    if (this.holaspirit_api_fetch_in_progress()) {
      return (
        <div className="alert alert-danger">
          {'Holaspirit Import is Running'}
        </div>
      )
    }

    return null
  },

  render() {
    return (
      <div className="import-component">
        <div>
          <form encType="multipart/form-data" id="import-file-form">
            <label htmlFor="import_file" className={this.importButtonClasses()}>
              {'Upload org export file'}
            </label>
            <input
              id="import_file"
              className="hide-me"
              name="import_file"
              type="file"
              disabled={this.state.isImportDisabled}
              onChange={this.uploadFile}
            />
          </form>
          <a className="underneath current-import">{this.renderImportFilename()}</a>
        </div>
        {this.renderHolaspiritImportWarning()}
        {this.renderImportComponents()}
      </div>
    )
  },
})

export default ImportComponent
