// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateRoleTemplateMutationResponse as Response,
  UpdateRoleTemplateInput as Input,
} from './__generated__/UpdateRoleTemplateMutation.graphql'

export type {
  Response,
}

const mutation = graphql`
  mutation UpdateRoleTemplateMutation(
    $input: UpdateRoleTemplateInput!
  ) {
    updateRoleTemplate(input: $input) {
      roleTemplate {
        ...EditRoleTemplateModalView_roleTemplate
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<Response> => {
  const variables = {input}

  return createMutationPromise({
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors)
        util.warn('there was a problem', errors)
    },
  })
}
