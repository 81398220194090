import util from 'utils/MiscUtils'

const EventHandling = {
  initialize() {
    this.initializeWithoutEventHandling.apply(this, arguments)

    Object.keys(this._collections).forEach((key) => {
      if (!this._collections.hasOwnProperty(key))
        return

      this.listenTo(this._collections[key], 'all', this.handleCollectionEvent)
    })
    this._eventTransactionDepth = 0
  },

  handleCollectionEvent(type, model, collection) {
    const now = new Date().getTime()

    // Note tests for the *updatedAt logic & use are in data_access_spec.js
    // under mayHaveChangedSince()

    // always update model & collection
    if (model)
      model._updatedAt = now

    if (model && model.collection)
      model.collection._updatedAt = now

    // if we're changing links on a model, note links changed on model & collection
    if (type.match(/links/)) {
      model._linksUpdatedAt = now

      if (model.collection)
        model.collection._linksUpdatedAt = now
    }

    // if we're adding/removing a model, note links & ids changed on collection
    if (type == 'add' || type == 'remove') {
      collection._idsUpdatedAt = now
      collection._linksUpdatedAt = now
    }

    if (this._eventTransactionDepth == 0) {
      // util.warn("backbone change outside action/event transaction");
      this.trigger('change')
    }
  },

  beginEventTransaction() {
    this._eventTransactionDepth += 1

    if (this._eventTransactionDepth > 1) {
      util.warn(`starting nesting eventTransaction at depth ${this._eventTransactionDepth
      } - events will not fire until all transactions have ended`)
    }
  },

  endEventTransaction() {
    this._eventTransactionDepth -= 1

    if (this._eventTransactionDepth < 0) {
      this._eventTransactionDepth = 0
      util.error('call to endEventTransaction when no transaction is open')
    }

    if (this._eventTransactionDepth == 0)
      this.trigger('change')
  },

  withSingleChangeEvent(fn, context) {
    let retVal
    try {
      this.beginEventTransaction()
      retVal = fn.call(context)
    } finally {
      this.endEventTransaction()
    }
    return retVal
  },
}

export default EventHandling
