/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Body_action$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Item_action$ref: FragmentReference;
declare export opaque type Item_action$fragmentType: Item_action$ref;
export type Item_action = {|
  +id: string,
  +privateToCircle: boolean,
  +trigger: boolean,
  +circle: ?{|
    +id: string
  |},
  +person: ?{|
    +id: string
  |},
  +$fragmentRefs: Body_action$ref,
  +$refType: Item_action$ref,
|};
export type Item_action$data = Item_action;
export type Item_action$key = {
  +$data?: Item_action$data,
  +$fragmentRefs: Item_action$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Item_action",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trigger",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Body_action"
    }
  ],
  "type": "Action",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1dda7179a79b15e6028a236d2570409';

module.exports = node;
