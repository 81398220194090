// @flow
import {useEffect, useState} from 'react'
import AppRouter from 'utils/AppRouter'

type Location = {
  absolutePathname: string,
  orgnavScopedPathname: string,
  isOrgnavScopedPathname: true,
} | {
  absolutePathname: string,
  orgnavScopedPathname: null,
  isOrgnavScopedPathname: false,
}

export default function useLocation(): Location {
  const [context, setContext] = useState(null)
  const [absoluteLocationPathname, setAbsoluteLocationPathname] = useState(window.location.pathname)

  useEffect(() => {
    const callback = (appRouterContext) => {
      if (appRouterContext)
        setContext(appRouterContext)
    }

    AppRouter.instance.subscribe(callback)

    return () => {
      AppRouter.instance.unsubscribe(callback)
      setContext(null)
    }
  }, [])

  const orgnavScopedLocation = context?.location

  useEffect(() => {
    setAbsoluteLocationPathname(window.location.pathname)
  }, [orgnavScopedLocation])

  if (context) {
    return {
      absolutePathname: absoluteLocationPathname,
      orgnavScopedPathname: context.location.pathname,
      isOrgnavScopedPathname: true,
    }
  }

  return {
    absolutePathname: absoluteLocationPathname,
    orgnavScopedPathname: null,
    isOrgnavScopedPathname: false,
  }
}
