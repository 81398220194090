/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectFormModalFragmented_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateProjectModal_organization$ref: FragmentReference;
declare export opaque type CreateProjectModal_organization$fragmentType: CreateProjectModal_organization$ref;
export type CreateProjectModal_organization = {|
  +$fragmentRefs: ProjectFormModalFragmented_organization$ref,
  +$refType: CreateProjectModal_organization$ref,
|};
export type CreateProjectModal_organization$data = CreateProjectModal_organization;
export type CreateProjectModal_organization$key = {
  +$data?: CreateProjectModal_organization$data,
  +$fragmentRefs: CreateProjectModal_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateProjectModal_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectFormModalFragmented_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2f917d7d5378a51b9ede4c25be8068a3';

module.exports = node;
