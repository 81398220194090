// @flow
import type {Element} from 'react'
import React from 'react'
import styles from './index.scss'

type Props = {
  message: string,
}

function NoItemsMessage({message}: Props): Element<"div"> {
  return (
    <div className={styles.noItems} test-id="no-items-message">
      {message}
    </div>
  )
}

export default NoItemsMessage
