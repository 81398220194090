// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import type {Output} from './types'

type Props = {|
  output: Output,
|}

const getInfo = (output: Output) => ({
  value: output.trigger,
  caption: I18n.t('tactical_meetings.handlebars.trigger_date'),
}
)

function OutputAdditionalInfo({output}: Props): Node {
  const {value, caption} = getInfo(output)

  return (
    <Fragment>
      <div className="label">
        {caption}
      </div>
      <div>
        {value}
      </div>
    </Fragment>
  )
}

export default (createFragmentContainer(OutputAdditionalInfo, {
  output: graphql`
    fragment OutputAdditionalInfo_output on TacticalOutput {
      type
      trigger
      tensionBody
    }
  `,
}): RelayFragmentContainer<typeof(OutputAdditionalInfo)>)
