// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {useFragment, graphql} from 'react-relay'

import NoMeetingsMessage from './NoMeetingsMessage'
import MeetingItem from './MeetingItem'
import type {
  MeetingsList_meetings$key as MeetingsKey,
} from './__generated__/MeetingsList_meetings.graphql'

type Props = {
  meetings: MeetingsKey,
}

const meetingsFragment = graphql`
  fragment MeetingsList_meetings on Meeting @relay(plural: true) {
    id

    ...MeetingItem_meeting
  }
`

function MeetingsList({meetings: meetingsKey}: Props): Node {
  const meetings = useFragment(meetingsFragment, meetingsKey)
  return (
    <Fragment>
      {meetings.map((meeting) => <MeetingItem key={meeting.id} meeting={meeting} />)}
      {meetings.length === 0 && <NoMeetingsMessage />}
    </Fragment>
  )
}

export default MeetingsList
