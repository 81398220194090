/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Project_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Group_projects$ref: FragmentReference;
declare export opaque type Group_projects$fragmentType: Group_projects$ref;
export type Group_projects = $ReadOnlyArray<{|
  +id: string,
  +databaseId: string,
  +status: string,
  +description: string,
  +createdAt: any,
  +roi: ?number,
  +organization: {|
    +viewerIsMember: boolean
  |},
  +circle: {|
    +shortName: string
  |},
  +$fragmentRefs: Project_project$ref,
  +$refType: Group_projects$ref,
|}>;
export type Group_projects$data = Group_projects;
export type Group_projects$key = $ReadOnlyArray<{
  +$data?: Group_projects$data,
  +$fragmentRefs: Group_projects$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Group_projects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MARKDOWN"
        }
      ],
      "kind": "ScalarField",
      "name": "description",
      "storageKey": "description(format:\"MARKDOWN\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerIsMember",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Project_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a2c3339b59f20f3a1f7b1d54d1262e53';

module.exports = node;
