/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EditOutput_output$ref = any;
type OutputActions_output$ref = any;
type OutputAdditionalInfo_output$ref = any;
type OutputDescription_output$ref = any;
type OutputOwner_output$ref = any;
type OutputPerson_output$ref = any;
type OutputProjectStatus_output$ref = any;
export type TacticalOutputType = "ACTION" | "INFORMATION" | "PROJECT" | "TENSION" | "TRIGGER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Output_output$ref: FragmentReference;
declare export opaque type Output_output$fragmentType: Output_output$ref;
export type Output_output = {|
  +databaseId: string,
  +type: TacticalOutputType,
  +error: ?string,
  +$fragmentRefs: OutputAdditionalInfo_output$ref & OutputProjectStatus_output$ref & OutputDescription_output$ref & OutputPerson_output$ref & OutputActions_output$ref & OutputOwner_output$ref & EditOutput_output$ref,
  +$refType: Output_output$ref,
|};
export type Output_output$data = Output_output;
export type Output_output$key = {
  +$data?: Output_output$data,
  +$fragmentRefs: Output_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Output_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputAdditionalInfo_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputProjectStatus_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputDescription_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputPerson_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputActions_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputOwner_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditOutput_output"
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '28b43270ddf368e5be18e2d026d192d9';

module.exports = node;
