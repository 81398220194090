// @flow
import type {Node} from 'react'
import React, {Fragment, useRef, useState} from 'react'
import getIDGenerator from 'utils/getIDGenerator'
import {FieldArray} from 'formik'
import {graphql, useFragment} from 'react-relay'
import classnames from 'classnames'

import useEnterOverride from 'utils/hooks/useEnterOverride'
import RemovableItem, {type Item} from 'components/ui/forms/RemovableItem'
import EnterNotice from 'components/proposals/Part/ui/AccordianField/EnterNotice'
import styles from './index.scss'

import type {
  RemovableItems_organization$key as OrganizationKey,
} from './__generated__/RemovableItems_organization.graphql'

type Props = $ReadOnly<{
  name: string,
  items: $ReadOnlyArray<Item>,
  itemsErrors?: any,
  organization: OrganizationKey,
  buttonInline: boolean,
}>

export type Removable = {
  +autofocus?: boolean,
  +destroy: boolean,
  +id: string,
  +isNew: boolean,
  +removable: boolean,
  +value: string,
}

const generateRandom = getIDGenerator()

export const newItem = (autofocus: boolean = true): Removable => ({
  id: generateRandom(),
  value: '',
  isNew: true,
  destroy: false,
  removable: true,
  autofocus,
})

const organizationFragment = graphql`
  fragment RemovableItems_organization on Organization {
    ...RemovableItem_organization
  }
`

function RemovableItems({
  items,
  itemsErrors,
  buttonInline,
  organization: organizationKey,
  name,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const [enterOverride, setEnterOverride] = useEnterOverride()
  const inputRef = useRef()
  const [currentIndex, setCurrentIndex] = useState(0)

  const enterNoticeContainerClass = classnames({
    [styles.enterNoticeContainerBlock]: !buttonInline,
    [styles.enterNoticeContainerFlex]: buttonInline,
  })

  const itemWarning = (index: number) => (
    itemsErrors && itemsErrors[index] && itemsErrors[index].value
  )

  const isNext = (index: number) => (currentIndex + 1) === index

  const handleEnterOverride = (
    push: (obj: Item) => void,
    index: number,
  ) => (event: SyntheticKeyboardEvent<HTMLTextAreaElement>) => {
    const isNewItem = (enterOverride === 'NEW_ITEM' && !event.altKey)
      || (enterOverride === 'NEWLINE' && event.altKey)

    const isLastItem = items.length === index + 1

    if (isNewItem) {
      event.preventDefault()
      if (!isLastItem && inputRef.current) {
        inputRef.current.focus()

        return
      }

      push(newItem())
    }
  }

  const onKeyDown = (
    push: (obj: Item) => void,
    index: number,
  ) => (event: SyntheticKeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter')
      handleEnterOverride(push, index)(event)
  }

  const onFocus = (index: number) => () => {
    if (index !== currentIndex)
      setCurrentIndex(index)
  }

  const newButton = (push) => (
    <button
      className="btn btn-sm btn-secondary"
      type="button"
      onClick={() => {
        push(newItem())
      }}
    >
      {I18n.t('shared.add')}
    </button>
  )

  return (
    <FieldArray name={name}>
      {({remove, push}) => (
        <Fragment>
          {items.map((item, index) => (
            <RemovableItem
              key={item.id}
              ref={isNext(index) ? (_ref) => { inputRef.current = _ref } : null}
              item={item}
              name={`${name}.${index}`}
              onFocus={onFocus(index)}
              onKeyDown={onKeyDown(push, index)}
              organization={organization}
              removeItem={() => remove(index)}
              warning={itemWarning(index)}
            />
          ))}
          <div className={enterNoticeContainerClass}>
            {buttonInline && newButton(push)}
            {items.length > 0 && (
              <EnterNotice
                enterOverride={enterOverride}
                changeEnterOverride={setEnterOverride}
              />
            )}
          </div>
          {!buttonInline && (
            <div className={styles.container}>
              {newButton(push)}
            </div>
          )}
        </Fragment>
      )}
    </FieldArray>
  )
}

RemovableItems.defaultProps = {
  buttonInline: false,
}

export default RemovableItems
