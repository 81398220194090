/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateActionModal_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActionProcessModal_viewer$ref: FragmentReference;
declare export opaque type ActionProcessModal_viewer$fragmentType: ActionProcessModal_viewer$ref;
export type ActionProcessModal_viewer = {|
  +$fragmentRefs: CreateActionModal_viewer$ref,
  +$refType: ActionProcessModal_viewer$ref,
|};
export type ActionProcessModal_viewer$data = ActionProcessModal_viewer;
export type ActionProcessModal_viewer$key = {
  +$data?: ActionProcessModal_viewer$data,
  +$fragmentRefs: ActionProcessModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionProcessModal_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateActionModal_viewer"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b3b37ac44853fc1206a6da50f73973f6';

module.exports = node;
