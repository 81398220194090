/**
 * @flow
 * @relayHash d41d3156aa11e653d2c9b35b3e01bcfa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AgendaTensionEditForm_agendaItem$ref = any;
export type TensionMeetingType = "GOVERNANCE" | "TACTICAL";
export type AddTensionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  body: string,
  agendaLabel?: ?string,
  meetingType?: ?TensionMeetingType,
  proposalId?: ?string,
  roleId?: ?string,
  impactedRoleId?: ?string,
  agendaItemId?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddTensionMutationVariables = {|
  input: AddTensionInput
|};
export type AddTensionMutationResponse = {|
  +addTension: ?{|
    +tensionEdge: ?{|
      +node: ?{|
        +id: string,
        +databaseId: string,
        +agendaItems: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +$fragmentRefs: AgendaTensionEditForm_agendaItem$ref
            |}
          |}>
        |},
      |}
    |}
  |}
|};
export type AddTensionMutation = {|
  variables: AddTensionMutationVariables,
  response: AddTensionMutationResponse,
|};
*/


/*
mutation AddTensionMutation(
  $input: AddTensionInput!
) {
  addTension(input: $input) {
    tensionEdge {
      node {
        id
        databaseId
        agendaItems {
          edges {
            node {
              ...AgendaTensionEditForm_agendaItem
              id
            }
          }
        }
      }
    }
  }
}

fragment AgendaTensionEditForm_agendaItem on AgendaItem {
  id
  tension {
    id
    body
    impactedRole {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddTensionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTensionPayload",
        "kind": "LinkedField",
        "name": "addTension",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TensionEdge",
            "kind": "LinkedField",
            "name": "tensionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tension",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgendaItemConnection",
                    "kind": "LinkedField",
                    "name": "agendaItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AgendaItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AgendaItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "AgendaTensionEditForm_agendaItem"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddTensionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTensionPayload",
        "kind": "LinkedField",
        "name": "addTension",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TensionEdge",
            "kind": "LinkedField",
            "name": "tensionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tension",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgendaItemConnection",
                    "kind": "LinkedField",
                    "name": "agendaItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AgendaItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AgendaItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tension",
                                "kind": "LinkedField",
                                "name": "tension",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "body",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "impactedRole",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d41d3156aa11e653d2c9b35b3e01bcfa",
    "metadata": {},
    "name": "AddTensionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8167c5f9579b67b52d9f6435544e22d';

module.exports = node;
