import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const ChecklistItem = GlassFrogModel.extend({ }, {
  resourceName: 'checklist_items',
  defaults: {
    circle_id: null,
    role_id: null,
  },
  placeholderText() {
    return I18n.t('admin.global_items.habit_or_action')
  },
})

export default ChecklistItem
