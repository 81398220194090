// @flow
import type {Node} from 'react'
import React, {useMemo, useCallback} from 'react'
import SortDropdown, {type Option} from 'components/ui/SortDropdown'
import {type GroupBy} from './types'

type Props = $ReadOnly<{
  groupBy: GroupBy,
  setGroupBy: (GroupBy) => void,
  values: GroupBy[],
}>

function GroupProjectsBy({
  groupBy,
  setGroupBy,
  values,
}: Props): Node {
  const possibleOptions = useMemo(() => (
    [{
      value: 'all',
      label: I18n.t('projects.group_by.all'),
    },
    {
      value: 'role',
      label: I18n.t('projects.group_by.role'),
    }, {
      value: 'person',
      label: I18n.t('projects.group_by.person'),
    }]
  ), [])

  const options = useMemo(() => (
    possibleOptions.filter((option) => (values.some((val) => (option.value === val))))
  ), [values, possibleOptions])

  const onSelect = useCallback((option: Option) => {
    const {value} = option

    if (value !== 'person' && value !== 'role' && value !== 'all')
      return

    setGroupBy(value)
  }, [setGroupBy])

  return (
    <SortDropdown
      test-id="group-projects-by"
      sortByLabel={I18n.t('projects.group_by.label')}
      onSelect={onSelect}
      options={options}
      selectedValue={groupBy}
    />
  )
}

GroupProjectsBy.defaultProps = {
  values: ['role', 'person', 'all'],
}

export type {GroupBy}

export default GroupProjectsBy
