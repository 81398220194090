import _ from 'underscore'

const MeetingMixin = {
  agendaItems() {
    return _.union(this.getBacklinkedModels('agenda_items'), this.getLinkedModels('agenda_items'))
  },
  currentHostId() {
    return this.getLinkedId('current_host')
  },
}

export default MeetingMixin
