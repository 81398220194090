// @flow
import type {Element, Node} from 'react'
import React, {useRef, useEffect} from 'react'
import lineClamp from 'line-clamp'

type Content = string | Node

type Props = {
  children: Content,
  className: ?string,
  tagName: string,
  clamp: number,
}

function TruncateString({children, className, tagName, clamp}: Props): Element<string> {
  const stringRef = useRef(null)
  const elementProps = {className, ref: stringRef}

  useEffect(() => {
    lineClamp(stringRef.current, clamp)
  })

  return React.createElement(tagName, elementProps, children)
}

TruncateString.defaultProps = {
  tagName: 'div',
  clamp: 1,
  className: null,
}

export default TruncateString
