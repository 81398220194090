// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'

import environment from 'environment'
import Preload from 'components/ui/Preload'

import ProfileMetrics from './ProfileMetrics'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

function MetricsTab({orgDatabaseId, personDatabaseId}: Props): Node {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<Preload />}>
        <ProfileMetrics
          orgDatabaseId={orgDatabaseId}
          personDatabaseId={personDatabaseId}
        />
      </Suspense>
    </RelayEnvironmentProvider>

  )
}

export default MetricsTab
