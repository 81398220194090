// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'

import type {Option} from 'components/ui/forms/Select'
import BasicMetricFormModal from '../BasicMetricFormModal'
import type {ValidValues, Values} from '../BasicMetricFormModal'

import type {
  MetricFormModal_organization$key as Organization,
} from './__generated__/MetricFormModal_organization.graphql'

const organizationFragment = graphql`
  fragment MetricFormModal_organization on Organization {
    onRestrictedPlan
    ...FrequencySelector_organization
  }
`

type Props<Response> = $ReadOnly<{
  initialValues: Values,
  submit: (ValidValues) => Promise<Response>,
  modalTitle: string,
  renderLeftFooterButton: (boolean) => Node,
  close: () => void,
  roles?: Object[],
  roleOptions?: $ReadOnlyArray<Option>,
  organization: Organization,
  globalItem?: boolean,
}>

function MetricFormModal<Response>({
  initialValues,
  submit,
  modalTitle,
  renderLeftFooterButton,
  close,
  roles,
  roleOptions,
  organization: organizationKey,
  globalItem,
}: Props<Response>): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  return (
    <BasicMetricFormModal
      initialValues={initialValues}
      submit={submit}
      modalTitle={modalTitle}
      renderLeftFooterButton={renderLeftFooterButton}
      close={close}
      roles={roles}
      roleOptions={roleOptions}
      organization={organization}
      globalItem={globalItem}
    />
  )
}

MetricFormModal.defaultProps = {
  renderLeftFooterButton: (): null => null,
}

export default MetricFormModal
