// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  DeleteTensionInput as Input,
  DeleteTensionMutationResponse as Response,
} from './__generated__/DeleteTensionMutation.graphql'

const mutation = graphql`
  mutation DeleteTensionMutation(
    $input: DeleteTensionInput!
  ) {
    deleteTension(input: $input) {
      deletedTensionId
    }
  }
`

export type {
  Input,
  Response,
}

export default (input: MutationInput<Input>, viewerId: string): Promise<Response> => {
  const configs = [
    {
      type: 'RANGE_DELETE',
      parentID: viewerId,
      connectionKeys: [{
        key: 'InboxNotifications_unprocessedTensions',
      }],
      pathToConnection: ['viewer', 'unprocessedTensions'],
      deletedIDFieldName: 'deletedTensionId',
    },
    {
      type: 'RANGE_DELETE',
      parentID: viewerId,
      connectionKeys: [{
        key: 'AgendaItems_tensionsDisplayedAsAgendaItem',
      }],
      pathToConnection: ['viewer', 'tensionsDisplayedAsAgendaItem'],
      deletedIDFieldName: 'deletedTensionId',
    },
  ]

  const variables = {input}
  const optimisticResponse = {
    deleteTension: {
      deletedTensionId: input.tensionId,
    },
  }

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse,
    onCompleted: (response, errors) => {
      if (errors)
        util.error('There was a problem', errors)
    },
  })
}
