/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type MeetingType = "AdministrativeChange" | "GovernanceMeeting" | "GovernanceUpdate" | "TacticalMeeting";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MeetingItem_meeting$ref: FragmentReference;
declare export opaque type MeetingItem_meeting$fragmentType: MeetingItem_meeting$ref;
export type MeetingItem_meeting = {|
  +endedAt: ?any,
  +outputCount: number,
  +startedAt: any,
  +type: MeetingType,
  +typeLabel: string,
  +historyPath: any,
  +agendaItems: {|
    +count: number
  |},
  +resourceReferences?: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +change: {|
          +summary: ?{|
            +sections: $ReadOnlyArray<any>
          |}
        |}
      |}
    |}>
  |},
  +$refType: MeetingItem_meeting$ref,
|};
export type MeetingItem_meeting$data = MeetingItem_meeting;
export type MeetingItem_meeting$key = {
  +$data?: MeetingItem_meeting$data,
  +$fragmentRefs: MeetingItem_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeetingItem_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "historyPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "completed",
          "value": true
        }
      ],
      "concreteType": "AgendaItemConnection",
      "kind": "LinkedField",
      "name": "agendaItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": "agendaItems(completed:true)"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            }
          ],
          "concreteType": "ResourceReferenceConnection",
          "kind": "LinkedField",
          "name": "resourceReferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ResourceReferenceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ResourceReference",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "change",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ChangeSummary",
                          "kind": "LinkedField",
                          "name": "summary",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "sections",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "resourceReferences(first:10)"
        }
      ],
      "type": "AdministrativeChange",
      "abstractKey": null
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
// prettier-ignore
(node/*: any*/).hash = '9a61195004c02f950ba93a9fd84e841d';

module.exports = node;
