import _ from 'underscore'
import Backbone from 'backbone'

import util from 'utils/MiscUtils'

const GlassFrogCollection = Backbone.Collection.extend({
  initialize(models, options, store) {
    this.store = store || util.error('collections need to be passed store as third argument')
    this.apiAdapter = this.store.apiAdapter || util.error('global store.apiAdapter must be available')
    const now = new Date().getTime()
    this._updatedAt = now
    this._idsUpdatedAt = now
    this._linksUpdatedAt = now
  },

  idsChangedSince(time) {
    return this._idsUpdatedAt >= time
  },

  linksCouldHaveChangedSince(time) {
    return this._linksUpdatedAt >= time
  },

  couldHaveChangedSince(time) {
    return this._updatedAt >= time
  },

  url() {
    return `/api/v3/org/${gf.app.currentOrganizationId()}${this.model.resourceName}`
  },

  merge(models, options) {
    return this.set(models, _.defaults(options || {}, {add: true, merge: true, remove: false}))
  },

  parse(data) {
    return data // stub/placeholder
  },
})

export default GlassFrogCollection
