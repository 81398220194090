// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {useFragment, graphql} from 'react-relay'

import type {OutputProjectStatus_output$key as Output} from './__generated__/OutputProjectStatus_output.graphql'

type Props = {|
  output: Output,
|}

const outputFragment = graphql`
  fragment OutputProjectStatus_output on TacticalOutput {
    projectStatus
  }
`

function OutputProjectStatus({output: outputKey}: Props): Node {
  const output = useFragment(outputFragment, outputKey)

  return (
    <Fragment>
      <div className="label">
        {I18n.t('projects.my_projects.status')}
      </div>
      <div>
        {output.projectStatus}
      </div>
    </Fragment>
  )
}

export default OutputProjectStatus
