// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import Container from 'components/SideBar/ui/Container'
import SectionPlaceholder from './SectionPlaceholder'
import UserSectionPlaceholder from './UserSectionPlaceholder'
import SearchPlaceholder from './SearchPlaceholder'
import styles from './index.scss'

type Props = $ReadOnly<{
  isOpen: boolean,
  toggleSidebar: () => void,
}>

function Placeholder({
  isOpen,
  toggleSidebar,
}: Props): Node {
  const renderUncollapsed = () => (
    <div className={styles.wrapper}>
      <UserSectionPlaceholder />
      <SectionPlaceholder>
        <SearchPlaceholder />
      </SectionPlaceholder>
      <SectionPlaceholder>
        <SectionPlaceholder.Items>
          <SectionPlaceholder.NoHeaderItem />
          <SectionPlaceholder.NoHeaderItem />
          <SectionPlaceholder.NoHeaderItem />
          <SectionPlaceholder.NoHeaderItem />
          <SectionPlaceholder.NoHeaderItem />
        </SectionPlaceholder.Items>
      </SectionPlaceholder>
      <SectionPlaceholder>
        <SectionPlaceholder.Header />
        <SectionPlaceholder.Items>
          <SectionPlaceholder.Item />
          <SectionPlaceholder.Item />
          <SectionPlaceholder.Item />
          <SectionPlaceholder.Item />
        </SectionPlaceholder.Items>
      </SectionPlaceholder>
      <SectionPlaceholder>
        <SectionPlaceholder.Header />
      </SectionPlaceholder>
      <SectionPlaceholder>
        <SectionPlaceholder.Header />
        <SectionPlaceholder.Items>
          <SectionPlaceholder.NoHeaderItem />
        </SectionPlaceholder.Items>
      </SectionPlaceholder>
    </div>
  )

  const renderSettingsSection = () => (
    <Fragment>
      <SectionPlaceholder collapsed />
      <SectionPlaceholder collapsed />
      <SectionPlaceholder collapsed />
    </Fragment>
  )

  const renderOrgSelect = () => (
    <div className={styles.orgSelect}>
      <SectionPlaceholder collapsed />
    </div>
  )

  return (
    <Container
      homePath="/"
      isOpen={isOpen}
      toggleSidebar={toggleSidebar}
      renderUncollapsed={renderUncollapsed}
      renderSettingsSection={renderSettingsSection}
      renderOrgSelect={renderOrgSelect}
    />
  )
}

export default Placeholder
