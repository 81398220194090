// @flow
import type {Node} from 'react'
import React from 'react'

import {type Option, type Value} from 'components/ui/EtcMenu/EtcSelector'
import TinySelectDropdown from 'components/ui/TinySelectDropdown'

type Props = {
  sortByLabel: ?string,
  onSelect: Option => void,
  options: Option[],
  selectedValue: Value,
  'test-id': string,
}

function SortDropdown({sortByLabel, onSelect, options, selectedValue, 'test-id': testId}: Props): Node {
  const label = sortByLabel || I18n.t('react.components.sort_dropdown.sort_by')

  return (
    <TinySelectDropdown
      caret
      test-id={testId}
      label={() => label}
      highlightedLabel={(option: ?Option) => option?.label || ''}
      onSelect={onSelect}
      options={options}
      selectedValue={selectedValue}
    />
  )
}

SortDropdown.defaultProps = {
  sortByLabel: null,
  'test-id': 'sort-menu-button',
}
export type {Option, Value}
export default SortDropdown
