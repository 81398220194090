// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'
import {graphql, useFragment} from 'react-relay'

import List from './List'
import type {
  Actions_project$key as Project,
} from './__generated__/Actions_project.graphql'

type Props = $ReadOnly<{
  project: Project,
  foldable: boolean,
}>

const projectFragment = graphql`
  fragment Actions_project on Project {    
    viewerCanAddActions

    ...List_project
  }
`

function Actions({
  project: projectKey,
  foldable,
}: Props): Element<"div"> {
  const project = useFragment(projectFragment, projectKey)

  const className = classNames('project-actions', {
    'project-actions__foldable': foldable,
  })

  return (
    <div className={className}>
      <div className="project-actions__list">
        <List
          project={project}
          expandable={project.viewerCanAddActions}
          foldable={foldable}
        />
      </div>
    </div>
  )
}

Actions.defaultProps = {
  foldable: false,
}

export default Actions
