import React from 'react'

import AppRouter from 'utils/AppRouter'
import styles from './index.scss'

type Props = $ReadOnly<{
  organizationName: string,
  organizationPath: string,
  organizationIconUrl: string,
}>

function InvitationAcceptedPage({organizationName, organizationPath, organizationIconUrl}: Props) {
  const onClick = () => {
    AppRouter.instance.redirectTo(organizationPath)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.invitationAcceptedContainer}>
        <img
          className={styles.iconStyles}
          src={organizationIconUrl}
          alt={organizationName}
        />
        <h1>{I18n.t('organization.invitations.now_part_of_organization', {organization_name: organizationName})}</h1>
        <button
          className="btn btn-primary btn-lg"
          type="button"
          onClick={onClick}
        >
          {I18n.t('organization.invitations.go_to_organization', {organization_name: organizationName})}
        </button>
      </div>
    </div>
  )
}

export default InvitationAcceptedPage
