// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateProjectInput as Input,
  UpdateProjectMutationResponse as Response,
} from './__generated__/UpdateProjectMutation.graphql'

const mutation = graphql`
  mutation UpdateProjectMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        ...Project_project
      }

      errors {
        path
        message
        severity
      }
    }
  }
`

const UpdateProjectMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  return createMutationPromise({mutation, variables})
}

export default UpdateProjectMutation
export type {Input, Response}
