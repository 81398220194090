import $ from 'jquery'
import _ from 'underscore'

// thanks to: http://www.csskarma.com/blog/required-input-fallback/
_(gf).extend({
  setupFormValidation() {
    // parse through each required input
    $('form').find(':input[required]').each(function () {
      // add a class to each required field with "required" & the input type
      // using the normal "getAttribute" method because jQuery's attr always returns "text"
      $(this).addClass(`required ${this.getAttribute('type')}`).removeAttr('required')
    })

    $.validator.addMethod('passwordStrength', function (value, element) {
      return this.optional(element)
        || /^(?=.*[a-zA-Z])(?=.*[\d\W]).{1,}$/.test(value)
    }, I18n.t('errors.messages.weak_password'))

    // Override to allow urls with embedded json
    // regex from here: http://blog.mattheworiordan.com/post/13174566389/url-regular-expression-for-links-with-or-without-the
    $.validator.methods.url = function (value, element) {
      return this.optional(element)
        || /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(value)
    }

    $('form:not([data-no-global-form-validation])').each(function () {
      $(this).validate({
        highlight(element) {
          if (!$(element).parent().hasClass('error-container'))
            $(element).wrap("<div class='error-container'>")
        },
      })
    })
  },
})

$(document)
  .ready(gf.setupFormValidation)
  .on('dialogopen', () => { setTimeout(gf.setupFormValidation, 1) })
  .on('ajax:success', gf.setupFormValidation)
