// @flow strict

export type ProcessModalType = 'Action' | 'Project' | 'AgendaTactical' | 'AgendaGovernance' | 'Proposal'

type Controls = $ReadOnly<{
  openTensionProcessingModal: (string) => void,
  openModal: ({tensionDatabaseId: ?string, processModal: ProcessModalType | null}) => void,
}>

const defaultControls: Controls = {
  openTensionProcessingModal: () => {},
  openModal: () => {},
}

let globalControls: Controls = defaultControls

function setGlobalControls(controls: Controls) {
  globalControls = controls
}

function openTensionProcessingModal(tensionDatabaseId: string) {
  globalControls.openTensionProcessingModal(tensionDatabaseId)
}

function processTensionTo(tensionDatabaseId: string, processModal: ProcessModalType) {
  globalControls.openModal({tensionDatabaseId, processModal})
}

export {
  openTensionProcessingModal,
  setGlobalControls,
  processTensionTo,
}

// Ensure this is available globally
window.openTensionProcessingModal = openTensionProcessingModal
window.processTensionTo = processTensionTo
