/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CircleAndRoleSelector_project$ref: FragmentReference;
declare export opaque type CircleAndRoleSelector_project$fragmentType: CircleAndRoleSelector_project$ref;
export type CircleAndRoleSelector_project = {|
  +circle: {|
    +id: string,
    +supportedRole: ?{|
      +id: string,
      +isDisplayedAsCircle: boolean,
      +$fragmentRefs: RoleSelector_roles$ref,
    |},
  |},
  +$refType: CircleAndRoleSelector_project$ref,
|};
export type CircleAndRoleSelector_project$data = CircleAndRoleSelector_project;
export type CircleAndRoleSelector_project$key = {
  +$data?: CircleAndRoleSelector_project$data,
  +$fragmentRefs: CircleAndRoleSelector_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CircleAndRoleSelector_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDisplayedAsCircle",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RoleSelector_roles"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '41dd3fcbd4be670bc6d99dd304cda45f';

module.exports = node;
