import * as R from 'ramda'
import util from 'utils/MiscUtils'
import Instruction from 'legacy/GlassFrog/misc/Instruction'
import ImportTensionsButtonsRefetcher from 'utils/ImportTensionsButtonsRefetcher'

const CommActions = {
  APPLY_GENERIC_INSTRUCTION(action) {
    Instruction.run(action.instruction)
  },

  SWITCH_FROM_HOST_TO_OBSERVER(action) {
    // still change host in store..
    Instruction.run(action.instruction)

    // clear out any in-progress local stuff that hasn't been persisted to server

    const localProposals = R.filter(R.compose(util.isLocalId, R.prop('id')), gf.db.getData('proposals', '*'))
    R.forEach((proposal) => {
      if (gf.db.resolveLocalId(proposal.id)) {
        util.warn('not removing saved proposal')
        return
      }
      gf.db.setData('proposals', proposal.id, null)
    }, localProposals)

    R.forEach((agendaItem) => {
      if (util.isLocalId(gf.r.proposalId(agendaItem)))
        gf.db.setData('agenda_items', agendaItem.id, 'links', {proposal: null})
    }, gf.db.getData('agenda_items', '*'))

    // Reload host-related Relay fragments
    ImportTensionsButtonsRefetcher.instance.call()
  },
}

export default CommActions
