// @flow
import type {Node} from 'react'
import React from 'react'
import environment from 'environment'
import {QueryRenderer, graphql} from 'react-relay'

import {withErrorCheck} from 'decorators'
import Preload from 'components/ui/Preload'
import ConstitutionTab from './ConstitutionTab'

import type {
  ConstitutionQueryVariables as Variables,
  ConstitutionQueryResponse as Response,
} from './__generated__/ConstitutionQuery.graphql'

const constitutionQuery = graphql`
  query ConstitutionQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...ConstitutionTab_organization
    }
  }
`

type Props = $ReadOnly<{
  orgDatabaseId: string,
  readonly: boolean,
  showDescription: boolean,
}>

function Constitution({orgDatabaseId, readonly, showDescription}: Props): Node {
  const variables: Variables = {orgDatabaseId}

  return (
    <QueryRenderer
      environment={environment}
      query={constitutionQuery}
      variables={variables}
      render={withErrorCheck((rendererProps: ?Response) => {
        const organization = rendererProps?.organization

        if (!organization)
          return <Preload />

        return (
          <ConstitutionTab
            organization={organization}
            readonly={readonly}
            showDescription={showDescription}
          />
        )
      })}
    />
  )
}

Constitution.defaultProps = {
  readonly: false,
  showDescription: false,
}

export default Constitution
