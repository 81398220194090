// @flow
import React, {Fragment} from 'react'
import {graphql, useFragment} from 'react-relay'

import type {CollectionNode} from 'utils/GraphQL/types'
import OrganizationSubscription from 'subscriptions/OrganizationSubscription'
import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import useLocation from 'utils/hooks/useLocation'
import GovernanceMeetingBanner from './GovernanceMeetingBanner'
import TacticalMeetingBanner from './TacticalMeetingBanner'

import type {
  MeetingBanners_organization$key as OrganizationKey,
  MeetingBanners_organization as Organization,
} from './__generated__/MeetingBanners_organization.graphql'

type Viewer = $PropertyType<Organization, 'viewer'>
type Circles = CollectionNode<$PropertyType<Viewer, 'circles'>>
type Circle = Circles[number]

type Props = $ReadOnly<{
  organization: OrganizationKey,
  refetch: () => Promise<null>,
}>

const organizationFragment = graphql`
  fragment MeetingBanners_organization on Organization {
    databaseId

    viewer {
      circles(canAttendMeetings: true) {
        edges {
          node {
            id
  
            ...GovernanceMeetingBanner_circle
            ...TacticalMeetingBanner_circle    
          }
        }
      }  
    }
  }
`

function MeetingBanners({
  organization: organizationKey,
  refetch,
}: Props): Array<Circle> {
  const {absolutePathname} = useLocation()

  const organization = useFragment(
    organizationFragment,
    organizationKey,
  )
  const circles = liftNodes(organization.viewer.circles)

  useSubscriptionRefetch(
    OrganizationSubscription,
    {orgDatabaseId: organization.databaseId},
    refetch,
    {},
    (data) => (
      ['MeetingStartedEvent', 'MeetingEndedEvent'].includes(data.organizationEvent?.type)
    ),
  )

  return (
    circles.map((circle) => (
      <Fragment key={circle.id}>
        <GovernanceMeetingBanner pathname={absolutePathname} circle={circle} />
        <TacticalMeetingBanner pathname={absolutePathname} circle={circle} />
      </Fragment>
    ))
  )
}

export default MeetingBanners
