// @flow
import {format} from 'date-fns'
import type {Event} from '../types'

function formatDatetime(datetime) {
  return format(datetime, "yyyyMMdd'T'HHmmssX")
}

function generateGoogleLink(event: Event): string {
  const params = new URLSearchParams({
    action: 'TEMPLATE',
    dates: `${formatDatetime(event.startDatetime)}/${formatDatetime(event.endDatetime)}`,
    location: event.location,
    text: event.title,
    details: event.description || '',
  })

  return `https://calendar.google.com/calendar/render?${params.toString()}`
}

export default generateGoogleLink
