import _ from 'underscore'
import DataRequest from './DataRequest'

const ChangedData = {
  // mayHaveChangedSince takes the same args as getData(), except prepended by a time-stamp.
  // the logic parallels getData and retrieves the same models & collections.
  // based on whether these underlying models & collections have changed, we can efficiently
  // tell if the answer to a getData() query may have changed. "may" because you might be asking
  // for specific attributes that didn't change, but if the answer here is 'no' you can be sure
  // things didn't change.
  //
  // used by GupsMixin to determine whether components need to have their state reset on a
  // store change event.
  mayHaveChangedSince() {
    const args = _.toArray(arguments)
    const time = args.shift()
    const dr = DataRequest.fromArguments(args, this)
    const coll = this.getCollection(dr.type)

    if (!dr.id) {
      if (dr.dataSpec == 'ids')
        return coll.idsChangedSince(time)
      return coll.couldHaveChangedSince(time)
    }

    const model = coll.get(dr.id)
    if (!model)
      return true // who knows..

    if (model[dr.relName] && _.isFunction(model[dr.relName])) {
      // assume based on model attributes
      return model.couldHaveChangedSince(time)
    } if (dr.relName) {
      if (dr.dataSpec == 'id' || dr.dataSpec == 'ids') {
        if (dr.options.reverseLookup) {
          const relColl = this.getCollection(dr.relName)
          return model.linksCouldHaveChangedSince(time) || relColl.linksCouldHaveChangedSince(time)
        }
        return model.linksCouldHaveChangedSince(time)
      }
      const relColl = this.getCollection(dr.relName)
      return model.linksCouldHaveChangedSince(time) || relColl.couldHaveChangedSince(time)
    }

    if (
      _.isString(dr.dataSpec) && dr.dataSpec !== '*'
      && !model.has(dr.dataSpec) && model[dr.dataSpec]
    ) {
      // this is the function case.  if we're calling some random method on the model
      // WHO KNOWS what it depends on or whether it might have changed.
      // this is a performance tanker...
      return true
    }
    return model.couldHaveChangedSince(time)
  },

  // private

}

export default ChangedData
