/**
 * @flow
 * @relayHash c3a3dd3b06e15b420bc149f9460fae76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditAmendmentBody_amendment$ref = any;
type Section_amendment$ref = any;
export type AddAmendmentInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  organizationId: string,
  title: string,
  body: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddAmendmentMutationVariables = {|
  input: AddAmendmentInput
|};
export type AddAmendmentMutationResponse = {|
  +addAmendment: ?{|
    +amendmentEdge: ?{|
      +node: ?{|
        +id: string,
        +viewerCanUpdate: boolean,
        +$fragmentRefs: Section_amendment$ref & EditAmendmentBody_amendment$ref,
      |}
    |}
  |}
|};
export type AddAmendmentMutation = {|
  variables: AddAmendmentMutationVariables,
  response: AddAmendmentMutationResponse,
|};
*/


/*
mutation AddAmendmentMutation(
  $input: AddAmendmentInput!
) {
  addAmendment(input: $input) {
    amendmentEdge {
      node {
        id
        viewerCanUpdate
        ...Section_amendment
        ...EditAmendmentBody_amendment
      }
    }
  }
}

fragment AmendmentForm_organization on Organization {
  ...WYSIWYGTextareaWithFeatureFlag_organization
  ...SectionHeader_organization
}

fragment EditAmendmentBody_amendment on Amendment {
  id
  title
  body
  organization {
    ...AmendmentForm_organization
    id
  }
}

fragment SectionHeader_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment Section_amendment on Amendment {
  id
  ...EditAmendmentBody_amendment
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAmendmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAmendmentPayload",
        "kind": "LinkedField",
        "name": "addAmendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AmendmentEdge",
            "kind": "LinkedField",
            "name": "amendmentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Amendment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Section_amendment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditAmendmentBody_amendment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAmendmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAmendmentPayload",
        "kind": "LinkedField",
        "name": "addAmendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AmendmentEdge",
            "kind": "LinkedField",
            "name": "amendmentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Amendment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "databaseId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isWysiwygEnabled",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c3a3dd3b06e15b420bc149f9460fae76",
    "metadata": {},
    "name": "AddAmendmentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a68a5d3c6359cd703c580cf5011fb2c4';

module.exports = node;
