// @flow
import type {Node} from 'react'
import React from 'react'
import {ModalHeader} from 'reactstrap'

import useUniqueId from 'utils/hooks/useUniqueId'
import DefinitionTooltip from 'components/ui/DefinitionTooltip'

import styles from './index.scss'

type Props = $ReadOnly<{
  close: () => void,
  title: string | Node,
  tooltip: string | null,
  cite: string | null,
}>

function Header({close, title, tooltip, cite}: Props): Node {
  const [id] = useUniqueId()

  return (
    <ModalHeader toggle={close} tag="div">
      <div className={styles.container}>
        <span id="modal-title">
          {title}
        </span>
        {tooltip && (
          <DefinitionTooltip
            definition={tooltip}
            target={id}
          />
        )}
      </div>
      {cite && (
        <div className={styles.container}>
          <span className={styles.cite}>
            {cite}
          </span>
        </div>
      )}
    </ModalHeader>
  )
}

Header.defaultProps = {
  tooltip: null,
  cite: null,
}

export default Header
