// @flow
import {offset} from '@floating-ui/dom'
import $ from 'jquery'

export const tourOptions = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const buttonConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      tour.next()
    },
  },
])

const buttonLinkToAdminMembers = ({tour, orgDatabaseId}) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      const url = `/admin/organizations/${orgDatabaseId}/people?shepherdTour=inviteNewMembers&shepherdStep=2`
      window.location.href = url
    },
  },
])

const buttonLinkToInviteMember = (orgDatabaseId) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => {
      const url = `/admin/organizations/${orgDatabaseId}/people?shepherdTour=inviteNewMembers&shepherdStep=2`
      window.location.href = url
    },
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      const url = `/admin/organizations/${orgDatabaseId}/people/new?shepherdTour=inviteNewMembers&shepherdStep=3`
      window.location.href = url
    },
  },
])

const buttonLinkToAdminMembers2 = ({tour, orgDatabaseId}) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      const url = `/admin/organizations/${orgDatabaseId}/people?shepherdTour=inviteNewMembers&shepherdStep=bulk_invite`
      window.location.href = url
    },
  },
])

const buttonLinkToInviteBulk = ({tour, orgDatabaseId}) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      const url = `/admin/organizations/${orgDatabaseId}/people/import?shepherdTour=inviteNewMembers&shepherdStep=bulk_invite_page`
      window.location.href = url
    },
  },
])

type Props = {
  tour: Object,
  history: Object,
  orgDatabaseId: string,
}

const tooltip = (linkText, tipKey, orgDatabaseId) => `<a href="/organizations/${orgDatabaseId}/dialogs/tooltip/${tipKey}" class="async-popover" data-container=".shepherd-element:visible">${linkText}</a>`

export const getSteps = ({tour, history, orgDatabaseId}: Props): Object => ([
  {
    id: '1',
    buttons: buttonLinkToAdminMembers({tour, history, orgDatabaseId}),
    attachTo: {
      element: '.admin-section li',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    title: 'Adding Members',
    text: [`<div><p>To add  ${tooltip('member', 'member', orgDatabaseId)}s to your organization, go to 
        the admin section in the left panel and select 'Organization Members'.</p></div>`],
  },
  {
    id: '2',
    buttons: buttonLinkToInviteMember(orgDatabaseId),
    attachTo: {
      element: '#sjs-invite_member_button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>You can add ${tooltip('member', 'member', orgDatabaseId)}s one at a time by selecting 
        'Invite New Member'.</p></div>`],
  },
  {
    id: '3',
    buttons: buttonConfig(tour),
    when: {
      show() {
        $('#sjs-invite_member_button').click()
      },
    },
    attachTo: {
      element: '#sjs-name',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: ['<div><p>The name you enter here is what will be displayed in GlassFrog.</p></div>'],
  },
  {
    buttons: buttonConfig(tour),
    attachTo: {
      element: '#sjs-email',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>The email you enter here will be sent an invitation email to join the organization and set up a 
        password.</p>
        <p>They will not receive any GlassFrog notifications until they accept the invitation.</p></div>`],
  },
  {
    buttons: buttonConfig(tour),
    attachTo: {
      element: '#sjs-admin-checkbox',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>Select the admin checkbox if you want the person to have administrative privileges in the
        application.</p></div>`],
  },
  {
    buttons: buttonLinkToAdminMembers2({tour, history, orgDatabaseId}),
    attachTo: {
      element: '#form_submit',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>When you select 'Invite' an invitation email will be sent.</p>
      <p>If your organization is in setup mode, invitations will be held until you turn off setup mode.  If you wish
      to send the invitation while in setup mode, select 'Invite Member Immediately'.</p></div>`],
  },
  {
    id: 'bulk_invite',
    buttons: buttonLinkToInviteBulk({orgDatabaseId, tour}),
    attachTo: {
      element: '#sjs-import_new_members_button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>You can also add ${tooltip('member', 'member', orgDatabaseId)}s in bulk by using the 
        'Import New Members' option.</p></div>`],
  },
  {
    id: 'bulk_invite_page',
    buttons: buttonConfig(tour),
    when: {
      show() {
        $('#sjs-import_new_members_button').click()
      },
    },
    attachTo: {
      element: '.page__main',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: ['<div><p>Once you have a .csv file as described, upload that file here.</p></div>'],
  },
  {
    buttons: buttonConfig(tour),
    attachTo: {
      element: '#sjs-import_new_members_button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>Selecting 'Import Members' will import all of the 
      ${tooltip('member', 'member', orgDatabaseId)}s and send them invitation requests or queue them up 
      if you are in setup mode.</p></div>`],
  },
])
