/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomProfileFieldsEditor_person$ref = any;
type CustomProfileFieldsList_person$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AboutTab_person$ref: FragmentReference;
declare export opaque type AboutTab_person$fragmentType: AboutTab_person$ref;
export type AboutTab_person = {|
  +viewerCanUpdateCustomSettings: boolean,
  +$fragmentRefs: CustomProfileFieldsEditor_person$ref & CustomProfileFieldsList_person$ref,
  +$refType: AboutTab_person$ref,
|};
export type AboutTab_person$data = AboutTab_person;
export type AboutTab_person$key = {
  +$data?: AboutTab_person$data,
  +$fragmentRefs: AboutTab_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AboutTab_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdateCustomSettings",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomProfileFieldsEditor_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomProfileFieldsList_person"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3b88ebbaa56ff9ac64007cbe5521ad4a';

module.exports = node;
