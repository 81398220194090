// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise from './createMutationPromise'

const mutation = graphql`
  mutation DeleteRoleTemplateMutation(
    $input: DeleteRoleTemplateInput!
  ) {
    deleteRoleTemplate(input: $input) {
      deletedRoleTemplateId
    }
  }
`

function DeleteRoleTemplateMutation(roleTemplateId: string, organizationId: string): Promise<mixed> {
  const variables = {
    input: {
      roleTemplateId,
    },
  }

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedRoleTemplateId',
  }, {
    type: 'RANGE_DELETE',
    parentID: organizationId,
    connectionKeys: [{
      key: 'RoleTemplatesList_roleTemplates',
    }],
    pathToConnection: ['organization', 'roleTemplates'],
    deletedIDFieldName: 'deletedRoleTemplateId',
  }]

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse: {
      deleteRoleTemplate: {
        deletedRoleTemplateId: roleTemplateId,
      },
    },
    onError: (e: Error) => util.error(e),
  })
}

export default DeleteRoleTemplateMutation
