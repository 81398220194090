import _ from 'underscore'
import $ from 'jquery'
import util from 'utils/MiscUtils'
import * as R from 'ramda'

/* eslint no-mixed-operators:0 */

function ApiAdapter() {
  this.initialize.apply(this, arguments)
}

_.extend(ApiAdapter.prototype, {
  initialize(config) {
    this._requestQueue = []
    this._currentRequest = null
    this._requestLog = []
    this.config = _.extend({
      defaultErrorHandler: null,
    }, config || {})
  },

  updateConfig(config) {
    this.config = _.extend(this.config, config)
  },

  // Typical use:
  // gf.app.apiAdapter.apiGet("projects", { params: params }).then(this.onResponse);
  apiGet() {
    const args = _.toArray(arguments)
    args.unshift('GET')
    return this._apiRequest(this._makeApiRequest.apply(this, args))
  },

  apiPost(type, data, options) {
    return this._apiRequest(this._makeApiRequest('POST', type, data, options || {}))
  },

  apiPatch(type, id, data, options) {
    return this._apiRequest(this._makeApiRequest('PATCH', type, id, data, options || {}))
  },

  apiDelete(type, id, options) {
    return this._apiRequest(this._makeApiRequest('DELETE', type, id, null, options || {}))
  },

  apiRequest(request) {
    return this._apiRequest(_.extend({
      data: {},
      options: {},
      deferred: $.Deferred(),
    }, request))
  },

  _apiRequest(request) {
    return this._currentRequest ? this._enqueOrUpdate(request) : this._requestImmediate(request)
  },

  apiHasEndpoint(typeName) {
    const knownToBeExcludedTypes = ['accountabilities']
    return !_.contains(knownToBeExcludedTypes, typeName)
  },

  apiHasRequested() {
    return !!util.findIn(
      this._makeMatcher(arguments),
      this._currentRequest && this._currentRequest.completed
        ? [this._currentRequest]
        : [],
      this._requestLog,
    )
  },

  apiWillRequest() {
    return !!util.findIn(this._makeMatcher(arguments), this._requestQueue)
  },

  apiHasOrWillRequest() {
    return !!util.findIn(
      this._makeMatcher(arguments),
      [this._currentRequest],
      this._requestQueue,
      this._requestLog,
    )
  },

  apiFindRequest() {
    return util.findIn(
      this._makeMatcher(arguments),
      [this._currentRequest],
      this._requestQueue,
      this._requestLog,
    )
  },

  apiResolveLocalId(localId, serverId) {
    const matchingRequests = _.filter(this._requestQueue, (r) => r.id == localId)

    _.each(matchingRequests, (request) => {
      request.id = serverId
      request.path = `/${[request.type, serverId].join('/')}`
    })
  },

  _makeMatcher(args) {
    return this._makeRequestMatcher(this._makeApiRequest.apply(this, args))
  },

  // private
  _makeRequestMatcher(r2) {
    return function (r1) {
      return (
        r2 && r1 // things exist
        && r1.method == r2.method && r1.path == r2.path // methods and paths match
        && (
          r1.id && r1.id == r2.id // ids exist && match OR
          || r1.method == 'GET' && !r1.id && !r2.id // index GET w/o ids
          || (
            // localIds are specified and match
            r1.options && r2.options && r1.options.localId
            && r1.options.localId == r2.options.localId
          )
        )
        && (
          (!r1.options.params && !r2.options.params)
          || (_.isEqual(r1.options.params, r2.options.params))
        )
      )
    }
  },

  _makeApiRequest() {
    const args = _.toArray(arguments)
    const method = args.shift()

    let type
    let id
    let data
    let pathArray
    let options

    switch (method) {
      case 'GET':
        if (_.isObject(args[args.length - 1]))
          options = args.pop()

        pathArray = args
        type = pathArray[0]
        id = pathArray[1]
        options = options || {}
        data = options && options.params || {}
        break

      case 'POST':
        type = args[0]
        id = null
        data = args[1] || util.error('data required for POST')
        options = args[2] || {}
        pathArray = options.pathArray || [type]
        break

      default:
        type = args[0]
        id = args[1]
        data = args[2]
        options = args[3] || {}
        pathArray = [type, id]

        if (!R.isNil(options.action))
          pathArray.push(options.action)

        break
    }

    if (!_.isArray(pathArray) || _.isEmpty(pathArray))
      util.error('bad path')

    let path = `/${pathArray.join('/')}`

    if (path[path.length - 1] == '/')
      util.error('bad path')

    if (options.organizationId)
      path = `/org/${options.organizationId}${path}`

    const request = {
      method,
      path,
      type,
      id,
      data,
      options,
      deferred: $.Deferred(),
    }
    // yes, terrible.. for debugging purposes..
    request.deferred._request = request
    return request
  },

  _enqueOrUpdate(request) {
    if (this._requestQueue.length > 0) {
      const isMatch = this._makeRequestMatcher(request)
      const matchingRequests = _.filter(this._requestQueue, isMatch)

      if (matchingRequests.length == 1)
        return this._mergeRequests(matchingRequests[0], request)

      if (matchingRequests.length > 1)
        util.error('duplicate requests match ', request)
    }
    this._requestQueue.push(request)
    return request.deferred
  },

  _mergeRequests(r0, r1) {
    _.extend(r0.data, r1.data)

    r0.options = r0.options || {}
    r1.options = r1.options || {}
    r0.options.cancelIf = r0.options.cancelIf || function () {
      return false
    }
    r1.options.cancelIf = r1.options.cancelIf || function () {
      return false
    }

    if (!_.isArray(r0.options.cancelIf))
      r0.options.cancelIf = [r0.options.cancelIf]

    r0.options.cancelIf.push(r1.options.cancelIf)

    r0._mergedRequests = r0._subrequests || []
    r0._mergedRequests.push(r1)
    return r0.deferred
  },

  _requestImmediate(request) {
    if (this._currentRequest)
      util.error(`A currentRequest already exists: ${this._debugCurrentRequest()}`)

    this._currentRequest = request

    if (request.options.skipDataProcessing) {
      request.wireData = request.data
    } else if (request.method == 'GET') {
      request.wireData = request.options.params || {}
      if (!request.id && request.path.indexOf('/', 1) !== -1 && _.isEmpty(request.options.params))
        util.warn(`requesting global list: ${request.path}. This may be very expensive`)

      const queryString = _.map(request.options.params, (v, k) => [k, '=', v].join('')).join('&')
      request.pathWithQuery = _.isEmpty(queryString) ? request.path : `${request.path}?${queryString}`
    } else if (request.method == 'POST') {
      request.wireData = {}
      request.wireData[request.type] = [
        this._filterPostPatchAttrs(this._convertLinksToIdAttrs(request.data)),
      ]
    } else if (request.method == 'PATCH' && !_.isArray(request.data)) {
      request.wireData = this.hashToPatchOps(
        this._filterPostPatchAttrs(this._convertLinksToIdAttrs(request.data)),
        `${request.path}/`,
      )
    }

    if (util.isLocalId(request.id))
      util.error('unresolved localId in request', request)

    request.xhr = this._ajax(request)
    return request.deferred
  },

  _filterPostPatchAttrs(data) {
    return _.omit(data, (v, k) => k == 'id' || k[0] == '_' || util.isLocalId(v))
  },

  hashToPatchOps(params, pathPrefix) {
    return _.map(params, (v, k) => ({op: 'replace', path: pathPrefix + k, value: v}))
  },

  _convertLinksToIdAttrs(data) {
    if (!data)
      return data

    const linkData = _.clone(data.links)
    const result = _.omit(data, ['links'])

    if (_.isObject(linkData)) {
      Object.keys(linkData).forEach((key) => {
        if (!linkData.hasOwnProperty(key) || _.isArray(linkData[key]))
          return

        result[`${key}_id`] = linkData[key]
      })
    }

    return result
  },

  _requestSuccess(data, textStatus, jqXHR) {
    const deferred = this._currentRequest.deferred
    const retReq = _.omit(this._currentRequest, ['deferred'])
    const retRes = {
      status: jqXHR.status,
      data,
    }

    this._currentRequest.completed = true
    try {
      deferred.resolve({request: retReq, response: retRes})
    } finally {
      this._requestComplete()
    }
  },

  _requestError(jqXHR) {
    try {
      const deferred = this._currentRequest.deferred
      const retReq = _.omit(this._currentRequest, ['deferred'])
      const retRes = {
        status: jqXHR.status,
        data: null,
      }

      try {
        retRes.data = JSON.parse(jqXHR.responseText)
      } catch (e) {
        util.reportAndContinue(e)
        retRes.data = {
          message: 'Glassfrog encountered an error processing your request (500)',
        }
      }

      this._currentRequest.completed = true
      const protoAction = {request: retReq, response: retRes}
      deferred.reject(protoAction)

      if (!protoAction.response.processed && this.config.defaultErrorHandler)
        this.config.defaultErrorHandler(protoAction)
    } finally {
      this._requestComplete()
    }
  },

  _isCancellable(request) {
    // return false;
    const cancelIf = request.options && request.options.cancelIf

    if (!cancelIf)
      return false

    if (_.isArray(cancelIf))
      // eslint-disable-next-line jest/no-disabled-tests
      return _.all(cancelIf, (test) => test())

    if (_.isFunction(cancelIf))
      return cancelIf()

    util.error('invalid value for cancelIf option', cancelIf)
  },

  _cancelCancellableRequests() {
    const pair = R.partition(this._isCancellable.bind(this), this._requestQueue)
    const toCancel = pair[0]
    const toKeep = pair[1]

    R.forEach((request) => {
      request.deferred.resolve({
        request,
        response: {
          status: 304, // presumably we're cancelling so we just use data already in store
          data: 'cancelledByRequestInApiAdapter', // for visibility if debugging
          processed: true, // prevent attempt to run a serverSuccess action on this response
        },
      })
    }, toCancel)

    this._cancelledRequests = (this._cancelledRequests || []).concat(toCancel)
    this._requestQueue = toKeep
  },

  _requestComplete() {
    this._requestLog.push(this._currentRequest)
    // cancelCancellableRequests must run BEFORE we end the currentRequest
    // so that any chained requests in callbacks aren't immediately started.
    this._cancelCancellableRequests()
    this._currentRequest = null

    if (this._requestQueue.length > 0) {
      const nextRequest = this._requestQueue.shift()
      this._requestImmediate(nextRequest)
    }
  },

  _debugCurrentRequest() {
    return JSON.stringify(
      R.pick(['method', 'path', 'data'], this._currentRequest || {}),
    )
  },

  // TODO make this a public method - https://www.pivotaltracker.com/story/show/139991727
  _ajax(request) {
    if (!this.apiHasEndpoint(request.type))
      return util.warn(`no api endpoint for resource type ${request.type}`)

    request.options.headers = _.extend(
      {
        'X-GF-REQUESTED-AT': Date.now(),
        'Cache-Control': 'no-cache, no-store',
      },
      request.options ? request.options.headers : {},
    )
    const headers = request.options.headers

    const url = gf.app.apiV3Path(request.path)
    const isGet = (request.method == 'GET')
    const ajaxSettings = {
      type: request.method,
      headers,
      processData: isGet,
      dataType: 'json',
      data: isGet ? request.wireData : JSON.stringify(request.wireData),
      contentType: 'application/json',
    }

    return $.ajax(url, ajaxSettings).then(
      _.bind(this._requestSuccess, this),
      _.bind(this._requestError, this),
    )
  },
});

(function () {
  function isConnectionTimeout(jqxhr) {
    return (jqxhr.status == 0)
      && !util.isFirefox()
      && (jqxhr.state() !== 'rejected')
  }

  function logError(jqxhr, request) {
    // eslint-disable-next-line no-console
    console.log(`Error on AJAX request: ${
      request.type} ${request.url} returned status ${
      jqxhr.status}.`)
  }

  // Register a global hook to detect failure in ANY ajax call.
  // See https://api.jquery.com/category/ajax/global-ajax-event-handlers/
  $(document).ajaxError((event, jqxhr, request) => {
    if (isConnectionTimeout(jqxhr)) {
      logError(jqxhr, request)
      // eslint-disable-next-line no-console
      console.log('This was a request timeout or connection failure.')
    } else if (jqxhr.status == 408 || jqxhr.status >= 500) {
      logError(jqxhr, request)
      // eslint-disable-next-line no-console
      console.log('This is a bug in our code.')
      $('#js-error-500').show()
    } else {
      // console.log("We don't care about errors with this status code.");
    }
  })
}())

export default ApiAdapter
