// @flow
import React, {useMemo, type Node} from 'react'
import {useFragment, graphql} from 'react-relay'
import {Field, type FormikProps} from 'formik'

import uniqueArrayById from 'utils/MiscUtils/uniqueArrayById'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import HorizontalGroup from 'components/ui/forms/HorizontalGroup'
import RoleSelector from 'components/roles/RoleSelector'
import PeopleSelector from 'components/people/PeopleSelector'

import type {Values} from '../../../ProjectEditForm'
import type {
  RoleAndPersonSelector_circle$key as CircleKey,
} from './__generated__/RoleAndPersonSelector_circle.graphql'
import type {
  RoleAndPersonSelector_project$key as ProjectKey,
} from './__generated__/RoleAndPersonSelector_project.graphql'

type Props = $ReadOnly<{
  disabled: boolean,
  formikProps: FormikProps<Values>,
  circle: CircleKey,
  project: ?ProjectKey,
}>

const circleFragment = graphql`
  fragment RoleAndPersonSelector_circle on Circle {
    individualAction {
      id
    }

    supportedRole {
      id
      isExpanded

      assignments {
        edges {
          node {
            person {
              id

              ...PeopleSelector_people
            }
          }
        }
      }

      ...RoleSelector_roles
    }

    roles(includeFocusRoles: true) {
      edges {
        node {
          id
          isIndividualAction

          assignments {
            edges {
              node {
                person {
                  id

                  ...PeopleSelector_people
                }
              }
            }
          }

          ...RoleSelector_roles
        }
      }
    }

    members {
      edges {
        node {
          id

          ...PeopleSelector_people
        }
      }
    }
  }
`

const projectFragment = graphql`
  fragment RoleAndPersonSelector_project on Project {
    person {
      id

      ...PeopleSelector_people
    }

    role {
      isIndividualAction
    }
  }
`

function RoleAndPersonSelector({disabled, formikProps, circle: circleKey, project: projectKey}: Props): Node {
  const circle = useFragment(circleFragment, circleKey)
  const project = useFragment(projectFragment, projectKey)

  const roles = (circle.supportedRole && !circle.supportedRole.isExpanded)
    ? [circle.supportedRole]
    : liftNodes(circle.roles)

  const selectedRole = [circle.individualAction, ...roles].find((r) => r.id === formikProps.values.roleId) || null
  const circleMembersAndProjectPerson = uniqueArrayById([project?.person, ...liftNodes(circle.members)].filter(Boolean))

  const peopleToSelect = useMemo(() => {
    if (selectedRole)
      return liftNodes(selectedRole.assignments).map((a) => a.person)

    return circleMembersAndProjectPerson
  }, [selectedRole, circleMembersAndProjectPerson])

  return (
    <HorizontalGroup>
      <Field
        disabled={disabled}
        label={I18n.t('projects.my_projects.role')}
        as={RoleSelector}
        useIndividualActions
        roles={roles}
        useAsValue="id"
        name="roleId"
        placeholder={I18n.t('projects.my_projects.select_role')}
        test-id="role-selector"
        warning={formikProps.errors.roleId}
      />
      <Field
        disabled={disabled}
        label={I18n.t('projects.my_projects.person')}
        as={PeopleSelector}
        people={peopleToSelect}
        useAsValue="id"
        name="personId"
        placeholder={I18n.t('projects.my_projects.select_person')}
        test-id="person-selector"
        warning={formikProps.errors.personId}
      />
    </HorizontalGroup>
  )
}

export default RoleAndPersonSelector
