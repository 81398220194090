/**
 * @flow
 * @relayHash 85f2cd90771456931ce76f52a03032df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  actionId: string,
  circleId: string,
  parentGoalOrTarget?: ?string,
  projectId?: ?string,
  roleId?: ?string,
  personId: string,
  description: string,
  note?: ?string,
  privateToCircle: boolean,
  tagNames?: ?$ReadOnlyArray<string>,
  trigger: boolean,
  triggerEvent?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateActionDescriptionMutationVariables = {|
  input: UpdateActionInput
|};
export type UpdateActionDescriptionMutationResponse = {|
  +updateAction: ?{|
    +action: ?{|
      +id: string,
      +description: string,
      +rawDescription: string,
      +isUpdating: ?boolean,
      +viewerCanUpdate: boolean,
    |}
  |}
|};
export type UpdateActionDescriptionMutation = {|
  variables: UpdateActionDescriptionMutationVariables,
  response: UpdateActionDescriptionMutationResponse,
|};
*/


/*
mutation UpdateActionDescriptionMutation(
  $input: UpdateActionInput!
) {
  updateAction(input: $input) {
    action {
      id
      description(format: HTML)
      rawDescription: description(format: PLAIN)
      viewerCanUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateActionPayload",
    "kind": "LinkedField",
    "name": "updateAction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Action",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "HTML"
              }
            ],
            "kind": "ScalarField",
            "name": "description",
            "storageKey": "description(format:\"HTML\")"
          },
          {
            "alias": "rawDescription",
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "PLAIN"
              }
            ],
            "kind": "ScalarField",
            "name": "description",
            "storageKey": "description(format:\"PLAIN\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanUpdate",
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUpdating",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateActionDescriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateActionDescriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "85f2cd90771456931ce76f52a03032df",
    "metadata": {},
    "name": "UpdateActionDescriptionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7684a0cfc01811635f66b16f142e552d';

module.exports = node;
