// @flow
import classNames from 'classnames'
import React, {type Node} from 'react'
// import CallFunctionButton from 'components/ai/CallFunctionButton'
import StartProposalButton from 'components/ai/StartProposalButton'
// import {useAiChatContext} from 'contexts/AiChatContext'
import renderableMessage, {getType, visibleTypes} from './renderableMessage'
import type {MessageType} from '../MessageType'
import styles from './index.scss'
import msgAreaStyles from '../MessagesArea/index.scss'
import ThumbsUpDown from '../ThumbsUpDown'

type Props = {
  message: MessageType,
  images: any,
  isLastMessage: boolean,
  messagesPath: string,
};

const chatButtons = {
  /* eslint-disable no-unused-vars */
  /* eslint-disable camelcase */
  // '{{exchange_information}}': (_) => (
  //   <CallFunctionButton
  //     buttonText={I18n.t('ai.functions.exchange_information')}
  //     functionName="exchange_information"
  //   />
  // ),
  // '{{improve_governance}}': (_) => (
  //   <CallFunctionButton
  //     buttonText={I18n.t('ai.functions.improve_governance')}
  //     functionName="improve_governance"
  //   />
  // ),
  // '{{request_work}}': (_) => (
  //   <CallFunctionButton
  //     buttonText={I18n.t('ai.functions.request_work')}
  //     functionName="request_work"
  //   />
  // ),
  '{{ui-start_proposal_button}}': (chatData: Object) => {
    const {organization_id, circle_id, tension_id} = chatData
    return (
      <StartProposalButton
        organizationId={`${organization_id}`}
        circleId={`${circle_id}`}
        tensionId={`${tension_id}`}
      />
    )
  },
  /* eslint-enable camelcase */
  /* eslint-enable no-unused-vars */
}

function Message({message, images, isLastMessage, messagesPath}: Props): Node {
  const showSystemMessage = (event) => {
    const readMore = event.target
    if (readMore.previousElementSibling.style.maxHeight === 'initial')
      readMore.previousElementSibling.style.maxHeight = '1.2rem'
    else
      readMore.previousElementSibling.style.maxHeight = 'initial'
  }
  // const {chatId, messages, setMessages} = useAiChatContext()

  const includedButtonKeys = []
  const cleanedMessage = {...message}
  const msgType = getType(message)
  const messageIncludesKey = (msg, key) => visibleTypes.includes(msgType)
    && msg.content
    && msg.content.includes(key)

  Object.keys(chatButtons).forEach((key) => {
    if (messageIncludesKey(message, key)) {
      includedButtonKeys.push(key)
      cleanedMessage.content = cleanedMessage.content.replace(key, '')
    }
  })

  const {image, cssClass, content} = renderableMessage(cleanedMessage)

  return (
    <div className={classNames(msgAreaStyles.message, styles[cssClass])}>
      {images[image]}

      <div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={msgAreaStyles.text}>{content}</div>
        {cssClass === 'system' && (
        <button
          className={classNames('btn-link', msgAreaStyles.readMore)}
          onClick={showSystemMessage}
          type="button"
        >
          {'...'}
        </button>
        )}
        {includedButtonKeys.length > 0 && (
        <div className={styles.buttons}>
          <hr />
          {includedButtonKeys.map((key) => chatButtons[key](message.data))}
        </div>
        )}
        {isLastMessage && msgType === 'assistant' && (
        <ThumbsUpDown
          message={message}
          messagesPath={messagesPath}
        />
        )}
      </div>
    </div>
  )
}

export default Message
