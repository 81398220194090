// @flow
import {Component, type Node} from 'react'

type Props = $ReadOnly<{
  source: string,
  children: Node,
}>

type State = $ReadOnly<{
  hasError: boolean,
}>

class MarkdownErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(): { hasError: boolean } {
    return {hasError: true}
  }

  state: State = {hasError: false}

  /* eslint-disable react/require-render-return */
  // eslint-disable-next-line react/no-arrow-function-lifecycle
  render: (() => Node | string) = () => {
    if (this.state.hasError)
      return this.props.source

    return this.props.children
  }
}

export default MarkdownErrorBoundary
