// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {useFragment, graphql} from 'react-relay'

import useToggle from 'utils/hooks/useToggle'
import CustomProfileFieldsEditor from './CustomProfileFieldsEditor'
import CustomProfileFieldsList from './CustomProfileFieldsList'
import styles from './index.scss'

import type {
  AboutTab_person$key as PersonKey,
} from './__generated__/AboutTab_person.graphql'

import type {
  AboutTab_organization$key as OrganizationKey,
} from './__generated__/AboutTab_organization.graphql'

type Props = $ReadOnly<{
  person: PersonKey,
  organization: OrganizationKey,
}>

const personFragment = graphql`
  fragment AboutTab_person on Person {
    viewerCanUpdateCustomSettings

    ...CustomProfileFieldsEditor_person
    ...CustomProfileFieldsList_person
  }
`

const organizationFragment = graphql`
  fragment AboutTab_organization on Organization {
    name
    isPubliclyVisible

    ...CustomProfileFieldsEditor_organization
    ...CustomProfileFieldsList_organization
  }
`

function AboutTab({person: personKey, organization: organizationKey}: Props): Node {
  const [isCustomSettingsEditorOpen, {open, close}] = useToggle(false)
  const person = useFragment(personFragment, personKey)
  const organization = useFragment(organizationFragment, organizationKey)
  const openCustomSettingsEditor = person.viewerCanUpdateCustomSettings ? open : null

  const note = () => {
    if (!organization.isPubliclyVisible || !person.viewerCanUpdateCustomSettings)
      return null

    return (
      <div className={styles.note}>
        {I18n.t('people.about.note', {organization: organization.name})}
      </div>
    )
  }

  return (
    <Fragment>
      {note()}
      {isCustomSettingsEditorOpen
        ? (
          <CustomProfileFieldsEditor
            person={person}
            organization={organization}
            close={close}
          />
        )
        : (
          <CustomProfileFieldsList
            person={person}
            organization={organization}
            openEditor={openCustomSettingsEditor}
          />
        )
      }
    </Fragment>
  )
}

export default AboutTab
