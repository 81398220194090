import React from 'react'

import IconButton from 'components/ui/Inputs/IconButton'
import Pencil from 'components/ui/icons/fa/Pencil'
import Trashcan from 'components/ui/icons/fa/Trashcan'
import styles from './index.scss'

type Props = $ReadOnly<{
  onEdit: () => void,
  onDelete: () => void,
}>

function MutationIcons({onEdit, onDelete}: Props) {
  return (
    <div className={styles.buttonContainer}>
      <IconButton
        size="sm"
        testId="project-action-edit-button"
        title={I18n.t('shared.edit')}
        onClick={onEdit}
      >
        <Pencil />
      </IconButton>
      <IconButton
        onClick={onDelete}
        size="sm"
        testId="project-action-delete-button"
        title={I18n.t('shared.delete')}
      >
        <Trashcan />
      </IconButton>
    </div>
  )
}

export default MutationIcons
