/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Item_action$ref = any;
type Item_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type List_project$ref: FragmentReference;
declare export opaque type List_project$fragmentType: List_project$ref;
export type List_project = {|
  +id: string,
  +actions: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +position: ?number,
        +description: string,
        +rawDescription: string,
        +viewerCanUpdate: boolean,
        +$fragmentRefs: Item_action$ref,
      |}
    |}>
  |},
  +$fragmentRefs: Item_project$ref,
  +$refType: List_project$ref,
|};
export type List_project$data = List_project;
export type List_project$key = {
  +$data?: List_project$data,
  +$fragmentRefs: List_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "actions"
        ]
      }
    ]
  },
  "name": "List_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "actions",
      "args": null,
      "concreteType": "ActionConnection",
      "kind": "LinkedField",
      "name": "__List_actions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Action",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "format",
                      "value": "HTML"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": "description(format:\"HTML\")"
                },
                {
                  "alias": "rawDescription",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "format",
                      "value": "PLAIN"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": "description(format:\"PLAIN\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewerCanUpdate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Item_action"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Item_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '026b65845223af984998a0a51ef87381';

module.exports = node;
