/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MetricItem_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistItem_circle$ref: FragmentReference;
declare export opaque type ChecklistItem_circle$fragmentType: ChecklistItem_circle$ref;
export type ChecklistItem_circle = {|
  +databaseId: string,
  +organization: {|
    +databaseId: string
  |},
  +$fragmentRefs: MetricItem_circle$ref,
  +$refType: ChecklistItem_circle$ref,
|};
export type ChecklistItem_circle$data = ChecklistItem_circle;
export type ChecklistItem_circle$key = {
  +$data?: ChecklistItem_circle$data,
  +$fragmentRefs: ChecklistItem_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChecklistItem_circle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricItem_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c684561c812d8e2165c1f14a67703050';

module.exports = node;
