/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Actions_project$ref = any;
type ProjectOwnerSelector_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectEditForm_project$ref: FragmentReference;
declare export opaque type ProjectEditForm_project$fragmentType: ProjectEditForm_project$ref;
export type ProjectEditForm_project = {|
  +$fragmentRefs: Actions_project$ref & ProjectOwnerSelector_project$ref,
  +$refType: ProjectEditForm_project$ref,
|};
export type ProjectEditForm_project$data = ProjectEditForm_project;
export type ProjectEditForm_project$key = {
  +$data?: ProjectEditForm_project$data,
  +$fragmentRefs: ProjectEditForm_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectEditForm_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Actions_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectOwnerSelector_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ca46842a2f0153a5dec282bb587b30ec';

module.exports = node;
