/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleSelector_roles$ref: FragmentReference;
declare export opaque type RoleSelector_roles$fragmentType: RoleSelector_roles$ref;
export type RoleSelector_roles = $ReadOnlyArray<{|
  +id: string,
  +isDisplayedAsCircle: boolean,
  +isFocus: boolean,
  +databaseId: string,
  +governanceEnabled: boolean,
  +nameWithMode: string,
  +circle: ?{|
    +id: string,
    +databaseId: string,
    +supportedRole: ?{|
      +id: string,
      +databaseId: string,
    |},
  |},
  +$refType: RoleSelector_roles$ref,
|}>;
export type RoleSelector_roles$data = RoleSelector_roles;
export type RoleSelector_roles$key = $ReadOnlyArray<{
  +$data?: RoleSelector_roles$data,
  +$fragmentRefs: RoleSelector_roles$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "CIRCLE_FOR_CORE_ROLES",
      "kind": "LocalArgument",
      "name": "nameWith"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RoleSelector_roles",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDisplayedAsCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFocus",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "governanceEnabled",
      "storageKey": null
    },
    {
      "alias": "nameWithMode",
      "args": [
        {
          "kind": "Variable",
          "name": "with",
          "variableName": "nameWith"
        }
      ],
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Role",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b31a01cee3c95edd83e662a68a5a68a';

module.exports = node;
