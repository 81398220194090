// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import {graphql, useFragment} from 'react-relay'
import frequencies from 'constants/frequencies'
import Select from 'components/ui/forms/Select'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import {
  getFrequencyOptions,
  convertFrequencyToOptionValue,
  type ChoosableFrequency,
} from 'utils/MiscUtils/frequencyHelper'
import type {
  FrequencySelector_organization$key as Organization,
} from './__generated__/FrequencySelector_organization.graphql'

type Props = $ReadOnly<{
  'test-id': string,
  value: ChoosableFrequency | string | null,
  organization: Organization,
  name: string,
  label: string,
  warning: ?string,
  placeholder: string,
  onChange: (FakeEvent, boolean | string | null) => void,
}>

const organizationFragment = graphql`
  fragment FrequencySelector_organization on Organization {
    customFrequencies(first: 100) @connection(key: "FrequencySelector_customFrequencies") {
      edges {
        node {
          id
          name
          universalUnits
        }
      }
    }
  }
`

function FrequencySelector({
  'test-id': testId,
  name,
  label,
  warning,
  onChange,
  value,
  placeholder,
  organization: organizationKey,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const customFrequencies = liftNodes(organization.customFrequencies)
  const valueString = (value && typeof value !== 'string') ? convertFrequencyToOptionValue(value) : value

  const options = useMemo(
    () => getFrequencyOptions(frequencies, customFrequencies),
    [customFrequencies],
  )

  return (
    <Select
      test-id={testId}
      name={name}
      label={label}
      options={options}
      warning={warning}
      onChange={onChange}
      value={valueString}
      placeholder={placeholder}
    />
  )
}

export default FrequencySelector
