/**
 * @flow
 * @relayHash 188a7dd9ce0d89161f1baf24e679f65f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AboutTab_person$ref = any;
export type UpdatePersonInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  personId: string,
  name?: ?string,
  email?: ?string,
  customProfileFields?: ?$ReadOnlyArray<CustomProfileFieldMappingInputObject>,
|};
export type MutationContext = {|
  organizationId: string
|};
export type CustomProfileFieldMappingInputObject = {|
  name: string,
  value?: ?string,
  appliedFrom?: ?string,
|};
export type UpdatePersonMutationVariables = {|
  input: UpdatePersonInput
|};
export type UpdatePersonMutationResponse = {|
  +updatePerson: ?{|
    +person: ?{|
      +$fragmentRefs: AboutTab_person$ref
    |}
  |}
|};
export type UpdatePersonMutation = {|
  variables: UpdatePersonMutationVariables,
  response: UpdatePersonMutationResponse,
|};
*/


/*
mutation UpdatePersonMutation(
  $input: UpdatePersonInput!
) {
  updatePerson(input: $input) {
    person {
      ...AboutTab_person
      id
    }
  }
}

fragment AboutTab_person on Person {
  viewerCanUpdateCustomSettings
  ...CustomProfileFieldsEditor_person
  ...CustomProfileFieldsList_person
}

fragment CustomProfileFieldsEditor_person on Person {
  id
  customSettings(scope: ORGANIZATION) {
    customProfileFields {
      name
      value
      appliedFrom
    }
  }
}

fragment CustomProfileFieldsList_person on Person {
  customSettings(scope: ORGANIZATION) {
    customProfileFields {
      name
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AboutTab_person"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdateCustomSettings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "scope",
                    "value": "ORGANIZATION"
                  }
                ],
                "concreteType": "CustomSettings",
                "kind": "LinkedField",
                "name": "customSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomProfileFieldMapping",
                    "kind": "LinkedField",
                    "name": "customProfileFields",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appliedFrom",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "customSettings(scope:\"ORGANIZATION\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "188a7dd9ce0d89161f1baf24e679f65f",
    "metadata": {},
    "name": "UpdatePersonMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd29262961ae629905b56ec888c437dfd';

module.exports = node;
