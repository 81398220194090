// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import environment from 'environment'
import {RelayEnvironmentProvider} from 'react-relay'
import LoadingDots from 'components/ui/LoadingDots'
import RoleTemplatesSection, {type Props} from './RoleTemplatesSection'

function RoleTemplates(props: Props): Node {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<LoadingDots />}>
        <RoleTemplatesSection {...props} />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default RoleTemplates
