/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PeopleSelector_people$ref = any;
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputForm_meeting$ref: FragmentReference;
declare export opaque type OutputForm_meeting$fragmentType: OutputForm_meeting$ref;
export type OutputForm_meeting = {|
  +id: string,
  +databaseId: string,
  +currentAgendaItem: ?{|
    +id: string,
    +databaseId: string,
  |},
  +invitedRoles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string
      |}
    |}>
  |},
  +invitedCircles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string
      |}
    |}>
  |},
  +allParticipatingRoles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +databaseId: string,
        +isIndividualAction: boolean,
        +circle: ?{|
          +id: string
        |},
        +assignments: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +person: {|
                +databaseId: string,
                +$fragmentRefs: PeopleSelector_people$ref,
              |}
            |}
          |}>
        |},
        +$fragmentRefs: RoleSelector_roles$ref,
      |}
    |}>
  |},
  +allParticipatingMembers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +databaseId: string,
        +roles: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +databaseId: string,
              +isDisplayedAsCircle: boolean,
              +circle: ?{|
                +id: string
              |},
              +$fragmentRefs: RoleSelector_roles$ref,
            |}
          |}>
        |},
        +$fragmentRefs: PeopleSelector_people$ref,
      |}
    |}>
  |},
  +organization: {|
    +databaseId: string,
    +isOnV5: boolean,
    +isWysiwygEnabled: boolean,
  |},
  +$refType: OutputForm_meeting$ref,
|};
export type OutputForm_meeting$data = OutputForm_meeting;
export type OutputForm_meeting$key = {
  +$data?: OutputForm_meeting$data,
  +$fragmentRefs: OutputForm_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PeopleSelector_people"
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RoleSelector_roles"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputForm_meeting",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AgendaItem",
      "kind": "LinkedField",
      "name": "currentAgendaItem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "invitedRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CircleConnection",
      "kind": "LinkedField",
      "name": "invitedCircles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CircleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Circle",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "allParticipatingRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isIndividualAction",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentConnection",
                  "kind": "LinkedField",
                  "name": "assignments",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssignmentEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Assignment",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "person",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonConnection",
      "kind": "LinkedField",
      "name": "allParticipatingMembers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoleConnection",
                  "kind": "LinkedField",
                  "name": "roles",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoleEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Role",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isDisplayedAsCircle",
                              "storageKey": null
                            },
                            (v3/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnV5",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isWysiwygEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TacticalMeeting",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2947c0716e57019564ef0731197234cf';

module.exports = node;
