// @flow
import type {Element} from 'react'
import React, {Fragment} from 'react'

type Props = $ReadOnly<{
  headerless: boolean,
  compact: boolean,
}>

function Preload({headerless, compact}: Props): Element<"div"> {
  return (
    <div className="preloader-text py-2">
      {!headerless && (<div className="header--large" />)}
      {!compact && (
      <Fragment>
        <div className="header--small" />
        <div className="text-content" />
        <div className="text-content" />
        <div className="header--small" />
        <div className="text-content" />
        <div className="text-content" />
      </Fragment>
      )}
      <div className="header--small" />
      <div className="text-content" />
      <div className="text-content" />
      <div className="text-content" />
      <div className="text-content" />
      <div className="header--small" />
      <div className="text-content" />
      <div className="text-content" />
      <div className="text-content" />
      <div className="text-content" />
    </div>
  )
}

Preload.defaultProps = {
  headerless: false,
  compact: false,
}

export default Preload
