// @flow
import type {Element, Node} from 'react'
import React from 'react'
import AppLink from 'components/ui/AppLink'
import RoleTabLink from '../RoleTabLink'
import styles from './index.scss'

const escapeChars = (str) => str.replace(/\[/g, '%5B').replace(/\]/g, '%5D')

export const linkText = (href: string, display: string): string => `[@${escapeChars(display)}](${href})`

export const renderMarkdownLink = ({href, children}: {href: string, children: Element<string>}): Node => {
  const text = children[0]

  if (text[0] === '@' && href.search('/roles/')) {
    const roleId = href.substring(href.lastIndexOf('/') + 1)
    return (
      <RoleTabLink
        className="btn-link"
        roleDatabaseId={roleId}
        tabName="overview"
      >
        {text.substr(1)}
      </RoleTabLink>
    )
  }

  return (
    <AppLink
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {text}
    </AppLink>
  )
}
