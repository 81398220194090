// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import {useFragment, graphql} from 'react-relay'
import OrganizationContext from './OrganizationContext'

const organizationFragment = graphql`
  fragment OrganizationContextProviderFragment_organization on Organization {
    id
    databaseId
    name
    isWysiwygEnabled   
    onRestrictedPlan 
    isOnV5
    isOnV4
    isROIEnabled
    isAiEnabled
    showChecklists: featureEnabled(name: "checklists")
    showHistory: featureEnabled(name: "history")
    showMetrics: featureEnabled(name: "metrics")
    showNotes: featureEnabled(name: "notes")
    showPolicies: featureEnabled(name: "policies")
    showProjects: featureEnabled(name: "projects")
    checklistsLabel: termFor(name: "checklists")
    historyLabel: termFor(name: "history")
    metricsLabel: termFor(name: "metrics")
    notesLabel: termFor(name: "notes")
    policiesLabel: termFor(name: "policies")
    projectsLabel: termFor(name: "projects")
  }
`

type Props = {
  children: Node,
  organization: any,
}

export default function OrganizationContextProviderFragment({children, organization}: Props): Node {
  const orgData = useFragment(organizationFragment, organization)
  const value = useMemo(() => (
    orgData == null ? null : {
      id: orgData.id,
      databaseId: orgData.databaseId,
      name: orgData.name,
      isWysiwygEnabled: orgData.isWysiwygEnabled,
      onRestrictedPlan: orgData.onRestrictedPlan,
      isOnV5: orgData.isOnV5,
      isOnV4: orgData.isOnV4,
      isROIEnabled: orgData.isROIEnabled,
      isAiEnabled: orgData.isAiEnabled,
      labels: {
        checklists: orgData.checklistsLabel,
        history: orgData.historyLabel,
        metrics: orgData.metricsLabel,
        notes: orgData.notesLabel,
        policies: orgData.policiesLabel,
        projects: orgData.projectsLabel,
      },
      show: {
        checklists: orgData.showChecklists,
        history: orgData.showHistory,
        metrics: orgData.showMetrics,
        notes: orgData.showNotes,
        policies: orgData.showPolicies,
        projects: orgData.showProjects,
      },
    }), [orgData])
  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}
