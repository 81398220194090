// @flow
import React, {type Node, useEffect, useRef, useState} from 'react'
import classnames from 'classnames'

import styles from './index.scss'

export enum Color {
  Danger = 'danger', Warning = 'warning', Secondary = 'secondary', Primary = 'primary', Reset = 'reset'
}

type OverriddenBootstrapButtonProps = $ReadOnly<{
  children?: Node,
  color: Color,
  disabled: boolean,
  className?: string,
}>

type ButtonProps = $ReadOnly<{
  loading: boolean
}>

type AllProps = {
  ...OverriddenBootstrapButtonProps,
  ...ButtonProps,
  ...$Diff<Button.props, OverriddenBootstrapButtonProps>
}

function Button({loading, disabled, color, children, className, ...bootstrapButtonProps}: AllProps): Node {
  const childWrapperRef = useRef()
  const [childSize, setChildSize] = useState({width: 0, height: 0})

  useEffect(() => {
    if (loading)
      return

    if (!childWrapperRef.current)
      return

    const {width, height} = childWrapperRef.current.getBoundingClientRect()
    setChildSize({width, height})
  }, [loading])

  const loadingDotClass = classnames(styles.loadingDot, {
    [styles.loadingDotSecondary]: color === Color.Secondary,
  })

  return (
    <button
      disabled={disabled || loading}
      className={`btn btn-${color} ${className}`}
      type="button"
      {...bootstrapButtonProps}
    >
      {!loading && (
        <div ref={childWrapperRef}>{children}</div>
      )}
      {loading && color !== Color.Reset && (
        <div style={childSize} className={styles.loadingContainer}>
          <div className={loadingDotClass} />
          <div className={loadingDotClass} />
          <div className={loadingDotClass} />
        </div>
      )}
    </button>
  )
}

Button.defaultProps = {
  color: Color.Primary,
  className: '',
  disabled: false,
}

export default Button
