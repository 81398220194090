/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CircleSelector_circles$ref: FragmentReference;
declare export opaque type CircleSelector_circles$fragmentType: CircleSelector_circles$ref;
export type CircleSelector_circles = $ReadOnlyArray<{|
  +id: string,
  +databaseId: string,
  +localizedName: string,
  +isGovernanceEnabled: boolean,
  +supportedRole: ?{|
    +isDisplayedAsCircle: boolean
  |},
  +$refType: CircleSelector_circles$ref,
|}>;
export type CircleSelector_circles$data = CircleSelector_circles;
export type CircleSelector_circles$key = $ReadOnlyArray<{
  +$data?: CircleSelector_circles$data,
  +$fragmentRefs: CircleSelector_circles$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CircleSelector_circles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGovernanceEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDisplayedAsCircle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4e9749a07df94b6757be7efeee90f906';

module.exports = node;
