// @flow
/* disallowedTypes prop would be substituted with disallowedElements in react-markdown 6.0.0
Changelog: https://github.com/remarkjs/react-markdown/blob/main/changelog.md#replace-allownode-allowedtypes-and-disallowedtypes */
import React, {useMemo} from 'react'
import type {Node} from 'react'
import MarkdownLib from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

import {renderMarkdownLink} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/markdownConfig'
import MarkdownErrorBoundary from './MarkdownErrorBoundary'

type Plugin = () => (any) => void

type Props = $ReadOnly<{
  disallowedTypes: $ReadOnlyArray<string>,
  className: string,
  unwrapDisallowed: boolean,
  source: string,
  plugins: $ReadOnlyArray<Plugin>,
}>

function Markdown({
  disallowedTypes,
  className,
  source,
  plugins,
  unwrapDisallowed,
}: Props): Node {
  const components = useMemo(() => ({
    a: renderMarkdownLink,
  }), [])

  return (
    <MarkdownErrorBoundary source={source}>
      <MarkdownLib
        className={className}
        disallowedElements={disallowedTypes}
        components={components}
        remarkPlugins={plugins}
        unwrapDisallowed={unwrapDisallowed}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
      >
        {source}
      </MarkdownLib>
    </MarkdownErrorBoundary>
  )
}

Markdown.defaultProps = {
  className: '',
  disallowedTypes: ([]: Array<any>),
  unwrapDisallowed: false,
  plugins: ([]: Array<any>),
}

export default Markdown
