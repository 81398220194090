// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import useToggle from 'utils/hooks/useToggle'
import TensionInfoUI from 'components/tensions/ui/TensionInfo'
import NoneTensionInfo, {type MarginBottom} from 'components/tensions/ui/NoneTensionInfo'
import type {
  TensionInfo_tension$key as TensionKey,
} from './__generated__/TensionInfo_tension.graphql'
import type {
  TensionInfo_viewer$key as ViewerKey,
} from './__generated__/TensionInfo_viewer.graphql'

type Props = $ReadOnly<{
  tension: TensionKey,
  viewer: ViewerKey | null,
  renderTensionForm: ?(() => void) => Node,
  marginBottom: MarginBottom,
}>

const tensionFragment = graphql`
  fragment TensionInfo_tension on Tension {
    createdAt
    body
    agendaLabel

    impactedRole {
      localizedName
    }

    person {
      id
      name
      isActive
    }
    
    organization {
      ...TensionInfo_organization
    }
  }
`

const viewerFragment = graphql`
  fragment TensionInfo_viewer on Person {
    id
  }
`

function TensionInfo({
  tension: tensionKey,
  viewer: viewerKey,
  renderTensionForm,
  marginBottom,
}: Props): Node {
  const tension = useFragment(tensionFragment, tensionKey)
  const viewer = useFragment(viewerFragment, viewerKey)
  const [isOpen, {close, open}] = useToggle(false)

  const personName = tension.person?.isActive ? tension.person.name : I18n.t('shared.removed')

  if (isOpen && renderTensionForm)
    return renderTensionForm(close)

  const openForm = renderTensionForm ? open : null

  if (!tension.body) {
    return (
      <NoneTensionInfo marginBottom={marginBottom} openForm={openForm} />
    )
  }

  return (
    <TensionInfoUI
      organization={tension.organization}
      marginBottom={marginBottom}
      openForm={openForm}
      roleName={tension.impactedRole?.localizedName}
      personName={personName}
      tensionBody={tension.body}
      agendaLabel={tension.agendaLabel}
      tensionCreatedAt={tension.createdAt}
      sensedByViewer={!!viewer && viewer.id === tension.person?.id}
    />
  )
}

TensionInfo.defaultProps = {
  renderTensionForm: null,
  viewer: null,
}

export default TensionInfo
