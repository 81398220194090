import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import _ from 'underscore'
import {prop} from 'ramda'
import GupsMixin from 'legacy_support/GupsMixin'

const RenderDataComponent = createReactClass({
  displayName: 'RenderDataComponent',

  propTypes: {
    path: PropTypes.array,
  },

  mixins: [GupsMixin],

  getDataMap(props) {
    return {
      data: props.path,
    }
  },

  render() {
    const defaultValue = prop('default')(_.last(this.props.path))
    const value = this.state.data || defaultValue

    return <span>{value}</span>
  },

})

export default RenderDataComponent
