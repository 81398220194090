// @flow
import React, {useCallback} from 'react'

import type {Node} from 'react'
import {QueryRenderer, graphql} from 'react-relay'
import type {GraphQLTaggedNode} from 'react-relay'

import environment from 'environment'
import logger from 'utils/MiscUtils/main'

import OrganizationContextProviderFragment from 'contexts/OrganizationContextProviderFragment'
import CreateProjectModal from 'components/projects/CreateProjectModal'
import AddProjectMutation from 'mutations/AddProjectMutation'

export const newProjectFormRailsQuery: GraphQLTaggedNode = graphql`
    query NewProjectFormRailsQuery($orgDatabaseId: String!, $circleDatabaseId: String!) {
      organization(databaseId: $orgDatabaseId) {
        id
        databaseId
        ...OrganizationContextProviderFragment_organization      
        ...CreateProjectModal_organization


        circle(databaseId: $circleDatabaseId) {
          id
          databaseId
          ...CreateProjectModal_circle
        }
      }
    }
`

type Props = $ReadOnly<{
  orgDatabaseId: string,
  circleDatabaseId: string,
  parentGoalOrTarget: string,
  supportedRoleId: string,
  defaultRoleId: string,
  close: () => void,
}>

type RendererProps = $ReadOnly<{
  // eslint-disable-next-line react/no-unused-prop-types
  error: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  props: ?{
    organization: any, // Update the type of organization property
  },
  ...
}>

function NewProjectFormRails({orgDatabaseId,
  circleDatabaseId,
  parentGoalOrTarget,
  supportedRoleId,
  defaultRoleId,
  close}: Props): Node {
  const addMutation = useCallback(async (values) => {
    const response = await AddProjectMutation(values)
    // $FlowFixMe[prop-missing]
    // $FlowFixMe[incompatible-use]
    document.getElementById('goals-and-targets').reload()
    return response.addProject
  }, [])

  const render = useCallback(({props: response, error}: RendererProps) => {
    if (error) {
      logger.warn('error:', error)
      return null
    }
    const placeholder = <div>{'Loading...'}</div>

    const organization = response?.organization
    if (!organization)
      return placeholder

    return (
      <OrganizationContextProviderFragment organization={organization}>
        <CreateProjectModal
          organization={organization}
          circle={organization.circle}
          initialValues={{
            supportedRoleId,
            parentGoalOrTarget,
            roleId: defaultRoleId,
            personId: '',
            note: '',
          }}
          addMutation={addMutation}
          close={close}
          mode="RoleAndPerson"
        />
      </OrganizationContextProviderFragment>
    )
  }, [supportedRoleId, parentGoalOrTarget,
    defaultRoleId, addMutation, close,
  ])

  const variables = {
    orgDatabaseId,
    circleDatabaseId,
  }

  return (
    <QueryRenderer
      environment={environment}
      query={newProjectFormRailsQuery}
      variables={variables}
      render={render}
    />
  )
}

export default NewProjectFormRails
