// @flow
import type {Node} from 'react'
import React from 'react'
import {useLazyLoadQuery, graphql} from 'react-relay'

import UpdateChecklistItemMutation, {type Response} from 'mutations/UpdateChecklistItemMutation'
import DeleteChecklistItemMutation from 'mutations/DeleteChecklistItemMutation'
import ViewerChecklistFormModal from 'components/checklistItems/ViewerChecklistFormModal'

import {allCircleMembersId} from 'constants/fakeId'

import type {
  ViewerChecklistItemEditModalQuery as Query,
} from './__generated__/ViewerChecklistItemEditModalQuery.graphql'

type Props = {
  checklistItemId: string,
  close: () => void,
  onSubmit: Response => void,
  orgDatabaseId: string,
}

const editModalQuery = graphql`
  query ViewerChecklistItemEditModalQuery(
    $orgDatabaseId: String!,
    $checklistItemId: ID!
  ) {
    organization(databaseId: $orgDatabaseId) {
      onRestrictedPlan
      ...FrequencySelector_organization

      viewer {
        ...ViewerChecklistFormModal_viewer
      }
    }

    checklistItem: node(id: $checklistItemId) {
      ... on ChecklistItem {
        description
        frequency
        link
        appliesTo
        privateToCircle

        circle {
          id
        }

        role {
          id
        }

        customFrequency {
          id
          name
          universalUnits
        }
      }
    }
  }
`

function ViewerChecklistItemEditModal({
  checklistItemId,
  close,
  onSubmit,
  orgDatabaseId,
}: Props): null | Node {
  const {organization, checklistItem} = useLazyLoadQuery<Query>(editModalQuery, {
    orgDatabaseId,
    checklistItemId,
  })

  if (!organization?.viewer || !checklistItem)
    return null

  const initialValues = {
    description: checklistItem.description || '',
    circleId: checklistItem.circle?.id,
    roleId: checklistItem.appliesTo === 'CIRCLE_MEMBERS'
      ? allCircleMembersId
      : checklistItem.role?.id,
    frequency: checklistItem.frequency,
    customFrequency: checklistItem.customFrequency,
    link: checklistItem.link,
    privateToCircle: checklistItem.privateToCircle,
  }

  const submit = async (values) => {
    const response = await UpdateChecklistItemMutation({
      ...values,
      checklistItemId,
    })

    onSubmit(response)

    return response
  }

  const deleteChecklist = async () => {
    /* eslint-disable-next-line no-alert */
    if (!window.confirm(I18n.t('metrics.delete_checklist_item_confirmation')))
      return

    await DeleteChecklistItemMutation(checklistItemId)
    close()
  }

  return (
    <ViewerChecklistFormModal
      includeEachCircleMember
      disabledCircle
      initialValues={initialValues}
      submit={submit}
      modalTitle={I18n.t('metrics.edit_checklist_item')}
      organization={organization}
      viewer={organization.viewer}
      close={close}
      renderLeftFooterButton={(isSubmitting) => (
        <button
          className="btn btn-secondary btn-lg"
          disabled={isSubmitting}
          onClick={deleteChecklist}
          type="button"
        >
          {I18n.t('react.components.edit_dialog.delete')}
        </button>
      )}
    />
  )
}

ViewerChecklistItemEditModal.defaultProps = {
  onSubmit: () => {},
}

export default ViewerChecklistItemEditModal
