// @flow
import type {Node} from 'react'
import React, {Fragment, Suspense} from 'react'
import {graphql, useFragment} from 'react-relay'

import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import ChecklistItemEditModal from 'components/checklistItems/ChecklistItemEditModal'
import {Item} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import useToggle from 'utils/hooks/useToggle'
import type {Source} from 'components/ui/SourceIcon'
import type {
  ChecklistItem_circle$key as CircleKey,
} from './__generated__/ChecklistItem_circle.graphql'
import type {
  ChecklistItem_item$key as ChecklistItemTypeKey,
} from './__generated__/ChecklistItem_item.graphql'

type Props = $ReadOnly<{
  item: ChecklistItemTypeKey,
  circle: CircleKey,
  source: Source,
}>

const itemFragment = graphql`
  fragment ChecklistItem_item on ChecklistItem {
    id
    databaseId
    description
    frequency
    localizedFrequency
    link
    localizedRoleName
    isGlobal
    appliesToAllMembers
    viewerCanSeeEditIcon
    privateToCircle
    position
    viewerCanReorder

    organization {
      id
      onRestrictedPlan
    }

    customFrequency {
      id
      name
      universalUnits
    }

    people(first: 30) {
      edges {
        node {
          name
        }
      }
    }

    role {
      id
    }
  }
`

const circleFragment = graphql`
  fragment ChecklistItem_circle on Circle {
    databaseId

    organization {
      databaseId
    }

    ...MetricItem_circle
  }
`

function ChecklistItem({item: itemKey, circle: circleKey, source}: Props): Node {
  const [isEditModalOpen, {
    close: closeEditModal,
    open: openEditModal,
  }] = useToggle()
  const item = useFragment(itemFragment, itemKey)
  const circle = useFragment(circleFragment, circleKey)

  return (
    <Fragment>
      {isEditModalOpen && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <ChecklistItemEditModal
              close={closeEditModal}
              orgDatabaseId={circle.organization.databaseId}
              circleDatabaseId={circle.databaseId}
              checklistItemId={item.id}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
      <Item
        item={item}
        onEdit={item.viewerCanSeeEditIcon ? openEditModal : null}
        disabled={item.isGlobal}
        tooltip={I18n.t('metrics.tip_editable_by_you')}
        circle={circle}
        source={source}
      />
    </Fragment>
  )
}

export default ChecklistItem
