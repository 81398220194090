// @flow
import type {Node} from 'react'
import React from 'react'
import Select from 'components/ui/forms/Select'
import type {Option} from 'components/types'
import type {FormikProps} from 'formik'
import type {Values} from '../index'

type Props = $ReadOnly<{
  formikProps: FormikProps<Values>,
  governanceEnabled: boolean,
}>

const operationalOptions: $ReadOnlyArray<Option> = [{
  value: 'ACTION',
  label: I18n.t('tactical_meetings.react.types.action'),
}, {
  value: 'PROJECT',
  label: I18n.t('tactical_meetings.react.types.project'),
}, {
  value: 'TRIGGER',
  label: I18n.t('tactical_meetings.react.types.trigger'),
}]

const tensionOption = {
  value: 'TENSION',
  label: I18n.t('tactical_meetings.react.types.tension'),
}

const informationOption = {
  value: 'INFORMATION',
  label: I18n.t('tactical_meetings.react.types.information'),
}

const allOptions: $ReadOnlyArray<Option> = [
  ...operationalOptions,
  tensionOption,
  informationOption,
]

const optionsWithoutTension: $ReadOnlyArray<Option> = [
  ...operationalOptions,
  informationOption,
]

export type OutputType = $PropertyType<Option[number], 'label'>

function OutputTypeSelector({governanceEnabled, formikProps}: Props): Node {
  const options = governanceEnabled
    ? allOptions
    : optionsWithoutTension

  const resetFormValues = (_, value: string | null) => {
    switch (value) {
      case 'ACTION':
        formikProps.setValues({
          ...formikProps.values,
          outputType: value,
          triggerEvent: null,
        })
        break
      case 'TRIGGER':
        formikProps.setValues({
          ...formikProps.values,
          outputType: value,
        })
        break
      case 'PROJECT':
        formikProps.setValues({
          ...formikProps.values,
          outputType: value,
          triggerEvent: null,
        })
        break
      case 'TENSION':
        formikProps.setValues({
          ...formikProps.values,
          outputType: value,
          roleId: null,
          privateToCircle: false,
          triggerEvent: null,
        })
        break
      case 'INFORMATION':
        formikProps.setValues({
          ...formikProps.values,
          outputType: value,
          roleId: null,
          personId: null,
          privateToCircle: false,
          triggerEvent: null,
        })
        break
      default:
        value = 'ACTION'
    }

    formikProps.setFieldValue('outputType', value)
  }

  return (
    <Select
      test-id="tactical-output-type"
      name="outputType"
      options={options}
      placeholder=""
      label={I18n.t('tactical_meetings.handlebars.what')}
      value={formikProps.values.outputType}
      onChange={resetFormValues}
    />
  )
}

export default OutputTypeSelector
