// @flow
import {format, isValid, parseISO} from 'date-fns'
import {configWithLocale} from 'utils/dateFnsLocale'

function formatDate(rawDate: ?string, dateFormat: string = 'PP'): string {
  if (!rawDate)
    return ''

  const date = parseISO(rawDate)

  if (!isValid(date))
    return ''

  return format(date, dateFormat, configWithLocale)
}

export default formatDate
