import _ from 'underscore'
import * as R from 'ramda'
import util from 'utils/MiscUtils'
import updateGupsProposal from 'mutations/gupsSync/updateGupsProposal'

const ProposalActions = {
  PROPOSAL_STATUS_EVENT(action) {
    const eventType = action.eventType || util.error('eventType required')
    const proposalId = action.proposalId || util.error('proposalId required')
    const data = action.data || {}

    return this.apiAdapter.apiRequest({
      method: 'POST',
      path: `/proposals/${proposalId}/status_events/${eventType}`,
      type: 'proposals',
      data,
      options: {
        skipDataProcessing: true,
      },
    }).then(
      this.handleWith({type: 'PROPOSAL_STATUS_EVENT_SUCCESS'}),
    )
  },

  PROPOSAL_STATUS_EVENT_SUCCESS(action) {
    this.mergeResponseDataIntoStore(action)
  },

  PROPOSAL_RESPONSE_CREATE(action) {
    const proposalId = action.proposalId || util.error('proposalId required')
    const value = action.value || util.error('value required')
    const proposedAt = action.proposedAt || util.error('proposedAt required')
    const data = {
      value,
      proposed_at: proposedAt,
      proposal_id: proposalId,
    }

    this.store.setData('proposal_responses', data)

    return this.apiAdapter.apiRequest({
      method: 'POST',
      path: `/proposals/${proposalId}/responses`,
      type: 'proposal_responses',
      data,
    }).then(
      this.handleWith({type: 'DEFAULT_CREATE_SUCCESS'}),
      this.handleWith({type: 'DEFAULT_SERVER_ERROR'}),
    )
  },

  PROPOSAL_RESPONSE_DELETE(action) {
    const proposalId = action.proposalId || util.error('proposalId required')

    return this.apiAdapter.apiRequest({
      method: 'DELETE',
      path: `/proposals/${proposalId}/responses`,
      type: 'proposal_responses',
      id: proposalId, // this is a hack so that I don't have to add to legacy code
    }).then(
      updateGupsProposal(proposalId),
      this.handleWith({type: 'DEFAULT_SERVER_ERROR'}),
    )
  },

  PROPOSAL_LOCAL_CREATE(action) {
    const actionData = action.data || util.error('valid data required')
    const tensionId = action.tensionId || util.error('tensionId required')
    const tension = this.store.getData('tensions', tensionId)
    const circleId = R.path(['links', 'circle'], tension) || util.error(`related tension must have circle: ${JSON.stringify(tension)}`)
    const proposalData = _.extend({}, actionData, {
      tension_id: tensionId,
      person_id: gf.app.currentPersonId(),
      circle_id: circleId,
    })

    const newProposalId = this.store.setData('proposals', proposalData)
      || util.error(`PROPOSAL_LOCAL_CREATE: setData returned a falsey id given proposalData: ${JSON.stringify(proposalData)}`)
    this.store.setData('tensions', tensionId, 'links', {proposal: newProposalId})
    return newProposalId
  },

  PROPOSAL_CREATE(action) {
    const proposalId = action.proposalId || util.error('proposalId required')
    const tensionId = action.tensionId || util.error('tensionId required')
    const actionData = action.data || util.error('valid data required')
    const localSave = action.localSave

    this.store.setData('proposals', proposalId, actionData)
    this.store.setData('tensions', tensionId, 'links', {proposal: proposalId})

    if (localSave)
      return Promise.resolve()

    const proposalData = this.store.getData('proposals', proposalId)
    return this.apiAdapter.apiPost('proposals', proposalData, {localId: proposalId}).then(
      this.handleWith({type: 'PROPOSAL_CREATE_SUCCESS', snapshotData: proposalData}),
    )
  },

  PROPOSAL_CREATE_SUCCESS(action) {
    const type = action.request.type
    const localId = action.request.options.localId
    const responseData = action.response.data[type][0]
    const serverId = responseData.id
    const tensionId = action.snapshotData.tension_id
    const updatedTensionId = this.store.resolveLocalId(tensionId)

    this.apiAdapter.apiResolveLocalId(localId, serverId)
    this.store.setData('tension', tensionId, 'links', {proposal: serverId})
    this.mergeResponseDataIntoStore(action)
    this.store.setData('proposals', serverId, 'links', {tension: updatedTensionId || tensionId})
  },
}

export default ProposalActions
