// @flow
import {Environment, Network, RecordSource, Store, Observable} from 'relay-runtime'
import {head, propOr, replace, toUpper} from 'ramda'

import flash from 'utils/MiscUtils/flash'
import inflate from 'utils/GraphQL/inflate'
import preloadedCacheHandler from 'utils/GraphQL/preloadedCacheHandler'
import logger from 'utils/MiscUtils/main'
import {withAuth} from './authentication'
import config from './config'

// Whitelist individual Bootstrap components as needed
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/tooltip'

const capitalize = replace(/^./, toUpper)

const jsonBody = ({id, text}, variables) => (
  id
    ? {id, variables}
    : {query: text, variables}
)

function flashError(errors) {
  const message = propOr(
    capitalize(I18n.t('errors.messages.unexpected_error')),
    'message',
    head(errors),
  )
  flash.danger(message)
}

const timeOutForGlobalBlock = () => new Promise((resolve) => { setTimeout(resolve, 1500) })

let externalBlockingPromise: Promise<*> = Promise.resolve()

const setGlobalRelayWaitForIt = (thenable: Promise<*>): Promise<*> => {
  externalBlockingPromise = thenable
  return thenable
}

const globalRelayWaitForIt = async () => {
  // pivotaltracker.com/story/show/165424675
  // Sometimes externalBlockingPromise may get stuck, may introduce forever loading.
  // Why? Well, it hard to say why...
  // Anyway, we still want the app to be loaded without stuck.
  // So we make an assumption that every global block won't take more than 1.5 seconds.
  // Otherwise, we skip the block.
  try {
    await Promise.race([externalBlockingPromise, timeOutForGlobalBlock()])
  } catch (error) {
    // However, externalBlockingPromise may not get stuck at all but may throw an error.
    // Here we basically want to catch the error.
    logger.warn('error caused by global block', error)
  } finally {
    // Let's just reset externalBlockingPromise to its initial state.
    externalBlockingPromise = Promise.resolve()
  }
}

async function fetchQuery(operation, variables) {
  try {
    const preloadedData = preloadedCacheHandler(operation, variables)

    if (preloadedData) {
      await globalRelayWaitForIt()
      return preloadedData
    }

    const body = jsonBody(operation, variables)

    const response = await fetch(
      config.GRAPHQL_URL,
      withAuth({
        method: 'POST',
        body: JSON.stringify(body),
      }),
    )

    await globalRelayWaitForIt()

    const json = await response.json()

    if (json.errors) {
      flashError(json.errors)
      return {
        data: null,
        errors: json.errors,
      }
    }

    return json
  } catch (error) {
    logger.warn('error while fetching relay query:', error)

    return {
      data: null,
      errors: [error],
    }
  }
}

const eventNames = {
  GovernanceMeetingEventSubscription: 'governanceMeetingEvent',
  TacticalMeetingOutputsEventSubscription: 'tacticalMeetingEvent',
  CombinedMeetingEventSubscription: 'combinedMeetingEvent',
  OrganizationSubscription: 'organizationEvent',
}

function getSubscriptionChannel(operation, variables) {
  const isGovernanceMeetingEvent = operation.name === 'GovernanceMeetingEventSubscription'
  if (isGovernanceMeetingEvent && variables.meetingDatabaseId)
    return window.gf.comm.subscribe({name: 'governance-meeting', id: variables.meetingDatabaseId})

  const isMeetingEvent = operation.name === 'TacticalMeetingOutputsEventSubscription'
  if (isMeetingEvent && variables.tacticalMeetingDatabaseId) {
    return window.gf.comm.subscribe({
      name: 'tactical-meeting',
      id: variables.tacticalMeetingDatabaseId,
    })
  }

  const isCombinedMeetingEvent = operation.name === 'CombinedMeetingEventSubscription'
  if (isCombinedMeetingEvent && variables.meetingDatabaseId) {
    return window.gf.comm.subscribe({
      prefix: 'private',
      name: 'meeting',
      id: variables.meetingDatabaseId,
    })
  }

  const isOrganizationEvent = operation.name === 'OrganizationSubscription'
  if (isOrganizationEvent && variables.orgDatabaseId) {
    return window.gf.comm.subscribe({
      name: 'organization',
      id: variables.orgDatabaseId,
    })
  }

  return null
}

function handleSubscription(operation, variables) {
  const channel = getSubscriptionChannel(operation, variables)
  return Observable.create(({next, error, closed}) => {
    if (!channel || closed)
      return

    channel.bind(eventNames[operation.name], (data) => {
      try {
        const payload = data.compressed ? inflate(data.compressed) : data

        logger.info(`${eventNames[operation.name]} received from pusher`, payload)
        next(payload)
      } catch (e) {
        error(e)
      }
    })
  })
}

// Create a Relay Modern network with the handler
const network = Network.create(fetchQuery, handleSubscription)
const source = new RecordSource()
const store = new Store(source)

export default (new Environment({network, store}): Environment)

export {
  setGlobalRelayWaitForIt,
}
