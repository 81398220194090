// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import styles from './index.scss'

type Props = $ReadOnly<{
  status: string,
  description: Node,
  link: Node,
  role: Node,
  age: Node,
  roi: ?Node,
  privateToCircle: ?Node,
  source: Node,
  avatar: ?Node,
  parentGoalOrTarget: ?Node,
  'test-id': ?string,
}>

function Card({
  status,
  description,
  link,
  role,
  avatar,
  age,
  roi,
  privateToCircle,
  source,
  parentGoalOrTarget,
  'test-id': testId,
}: Props): Element<"div"> {
  const containerClassname = classNames(
    styles.container,
    styles[status.toLowerCase()],
  )

  return (
    <div className={containerClassname} test-id={testId}>
      <div className={styles.body}>
        <span className={styles.description}>
          {description}
        </span>
        {link && (
          <div className={styles.icon}>{link}</div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <div className={styles.role}>{role}</div>
          <div className={styles.details}>
            {age}
            {roi && (
              <span>{roi}</span>
            )}
            <div>
              <span className={styles.icon}>{source}</span>
              {privateToCircle && (
                <span className={styles.icon}>{privateToCircle}</span>
              )}
              {parentGoalOrTarget}
            </div>
          </div>
        </div>
        <div className={styles.avatar}>{avatar}</div>
      </div>
    </div>
  )
}

Card.defaultProps = {
  'test-id': null,
}

export default Card
