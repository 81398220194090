/**
 * @flow
 * @relayHash 36fe6854e6f99c3747f7aba24b050c2f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConstitutionTab_organization$ref = any;
export type UpdateOrganizationInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  organizationId: string,
  goalsEnabled?: ?boolean,
  modularMeetingsEnabled?: ?boolean,
  setupModeEnabled?: ?boolean,
  constitutionLabel?: ?string,
  constitutionRatifiers?: ?string,
  roiEnabled?: ?boolean,
  fillerNotificationEditingEnabled?: ?boolean,
  pdfReportGenerationRestricted?: ?boolean,
  invitationsEnabled?: ?boolean,
  publiclyVisible?: ?boolean,
  asyncProposalsEnabled?: ?boolean,
  customProfileFields?: ?$ReadOnlyArray<CustomProfileFieldInputObject>,
  wysiwygEnabled?: ?boolean,
|};
export type MutationContext = {|
  organizationId: string
|};
export type CustomProfileFieldInputObject = {|
  name: string,
  initialName?: ?string,
|};
export type UpdateOrganizationMutationVariables = {|
  input: UpdateOrganizationInput
|};
export type UpdateOrganizationMutationResponse = {|
  +updateOrganization: ?{|
    +organization: ?{|
      +$fragmentRefs: ConstitutionTab_organization$ref
    |}
  |}
|};
export type UpdateOrganizationMutation = {|
  variables: UpdateOrganizationMutationVariables,
  response: UpdateOrganizationMutationResponse,
|};
*/


/*
mutation UpdateOrganizationMutation(
  $input: UpdateOrganizationInput!
) {
  updateOrganization(input: $input) {
    organization {
      ...ConstitutionTab_organization
      id
    }
  }
}

fragment AdvancedSettingsV4_organization on Organization {
  id
  setupModeEnabled
  ...SetupModeButton_organization
}

fragment AdvancedSettingsV5_organization on Organization {
  id
  name
  ...SetupModeButton_organization
}

fragment AmendmentForm_organization on Organization {
  ...WYSIWYGTextareaWithFeatureFlag_organization
  ...SectionHeader_organization
}

fragment Amendments_organization on Organization {
  databaseId
  amendments(first: 100) {
    edges {
      node {
        id
        viewerCanUpdate
        ...Section_amendment
        ...EditAmendmentBody_amendment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...CreateAmendmentBody_organization
}

fragment ConstitutionTab_organization on Organization {
  databaseId
  isOnV4
  isOnV5
  v4migrationStatus
  v5migrationStatus
  resourcePath
  viewerCanEditConstitution
  viewerCanViewConstitution
  anchorRole {
    resourcePath
    id
  }
  ...V4ConstitutionSection_organization
  ...ModularGrid_organization
}

fragment CreateAmendmentBody_organization on Organization {
  id
  ...AmendmentForm_organization
}

fragment CustomGovernanceCircles_circles on Circle {
  id
  localizedName
  resourcePath
  isGovernanceEnabled
}

fragment EditAmendmentBody_amendment on Amendment {
  id
  title
  body
  organization {
    ...AmendmentForm_organization
    id
  }
}

fragment ModularGrid_organization on Organization {
  id
  constitutionLabel
  viewerIsAdmin
  onRestrictedPlan
  viewer {
    routes {
      constitutionUrl
    }
    id
  }
  ...RulesSections_organization
  ...AdvancedSettingsV5_organization
  ...Amendments_organization
}

fragment RulesSections_organization on Organization {
  name
  constitutionRatifiers
  onRestrictedPlan
  isModularAdoptionByCircleEnabled
  circles {
    edges {
      node {
        isGovernanceEnabled
        ...CustomGovernanceCircles_circles
        id
      }
    }
  }
  rules {
    ruleKey
    ruleValue
    ...Section_rule
    id
  }
  viewer {
    routes {
      constitutionUrl
      articleTargets {
        article1
        article2
        article3
        article4
        article5
      }
    }
    id
  }
  ...Section_organization
}

fragment SectionBody_organization on Organization {
  constitutionRatifiers
  ...WYSIWYGViewerWithFeatureFlag_organization
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment SectionHeader_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment Section_amendment on Amendment {
  id
  ...EditAmendmentBody_amendment
}

fragment Section_organization on Organization {
  id
  constitutionRatifiers
  ...SectionBody_organization
  ...SectionHeader_organization
}

fragment Section_rule on Rule {
  id
  title
  frogTip
  ruleKey
  ruleValue
  centralizedText
  universalText
  viewerCanUpdate
}

fragment SetupModeButton_organization on Organization {
  id
  setupModeEnabled
  setupModeSuspendedInvitationsCount
}

fragment V4ConstitutionSection_organization on Organization {
  id
  name
  v5migrationStatus
  setupModeEnabled
  viewer {
    routes {
      constitutionUrl
    }
    id
  }
  ...AdvancedSettingsV4_organization
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourcePath",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConstitutionTab_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnV4",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnV5",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "v4migrationStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "v5migrationStatus",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanEditConstitution",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanViewConstitution",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "anchorRole",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setupModeEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Routes",
                    "kind": "LinkedField",
                    "name": "routes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "constitutionUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConstitutionArticleTargets",
                        "kind": "LinkedField",
                        "name": "articleTargets",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article3",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article4",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article5",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setupModeSuspendedInvitationsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constitutionLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerIsAdmin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onRestrictedPlan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constitutionRatifiers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isModularAdoptionByCircleEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGovernanceEnabled",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Rule",
                "kind": "LinkedField",
                "name": "rules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ruleKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ruleValue",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frogTip",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "centralizedText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "universalText",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "AmendmentConnection",
                "kind": "LinkedField",
                "name": "amendments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AmendmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Amendment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v7/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "amendments(first:100)"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Amendments_amendments",
                "kind": "LinkedHandle",
                "name": "amendments"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "36fe6854e6f99c3747f7aba24b050c2f",
    "metadata": {},
    "name": "UpdateOrganizationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '783ee132f7d22a25f481ef81ddc58f72';

module.exports = node;
