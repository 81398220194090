// @flow
import {graphql} from 'react-relay'

import rangeAddUpdater from 'utils/GraphQL/rangeAddUpdater'
import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  AddTacticalOutputMutationResponse as Response,
  AddTacticalOutputInput as Input,
} from './__generated__/AddTacticalOutputMutation.graphql'

const mutation = graphql`
  mutation AddTacticalOutputMutation(
    $input: AddTacticalOutputInput!
  ) {
    addTacticalOutput(input: $input) {
      output {
        node {
          id
          databaseId
          
          agendaItem {
            databaseId
          }
          
          ...Output_output
        }
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  const updater = rangeAddUpdater({
    parentId: input.meetingId,
    connectionInfo: {
      key: 'AgendaItemGroups_tacticalOutputs',
    },
    edgeName: 'output',
    rootNode: 'addTacticalOutput',
    addLocation: 'before',
  })

  return createMutationPromise({
    mutation,
    variables,
    updater,
  })
}
