// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import AddTacticalOutputMutation from 'mutations/AddTacticalOutputMutation'
import OutputForm, {type SubmitValues} from '../OutputForm'

import type {
  NewOutputForm_circle as Circle,
} from './__generated__/NewOutputForm_circle.graphql'
import type {
  NewOutputForm_meeting as Meeting,
} from './__generated__/NewOutputForm_meeting.graphql'

type Props = $ReadOnly<{
  meeting: Meeting,
  circle: Circle,
  beforeSave: () => Promise<void>
}>

function NewOutputForm({meeting, circle, beforeSave}: Props): Node {
  const submit = async (values: SubmitValues) => {
    await beforeSave()
    await AddTacticalOutputMutation({
      ...values,
      meetingId: meeting.id,
      agendaItemId: meeting.currentAgendaItem?.id,
    })
  }

  return (
    <OutputForm
      circle={circle}
      submit={submit}
      output={null}
      meeting={meeting}
    />
  )
}

export default (createFragmentContainer(NewOutputForm, {
  circle: graphql`
    fragment NewOutputForm_circle on Circle {
      ...OutputForm_circle
    }
  `,
  meeting: graphql`
    fragment NewOutputForm_meeting on Meeting {
      id

      currentAgendaItem {
        id
      }
      
      ...OutputForm_meeting
    }
  `,
}): RelayFragmentContainer<typeof(NewOutputForm)>)
