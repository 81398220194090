/**
 * @flow
 * @relayHash 64973ab0a2f5ce5d1b183affd72beeb4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useRolesForAutocompleteQueryVariables = {|
  orgDatabaseId: string,
  count: number,
  circleDatabaseId?: ?string,
|};
export type useRolesForAutocompleteQueryResponse = {|
  +organization: ?{|
    +autocompleteRoles: $ReadOnlyArray<{|
      +name: string,
      +link: any,
      +linkText: string,
      +localizedName: string,
    |}>
  |}
|};
export type useRolesForAutocompleteQuery = {|
  variables: useRolesForAutocompleteQueryVariables,
  response: useRolesForAutocompleteQueryResponse,
|};
*/


/*
query useRolesForAutocompleteQuery(
  $orgDatabaseId: String!
  $count: Int!
  $circleDatabaseId: String
) {
  organization(databaseId: $orgDatabaseId) {
    autocompleteRoles(first: $count, circleDatabaseId: $circleDatabaseId) {
      name: localizedNameWithContext
      link: resourcePath
      linkText: legacyLinkText
      localizedName
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "circleDatabaseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgDatabaseId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "circleDatabaseId",
      "variableName": "circleDatabaseId"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "count"
    }
  ],
  "concreteType": "AutocompleteRole",
  "kind": "LinkedField",
  "name": "autocompleteRoles",
  "plural": true,
  "selections": [
    {
      "alias": "name",
      "args": null,
      "kind": "ScalarField",
      "name": "localizedNameWithContext",
      "storageKey": null
    },
    {
      "alias": "link",
      "args": null,
      "kind": "ScalarField",
      "name": "resourcePath",
      "storageKey": null
    },
    {
      "alias": "linkText",
      "args": null,
      "kind": "ScalarField",
      "name": "legacyLinkText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRolesForAutocompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useRolesForAutocompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "64973ab0a2f5ce5d1b183affd72beeb4",
    "metadata": {},
    "name": "useRolesForAutocompleteQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48a37ad2f2a40ae9f0c60c9a30f26ceb';

module.exports = node;
