// @flow
import util from './main'

const CSRFToken = (): any | string => {
  const meta = (document.querySelector('meta[name="csrf-token"]'): any)
  if (meta === null) {
    util.warn('There was an issue finding the csrf-token')
    return ''
  }
  return meta.content
}

export default CSRFToken
