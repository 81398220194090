import React, {lazy, Suspense} from 'react'

const Page = lazy(() => import(/* webpackChunkName: "TagHistoryPage" */ './index'))

function LazyTagHistoryPage(props) {
  return (
    <Suspense fallback={null}>
      <Page {...props} />
    </Suspense>
  )
}

export default LazyTagHistoryPage
