/**
 * @flow
 * @relayHash 347d9ad8b76ed39622ee7748f933d014
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Section_amendment$ref = any;
export type UpdateAmendmentInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  amendmentId: string,
  title: string,
  body: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateAmendmentMutationVariables = {|
  input: UpdateAmendmentInput
|};
export type UpdateAmendmentMutationResponse = {|
  +updateAmendment: ?{|
    +amendment: ?{|
      +$fragmentRefs: Section_amendment$ref
    |}
  |}
|};
export type UpdateAmendmentMutation = {|
  variables: UpdateAmendmentMutationVariables,
  response: UpdateAmendmentMutationResponse,
|};
*/


/*
mutation UpdateAmendmentMutation(
  $input: UpdateAmendmentInput!
) {
  updateAmendment(input: $input) {
    amendment {
      ...Section_amendment
      id
    }
  }
}

fragment AmendmentForm_organization on Organization {
  ...WYSIWYGTextareaWithFeatureFlag_organization
  ...SectionHeader_organization
}

fragment EditAmendmentBody_amendment on Amendment {
  id
  title
  body
  organization {
    ...AmendmentForm_organization
    id
  }
}

fragment SectionHeader_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment Section_amendment on Amendment {
  id
  ...EditAmendmentBody_amendment
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAmendmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAmendmentPayload",
        "kind": "LinkedField",
        "name": "updateAmendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Amendment",
            "kind": "LinkedField",
            "name": "amendment",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Section_amendment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAmendmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAmendmentPayload",
        "kind": "LinkedField",
        "name": "updateAmendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Amendment",
            "kind": "LinkedField",
            "name": "amendment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isWysiwygEnabled",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "347d9ad8b76ed39622ee7748f933d014",
    "metadata": {},
    "name": "UpdateAmendmentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07ddbce61685b48d4e6f6dadb3f31945';

module.exports = node;
