// @flow
// TODO: should be moved to GQL side
import {
  allRoleFillersId,
  allCircleMembersId,
  individualActionId,
} from 'constants/fakeId'

export default {
  allRoleFillersId: (parseInt(allRoleFillersId, 10): number),
  allCircleMembersId: (parseInt(allCircleMembersId, 10): number),
  individualActionId,
  // Project accountability
  notSpecifiedId: -1,
}
