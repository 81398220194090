// @flow
import type {Group} from 'components/ui/Groups'
import {useMemo} from 'react'

import {type GroupBy} from 'components/projects/GroupProjectsBy'
import {
  groupProjects,
} from 'components/projects/groupProjects'
import sortByProp from 'utils/MiscUtils/sortByProp'

const sortByTitle: <T>(T) => T = sortByProp('title')

type Person = $ReadOnly<{
  id: string,
  name: string,
  ...
}>

type GenericProject = $ReadOnly<{
  role: $ReadOnly<{
    id: string,
    localizedName: string,
    nameWithLinkedTo: string,
    nameWithLinkedFrom: string,
    ...
  }>,
  person: ?Person,
  ...
}>

function useGroupedProjects<Project: GenericProject>(
  projects: $ReadOnlyArray<Project>,
  groupBy: GroupBy,
  roleNameMode: 'WITH_LINKED_TO'| 'WITH_LINKED_FROM',
  circleLeads: $ReadOnlyArray<Person>,
): $ReadOnlyArray<Group<Project, string>> {
  return useMemo(() => {
    const groups = groupProjects(projects, roleNameMode, groupBy, circleLeads)

    return sortByTitle(groups.filter((group) => group.items.length > 0))
  }, [projects, groupBy, roleNameMode, circleLeads])
}

export default useGroupedProjects
