import Backbone from 'backbone'
import util from 'utils/MiscUtils'
import _ from 'underscore'

const isPlainObject = (x) => _.isObject(x) && x.constructor === Object

export default {
  mixins: [Backbone.Events],
  _isMounted: true,
  componentDidMount: function () {
    this.listenTo(this.store, 'change', this.handleStoreChange);
  },

  componentWillUnmount: function () {
    this._isMounted = false;
    this.stopListening(this.store, 'change', this.handleStoreChange);
  },

  getInitialState: function () {
    this._updatedAt = new Date().getTime();
    this.store = (gf.app && gf.app.store) || util.error("global gf.app.store must be available");
    this.dispatcher = gf.app.dispatcher || util.error("global gf.app.dispatcher must be available");
    this.apiAdapter = gf.app.apiAdapter || util.error("global gf.app.apiAdapter must be available");

    var initialLocalState = this.getInitialLocalState ? this.getInitialLocalState() : {},
        stateAndPromises  = this.getStoredStateAndPromises(this.props),
        state             = stateAndPromises[0],
        promises          = stateAndPromises[1];

    if (this.componentDidLoad) {
      // Since we aren't setting state directly ourselves, but returning it and depending on
      // react to set it, and we want `componentDidLoad` to be called _after_ initial state is set,
      // we add a local promise with a slightly delayed resolve, to cover the cases that there are
      // no promises from the store or that they are all already resolved.
      var localPromise =  $.Deferred();
      promises.push(localPromise);
      this._promises = promises;
      var promise = $.when.apply($, promises).done(this.componentDidLoad);
      this.componentDidLoad.promise = promise;
      setTimeout(localPromise.resolve, 1);
    }

    return _.extend(initialLocalState, state);
  },

  // Seems like there is no way to replace UNSAFE_componentWillReceiveProps with non-deprecated method
  // for mixins. I hope we'll get rid of GUPS before react 17 release
  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (_.isEqual(nextProps, this.props)) return;
    this.setState(this.getStoredState(nextProps));
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    if (!this._isMounted) {
      return false;
    }
    // shallow compare so for props - (so we can have things like store & app in props)
    // deep compare for state.
    // unfortunately duplicates the shallow check on state, but it wasn't straightforward
    // how to pull out just the props comparison from the react code & didn't want to rewrite
    // our own shallow comparison.
    var propsOrStateChanged = this.shallowCompare(this, nextProps, nextState) || !_.isEqual(this.state, nextState);

    return propsOrStateChanged;
  },

  handleStoreChange: function() {
    // the _isMounted check is to avoid warnings about setting state on unMounted components
    //
    // Even though we unsubscribe properly, there's some way (that I don't totally understand)
    // that references are held onto- I believe in (ahem)
    // promises-returned-from-actions-that-return-promises-from-api-calls
    // so handleStoreChange can sometimes still be called after the component is unmounted.
    //
    // react docs say that checking isMounted is an anti-pattern
    // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    //
    // there may be a cleaner way to fix this with cancelable promises as mentioned in above link,
    // but that's a project for another day-- for now we just track _isMounted and check it in handleStoreChange
    //

    if (this._isMounted && (this.mayHaveChangedSince(this._updatedAt))) {
      this.setState(this.getStoredState(this.props));
      this._updatedAt = new Date().getTime();
    }
  },

  shallowCompare: function(instance, nextProps, nextState) {
    return (
      !util.shallowEqual(instance.props, nextProps) || !util.shallowEqual(instance.state, nextState)
    );
  },

  dispatch: function(action) {
    var retval           = this.dispatcher.dispatch(action),
        returningPromise = retval && _.isFunction(retval.then),
        wrapperPromise = jQuery.Deferred(),
        self = this;

    if (returningPromise) {
      // this is a little wrapper hack to make it so you don't have to do .bind(this)
      // on post-dispatch functions.  no 100% sure this syntax improvement is worth
      // potential confusion.  -LWH
      retval.then(function() {
        wrapperPromise.resolveWith(self, arguments);
      }, function() {
        wrapperPromise.rejectWith(self, arguments);
      });
    } else {
      // if the action didn't return a promise, we'll just make one and resolve it
      // immediately, but in new "thread" to give chance for any state updates to GUPS
      // components to complete (I think React may not execute all the state updates
      // synchronously)
      var fn = retval ? wrapperPromise.resolveWith.bind(self, retval) :
        wrapperPromise.resolveWith.bind(self);
      setTimeout(fn, 1);
    }
    return wrapperPromise.promise();
  },

  mayHaveChangedSince: function (time) {
    var dataMap = this.getDataMap ? this.getDataMap(this.props) : {},
        key;

    for (key in dataMap) {
      if (!dataMap.hasOwnProperty(key)) continue;

      if (this.store.mayHaveChangedSince.apply(this.store, [time].concat(dataMap[key]))) {
        return true;
      }
    }
    return false
  },

  getStoredStateAndPromises: function(props) {
    var promises = [];
    var dataMap = this.getDataMap ? this.getDataMap(props) : {},
        state   = {},
        key;

    for (key in dataMap) {
      if (!dataMap.hasOwnProperty(key)) continue;
      var args = dataMap[key];

      // add options argument if it's not there
      if (!isPlainObject(args[args.length - 1])) args.push({});

      var flags = args[args.length - 1];

      args[args.length - 1]._requester = this;
      args[args.length - 1]._requesterClass = this.constructor.displayName;
      var lastPromise;
      try {
        if (flags.fetch || !flags.hasOwnProperty('fetch')) {
          lastPromise = gf.app.apiFetcher.fetchDataForArguments.apply(gf.app.apiFetcher, args);
        }
        state[key] = this.store.getData.apply(this.store, args);
      } catch (e) {
        var error = {
          message: "error loading data: " + e.message,
          dataMapKey: key,
          getDataArgs: args,
          origError: e
        };
        state['_error'] = error;
        util.reportAndContinue(e, {object: error});
      }
      if (lastPromise) promises.push(lastPromise);
    }

    return [state, promises];
  },

  getStoredState: function(props) {
    return this.getStoredStateAndPromises(props)[0];
  }
}
