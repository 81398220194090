// @flow
import type {AbstractComponent} from 'react'
import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import type {$RelayProps} from 'react-relay'
import {Field, FormikProvider, Form, useFormik} from 'formik'

import InputWithRoleAutocomplete from 'components/ui/forms/InputWithRoleAutocomplete'
import Textarea from 'components/ui/forms/Textarea'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import UpdateTensionInlineBoxContainer from 'components/tensions/UpdateTensionInlineBoxContainer'
import CircleRoleSelector from 'components/roles/CircleRoleSelector'
import UpdateTensionMutation from 'mutations/UpdateTensionMutation'
import AddTensionMutation from 'mutations/AddTensionMutation'
import useFormikAutosave from 'utils/hooks/useFormikAutosave'
import useCKRolesFeed from 'utils/hooks/useCKRolesFeed'

import type {
  AgendaTensionEditForm_agendaItem as AgendaItem,
} from './__generated__/AgendaTensionEditForm_agendaItem.graphql'
import type {
  AgendaTensionEditForm_meeting as Meeting,
} from './__generated__/AgendaTensionEditForm_meeting.graphql'

type Props = $ReadOnly<{
  meeting: Meeting,
  agendaItem: AgendaItem,
  close: () => void,
}>

type Values = $ReadOnly<{
  body: string,
  impactedRoleId: string | null,
  roleId: string | null,
}>

const AgendaTensionEditForm = forwardRef(({
  meeting,
  agendaItem,
  close,
}: Props, ref) => {
  const form = useFormik<Values>({
    initialValues: {
      body: agendaItem.tension?.body || '',
      impactedRoleId: agendaItem.tension?.impactedRole?.id || null,
      roleId: meeting.circle.supportedRole?.id,
    },
    onSubmit: async (values) => {
      if (agendaItem.tension) {
        await UpdateTensionMutation(agendaItem.tension.id, null, values)
      } else {
        await AddTensionMutation({
          ...values,
          agendaItemId: agendaItem.id,
        })
      }
    },
  })

  useFormikAutosave(form)

  useEffect(() => {
    form.resetForm()
    // We want to reset form ONLY when agenda item id changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaItem.id])

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (form.dirty)
        await form.submitForm()
    },
  }))

  const rolesFeed = useCKRolesFeed(meeting.circle.organization.databaseId)

  return (
    <FormikProvider value={form}>
      <Form>
        <UpdateTensionInlineBoxContainer
          onSubmit={close}
        >
          <WYSIWYGTextareaWithFeatureFlag
            mode="inline"
            getFeed={rolesFeed}
            value={form.values.body}
            onChange={form.handleChange}
            label={I18n.t('tensions.react.labels.your_tension')}
            name="body"
            organization={meeting.circle.organization}
            rows={2}
            warning={form.errors.body}
            fallbackComponent={InputWithRoleAutocomplete}
            fallbackProps={{
              placeholder: I18n.t('tensions.react.placeholders.body'),
              input: Textarea,
              orgDatabaseId: meeting.circle.organization.databaseId,
            }}
          />
          <Field
            test-id="tension-role"
            circle={meeting.circle}
            useAsValue="id"
            as={CircleRoleSelector}
            label={I18n.t('tensions.react.labels.role')}
            name="impactedRoleId"
            placeholder={I18n.t('tensions.react.role_selector_placeholder')}
          />
        </UpdateTensionInlineBoxContainer>
      </Form>
    </FormikProvider>
  )
})

export default (createFragmentContainer(AgendaTensionEditForm, {
  agendaItem: graphql`
    fragment AgendaTensionEditForm_agendaItem on AgendaItem {
      id
      tension {
        id
        body

        impactedRole {
          id
        }
      }
    }
  `,
  meeting: graphql`
    fragment AgendaTensionEditForm_meeting on Meeting {
      circle {
        supportedRole {
          id
        }

        ...CircleRoleSelector_circle

        organization {
          databaseId

          ...WYSIWYGTextareaWithFeatureFlag_organization
        }
      }
    }
  `,
}): AbstractComponent<
  $RelayProps<
    React$ElementConfig<
      React$AbstractComponent<Props, { submit: () => Promise<void> }>,
    >,
  >,
  { submit: () => Promise<void> },
>)
