export function dig(object, ...keys) {
  return keys.reduce((o, k) => (o || {})[k], object)
}

export function deepAssign(object, keys, value) {
  keys.reduce((o, k, i, ks) => {
    if (i === ks.length - 1)
      o[k] = value
    else
      o[k] = o[k] || {}

    return o[k]
  }, object)
}
