// @flow
import {ascend, descend, path, pipe, prop, toLower, sortWith} from 'ramda'
import {parseISO} from 'date-fns'
import latinizeString from 'utils/MiscUtils/latinizeString'

type GenericProject = $ReadOnly<{
  description: string,
  createdAt: string,
  status: string,
  circle: $ReadOnly<{
    shortName: string,
    ...
  }>,
  roi: ?number,
  ...
}>

function getStatusSortValue<Project: GenericProject>(project: Project) {
  const statusSortHash = {current: 1, waiting: 2, done: 3, future: 4, archived: 5}
  return statusSortHash[project.status.toLowerCase()] || 6
}

function getProjectRoi<Project: GenericProject>(project: Project) {
  return project.roi
}

type SortFn = <Project: GenericProject>(projects: Project[]) => Project[]

type SortMethods = {
  description: SortFn,
  created_at: SortFn,
  status: SortFn,
  circle: SortFn,
  roi: SortFn,
}

const sortMethods: SortMethods = {
  description: ascend(pipe(prop('description'), latinizeString, toLower)),
  created_at: descend(pipe(prop('createdAt'), parseISO)),
  status: ascend(getStatusSortValue),
  circle: ascend(pipe(path(['circle', 'shortName']), latinizeString, toLower)),
  roi: descend(getProjectRoi),
}

const nullSortFn = <Project>(projects: Project[]): Project[] => projects

export const availableFields: string[] = Object.keys(sortMethods)

export default function sortByFields(sortByKeys: string[]):
  (<Project: GenericProject>(projects: $ReadOnlyArray<Project>) => any) {
  return <Project: GenericProject>(
    projects: $ReadOnlyArray<Project>,
  ) => sortWith(sortByKeys.map((sortKey) => (
    sortMethods[sortKey] || nullSortFn
  )))(projects)
}
