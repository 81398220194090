// @flow
import type {Element} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import SquareOutline from 'components/ui/icons/md/SquareOutline'
import CheckboxOutline from 'components/ui/icons/md/CheckboxOutline'
import {CompleteActionMutation, UncompleteActionMutation} from 'mutations/Actions'
import styles from './index.scss'

import type {
  ActionCompletionCheckbox_action$key as Action,
} from './__generated__/ActionCompletionCheckbox_action.graphql'

type Props = $ReadOnly<{
  action: Action,
}>

const actionFragment = graphql`
  fragment ActionCompletionCheckbox_action on Action {
    id
    completedAt
  }
`

function ActionCompletionCheckbox({action: actionKey}: Props): Element<"div"> {
  const action = useFragment(actionFragment, actionKey)
  const checked = !!action.completedAt

  const complete = () => {
    CompleteActionMutation(action.id)
  }

  const uncomplete = () => {
    UncompleteActionMutation(action.id)
  }

  const processCompletion = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation()

    return checked
      ? uncomplete()
      : complete()
  }

  return (
    <div
      className={styles.button}
      role="checkbox"
      aria-checked={checked}
      {...accessibleOnClickProps(processCompletion)}
    >
      {checked
        ? <CheckboxOutline className={styles.checkedIcon} />
        : <SquareOutline className={styles.uncheckedIcon} />
      }
    </div>
  )
}

export default ActionCompletionCheckbox
