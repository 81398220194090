// @flow
import type {Node} from 'react'
import React from 'react'
import Security from 'components/ui/icons/fa/Security'
import classNames from 'classnames'

import style from './style.scss'

type Props = $ReadOnly<{
  className?: string,
  privacyText: ?string,
}>

function PrivateIcon({className, privacyText}: Props): Node {
  const containerClassNames = classNames(style.visibilityContainer, className)

  return (
    <span className={containerClassNames}>
      <Security className={style.privateIcon} />
      {privacyText}
    </span>
  )
}

export default PrivateIcon
