// @flow
import type {Element} from 'react'
import React, {useRef, useState} from 'react'
import Button, {type Color} from 'components/ui/forms/Button'

type Props = $ReadOnly<{
  text: string,
  color: Color,
}>

function RailsSubmitButton({text, color}: Props): Element<"div"> {
  const ref = useRef()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const onClick = () => {
    setIsSubmitted(true)
    if (!ref.current)
      return

    const form: HTMLFormElement = ref.current.closest('form')
    // $FlowFixMe[method-unbinding]
    if (form && form.submit)
      form.submit()
  }
  return (
    // https://stackoverflow.com/questions/50076176/how-to-use-flow-with-react-createref
    // $FlowFixMe[incompatible-type]
    <div ref={ref}>
      <Button
        type="submit"
        onClick={onClick}
        color={color}
        loading={isSubmitted}
      >
        {text}
      </Button>
    </div>
  )
}

export default RailsSubmitButton
