// @flow
import type {Node} from 'react'
import React, {useState} from 'react'
import $ from 'jquery'
import util from '../../../utils/MiscUtils'
import ExportButtonComponent from './ExportButtonComponent'

type Props = {
  organizationId: string,
  personId: string
}

function ExportComponent({organizationId, personId}: Props): Node {
  const [message, setMessage] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)

  const onExportFinished = () => {
    window.location.reload()
  }

  const startCheckExportState = (exportId) => {
    const interval = setInterval(async () => {
      try {
        const response = await $.ajax({
          url: `/admin/organizations/${organizationId}/exports/${exportId}/status`,
          cache: false,
        })

        const e = response.export
        if (!e || e.completed_at) {
          clearInterval(interval)
          onExportFinished()
        }
      } catch (e) {
        clearInterval(interval)
        setMessage(null)
      }
    }, 15000)
  }

  const handleStartExport = () => {
    const data = {
      data: {
        id: util.generateUuid(),
        relationships: {
          person: {
            type: 'people',
            id: personId,
          },
          'requested-organization': {
            type: 'organizations',
            id: organizationId,
          },
        },
      },
    }
    setMessage(
      I18n.t('admin.organizations.exports.exporting_msg'),
    )
    setIsDisabled(true)

    $.ajax({
      url: `/admin/organizations/${organizationId}/exports`,
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
    })
      .then((response) => response.export.id)
      .then((exportId) => startCheckExportState(exportId))
      .fail((e) => {
        setMessage(e?.responseJSON?.error || I18n.t('errors.messages.unexpected_error'))
        setIsDisabled(false)
      })
  }

  return (
    <ExportButtonComponent
      message={message}
      disabled={isDisabled}
      loading={isDisabled}
      onClick={handleStartExport}
    />
  )
}

export default ExportComponent
