// @flow
import React, {useCallback, forwardRef, useState, useEffect} from 'react'
import classNames from 'classnames'

import InputContainer from '../InputContainer'
import Label from '../Label'
import styles from './index.scss'

/* eslint jsx-a11y/no-autofocus:0 */

type Props = $ReadOnly<{
  className?: string,
  autosize: boolean,
  autofocus: boolean,
  label: string,
  hideLabel?: string | boolean,
  onChange: (SyntheticInputEvent<>, string) => void,
  onBlur?: (SyntheticFocusEvent<>) => void,
  onKeyDown: (SyntheticKeyboardEvent<HTMLTextAreaElement>) => void,
  onFocus: (SyntheticFocusEvent<HTMLTextAreaElement>) => void,
  name?: string,
  'test-id': ?string,
  placeholder: string,
  rows: number,
  value: ?string,
  warning?: string | null,
  disabled: boolean,
  inputRef: (HTMLElement | null) => void,
}>

const linesCount = (string: ?string) => {
  if (string == null)
    return 1

  return string.split(/\r\n|\r|\n/).length
}

function TextareaWithoutForwardRef({
  autosize,
  autofocus,
  className,
  label,
  hideLabel,
  onChange,
  onBlur,
  onKeyDown,
  onFocus,
  'test-id': testId,
  name,
  placeholder,
  rows,
  value,
  warning,
  disabled,
  inputRef,
}: Props, ref) {
  const [rowsToUse, setRowsToUse] = useState(() => {
    const currentLinesCount = linesCount(value)

    return currentLinesCount >= rows && autosize
      ? currentLinesCount
      : rows
  })

  const setRowsByValue = useCallback((fieldValue: ?string) => {
    const currentLinesCount = linesCount(fieldValue)

    if (currentLinesCount >= rows && autosize)
      setRowsToUse(currentLinesCount)
  }, [rows, autosize, setRowsToUse])

  useEffect(() => {
    setRowsByValue(value)
  }, [value, setRowsByValue])

  const isValid = warning === null

  const handleChange = useCallback((event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value

    setRowsByValue(newValue)

    onChange(event, newValue)
  }, [onChange, setRowsByValue])

  const inputClassName = classNames(styles.base, className, {
    [styles.warning]: !isValid,
  })

  const input = (
    <textarea
      ref={(_ref) => {
        // Call passed setter(it will write the generated _ref
        // to the mutable current field of ref object), so we will be able
        // to have an access to the component ref in the parent component
        if (inputRef)
          inputRef(_ref)

        // Call ref function with this generated _ref,
        // so it will set _ref to the current component and autocomplete
        // component will handle it too
        if (ref)
          // $FlowFixMe[prop-missing]
          ref(_ref)

        // Return this ref function to follow consistency
        return ref
      }}
      autoFocus={autofocus}
      disabled={disabled}
      name={name}
      test-id={testId}
      onChange={handleChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      placeholder={placeholder}
      rows={rowsToUse}
      value={value || ''} // This is to mitigate the warning about nulls
      className={inputClassName}
    />
  )

  return (
    <InputContainer className={styles.block}>
      {hideLabel && input}
      {!hideLabel && (
        <Label
          input={input}
          label={label}
        />
      )}
      {warning ? <div className={styles.errorMessage}>{warning}</div> : null}
    </InputContainer>
  )
}

const Textarea: React$AbstractComponent<Props, HTMLTextAreaElement>
  = forwardRef<Props, HTMLTextAreaElement>(TextareaWithoutForwardRef)

// $FlowFixMe[prop-missing]
Textarea.defaultProps = {
  autosize: false,
  autofocus: false,
  className: '',
  hideLabel: false,
  onBlur: () => {},
  warning: null,
  disabled: false,
  rows: 2,
  'test-id': null,
}

export default Textarea
