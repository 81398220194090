import uniqueArrayById from 'utils/MiscUtils/uniqueArrayById'

function projectsRoles(projects) {
  const rolesWithDuplications = projects
    .map((project) => project.role)

  return uniqueArrayById(rolesWithDuplications)
}

export function groupProjectsByRole(projects, roleNameMode) {
  return projectsRoles(projects).map((role) => {
    const roleProjects = projects.filter((project) => (
      project.role.id === role.id
    ))

    return {
      items: roleProjects,
      key: role.id,
      title: roleNameMode === 'WITH_LINKED_FROM'
        ? role.nameWithLinkedFrom
        : role.nameWithLinkedTo,
    }
  })
}

function projectsPersons(projects, exclude) {
  const peopleWithDuplications = projects
    .map((project) => project.person)
    .filter(Boolean)
    .filter((person) => exclude.indexOf(person.id) === -1)

  return uniqueArrayById(peopleWithDuplications)
}

export function groupProjectsByPerson(projects, circleLeads) {
  const circleLeadProjects = circleLeads.map((person) => ({
    key: person.id,
    title: person.name,
    items: projects.filter((project) => !project.person || project.person.id === person.id),
  }))

  const otherPeople = projectsPersons(projects, circleLeads.map((c) => c.id))
  const otherProjects = otherPeople.map((person) => ({
    key: person.id,
    title: person.name,
    items: projects.filter((project) => project.person?.id === person.id),
  }))

  return [...otherProjects, ...circleLeadProjects]
}

export function groupProjectsByDefault(projects) {
  return [{
    items: projects,
    key: 'projects-all',
  }]
}

export function groupProjects(projects, roleNameMode, groupBy, circleLeads) {
  if (groupBy === 'role')
    return groupProjectsByRole(projects, roleNameMode)

  if (groupBy === 'person')
    return groupProjectsByPerson(projects, circleLeads)

  return groupProjectsByDefault(projects)
}
