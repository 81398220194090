// @flow
import {commitMutation} from 'react-relay'

import environment from 'environment'
import {info, warn} from 'utils/logger'
import currentOrgGid from 'utils/MiscUtils/currentOrgGid'

// FIXME: use real types from 'relay-runtime/mutations/commitMutation'
type CommitMutationArgs = Object
type MutationVariables = Object

type MutationContext = {
  organizationId: string,
}

type MutationInput<OriginalType: {context: MutationContext, ...}> =
  $Rest<OriginalType, {context: MutationContext}>

export type {MutationInput}

// TODO: Move this logic into the `environment` layout
function writeMutationContext({input}: MutationVariables) {
  if (!input)
    return

  if (!input.context) {
    info('Writing the mutation context...')
    input.context = {
      organizationId: currentOrgGid(),
    }
  }
}

function createMutationPromise<T>(fields: CommitMutationArgs): Promise<T> {
  const {onCompleted, variables} = fields

  writeMutationContext(variables) // beware, it mutates the passing variables

  return new Promise((resolve: Function, reject: Function) => {
    commitMutation(
      environment,
      {
        ...fields,
        onCompleted: (response: T) => {
          resolve(response)
          if (onCompleted)
            onCompleted(response)
        },
        onError: (error: Error) => {
          warn(error)
          reject(error)
        },
      },
    )
  })
}

export default createMutationPromise
