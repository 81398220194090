// @flow
import {identity} from 'ramda'
import setWindowLocation from './_setWindowLocation'

const locationFallback = window.location

type PushArgs = {|
  pathname: string,
  search?: string,
|} | string

const historyPush = (config: PushArgs): void => {
  const pathname = typeof config === 'string' ? config : config.pathname
  const search = typeof config === 'string' ? null : config.search

  const newLocation = search ? `${pathname}?${search}` : pathname
  setWindowLocation(newLocation)
}

const historyFallback = {
  ...window.history,
  location: locationFallback,
  push: historyPush,
  replace: historyPush,
  listen: identity,
}

const matchFallback = {
  isExact: false,
  params: ({}: { ... }),
  path: '/',
  url: '/',
}

export default {
  history: historyFallback,
  location: locationFallback,
  match: matchFallback,
}
