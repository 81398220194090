// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import ChecklistList from 'components/pages/Checklists/ChecklistList'

import type {
  ProfileChecklistsView_organization$key as Organization,
} from './__generated__/ProfileChecklistsView_organization.graphql'
import type {
  ProfileChecklistsView_person$key as Person,
} from './__generated__/ProfileChecklistsView_person.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  person: Person,
}>

const orgFragment = graphql`
  fragment ProfileChecklistsView_organization on Organization {
    viewer {
      id
    }
    ...ChecklistList_organization
  }
`

const personFragment = graphql`
  fragment ProfileChecklistsView_person on Person {
    id
    ...ChecklistList_viewer
  }
`

function ProfileChecklistsView({organization: orgKey, person: personKey} : Props): Node {
  const organization = useFragment(orgFragment, orgKey)
  const viewer = organization.viewer
  const person = useFragment(personFragment, personKey)

  return (
    <ChecklistList
      showAddButton={person.id === viewer.id}
      viewer={person}
      organization={organization}
    />
  )
}

export default ProfileChecklistsView
