// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'

import environment from 'environment'
import AsyncBannersView from './AsyncBannersView'

type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

function AsyncBanners({orgDatabaseId}: Props): Node {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={null}>
        <AsyncBannersView orgDatabaseId={orgDatabaseId} />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default AsyncBanners
