// @flow
import type {Element, Node} from 'react'
import React, {Component} from 'react'
import {Tooltip} from 'reactstrap'
import {evolve, not} from 'ramda'

import Help from 'components/ui/icons/md/Help'

type Props = {
  children?: Node,
  definition: string,
  target: string,
}

type State = {
  isOpen: boolean,
}

/* eslint react/no-danger: 0 */
class DefinitionTooltip extends Component<Props, State> {
  state: State = {
    isOpen: false,
  }

  toggle: (() => void) = () => this.setState(evolve({isOpen: not}))

  render(): Element<"div"> {
    return (
      <div className="ui-definition-tooltip">
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <label className="ui-definition-tooltip__title">
          {this.props.children}
        </label>
        <span
          className="ui-definition-tooltip__question-badge"
          id={this.props.target}
        >
          <Help />
        </span>
        <Tooltip
          autohide={false}
          delay={{hide: 200}}
          isOpen={this.state.isOpen}
          modifiers={[{name: 'preventOverflow', options: {boundary: 'window'}}]}
          target={this.props.target}
          toggle={this.toggle}
        >
          <span dangerouslySetInnerHTML={{__html: this.props.definition}} />
        </Tooltip>
      </div>
    )
  }
}

export default DefinitionTooltip
