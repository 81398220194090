// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import {useFragment, graphql} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import Box from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/Box'

import type {
  MetricListBody_viewer$key as ViewerKey,
} from './__generated__/MetricListBody_viewer.graphql'

import MetricsViewerSection from './MetricsViewerSection'

type Props = $ReadOnly<{
  viewer: ViewerKey,
}>

const viewerFragment = graphql`
  fragment MetricListBody_viewer on Person {
    metrics(first: 100) @connection(key: "Viewer_metrics", filters: []) {
      edges {
        node {
          ...MetricsViewerSection_metrics
        }
      }
    }
  }
`

function MetricListBody({viewer: viewerKey}: Props): Node {
  const viewer = useFragment(viewerFragment, viewerKey)

  const metrics = useMemo(() => (
    liftNodes(viewer.metrics)
  ), [viewer])

  return (
    <Box.Body>
      <MetricsViewerSection
        showNoItemsMessage
        metrics={metrics}
      />
    </Box.Body>
  )
}

export default MetricListBody
