/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PersonCircleSelector_person$ref = any;
type PersonRoleSelector_person$ref = any;
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CircleAndRoleSelector_viewer$ref: FragmentReference;
declare export opaque type CircleAndRoleSelector_viewer$fragmentType: CircleAndRoleSelector_viewer$ref;
export type CircleAndRoleSelector_viewer = {|
  +circles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +supportedRole: ?{|
          +id: string,
          +isDisplayedAsCircle: boolean,
          +$fragmentRefs: RoleSelector_roles$ref,
        |},
      |}
    |}>
  |},
  +$fragmentRefs: PersonRoleSelector_person$ref & PersonCircleSelector_person$ref,
  +$refType: CircleAndRoleSelector_viewer$ref,
|};
export type CircleAndRoleSelector_viewer$data = CircleAndRoleSelector_viewer;
export type CircleAndRoleSelector_viewer$key = {
  +$data?: CircleAndRoleSelector_viewer$data,
  +$fragmentRefs: CircleAndRoleSelector_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CircleAndRoleSelector_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "CircleConnection",
      "kind": "LinkedField",
      "name": "circles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CircleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Circle",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "supportedRole",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isDisplayedAsCircle",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "RoleSelector_roles"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "circles(first:100)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonRoleSelector_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonCircleSelector_person"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '36ea62f5ca5ee20c1511abdea520b807';

module.exports = node;
