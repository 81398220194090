// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  UpdateActionMutationResponse as Response,
  UpdateActionInput as Input,
} from './__generated__/UpdateActionMutation.graphql'

const mutation = graphql`
  mutation UpdateActionMutation(
  $input: UpdateActionInput!
  ) {
    updateAction(input: $input) {
      action {
        ...ActionsList_actions
      }
    }
  }
`

export default ({actionId, ...inputs}: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input: {
      actionId,
      ...inputs,
    },
  }

  return createMutationPromise({
    mutation,
    variables,
  })
}
