/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SectionHeader_organization$ref = any;
type WYSIWYGTextareaWithFeatureFlag_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AmendmentForm_organization$ref: FragmentReference;
declare export opaque type AmendmentForm_organization$fragmentType: AmendmentForm_organization$ref;
export type AmendmentForm_organization = {|
  +$fragmentRefs: WYSIWYGTextareaWithFeatureFlag_organization$ref & SectionHeader_organization$ref,
  +$refType: AmendmentForm_organization$ref,
|};
export type AmendmentForm_organization$data = AmendmentForm_organization;
export type AmendmentForm_organization$key = {
  +$data?: AmendmentForm_organization$data,
  +$fragmentRefs: AmendmentForm_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AmendmentForm_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WYSIWYGTextareaWithFeatureFlag_organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SectionHeader_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '381c692701e05d6947d989c621a5bfa1';

module.exports = node;
