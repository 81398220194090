// @flow
import type {Node} from 'react'
import React from 'react'
import {useContainerQuery} from 'react-container-query'
import {graphql, useFragment} from 'react-relay'
import classnames from 'classnames'

import {widthXxs} from 'styles/variables-breakpoints.scss'
import BoltSolid from 'components/ui/icons/fa/BoltSolid'
import Pencil from 'components/ui/icons/fa/Pencil'
import Age from 'components/ui/Age'
import ItemDetailRow from 'components/ui/ItemDetailRow'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import TensionBoxContainer, {type MarginBottom} from '../TensionBoxContainer'
import styles from './index.scss'

import type {TensionInfo_organization$key as Organization} from './__generated__/TensionInfo_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  roleName: ?string,
  personName: ?string,
  tensionCreatedAt: string,
  tensionBody: ?string,
  agendaLabel: ?string,
  openForm: ?() => void,
  sensedByViewer: boolean,
  marginBottom: MarginBottom,
}>

const containerConfig = {
  narrowScreen: {
    maxWidth: widthXxs,
  },
}

const organizationFragment = graphql`
  fragment TensionInfo_organization on Organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
  }
`

function TensionInfo({
  organization: organizationKey,
  roleName,
  personName,
  tensionCreatedAt,
  tensionBody,
  agendaLabel,
  openForm,
  sensedByViewer,
  marginBottom,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const [containerQuery, containerQueryRef] = useContainerQuery(containerConfig)

  const roleLabel = () => {
    if (roleName && sensedByViewer)
      return I18n.t('tensions.react.sensed_by', {role: roleName})

    if (roleName && personName)
      return I18n.t('tensions.react.sensed_by_person', {role: roleName, person: personName})

    if (roleName && !personName)
      return I18n.t('tensions.react.sensed_by_role', {role: roleName})

    if (personName)
      return I18n.t('tensions.react.sensed_by_role', {role: personName})

    return null
  }

  const body = (() => {
    if (tensionBody && agendaLabel)
      return I18n.t('tensions.react.body_with_agenda', {body: tensionBody, agenda_label: agendaLabel})

    if (tensionBody)
      return tensionBody

    if (agendaLabel)
      return agendaLabel

    return I18n.t('tactical_meetings.shared.no_tension_recorded')
  })()

  const gridClassName = classnames(styles.grid, {
    [styles.gridMobile]: containerQuery.narrowScreen,
    [styles.gridFull]: !containerQuery.narrowScreen,
  })

  return (
    <TensionBoxContainer
      marginBottom={marginBottom}
      test-id="tension-box"
    >
      <div ref={containerQueryRef} className={gridClassName}>
        <BoltSolid className={styles.boltContainer} />
        <WYSIWYGViewerWithFeatureFlag
          value={body}
          organization={organization}
          fallbackProps={{children: body, className: styles.body}}
          fallbackComponent="div"
        />
        {openForm && (
          <div className={styles.editButton}>
            <button
              test-id="show-edit-tension-box"
              className={styles.button}
              title={I18n.t('shared.edit')}
              type="button"
              onClick={openForm}
            >
              <Pencil />
            </button>
          </div>
        )}
        <div className={styles.details}>
          <ItemDetailRow
            items={[
              <Age createdAt={tensionCreatedAt} />,
              roleLabel(),
            ]}
          />
        </div>
      </div>
    </TensionBoxContainer>
  )
}

TensionInfo.defaultProps = {
  openForm: null,
}

export default TensionInfo
