// @flow
import {useState, useEffect} from 'react'
import {fetchQuery, graphql} from 'react-relay'
import {getRequest, createOperationDescriptor, type FetchQueryFetchPolicy} from 'relay-runtime'
import environment from 'environment'

import type {
  useRolesForAutocompleteQuery as Query,
  useRolesForAutocompleteQueryResponse as Response,
  useRolesForAutocompleteQueryVariables as Variables,
} from './__generated__/useRolesForAutocompleteQuery.graphql'

const query = graphql`
  query useRolesForAutocompleteQuery($orgDatabaseId: String!, $count: Int!, $circleDatabaseId: String) {
    organization(databaseId: $orgDatabaseId) {
      autocompleteRoles(first: $count, circleDatabaseId: $circleDatabaseId) {
        name: localizedNameWithContext
        link: resourcePath
        linkText: legacyLinkText
        localizedName
      }
    }
  }
`

export type AutocompleteItem = $ReadOnly<{
  name: string,
  link: string,
  linkText: string,
  localizedName: string,
}>

function runQuery(
  variables: Variables,
  setState: $ReadOnlyArray<AutocompleteItem> => void,
  fetchPolicy: FetchQueryFetchPolicy,
  onNext: ?() => void,
) {
  const subscription = fetchQuery<Query>(
    environment,
    query,
    variables,
    {fetchPolicy},
  ).subscribe({
    next: (data: Response) => {
      setState(data.organization?.autocompleteRoles || [])

      if (onNext)
        onNext()
    },
  })

  const queryRequest = getRequest(query)
  const queryDescriptor = createOperationDescriptor(
    queryRequest,
    variables,
  )

  environment.retain(queryDescriptor)

  return subscription
}

function useRolesForAutocomplete(orgDatabaseId: string, circleDatabaseId: string | null = null):
  $ReadOnlyArray<AutocompleteItem> {
  const [state, setState] = useState<$ReadOnlyArray<AutocompleteItem>>([])

  useEffect(() => {
    let slowRequestSubscription = null

    const fastRequestSubscription = runQuery(
      {
        orgDatabaseId,
        circleDatabaseId,
        count: 10,
      },
      setState,
      'store-or-network',
      () => {
        slowRequestSubscription = runQuery(
          {
            orgDatabaseId,
            circleDatabaseId,
            count: 10000,
          },
          setState,
          'network-only',
        )
      },
    )

    return () => {
      fastRequestSubscription.unsubscribe()

      if (slowRequestSubscription)
        slowRequestSubscription.unsubscribe()
    }
  }, [setState, orgDatabaseId, circleDatabaseId])

  return state
}

export default useRolesForAutocomplete
