// @flow
import type {Node} from 'react'
import React from 'react'
import {DropdownItem} from 'reactstrap'
import generateGoogleLink from 'utils/calendarHelpers/generateGoogleLink'
import {type Event} from 'utils/calendarHelpers/types'

type Props = $ReadOnly<{
  event: Event,
  label: string,
}>

function GoogleEventButton({event, label}: Props): Node {
  const link = generateGoogleLink(event)

  return (
    <DropdownItem
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </DropdownItem>
  )
}

export default GoogleEventButton
