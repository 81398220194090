// @flow
import type {Element} from 'react'
import React, {useState} from 'react'
import ReactSelect from 'react-select'
import {parse} from 'date-fns'

import DatePicker from 'components/ui/DatePicker'

type Props = {|
  dateRangeOptions: $ReadOnlyArray<Object>,
  initialDateRange: Object,
  initialStart: ?string,
  initialEnd: ?string,
|}

function GoalsDateRange(props: Props): Element<"div"> {
  const DATE_FORMAT = 'yyyy-MM-dd'
  const initialStartDate = props.initialStart ? parse(props.initialStart, DATE_FORMAT, new Date()) : null
  const initialEndDate = props.initialEnd ? parse(props.initialEnd, DATE_FORMAT, new Date()) : null
  const [dateRange, setDateRange] = useState(props.initialDateRange)
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)

  return (
    <div className="goal_duration">
      <div className="field">
        <label htmlFor="goal_date_range_id">{I18n.t('goals.time_period_label')}</label>
        <ReactSelect
          allowBlank={false}
          id="date_range_id"
          isSearchable
          name="goal[date_range_id]"
          options={props.dateRangeOptions}
          onChange={setDateRange}
          value={dateRange}
        />
      </div>
      {!dateRange.value && (
        <div className="field custom-date-range">
          <div className="date-range">
            <input
              name="goal[start_date]"
              type="hidden"
              value={startDate}
            />
            <input
              name="goal[end_date]"
              type="hidden"
              value={endDate}
            />
            <DatePicker
              id="goal_start_date"
              onChange={setStartDate}
              selected={startDate}
            />
            <label htmlFor="goal_end_date">{I18n.t('project_search.to')}</label>
            <DatePicker
              id="goal_end_date"
              minDate={startDate}
              onChange={setEndDate}
              selected={endDate}
            />
          </div>
        </div>
      )}
    </div>
  )
}

GoalsDateRange.defaultProps = {
  initialStart: null,
  initialEnd: null,
}

export default GoalsDateRange
