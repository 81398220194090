// @flow

import React, {type Node, useMemo} from 'react'
import AiChatContext from './AiChatContext'

type Props = {
  chatId: string | null,
  messages: Array<any>,
  setMessages: (any) => void,
  children: Node
};

export default function AiChatContextProvider({
  chatId,
  messages,
  setMessages,
  children,
}: Props): Node {
  const value = useMemo(
    () => ({chatId, messages, setMessages}),
    [chatId, setMessages, messages],
  )
  return (
    <AiChatContext.Provider value={value}>{children}</AiChatContext.Provider>
  )
}
