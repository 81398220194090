// @flow
import type {Node} from 'react'
import React, {useMemo, useState, useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'

import {Card} from 'components/ui/Accordion'
import SetOrganizationRuleMutation from 'mutations/SetOrganizationRuleMutation'
import SectionHeader from './SectionHeader'
import SectionBody from './SectionBody'
import type {
  Section_rule$key as Rule,
  RuleValue,
} from './__generated__/Section_rule.graphql'
import type {
  Section_organization$key as Organization,
} from './__generated__/Section_organization.graphql'

type Props = {
  organization: Organization,
  readonly: boolean,
  rule: Rule,
  children: Node,
  onSave?: (currentOption: RuleValue, nextOption: RuleValue) => boolean,
}

const ruleFragment = graphql`
  fragment Section_rule on Rule {
    id
    title
    frogTip
    ruleKey
    ruleValue
    centralizedText
    universalText
    viewerCanUpdate
  }
`

const organizationFragment = graphql`
  fragment Section_organization on Organization {
    id
    constitutionRatifiers

    ...SectionBody_organization
    ...SectionHeader_organization
  }
`

function Section({organization: organizationKey, rule: ruleKey, readonly, onSave, children}: Props): Node {
  const rule = useFragment(ruleFragment, ruleKey)
  const organization = useFragment(organizationFragment, organizationKey)

  const [ruleTypeSelection, setRuleTypeSelection] = useState(rule.ruleValue)
  const [centralizedRuleText, setCentralizedRuleText] = useState(rule.centralizedText)
  const [universalRuleText, setUniversalRuleText] = useState(rule.universalText)
  const [constitutionRatifiers, setConstitutionRatifiers] = useState(organization.constitutionRatifiers)
  const disabled = readonly || !rule.viewerCanUpdate

  const customTextAccessors = useMemo(() => ({
    CENTRALIZED: {
      getter: centralizedRuleText,
      setter: setCentralizedRuleText,
    },
    UNIVERSAL: {
      getter: universalRuleText,
      setter: setUniversalRuleText,
    },
  }), [centralizedRuleText, setCentralizedRuleText, universalRuleText, setUniversalRuleText])

  const onClickSave = useCallback(() => {
    const ruleInput = {
      organizationId: organization.id,
      rule: {
        ruleKey: rule.ruleKey,
        ruleValue: ruleTypeSelection,
        // $FlowIgnore[prop-missing] If the ruleKey does not have custom text, null is expected
        customText: customTextAccessors[ruleTypeSelection]?.getter,
      },
      constitutionRatifiers,
    }

    if (onSave && !onSave(rule.ruleValue, ruleTypeSelection))
      return Promise.reject()

    return SetOrganizationRuleMutation(rule.id, ruleInput)
  }, [onSave, rule, ruleTypeSelection, customTextAccessors, constitutionRatifiers, organization])

  const options = React.Children.map(children, (child) => child?.props)
  const ruleOption = options.find(({value: val}) => val === rule.ruleValue)

  const description = ruleOption?.description
                      // $FlowIgnore[invalid-computed-prop] If the ruleKey does not have custom text, null is expected
                      || customTextAccessors[ruleOption?.value]?.getter
                      || ruleOption?.defaultText

  return (
    <Card
      test-id={rule.title}
      index={rule.title}
      disabled={disabled}
    >
      <SectionHeader
        organization={organization}
        title={rule.title}
        frogTip={rule.frogTip}
        ruleOption={ruleOption}
        description={description}
        disabled={disabled}
      />
      <SectionBody
        organization={organization}
        ruleTypeSelection={ruleTypeSelection}
        name={rule.title}
        setRuleTypeSelection={setRuleTypeSelection}
        customTextAccessors={customTextAccessors}
        constitutionRatifiers={constitutionRatifiers}
        setConstitutionRatifiers={setConstitutionRatifiers}
        disabled={disabled}
        onClickSave={onClickSave}
        options={options}
      />
    </Card>
  )
}

export default Section
