require('legacy/GlassFrog/backbonePatch')

window.GlassFrogApp = require('legacy/GlassFrog/GlassFrogApp').default;
window.GlassFrogCommunication = require('legacy/GlassFrog/GlassFrogCommunication').default;
window.ApiAdapter = require('legacy/GlassFrog/ApiAdapter').default;
window.GlassFrogStore = require('legacy/GlassFrog/GlassFrogStore').default;
window.GlassFrogCollection = require('legacy/GlassFrog/GlassFrogCollection').default;
window.Dispatcher = require('legacy/GlassFrog/Dispatcher').default;
window.ApiFetcher = require('legacy/GlassFrog/ApiFetcher').default;

window.Instruction = require('legacy/GlassFrog/misc/Instruction').default;
window.Path = require('legacy/GlassFrog/misc/Path').default;
window.RoleAndFillerOptions = require('legacy/GlassFrog/misc/RoleAndFillerOptions').default;

window.Accountability = require('legacy/GlassFrog/models/Accountability').default;
window.Action = require('legacy/GlassFrog/models/Action').default;
window.AgendaItem = require('legacy/GlassFrog/models/AgendaItem').default;
window.AgendaItemCollection = require('legacy/GlassFrog/models/AgendaItemCollection').default;
window.AgendaItemList = window.AgendaItemCollection
window.Announcement = require('legacy/GlassFrog/models/Announcement').default;
window.ChecklistItem = require('legacy/GlassFrog/models/ChecklistItem').default;
window.Circle = require('legacy/GlassFrog/models/Circle').default;
window.Domain = require('legacy/GlassFrog/models/Domain').default;
window.GovernanceMeeting = require('legacy/GlassFrog/models/GovernanceMeeting').default;
window.Integration = require('legacy/GlassFrog/models/Integration').default;
window.MeetingHost = require('legacy/GlassFrog/models/MeetingHost').default;
window.Metric = require('legacy/GlassFrog/models/Metric').default;
window.Organization = require('legacy/GlassFrog/models/Organization').default;
window.Person = require('legacy/GlassFrog/models/Person').default;
window.Policy = require('legacy/GlassFrog/models/Policy').default;
window.Project = require('legacy/GlassFrog/models/Project').default;
window.Proposal = require('legacy/GlassFrog/models/Proposal').default;
window.ProposalResponse = require('legacy/GlassFrog/models/ProposalResponse').default;
window.Role = require('legacy/GlassFrog/models/Role').default;
window.TacticalMeeting = require('legacy/GlassFrog/models/TacticalMeeting').default;
window.Tension = require('legacy/GlassFrog/models/Tension').default;
window.Vote = require('legacy/GlassFrog/models/Vote').default;

window.ActionHandlerUtils = require('legacy/GlassFrog/utills/ActionHandlerUtils').default;
window.AgendaHelper = require('legacy/GlassFrog/utills/AgendaHelper').default;
