// @flow
import type {Node} from 'react'
import React from 'react'
import {useLazyLoadQuery, graphql} from 'react-relay'

import UpdateMetricMutation, {type Response} from 'mutations/Metrics/UpdateMetricMutation'
import DeleteMetricMutation from 'mutations/Metrics/DeleteMetricMutation'
import ViewerMetricFormModal from 'components/metrics/ViewerMetricFormModal'

import type {
  ViewerMetricEditModalQuery as Query,
} from './__generated__/ViewerMetricEditModalQuery.graphql'

type Props = {
  metricId: string,
  close: () => void,
  onSubmit: Response => void,
  orgDatabaseId: string,
}

const editModalQuery = graphql`
  query ViewerMetricEditModalQuery(
    $orgDatabaseId: String!,
    $metricId: ID!
  ) {
    organization(databaseId: $orgDatabaseId) {
      id
      onRestrictedPlan
      ...FrequencySelector_organization

      viewer {
        ...ViewerMetricFormModal_viewer
      }
    }

    metric: node(id: $metricId) {
      ... on Metric {
        description
        frequency
        link
        privateToCircle

        circle {
          id
        }

        role {
          id
        }

        customFrequency {
          id
          name
          universalUnits
        }
      }
    }
  }
`

function ViewerMetricEditModal({
  metricId,
  close,
  onSubmit,
  orgDatabaseId,
}: Props): null | Node {
  const {organization, metric} = useLazyLoadQuery<Query>(editModalQuery, {
    orgDatabaseId,
    metricId,
  })

  if (!organization?.viewer || !metric)
    return null

  const initialValues = {
    description: metric.description || '',
    circleId: metric.circle?.id,
    roleId: metric.role?.id,
    frequency: metric.frequency,
    customFrequency: metric.customFrequency,
    link: metric.link,
    privateToCircle: metric.privateToCircle,
  }

  const submit = async (values) => {
    const response = await UpdateMetricMutation({
      ...values,
      metricId,
    })

    onSubmit(response)

    return response
  }

  const deleteMetric = async () => {
    /* eslint-disable-next-line no-alert */
    if (!window.confirm(I18n.t('metrics.delete_metric_confirmation')))
      return

    await DeleteMetricMutation({metricId}, organization.id)
    close()
  }

  return (
    <ViewerMetricFormModal
      disabledCircle
      initialValues={initialValues}
      submit={submit}
      modalTitle={I18n.t('metrics.edit_metric')}
      organization={organization}
      viewer={organization.viewer}
      close={close}
      renderLeftFooterButton={(isSubmitting) => (
        <button
          className="btn btn-secondary btn-lg"
          disabled={isSubmitting}
          onClick={deleteMetric}
          type="button"
        >
          {I18n.t('react.components.edit_dialog.delete')}
        </button>
      )}
    />
  )
}

ViewerMetricEditModal.defaultProps = {
  onSubmit: () => {},
}

export default ViewerMetricEditModal
