/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PersonChecklistItem_item$ref = any;
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistsItemsViewerSection_checklistItems$ref: FragmentReference;
declare export opaque type ChecklistsItemsViewerSection_checklistItems$fragmentType: ChecklistsItemsViewerSection_checklistItems$ref;
export type ChecklistsItemsViewerSection_checklistItems = $ReadOnlyArray<{|
  +frequency: ?Frequency,
  +localizedFrequency: string,
  +id: string,
  +appliesToAllMembers: boolean,
  +databaseId: string,
  +description: string,
  +link: ?any,
  +localizedRoleName: string,
  +isGlobal: boolean,
  +privateToCircle: boolean,
  +position: number,
  +organization: {|
    +id: string,
    +onRestrictedPlan: boolean,
  |},
  +customFrequency: ?{|
    +id: string,
    +name: string,
    +universalUnits: number,
  |},
  +$fragmentRefs: PersonChecklistItem_item$ref,
  +$refType: ChecklistsItemsViewerSection_checklistItems$ref,
|}>;
export type ChecklistsItemsViewerSection_checklistItems$data = ChecklistsItemsViewerSection_checklistItems;
export type ChecklistsItemsViewerSection_checklistItems$key = $ReadOnlyArray<{
  +$data?: ChecklistsItemsViewerSection_checklistItems$data,
  +$fragmentRefs: ChecklistsItemsViewerSection_checklistItems$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ChecklistsItemsViewerSection_checklistItems",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFrequency",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliesToAllMembers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedRoleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onRestrictedPlan",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFrequency",
      "kind": "LinkedField",
      "name": "customFrequency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "universalUnits",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonChecklistItem_item"
    }
  ],
  "type": "ChecklistItem",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '64f33ba4c091425d03bd6e11bab83a0c';

module.exports = node;
