// @flow
import type {ComponentType, Node} from 'react'
import React, {Component} from 'react'
import {__RouterContext as RouterContext} from 'react-router'
import AppRouter from 'utils/AppRouter'
import type {ReactRouterContext} from 'utils/AppRouter'
import withMaybeRouter from '../withMaybeRouter'

type Props = {
  children: Node,
  context: ?ReactRouterContext,
  fallback: () => Node,
}

type State = {
  context: ?ReactRouterContext,
}

class AppRouterContextProvider extends Component<Props, State> {
  state = {
    context: null,
  }

  componentDidMount() {
    AppRouter.instance.subscribe(this.onContextSetup)
  }

  componentWillUnmount() {
    AppRouter.instance.unsubscribe(this.onContextSetup)
  }

  onContextSetup = (context: ?ReactRouterContext) => {
    this.setState({context})
  }

  /* eslint-disable react/require-render-return */
  render() {
    if (this.props.context)
      return this.props.children

    if (this.state.context) {
      return (
        <RouterContext.Provider value={this.state.context}>
          {this.props.children}
        </RouterContext.Provider>
      )
    }

    return this.props.fallback()
  }
}

export default (withMaybeRouter(AppRouterContextProvider): ComponentType<
      {+children: Node, +context?: ?ReactRouterContext, +fallback: () => Node}
  >
)
