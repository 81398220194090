// @flow
import type {Element} from 'react'
import React, {useCallback, useEffect, useRef} from 'react'
import ReactSelect from 'react-select'

export type Props = $ReadOnly<{
  defaultValue?: Object,
  id: string,
  isMulti?: boolean,
  isSearchable?: boolean,
  name: string,
  options: Array<any>,
  wrapperClass?: string,
}>

function RailsSelect({defaultValue, id, isMulti, isSearchable, name, options, wrapperClass}: Props): Element<"div"> {
  const wrapperRef = useRef(null)
  const selectRef = useRef(null)

  const handleChange = useCallback(() => {
    if (wrapperRef.current)
      wrapperRef.current.dispatchEvent(new Event('change'))
  }, [wrapperRef])

  useEffect(() => {
    if (selectRef.current) {
      const availableIds = options.map((option) => option.value)
      const selectedIds = selectRef.current.getValue().map((option) => option.value)

      if (options.length === 1)
        selectRef.current.setValue(options)
      else if (selectedIds.filter((optionId) => availableIds.includes(optionId)).length === 0)
        selectRef.current.setValue(null)
    }
  }, [selectRef, options])

  return (
    <div ref={wrapperRef} className={wrapperClass}>
      <ReactSelect
        defaultValue={defaultValue}
        id={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        onBlur={handleChange}
        options={options}
        // $FlowFixMe[incompatible-type]
        ref={selectRef}
      />
    </div>
  )
}

export default RailsSelect
