/**
 * @flow
 * @relayHash c431b8bcb94a4cb76077f6d7908f5cfc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChecklistFormModal_circle$ref = any;
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type OwnerType = "CIRCLE_MEMBERS" | "FACILITATOR" | "LEAD_LINK" | "REP_LINK" | "ROLE_FILLERS" | "SECRETARY";
export type ChecklistItemEditModalQueryVariables = {|
  orgDatabaseId: string,
  circleDatabaseId: string,
  checklistItemId: string,
|};
export type ChecklistItemEditModalQueryResponse = {|
  +organization: ?{|
    +circle: ?{|
      +$fragmentRefs: ChecklistFormModal_circle$ref
    |}
  |},
  +checklistItem: ?{|
    +description?: string,
    +frequency?: ?Frequency,
    +link?: ?any,
    +appliesTo?: OwnerType,
    +privateToCircle?: boolean,
    +role?: ?{|
      +id: string
    |},
    +customFrequency?: ?{|
      +id: string,
      +name: string,
      +universalUnits: number,
    |},
  |},
|};
export type ChecklistItemEditModalQuery = {|
  variables: ChecklistItemEditModalQueryVariables,
  response: ChecklistItemEditModalQueryResponse,
|};
*/


/*
query ChecklistItemEditModalQuery(
  $orgDatabaseId: String!
  $circleDatabaseId: String!
  $checklistItemId: ID!
) {
  organization(databaseId: $orgDatabaseId) {
    circle(databaseId: $circleDatabaseId) {
      ...ChecklistFormModal_circle
      id
    }
    id
  }
  checklistItem: node(id: $checklistItemId) {
    __typename
    ... on ChecklistItem {
      description
      frequency
      link
      appliesTo
      privateToCircle
      role {
        id
      }
      customFrequency {
        id
        name
        universalUnits
      }
    }
    id
  }
}

fragment ChecklistFormModal_circle on Circle {
  id
  supportedRole {
    id
    isExpanded
    localizedName
    parentRole {
      __typename
      id
    }
    parentCircle: circle {
      id
    }
  }
  roles(includeHiddenRoles: true) {
    edges {
      node {
        id
        localizedName
        parentCircle: circle {
          id
        }
      }
    }
  }
  organization {
    id
    onRestrictedPlan
    ...FrequencySelector_organization
  }
}

fragment FrequencySelector_organization on Organization {
  customFrequencies(first: 100) {
    edges {
      node {
        id
        name
        universalUnits
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checklistItemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "circleDatabaseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgDatabaseId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "circleDatabaseId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "checklistItemId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universalUnits",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliesTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFrequency",
      "kind": "LinkedField",
      "name": "customFrequency",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ChecklistItem",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": "parentCircle",
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChecklistItemEditModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChecklistFormModal_circle"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "checklistItem",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChecklistItemEditModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "supportedRole",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExpanded",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "parentRole",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeHiddenRoles",
                    "value": true
                  }
                ],
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v11/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(includeHiddenRoles:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onRestrictedPlan",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "concreteType": "CustomFrequencyConnection",
                    "kind": "LinkedField",
                    "name": "customFrequencies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomFrequencyEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomFrequency",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "customFrequencies(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "FrequencySelector_customFrequencies",
                    "kind": "LinkedHandle",
                    "name": "customFrequencies"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "checklistItem",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v6/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c431b8bcb94a4cb76077f6d7908f5cfc",
    "metadata": {},
    "name": "ChecklistItemEditModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '519fc30cbcac19efb3ba9997538f2332';

module.exports = node;
