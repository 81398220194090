import _ from 'underscore'
import util from 'utils/MiscUtils'

const AgendaHelper = {
  currentAgendaItemId(store, meetingType, meetingId) {
    try {
      const item = AgendaHelper.selectedOrDefaultAgendaItemForMeeting(store, meetingType, meetingId)
      return item ? item.id : null
    } catch (e) {
      return null
    }
  },

  selectedOrDefaultAgendaItemForMeeting(store, meetingType, meetingId) {
    const meeting = store.getData(
      meetingType || util.error('meetingType required'),
      meetingId || util.error('meetingId required'),
    )
    const currentAgendaItemId = meeting.links.current_agenda_item
    // ^^ should've been to able to do getData(meetingType, meetingId, 'current_agenda_item','*')
    // but it wasn't working for me for some reason & cut scope on debugging it.  LWH
    const currentAgendaItem = currentAgendaItemId
      ? store.getData('agenda_items', currentAgendaItemId)
      : null

    // wtf does it return an empty array?
    if (currentAgendaItem)
      return currentAgendaItem

    const group = store.getData(meetingType, meetingId, 'agenda_items', '*', {reverseLookup: true})
    return AgendaHelper.getNextItemInGroup(group)
  },

  /*
   Do a single pass over over group list
   Task 1: First find any .pending items before the item currently selected
   Task 2: Identify current selected radio
   Task 3: Try to find any .pending after current
   With this approach,
   if the third task is still null,
   use the result of task 1 if it is set
   Otherwise, nothing should be pending and we set it to null
   */
  getNextItemInGroup(group) {
    _.each(group, (item) => {
      const pending = !item.completed

      if (pending)
        return item
    })

    return null
  },

}

export default AgendaHelper
