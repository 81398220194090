// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import AddProjectAction from 'mutations/Actions/AddProjectAction'
import UpdateActionDescriptionMutation from 'mutations/Actions/UpdateActionDescriptionMutation'
import DeleteActionMutation from 'mutations/Actions/DeleteActionMutation'
import Body from './Body'
import type {
  Item_action as Action,
} from './__generated__/Item_action.graphql'
import type {
  Item_project as Project,
} from './__generated__/Item_project.graphql'

type Props = $ReadOnly<{
  action: ?Action,
  project: Project,
  readonly: boolean,
  isEditing: boolean,
  startEditing: () => void,
  finishEditing: () => void,
  onTabPress: (string, 'BACK' | 'FORWARD') => void,
}>

function Item({
  action,
  project,
  readonly,
  isEditing,
  finishEditing,
  startEditing,
  onTabPress,
}: Props): Node {
  const create = (description: string) => {
    const circleId = project.circle.id
    const personId = project.person?.id
    const projectId = project.id

    if (!circleId || !projectId)
      return

    AddProjectAction({
      circleId,
      personId,
      projectId,
      description,
    })
  }
  const update = (existedAction: Action, description: string) => {
    const circleId = existedAction.circle?.id
    const personId = existedAction.person?.id
    const projectId = project.id

    if (readonly || !circleId || !personId || !projectId)
      return

    UpdateActionDescriptionMutation({
      actionId: existedAction.id,
      privateToCircle: existedAction.privateToCircle,
      trigger: existedAction.trigger,
      circleId,
      personId,
      projectId,
      description,
    })
  }

  const save = (description: string) => {
    if (!action)
      create(description)
    else
      update(action, description)
  }

  const deleteAction = () => {
    if (readonly || !action)
      return

    DeleteActionMutation(action.id)
  }

  return (
    <Body
      isEditing={isEditing}
      startEditing={startEditing}
      finishEditing={finishEditing}
      action={action}
      readonly={readonly}
      onDescriptionChange={save}
      onDelete={deleteAction}
      onTabPress={onTabPress}
    />
  )
}

Item.defaultProps = {
  onTabPress: () => {},
  readonly: false,
}

export default (createFragmentContainer(Item, {
  action: graphql`
    fragment Item_action on Action {
      id
      privateToCircle
      trigger
      
      circle {
        id
      }
      
      person {
        id
      }
      
      ...Body_action
    }
  `,
  project: graphql`
    fragment Item_project on Project {
      id
      
      circle {
        id
      }
      
      person {
        id
      }
    }
  `,
}): RelayFragmentContainer<typeof(Item)>)
