import React from 'react'
import $ from 'jquery'
import createReactClass from 'create-react-class'

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-literals */

const ErrorWarningComponent = createReactClass({
  displayName: 'ErrorWarningComponent',

  dismissWarning(e) {
    e.preventDefault()
    $('#js-error-500').hide()
  },

  render() {
    // js-error-500 is shown if any AJAX response has a 5** status (ie. a server error).
    return (
      <div>
        <div
          id="js-error-500"
          className="alert alert-danger"
          style={{display: 'none', position: 'fixed', top: '20px', left: '20px', right: '20px', zIndex: 101}}
        >
          {I18n.t('error.general_error')}
          &nbsp;
          <a href="#" onClick={this.dismissWarning} className="alert-link">&times;</a>
        </div>
      </div>
    )
  },
})

export default ErrorWarningComponent
