// @flow strict-local
import {useEffect, useCallback} from 'react'

type Unsubscribe = () => void
type Subscription<Variables, Response> = (Variables, Response => void) => Unsubscribe

export default function useSubscriptionRefetch<SVariables, SResponse, RVariables: {...}>(
  subscription: Subscription<SVariables, SResponse>,
  subscriptionVariables: SVariables,
  refetch: RVariables => mixed,
  refetchVariables: RVariables,
  shouldRefetch: true | (SResponse) => boolean = true,
) {
  const callback = useCallback((data) => {
    if (shouldRefetch === true || shouldRefetch(data))
      refetch(refetchVariables)
  }, [refetch, refetchVariables, shouldRefetch])

  useEffect(() => {
    const unsubscribe = subscription(subscriptionVariables, callback)

    return unsubscribe
  }, [subscription, subscriptionVariables, callback])
}
