/**
 * @flow
 * @relayHash f7542aaf818eb012e14cfdcb0b397b4e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReorderChecklistItemInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  checklistItemId: string,
  newPosition: number,
|};
export type MutationContext = {|
  organizationId: string
|};
export type ReorderChecklistItemMutationVariables = {|
  input: ReorderChecklistItemInput
|};
export type ReorderChecklistItemMutationResponse = {|
  +reorderChecklistItem: ?{|
    +reorderedList: ?$ReadOnlyArray<{|
      +__typename: string,
      +id: string,
      +position: number,
    |}>
  |}
|};
export type ReorderChecklistItemMutation = {|
  variables: ReorderChecklistItemMutationVariables,
  response: ReorderChecklistItemMutationResponse,
|};
*/


/*
mutation ReorderChecklistItemMutation(
  $input: ReorderChecklistItemInput!
) {
  reorderChecklistItem(input: $input) {
    reorderedList {
      __typename
      id
      position
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderChecklistItemPayload",
    "kind": "LinkedField",
    "name": "reorderChecklistItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChecklistItem",
        "kind": "LinkedField",
        "name": "reorderedList",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "position",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReorderChecklistItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReorderChecklistItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f7542aaf818eb012e14cfdcb0b397b4e",
    "metadata": {},
    "name": "ReorderChecklistItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5af8ad895b15a8ae7bf089060522093b';

module.exports = node;
