// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import MeetingBanner from '../MeetingBanner'
import type {
  GovernanceMeetingBanner_circle as Circle,
} from './__generated__/GovernanceMeetingBanner_circle.graphql'

type Props = {|
  circle: Circle,
  pathname: string,
|}

function GovernanceMeetingBanner({circle, pathname}: Props): Node {
  const meeting = circle.activeGovernanceMeeting

  const bannerCaption = I18n.t(
    'announcements.react.governance_meeting_started.content',
    {circle_name: circle.localizedName},
  )

  const meetingStartedCaption = I18n.t('announcements.react.governance_meeting_started.action')
  const className = 'banner__governance-meeting'

  const isOnMeetingPage = useCallback((meetingPath: string) => (
    pathname === meetingPath
  ), [pathname])

  if (meeting) {
    return (
      <MeetingBanner
        className={className}
        meetingStartedCaption={meetingStartedCaption}
        bannerCaption={bannerCaption}
        circle={circle}
        meeting={meeting}
        isOnMeetingPage={isOnMeetingPage}
      />
    )
  }

  return null
}

export default (createFragmentContainer(GovernanceMeetingBanner, {
  circle: graphql`
    fragment GovernanceMeetingBanner_circle on Circle {
      localizedName

      ...MeetingBanner_circle

      activeGovernanceMeeting {
        ...MeetingBanner_meeting
      }
    }
  `,
}): RelayFragmentContainer<typeof(GovernanceMeetingBanner)>)
