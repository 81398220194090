/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EditRoleTemplateModalView_roleTemplate$ref: FragmentReference;
declare export opaque type EditRoleTemplateModalView_roleTemplate$fragmentType: EditRoleTemplateModalView_roleTemplate$ref;
export type EditRoleTemplateModalView_roleTemplate = {|
  +id: string,
  +name: string,
  +purpose: ?string,
  +domains: ?$ReadOnlyArray<string>,
  +accountabilities: ?$ReadOnlyArray<string>,
  +$refType: EditRoleTemplateModalView_roleTemplate$ref,
|};
export type EditRoleTemplateModalView_roleTemplate$data = EditRoleTemplateModalView_roleTemplate;
export type EditRoleTemplateModalView_roleTemplate$key = {
  +$data?: EditRoleTemplateModalView_roleTemplate$data,
  +$fragmentRefs: EditRoleTemplateModalView_roleTemplate$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRoleTemplateModalView_roleTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purpose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountabilities",
      "storageKey": null
    }
  ],
  "type": "RoleTemplate",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ff005b612636eca8103c58989a290695';

module.exports = node;
