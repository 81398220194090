/**
 * @flow
 * @relayHash c96fcaab0acca574967397d81ea05db7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Project_project$ref = any;
export type AlertSeverity = "ERROR" | "INFO" | "SUCCESS" | "WARNING";
export type ProjectVisibility = "ALL" | "PRIVATE_TO_CIRCLE" | "PRIVATE_TO_PERSON";
export type RoleOwner = "INDIVIDUAL_INITIATIVE" | "ROLE";
export type UpdateProjectInput = {|
  context: MutationContext,
  clientMutationId?: ?string,
  accountabilityId?: ?string,
  description?: ?string,
  personId?: ?string,
  projectId: string,
  roleId: string,
  roleType: RoleOwner,
  parentGoalOrTarget?: ?string,
  link?: ?any,
  visibility?: ?ProjectVisibility,
  value?: ?number,
  effort?: ?number,
  status?: ?string,
  note?: ?string,
  waitingOnWho?: ?string,
  waitingOnWhat?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateProjectMutationVariables = {|
  input: UpdateProjectInput
|};
export type UpdateProjectMutationResponse = {|
  +updateProject: ?{|
    +project: ?{|
      +$fragmentRefs: Project_project$ref
    |},
    +errors: $ReadOnlyArray<{|
      +path: ?string,
      +message: string,
      +severity: AlertSeverity,
    |}>,
  |}
|};
export type UpdateProjectMutation = {|
  variables: UpdateProjectMutationVariables,
  response: UpdateProjectMutationResponse,
|};
*/


/*
mutation UpdateProjectMutation(
  $input: UpdateProjectInput!
) {
  updateProject(input: $input) {
    project {
      ...Project_project
      id
    }
    errors {
      path
      message
      severity
    }
  }
}

fragment NoteTooltip_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment ProjectDescription_project on Project {
  description(format: MARKDOWN)
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment ProjectDetails_project on Project {
  databaseId
  createdAt
  privateToCircle
  privateToPerson
  link
  note
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  organization {
    isROIEnabled
    ...NoteTooltip_organization
    id
  }
  person {
    name
    id
  }
  circle {
    localizedName
    id
  }
  role {
    localizedName
    id
  }
}

fragment Project_project on Project {
  id
  status
  viewerCanUpdate
  visibility
  privateToPerson
  role {
    isLinked
    id
  }
  circle {
    databaseId
    id
  }
  ...ProjectDetails_project
  ...ProjectDescription_project
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Project_project"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToPerson",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLinked",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roi",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentGoalOrTarget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentGoalOrTargetDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isROIEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isWysiwygEnabled",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MARKDOWN"
                  }
                ],
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"MARKDOWN\")"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c96fcaab0acca574967397d81ea05db7",
    "metadata": {},
    "name": "UpdateProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '491d9cf1d3174e537615737bc066d614';

module.exports = node;
