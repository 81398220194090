// @flow
import type {Node} from 'react'
import React from 'react'
import {QueryRenderer, graphql} from 'react-relay'

import environment from 'environment'
import withErrorCheck from 'decorators/withErrorCheck'
import ConfigureJiraSectionInner from './ConfigureJiraSectionInner'
import type {GQLResponse} from './types'
import styles from './index.scss'

const query = graphql`
  query ConfigureJiraSectionQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...ConfigureJiraSectionInner_organization
    }
  }
`

type Props = {
  logoPath: string,
  orgDatabaseId: string,
}

function Loading() {
  return <div className={styles.loading} />
}

function ConfigureJiraSection({logoPath, orgDatabaseId}: Props): Node {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{orgDatabaseId}}
      render={withErrorCheck((renderProps: ?GQLResponse) => (
        renderProps && renderProps.organization ? (
          <ConfigureJiraSectionInner
            logoPath={logoPath}
            organization={renderProps.organization}
          />
        ) : <Loading />
      ))}
    />
  )
}

export default ConfigureJiraSection
