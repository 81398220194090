// @flow
import type {Node} from 'react'
import React from 'react'
import {isEmpty, sortWith, ascend, prop, pipe} from 'ramda'

import latinizeString from 'utils/MiscUtils/latinizeString'
import NoteCard from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/NoteCard'
import NoItems from './NoItems'
import type {AbstractMetric} from '../types'

type Props<T> = {
  frequency: string,
  items: T[],
  renderItem: T => Node
}

const sortItems = sortWith([
  ascend(pipe(prop('localizedRoleName'), latinizeString)),
  ascend(pipe(prop('description'), latinizeString)),
])

function FrequencySubsection<T: AbstractMetric>({frequency, items, renderItem}: Props<T>): Node {
  return (
    <NoteCard test-id={`section-${frequency}`}>
      <h3>{frequency}</h3>
      {isEmpty(items)
        ? <NoItems />
        : sortItems(items).map(renderItem)
    }
    </NoteCard>
  )
}

export default FrequencySubsection
