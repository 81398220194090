// @flow
import type {Element} from 'react'
import React from 'react'

import useUniqueId from 'utils/hooks/useUniqueId'
import VisuallyHidden from 'components/ui/VisuallyHidden'
import Close from 'components/ui/icons/md/Close'
import Sync from 'components/ui/icons/md/Sync'
import styles from './index.scss'

type Props = $ReadOnly<{
  value: boolean,
  name: string,
  onChange: (SyntheticEvent<HTMLInputElement>, boolean) => void,
  ...
}>

function RemoveCheckbox({
  onChange,
  name,
  value,
  ...inputProps
}: Props): Element<"label"> {
  const [id] = useUniqueId()
  const inputId = `remove-checkbox-${id}`

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label test-id={`label-for-${name}`} htmlFor={inputId} className={styles.container}>
      <VisuallyHidden>
        <input
          {...inputProps}
          name={name}
          type="checkbox"
          checked={value}
          onChange={onChange}
          id={inputId}
        />
      </VisuallyHidden>
      {value
        ? <Sync className={styles.rollbackIcon} />
        : <Close className={styles.deleteIcon} />
      }
    </label>
  )
}

export default RemoveCheckbox
