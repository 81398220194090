import {equals} from 'ramda'

/*
Ideally preloading caching should look this way:
 - run relay on the server-side
 - run a query we need to cache
 - get store snapshot and put it somewhere in HTML (like window.__PRELOADED_GQL_QUERIES)
 - use a snapshot to fill the store while creating it

But we can't do that (at least with minimal effort) cuz we don't have JS backend lol,
so I've decided to make a kinda proxy (not really a proxy, but still).
After returning cached data this function deleting this data, so any further attempts
to re-run the same query will fire the actual API call.

Also to be sure that we'll get actual data from cache this function check:
 - query ID
 - does it query really Query but not Mutation or Subscription
 - checks variables
*/

export default function preloadedCacheHandler(operation, variables) {
  // eslint-disable-next-line no-underscore-dangle
  const preloadedCacheIndex = window.__PRELOADED_GQL_QUERIES
    .findIndex((cache) => cache.name === operation.name)

  // eslint-disable-next-line no-underscore-dangle
  const preloadedCache = window.__PRELOADED_GQL_QUERIES[preloadedCacheIndex]

  if (!preloadedCache)
    return null

  const isSameQuery = preloadedCache.id === operation.id
  const isSameVariables = equals(variables, preloadedCache.variables)

  if (isSameQuery && isSameVariables) {
    const data = preloadedCache.data
    // eslint-disable-next-line no-underscore-dangle
    window.__PRELOADED_GQL_QUERIES.splice(preloadedCacheIndex, 1)

    return {data}
  }

  return null
}
