// @flow
import type {Node} from 'react'
import React from 'react'
import Security from 'components/ui/icons/fa/Security'

type Props = $ReadOnly<{
  className: string
}>

function ProjectPrivateIcon({className}: Props): Node {
  return <Security className={className} test-id="project-private-icon" />
}

export default ProjectPrivateIcon
