// @flow
import type {Node} from 'react'
import React, {useCallback, useEffect} from 'react'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import type {ActionMeta, ValueType} from 'react-select'

import fakeInputEvent, {type FakeEvent} from 'utils/forms/fakeInputEvent'
import type {Option} from 'components/types'

import PremiumCallout, {type CalloutType} from 'components/ui/PremiumCallout'

import InputContainer from '../InputContainer'
import Label from '../Label'
import styles from './index.scss'

type Props = $ReadOnly<{
  allowMultiple: boolean,
  label: string,
  hideLabel: boolean,
  options: $ReadOnlyArray<Option>,
  value: string | null,
  initialValue: string | null,
  containerClassName?: string,
  onChange: (FakeEvent, string | null) => void,
  onBlur: (FakeEvent) => void | () => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  premiumCalloutType?: CalloutType,
  selectType: string,
  name: string,
  tooltip: ?string,
  'test-id': ?string,
  disabled: boolean,
}>

export type {Option, Props}

function Select({
  allowMultiple,
  hideLabel,
  label,
  tooltip,
  options,
  name,
  value,
  initialValue,
  containerClassName,
  onChange,
  onBlur,
  placeholder,
  premiumCalloutType,
  selectType,
  warning,
  'test-id': testId,
  clearable,
  disabled,
}: Props): Node {
  const isValid = warning === null

  const className = classNames({[styles.warning]: !isValid})

  const handleChange: (ValueType, ActionMeta<*>) => void = useCallback((option: any) => {
    const valueToUse = clearable && initialValue && !option
      ? initialValue
      : option?.value || option?.map((tag) => tag.value) || null

    onChange(fakeInputEvent({value: valueToUse, name}), valueToUse)
  }, [clearable, initialValue, onChange, name])

  useEffect(() => {
    if (initialValue && value === null)
      onChange(fakeInputEvent({value: initialValue, name}), initialValue)
  }, [initialValue, value, onChange, name])

  useEffect(() => {
    if (value && initialValue && !options.map((option) => option.value).includes(value))
      onChange(fakeInputEvent({value: initialValue, name}), initialValue)
  }, [options, value, initialValue, name, onChange])

  const selectedOption = Array.isArray(value)
    ? value.map((tag) => ({label: tag, value: tag}))
    : options.find((option) => option.value === value) || null

  const components = {
    default: ReactSelect,
    creatable: CreatableSelect,
  }

  const ComponentType = components[selectType]

  const renderOptionLabel = (option) => (option.premiumCalloutType ? (
    <span id={option.premiumCalloutType}>
      {option.label}
      <PremiumCallout callout={option.premiumCalloutType} target={option.premiumCalloutType} enabled darkIcon />
    </span>
  ) : option.label)

  const input = (
    <div test-id={testId}>
      <ComponentType
        isDisabled={disabled}
        isClearable={clearable && value !== initialValue}
        isMulti={allowMultiple}
        name={name}
        className={className}
        classNamePrefix="Select"
        onChange={handleChange}
        onBlur={() => onBlur(fakeInputEvent({value, name}))}
        value={selectedOption}
        options={options}
        isOptionDisabled={(option) => option.disabled}
        placeholder={placeholder}
        getOptionLabel={renderOptionLabel}
      />
    </div>
  )

  return (
    <InputContainer className={containerClassName}>
      {hideLabel && input}
      {!hideLabel && (
        <Label
          input={input}
          label={label}
          tooltip={tooltip}
          premiumCalloutType={premiumCalloutType}
        />
      )}
    </InputContainer>
  )
}

Select.defaultProps = {
  allowMultiple: false,
  hideLabel: false,
  warning: null,
  disabled: false,
  clearable: false,
  tooltip: null,
  selectType: 'default',
  'test-id': null,
  initialValue: null,
  onBlur: () => {},
}

export default Select
