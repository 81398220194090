// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'

import AbstractMetricsSection from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import NoItemsMessage from 'components/ui/NoItemsMessage'
import Metric from 'components/metrics/Metric'
import type {Source} from 'components/ui/SourceIcon'
import type {
  MetricsSection_metrics as Metrics,
  MetricsSection_metrics$key as MetricsKey,
} from './__generated__/MetricsSection_metrics.graphql'
import type {
  MetricsSection_circle$key as Circle,
} from './__generated__/MetricsSection_circle.graphql'
import ReorderMetricMutation from '../../../mutations/ReorderMetricMutation'

type MetricType = Metrics[number]

type Props = $ReadOnly<{
  metrics: MetricsKey,
  circle: Circle,
  showNoItemsMessage: boolean,
  source: Source,
}>

const circleFragment = graphql`
  fragment MetricsSection_circle on Circle {
    ...Metric_circle
  }
`

const metricsFragment = graphql`
  fragment MetricsSection_metrics on Metric @relay(plural: true) {
    id
    databaseId
    link
    description
    frequency
    localizedFrequency
    id
    localizedRoleName
    appliesToAllMembers
    isGlobal
    privateToCircle
    position
    viewerCanReorder

    organization {
      id
      onRestrictedPlan
    }
    
    customFrequency {
      id
      name
      universalUnits
    }

    ...Metric_metric
  }
`

function MetricsSection({metrics: metricsKey, circle: circleKey, showNoItemsMessage, source}: Props): Node {
  const metrics = useFragment(metricsFragment, metricsKey)
  const circle = useFragment(circleFragment, circleKey)

  const renderMetric = useCallback((metric: MetricType) => (
    <Metric
      metric={metric}
      circle={circle}
      key={metric.id}
      source={source}
    />
  ), [circle, source])

  if (metrics.length === 0 && showNoItemsMessage) {
    return (
      <NoItemsMessage
        message={I18n.t('metrics.no_metrics')}
      />
    )
  }

  const onReorder = (metricId, newPosition, optimisticList) => (
    ReorderMetricMutation({metricId, newPosition}, optimisticList)
  )

  return (
    <AbstractMetricsSection
      reorderable={source === 'internal'}
      onReorder={onReorder}
      hideIfEmpty
      items={metrics}
      renderItem={renderMetric}
    />
  )
}

MetricsSection.defaultProps = {
  source: 'internal',
}

export default MetricsSection
