// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

/* eslint-disable react/button-has-type */

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xlg'

type Props = {
  className: ?string,
  testId: ?string,
  disabled: ?boolean,
  size: Size,
  faded: boolean,
  highlighted: boolean,
  dark: boolean,
  headerStyle: boolean,
  children: ?Node,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  title: string,
  tabIndex? : string,
  type: ?string,
}

function IconButton({
  className,
  testId,
  disabled,
  size,
  faded,
  highlighted,
  onClick,
  dark,
  headerStyle,
  children,
  title,
  tabIndex,
  type,
}: Props): Element<"button"> {
  const buttonClassName = classNames(
    className,
    'ui-icon-button',
    `ui-icon-button--${size}`,
    {
      'ui-icon-button--faded': faded,
      'ui-icon-button--highlighted': highlighted,
      'ui-icon-button--dark': dark,
      'ui-icon-button--header': headerStyle,
      'ui-icon-button--disabled': disabled,
    },
  )

  return (
    <button
      test-id={testId}
      title={title}
      className={buttonClassName}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
    >
      {children}
    </button>
  )
}

IconButton.defaultProps = {
  className: null,
  testId: null,
  disabled: false,
  size: 'md',
  faded: false,
  highlighted: false,
  dark: false,
  headerStyle: false,
  children: null,
  onClick: () => {},
  tabIndex: '0',
  type: null,
}

export default IconButton
