// @flow
import type {Element, Node} from 'react'
import React from 'react'
import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
}>

function VisuallyHidden({children}: Props): Element<"span"> {
  return (
    <span className={styles.hidden}>
      {children}
    </span>
  )
}

export default VisuallyHidden
