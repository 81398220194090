// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import ProfileChecklistsView from './ProfileChecklistsView'

import type {
  ProfileChecklistsQuery as Query,
} from './__generated__/ProfileChecklistsQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

const profileChecklistsQuery = graphql`
query ProfileChecklistsQuery(
  $orgDatabaseId: String!,
  $personDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      ...ProfileChecklistsView_organization
      person(databaseId: $personDatabaseId) {
        ...ProfileChecklistsView_person 
      }
    }
  }
`

function ProfileChecklists({orgDatabaseId, personDatabaseId} : Props): null | Node {
  const queryVariables = {orgDatabaseId, personDatabaseId}
  const [{organization}] = useRefetchableLazyLoadQuery<Query>(profileChecklistsQuery, queryVariables)
  const person = organization?.person

  if (!organization || !person)
    return null

  return (
    <ProfileChecklistsView
      organization={organization}
      person={person}
    />
  )
}

export default ProfileChecklists
