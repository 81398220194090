// @flow
import React, {type Node} from 'react'
import {useFragment, graphql} from 'react-relay'
import {type FormikProps} from 'formik'

import CircleAndRoleSelector from './CircleAndRoleSelector'
import RoleAndPersonSelector from './RoleAndPersonSelector'
import type {Values} from '../../ProjectEditForm'
import type {
  ProjectOwnerSelector_circle$key as CircleKey,
} from './__generated__/ProjectOwnerSelector_circle.graphql'
import type {
  ProjectOwnerSelector_viewer$key as ViewerKey,
} from './__generated__/ProjectOwnerSelector_viewer.graphql'
import type {
  ProjectOwnerSelector_project$key as ProjectKey,
} from './__generated__/ProjectOwnerSelector_project.graphql'

export type Mode = 'CircleAndRole' | 'RoleAndPerson'

type Props = $ReadOnly<{
  disabled: boolean,
  formikProps: FormikProps<Values>,
  mode: Mode,
  viewer: ViewerKey,
  circle: ?CircleKey,
  project: ?ProjectKey,
}>

const viewerFragment = graphql`
  fragment ProjectOwnerSelector_viewer on Person {
    ...CircleAndRoleSelector_viewer
  }
`

const circleFragment = graphql`
  fragment ProjectOwnerSelector_circle on Circle {
    ...RoleAndPersonSelector_circle
  }
`

const projectFragment = graphql`
  fragment ProjectOwnerSelector_project on Project {
    ...RoleAndPersonSelector_project
    ...CircleAndRoleSelector_project
  }
`

function ProjectOwnerSelector(
  {disabled, formikProps, mode, viewer: viewerKey, circle: circleKey, project: projectKey}: Props,
): Node {
  const viewer = useFragment(viewerFragment, viewerKey)
  const circle = useFragment(circleFragment, circleKey)
  const project = useFragment(projectFragment, projectKey)

  if (mode === 'CircleAndRole') {
    return (
      <CircleAndRoleSelector
        disabled={disabled}
        formikProps={formikProps}
        viewer={viewer}
        project={project}
      />
    )
  }

  if (mode === 'RoleAndPerson' && circle) {
    return (
      <RoleAndPersonSelector
        disabled={disabled}
        formikProps={formikProps}
        circle={circle}
        project={project}
      />
    )
  }

  return null
}

export default ProjectOwnerSelector
