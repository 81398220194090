import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import * as R from 'ramda'
import util from 'utils/MiscUtils'

/* eslint-disable react/jsx-no-literals */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */

// This component handles initiating data import from the HolaSpirit API.
// It collects user credentials, posts directly to the HS API to get an
// auth token, then posts to OUR server to initiate an import using that token.
const HolaspiritImportComponent = createReactClass({
  displayName: 'HolaspiritImportComponent',

  // this component moves through steps similar to state-machine.
  // current state/step is captured in this.state.step

  propTypes: {
    organizationId: PropTypes.number.isRequired,
    importExcludedOrganization: PropTypes.number.isRequired,
  },

  getInitialState() {
    return {
      step: 'initialized',
      username: '',
      password: '',
      accessToken: null,
      error: null,
      organizations: null,
      hsOrganizationId: null,
    }
  },

  // TODO: The rest of the HolaSpirit API interactions happen from the backend
  // (see HolaspiritImportService). Maybe this step should be moved there too?
  getToken(username, password) {
    $.get(`/admin/organizations/${this.props.organizationId}/holaspirit_auth`, {
      password,
      username,
    }).then(
      this.getTokenSuccess,
      this.getTokenError,
    )
  },

  getTokenSuccess(data) {
    this.setState({accessToken: data.access_token, step: 'token_received'})
    this.getOrganizations(data.access_token)
  },

  getTokenError(e) {
    let message = e.responseText
    try {
      message = JSON.parse(message).error_description
    } catch (exception) {
      util.reportAndContinue(exception)
    }
    this.setState({step: 'ready_for_credentials', error: message})
  },

  getOrganizations(token) {
    $.get(`/admin/organizations/${this.props.organizationId}/holaspirit_orgs`, {token})
      .then((data) => R.pipe(
        R.path(['linked', 'organizations']),
        R.map(R.pick(['id', 'name'])),
      )(data))
      .then(this.getOrganizationsSuccess)
  },

  getOrganizationsSuccess(organizations) {
    this.setState({
      organizations,
      hsOrganizationId: organizations[0].id,
    })
    if (organizations.length > 1)
      this.setState({step: 'choose_organization'})
    else
      this.createImport()
  },

  setField(fieldName, e) {
    const data = {}
    data[fieldName] = e.target.value
    this.setState(data)
  },

  createImport() {
    if (!this.state.hsOrganizationId) {
      alert('Invalid organization id')
      return
    }
    $.ajax({
      type: 'POST',
      url: `/admin/organizations/${this.props.organizationId}/holaspirit_imports`,
      data: JSON.stringify({
        holaspirit_auth_token: this.state.accessToken,
        holaspirit_organization_id: this.state.hsOrganizationId,
      }),
      dataType: 'json',
      contentType: 'application/json',
    }).then(
      this.createImportSuccess,
      this.createImportError,
    )
  },

  createImportSuccess(data) {
    if (data.status === 'started') {
      this.setState({step: 'import_started'})
      window.location.reload(true)
    } else {
      alert('Import failed for an unknown reason. Please contact the web development team.')
    }
  },

  createImportError(data) {
    if (data.responseJSON?.error)
      alert(data.responseJSON.error)
    else
      alert('Import failed for an unknown reason. Please contact the web development team.')
  },

  clickStart(e) {
    e.preventDefault()
    this.setState({step: 'ready_for_credentials'})
  },

  clickAuthorize(e) {
    e.preventDefault()
    this.setState({step: 'authenticating'})
    this.getToken(this.state.username, this.state.password)
  },

  clickOrgContinue(e) {
    e.preventDefault()
    this.setState({step: 'token_received'})
    this.createImport()
  },

  renderStep() {
    switch (this.state.step) {
      case 'initialized':
        if (this.props.organizationId === this.props.importExcludedOrganization)
          return this.renderImportDisabled()

        return this.renderInitialized()

      case 'ready_for_credentials':
        return this.renderReadyForCredentials()
      case 'authenticating':
        return this.renderAuthenticating()
      case 'token_received':
        return this.renderTokenReceived()
      case 'choose_organization':
        return this.renderChooseOrganization()
      case 'import_started':
        return this.renderImportStarted()
      case 'import_complete':
        return this.renderImportComplete()
      default:
        return null
    }
  },

  renderInitialized() {
    return (
      <div>
        Have a Holaspirit Account? &nbsp;
        <a href="#" onClick={this.clickStart}>Start Import</a>
      </div>
    )
  },

  renderImportDisabled() {
    return (
      <div>
        You can't import from HolaSpirit because this is your primary organization.
        Select another organization to import onto.
      </div>
    )
  },

  renderReadyForCredentials() {
    return (
      <div className="row">

        <div className="col-lg-6">
          <p>Sign into HolaSpirit to start the import.</p>
          <p>For a large organization, this will take several minutes.</p>
          <div className="form-group">
            <label htmlFor="account-username">{'Username'}</label>
            <input
              type="text"
              onChange={this.setField.bind(this, 'username')}
              value={this.state.username}
              className="form-control"
              id="account-username"
            />
          </div>

          <div className="form-group">
            <label htmlFor="account-password">{I18n.translate('accounts.new.password')}</label>
            <input
              type="password"
              onChange={this.setField.bind(this, 'password')}
              value={this.state.password}
              className="form-control"
              id="account-password"
            />
          </div>

          <div className="form-group">
            <a href="#" className="btn btn-primary" onClick={this.clickAuthorize}>Authorize</a>
            {this.state.error ? this.renderAuthorizationError() : null}
          </div>
        </div>
      </div>
    )
  },

  renderChooseOrganization() {
    return (
      <div className="row">
        <div className="col-lg-6">
          <p>You belong to multiple Holaspirit organizations.  Please select which org to import</p>
          <div className="form-group">
            <label htmlFor="organization-select">{'Select Organization'}</label>
            <select onChange={this.setField.bind(this, 'hsOrganizationId')} id="organization-select" className="form-control">
              {this.state.organizations.map((org) => (
                <option value={org.id}>{org.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <a href="#" className="btn btn-primary" onClick={this.clickOrgContinue}>Continue</a>
          </div>
        </div>
      </div>
    )
  },

  renderAuthorizationError() {
    return (
      <div>
        <p>
          There was a problem logging in:
          {this.state.error}
        </p>
        <p>
          Please double-check that:
          a) You can log into HolaSpirit using these same credentials, and the site loads correctly,
          and b) (for developers) ENV['HOLASPIRIT_API_CLIENT_ID'] is the correct client ID.
        </p>
      </div>
    )
  },

  renderAuthenticating() {
    return (
      <p>
        <span className="waiting-spinner" />
        Logging you in...
      </p>
    )
  },

  renderTokenReceived() {
    return (
      <div>
        <p>
          Holaspirit successfully authenticated. Your API token is:
          {this.state.accessToken}
        </p>
        <p>
          The import is running. The import itself completes quickly,
          but refreshing the organization data can take several minutes.
        </p>
      </div>
    )
  },

  renderImportStarted() {
    return (
      <p>
        Your import has been started.
      </p>
    )
  },

  renderImportComplete() {
    return (
      <p>
        Your import is complete! Go check out your newly imported org hierarchy.
      </p>
    )
  },

  render() {
    return (
      <div>
        <h3>Holaspirit Import</h3>
        {this.renderStep()}
      </div>
    )
  },
})

export default HolaspiritImportComponent
