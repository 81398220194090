// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import Project, {type OpenEditor} from 'components/projects/Project'
import AccessibleList from 'components/ui/AccessibleList'
import {type GroupBy} from 'components/projects/GroupProjectsBy'
import styles from './index.scss'
import type {
  Group_projects as Projects,
} from './__generated__/Group_projects.graphql'
import type {Group_circle as Circle} from './__generated__/Group_circle.graphql'

type ProjectType = Projects[number]

export type {OpenEditor}

type Props = $ReadOnly<{
  title?: string,
  projects: Projects,
  groupedBy: GroupBy,
  openEditor: OpenEditor,
  circle: null | Circle,
  showCircleName: boolean,
  hidePersonName: boolean,
}>

function Group({
  groupedBy,
  projects,
  openEditor,
  title,
  circle,
  showCircleName,
  hidePersonName,
}: Props): Node {
  const openEditorGuarded = useCallback((project: ProjectType) => () => {
    if (project.organization.viewerIsMember)
      openEditor(project.id)
  }, [openEditor])

  const renderProject = useCallback((project: ProjectType) => (
    <AccessibleList.ListItem
      test-id={project.databaseId}
      onClickText={I18n.t('shared.edit')}
      onClick={openEditorGuarded(project)}
    >
      <Project
        openEditor={openEditor}
        project={project}
        showRoleName={groupedBy !== 'role'}
        showPersonName={hidePersonName ? false : groupedBy !== 'person'}
        showCircleName={showCircleName}
        viewContext="tab"
        circle={circle}
      />
    </AccessibleList.ListItem>
  ), [circle, groupedBy, openEditorGuarded, openEditor, showCircleName, hidePersonName])

  if (projects.length === 0)
    return null

  return (
    <div className={styles.container}>
      {title && (
        <h3 className={styles.subHeader}>
          {title}
        </h3>
      )}
      <AccessibleList
        test-id="org-nav-project-list"
        items={projects}
        renderItem={renderProject}
        hasTopBorder={!!title}
      />
    </div>
  )
}

Group.defaultProps = {
  groupedBy: 'all',
  showCircleName: false,
  hidePersonName: false,
}

export default (createFragmentContainer(Group, {
  projects: graphql`
    fragment Group_projects on Project @relay(plural: true) {
      id
      databaseId
      status
      description(format: MARKDOWN)
      createdAt
      roi

      organization {
        viewerIsMember
      }

      circle {
        shortName
      }

      ...Project_project
    }
  `,
  circle: graphql`
    fragment Group_circle on Circle {
      ...Project_circle
    }
  `,
}): RelayFragmentContainer<typeof(Group)>)
