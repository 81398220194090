/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateRoleTemplateModal_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleTemplatesList_organization$ref: FragmentReference;
declare export opaque type RoleTemplatesList_organization$fragmentType: RoleTemplatesList_organization$ref;
export type RoleTemplatesList_organization = {|
  +id: string,
  +roleTemplates: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +$fragmentRefs: CreateRoleTemplateModal_organization$ref,
  +$refType: RoleTemplatesList_organization$ref,
|};
export type RoleTemplatesList_organization$data = RoleTemplatesList_organization;
export type RoleTemplatesList_organization$key = {
  +$data?: RoleTemplatesList_organization$data,
  +$fragmentRefs: RoleTemplatesList_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "roleTemplates"
        ]
      }
    ]
  },
  "name": "RoleTemplatesList_organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "roleTemplates",
      "args": null,
      "concreteType": "RoleTemplateConnection",
      "kind": "LinkedField",
      "name": "__RoleTemplatesList_roleTemplates_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateRoleTemplateModal_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '40d0d69ed7e180776f7be0b4511e87e2';

module.exports = node;
