import {propOr, sortBy, reverse} from 'ramda'
import {CHILDREN_OMITTED} from './ChartNodes'
import type {ChartNode} from './ChartNodes'

const sortByTimestamp = sortBy(propOr(0, 'timestamp'))

const recursiveMerge = (n1: ChartNode, n2: ChartNode): ChartNode => {
  const [newNode, oldNode] = reverse(sortByTimestamp([n1, n2])) // later sort by timestamp

  if (newNode.id !== oldNode.id)
    throw new Error('cant merge mismatched nodes')

  if (!newNode.children && !oldNode.children)
    return newNode

  if (newNode.children === CHILDREN_OMITTED)
    newNode.children = oldNode.children

  newNode.children = newNode.children?.map((newChild) => {
    const oldChild = oldNode.children?.find((n) => n.id === newChild.id)

    if ((!newChild.children && !(oldChild?.children)) || !oldChild)
      return newChild

    return recursiveMerge(newChild, oldChild)
  })

  return newNode
}

export default recursiveMerge
