/**
 * @flow
 * @relayHash 34bd217d9467cfad64e179b50bee6d95
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MetricFormModal_organization$ref = any;
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type MetricEditModalQueryVariables = {|
  orgDatabaseId: string,
  circleDatabaseId: string,
  metricId: string,
|};
export type MetricEditModalQueryResponse = {|
  +organization: ?{|
    +circle: ?{|
      +supportedRole: ?{|
        +id: string,
        +localizedName: string,
        +parentRole: ?{|
          +__typename: string
        |},
      |},
      +roles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +localizedName: string,
          |}
        |}>
      |},
    |},
    +$fragmentRefs: MetricFormModal_organization$ref,
  |},
  +metric: ?{|
    +description?: string,
    +frequency?: ?Frequency,
    +link?: ?any,
    +privateToCircle?: boolean,
    +customFrequency?: ?{|
      +id: string,
      +name: string,
      +universalUnits: number,
    |},
    +role?: ?{|
      +id: string
    |},
  |},
|};
export type MetricEditModalQuery = {|
  variables: MetricEditModalQueryVariables,
  response: MetricEditModalQueryResponse,
|};
*/


/*
query MetricEditModalQuery(
  $orgDatabaseId: String!
  $circleDatabaseId: String!
  $metricId: ID!
) {
  organization(databaseId: $orgDatabaseId) {
    circle(databaseId: $circleDatabaseId) {
      supportedRole {
        id
        localizedName
        parentRole {
          __typename
          id
        }
      }
      roles {
        edges {
          node {
            id
            localizedName
          }
        }
      }
      id
    }
    ...MetricFormModal_organization
    id
  }
  metric: node(id: $metricId) {
    __typename
    ... on Metric {
      description
      frequency
      link
      privateToCircle
      customFrequency {
        id
        name
        universalUnits
      }
      role {
        id
      }
    }
    id
  }
}

fragment FrequencySelector_organization on Organization {
  customFrequencies(first: 100) {
    edges {
      node {
        id
        name
        universalUnits
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment MetricFormModal_organization on Organization {
  onRestrictedPlan
  ...FrequencySelector_organization
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "circleDatabaseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metricId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgDatabaseId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "circleDatabaseId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RoleConnection",
  "kind": "LinkedField",
  "name": "roles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "metricId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universalUnits",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFrequency",
      "kind": "LinkedField",
      "name": "customFrequency",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Metric",
  "abstractKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MetricEditModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "supportedRole",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "parentRole",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MetricFormModal_organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "metric",
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MetricEditModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "supportedRole",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "parentRole",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "CustomFrequencyConnection",
            "kind": "LinkedField",
            "name": "customFrequencies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomFrequencyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFrequency",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "customFrequencies(first:100)"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "FrequencySelector_customFrequencies",
            "kind": "LinkedHandle",
            "name": "customFrequencies"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "metric",
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "34bd217d9467cfad64e179b50bee6d95",
    "metadata": {},
    "name": "MetricEditModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22bdd7008f76462269b1b33bd48704e5';

module.exports = node;
