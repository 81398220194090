// @flow
import type {Node} from 'react'
import React, {useContext} from 'react'
import {useFragment, graphql} from 'react-relay'
import classNames from 'classnames'

import type {Response as MutationResponse} from 'mutations/SetOrganizationRuleMutation'
import RadioButton from 'components/ui/forms/RadioButton'
import TextareaAutosize from 'react-textarea-autosize'
import {Body, CardContext} from 'components/ui/Accordion'
import SaveButton from 'components/ui/SaveButton'
import InlineMarkdown from 'components/ui/InlineMarkdown'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import FormattedOption from '../FormattedOption'
import styles from './index.scss'

import type {
  SectionBody_organization$key as OrganizationKey,
} from './__generated__/SectionBody_organization.graphql'

type Props = {
  organization: OrganizationKey,
  ruleTypeSelection: Object,
  name: string,
  customTextAccessors: Object,
  setRuleTypeSelection: (Object) => void,
  constitutionRatifiers: Object,
  setConstitutionRatifiers: (Object) => void,
  options: Object[],
  onClickSave: () => Promise<MutationResponse>,
  disabled?: boolean,
}

const organizationFragment = graphql`
  fragment SectionBody_organization on Organization {
    constitutionRatifiers

    ...WYSIWYGViewerWithFeatureFlag_organization
    ...WYSIWYGTextareaWithFeatureFlag_organization
  }
`

function FallbackTextarea(props) {
  return (
    <TextareaAutosize
      {...props}
      className="form-control"
    />
  )
}

function SectionBody({
  organization: organizationKey,
  name, customTextAccessors, ruleTypeSelection, setRuleTypeSelection, constitutionRatifiers, setConstitutionRatifiers,
  options, onClickSave, disabled,
} :Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const {isOpen, toggleOpen} = useContext(CardContext)
  const onClick = () => {
    onClickSave().then(toggleOpen())
  }

  const editable = (option: Object) => (
    ['CENTRALIZED', 'CUSTOM', 'UNIVERSAL'].indexOf(option.value) >= 0
  )
  const currentText = (option: Object) => (
    option.description || customTextAccessors[option.value].getter || option.defaultText
  )
  const setCustomText = (option: Object, newValue: Object) => {
    customTextAccessors[option.value].setter(newValue)
  }
  const restoreDefaultClassName = classNames('btn-link', styles.restoreDefault)

  return (
    <Body>
      <RadioButton
        disabled={disabled}
        name={name}
        value={ruleTypeSelection}
        onChange={(_, newValue) => setRuleTypeSelection(newValue)}
        options={options.filter((option) => option.display !== false).map((option) => ({
          ...option,
          label: (
            <FormattedOption isOpen={isOpen} selected={ruleTypeSelection === option.value} option={option}>
              {editable(option) && option.value === ruleTypeSelection
                ? (
                  <div
                    className={styles.noClick}
                    role="none"
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <button
                      className={restoreDefaultClassName}
                      onClick={() => setCustomText(option, option.defaultText)}
                      type="button"
                    >
                      {I18n.t('admin.organizations.org_rules.restore_default')}
                    </button>
                    <WYSIWYGTextareaWithFeatureFlag
                      label={I18n.t('admin.organizations.org_rules.rules.custom')}
                      forceAllow
                      hideLabel
                      onChange={(event) => setCustomText(option, event.target.value)}
                      value={currentText(option)}
                      name="customRule"
                      organization={organization}
                      fallbackComponent={FallbackTextarea}
                    />
                  </div>
                )
                : (
                  <WYSIWYGViewerWithFeatureFlag
                    value={currentText(option)}
                    organization={organization}
                    fallbackProps={{
                      text: currentText(option),
                    }}
                    fallbackComponent={InlineMarkdown}
                  />
                )
              }
              {option.label === I18n.t('admin.organizations.org_rules.rules.preamble.holacracy.label') && (
                <input
                  type="text"
                  className="form-control"
                  name="constitutionRatifiers"
                  value={constitutionRatifiers}
                  onChange={(event) => setConstitutionRatifiers(event.target.value)}
                />
              )}
            </FormattedOption>
          ),
        }))}
      />
      {!disabled && (
        <div className={styles.saveContainer}>
          <SaveButton onClick={onClick}>{I18n.t('admin.organizations.org_rules.save_button')}</SaveButton>
        </div>
      )}
    </Body>
  )
}

SectionBody.defaultProps = {disabled: false}

export default SectionBody
