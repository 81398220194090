// @flow
import type {Node} from 'react'
import React, {useEffect} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import logger from 'utils/MiscUtils/main'
import type {AllProjectStatuses, InitialProjectStatuses} from 'components/projects/ProjectEditForm'
import type {Alert} from 'components/types'
import type {Values, SubmitValues, FormikProps, Mode} from './ProjectFormModalView'
import ProjectFormModalView from './ProjectFormModalView'
import type {
  ProjectFormModalQueryVariables as Variables,
  ProjectFormModalQuery as Query,
} from './__generated__/ProjectFormModalQuery.graphql'

type Props<SubmitResponse> = $ReadOnly<{
  disabled: boolean,
  initialValues: Values,
  submit: SubmitValues => Promise<SubmitResponse>,
  onSuccess: () => Promise<void>,
  modalTitle: string,
  mode: Mode,
  renderLeftFooterButton: FormikProps => Node,
  close: () => void,
  projectId: string | null,
  tensionDatabaseId: ?string,
  circleDatabaseId: string,
  statuses: InitialProjectStatuses | AllProjectStatuses,
}>

export type {FormikProps, SubmitValues, Values, Mode}

const query = graphql`
  query ProjectFormModalQuery(
    $orgDatabaseId: String!,
    $circleDatabaseId: String!,
    $tensionDatabaseId: String!,
    $projectId: ID!
  ) {
    organization(databaseId: $orgDatabaseId) {
      viewer {
        ...ProjectFormModalView_viewer
      }

      circle(databaseId: $circleDatabaseId) {      
        ...ProjectFormModalView_circle
      }

      tension(databaseId: $tensionDatabaseId) {
        ...ProjectFormModalView_tension
      }

      ...ProjectFormModalView_organization
    }
    
    project: node(id: $projectId) {
      id
      
      ... on Project {
        ...ProjectFormModalView_project
      }
    }
  }
`

function ProjectFormModal<SubmitResponse: ?{+errors: $ReadOnlyArray<Alert>, ...}>({
  tensionDatabaseId,
  circleDatabaseId,
  projectId,
  ...formProps
}: Props<SubmitResponse>): null | Node {
  const {mode, initialValues} = formProps

  const variables: Variables = {
    orgDatabaseId: window.gf.app.currentOrganizationId().toString(),
    tensionDatabaseId: tensionDatabaseId || '',
    projectId: projectId || '',
    circleDatabaseId,
  }

  const {organization, project} = useLazyLoadQuery<Query>(query, variables)

  useEffect(() => {
    if (!organization)
      formProps.close()
  }, [organization, formProps])

  if (!organization)
    return null

  if (mode === 'RoleAndPerson' && !circleDatabaseId) {
    logger.error('circleDatabaseId must be provided in Role-Person mode')
    return null
  }

  if (mode === 'CircleAndRole' && !initialValues.personId) {
    logger.error('personDatabaseId must be provided in Circle-Role mode')
    return null
  }

  return (
    <ProjectFormModalView
      organization={organization}
      circle={organization.circle}
      tension={organization.tension}
      viewer={organization.viewer}
      project={project}
      {...formProps}
    />
  )
}

ProjectFormModal.defaultProps = {
  disabled: false,
  renderLeftFooterButton: (): null => null,
  tensionDatabaseId: '',
  projectId: null,
  onSuccess: (): Promise<void> => Promise.resolve(),
}

export default ProjectFormModal
