/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ProjectVisibility = "ALL" | "PRIVATE_TO_CIRCLE" | "PRIVATE_TO_PERSON";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdateProjectModal_project$ref: FragmentReference;
declare export opaque type UpdateProjectModal_project$fragmentType: UpdateProjectModal_project$ref;
export type UpdateProjectModal_project = {|
  +id: string,
  +databaseId: string,
  +description: string,
  +rawDescription: string,
  +link: ?any,
  +visibility: ProjectVisibility,
  +value: ?number,
  +effort: ?number,
  +status: string,
  +note: ?string,
  +parentGoalOrTarget: ?string,
  +viewerCanAddActions: boolean,
  +viewerCanUpdate: boolean,
  +waitingOnWho: ?string,
  +waitingOnWhat: ?string,
  +accountability: ?{|
    +id: string
  |},
  +circle: {|
    +databaseId: string,
    +supportedRole: ?{|
      +id: string
    |},
    +organization: {|
      +isWysiwygEnabled: boolean
    |},
  |},
  +person: ?{|
    +id: string
  |},
  +role: {|
    +id: string
  |},
  +$refType: UpdateProjectModal_project$ref,
|};
export type UpdateProjectModal_project$data = UpdateProjectModal_project;
export type UpdateProjectModal_project$key = {
  +$data?: UpdateProjectModal_project$data,
  +$fragmentRefs: UpdateProjectModal_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateProjectModal_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MARKDOWN"
        }
      ],
      "kind": "ScalarField",
      "name": "description",
      "storageKey": "description(format:\"MARKDOWN\")"
    },
    {
      "alias": "rawDescription",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "RAW"
        }
      ],
      "kind": "ScalarField",
      "name": "description",
      "storageKey": "description(format:\"RAW\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effort",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentGoalOrTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanAddActions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitingOnWho",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitingOnWhat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Accountability",
      "kind": "LinkedField",
      "name": "accountability",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isWysiwygEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '3967b1add2243ca3b2a2c0505b52535e';

module.exports = node;
