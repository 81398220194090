// @flow
import type {Element} from 'react'
import React, {useRef} from 'react'
import {isEmpty} from 'ramda'

import TruncateString from 'components/ui/text/TruncateString'
import InlineEdit from './InlineEdit'

const TAB_KEYCODE = 9
const ENTER_KEYCODE = 13
const ESC_KEYCODE = 27

type Props = $ReadOnly<{
  actionId: ?string,
  editing: boolean,
  value: string,
  rawValue: string,
  isPlaceholder: boolean,
  startEditing: () => void,
  finishEditing: () => void,
  onChange: (string) => void,
  onTabPress: (string, 'BACK' | 'FORWARD') => void,
}>

function Description({
  actionId,
  editing,
  startEditing,
  value,
  rawValue,
  isPlaceholder,
  finishEditing,
  onChange,
  onTabPress,
}: Props): Element<"span"> {
  const wrapperRef = useRef<?HTMLSpanElement>(null)

  const focusOnInput = () => {
    setTimeout(() => {
      const wrapper = wrapperRef.current
      if (wrapper)
        wrapper.click()
    }, 10)
  }

  const setBaseValue = () => {}
  const handleKeyPress = ({keyCode, shiftKey}) => {
    if (isPlaceholder && keyCode === ESC_KEYCODE)
      setBaseValue()

    if (keyCode === ENTER_KEYCODE || keyCode === ESC_KEYCODE)
      finishEditing()

    if (actionId && editing && keyCode === TAB_KEYCODE) {
      const direction: 'FORWARD' | 'BACK' = shiftKey
        ? 'BACK'
        : 'FORWARD'

      onTabPress(actionId, direction)
    }
  }

  const changeValue = (newValue: string) => {
    onChange(newValue)

    if (isPlaceholder) {
      focusOnInput()
      setBaseValue()
    }
  }

  const guardedStartEdit = () => {
    if (isPlaceholder)
      startEditing()
  }

  const validate = (text: string) => (
    !isEmpty(text)
  )

  return (
    <span
      className="project__action-description"
      onBlur={finishEditing}
      ref={wrapperRef}
    >
      <TruncateString clamp={1}>
        <InlineEdit
          actionId={actionId}
          editing={editing}
          startEditing={guardedStartEdit}
          finishEditing={finishEditing}
          activeClassName="editing"
          placeholder={value}
          value={isPlaceholder ? '' : rawValue}
          validate={validate}
          commitChange={changeValue}
          onKeyPress={handleKeyPress}
        />
      </TruncateString>
    </span>
  )
}

export default Description
