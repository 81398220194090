import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const ProposalResponse = GlassFrogModel.extend({
  url() {
    return `/api/v3/proposals/${this.get('proposal_id')}/responses`
  },
}, {
  resourceName: 'proposal_responses',
  defaults: {
  },
})

export default ProposalResponse
