// @flow
import type {Node} from 'react'
import React, {useMemo, useCallback} from 'react'
import {useFragment, graphql} from 'react-relay'

import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import idOrDatabaseId, {type UseAsValue} from 'utils/MiscUtils/idOrDatabaseId'
import Select from 'components/ui/forms/Select'

import type {
  CircleSelector_circles$key as CirclesKey,
  CircleSelector_circles as Circles,
} from './__generated__/CircleSelector_circles.graphql'

export type Circle = Circles[number]

type Props = $ReadOnly<{
  circles: CirclesKey,
  useAsValue: UseAsValue,
  label: string,
  value: string | null,
  initialValue: string | null,
  onChange: (FakeEvent, boolean | string | null) => void,
  onCircleChange: ?(Circle) => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
  circleLabel: ?(Circle) => string,
  optionDisabled: ?((Circle) => boolean) | boolean,
}>

const circlesFragment = graphql`
  fragment CircleSelector_circles on Circle @relay(plural: true) {
    id
    databaseId
    localizedName
    isGovernanceEnabled

    supportedRole {
      isDisplayedAsCircle
    }
  }
`

function CircleSelector({
  useAsValue,
  circles: circlesKey,
  disabled,
  circleLabel,
  onChange,
  onCircleChange,
  optionDisabled,
  ...selectProps
}: Props): Node {
  const circles = useFragment(circlesFragment, circlesKey)

  const options = useMemo(() => (
    circles
      .filter((circle) => circle.supportedRole?.isDisplayedAsCircle)
      .map((circle) => ({
        value: idOrDatabaseId(useAsValue, circle),
        label: circleLabel ? circleLabel(circle) : circle.localizedName,
        disabled: typeof optionDisabled === 'function' ? optionDisabled(circle) : optionDisabled,
      }))
  ), [circles, useAsValue, circleLabel, optionDisabled])

  const handleChange = useCallback((event, newValue) => {
    const selectedCircle = circles.find((circle) => idOrDatabaseId(useAsValue, circle) === newValue)

    onChange(event, newValue)

    if (onCircleChange && selectedCircle)
      onCircleChange(selectedCircle)
  }, [onChange, circles, useAsValue, onCircleChange])

  const initialValue = options.length === 1 && !options[0].disabled ? options[0].value : null

  return (
    <Select
      {...selectProps}
      onChange={handleChange}
      disabled={disabled || options.length === 0}
      options={options}
      initialValue={initialValue}
    />
  )
}

CircleSelector.defaultProps = {
  disabled: false,
  clearable: false,
  'test-id': null,
  warning: null,
  initialValue: null,
  onCircleChange: null,
  circleLabel: null,
  optionDisabled: false,
}

export default CircleSelector
