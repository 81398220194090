/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectFormModalView_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalFragmented_circle$ref: FragmentReference;
declare export opaque type ProjectFormModalFragmented_circle$fragmentType: ProjectFormModalFragmented_circle$ref;
export type ProjectFormModalFragmented_circle = {|
  +$fragmentRefs: ProjectFormModalView_circle$ref,
  +$refType: ProjectFormModalFragmented_circle$ref,
|};
export type ProjectFormModalFragmented_circle$data = ProjectFormModalFragmented_circle;
export type ProjectFormModalFragmented_circle$key = {
  +$data?: ProjectFormModalFragmented_circle$data,
  +$fragmentRefs: ProjectFormModalFragmented_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalFragmented_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectFormModalView_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9b7fa7e8aaf0ee64b323d04a03fa8863';

module.exports = node;
