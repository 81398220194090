// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  UpdateTensionInput as RawInput,
  UpdateTensionMutationResponse as Response,
  TensionMeetingType as MeetingType,
} from './__generated__/UpdateTensionMutation.graphql'

const mutation = graphql`
  mutation UpdateTensionMutation(
    $input: UpdateTensionInput!
  ) {
    updateTension(input: $input) {
      tension {
        id
        databaseId
        body
        agendaLabel
        processed

        impactedRole {
          databaseId
          localizedName
        }

        agendaItems {
          count
        }

        ...TensionEditor_tension
      }
    }
  }
`

type Input = $ReadOnly<{
  clientMutationId?: ?string,
  body?: ?string,
  agendaLabel?: ?string,
  meetingType?: ?MeetingType,
  roleId?: ?string,
  impactedRoleId?: ?string,
}>

export default (tensionId: string, meetingId: ?string, input: Input): Promise<Response> => {
  const mutationInput: MutationInput<RawInput> = {
    tensionId,
    meetingId,
    ...input,
  }

  const variables = {input: mutationInput}

  return createMutationPromise({
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors)
        util.error('There was a problem', errors)
    },
  })
}

export type {
  Input,
  Response,
}
