// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import DefaultTabs from 'components/DefaultTabs'
import PrintableGovernanceForm from '../PrintableGovernanceForm'
import type {
  PrintableGovernancePageQueryVariables as Variables,
} from './__generated__/PrintableGovernancePageQuery.graphql'

export type Props = $ReadOnly<{
  orgDatabaseId: string,
  tabs: Object,
}>

const query = graphql`
  query PrintableGovernancePageQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      id
      onRestrictedPlan

      ...PrintableGovernanceForm_organization
    }
  }
`

function PrintableGovernancePage({orgDatabaseId, tabs}: Props): null | Node {
  const variables: Variables = {orgDatabaseId}

  const {organization} = useLazyLoadQuery(query, variables)

  if (!organization)
    return null

  return (
    <div>
      <header className="page__header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>
                {I18n.t('reports.title')}
              </h1>
            </div>
          </div>
        </div>
      </header>
      <main className="page__main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <DefaultTabs
                tabs={tabs}
                currentTab="reports_printable_governance"
                isOnRestricted={organization.onRestrictedPlan}
              />
              <br />
              <PrintableGovernanceForm
                organization={organization}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default PrintableGovernancePage
