import trackMissedKeys from './trackMissedKeys'

function translateWithMissedKeysDetection(originalTranslateFn) {
  function translate(...args) {
    const [key] = args

    trackMissedKeys(key)

    return originalTranslateFn.bind(I18n)(...args)
  }

  return translate
}

export default translateWithMissedKeysDetection
