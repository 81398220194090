// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'
import rangeAddUpdater from 'utils/GraphQL/rangeAddUpdater'

import type {
  AddViewerMetricMutationResponse as Response,
  AddMetricInput as Input,
} from './__generated__/AddViewerMetricMutation.graphql'

const mutation = graphql`
  mutation AddViewerMetricMutation(
    $input: AddMetricInput!
  ) {
    addMetric(input: $input) {
      metricEdge {
        node {
          id

          ...MetricsViewerSection_metrics
        }
      }
    }
  }
`

const AddViewerMetricMutation = (
  input: MutationInput<Input>,
  viewerId: string,
): Promise<Response> => {
  const variables = {input}

  const updater = rangeAddUpdater({
    connectionInfo: {
      key: 'Viewer_metrics',
    },
    parentId: viewerId,
    edgeName: 'metricEdge',
    rootNode: 'addMetric',
    addLocation: 'after',
  })

  return createMutationPromise({mutation, variables, updater})
}

export type {Response}
export default AddViewerMetricMutation
