import util from 'utils/MiscUtils'

const GovMtgActions = {
  GOV_MTG_PROPOSAL_LOCAL_CREATE(action) {
    const agendaItemId = action.agendaItemId || util.error('agendaItemId required')
    const localId = this.store.setData('proposals', {
      status: 'draft',
      links: {
        circle: action.circleId || util.error('circleId required'),
        person: action.personId || util.error('personId required'),
      },
    })
    this.store.setData('agenda_items', agendaItemId, 'links', {proposal: localId})
    return localId
  },
}

export default GovMtgActions
