// @flow
const posixToLdmlMap: {[key: string]: string, ...} = {
  '%y': 'yy',
  '%Y': 'yyyy',
  '%-m': 'M',
  '%m': 'MM',
  '%b': 'MMM',
  '%B': 'MMMM',
  '%e': 'd',
  '%d': 'dd',
  '%j': 'D',
  '%A': 'EEEE',
  '%-I': 'h',
  '%I': 'hh',
  '%-H': 'H',
  '%H': 'HH',
  '%-M': 'm',
  '%M': 'mm',
  '%-S': 's',
  '%S': 'ss',
  '%p': 'a',
  '%Z': 'Z',
}

const posixKeys = Object.keys(posixToLdmlMap)

function convertPosixToLdml(posixFormat: string): string {
  let ldmlFormat = posixFormat

  posixKeys.forEach((posixKey) => {
    const ldmlKey = posixToLdmlMap[posixKey]
    const posixKeyPattern = new RegExp(posixKey, 'g')

    ldmlFormat = ldmlFormat.replace(posixKeyPattern, ldmlKey)
  })

  return ldmlFormat
}

export default convertPosixToLdml
