// @flow
import type {Node} from 'react'
import React, {Fragment, Suspense} from 'react'
import {graphql, useFragment} from 'react-relay'

import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import MetricEditModal from 'components/metrics/MetricEditModal'
import {Item} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import useToggle from 'utils/hooks/useToggle'
import type {Source} from 'components/ui/SourceIcon'
import type {
  Metric_metric$key as MetricType,
} from './__generated__/Metric_metric.graphql'
import type {
  Metric_circle$key as Circle,
} from './__generated__/Metric_circle.graphql'

type Props = $ReadOnly<{
  metric: MetricType,
  circle: Circle,
  source: Source,
}>

const metricFragment = graphql`
  fragment Metric_metric on Metric {
    id
    databaseId
    description
    frequency
    localizedFrequency
    link
    localizedRoleName
    isGlobal
    appliesToAllMembers
    viewerCanSeeEditIcon
    privateToCircle
    position
    viewerCanReorder

    organization {
      id
      onRestrictedPlan
    }
    
    customFrequency {
      id
      name
      universalUnits
    }

    role {
      id
    }

    people(first: 30) {
      edges {
        node {
          name
        }
      }
    }
  }
`

const circleFragment = graphql`
  fragment Metric_circle on Circle {
    databaseId

    organization {
      databaseId
    }

    ...MetricItem_circle
  }
`

function Metric({metric: metricKey, circle: circleKey, source}: Props): Node {
  const metric = useFragment(metricFragment, metricKey)
  const circle = useFragment(circleFragment, circleKey)

  const [isModalOpen, {
    close: closeModal,
    open: openModal,
  }] = useToggle()

  return (
    <Fragment>
      {isModalOpen && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <MetricEditModal
              close={closeModal}
              orgDatabaseId={circle.organization.databaseId}
              circleDatabaseId={circle.databaseId}
              metricId={metric.id}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
      <Item
        item={metric}
        onEdit={metric.viewerCanSeeEditIcon ? openModal : null}
        disabled={metric.isGlobal}
        tooltip={I18n.t('metrics.tip_editable_by_you')}
        circle={circle}
        source={source}
      />
    </Fragment>
  )
}

export default Metric
