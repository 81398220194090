/**
 * @flow
 * @relayHash 8fa755e6ec00e6476188d1371f90c50b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TacticalMeetingChecklistItemsView_circle$ref = any;
export type TacticalMeetingChecklistItemsQueryVariables = {|
  circleDatabaseId: string,
  orgDatabaseId: string,
|};
export type TacticalMeetingChecklistItemsQueryResponse = {|
  +organization: ?{|
    +circle: ?{|
      +$fragmentRefs: TacticalMeetingChecklistItemsView_circle$ref
    |}
  |}
|};
export type TacticalMeetingChecklistItemsQuery = {|
  variables: TacticalMeetingChecklistItemsQueryVariables,
  response: TacticalMeetingChecklistItemsQueryResponse,
|};
*/


/*
query TacticalMeetingChecklistItemsQuery(
  $circleDatabaseId: String!
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    circle(databaseId: $circleDatabaseId) {
      ...TacticalMeetingChecklistItemsView_circle
      id
    }
    id
  }
}

fragment ChecklistItem_circle on Circle {
  databaseId
  organization {
    databaseId
    id
  }
  ...MetricItem_circle
}

fragment ChecklistItem_item on ChecklistItem {
  id
  databaseId
  description
  frequency
  localizedFrequency
  link
  localizedRoleName
  isGlobal
  appliesToAllMembers
  viewerCanSeeEditIcon
  privateToCircle
  position
  viewerCanReorder
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  people(first: 30) {
    edges {
      node {
        name
        id
      }
    }
  }
  role {
    id
  }
}

fragment ChecklistsItemsSection_checklistItems on ChecklistItem {
  databaseId
  link
  description
  frequency
  localizedFrequency
  id
  localizedRoleName
  appliesToAllMembers
  isGlobal
  privateToCircle
  position
  viewerCanReorder
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  people(first: 30) {
    edges {
      node {
        name
        id
      }
    }
  }
  ...ChecklistItem_item
}

fragment ChecklistsItemsSection_circle on Circle {
  ...ChecklistItem_circle
}

fragment MetricItem_circle on Circle {
  roles(first: 100) {
    edges {
      node {
        localizedName
        people(first: 20) {
          edges {
            node {
              name
              id
            }
          }
        }
        id
      }
    }
  }
  members(first: 20) {
    edges {
      node {
        name
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment TacticalMeetingChecklistItemsView_circle on Circle {
  databaseId
  organization {
    id
  }
  checklistItems(first: 100) {
    edges {
      node {
        ...ChecklistsItemsSection_checklistItems
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...ChecklistsItemsSection_circle
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "circleDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "circleDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PersonEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TacticalMeetingChecklistItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TacticalMeetingChecklistItemsView_circle"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TacticalMeetingChecklistItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "circle",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ChecklistItemConnection",
                "kind": "LinkedField",
                "name": "checklistItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChecklistItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChecklistItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "frequency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedFrequency",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedRoleName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appliesToAllMembers",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGlobal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "privateToCircle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "position",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerCanReorder",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "onRestrictedPlan",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomFrequency",
                            "kind": "LinkedField",
                            "name": "customFrequency",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "universalUnits",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 30
                              }
                            ],
                            "concreteType": "PersonConnection",
                            "kind": "LinkedField",
                            "name": "people",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": "people(first:30)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerCanSeeEditIcon",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "checklistItems(first:100)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "TacticalMeetingChecklistItemsView_checklistItems",
                "kind": "LinkedHandle",
                "name": "checklistItems"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v11/*: any*/),
                            "concreteType": "PersonConnection",
                            "kind": "LinkedField",
                            "name": "people",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": "people(first:20)"
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(first:100)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "PersonConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v4/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "members(first:20)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MetricItem_members",
                "kind": "LinkedHandle",
                "name": "members"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8fa755e6ec00e6476188d1371f90c50b",
    "metadata": {},
    "name": "TacticalMeetingChecklistItemsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4a17c1116b9dc0f7c80d6b041588334';

module.exports = node;
