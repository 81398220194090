/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgendaItemGroup_agendaItem$ref: FragmentReference;
declare export opaque type AgendaItemGroup_agendaItem$fragmentType: AgendaItemGroup_agendaItem$ref;
export type AgendaItemGroup_agendaItem = {|
  +label: string,
  +createdAt: any,
  +tension: ?{|
    +body: string,
    +impactedRole: ?{|
      +localizedName: string
    |},
  |},
  +person: ?{|
    +id: string,
    +name: string,
    +isActive: boolean,
  |},
  +$refType: AgendaItemGroup_agendaItem$ref,
|};
export type AgendaItemGroup_agendaItem$data = AgendaItemGroup_agendaItem;
export type AgendaItemGroup_agendaItem$key = {
  +$data?: AgendaItemGroup_agendaItem$data,
  +$fragmentRefs: AgendaItemGroup_agendaItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgendaItemGroup_agendaItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tension",
      "kind": "LinkedField",
      "name": "tension",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "impactedRole",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "localizedName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AgendaItem",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9607a2b904dfdf20adf15f4c91440fb0';

module.exports = node;
