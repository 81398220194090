// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'
import {useFormik, FormikProvider, Form, Field, FieldArray} from 'formik'
import getIDGenerator from 'utils/getIDGenerator'

import UpdatePersonMutation from 'mutations/UpdatePersonMutation'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import Textarea from 'components/ui/forms/Textarea'
import styles from './index.scss'

import type {
  CustomProfileFieldsEditor_person$key as PersonKey,
} from './__generated__/CustomProfileFieldsEditor_person.graphql'

import type {
  CustomProfileFieldsEditor_organization$key as OrganizationKey,
} from './__generated__/CustomProfileFieldsEditor_organization.graphql'

type Props = $ReadOnly<{
  person: PersonKey,
  organization: OrganizationKey,
  close: () => void,
}>

type Item = $ReadOnly<{
  id: string,
  name: string,
  value: ?string,
  appliedFrom: ?string,
}>

type Values = $ReadOnly<{
  customProfileFields: $ReadOnlyArray<Item>,
}>

const personFragment = graphql`
  fragment CustomProfileFieldsEditor_person on Person {
    id

    customSettings(scope: ORGANIZATION) {
      customProfileFields {
        name
        value
        appliedFrom
      }
    }
  }
`

const organizationFragment = graphql`
  fragment CustomProfileFieldsEditor_organization on Organization {
    ...WYSIWYGTextareaWithFeatureFlag_organization
  }
`

const generateRandom = getIDGenerator()

const createCustomProfileFieldItem = (customProfileField) => ({
  id: generateRandom(),
  name: customProfileField.name,
  value: customProfileField.value || '',
  appliedFrom: customProfileField.appliedFrom || null,
})

function CustomProfileFieldsEditor({
  person: personKey,
  organization: organizationKey,
  close,
}: Props): Node {
  const person = useFragment(personFragment, personKey)
  const organization = useFragment(organizationFragment, organizationKey)
  const customProfileFields = person.customSettings.customProfileFields

  const fieldRenderer = (item, index) => (
    <Field
      key={item.id}
      as={WYSIWYGTextareaWithFeatureFlag}
      fallbackComponent={Textarea}
      organization={organization}
      name={`customProfileFields.${index}.value`}
      label={item.name}
      value={item.value}
      className="form-control"
    />
  )

  const formik = useFormik<Values>({
    initialValues: {
      customProfileFields: customProfileFields.map(createCustomProfileFieldItem),
    },
    onSubmit: async (values) => {
      const mutationInput = {
        personId: person.id,
        customProfileFields: values.customProfileFields
          .map(({name, value, appliedFrom}) => ({name, value, appliedFrom})),
      }

      await UpdatePersonMutation(mutationInput)

      close()
    },
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldArray name="customProfileFields">
          {
            /* eslint-disable-next-line no-unused-vars */
            (_) => formik.values.customProfileFields.map(fieldRenderer)
          }
        </FieldArray>
        <div className={styles.buttonsContainer}>
          <button
            className="btn btn-primary"
            disabled={formik.isSubmitting}
            type="submit"
          >
            {I18n.t('buttons.save')}
          </button>
          <button
            className="btn btn-secondary"
            disabled={formik.isSubmitting}
            onClick={close}
            type="button"
          >
            {I18n.t('buttons.cancel')}
          </button>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default CustomProfileFieldsEditor
