// @flow
import type {Node} from 'react'
import React from 'react'
import AppLink from 'components/ui/AppLink'
import GFIcon from 'components/ui/icons/GFIcon'

type Props = $ReadOnly<{
  path: string,
}>

function HomeLink({path}: Props): Node {
  return (
    <AppLink
      aria-label="GlassFrog"
      href={path}
      className="side-bar-org-link btn-reset"
    >
      <GFIcon />
    </AppLink>
  )
}

export default HomeLink
