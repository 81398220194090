/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MeetingBanner_circle$ref = any;
type MeetingBanner_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GovernanceMeetingBanner_circle$ref: FragmentReference;
declare export opaque type GovernanceMeetingBanner_circle$fragmentType: GovernanceMeetingBanner_circle$ref;
export type GovernanceMeetingBanner_circle = {|
  +localizedName: string,
  +activeGovernanceMeeting: ?{|
    +$fragmentRefs: MeetingBanner_meeting$ref
  |},
  +$fragmentRefs: MeetingBanner_circle$ref,
  +$refType: GovernanceMeetingBanner_circle$ref,
|};
export type GovernanceMeetingBanner_circle$data = GovernanceMeetingBanner_circle;
export type GovernanceMeetingBanner_circle$key = {
  +$data?: GovernanceMeetingBanner_circle$data,
  +$fragmentRefs: GovernanceMeetingBanner_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GovernanceMeetingBanner_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GovernanceMeeting",
      "kind": "LinkedField",
      "name": "activeGovernanceMeeting",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MeetingBanner_meeting"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MeetingBanner_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '009b808fda8a6823308607f07cd09312';

module.exports = node;
