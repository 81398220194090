// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  AddRoleTemplateMutationResponse as Response,
  AddRoleTemplateInput as Input,
} from './__generated__/AddRoleTemplateMutation.graphql'

const mutation = graphql`
  mutation AddRoleTemplateMutation(
    $input: AddRoleTemplateInput!
  ) {
    addRoleTemplate(input: $input) {
      roleTemplateEdge {
        node {
          ...EditRoleTemplateModalView_roleTemplate
        }
      }
    }
  }
`

const AddRoleTemplateMutation = (
  input: MutationInput<Input>,
  organizationId: string,
): Promise<Response> => {
  const variables = {input}

  const configs = [
    {
      type: 'RANGE_ADD',
      parentID: organizationId,
      connectionInfo: [{
        key: 'RoleTemplatesList_roleTemplates',
        rangeBehavior: 'append',
      }],
      edgeName: 'roleTemplateEdge',
      pathToConnection: ['organization', 'roleTemplates'],
    },
  ]

  return createMutationPromise({mutation, variables, configs})
}

export type {Input, Response}
export default AddRoleTemplateMutation
