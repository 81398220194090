// @flow
let instance = null

class ImportTensionsButtonsRefetcher {
  static get instance(): ImportTensionsButtonsRefetcher {
    if (!instance)
      instance = new ImportTensionsButtonsRefetcher()

    return instance
  }

  refetch: ?() => void

  setup: ((refetch: any) => void) = (refetch: Function) => {
    this.refetch = refetch
  }

  call: (() => void | Promise<void>) = () => {
    if (this.refetch)
      return this.refetch()

    return Promise.resolve()
  }
}

export default ImportTensionsButtonsRefetcher
