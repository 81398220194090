import React from 'react'
import Markdown from 'components/ui/Markdown'
import Thinking from 'components/ai/Thinking'

export const visibleTypes = ['user', 'assistant']

export const getType = (rawMsg) => {
  if (rawMsg.content === '__THINKING__')
    return 'thinking'
  if (rawMsg.role === 'user')
    return 'user'
  if (rawMsg.user_visible)
    return 'assistant'
  if (rawMsg.role === 'system' || rawMsg.role === 'function' || rawMsg.role === 'tool')
    return 'system'
  if (rawMsg.role === 'assistant' && rawMsg.function_call)
    return 'functionCall'
  if (rawMsg.role === 'tool')
    return 'system'
  if (rawMsg.error_message)
    return 'errorMessage'
  return rawMsg.role
}

const transform = (rawMsg) => {
  const fnCall = rawMsg.function_call

  switch (getType(rawMsg)) {
    case 'thinking':
      return {
        cssClass: 'assistant',
        content: <Thinking />,
      }
    case 'system':
      return {
        cssClass: 'system',
        content: `${rawMsg.role}: ${rawMsg.content}`,
      }
    case 'user':
      return {
        cssClass: 'user',
        content: rawMsg.content,
      }
    case 'assistant':
      return {
        cssClass: 'assistant',
        content: <Markdown
          unwrapDisallowed
          disallowedTypes={['p']}
          source={rawMsg.content}
        />,
      }
    case 'functionCall':
      return {
        cssClass: 'system',
        content: `functionCall: ${fnCall.name}( ${JSON.stringify(fnCall.arguments)} )`,
      }
    case 'errorMessage':
      return {
        cssClass: 'assistant',
        content: `errorMessage: ${rawMsg.error_message}`,
      }
    default:
      throw new Error(`Unknown message type: ${getType(rawMsg)} for message: ${JSON.stringify(rawMsg)}`)
  }
}

const renderableMessage = (rawMsg) => {
  const msgType = getType(rawMsg)
  const msg = transform(rawMsg)
  return ({
    id: rawMsg.id,
    type: msgType,
    image: msg.cssClass,
    ...msg,
  })
}

export default renderableMessage
