import $ from 'jquery'
import string from 'underscore.string'

$.extend($.fn, {
  killWhitespace: function() {
    const $this = $(this)

    if ($this.children().size() === 0 && string.isBlank($this.text()))
      $this.text("")

    return this
  }
})
