// @flow

function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', filename)

  document.body?.appendChild(link)
  link.click()
  document.body?.removeChild(link)
}

export default downloadBlob
