/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateAmendmentBody_organization$ref = any;
type EditAmendmentBody_amendment$ref = any;
type Section_amendment$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Amendments_organization$ref: FragmentReference;
declare export opaque type Amendments_organization$fragmentType: Amendments_organization$ref;
export type Amendments_organization = {|
  +databaseId: string,
  +amendments: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +viewerCanUpdate: boolean,
        +$fragmentRefs: Section_amendment$ref & EditAmendmentBody_amendment$ref,
      |}
    |}>
  |},
  +$fragmentRefs: CreateAmendmentBody_organization$ref,
  +$refType: Amendments_organization$ref,
|};
export type Amendments_organization$data = Amendments_organization;
export type Amendments_organization$key = {
  +$data?: Amendments_organization$data,
  +$fragmentRefs: Amendments_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "amendments"
        ]
      }
    ]
  },
  "name": "Amendments_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": "amendments",
      "args": null,
      "concreteType": "AmendmentConnection",
      "kind": "LinkedField",
      "name": "__Amendments_amendments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AmendmentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Amendment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewerCanUpdate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Section_amendment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditAmendmentBody_amendment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateAmendmentBody_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '90d01f4e8e61c51c511a9b76c669745e';

module.exports = node;
