/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Project_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Group_circle$ref: FragmentReference;
declare export opaque type Group_circle$fragmentType: Group_circle$ref;
export type Group_circle = {|
  +$fragmentRefs: Project_circle$ref,
  +$refType: Group_circle$ref,
|};
export type Group_circle$data = Group_circle;
export type Group_circle$key = {
  +$data?: Group_circle$data,
  +$fragmentRefs: Group_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Group_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Project_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6700f82c3da68946c3dd7ca3fcf2c709';

module.exports = node;
