// @flow
import type {Element} from 'react'
import React, {useEffect, useState} from 'react'
import classNames from 'classnames'

import styles from './index.scss'

function OfflineBanner(): null | Element<"div"> {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine)

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  })

  if (isOnline)
    return null

  const bannerClassName = classNames('banner', styles.offlineBanner)

  return (
    <div className={bannerClassName}>
      <div className="banner__content">
        {I18n.t('error.connection_offline')}
      </div>
    </div>
  )
}

export default OfflineBanner
