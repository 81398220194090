// @flow
import type {Node} from 'react'
import React, {Fragment, useEffect, useContext} from 'react'
import {graphql, useFragment} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import {Card, AccordionContext} from 'components/ui/Accordion'
import Section from './Section'
import CreateAmendmentBody from './Section/CreateAmendmentBody'
import type {
  Amendments_organization$key as Organization,
} from './__generated__/Amendments_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  readonly: boolean,
  amendmentFormId: ?string,
  setAmendmentFormId: (?string) => void,
}>

const organizationFragment = graphql`
  fragment Amendments_organization on Organization {
    databaseId

    amendments(first: 100) @connection(key: "Amendments_amendments") {
      edges {
        node {
          id
          viewerCanUpdate

          ...Section_amendment
          ...EditAmendmentBody_amendment
        }
      }
    }

    ...CreateAmendmentBody_organization
  }
`

function Amendments({organization: organizationKey, readonly, amendmentFormId, setAmendmentFormId}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const amendments = liftNodes(organization.amendments)
  const {openIndex} = useContext(AccordionContext)

  useEffect(() => {
    if (openIndex === -1)
      setAmendmentFormId(null)
  }, [openIndex, setAmendmentFormId])

  return (
    <Fragment>
      {amendments.map((amendment) => (
        <Section
          key={amendment.id}
          amendment={amendment}
          disabled={readonly || !amendment.viewerCanUpdate}
        />
      ))}
      {amendmentFormId && (
        <Card
          test-id="create-amendment-form"
          index={amendmentFormId}
          disabled={readonly}
        >
          <CreateAmendmentBody
            organization={organization}
            disabled={readonly}
          />
        </Card>
      )}
    </Fragment>
  )
}

export default Amendments
