/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EditOutput_meeting$ref = any;
type OutputActions_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Output_meeting$ref: FragmentReference;
declare export opaque type Output_meeting$fragmentType: Output_meeting$ref;
export type Output_meeting = {|
  +$fragmentRefs: OutputActions_meeting$ref & EditOutput_meeting$ref,
  +$refType: Output_meeting$ref,
|};
export type Output_meeting$data = Output_meeting;
export type Output_meeting$key = {
  +$data?: Output_meeting$data,
  +$fragmentRefs: Output_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Output_meeting",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputActions_meeting"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditOutput_meeting"
    }
  ],
  "type": "TacticalMeeting",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '539b46a0f0b1e383113946c68200aaf4';

module.exports = node;
