// @flow
import type {Node} from 'react'
import React from 'react'
import {Modal as BootsrapModal} from 'reactstrap'

import Header from './Header'
import Body from './Body'
import Footer from './Footer'

type Props = $ReadOnly<{
  autofocus: boolean,
  closeOnEscape: boolean,
  close: () => void,
  children: Node,
  size: string,
  'test-id': string | null,
  // deprecated
  onOpened?: () => void,
}>

function Modal({
  autofocus,
  closeOnEscape,
  close,
  children,
  size,
  onOpened,
  'test-id': testId,
}: Props): Node {
  return (
    <BootsrapModal
      autoFocus={autofocus}
      backdrop="static"
      backdropClassName="edit-dialog-modal__backdrop"
      className="edit-dialog-modal"
      fade={false}
      isOpen
      keyboard={closeOnEscape}
      labelledBy="modal-title"
      toggle={close}
      size={size}
      onOpened={onOpened}
    >
      <div test-id={testId}>
        {children}
      </div>
    </BootsrapModal>
  )
}

Modal.defaultProps = {
  autofocus: true,
  closeOnEscape: true,
  size: '',
  'test-id': null,
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export default Modal
