// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {Actions, Arrow, Suggestion, SuggestionRow, Suggestions, SuggestionSection}
  from 'components/ui/GuideBox'
import {useToggle} from 'utils/hooks'
import SetupModeButton from '../../SetupModeButton'
import AdvancedSettingsSection from '../../AdvancedSettingsSection'
import type {AdvancedSettingsV4_organization as Organization}
  from './__generated__/AdvancedSettingsV4_organization.graphql'

import styles from '../../AdvancedSettingsSection/index.scss'

type Props = $ReadOnly<{
  organization: Organization,
}>

function AdvancedSettingsV4({organization}: Props): Node {
  const startOpen = Boolean(window.location.hash.match('advancedOpen'))
  const [isAdvSectionOpen, {toggle: toggleAdvSectionOpen}] = useToggle(startOpen)

  return (
    <AdvancedSettingsSection
      isSectionOpen={isAdvSectionOpen}
      toggleSectionOpen={toggleAdvSectionOpen}
    >
      <SuggestionSection>
        <SuggestionRow className={styles.suggestionRow}>
          <Suggestions>
            <Suggestion
              text={I18n.t('admin.organizations.org_rules.setup_mode.question')}
            />
          </Suggestions>
          <Arrow className={styles.arrow} />
          <Actions>
            <SetupModeButton
              className={styles.actionButton}
              organization={organization}
              color="secondary"
            />
          </Actions>
        </SuggestionRow>
      </SuggestionSection>
    </AdvancedSettingsSection>
  )
}

export default (createFragmentContainer(AdvancedSettingsV4, {
  organization: graphql`
    fragment AdvancedSettingsV4_organization on Organization {
      id
      setupModeEnabled
      
      ...SetupModeButton_organization
    }
  `,
}): RelayFragmentContainer<typeof(AdvancedSettingsV4)>)
