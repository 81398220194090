/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CircleSelector_circles$ref = any;
type WithCurrentRoleSelector_person$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ViewerChecklistFormModal_viewer$ref: FragmentReference;
declare export opaque type ViewerChecklistFormModal_viewer$fragmentType: ViewerChecklistFormModal_viewer$ref;
export type ViewerChecklistFormModal_viewer = {|
  +circles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +supportedRole: ?{|
          +id: string
        |},
        +$fragmentRefs: CircleSelector_circles$ref,
      |}
    |}>
  |},
  +$fragmentRefs: WithCurrentRoleSelector_person$ref,
  +$refType: ViewerChecklistFormModal_viewer$ref,
|};
export type ViewerChecklistFormModal_viewer$data = ViewerChecklistFormModal_viewer;
export type ViewerChecklistFormModal_viewer$key = {
  +$data?: ViewerChecklistFormModal_viewer$data,
  +$fragmentRefs: ViewerChecklistFormModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerChecklistFormModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CircleConnection",
      "kind": "LinkedField",
      "name": "circles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CircleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Circle",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "supportedRole",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CircleSelector_circles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WithCurrentRoleSelector_person"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5145834f67559b0e51fd227a7ad8b4f';

module.exports = node;
