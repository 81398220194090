/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SetupModeBanner_organization$ref: FragmentReference;
declare export opaque type SetupModeBanner_organization$fragmentType: SetupModeBanner_organization$ref;
export type SetupModeBanner_organization = {|
  +id: string,
  +viewerIsAdmin: boolean,
  +setupModeEnabled: boolean,
  +setupModeSuspendedInvitationsCount: number,
  +viewer: {|
    +routes: {|
      +editConstitutionPath: string,
      +setupModeArticleUrl: string,
    |}
  |},
  +$refType: SetupModeBanner_organization$ref,
|};
export type SetupModeBanner_organization$data = SetupModeBanner_organization;
export type SetupModeBanner_organization$key = {
  +$data?: SetupModeBanner_organization$data,
  +$fragmentRefs: SetupModeBanner_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetupModeBanner_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupModeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupModeSuspendedInvitationsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Routes",
          "kind": "LinkedField",
          "name": "routes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "editConstitutionPath",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "setupModeArticleUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd4e17705bb92b2c75e2be0d3e8381a7a';

module.exports = node;
