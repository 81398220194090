// @flow
import {compose, propOr, sort, descend, ascend} from 'ramda'

import latinizeString from './latinizeString'

const sortingDirection = (sortDescend: boolean) => (sortDescend ? descend : ascend)

export default (propName: string, sortDescend: boolean = false, isString: boolean = true): any => sort(
  sortingDirection(sortDescend)(
    isString ? compose(latinizeString, propOr('', propName)) : propOr('', propName),
  ),
)
