/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrganizationContextProviderFragment_organization$ref: FragmentReference;
declare export opaque type OrganizationContextProviderFragment_organization$fragmentType: OrganizationContextProviderFragment_organization$ref;
export type OrganizationContextProviderFragment_organization = {|
  +id: string,
  +databaseId: string,
  +name: string,
  +isWysiwygEnabled: boolean,
  +onRestrictedPlan: boolean,
  +isOnV5: boolean,
  +isOnV4: boolean,
  +isROIEnabled: boolean,
  +isAiEnabled: boolean,
  +showChecklists: boolean,
  +showHistory: boolean,
  +showMetrics: boolean,
  +showNotes: boolean,
  +showPolicies: boolean,
  +showProjects: boolean,
  +checklistsLabel: string,
  +historyLabel: string,
  +metricsLabel: string,
  +notesLabel: string,
  +policiesLabel: string,
  +projectsLabel: string,
  +$refType: OrganizationContextProviderFragment_organization$ref,
|};
export type OrganizationContextProviderFragment_organization$data = OrganizationContextProviderFragment_organization;
export type OrganizationContextProviderFragment_organization$key = {
  +$data?: OrganizationContextProviderFragment_organization$data,
  +$fragmentRefs: OrganizationContextProviderFragment_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "checklists"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "history"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "metrics"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "notes"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "policies"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "projects"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationContextProviderFragment_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWysiwygEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onRestrictedPlan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnV5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnV4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isROIEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAiEnabled",
      "storageKey": null
    },
    {
      "alias": "showChecklists",
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"checklists\")"
    },
    {
      "alias": "showHistory",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"history\")"
    },
    {
      "alias": "showMetrics",
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"metrics\")"
    },
    {
      "alias": "showNotes",
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"notes\")"
    },
    {
      "alias": "showPolicies",
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"policies\")"
    },
    {
      "alias": "showProjects",
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"projects\")"
    },
    {
      "alias": "checklistsLabel",
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"checklists\")"
    },
    {
      "alias": "historyLabel",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"history\")"
    },
    {
      "alias": "metricsLabel",
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"metrics\")"
    },
    {
      "alias": "notesLabel",
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"notes\")"
    },
    {
      "alias": "policiesLabel",
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"policies\")"
    },
    {
      "alias": "projectsLabel",
      "args": (v5/*: any*/),
      "kind": "ScalarField",
      "name": "termFor",
      "storageKey": "termFor(name:\"projects\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '44711628ad5df8f905e88152e630f5bd';

module.exports = node;
