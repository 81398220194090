// @flow
import type {Node} from 'react'
import React from 'react'

import Add from 'components/ui/icons/md/Add'
import ProposalEdit from 'components/ui/icons/fa/ProposalEdit'
import {addButton} from './index.scss'

type Props = $ReadOnly<{
  itemName: string,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  title?: string,
  byProposal: ?boolean,
}>

function AddButton({itemName, onClick, title, byProposal}: Props): Node {
  return (
    <button
      test-id="add-item-button"
      className={addButton}
      onClick={onClick}
      title={title || I18n.t('org_nav.shared.add_item', {item_name: itemName})}
      type="button"
    >
      {byProposal ? <ProposalEdit /> : <Add />}
    </button>
  )
}

AddButton.defaultProps = {
  byProposal: false,
}

export default AddButton
