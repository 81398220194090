// @flow
import type {Node} from 'react'
import React from 'react'
import useGroupedMetrics from 'components/metrics/utils/useGroupedMetrics'
import FrequencySubsection from './FrequencySubsection'
import Item from './MetricItem'
import type {AbstractMetric} from './types'
import Section from '../Section'
import ReorderableFrequencySubsection from './ReorderableFrequencySubsection'

type Props<T> = {|
  items: $ReadOnlyArray<T>,
  renderItem: T => Node,
  hideIfEmpty: boolean,
  reorderable: boolean,
  onReorder?: (string, number, Array<T>) => Promise<any>,
|}

function MetricsSection<T: AbstractMetric>({
  items,
  renderItem,
  hideIfEmpty,
  reorderable,
  onReorder,
}: Props<T>): null | Node {
  const groupedMetrics = useGroupedMetrics(items)

  if (items.length === 0 && hideIfEmpty)
    return null

  return (
    <Section>
      {groupedMetrics.map(({frequency, metrics}) => (reorderable && onReorder
        ? (
          <ReorderableFrequencySubsection
            key={frequency.id}
            frequency={frequency.name}
            items={metrics}
            renderItem={renderItem}
            onReorder={onReorder}
          />
        )
        : (
          <FrequencySubsection
            key={frequency.id}
            frequency={frequency.name}
            items={metrics}
            renderItem={renderItem}
          />
        )))}
    </Section>
  )
}

MetricsSection.defaultProps = {
  hideIfEmpty: false,
  reorderable: false,
}

export {Item}
export default MetricsSection
