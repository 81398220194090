// @flow
import type {Element} from 'react'
import React from 'react'
import classnames from 'classnames'

type Props = {|
  className: ?string,
  icon: string,
|}

const iconClassName = (externalClasses: ?string, icon: string) => classnames('fa', `fa-${icon}`, externalClasses)

function FaIcon({className, icon}: Props): Element<"i"> {
  return <i className={iconClassName(className, icon)} />
}

FaIcon.defaultProps = {
  className: null,
}

export default FaIcon
