/**
 * @flow
 * @relayHash a8cc28ce8729a88ab093303ffe93bd89
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganizationContextProviderFragment_organization$ref = any;
type SidebarView_organization$ref = any;
export type SideBarQueryVariables = {|
  orgDatabaseId: string
|};
export type SideBarQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: OrganizationContextProviderFragment_organization$ref & SidebarView_organization$ref
  |}
|};
export type SideBarQuery = {|
  variables: SideBarQueryVariables,
  response: SideBarQueryResponse,
|};
*/


/*
query SideBarQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...OrganizationContextProviderFragment_organization
    ...SidebarView_organization
    id
  }
}

fragment AdminSection_organization on Organization {
  viewerIsSuperUser
  onRestrictedPlan
  viewer {
    routes {
      adminOrganizationPeoplePath
      adminOrganizationGlobalItemsPath
      adminOrganizationHabitsPath
      editAccountPath
      editAdminOrganizationPath
      adminBannerNoticesPath
      adminLessonsPath
      adminOfficeHoursPath
      adminOrganizationsPath
      adminReportsPath
      adminSearchOrganizationPath
      adminSearchPeoplePath
      announcementsPath
      deactivatedAdminOrganizationsPath
      metricsSummaryAdminOrganizationsPath
      newAdminOrganizationPath
      newImpersonationPath
      adminAiContentEditPath
    }
    id
  }
}

fragment Announcements_viewer on Person {
  hasNewAnnouncements
  routes {
    announcementsPath
  }
}

fragment AssignmentItem_assignment on Assignment {
  localizedName
  chartPath
  role {
    isFocus
    isLinked
    id
  }
}

fragment CircleAssignments_assignments on Assignment {
  ...SectionWithAssignments_assignments
}

fragment CircleAssignments_circle on Circle {
  localizedName
  supportedRole {
    chartPath
    id
  }
}

fragment FreshChat_organization on Organization {
  onRestrictedPlan
  viewerIsMember
}

fragment FullSearch_viewer on Person {
  routes {
    searchPath
  }
}

fragment Help_organization on Organization {
  databaseId
  viewer {
    routes {
      coachQAPath
      holacracyHabitsPath
      holacracyResourcesUrl
      knowledgebaseUrl
      whatsNewPath
    }
    id
  }
}

fragment InboxNotifications_viewer on Person {
  unprocessedTensions(first: 100) {
    edges {
      node {
        processed
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment LocaleSwitcher_viewer on Person {
  locale
}

fragment OrgSelect_organization on Organization {
  databaseId
  name
  iconUrl
  resourcePath
}

fragment OrgSelect_viewer on Person {
  id
  organizations {
    edges {
      node {
        databaseId
        name
        iconUrl
        resourcePath
        id
      }
    }
  }
}

fragment OrganizationContextProviderFragment_organization on Organization {
  id
  databaseId
  name
  isWysiwygEnabled
  onRestrictedPlan
  isOnV5
  isOnV4
  isROIEnabled
  isAiEnabled
  showChecklists: featureEnabled(name: "checklists")
  showHistory: featureEnabled(name: "history")
  showMetrics: featureEnabled(name: "metrics")
  showNotes: featureEnabled(name: "notes")
  showPolicies: featureEnabled(name: "policies")
  showProjects: featureEnabled(name: "projects")
  checklistsLabel: termFor(name: "checklists")
  historyLabel: termFor(name: "history")
  metricsLabel: termFor(name: "metrics")
  notesLabel: termFor(name: "notes")
  policiesLabel: termFor(name: "policies")
  projectsLabel: termFor(name: "projects")
}

fragment OrganizationSection_organization on Organization {
  anchorRole {
    chartPath
    id
  }
}

fragment ProposalsNotifications_viewer on Person {
  proposalsForResponse: proposals(reviewStatus: TO_REVIEW) {
    edges {
      node {
        __typename
        id
      }
    }
  }
}

fragment Search_viewer on Person {
  ...FullSearch_viewer
}

fragment SectionWithAssignments_assignments on Assignment {
  id
  localizedName
  ...AssignmentItem_assignment
}

fragment SettingsSection_organization on Organization {
  ...FreshChat_organization
  ...Help_organization
}

fragment SettingsSection_viewer on Person {
  ...Announcements_viewer
}

fragment SidebarView_organization on Organization {
  viewerIsMember
  viewerIsAdmin
  viewer {
    routes {
      homepagePath
    }
    ...LocaleSwitcher_viewer
    ...Search_viewer
    ...UserSection_viewer
    ...SettingsSection_viewer
    ...YourOrganizationSection_viewer
    ...YourProfileAndSettings_viewer
    ...SignoutButton_viewer
    ...OrgSelect_viewer
    id
  }
  ...UpgradeLink_organization
  ...OrganizationSection_organization
  ...YourRolesSection_organization
  ...UserSection_organization
  ...TensionProcessing_organization
  ...SettingsSection_organization
  ...YourOrganizationSection_organization
  ...AdminSection_organization
  ...OrgSelect_organization
}

fragment SignoutButton_viewer on Person {
  routes {
    signoutPath
  }
}

fragment TensionProcessing_organization on Organization {
  id
  databaseId
  governanceEnabled
  isAgendaItemsEnabled
  isGoalsEnabled
  viewerCanAddProposal
  onRestrictedPlan
  viewer {
    id
    roles {
      edges {
        node {
          __typename
          id
        }
      }
    }
    routes {
      actionsPath
      agendaItemsPath
      projectsPath
      proposalsPath
      checklistsPath
      metricsPath
      goalsPath
      inboxPath
      addTensionPath
    }
    ...ProposalsNotifications_viewer
    ...InboxNotifications_viewer
  }
}

fragment UpgradeLink_organization on Organization {
  onRestrictedPlan
  viewerIsAdmin
  viewer {
    routes {
      editAccountPath
    }
    id
  }
}

fragment UserSection_organization on Organization {
  name
  viewerIsMember
  resourcePath
}

fragment UserSection_viewer on Person {
  name
  resourcePath
}

fragment YourOrganizationSection_organization on Organization {
  constitutionLabel
  viewerCanViewConstitution
}

fragment YourOrganizationSection_viewer on Person {
  routes {
    constitutionPath
    membershipListsPath
    printableGovernancePath
    searchProjectsPath
  }
}

fragment YourProfileAndSettings_viewer on Person {
  resourcePath
}

fragment YourRolesSection_organization on Organization {
  viewer {
    assignments {
      edges {
        node {
          role {
            circle {
              id
              localizedName
              ...CircleAssignments_circle
            }
            id
          }
          ...CircleAssignments_assignments
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "checklists"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "history"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "metrics"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "notes"
  }
],
v9 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "policies"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "projects"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourcePath",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iconUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartPath",
  "storageKey": null
},
v15 = [
  (v14/*: any*/),
  (v2/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = [
  (v16/*: any*/),
  (v2/*: any*/)
],
v18 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SideBarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationContextProviderFragment_organization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SidebarView_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SideBarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWysiwygEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV5",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV4",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isROIEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAiEnabled",
            "storageKey": null
          },
          {
            "alias": "showChecklists",
            "args": (v5/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"checklists\")"
          },
          {
            "alias": "showHistory",
            "args": (v6/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"history\")"
          },
          {
            "alias": "showMetrics",
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"metrics\")"
          },
          {
            "alias": "showNotes",
            "args": (v8/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"notes\")"
          },
          {
            "alias": "showPolicies",
            "args": (v9/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"policies\")"
          },
          {
            "alias": "showProjects",
            "args": (v10/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"projects\")"
          },
          {
            "alias": "checklistsLabel",
            "args": (v5/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"checklists\")"
          },
          {
            "alias": "historyLabel",
            "args": (v6/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"history\")"
          },
          {
            "alias": "metricsLabel",
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"metrics\")"
          },
          {
            "alias": "notesLabel",
            "args": (v8/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"notes\")"
          },
          {
            "alias": "policiesLabel",
            "args": (v9/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"policies\")"
          },
          {
            "alias": "projectsLabel",
            "args": (v10/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"projects\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsMember",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Routes",
                "kind": "LinkedField",
                "name": "routes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "homepagePath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "announcementsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "constitutionPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "membershipListsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "printableGovernancePath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchProjectsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signoutPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editAccountPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actionsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agendaItemsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "proposalsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checklistsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metricsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inboxPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addTensionPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coachQAPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "holacracyHabitsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "holacracyResourcesUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "knowledgebaseUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whatsNewPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminOrganizationPeoplePath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminOrganizationGlobalItemsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminOrganizationHabitsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editAdminOrganizationPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminBannerNoticesPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminLessonsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminOfficeHoursPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminOrganizationsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminReportsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminSearchOrganizationPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminSearchPeoplePath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deactivatedAdminOrganizationsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metricsSummaryAdminOrganizationsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newAdminOrganizationPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newImpersonationPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminAiContentEditPath",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locale",
                "storageKey": null
              },
              (v4/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNewAnnouncements",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConnection",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v12/*: any*/),
                          (v11/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentConnection",
                "kind": "LinkedField",
                "name": "assignments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Assignment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Circle",
                                "kind": "LinkedField",
                                "name": "circle",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "supportedRole",
                                    "plural": false,
                                    "selections": (v15/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isFocus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isLinked",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "proposalsForResponse",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "reviewStatus",
                    "value": "TO_REVIEW"
                  }
                ],
                "concreteType": "ProposalConnection",
                "kind": "LinkedField",
                "name": "proposals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProposalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Proposal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "proposals(reviewStatus:\"TO_REVIEW\")"
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "TensionConnection",
                "kind": "LinkedField",
                "name": "unprocessedTensions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TensionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tension",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processed",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "unprocessedTensions(first:100)"
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "InboxNotifications_unprocessedTensions",
                "kind": "LinkedHandle",
                "name": "unprocessedTensions"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "anchorRole",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "governanceEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAgendaItemsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGoalsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanAddProposal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "constitutionLabel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanViewConstitution",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsSuperUser",
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a8cc28ce8729a88ab093303ffe93bd89",
    "metadata": {},
    "name": "SideBarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f1da81b5e4826dad2a10f50637dae8c';

module.exports = node;
