// @flow
import type {Node} from 'react'
import React, {Component} from 'react'

import type {Option, Value} from 'components/ui/SortDropdown'
import SortDropdownComponent from 'components/ui/SortDropdown'

type Props = {
  currentItem: Value,
  items: Value[],
  onUpdateSortFn: Function & string,
}

type State = {
  currentItem: Value,
}

const itemToOption = (item: string) => ({
  value: item,
  label: I18n.t(`projects.my_projects.sort_menu.${item}`),
})

class SortDropdown extends Component<Props, State> {
  changeCurrentItem: ((option: Option) => void) = (option: Option) => {
    this.props.onUpdateSortFn(option.value)
  }

  // eslint-disable-next-line react/no-arrow-function-lifecycle
  render: (() => Node) = () => (
    <SortDropdownComponent
      onSelect={this.changeCurrentItem}
      options={this.props.items.map(itemToOption)}
      selectedValue={this.props.currentItem}
    />
  )
}

export default SortDropdown
