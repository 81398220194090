/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleAndPersonSelector_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectOwnerSelector_circle$ref: FragmentReference;
declare export opaque type ProjectOwnerSelector_circle$fragmentType: ProjectOwnerSelector_circle$ref;
export type ProjectOwnerSelector_circle = {|
  +$fragmentRefs: RoleAndPersonSelector_circle$ref,
  +$refType: ProjectOwnerSelector_circle$ref,
|};
export type ProjectOwnerSelector_circle$data = ProjectOwnerSelector_circle;
export type ProjectOwnerSelector_circle$key = {
  +$data?: ProjectOwnerSelector_circle$data,
  +$fragmentRefs: ProjectOwnerSelector_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectOwnerSelector_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RoleAndPersonSelector_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7737a5b8566357061d1db1a0df98eac4';

module.exports = node;
