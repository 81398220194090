// @flow
import type {Node} from 'react'
import React from 'react'
import {useLazyLoadQuery, graphql} from 'react-relay'

import {compareFunction} from 'utils/MiscUtils/latinizeString'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import DeleteMetricMutation from 'mutations/Metrics/DeleteMetricMutation'
import UpdateMetricMutation, {type Response} from 'mutations/Metrics/UpdateMetricMutation'
import MetricFormModal from 'components/metrics/MetricFormModal'
import type {
  MetricEditModalQuery as Query,
} from './__generated__/MetricEditModalQuery.graphql'

type Props = {
  circleDatabaseId: string,
  metricId: string,
  close: () => void,
  onSubmit: Response => void,
  orgDatabaseId: string,
}

const editModalQuery = graphql`
  query MetricEditModalQuery(
    $orgDatabaseId: String!,
    $circleDatabaseId: String!,
    $metricId: ID!
  ) {
    organization(databaseId: $orgDatabaseId) {
      circle(databaseId: $circleDatabaseId) {
        supportedRole {
          id
          localizedName

          parentRole {
            __typename
          }
        }

        roles {
          edges {
            node {
              id
              localizedName
            }
          }
        }
      }
      
      ...MetricFormModal_organization
    }

    metric: node(id: $metricId) {
      ... on Metric {
        description
        frequency
        link
        privateToCircle
        
        customFrequency {
          id
          name
          universalUnits
        }

        role {
          id
        }
      }
    }
  }
`

function MetricEditModal({
  circleDatabaseId,
  metricId,
  close,
  onSubmit,
  orgDatabaseId,
}: Props): null | Node {
  const {organization, metric} = useLazyLoadQuery<Query>(editModalQuery, {
    orgDatabaseId,
    circleDatabaseId,
    metricId,
  })

  const circle = organization?.circle

  if (!circle || !metric || !organization)
    return null

  const initialValues = {
    description: metric.description || '',
    roleId: metric.role?.id,
    frequency: metric.frequency,
    customFrequency: metric.customFrequency,
    link: metric.link,
    privateToCircle: metric.privateToCircle,
  }

  const submit = async (values) => {
    const response = await UpdateMetricMutation({
      ...values,
      metricId,
    })

    onSubmit(response)

    return response
  }

  const deleteMetric = async () => {
    /* eslint-disable-next-line no-alert */
    if (!window.confirm(I18n.t('metrics.delete_item_confirmation')))
      return

    await DeleteMetricMutation({metricId})
    close()
  }

  const roles = [
    circle.supportedRole?.parentRole ? circle.supportedRole : null,
    ...liftNodes(circle.roles),
  ]
    .filter(Boolean)
    .sort((roleA, roleB) => compareFunction(roleA.localizedName, roleB.localizedName))

  return (
    <MetricFormModal
      initialValues={initialValues}
      submit={submit}
      organization={organization}
      close={close}
      roles={roles}
      modalTitle={I18n.t('metrics.edit_metric')}
      renderLeftFooterButton={(isSubmitting) => (
        <button
          className="btn btn-secondary btn-lg"
          disabled={isSubmitting}
          onClick={deleteMetric}
          type="button"
        >
          {I18n.t('react.components.edit_dialog.delete')}
        </button>
      )}
    />
  )
}

MetricEditModal.defaultProps = {
  onSubmit: () => {},
}

export default MetricEditModal
