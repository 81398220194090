// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import Modal from 'components/ui/Modal'
import type {
  TensionProcessModal_tension as Tension,
} from './__generated__/TensionProcessModal_tension.graphql'
import TensionGuide from './TensionGuide'
import TensionInfo from '../../../TensionInfo'
import styles from './index.scss'

type ProcessModalType = 'Action' | 'Project' | 'AgendaTactical' | 'AgendaGovernance' | 'Proposal'

type Props = $ReadOnly<{
  isOpen: boolean,
  onClose: () => void,
  tension: Tension,
  openModal: ({tensionDatabaseId: string, processModal: ProcessModalType}) => Promise<void>,
}>

function TensionProcessModal({isOpen, onClose, tension, openModal}: Props): Node {
  return (
    isOpen && (
      <Modal
        close={onClose}
        size="lg"
      >
        <Modal.Header
          close={onClose}
          title={I18n.t('tensions.react.process_tension')}
        />
        <Modal.Body>
          <TensionInfo
            marginBottom="MEDIUM"
            tension={tension}
          />
          <div className={styles.whatNeed}>
            {I18n.t('tensions.tension_processing.modal_title')}
          </div>
          <TensionGuide
            openModal={openModal}
            close={onClose}
            tension={tension}
            viewer={tension.organization.viewer}
          />
        </Modal.Body>
      </Modal>
    )
  )
}

export default (createFragmentContainer(TensionProcessModal, {
  tension: graphql`
    fragment TensionProcessModal_tension on Tension {
      ...TensionGuide_tension
      ...TensionInfo_tension
      
      organization {
        viewer {
          ...TensionGuide_viewer
        }
      }
    }
  `,
}): RelayFragmentContainer<typeof(TensionProcessModal)>)
