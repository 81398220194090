// @flow
import type {Node} from 'react'
import React, {useCallback, Fragment} from 'react'
import classNames from 'classnames'
import InputContainer from '../InputContainer'
import Label from '../Label'
import styles from './index.scss'

type ForwardedRef = { current: null | HTMLInputElement, ... } |
  ((null | HTMLInputElement) => mixed)

type DefaultProps = $ReadOnly<{
  className: string,
  hideLabel: boolean,
  onBlur: () => void,
  lpignore: boolean,
  min: string | null,
  step: string | null,
  'test-id': ?string,
  tooltip: string | null,
  type: string | null,
  warning: string | null,
}>

export type Props = $ReadOnly<{
  autocomplete?: string,
  disabled?: boolean,
  label?: string,
  onChange?: (SyntheticInputEvent<>, string) => void,
  tooltip?: string | null,
  type?: string | null,
  placeholder?: string,
  value: ?string,
  containerClassName?: string,
  forwardedRef?: ForwardedRef,
  inputRef?: (HTMLElement | null) => void,
  name?: string,
  className?: string,
  hideLabel?: boolean | string,
  onFocus?: () => void,
  onBlur?: () => void,
  lpignore?: boolean,
  min?: string | null,
  step?: string | null,
  'test-id'?: ?string,
  warning?: string | null,
  renderBelowInput?: () => Node,
}>

function Input({
  autocomplete,
  className,
  disabled,
  forwardedRef,
  label,
  hideLabel,
  lpignore,
  onChange,
  min,
  onBlur,
  onFocus,
  name,
  placeholder,
  step,
  'test-id': testId,
  tooltip,
  type,
  value,
  warning,
  containerClassName,
  renderBelowInput = () => null,
  inputRef,
}: Props) {
  const handleChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    if (onChange)
      onChange(event, newValue)
  }, [onChange])

  const isValid = warning === null

  const inputClassName = classNames(styles.base, className, {
    [styles.warning]: !isValid,
  })

  const optionalProps = {}
  if (typeof autocomplete !== 'undefined')
    optionalProps.autoComplete = autocomplete

  const input = (
    <Fragment>
      <input
        className={inputClassName}
        data-lpignore={lpignore}
        ref={(_ref) => {
          // Call passed setter(it will write the generated _ref
          // to the mutable current field of ref object), so we will be able
          // to have an access to the component ref in the parent component
          if (inputRef)
            inputRef(_ref)

          // Call ref function with this generated _ref,
          // so it will set _ref to the current component and autocomplete
          // component will handle it too
          if (forwardedRef)
            // $FlowFixMe[prop-missing]
            forwardedRef(_ref)

          // Return this ref function to follow consistency
          return forwardedRef
        }}
        disabled={disabled}
        min={min}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        step={step}
        test-id={testId}
        type={type}
        value={value || ''} // This is to mitigate the warning about nulls
        {...optionalProps}
      />
      {warning ? <div className={styles.errorMessage}>{warning}</div> : null}
    </Fragment>
  )

  return (
    <InputContainer className={containerClassName}>
      {hideLabel
        ? input
        : (
          <Label
            label={label || ''}
            tooltip={tooltip}
            input={input}
            renderBelowInput={renderBelowInput}
          />
        )
      }
    </InputContainer>
  )
}

Input.defaultProps = {
  className: '',
  hideLabel: false,
  autocomplete: 'on',
  onBlur: () => {},
  lpignore: true,
  min: null,
  step: null,
  'test-id': null,
  tooltip: null,
  type: null,
  warning: null,
}

export default (React.forwardRef<React$Config<Props, DefaultProps>, HTMLInputElement>(
  (props, ref) => <Input {...props} forwardedRef={ref} />,
): React$AbstractComponent<React$Config<Props, DefaultProps>, HTMLInputElement>)
