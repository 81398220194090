import React, {Component} from 'react'
import {path, uniq, propOr, addIndex, map} from 'ramda'
import PropTypes from 'prop-types'
import utils from 'utils/MiscUtils'
import classNames from 'classnames'
import styles from './index.scss'

const isCurrentStepCompleted = (props) => (
  path(['currentStep', 'attributes', 'completed-at'], props.currentInfo) != null
)

class ImportStepComponent extends Component {
  static getDerivedStateFromProps(props, state) {
    if (state.wasJustStarted && isCurrentStepCompleted(props)) {
      utils.info(`Step ${props.stepName} is complete!`)
      return {
        ...state,
        wasJustStarted: false,
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = this.getInitialState()
  }

  getInitialState() { return {wasJustStarted: false} }

  isCurrentStep = () => {
    const curStep = path(['currentStep', 'attributes', 'step'], this.props.currentInfo)
    return curStep === this.props.stepName
  }

  isCurrentStepCompleted = () => (
    isCurrentStepCompleted(this.props)
  )

  isUnderway = () => (
    this.isCurrentStep() && !this.isCurrentStepCompleted()
  )

  isAllowedStep = () => {
    const allowedSteps = (this.props.currentInfo.allowedSteps || [])
    return allowedSteps.indexOf(this.props.stepName) >= 0
  }

  isDisabled = () => (
    this.isUnderway() || this.state.wasJustStarted || !this.isAllowedStep()
  )

  shouldShowSpinner = () => (
    this.isUnderway() || this.state.wasJustStarted
  )

  confirmAction = () => (
    // eslint-disable-next-line no-alert
    this.props.confirm ? window.confirm(this.props.confirm) : true
  )

  handleClick = (event) => {
    event.preventDefault()
    if (this.isDisabled())
      return
    if (!this.confirmAction())
      return
    this.setState({wasJustStarted: true, isEnabled: false})
    this.props.parent.replayStep(this.props.stepName)
  }

  render() {
    const messages = path(['currentInfo', 'messages', this.props.stepName], this.props) || {}
    const errors = uniq(propOr([], 'errors')(messages))
    const warnings = uniq(propOr([], 'warnings')(messages))

    return (
      <div className={styles.step}>
        <div>
          <div className={styles.title}>
            {this.props.title}
          </div>
          <div className={styles.caption}>
            {this.props.caption}
          </div>
          <ul className={styles.errors}>
            {errors.length > 0 ? <li><strong>{'Errors:'}</strong></li> : ''}
            {addIndex(map)((message, i) => <li key={i}>{message.description}</li>, errors)}
          </ul>
          <ul className={styles.warnings}>
            {warnings.length > 0 ? <li><strong>{'Warnings:'}</strong></li> : ''}
            {addIndex(map)((message, i) => <li key={i}>{message.description}</li>, warnings)}
          </ul>
        </div>
        <div>
          <button
            className={classNames(this.props.primaryClass, 'btn', 'btn-primary')}
            onClick={this.handleClick}
            disabled={this.isDisabled()}
            type="button"
          >
            {this.props.buttonText}
            { this.shouldShowSpinner() ? '...' : '' }
          </button>
        </div>
      </div>
    )
  }
}

ImportStepComponent.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  primaryClass: PropTypes.string.isRequired,
  currentInfo: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
  confirm: PropTypes.string,
}

export default ImportStepComponent
