// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {useFragment, graphql} from 'react-relay'

import NoItemsMessage from 'components/ui/NoItemsMessage'
import InlineMarkdown from 'components/ui/InlineMarkdown'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import styles from './index.scss'

import type {
  CustomProfileFieldsList_person$key as PersonKey,
} from './__generated__/CustomProfileFieldsList_person.graphql'

import type {
  CustomProfileFieldsList_organization$key as OrganizationKey,
} from './__generated__/CustomProfileFieldsList_organization.graphql'

type Props = $ReadOnly<{
  person: PersonKey,
  organization: OrganizationKey,
  openEditor: ?() => void,
}>

const personFragment = graphql`
  fragment CustomProfileFieldsList_person on Person {
    customSettings(scope: ORGANIZATION) {
      customProfileFields {
        name
        value
      }
    }
  }
`

const organizationFragment = graphql`
  fragment CustomProfileFieldsList_organization on Organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
  }
`

function CustomProfileFieldsList({
  person: personKey,
  organization: organizationKey,
  openEditor,
}: Props): Node {
  const person = useFragment(personFragment, personKey)
  const organization = useFragment(organizationFragment, organizationKey)
  const customProfileFields = person.customSettings.customProfileFields

  const customProfileFieldsToShow = openEditor
    ? customProfileFields
    : customProfileFields.filter((field) => field.value && field.value.length > 0)

  if (!customProfileFieldsToShow.length)
    return <NoItemsMessage message={I18n.t('people.about.no_items')} />

  const renderCustomProfileField = (customProfileField) => (
    <Fragment>
      <p className={styles.title}><b>{customProfileField.name}</b></p>
      {!customProfileField.value
        ? (
          <p className={styles.emptyValue}>{I18n.t('people.about.empty')}</p>
        )
        : (
          <WYSIWYGViewerWithFeatureFlag
            value={customProfileField.value}
            organization={organization}
            fallbackProps={{
              text: customProfileField.value,
            }}
            fallbackComponent={InlineMarkdown}
          />
        )
      }
    </Fragment>
  )

  return (
    <Fragment>
      <ul className={styles.list}>
        {customProfileFieldsToShow.map((customProfileField) => (
          <li
            key={customProfileField.name}
            test-id={`custom-profile-field-${customProfileField.name}`}
            className={styles.item}
          >
            {renderCustomProfileField(customProfileField)}
          </li>
        ))}
      </ul>
      {openEditor && (
        <button
          className="btn btn-secondary"
          onClick={openEditor}
          type="button"
        >
          {I18n.t('buttons.edit')}
        </button>
      )}
    </Fragment>
  )
}

export default CustomProfileFieldsList
