import _ from 'underscore'
import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'
import MeetingMixin from './mixins/MeetingMixin'

const GovernanceMeeting = GlassFrogModel.extend(_.extend({}, MeetingMixin), {
  resourceName: 'governance_meetings',
  defaults: {},
})

export default GovernanceMeeting
