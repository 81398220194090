// @flow
import type {Node} from 'react'
import React from 'react'

import ArrowUp from 'components/ui/icons/ios/ArrowUp'
import BoltSolid from 'components/ui/icons/fa/BoltSolid'
import TensionBoxContainer from 'components/tensions/ui/TensionBoxContainer'
import IconButton from 'components/ui/Inputs/IconButton'
import styles from './index.scss'

type Props = $ReadOnly<{
  disableSubmit: boolean,
  onSubmit: ?() => void,
  children: Node,
}>

const emptyFn = () => {}

function UpdateTensionInlineBoxContainer({
  disableSubmit,
  onSubmit,
  children,
}: Props): Node {
  return (
    <TensionBoxContainer marginBottom="MEDIUM" test-id="edit-tension-box">
      <div className={styles.container}>
        <div className={styles.boltContainer}>
          <span className={styles.tension}>
            {I18n.t('meetings.react.tension')}
          </span>
          <BoltSolid className={styles.boltIcon} />
        </div>
        <div className={styles.form}>
          {children}
        </div>
        <div className={styles.closeButton}>
          <IconButton
            testId="save-tension"
            disabled={disableSubmit}
            title={I18n.t('shared.save')}
            type={onSubmit ? null : 'submit'}
            onClick={onSubmit || emptyFn}
          >
            <ArrowUp />
          </IconButton>
        </div>
      </div>
    </TensionBoxContainer>
  )
}

UpdateTensionInlineBoxContainer.defaultProps = {
  onSubmit: null,
  disableSubmit: false,
}

export default UpdateTensionInlineBoxContainer
