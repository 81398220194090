// @flow
import type {Element, Node} from 'react'
import React, {Children} from 'react'
import classNames from 'classnames'

import styles from './index.scss'

type Placement = 'top' | 'bottom'

type Props = $ReadOnly<{
  children: Node,
  placement: Placement,
}>

function CollapsibleContainer({children, placement}: Props): null | Element<"div"> {
  if (Children.count(children) === 0)
    return null

  const containerClass = classNames(styles.alerts, {
    [styles.top]: placement === 'top',
    [styles.bottom]: placement === 'bottom',
  })

  return (
    <div className={containerClass}>
      {children}
    </div>
  )
}

export default CollapsibleContainer
