// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'

import type {
  UpdateProjectStatusInput as Input,
  UpdateProjectStatusMutationResponse as Response,
} from './__generated__/UpdateProjectStatusMutation.graphql'

type Variables = $ReadOnly<{
  input: MutationInput<Input>,
}>

const mutation = graphql`
  mutation UpdateProjectStatusMutation($input: UpdateProjectStatusInput!) {
    updateProjectStatus(input: $input) {
      project {
        id
        status
      }
    }
  }
`

function UpdateProjectStatusMutation(input: MutationInput<Input>): Promise<Response> {
  const variables: Variables = {
    input: {
      ...input,
      clientMutationId: null,
    },
  }

  const optimisticResponse = {
    updateProjectStatus: {
      project: {
        id: input.projectId,
        status: input.status,
      },
    },
  }

  return createMutationPromise({mutation, optimisticResponse, variables})
}

export default UpdateProjectStatusMutation
export type {Input}
