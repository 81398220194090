/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Output_meeting$ref = any;
type TensionInfo_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgendaItemGroup_meeting$ref: FragmentReference;
declare export opaque type AgendaItemGroup_meeting$fragmentType: AgendaItemGroup_meeting$ref;
export type AgendaItemGroup_meeting = {|
  +organization: {|
    +viewer: {|
      +id: string
    |},
    +$fragmentRefs: TensionInfo_organization$ref,
  |},
  +$fragmentRefs: Output_meeting$ref,
  +$refType: AgendaItemGroup_meeting$ref,
|};
export type AgendaItemGroup_meeting$data = AgendaItemGroup_meeting;
export type AgendaItemGroup_meeting$key = {
  +$data?: AgendaItemGroup_meeting$data,
  +$fragmentRefs: AgendaItemGroup_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgendaItemGroup_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TensionInfo_organization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Output_meeting"
    }
  ],
  "type": "TacticalMeeting",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5e792e5f20647e0dda06ad2c0888f641';

module.exports = node;
