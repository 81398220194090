// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'
import {parseISO, differenceInMinutes, format} from 'date-fns'
import {flatten, path, map, pipe, filter, uniq} from 'ramda'
import {configWithLocale} from 'utils/dateFnsLocale'
import typedLiftNodes from 'utils/GraphQL/typedLiftNodes'

import NoteCard, {Header} from '../../../../ui/NoteCard'
import styles from './index.scss'
import type {
  MeetingItem_meeting$key as MeetingKey,
} from './__generated__/MeetingItem_meeting.graphql'

type Props = $ReadOnly<{
  meeting: MeetingKey
}>

const extractSections = path(['change', 'summary', 'sections'])
const extractTitle = path(['title'])
const removeEmpty = filter(Boolean)
const getSummariesTitles = pipe(map(extractSections), flatten, map(extractTitle), removeEmpty, uniq)

const meetingFragment = graphql`
  fragment MeetingItem_meeting on Meeting {
    endedAt
    outputCount
    startedAt
    type
    typeLabel
    historyPath

    agendaItems(completed: true) {
      count
    }
    
    ... on AdministrativeChange {
      resourceReferences(first: 10) {
        edges {
          node {
            change {
              summary {
                sections
              }
            }
          }
        }
      }
    }
  }
`

function MeetingItem({meeting: meetingKey}: Props): Node {
  const meeting = useFragment(meetingFragment, meetingKey)

  const startedDate = parseISO(meeting.startedAt)

  const endedDate = meeting.endedAt
    ? parseISO(meeting.endedAt)
    : new Date()

  const durationInMinutes = differenceInMinutes(endedDate, startedDate)

  const itemCount = meeting.agendaItems
    ? meeting.agendaItems.count
    : 0

  const formattedItemCount = I18n.t('meetings.agenda_items', {count: itemCount})

  const inMinutes = I18n.t('meetings.in_minutes', {count: durationInMinutes})

  const outputCount = I18n.t('meetings.total_outputs', {count: meeting.outputCount})

  const tacticalMeetingSummary = `${formattedItemCount} ${inMinutes}, ${outputCount}`
  const governanceUpdateSummary = I18n.t('meetings.proposals_accepted', {count: meeting.outputCount})
  const governanceMeetingSummary = I18n.t('meetings.proposals_accepted', {count: meeting.outputCount})
  const resourceReferences = typedLiftNodes(meeting.resourceReferences)
  const summaries = getSummariesTitles(resourceReferences)

  const outputSummaryMap = {
    GovernanceUpdate: governanceUpdateSummary,
    GovernanceMeeting: governanceMeetingSummary,
    TacticalMeeting: tacticalMeetingSummary,
    AdministrativeChange: summaries.join(', '),
  }

  const outputSummary = outputSummaryMap[meeting.type]

  const url = meeting.historyPath

  return (
    <NoteCard noBorder noPadding>
      <div className={styles.headerWrapper}>
        <Header url={url}>{meeting.typeLabel}</Header>
        <span className={styles.label}>{format(startedDate, 'PP', configWithLocale)}</span>
      </div>
      <div className={styles.summary}>{outputSummary}</div>
    </NoteCard>
  )
}

export default MeetingItem
