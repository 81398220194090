// @flow
import type {Element} from 'react'
import React, {Component} from 'react'
import {evolve, not} from 'ramda'

import LocaleHiddenInput from './LocaleHiddenInput'
import OrganizationHiddenInput from './OrganizationHiddenInput'
import QueryInput from './QueryInput'
import SubmitButton from './SubmitButton'

type Props = {
  searchUrl: string,
  organizationId: string,
  locale: string,
  textFieldContent: string,
}

type State = {
  isSubmittingAllowed: boolean,
}

// This isn't a magic number
// assets/stylesheets/components/_header – there you can find how it's used
// The thing we need is to allow submitting of form when then CSS animation ends
const togglingAnimationDuration = 400

class SearchBox extends Component<Props, State> {
  static defaultProps: { locale: null, textFieldContent: string } = {
    locale: null,
    textFieldContent: '',
  }

  state: State = {
    isSubmittingAllowed: false,
  }

  onHoverChange: (() => TimeoutID) = () => setTimeout(this.toggleSubmittingAllowance, togglingAnimationDuration)

  toggleSubmittingAllowance: (() => void) = () => this.setState(evolve({isSubmittingAllowed: not}))

  render(): Element<"form"> {
    return (
      <form
        action={this.props.searchUrl}
        className="app-search"
      >
        <OrganizationHiddenInput organizationId={this.props.organizationId} />
        <LocaleHiddenInput locale={this.props.locale} />
        <QueryInput textFieldContent={this.props.textFieldContent} />
        <SubmitButton isSubmittingAllowed={this.state.isSubmittingAllowed} />
      </form>
    )
  }
}

export default SearchBox
