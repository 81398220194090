// @flow strict
import {useState, useEffect, useCallback, useMemo} from 'react'
import LocalStorageCell from 'utils/LocalStorageCell'

function usePersistentState<S>(
  initialState: S | () => S,
  storageKey: string,
  validateState: (S) => boolean = () => true,
): [S, ((S => S) | S) => void] {
  const storageCell: LocalStorageCell<S> = useMemo(() => (
    new LocalStorageCell(storageKey)
  ), [storageKey])

  const storageValue = storageCell.get()
  const initialStateToUse = storageValue !== null && validateState(storageValue)
    ? storageValue
    : initialState

  const [state, setState] = useState<S>(initialStateToUse)

  const saveState = useCallback((newState: (S => S) | S) => {
    if (typeof newState === 'function')
      // $FlowFixMe[incompatible-use]
      storageCell.set(newState(state))
    else
      storageCell.set(newState)
  }, [storageCell, state])

  const setAndSaveState = useCallback((newState: (S => S) | S) => {
    saveState(newState)
    setState(newState)
  }, [setState, saveState])

  useEffect(() => {
    saveState(initialStateToUse)
    // We have to execute setAndSaveState only on storageKey update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey])

  const returnState = typeof initialStateToUse === 'function'
    // $FlowExpectedError[incompatible-use]
    ? initialStateToUse()
    : initialStateToUse

  return [returnState, setAndSaveState]
}

export default usePersistentState
