// @flow
import AppRouter from 'utils/AppRouter'
import {type RouterHistory} from 'react-router-dom'

let instance = null

type RolePathArgs = {
  roleDatabaseId: string,
  tabName: ?string,
}

class OrgnavRouter {
  static rolePath: ((RolePathArgs) => string) = ({roleDatabaseId, tabName}: RolePathArgs): string => (
    tabName
      ? `/roles/${roleDatabaseId}/${tabName}`
      : `/roles/${roleDatabaseId}`
  )

  static get instance(): OrgnavRouter {
    if (!instance)
      instance = new OrgnavRouter()

    return instance
  }

  // eslint-disable-next-line class-methods-use-this
  get isReady(): boolean {
    return AppRouter.instance.isReady
  }

  // eslint-disable-next-line class-methods-use-this
  get history(): ?RouterHistory {
    return AppRouter.instance.history
  }

  redirectToRole(roleDatabaseId: string) {
    if (this.history) {
      this.history.push(
        OrgnavRouter.rolePath({roleDatabaseId, tabName: this.tabName}),
      )
    }
  }

  replaceWithRole(roleDatabaseId: string) {
    if (this.history) {
      this.history.replace(
        OrgnavRouter.rolePath({roleDatabaseId, tabName: this.tabName}),
      )
    }
  }

  setRoleTab(tabName: ?string) {
    this.tabName = tabName
  }

  tabName: ?string
}

export default OrgnavRouter
