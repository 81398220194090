// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import RoleTemplatesSectionView from './RoleTemplatesSectionView'
import type {
  RoleTemplatesSectionQueryVariables as Variables,
} from './__generated__/RoleTemplatesSectionQuery.graphql'

export type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

const query = graphql`
  query RoleTemplatesSectionQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...RoleTemplatesSectionView_organization
    }
  }
`

function RoleTemplatesSection({orgDatabaseId}: Props): null | Node {
  const variables: Variables = {orgDatabaseId}

  const {organization} = useLazyLoadQuery(query, variables)

  if (!organization)
    return null

  return (
    <RoleTemplatesSectionView organization={organization} />
  )
}

export default RoleTemplatesSection
