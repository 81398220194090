/**
 * @flow
 * @relayHash 7b1c4a2b90f8d5f18479ece8fdf4ba36
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FlashNotification_notification$ref = any;
export type NotificationPlacement = "BOTTOM" | "TOP";
export type FlashNotificationsQueryVariables = {||};
export type FlashNotificationsQueryResponse = {|
  +__typename: string,
  +localNotifications: ?$ReadOnlyArray<?{|
    +id: string,
    +placement: NotificationPlacement,
    +$fragmentRefs: FlashNotification_notification$ref,
  |}>,
|};
export type FlashNotificationsQuery = {|
  variables: FlashNotificationsQueryVariables,
  response: FlashNotificationsQueryResponse,
|};
*/


/*
query FlashNotificationsQuery {
  __typename
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placement",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlashNotificationsQuery",
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "localNotifications",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FlashNotification_notification"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FlashNotificationsQuery",
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "localNotifications",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "callbackId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7b1c4a2b90f8d5f18479ece8fdf4ba36",
    "metadata": {},
    "name": "FlashNotificationsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7be23214dce183884574ff21dc0c2ec2';

module.exports = node;
