// @flow
import type {Node} from 'react'
import React, {useEffect} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import UpdateRoleTemplateModalView from './EditRoleTemplateModalView'

type Props = $ReadOnly<{
  roleTemplateId: string,
  close: () => void,
}>

const roleTemplateQuery = graphql`
  query EditRoleTemplateModalQuery($roleTemplateId: ID!) {
    roleTemplate: node(id: $roleTemplateId) {
      ... on RoleTemplate {
        organization {
          ...EditRoleTemplateModalView_organization
        }
      
        ...EditRoleTemplateModalView_roleTemplate
      }
    }
  }
`

function EditRoleTemplateModal({
  roleTemplateId,
  close,
}: Props): null | Node {
  const {roleTemplate} = useLazyLoadQuery(roleTemplateQuery, {roleTemplateId})

  useEffect(() => {
    if (!roleTemplate)
      close()
  }, [roleTemplate, close])

  if (!roleTemplate)
    return null

  return (
    <UpdateRoleTemplateModalView
      roleTemplate={roleTemplate}
      organization={roleTemplate.organization}
      close={close}
    />
  )
}

export default EditRoleTemplateModal
