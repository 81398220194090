// @flow
import type {Element} from 'react'
import React from 'react'

import Trashcan from 'components/ui/icons/fa/Trashcan'
import styles from './index.scss'

function ArchiveBackground(): Element<"div"> {
  return (
    <div className={styles.container}>
      <Trashcan className={styles.icon} />
      &nbsp;
      {I18n.t('projects.my_projects.statuses.archived')}
    </div>
  )
}

export default ArchiveBackground
