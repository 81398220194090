// @flow strict

type Controls = $ReadOnly<{
  isSidebarOpen: () => boolean,
  toggleSidebar: () => void,
}>

const defaultControls: Controls = {
  isSidebarOpen: () => true,
  toggleSidebar: () => {},
}

let globalControls: Controls = defaultControls

function setControls(controls: Controls) {
  globalControls = controls
}

function isSidebarOpen(): boolean {
  return globalControls.isSidebarOpen()
}

function toggleSidebar() {
  globalControls.toggleSidebar()
}

export {
  isSidebarOpen,
  toggleSidebar,
  setControls,
}
