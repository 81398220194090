/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectFormModalFragmented_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateProjectModal_circle$ref: FragmentReference;
declare export opaque type CreateProjectModal_circle$fragmentType: CreateProjectModal_circle$ref;
export type CreateProjectModal_circle = {|
  +$fragmentRefs: ProjectFormModalFragmented_circle$ref,
  +$refType: CreateProjectModal_circle$ref,
|};
export type CreateProjectModal_circle$data = CreateProjectModal_circle;
export type CreateProjectModal_circle$key = {
  +$data?: CreateProjectModal_circle$data,
  +$fragmentRefs: CreateProjectModal_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateProjectModal_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectFormModalFragmented_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '86550b7f3eea5a159cc15589c834a169';

module.exports = node;
