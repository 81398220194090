// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import queryString from 'query-string'
import {includes, pluck} from 'ramda'

import SortDropdown, {type Option} from 'components/ui/SortDropdown'
import AppRouter from 'utils/AppRouter'

type Props = $ReadOnly<{
  filterParam: string
}>

function AdminFilterPeopleDropdown({filterParam}: Props): Node {
  const options = useMemo(() => (
    [{
      value: 'pending',
      label: I18n.t('admin.people.pending_first'),
    }, {
      value: 'active',
      label: I18n.t('admin.people.active_first'),
    }]
  ), [])

  const onSelect = (option: Option) => {
    const pathWithParam = `${window.location.pathname}?${filterParam}=${option.value}`

    AppRouter.instance.redirectTo(pathWithParam)
  }

  const queryValue = queryString.parse(window.location.search)[filterParam]
  const selected = useMemo(
    () => (queryValue && includes(queryValue, pluck('value', options)) ? queryValue : options[0].value),
    [options, queryValue],
  )

  return (
    <SortDropdown
      sortByLabel={I18n.t('admin.people.show')}
      onSelect={onSelect}
      options={options}
      selectedValue={selected}
    />
  )
}

AdminFilterPeopleDropdown.defaultProps = {
  filterParam: 'sort',
}

export default AdminFilterPeopleDropdown
