/**
 * @flow
 * @relayHash c280af24b0a00475c58c9882746468e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganizationContextProviderFragment_organization$ref = any;
export type OrganizationContextProviderQueryVariables = {|
  orgDatabaseId: string
|};
export type OrganizationContextProviderQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: OrganizationContextProviderFragment_organization$ref
  |}
|};
export type OrganizationContextProviderQuery = {|
  variables: OrganizationContextProviderQueryVariables,
  response: OrganizationContextProviderQueryResponse,
|};
*/


/*
query OrganizationContextProviderQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...OrganizationContextProviderFragment_organization
    id
  }
}

fragment OrganizationContextProviderFragment_organization on Organization {
  id
  databaseId
  name
  isWysiwygEnabled
  onRestrictedPlan
  isOnV5
  isOnV4
  isROIEnabled
  isAiEnabled
  showChecklists: featureEnabled(name: "checklists")
  showHistory: featureEnabled(name: "history")
  showMetrics: featureEnabled(name: "metrics")
  showNotes: featureEnabled(name: "notes")
  showPolicies: featureEnabled(name: "policies")
  showProjects: featureEnabled(name: "projects")
  checklistsLabel: termFor(name: "checklists")
  historyLabel: termFor(name: "history")
  metricsLabel: termFor(name: "metrics")
  notesLabel: termFor(name: "notes")
  policiesLabel: termFor(name: "policies")
  projectsLabel: termFor(name: "projects")
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "checklists"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "history"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "metrics"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "notes"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "policies"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "projects"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationContextProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationContextProviderFragment_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationContextProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWysiwygEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV5",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnV4",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isROIEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAiEnabled",
            "storageKey": null
          },
          {
            "alias": "showChecklists",
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"checklists\")"
          },
          {
            "alias": "showHistory",
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"history\")"
          },
          {
            "alias": "showMetrics",
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"metrics\")"
          },
          {
            "alias": "showNotes",
            "args": (v5/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"notes\")"
          },
          {
            "alias": "showPolicies",
            "args": (v6/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"policies\")"
          },
          {
            "alias": "showProjects",
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"projects\")"
          },
          {
            "alias": "checklistsLabel",
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"checklists\")"
          },
          {
            "alias": "historyLabel",
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"history\")"
          },
          {
            "alias": "metricsLabel",
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"metrics\")"
          },
          {
            "alias": "notesLabel",
            "args": (v5/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"notes\")"
          },
          {
            "alias": "policiesLabel",
            "args": (v6/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"policies\")"
          },
          {
            "alias": "projectsLabel",
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "termFor",
            "storageKey": "termFor(name:\"projects\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c280af24b0a00475c58c9882746468e5",
    "metadata": {},
    "name": "OrganizationContextProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f76c2ca051131897e80edad5a956c08';

module.exports = node;
