/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectOwnerSelector_circle$ref = any;
type RoleAccountabilitySelector_role$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectEditForm_circle$ref: FragmentReference;
declare export opaque type ProjectEditForm_circle$fragmentType: ProjectEditForm_circle$ref;
export type ProjectEditForm_circle = {|
  +roles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: RoleAccountabilitySelector_role$ref,
      |}
    |}>
  |},
  +$fragmentRefs: ProjectOwnerSelector_circle$ref,
  +$refType: ProjectEditForm_circle$ref,
|};
export type ProjectEditForm_circle$data = ProjectEditForm_circle;
export type ProjectEditForm_circle$key = {
  +$data?: ProjectEditForm_circle$data,
  +$fragmentRefs: ProjectEditForm_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectEditForm_circle",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeFocusRoles",
          "value": true
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleAccountabilitySelector_role"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(includeFocusRoles:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectOwnerSelector_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c4bc77eac5b4d07ce6dee8a1f296f25f';

module.exports = node;
