/**
 * @flow
 * @relayHash ad2a3da339ea9198fbfeb5775ba0135a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AboutTab_organization$ref = any;
type AboutTab_person$ref = any;
export type AboutQueryVariables = {|
  orgDatabaseId: string,
  personDatabaseId: string,
|};
export type AboutQueryResponse = {|
  +organization: ?{|
    +person: ?{|
      +$fragmentRefs: AboutTab_person$ref
    |},
    +$fragmentRefs: AboutTab_organization$ref,
  |}
|};
export type AboutQuery = {|
  variables: AboutQueryVariables,
  response: AboutQueryResponse,
|};
*/


/*
query AboutQuery(
  $orgDatabaseId: String!
  $personDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    person(databaseId: $personDatabaseId) {
      ...AboutTab_person
      id
    }
    ...AboutTab_organization
    id
  }
}

fragment AboutTab_organization on Organization {
  name
  isPubliclyVisible
  ...CustomProfileFieldsEditor_organization
  ...CustomProfileFieldsList_organization
}

fragment AboutTab_person on Person {
  viewerCanUpdateCustomSettings
  ...CustomProfileFieldsEditor_person
  ...CustomProfileFieldsList_person
}

fragment CustomProfileFieldsEditor_organization on Organization {
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment CustomProfileFieldsEditor_person on Person {
  id
  customSettings(scope: ORGANIZATION) {
    customProfileFields {
      name
      value
      appliedFrom
    }
  }
}

fragment CustomProfileFieldsList_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment CustomProfileFieldsList_person on Person {
  customSettings(scope: ORGANIZATION) {
    customProfileFields {
      name
      value
    }
  }
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "personDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AboutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AboutTab_person"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AboutTab_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AboutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdateCustomSettings",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "scope",
                    "value": "ORGANIZATION"
                  }
                ],
                "concreteType": "CustomSettings",
                "kind": "LinkedField",
                "name": "customSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomProfileFieldMapping",
                    "kind": "LinkedField",
                    "name": "customProfileFields",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appliedFrom",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "customSettings(scope:\"ORGANIZATION\")"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPubliclyVisible",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWysiwygEnabled",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ad2a3da339ea9198fbfeb5775ba0135a",
    "metadata": {},
    "name": "AboutQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5afbba92e9e818330eb214abb3fff54b';

module.exports = node;
