/**
 * @flow
 * @relayHash a854c60de051856b7eb17421b6d33aae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteMetricInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  metricId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteMetricMutationVariables = {|
  input: DeleteMetricInput
|};
export type DeleteMetricMutationResponse = {|
  +deleteMetric: ?{|
    +deletedMetricId: string
  |}
|};
export type DeleteMetricMutation = {|
  variables: DeleteMetricMutationVariables,
  response: DeleteMetricMutationResponse,
|};
*/


/*
mutation DeleteMetricMutation(
  $input: DeleteMetricInput!
) {
  deleteMetric(input: $input) {
    deletedMetricId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteMetricPayload",
    "kind": "LinkedField",
    "name": "deleteMetric",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedMetricId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMetricMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMetricMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a854c60de051856b7eb17421b6d33aae",
    "metadata": {},
    "name": "DeleteMetricMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65e618db4cfedc608977295b12ec34ae';

module.exports = node;
