import {commitLocalUpdate} from 'react-relay'
import environment from 'environment'
import currentOrgGid from './currentOrgGid'

export function enableWYSIWYG() {
  commitLocalUpdate(environment, (store) => {
    const organizationRecord = store.get(currentOrgGid())
    if (organizationRecord)
      organizationRecord.setValue(true, 'isWysiwygEnabled')
  })
}

export function disableWYSIWYG() {
  commitLocalUpdate(environment, (store) => {
    const organizationRecord = store.get(currentOrgGid())
    if (organizationRecord)
      organizationRecord.setValue(false, 'isWysiwygEnabled')
  })
}
