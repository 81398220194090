// @flow
import React, {type Node, Suspense, lazy} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import constants from 'utils/projects/constants'
import flash from 'utils/MiscUtils/flash'
import ModalLoader from 'components/ui/ModalLoader'
import type {
  ActionProcessModal_tension as Tension,
} from './__generated__/ActionProcessModal_tension.graphql'
import type {
  ActionProcessModal_viewer as Viewer,
} from './__generated__/ActionProcessModal_viewer.graphql'

const LazyCreateActionModal = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CreateActionModal" */
  'components/actions/CreateActionModal'
))

const successCreating = async () => flash.notice(I18n.t('tensions.react.process_into.success.action'))

type Props = {
  isOpen: boolean,
  onClose: () => void,
  tension: Tension,
  viewer: Viewer,
}

function ActionProcessModal({isOpen, onClose, tension, viewer}: Props): Node {
  const circle = tension.impactedRole?.circle || tension.circle

  const actionData = {
    note: I18n.t('tensions.react.resolve_tension', {tension_body: tension.body}),
    circleId: circle?.id || null,
    roleId: tension.impactedRole?.id || constants.individualActionId,
    parentGoalOrTarget: null,
  }

  return (
    <Suspense fallback={<ModalLoader />}>
      {isOpen && (
        <LazyCreateActionModal
          initialValues={actionData}
          viewer={viewer}
          close={onClose}
          onSuccess={successCreating}
          tension={tension}
        />
      )}
    </Suspense>
  )
}

export default (createFragmentContainer(ActionProcessModal, {
  tension: graphql`
    fragment ActionProcessModal_tension on Tension {
      body

      circle {
        id
      }

      impactedRole {
        id

        circle {
          id
        }
      }
      
      ...CreateActionModal_tension
    }
  `,
  viewer: graphql`
    fragment ActionProcessModal_viewer on Person {
      ...CreateActionModal_viewer
    }
  `,
}): RelayFragmentContainer<typeof(ActionProcessModal)>)
