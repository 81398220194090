// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {Draggable} from 'react-beautiful-dnd'
import {type OpenEditor} from 'components/projects/Project'

import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import ProjectCard from 'components/projects/ProjectCard'
import styles from './index.scss'
import type {
  DraggableProjectCard_project as Project,
} from './__generated__/DraggableProjectCard_project.graphql'
import type {
  DraggableProjectCard_circle as Circle,
} from './__generated__/DraggableProjectCard_circle.graphql'

type Props = $ReadOnly<{
  index: number,
  project: Project,
  openEditor: OpenEditor,
  circle: null | Circle,
  hideAvatars: ?boolean,
  hideSource: ?boolean,
}>

function DraggableProjectCard({project, index, openEditor, circle, hideAvatars, hideSource}: Props): Node {
  const onClick = useCallback(() => {
    if (!project.viewerCanUpdate)
      return null

    return openEditor(project.id)
  }, [project, openEditor])

  const renderCard = useCallback((provided) => (
    <div
      className={styles.container}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...accessibleOnClickProps(onClick)}
      role="button"
      tabIndex={0}
    >
      <ProjectCard
        project={project}
        circle={circle}
        hideAvatars={hideAvatars}
        hideSource={hideSource}
      />
    </div>
  ), [onClick, project, circle, hideAvatars, hideSource])

  if (!project)
    return null

  return (
    <Draggable
      draggableId={project.id}
      index={index}
      isDragDisabled={!project.viewerCanUpdate}
    >
      {renderCard}
    </Draggable>
  )
}

export default (createFragmentContainer(DraggableProjectCard, {
  project: graphql`
    fragment DraggableProjectCard_project on Project {
      id
      viewerCanUpdate

      ...ProjectCard_project
    }
  `,
  circle: graphql`
    fragment DraggableProjectCard_circle on Circle {
      ...ProjectCard_circle
    }
  `,
}): RelayFragmentContainer<typeof(DraggableProjectCard)>)
