// @flow
import React, {type Node} from 'react'
import {useFragment, graphql} from 'react-relay'
import {useToggle} from 'utils/hooks'
import EditOutput from './EditOutput'
import OutputDescription from './OutputDescription'
import OutputPerson from './OutputPerson'
import OutputActions from './OutputActions'
import OutputOwner from './OutputOwner'
import OutputAdditionalInfo from './OutputAdditionalInfo'
import OutputProjectStatus from './OutputProjectStatus'

import type {
  Output_output$key as OutputKey,
} from './__generated__/Output_output.graphql'
import type {
  Output_meeting$key as MeetingKey,
} from './__generated__/Output_meeting.graphql'

type Props = $ReadOnly<{
  outputKey: OutputKey,
  meetingKey: MeetingKey,
}>

const meetingFragment = graphql`
  fragment Output_meeting on TacticalMeeting {
    ...OutputActions_meeting
    ...EditOutput_meeting
  }
`

/* eslint-disable-next-line no-unused-vars */
const outputFragment = graphql`
  fragment Output_output on TacticalOutput {
    databaseId
    type
    error

    ...OutputAdditionalInfo_output
    ...OutputProjectStatus_output
    ...OutputDescription_output
    ...OutputPerson_output
    ...OutputActions_output
    ...OutputOwner_output
    ...EditOutput_output
  }
`

function Output({outputKey, meetingKey}: Props): Node {
  const [isEditing, {open: startEditing, close: finishEditing}] = useToggle(false)
  const meeting = useFragment(meetingFragment, meetingKey)
  const output = useFragment(outputFragment, outputKey)

  const isTrigger = () => (output.type === 'TRIGGER')
  const isInformation = () => (output.type === 'INFORMATION')
  const isProject = () => (output.type === 'PROJECT')

  const descriptionCellClassName = isInformation()
    ? 'col-lg-11'
    : 'col-lg-4'
  const personCellClassName = isTrigger() || isProject()
    ? 'col-lg-2'
    : 'col-lg-4'

  if (isEditing) {
    return (
      <EditOutput
        meeting={meeting}
        output={output}
        onSuccess={finishEditing}
      />
    )
  }

  return (
    <div className="tactical-item item view action-item flex-column">
      {output.error && (
        <div className="error-container">{output.error}</div>
      )}
      <div className="row w-100">
        <div className={descriptionCellClassName}>
          <OutputDescription output={output} />
        </div>
        {!isInformation() && (
          <div className="col-lg-3">
            <OutputOwner output={output} />
          </div>
        )}
        {!isInformation() && (
          <div className={personCellClassName}>
            <OutputPerson output={output} />
          </div>
        )}
        {isTrigger() && (
          <div className="col-lg-2">
            <OutputAdditionalInfo output={output} />
          </div>
        )}
        {isProject() && (
          <div className="col-lg-2">
            <OutputProjectStatus output={output} />
          </div>
        )}
        <div className="col-lg-1">
          <OutputActions
            startEditing={startEditing}
            output={output}
            meeting={meeting}
          />
        </div>
      </div>
    </div>
  )
}

export default Output
