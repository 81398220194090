// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import GroupProjectsBy, {type GroupBy} from 'components/projects/GroupProjectsBy'
import TacticalMeetingHeader from './../../../ui/TacticalMeetingHeader'

import type {Role, Circle} from './types'

type Props = {|
  role: Role,
  groupBy: GroupBy,
  setGroupBy: (GroupBy) => void,
  openCreator: () => void,
|}

function TacticalMeetingProjectsHeader({role, groupBy, setGroupBy, openCreator}: Props): Node {
  const circle: ?Circle = role.innerCircle || role.circle

  const openAddProjectModal = circle?.viewerCanAddProjects
    ? openCreator
    : null

  const middleSection = useCallback(() => ([
    <GroupProjectsBy
      key="group-by"
      groupBy={groupBy}
      setGroupBy={setGroupBy}
    />,
  ]), [groupBy, setGroupBy])

  return (
    <TacticalMeetingHeader
      addButtonCaption={I18n.t('projects.my_projects.add_project')}
      renderMiddleSection={middleSection}
      openCreator={openAddProjectModal}
    />
  )
}

export default (createFragmentContainer(TacticalMeetingProjectsHeader, {
  role: graphql`
    fragment TacticalMeetingProjectsHeader_role on Role {
      databaseId

      organization {
        projectsGridEnabled
      }

      innerCircle {
        databaseId
        viewerCanAddProjects
      }

      circle {
        databaseId
        viewerCanAddProjects
      }
    }
  `,
}): RelayFragmentContainer<typeof(TacticalMeetingProjectsHeader)>)
