// @flow
import type {Element} from 'react'
import React from 'react'
import Markdown from 'components/ui/Markdown'
import styles from './index.scss'

type Props = {
  text: string,
  bottomLink?: string,
}

function MarkdownTip({text, bottomLink}: Props): Element<"div"> {
  return (
    <div className={styles.markdownText}>
      <Markdown source={text} />
      {bottomLink && (
        <div className={styles.linkContainer}>
          <Markdown source={bottomLink} />
        </div>
      )}
    </div>
  )
}

export default MarkdownTip
