// @flow
import {graphql} from 'react-relay'

import type {ID} from '../types'
import createMutationPromise from '../createMutationPromise'

const mutation = graphql`
  mutation DeleteActionMutation(
    $input: DeleteActionInput!
  ) {
    deleteAction(input: $input) {
      deletedActionId
    }
  }
`

export default (actionId: ID): Promise<mixed> => {
  const variables = {
    input: {actionId},
  }

  const optimisticResponse = {
    deleteAction: {
      deletedActionId: actionId,
    },
  }

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedActionId',
  }]

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse,
  })
}
