// @flow
import type {Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import useToggle from 'utils/hooks/useToggle'

import ExistingIcon from 'components/ui/icons/fa/Existing'
import HammerAndWrenchIcon from 'components/ui/icons/HammerAndWrench'
import HolacracyMarkBlue from 'components/ui/icons/HolacracyMarkBlue'
import ModularAdoptionByCircle from 'components/ui/icons/ModularAdoptionByCircle'
import Sitemap from 'components/ui/icons/fa/Sitemap'
import UniversalIcon from 'components/ui/icons/fa/Universal'

import styles from './index.scss'

const Existing = <span className={styles.icon}><ExistingIcon /></span>
const HammerAndWrench = <span className={styles.icon}><HammerAndWrenchIcon /></span>
const Hierarchy = <span className={styles.icon}><Sitemap /></span>
const Holacracy = <HolacracyMarkBlue />
const ModularAdoption = <ModularAdoptionByCircle />
const Universal = <span className={styles.icon}><UniversalIcon /></span>

type FormattedOptionValueType = 'CUSTOM' | 'EXISTING' | 'HOLACRACY' | 'MODULAR_ADOPTION_BY_CIRCLE' | 'UNIVERSAL'

type FormattedOptionOptionType = {
  label: string,
  value: FormattedOptionValueType,
  description: string,
  premiumCallout?: Node | null,
  footer?: () => Node,
}

type Props = $ReadOnly<{
  isOpen: boolean,
  option: FormattedOptionOptionType,
  selected: boolean,
  children: Node,
}>

const icons = {
  CUSTOM: HammerAndWrench,
  EXISTING: Existing,
  HOLACRACY: Holacracy,
  MODULAR_ADOPTION_BY_CIRCLE: ModularAdoption,
  UNIVERSAL: Universal,
  CENTRALIZED: Hierarchy,
}

function FormattedOption({isOpen, selected, option, children}: Props): Node {
  const [showingMore, {toggle}] = useToggle(false)
  const allowCollapse = selected && !isOpen && option.value === 'UNIVERSAL'
  const isCollapsed = showingMore ? styles.expanded : styles.collapsed
  const showAsCollapsed = allowCollapse ? isCollapsed : ''
  const descriptionClassName = classNames(showAsCollapsed, styles.description)
  const showMoreLessClassName = classNames('btn-link', styles.showMoreLess)
  const premiumCallout = option.premiumCallout

  const labelClassName = classNames(styles.label, {
    [styles.withPremiumCallout]: premiumCallout,
  })

  return (
    <span className={styles.grid}>
      <span className={styles.icon}>{icons[option.value]}</span>
      <span className={labelClassName}>
        {option.label}
        {premiumCallout}
      </span>

      {!premiumCallout && (
        <div
          className={descriptionClassName}
          onClick={(e) => e.stopPropagation() /* prevent section opening on click */}
          role="none"
          test-id="formatted-option-description"
        >
          {children}
          {selected && option.footer && option.footer()}
        </div>
      )}

      {allowCollapse && (
        <button
          className={showMoreLessClassName}
          onClick={() => toggle()}
          type="button"
        >
          {showingMore ? I18n.t('shared.show_less') : I18n.t('shared.show_more')}
        </button>
      )}
    </span>
  )
}

export default FormattedOption
