/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type WYSIWYGViewerWithFeatureFlag_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SectionHeader_organization$ref: FragmentReference;
declare export opaque type SectionHeader_organization$fragmentType: SectionHeader_organization$ref;
export type SectionHeader_organization = {|
  +$fragmentRefs: WYSIWYGViewerWithFeatureFlag_organization$ref,
  +$refType: SectionHeader_organization$ref,
|};
export type SectionHeader_organization$data = SectionHeader_organization;
export type SectionHeader_organization$key = {
  +$data?: SectionHeader_organization$data,
  +$fragmentRefs: SectionHeader_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionHeader_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WYSIWYGViewerWithFeatureFlag_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '15d363fcbcf025181b9e1e61439baa5d';

module.exports = node;
