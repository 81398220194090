// @flow
import {remove} from 'ramda'
import {type RouterHistory} from 'react-router-dom'
import withAppRouterSetup from './withAppRouterSetup'
import AppRouterContextProvider from './AppRouterContextProvider'
import routerContextFallback from './routerContextFallback'

let instance = null

export type ReactRouterContext = {
  history: RouterHistory,
  match: any,
  location: any,
}

type Callback = ?ReactRouterContext => void

class AppRouter {
  static get instance(): AppRouter {
    if (!instance)
      instance = new AppRouter()

    return instance
  }

  constructor() {
    this.baseUrl = null
    this.routerContext = null
    this.onContextSetupCallbacks = []
  }

  // public methods

  setupRouterContext: ((context: ?ReactRouterContext) => void) = (context: ?ReactRouterContext) => {
    this.routerContext = context
    this.onContextSetupCallbacks.forEach((cb) => cb(context))
  }

  setupBaseUrl: ((baseUrl: string) => void) = (baseUrl: string) => {
    this.baseUrl = baseUrl
    this.onContextSetupCallbacks.forEach((cb) => cb(this.routerContext))
  }

  subscribe: ((callback: Callback) => void) = (callback: Callback) => {
    this.onContextSetupCallbacks = [...this.onContextSetupCallbacks, callback]
    callback(this.routerContext)
  }

  unsubscribe: ((callbackToDelete: Callback) => void) = (callbackToDelete: Callback) => {
    const callbackIndex = this.onContextSetupCallbacks
      .findIndex((callback) => callback === callbackToDelete)

    this.onContextSetupCallbacks = remove(callbackIndex, 1, this.onContextSetupCallbacks)
  }

  redirectTo: ((path: string) => void) = (path: string) => {
    if (this.isReady && this.isOrgnavScopedPath(path))
      this.history.push(this.getOrgnavScopedPath(path))
    else
      routerContextFallback.history.push(path)
  }

  redirectToBaseUrl: (() => void) = () => {
    if (this.isReady && this.baseUrl)
      this.history.push(this.getOrgnavScopedPath(this.baseUrl))
  }

  replaceWith: ((path: string) => void) = (path: string) => {
    if (this.isReady && this.isOrgnavScopedPath(path))
      this.history.replace(this.getOrgnavScopedPath(path))
    else
      routerContextFallback.history.replace(path)
  }

  isOrgnavScopedPath: ((path: string) => boolean) = (path: string): boolean => {
    if (!this.isReady || !this.baseUrl)
      return false

    return (path || '').startsWith(this.baseUrl)
  }

  getOrgnavScopedPath: ((path: string) => string) = (path: string): string => (
    this.baseUrl
      ? path.replace(this.baseUrl, '')
      : path
  )

  get isReady(): boolean {
    return this.routerContext !== null && this.baseUrl !== null
  }

  // private methods
  get context(): ReactRouterContext {
    if (!this.routerContext)
      throw new Error('AppRouter is not ready')

    return this.routerContext
  }

  get history(): RouterHistory {
    const history = this.routerContext && this.routerContext.history
    if (!history)
      throw new Error('AppRouter is not ready')

    return history
  }

  onContextSetupCallbacks: Callback[]
  routerContext: ?ReactRouterContext

  baseUrl: ?string
}

export default AppRouter

export {withAppRouterSetup, AppRouterContextProvider, routerContextFallback}
