/**
 * @flow
 * @relayHash 9e0535c24f49c3d19d8952104551cdb1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TacticalMeetingTriageView_meeting$ref = any;
export type TacticalMeetingTriageQueryVariables = {|
  meetingDatabaseId: string,
  orgDatabaseId: string,
|};
export type TacticalMeetingTriageQueryResponse = {|
  +organization: ?{|
    +meeting: ?{|
      +databaseId: string,
      +$fragmentRefs: TacticalMeetingTriageView_meeting$ref,
    |}
  |}
|};
export type TacticalMeetingTriageQuery = {|
  variables: TacticalMeetingTriageQueryVariables,
  response: TacticalMeetingTriageQueryResponse,
|};
*/


/*
query TacticalMeetingTriageQuery(
  $meetingDatabaseId: String!
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    meeting(databaseId: $meetingDatabaseId) {
      __typename
      databaseId
      ...TacticalMeetingTriageView_meeting
      id
    }
    id
  }
}

fragment AgendaItemGroup_agendaItem on AgendaItem {
  label
  createdAt
  tension {
    body
    impactedRole {
      localizedName
      id
    }
    id
  }
  person {
    id
    name
    isActive
  }
}

fragment AgendaItemGroup_meeting on TacticalMeeting {
  organization {
    viewer {
      id
    }
    ...TensionInfo_organization
    id
  }
  ...Output_meeting
}

fragment AgendaItemGroups_meeting on TacticalMeeting {
  databaseId
  agendaItems(first: 100) {
    edges {
      node {
        databaseId
        hasTacticalOutputs
        ...AgendaItemGroup_agendaItem
        id
      }
    }
  }
  tacticalOutputs(first: 100) {
    edges {
      node {
        id
        databaseId
        agendaItem {
          databaseId
          id
        }
        ...Output_output
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...AgendaItemGroup_meeting
  ...Output_meeting
}

fragment AgendaTensionEditForm_agendaItem on AgendaItem {
  id
  tension {
    id
    body
    impactedRole {
      id
    }
  }
}

fragment AgendaTensionEditForm_meeting on Meeting {
  __isMeeting: __typename
  circle {
    supportedRole {
      id
    }
    ...CircleRoleSelector_circle
    organization {
      databaseId
      ...WYSIWYGTextareaWithFeatureFlag_organization
      id
    }
    id
  }
}

fragment CircleRoleSelector_circle on Circle {
  supportedRole {
    id
    isExpanded
    ...RoleSelector_roles
  }
  roles {
    edges {
      node {
        ...RoleSelector_roles
        id
      }
    }
  }
}

fragment EditOutput_meeting on TacticalMeeting {
  ...OutputForm_meeting
  circle {
    ...OutputForm_circle
    id
  }
}

fragment EditOutput_output on TacticalOutput {
  id
  ...OutputForm_output
}

fragment NewOutputForm_circle on Circle {
  ...OutputForm_circle
}

fragment NewOutputForm_meeting on Meeting {
  __isMeeting: __typename
  id
  currentAgendaItem {
    id
  }
  ...OutputForm_meeting
}

fragment OutputActions_meeting on TacticalMeeting {
  id
}

fragment OutputActions_output on TacticalOutput {
  id
}

fragment OutputAdditionalInfo_output on TacticalOutput {
  type
  trigger
  tensionBody
}

fragment OutputDescription_output on TacticalOutput {
  type
  description
  privateToCircle
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment OutputForm_circle on Circle {
  id
  databaseId
  isGovernanceEnabled
  supportedRole {
    id
  }
}

fragment OutputForm_meeting on TacticalMeeting {
  id
  databaseId
  currentAgendaItem {
    id
    databaseId
  }
  invitedRoles {
    edges {
      node {
        id
      }
    }
  }
  invitedCircles {
    edges {
      node {
        id
      }
    }
  }
  allParticipatingRoles {
    edges {
      node {
        id
        databaseId
        isIndividualAction
        circle {
          id
        }
        assignments {
          edges {
            node {
              person {
                databaseId
                ...PeopleSelector_people
                id
              }
              id
            }
          }
        }
        ...RoleSelector_roles
      }
    }
  }
  allParticipatingMembers {
    edges {
      node {
        id
        databaseId
        roles {
          edges {
            node {
              id
              databaseId
              isDisplayedAsCircle
              circle {
                id
              }
              ...RoleSelector_roles
            }
          }
        }
        ...PeopleSelector_people
      }
    }
  }
  organization {
    databaseId
    isOnV5
    isWysiwygEnabled
    id
  }
}

fragment OutputForm_output on TacticalOutput {
  type
  description
  privateToCircle
  trigger
  projectStatus
  owner {
    databaseId
    id
  }
  person {
    databaseId
    id
  }
}

fragment OutputOwner_output on TacticalOutput {
  type
  owner {
    localizedName
    isIndividualAction
    id
  }
}

fragment OutputPerson_output on TacticalOutput {
  person {
    name
    id
  }
}

fragment OutputProjectStatus_output on TacticalOutput {
  projectStatus
}

fragment Output_meeting on TacticalMeeting {
  ...OutputActions_meeting
  ...EditOutput_meeting
}

fragment Output_output on TacticalOutput {
  databaseId
  type
  error
  ...OutputAdditionalInfo_output
  ...OutputProjectStatus_output
  ...OutputDescription_output
  ...OutputPerson_output
  ...OutputActions_output
  ...OutputOwner_output
  ...EditOutput_output
}

fragment PeopleSelector_people on Person {
  id
  databaseId
  name
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment TacticalMeetingTension_meeting on Meeting {
  __isMeeting: __typename
  databaseId
  organization {
    databaseId
    viewer {
      ...TensionInfo_viewer
      id
    }
    id
  }
  currentAgendaItem {
    id
    viewerCanCreateTension
    tension {
      body
      viewerCanUpdate
      ...TensionInfo_tension
      id
    }
    ...AgendaTensionEditForm_agendaItem
  }
  ...AgendaTensionEditForm_meeting
}

fragment TacticalMeetingTriageView_meeting on Meeting {
  __isMeeting: __typename
  databaseId
  ...NewOutputForm_meeting
  ...AgendaItemGroups_meeting
  ...TacticalMeetingTension_meeting
  circle {
    ...NewOutputForm_circle
    id
  }
}

fragment TensionInfo_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment TensionInfo_tension on Tension {
  createdAt
  body
  agendaLabel
  impactedRole {
    localizedName
    id
  }
  person {
    id
    name
    isActive
  }
  organization {
    ...TensionInfo_organization
    id
  }
}

fragment TensionInfo_viewer on Person {
  id
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "meetingDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "meetingDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "impactedRole",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v14 = [
  (v5/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFocus",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "governanceEnabled",
  "storageKey": null
},
v18 = {
  "alias": "nameWithMode",
  "args": [
    {
      "kind": "Literal",
      "name": "with",
      "value": "CIRCLE_FOR_CORE_ROLES"
    }
  ],
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isIndividualAction",
  "storageKey": null
},
v21 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TacticalMeetingTriageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TacticalMeetingTriageView_meeting"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TacticalMeetingTriageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isMeeting"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgendaItem",
                "kind": "LinkedField",
                "name": "currentAgendaItem",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanCreateTension",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tension",
                    "kind": "LinkedField",
                    "name": "tension",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerCanUpdate",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "agendaLabel",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "supportedRole",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isExpanded",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v3/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v3/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v12/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGovernanceEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgendaItem",
                    "kind": "LinkedField",
                    "name": "currentAgendaItem",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "invitedRoles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleConnection",
                    "kind": "LinkedField",
                    "name": "invitedCircles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CircleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "allParticipatingRoles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/),
                              (v20/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentConnection",
                                "kind": "LinkedField",
                                "name": "assignments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Assignment",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Person",
                                            "kind": "LinkedField",
                                            "name": "person",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v5/*: any*/),
                                              (v10/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonConnection",
                    "kind": "LinkedField",
                    "name": "allParticipatingMembers",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RoleConnection",
                                "kind": "LinkedField",
                                "name": "roles",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RoleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v3/*: any*/),
                                          (v15/*: any*/),
                                          (v19/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isOnV5",
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v21/*: any*/),
                    "concreteType": "AgendaItemConnection",
                    "kind": "LinkedField",
                    "name": "agendaItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AgendaItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AgendaItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasTacticalOutputs",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tension",
                                "kind": "LinkedField",
                                "name": "tension",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v9/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "agendaItems(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v21/*: any*/),
                    "concreteType": "TacticalOutputConnection",
                    "kind": "LinkedField",
                    "name": "tacticalOutputs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TacticalOutputEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TacticalOutput",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AgendaItem",
                                "kind": "LinkedField",
                                "name": "agendaItem",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "error",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "trigger",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tensionBody",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "privateToCircle",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Person",
                                "kind": "LinkedField",
                                "name": "person",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "owner",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v20/*: any*/),
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "tacticalOutputs(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v21/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "AgendaItemGroups_tacticalOutputs",
                    "kind": "LinkedHandle",
                    "name": "tacticalOutputs"
                  }
                ],
                "type": "TacticalMeeting",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9e0535c24f49c3d19d8952104551cdb1",
    "metadata": {},
    "name": "TacticalMeetingTriageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9f75cfb7fb69522bc76427febe15555';

module.exports = node;
