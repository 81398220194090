// @flow
import React, {type Node, useCallback, useContext} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import getIDGenerator from 'utils/getIDGenerator'
import {useToggle} from 'utils/hooks'
import AppRouter from 'utils/AppRouter'
import {Action, Actions, Arrow, Suggestion, SuggestionRow, Suggestions, SuggestionSection}
  from 'components/ui/GuideBox'
import ConvertOrganizationToV4Mutation
  from 'mutations/Organizations/ConvertOrganizationToV4Mutation'
import {AccordionContext} from 'components/ui/Accordion'
import ButtonWithProcessing from 'components/ui/ButtonWithProcessing'

import AdvancedSettingsSection from '../../../AdvancedSettingsSection'
import SetupModeButton from '../../../SetupModeButton'

import styles from '../../../AdvancedSettingsSection/index.scss'

import type {AdvancedSettingsV5_organization as Organization}
  from './__generated__/AdvancedSettingsV5_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  updateRefetchId: () => void,
  setAmendmentFormId: (string) => void,
}>

function organizationToV4Converter(
  organization: Organization,
  beforeHook: () => void,
) {
  return async () => {
    beforeHook()
    AppRouter.instance.replaceWith('#advancedOpen')
    await ConvertOrganizationToV4Mutation({organizationId: organization.id})
  }
}

const generateRandom = getIDGenerator()

function AdvancedSettingsV5({organization, updateRefetchId, setAmendmentFormId}: Props): Node {
  const covertOrgToV4 = useCallback(
    () => organizationToV4Converter(organization, updateRefetchId)(),
    [organization, updateRefetchId],
  )

  const [isAdvSectionOpen, {toggle: toggleAdvSectionOpen}] = useToggle(false)

  const {setOpenIndex} = useContext(AccordionContext)

  const openAmendmentForm = useCallback(async () => {
    const amendmentId = generateRandom()

    if (amendmentId) {
      setAmendmentFormId(amendmentId)
      setOpenIndex(amendmentId)
    }

    toggleAdvSectionOpen()
  }, [setAmendmentFormId, setOpenIndex, toggleAdvSectionOpen])

  const confirmationMessage = [
    I18n.t(
      'admin.organizations.holacracy_version.confirm_upgrade.are_you_sure',
      {orgName: organization.name},
    ),
    I18n.t('admin.organizations.holacracy_version.confirm_upgrade.linked_role_losing'),
    I18n.t('admin.organizations.holacracy_version.confirm_upgrade.low_traffic'),
  ].join(' ')

  return (
    <AdvancedSettingsSection
      isSectionOpen={isAdvSectionOpen}
      toggleSectionOpen={toggleAdvSectionOpen}
    >
      <SuggestionSection>
        <SuggestionRow className={styles.suggestionRow}>
          <Suggestions>
            <Suggestion
              text={I18n.t('admin.organizations.org_rules.advanced_settings.amendments.question')}
            />
          </Suggestions>
          <Arrow className={styles.arrow} />
          <Actions>
            <Action
              onClick={openAmendmentForm}
              title={I18n.t('admin.organizations.org_rules.advanced_settings.amendments.action')}
            />
          </Actions>
        </SuggestionRow>
      </SuggestionSection>
      <SuggestionSection>
        <SuggestionRow className={styles.suggestionRow}>
          <Suggestions>
            <Suggestion
              text={I18n.t('admin.organizations.org_rules.advanced_settings.holacracy_v4.question')}
            />
          </Suggestions>
          <Arrow className={styles.arrow} />
          <Actions>
            <ButtonWithProcessing
              className={styles.actionButton}
              color="secondary"
              onClick={covertOrgToV4}
              confirmMsg={confirmationMessage}
            >
              {I18n.t('admin.organizations.org_rules.advanced_settings.holacracy_v4.action')}
            </ButtonWithProcessing>
          </Actions>
        </SuggestionRow>
      </SuggestionSection>
      <SuggestionSection>
        <SuggestionRow className={styles.suggestionRow}>
          <Suggestions>
            <Suggestion
              text={I18n.t('admin.organizations.org_rules.setup_mode.question')}
            />
          </Suggestions>
          <Arrow className={styles.arrow} />
          <Actions>
            <SetupModeButton
              className={styles.actionButton}
              organization={organization}
              color="secondary"
            />
          </Actions>
        </SuggestionRow>
      </SuggestionSection>
    </AdvancedSettingsSection>
  )
}

export default (createFragmentContainer(AdvancedSettingsV5, {
  organization: graphql`
    fragment AdvancedSettingsV5_organization on Organization {
      id
      name
      
      ...SetupModeButton_organization
    }
  `,
}): RelayFragmentContainer<typeof(AdvancedSettingsV5)>)
