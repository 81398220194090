// @flow strict
import usePersistentState from 'utils/hooks/usePersistentState'

export type EnterOverride = 'NEWLINE' | 'NEW_ITEM'
export type EnterOverrideSetter = ((EnterOverride => EnterOverride) | EnterOverride) => void

function useEnterOverride(): [EnterOverride, EnterOverrideSetter] {
  const defaultEnterOverride = I18n.locale === 'ja' ? 'NEWLINE' : 'NEW_ITEM'

  return usePersistentState<EnterOverride>(defaultEnterOverride, 'proposal-builder-enter-override')
}

export default useEnterOverride
