/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectOwnerSelector_viewer$ref = any;
type RoleAccountabilitySelector_role$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectEditForm_viewer$ref: FragmentReference;
declare export opaque type ProjectEditForm_viewer$fragmentType: ProjectEditForm_viewer$ref;
export type ProjectEditForm_viewer = {|
  +roles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: RoleAccountabilitySelector_role$ref,
      |}
    |}>
  |},
  +$fragmentRefs: ProjectOwnerSelector_viewer$ref,
  +$refType: ProjectEditForm_viewer$ref,
|};
export type ProjectEditForm_viewer$data = ProjectEditForm_viewer;
export type ProjectEditForm_viewer$key = {
  +$data?: ProjectEditForm_viewer$data,
  +$fragmentRefs: ProjectEditForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectEditForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleAccountabilitySelector_role"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectOwnerSelector_viewer"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7dacd766b611affe167f6d6eb7c47ce6';

module.exports = node;
