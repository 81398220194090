// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {graphql} from 'react-relay'

import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import MeetingBanners from './MeetingBanners'

import type {
  AsyncBannersViewQuery as Query,
} from './__generated__/AsyncBannersViewQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
}>

const query = graphql`
  query AsyncBannersViewQuery($orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      ...MeetingBanners_organization
    }
  }
`

function AsyncBannersView({orgDatabaseId}: Props): Node {
  const [{organization}, refetchQuery] = useRefetchableLazyLoadQuery<Query>(query, {
    orgDatabaseId,
  })

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {organization && (
        <MeetingBanners
          organization={organization}
          refetch={refetchQuery}
        />
      )}
    </Fragment>
  )
}

export default AsyncBannersView
