import $ from 'jquery'
import Clipboard from 'clipboard'

$(document).ready(() => {
  if ($('.js-metadata-configure-label')) {
    $('.js-metadata-configure-label').click((e) => {
      const target = e.target
      $('.js-metadata-configure').css('display', 'none')
      $(`.js-metadata-configure[data-configure-option=${target.id}]`).css('display', 'block')
    })
  }

  if ($('.js-clipboard-btn')) {
    $('.js-clipboard-btn').click((e) => {
      e.preventDefault()
    })
    // eslint-disable-next-line no-new
    new Clipboard('.js-clipboard-btn')
  }

  if ($('.js-issuer-default-value-setter').length !== 0) {
    const setValueIfEmpry = function (input) {
      if (input.value === '')
        input.value = $('#saml_consumer_url')[0].value
    }

    setValueIfEmpry($('.js-issuer-default-value-setter')[0])

    $('.js-issuer-default-value-setter').change(function () {
      setValueIfEmpry(this)
    })
  }
})
