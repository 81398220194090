import Backbone from 'backbone'
import $ from 'jquery'

const SubscriptionListView = Backbone.View.extend({
  events: {
    "change input.js-setting-checkbox[data-setting='include_gov_for_role']": 'updateSubcircleCheckbox',
    "change input.js-setting-checkbox[data-setting='include_ops_for_circle']": 'updateSubcircleCheckbox',
    'change input.js-setting-checkbox': 'changeSetting',
    "change input[name='delivery_preferences']": 'updateSummarySetting',
    "change input[name='disable_proposal_emails']": 'updateDisableProposalEmailsSetting',
  },

  initialize() {
    // eslint-disable-next-line func-names
    $('.subscription-list').each(function () {
      $(this).killWhitespace()
    })
  },

  withContext(path) {
    return `/org/${gf.app.currentOrganizationId()}${path}`
  },

  updateSubcircleCheckbox(e) {
    const checkBox = $(e.target)
    const dependentCheckBox = $(`#${checkBox.attr('id').replace('include', 'include_subcircle')}`)

    if (checkBox.is(':checked')) {
      dependentCheckBox.attr('disabled', false)
    } else {
      dependentCheckBox.attr('checked', false)
      dependentCheckBox.attr('disabled', true)
    }
  },

  changeSetting(e) {
    const checkBox = $(e.target)
    const row = $(checkBox.data('on-success-replace'))
    const subId = checkBox.data('subscription-id')

    const subscription = {}
    subscription[checkBox.data('setting')] = checkBox.is(':checked')

    $.ajax({
      type: 'POST',
      url: this.withContext(`/subscriptions/${subId}/update_settings`),
      data: {
        followed_id: checkBox.data('role-id'),
        subscription,
      },
      beforeSend() {
        row.find('.js-followed-name').append("<span class='waiting-spinner' />")
      },
      success(request) {
        row.replaceWith(request)
      },
    })
  },

  updateSummarySetting(e) {
    const val = $(e.target).val()
    $.ajax({
      type: 'POST',
      url: this.withContext('/subscriptions/update_summary_email_setting'),
      data: {
        value: val,
      },
    })
  },

  updateDisableProposalEmailsSetting(e) {
    const val = $(e.target).is(':checked') ? 'true' : 'false'
    $.ajax({
      type: 'POST',
      url: this.withContext('/subscriptions/update_disable_proposal_emails_setting'),
      data: {
        value: val,
      },
    })
  },
})

export default SubscriptionListView
