// @flow
import React, {type Node} from 'react'
import ButtonWithProcessing from 'components/ui/ButtonWithProcessing'
import UpdateOrganizationMutation from 'mutations/UpdateOrganizationMutation'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import confirmTurnOffMessage from 'components/banners/SetupModeBannerContainer/confirmTurnOffMessage'

import type {SetupModeButton_organization as Organization}
  from './__generated__/SetupModeButton_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  color: 'primary' | 'secondary',
  className?: string,
}>

function SetupModeButton({organization, color, className}: Props): Node {
  const toggleSetupMode = () => {
    UpdateOrganizationMutation({
      organizationId: organization.id,
      setupModeEnabled: !organization.setupModeEnabled,
    })
  }

  if (organization.setupModeEnabled) {
    return (
      <ButtonWithProcessing
        key="turn-off"
        color={color}
        className={className}
        onClick={toggleSetupMode}
        confirmMsg={confirmTurnOffMessage(organization.setupModeSuspendedInvitationsCount)}
      >
        {I18n.t('admin.organizations.setup_mode.turn_off')}
      </ButtonWithProcessing>
    )
  }

  return (
    <ButtonWithProcessing
      key="turn-on"
      color={color}
      className={className}
      onClick={toggleSetupMode}
      confirmMsg={I18n.t('admin.organizations.setup_mode.on.confirmation')}
    >
      {I18n.t('admin.organizations.setup_mode.turn_on')}
    </ButtonWithProcessing>
  )
}

export default (createFragmentContainer(SetupModeButton, {
  organization: graphql`
    fragment SetupModeButton_organization on Organization {
      id
      setupModeEnabled
      setupModeSuspendedInvitationsCount
    }
  `,
}): RelayFragmentContainer<typeof(SetupModeButton)>)
