// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'
import {Field, type FormikProps} from 'formik'

import uniqueArrayById from 'utils/MiscUtils/uniqueArrayById'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import HorizontalGroup from 'components/ui/forms/HorizontalGroup'
import PersonRoleSelector from 'components/roles/PersonRoleSelector'
import RoleSelector from 'components/roles/RoleSelector'
import type {Values} from '../..'
import type {
  CircleAndRoleSelector_viewer$key as ViewerKey,
} from './__generated__/CircleAndRoleSelector_viewer.graphql'
import type {
  CircleAndRoleSelector_project$key as ProjectKey,
} from './__generated__/CircleAndRoleSelector_project.graphql'

type Props = $ReadOnly<{
  disabled: boolean,
  formikProps: FormikProps<Values>,
  viewer: ViewerKey,
  project: ?ProjectKey,
}>

const viewerFragment = graphql`
  fragment CircleAndRoleSelector_viewer on Person {
    circles(first: 100) {
      edges {
        node {
          id

          supportedRole {
            id
            isDisplayedAsCircle

            ...RoleSelector_roles
          }
        }
      }
    }

    ...PersonRoleSelector_person
    ...PersonCircleSelector_person
  }
`

const projectFragment = graphql`
  fragment CircleAndRoleSelector_project on Project {
    circle {
      id

      supportedRole {
        id
        isDisplayedAsCircle

        ...RoleSelector_roles
      }
    }
  }
`

function CircleAndRoleSelector({
  disabled,
  formikProps,
  viewer: viewerKey,
  project: projectKey,
}: Props): Node {
  const handleRoleChange = useCallback(({roleValue, supportedRoleValue}) => {
    formikProps.setValues({
      ...formikProps.values,
      accountabilityId: null,
      supportedRoleId: supportedRoleValue || formikProps.values.supportedRoleId,
      roleId: roleValue,
    })
  }, [formikProps])

  const viewer = useFragment(viewerFragment, viewerKey)
  const project = useFragment(projectFragment, projectKey)
  const circles = uniqueArrayById([project?.circle, ...liftNodes(viewer.circles)].filter(Boolean))

  const pickedCircle = circles.find((circle) => (
    circle.supportedRole?.id === formikProps.values.supportedRoleId
  ))
  const circleValue = pickedCircle?.id || null

  const supportedRoles = circles
    .filter((circle) => circle.supportedRole?.isDisplayedAsCircle)
    .map((circle) => circle.supportedRole)
    .filter(Boolean)

  return (
    <HorizontalGroup>
      <Field
        disabled={disabled}
        label={I18n.t('projects.my_projects.circle')}
        as={RoleSelector}
        roles={supportedRoles}
        useAsValue="id"
        name="supportedRoleId"
        placeholder={I18n.t('projects.my_projects.select_circle')}
        test-id="circle-selector"
        warning={formikProps.errors.supportedRoleId}
      />
      <Field
        disabled={disabled}
        label={I18n.t('projects.my_projects.role')}
        as={PersonRoleSelector}
        circleValue={circleValue}
        person={viewer}
        useIndividualActions
        includeFocusRoles
        onRoleChange={handleRoleChange}
        useAsValue="id"
        name="roleId"
        placeholder={I18n.t('projects.my_projects.select_role')}
        test-id="role-selector"
        warning={formikProps.errors.roleId}
      />
    </HorizontalGroup>
  )
}

export default CircleAndRoleSelector
