// @flow

/*::
export type ProductionLocale = 'en'
  | 'ar'
  | 'de'
  | 'es'
  | 'fr'
  | 'hi'
  | 'it'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'zh-CN'
*/

const productionLocales /*: $ReadOnlyArray<ProductionLocale> */ = Object.freeze([
  'en',
  'ar',
  'de',
  'es',
  'fr',
  'hi',
  'it',
  'nl',
  'pl',
  'pt',
  'ru',
  'zh-CN',
])

/*::
export type ExperimentalLocale = 'ja'
*/

const experimentalLocales /*: $ReadOnlyArray<ExperimentalLocale> */ = Object.freeze(['ja'])

/*::
export type Locale = ProductionLocale | ExperimentalLocale
*/

const locales /*: $ReadOnlyArray<Locale> */ = Object.freeze([
  ...productionLocales,
  ...experimentalLocales,
])

// it have to be accessible for both frontend-app and webpack build,
//  so we have to support CJS
module.exports = {
  productionLocales,
  experimentalLocales,
  locales,
}
