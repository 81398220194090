// @flow
import type {Element, Node} from 'react'
import React from 'react'
import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
}>

function Items({children}: Props): Element<"span"> {
  return (
    <span className={styles.items}>
      {children}
    </span>
  )
}

export default Items
