/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TensionInfo_tension$ref = any;
type UpdateTensionInlineBox_tension$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalView_tension$ref: FragmentReference;
declare export opaque type ProjectFormModalView_tension$fragmentType: ProjectFormModalView_tension$ref;
export type ProjectFormModalView_tension = {|
  +$fragmentRefs: UpdateTensionInlineBox_tension$ref & TensionInfo_tension$ref,
  +$refType: ProjectFormModalView_tension$ref,
|};
export type ProjectFormModalView_tension$data = ProjectFormModalView_tension;
export type ProjectFormModalView_tension$key = {
  +$data?: ProjectFormModalView_tension$data,
  +$fragmentRefs: ProjectFormModalView_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalView_tension",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateTensionInlineBox_tension"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TensionInfo_tension"
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd024f64dc5407237612888875a73b96e';

module.exports = node;
