// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'

import constants from 'utils/projects/constants'
import {type Input} from 'mutations/AddProjectMutation'
import {type MutationInput} from 'mutations/createMutationPromise'
import ProjectFormModalFragmented from 'components/projects/ProjectFormModal/ProjectFormModalFragmented'
import type {SubmitValues, Values, Mode, InitialProjectStatuses} from 'components/projects/ProjectEditForm'
import type {Alert} from 'components/types'
import {graphql, useFragment} from 'react-relay'
import type {
  CreateProjectModal_organization$key as Organization,
} from './__generated__/CreateProjectModal_organization.graphql'
import type {
  CreateProjectModal_circle$key as Circle,
} from './__generated__/CreateProjectModal_circle.graphql'
import type {
  CreateProjectModal_tension$key as Tension,
} from './__generated__/CreateProjectModal_tension.graphql'

type InitialValues = $ReadOnly<{
  supportedRoleId?: string,
  roleId?: string,
  personId?: string,
  note?: string,
  parentGoalOrTarget?: string,
}>

type Props<SubmitResponse> = $ReadOnly<{
  addMutation: MutationInput<Input> => Promise<SubmitResponse>,
  initialValues: InitialValues,
  close: () => void,
  mode: Mode,
  onSuccess: () => Promise<void>,
  organization: Organization,
  circle: ?Circle,
  tension: ?Tension,
}>

const organizationFragment = graphql`
  fragment CreateProjectModal_organization on Organization {
    ...ProjectFormModalFragmented_organization
  }
`

const circleFragment = graphql`
  fragment CreateProjectModal_circle on Circle {
    ...ProjectFormModalFragmented_circle
  }
`

const tensionFragment = graphql`
  fragment CreateProjectModal_tension on Tension {
    id

    ...ProjectFormModalFragmented_tension
  }
`
export const statuses: InitialProjectStatuses = ['Current', 'Future', 'Waiting', 'Done']

function CreateProjectModal<SubmitResponse: ?{+errors: $ReadOnlyArray<Alert>, ...}>({
  addMutation,
  close,
  initialValues,
  mode,
  onSuccess,
  tension: tensionKey,
  circle: circleKey,
  organization: organizationKey,
}: Props<SubmitResponse>): Node {
  const initialValuesToUse: Values = {
    accountabilityId: null,
    description: '',
    personId: null,
    roleId: constants.individualActionId,
    supportedRoleId: null,
    parentGoalOrTarget: null,
    link: null,
    visibility: 'ALL',
    value: null,
    effort: null,
    status: 'Current',
    note: null,
    waitingOnWho: null,
    waitingOnWhat: null,
    ...initialValues,
  }

  const organization = useFragment(organizationFragment, organizationKey)
  const circle = useFragment(circleFragment, circleKey)
  const tension = useFragment(tensionFragment, tensionKey)

  const tensionId = tension?.id

  const submit = useCallback((submitValues: SubmitValues) => (
    addMutation({tensionId, ...submitValues})
  ), [addMutation, tensionId])

  return (
    <ProjectFormModalFragmented
      organization={organization}
      tension={tension}
      circle={circle}
      mode={mode}
      onSuccess={onSuccess}
      close={close}
      initialValues={initialValuesToUse}
      submit={submit}
      modalTitle={I18n.t('projects.add_project')}
      statuses={statuses}
    />
  )
}

CreateProjectModal.defaultProps = {
  circle: null,
  tension: null,
  initialValues: Object.freeze({}),
  onSuccess: (): Promise<void> => Promise.resolve(),
}

export default CreateProjectModal
