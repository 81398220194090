// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import {Field, Form, type FormikProps} from 'formik'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import getStatusText from 'utils/projects/getStatusText'
import useCKRolesFeed from 'utils/hooks/useCKRolesFeed'
import HorizontalGroup from 'components/ui/forms/HorizontalGroup'
import InputWithRoleAutocomplete from 'components/ui/forms/InputWithRoleAutocomplete'
import Input from 'components/ui/forms/Input'
import Select from 'components/ui/forms/Select'
import ShowMore from 'components/ui/forms/ShowMore'
import Textarea from 'components/ui/forms/Textarea'
import PremiumCallout from 'components/ui/PremiumCallout'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import RoleAccountabilitySelector from 'components/accountabilities/RoleAccountabilitySelector'
import Actions from 'components/pages/Projects/ProjectsView/ProjectsPageList/Actions'
import type {Option} from 'components/types'
import ProjectOwnerSelector, {type Mode} from './ProjectOwnerSelector'
import ProjectRoiInput from './ProjectRoiInput'
import type {
  ProjectEditForm_circle$key as Circle,
} from './__generated__/ProjectEditForm_circle.graphql'
import type {
  ProjectEditForm_organization$key as Organization,
} from './__generated__/ProjectEditForm_organization.graphql'
import type {
  ProjectEditForm_viewer$key as Viewer,
} from './__generated__/ProjectEditForm_viewer.graphql'
import type {
  ProjectEditForm_project$key as Project,
} from './__generated__/ProjectEditForm_project.graphql'

import styles from './index.scss'

/* TODO: refactor to remove duplication */
export type AllProjectStatuses = ['Current', 'Future', 'Waiting', 'Done', 'Archived']
export type InitialProjectStatuses = ['Current', 'Future', 'Waiting', 'Done']

type ProjectStatus = 'Current' | 'Future' | 'Waiting' | 'Done' | 'Archived'
type ProjectVisibility = 'ALL' | 'PRIVATE_TO_CIRCLE' | 'PRIVATE_TO_PERSON'
type ProjectStatusOption = {
  label: string,
  value: ProjectStatus,
}

export type Values = $ReadOnly<{
  accountabilityId: ?string,
  description: string,
  personId: ?string,
  roleId: ?string,
  supportedRoleId: ?string,
  parentGoalOrTarget: ?string,
  link: ?string,
  visibility: ProjectVisibility,
  value: ?number,
  effort: ?number,
  status: ?string,
  note: ?string,
  waitingOnWho: ?string,
  waitingOnWhat: ?string,
}>

export type SubmitValues = $ReadOnly<{
  accountabilityId: ?string,
  description: string,
  personId: ?string,
  roleId: string,
  roleType: "INDIVIDUAL_INITIATIVE" | "ROLE",
  parentGoalOrTarget: ?string,
  link: ?string,
  visibility: ProjectVisibility,
  value: ?number,
  effort: ?number,
  status: ?string,
  note: ?string,
  waitingOnWho: ?string,
  waitingOnWhat: ?string,
}>

type Props = $ReadOnly<{
  circle: ?Circle,
  disabled: boolean,
  formId: string,
  formikProps: FormikProps<Values>,
  mode: Mode,
  organization: Organization,
  viewer: Viewer,
  project: Project | null,
  statuses: InitialProjectStatuses | AllProjectStatuses,
}>

export const statusOptions = (
  statuses: InitialProjectStatuses | AllProjectStatuses,
): Array<ProjectStatusOption> => statuses.map((status) => ({
  label: getStatusText(status),
  value: status,
}))

const visibilityOptions = (isOnRestrictedPlan): Array<Option> => {
  const options = [
    {
      label: 'All',
      value: 'ALL',
    },
    {
      label: 'Private to Circle',
      value: 'PRIVATE_TO_CIRCLE',
    },
    {
      label: 'Private to Person',
      value: 'PRIVATE_TO_PERSON',
    },
  ]

  return options.map((option) => {
    if (option.value === 'PRIVATE_TO_PERSON' && isOnRestrictedPlan) {
      return {
        ...option,
        disabled: true,
        premiumCalloutType: 'private_to_person_projects',
      }
    }

    return option
  })
}

function InputWithAutocomplete(props) {
  return (
    <InputWithRoleAutocomplete
      {...props}
      input={Input}
    />
  )
}

const organizationFragment = graphql`
  fragment ProjectEditForm_organization on Organization {
    databaseId
    isGoalsEnabled
    isROIEnabled
    supportableGoalsAndTargets
    onRestrictedPlan
    showAccountabilities: featureEnabled(name: "accountabilities")

    ...WYSIWYGTextareaWithFeatureFlag_organization
  }
`

const viewerFragment = graphql`
  fragment ProjectEditForm_viewer on Person {
    ...ProjectOwnerSelector_viewer

    roles {
      edges {
        node {
          id

          ...RoleAccountabilitySelector_role
        }
      }
    }
  }
`

const circleFragment = graphql`
  fragment ProjectEditForm_circle on Circle {
    ...ProjectOwnerSelector_circle

    roles(includeFocusRoles: true) {
      edges {
        node {
          id

          ...RoleAccountabilitySelector_role
        }
      }
    }
  }
`

const projectFragment = graphql`
  fragment ProjectEditForm_project on Project {
    ...Actions_project
    ...ProjectOwnerSelector_project
  }
`

function ProjectEditForm({
  disabled,
  mode,
  organization: organizationKey,
  circle: circleKey,
  viewer: viewerKey,
  project: projectKey,
  formikProps,
  formId,
  statuses,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const viewer = useFragment(viewerFragment, viewerKey)
  const circle = useFragment(circleFragment, circleKey)
  const project = useFragment(projectFragment, projectKey)
  const owner = circle || viewer

  const selectedRole = liftNodes(owner.roles)
    .find((r) => r.id === formikProps.values.roleId) || null

  const rolesFeed = useCKRolesFeed(organization.databaseId)

  return (
    <Form id={formId}>
      <Field
        disabled={disabled}
        label={I18n.t('projects.my_projects.description')}
        as={WYSIWYGTextareaWithFeatureFlag}
        organization={organization}
        fallbackComponent={InputWithAutocomplete}
        fallbackProps={{
          orgDatabaseId: organization.databaseId,
        }}
        getFeed={rolesFeed}
        mode="inline"
        name="description"
        warning={formikProps.errors.description}
      />
      <ProjectOwnerSelector
        disabled={disabled}
        formikProps={formikProps}
        mode={mode}
        circle={circle}
        viewer={viewer}
        project={project}
      />
      {selectedRole && organization.showAccountabilities && (
        <Field
          as={RoleAccountabilitySelector}
          disabled={disabled}
          label={I18n.t('projects.my_projects.accountability')}
          name="accountabilityId"
          role={selectedRole}
          test-id="accountability-selector"
          warning={formikProps.errors.accountabilityId}
        />
      )}
      <HorizontalGroup>
        <Field
          disabled={disabled}
          label={I18n.t('projects.my_projects.status')}
          as={Select}
          name="status"
          warning={formikProps.errors.status}
          options={statusOptions(statuses)}
          test-id="project-status-selector"
        />
        <Field
          as={Select}
          disabled={disabled}
          label="Visibility"
          name="visibility"
          test-id="project-visibility"
          warning={formikProps.errors.visibility}
          options={visibilityOptions(organization.onRestrictedPlan)}
        />
      </HorizontalGroup>
      {formikProps.values.status === 'Waiting' && (
        <HorizontalGroup>
          <Field
            disabled={disabled}
            label={I18n.t('projects.my_projects.waiting_on')}
            as={Input}
            name="waitingOnWho"
            warning={formikProps.errors.waitingOnWho}
            placeholder={I18n.t('projects.my_projects.who')}
          />
          <Field
            disabled={disabled}
            label={I18n.t('projects.my_projects.for')}
            as={Input}
            name="waitingOnWhat"
            warning={formikProps.errors.waitingOnWhat}
            placeholder={I18n.t('projects.my_projects.what')}
          />
        </HorizontalGroup>
      )}
      {organization.isGoalsEnabled && (
      <Field
        as={Select}
        disabled={disabled}
        label={I18n.t('goals.supported_goal_or_target')}
        name="parentGoalOrTarget"
        warning={formikProps.errors.parentGoalOrTarget}
        options={organization.supportableGoalsAndTargets}
      />
      )}

      {organization.onRestrictedPlan && project && (
        <span id="actionsCallout" className={styles.actionsCallout}>
          <span>{I18n.t('shared.actions')}</span>
          <PremiumCallout callout="add_actions_from_projects" target="actionsCallout" enabled darkIcon />
        </span>
      )}
      {!organization.onRestrictedPlan && project && (
        <Actions project={project} foldable />
      )}
      <ShowMore>
        <Field
          disabled={disabled}
          label={I18n.t('projects.my_projects.link')}
          as={Input}
          name="link"
          warning={formikProps.errors.link}
          placeholder="https://docs.google.com/document/d/1234/edit"
        />
        <Field
          disabled={disabled}
          label={I18n.t('projects.my_projects.note')}
          as={WYSIWYGTextareaWithFeatureFlag}
          getFeed={rolesFeed}
          fallbackComponent={Textarea}
          organization={organization}
          name="note"
          warning={formikProps.errors.note}
        />
        {organization.isROIEnabled && (
          <ProjectRoiInput
            disabled={disabled}
            formikProps={formikProps}
          />
        )}
      </ShowMore>
    </Form>
  )
}

ProjectEditForm.defaultProps = {
  project: null,
}

export type {Mode}
export default ProjectEditForm
