// @flow strict-local
import {useState, useCallback} from 'react'

type CreatingProject = $ReadOnly<{
  type: 'CREATE_PROJECT',
}>

type EditingProject = $ReadOnly<{
  type: 'EDITING_PROJECT',
  projectId: string,
}>

type NoneType = $ReadOnly<{
  type: 'NONE'
}>

export type State = CreatingProject | EditingProject | NoneType

const noneState: NoneType = {type: 'NONE'}

function useModalsState():
  [State, { closeModal: () => void, openCreator: () => void, openEditor: (projectId: string) => void}] {
  const [state, setState] = useState<State>(noneState)

  const closeModal = useCallback(() => setState(noneState), [setState])

  const openEditor = useCallback((projectId: string) => {
    setState({
      type: 'EDITING_PROJECT',
      projectId,
    })
  }, [])

  const openCreator = useCallback(() => {
    setState({type: 'CREATE_PROJECT'})
  }, [])

  return [state, {closeModal, openEditor, openCreator}]
}

export default useModalsState
