/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PeopleSelector_people$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleAndPersonSelector_project$ref: FragmentReference;
declare export opaque type RoleAndPersonSelector_project$fragmentType: RoleAndPersonSelector_project$ref;
export type RoleAndPersonSelector_project = {|
  +person: ?{|
    +id: string,
    +$fragmentRefs: PeopleSelector_people$ref,
  |},
  +role: {|
    +isIndividualAction: boolean
  |},
  +$refType: RoleAndPersonSelector_project$ref,
|};
export type RoleAndPersonSelector_project$data = RoleAndPersonSelector_project;
export type RoleAndPersonSelector_project$key = {
  +$data?: RoleAndPersonSelector_project$data,
  +$fragmentRefs: RoleAndPersonSelector_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleAndPersonSelector_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PeopleSelector_people"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isIndividualAction",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5a351fd7ad35bfd3a6ae20b9c13fe9b5';

module.exports = node;
