// @flow
import OrgnavRouter from 'utils/OrgnavRouter'
import {offset} from '@floating-ui/dom'
import $ from 'jquery'

export const tourOptions = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const buttonConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      tour.next()
    },
  },
])

const buttonGoToRoles = ({tour, history, roleDatabaseId}) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'roles'}))
      tour.next()
    },
  },
])

const buttonLinkToManageRoleFiller = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      $('#orgnav-role-list .org-nav-details-assignments__edit-button button').click()
      tour.next()
    },
  },
])

const tooltip = (linkText, tipKey, orgDatabaseId) => `<a href="/organizations/${orgDatabaseId}/dialogs/tooltip/${tipKey}" class="async-popover" data-container=".shepherd-element:visible">${linkText}</a>`

type Props = {
  tour: Object,
  history: Object,
  roleDatabaseId: string,
  orgDatabaseId: string,
}

export const getSteps = ({tour, history, roleDatabaseId, orgDatabaseId}: Props): Object => ([
  {
    id: '1',
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: ''}))
      },
    },
    buttons: buttonConfig(tour),
    title: 'Filling a Role',
    text: [`<div class="tour-text-container">
      <p>To assign someone to a role or 
      ${tooltip('circle', 'circle', orgDatabaseId)}, first navigate to the 
      ${tooltip('circle', 'circle', orgDatabaseId)} you'd like to add them to.</p>
      <p>For this example, we will use the 
      ${tooltip('anchor circle', 'anchor_circle', orgDatabaseId)}.</p>
    </div>`],
  },
  {
    id: '2',
    attachTo: {
      element: '#Roles-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonGoToRoles({tour, history, roleDatabaseId}),
    classes: 'headerless',
    text: [`<div><p>From here you want to go to the Roles tab. This shows all of the roles in the 
        ${tooltip('circle', 'circle', orgDatabaseId)}, their Purpose, and who 
        fills them.</p></div>`],
  },
  {
    id: '3',
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'roles'}))
      },
    },
    classes: 'headerless',
    buttons: buttonConfig(tour),
    text: [`<div><p>From here you can see the list of all roles in the 
         ${tooltip('circle', 'circle', orgDatabaseId)}.</p></div>`],
  },
  {
    id: '4',
    when: {
      show() {
        $('body').addClass('shepherd-enabled')
      },
    },
    attachTo: {
      element: '#orgnav-role-list .org-nav-details-assignments__edit-button button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    buttons: buttonLinkToManageRoleFiller(tour),
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: ['To add, remove, or change the focus of a role, select the manage role fillers icon.'],
  },
  {
    id: '5',
    when: {
      show() {
        $('#react-select-3-live-region').click()
      },
    },
    buttons: ([{
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action: () => tour.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Finish',
      action: () => {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: ''}))
        tour.complete()
      },
    }]),
    attachTo: {
      element: '.modal',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    classes: 'headerless',
    text: [`<div><p>Here you can select a person to fill the role and press 'Assign'.</p>
        <p>Note that typically only the Circle Lead can assign role fillers, and that some roles are elected through a 
        ${tooltip('governance', 'governance', orgDatabaseId)} process.</p>`],
  },
])
