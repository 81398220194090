// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'

import Heading from 'components/ui/Heading'
import AddButton from './AddButton'

export type AddingButtonConfig = {
  itemName: string,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  title?: string,
  byProposal?: boolean,
}

export type RenderButtons = () => Node[] | null

type Props = {
  addingButtonConfig: ?AddingButtonConfig,
  children: Node,
  renderButtons: ?RenderButtons,
  underlined: boolean,
  spacing: 'none' | 'xSmall' | 'small' | 'medium' | 'huge',
}

function Header({
  addingButtonConfig,
  children,
  renderButtons,
  spacing,
  underlined,
}: Props): Node {
  const onAddButtonClick = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (addingButtonConfig)
      addingButtonConfig.onClick(e)
  }, [addingButtonConfig])

  const renderAdditionalSection = useCallback(() => {
    if (!addingButtonConfig)
      return null

    return (
      <AddButton
        itemName={addingButtonConfig.itemName}
        onClick={onAddButtonClick}
        title={addingButtonConfig.title}
        byProposal={!!addingButtonConfig.byProposal}
      />
    )
  }, [addingButtonConfig, onAddButtonClick])

  return (
    <Heading
      headingLevel={2}
      spacing={spacing}
      underlined={underlined}
      additionalSection={renderAdditionalSection}
      middleSection={renderButtons}
    >
      {children}
    </Heading>
  )
}

Header.defaultProps = {
  addingButtonConfig: null,
  renderButtons: null,
  spacing: 'xSmall',
  underlined: true,
}

export default Header
