/**
 * @flow
 * @relayHash 1ff0fb9e3549ff9421bf35d569238750
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteTacticalOutputInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  outputId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteTacticalOutputMutationVariables = {|
  input: DeleteTacticalOutputInput
|};
export type DeleteTacticalOutputMutationResponse = {|
  +deleteTacticalOutput: ?{|
    +deletedOutputId: ?string
  |}
|};
export type DeleteTacticalOutputMutation = {|
  variables: DeleteTacticalOutputMutationVariables,
  response: DeleteTacticalOutputMutationResponse,
|};
*/


/*
mutation DeleteTacticalOutputMutation(
  $input: DeleteTacticalOutputInput!
) {
  deleteTacticalOutput(input: $input) {
    deletedOutputId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteTacticalOutputPayload",
    "kind": "LinkedField",
    "name": "deleteTacticalOutput",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedOutputId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTacticalOutputMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTacticalOutputMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "1ff0fb9e3549ff9421bf35d569238750",
    "metadata": {},
    "name": "DeleteTacticalOutputMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bff2c843c68ccf1d636dcb0ca049099e';

module.exports = node;
