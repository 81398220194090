import React, {lazy, Suspense} from 'react'

const Tags = lazy(() => import(/* webpackChunkName: "Tags" */ './index'))

function LazyTags(props) {
  return (
    <Suspense fallback={null}>
      <Tags {...props} />
    </Suspense>
  )
}

export default LazyTags
