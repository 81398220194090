// @flow
import type {Node} from 'react'
import React, {Fragment, useCallback} from 'react'
import classNames from 'classnames'

import useUniqueId from 'utils/hooks/useUniqueId'
import VisuallyHidden from 'components/ui/VisuallyHidden'
import DefinitionTooltip from 'components/ui/DefinitionTooltip'
import FrogTipBox from 'components/ui/FrogTipBox'
import PremiumCallout, {type CalloutType} from 'components/ui/PremiumCallout'
import InputContainer from '../InputContainer'
import styles from './index.scss'

type Props = $ReadOnly<{
  onChange: (SyntheticInputEvent<HTMLInputElement>, boolean) => void,
  onBlur: (SyntheticFocusEvent<HTMLInputElement>) => void,
  name: string,
  'test-id': ?string,
  value: boolean,
  warning: string | null,
  label: string,
  disabled: boolean,
  hideLabel: boolean,
  containerClassName: string,
  tooltip: string | null,
  frogbox: boolean,
  premiumCalloutType?: CalloutType,
}>

/* eslint jsx-a11y/label-has-for:0 */
function Checkbox({
  onChange,
  onBlur,
  'test-id': testId,
  name,
  value,
  warning,
  label,
  disabled,
  hideLabel,
  containerClassName,
  tooltip,
  frogbox,
  premiumCalloutType,
}: Props): Node {
  const [id] = useUniqueId()
  const inputId = `checkbox-${id}`
  const isValid = warning === null

  const labelClassName = classNames(styles.label, {
    [styles.warning]: !isValid,
  })

  const handleChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const newValue = event.target.value === 'true'

    onChange(event, newValue)
  }, [onChange])

  const labelId = `${inputId}_label`
  const labelTextElement = !hideLabel && <span className={labelClassName}>{label}</span>

  const containerClass = classNames(containerClassName, {
    [styles.disabledWithpremiumCallout]: disabled && premiumCalloutType,
  })

  const labelWithPremiumCallout = premiumCalloutType && (
    <Fragment>
      {labelTextElement}
      <PremiumCallout
        callout={premiumCalloutType}
        target={labelId}
        darkIcon
        enabled
      />
    </Fragment>
  )

  const labelWithDefinitionTooltip = (
    <span className={labelClassName}>
      {label}
      {tooltip && (
        <DefinitionTooltip definition={tooltip} target={id} />
      )}
    </span>
  )

  const labelWithFrogBox = tooltip && (
    <FrogTipBox
      tip={tooltip}
      helpFor={label}
      // eslint-disable-next-line react/no-unstable-nested-components
      target={() => labelTextElement}
      hideDivider
    />
  )

  return (
    <InputContainer className={containerClass}>
      <label id={labelId} className={styles.container} htmlFor={inputId}>
        <input
          checked={value}
          disabled={disabled}
          test-id={testId}
          name={name}
          value={value}
          id={inputId}
          type="checkbox"
          className={styles.input}
          onChange={handleChange}
          onBlur={onBlur}
        />
        {hideLabel && (
          <VisuallyHidden>{label}</VisuallyHidden>
        )}
        {premiumCalloutType && (
          labelWithPremiumCallout
        )}
        {!hideLabel && !premiumCalloutType && !frogbox && (
          labelWithDefinitionTooltip
        )}
        {!hideLabel && !premiumCalloutType && frogbox && (
          labelWithFrogBox
        )}
      </label>
    </InputContainer>
  )
}

Checkbox.defaultProps = {
  onBlur: () => {},
  warning: null,
  'test-id': null,
  disabled: false,
  containerClassName: '',
  hideLabel: false,
  tooltip: null,
  frogbox: false,
}

export default Checkbox
