// @flow
import type {Node} from 'react'
import React, {Fragment, useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'

import DeleteTensionMutation from 'mutations/DeleteTensionMutation'
import {Accordion, Card, Header, Body} from 'components/ui/Accordion'
import InlineMarkdown from 'components/ui/InlineMarkdown'
import {SuggestionSection, SuggestionRow, Suggestions, Suggestion, Arrow, Actions, Action, Hint}
  from 'components/ui/GuideBox'
import styles from './index.scss'
import type {
  TensionGuide_tension$key as Tension,
} from './__generated__/TensionGuide_tension.graphql'
import type {
  TensionGuide_viewer$key as Viewer,
} from './__generated__/TensionGuide_viewer.graphql'

function Prompt({text}: {text: string}) {
  return (
    <div className={styles.prompt}>
      <InlineMarkdown text={text} />
    </div>
  )
}

type ProcessModalType = 'Action' | 'Project' | 'AgendaTactical' | 'AgendaGovernance' | 'Proposal'

type Props = $ReadOnly<{
  openModal: ({tensionDatabaseId: string, processModal: ProcessModalType}) => Promise<void>,
  close: () => void,
  tension: Tension,
  viewer: Viewer,
}>

const tensionFragment = graphql`
  fragment TensionGuide_tension on Tension {
    id
    databaseId
    
    impactedRole {
      governanceEnabled
    }    
  }
`

const viewerFragment = graphql`
  fragment TensionGuide_viewer on Person {
    id  
    hasCirclesWithGovernanceOn
  }
`

async function deleteTension({tension, viewer, close}) {
  await DeleteTensionMutation({tensionId: tension.id}, viewer.id)

  close()
}

type ActionType = $ReadOnly<{label: string, action: () => Promise<void>}>

type SuggestionTextType = $ReadOnly<{text: string, hints?: $ReadOnlyArray<string>}>

type SuggestionType = $ReadOnly<{
  texts: $ReadOnlyArray<SuggestionTextType>,
  actions: $ReadOnlyArray<ActionType>,
  hint?: string,
  separateActions?: boolean,
}>

type SectionType = $ReadOnly<{
  header: string,
  suggestions: $ReadOnlyArray<SuggestionType>,
}>

function TensionGuide({openModal, close, tension: tensionKey, viewer: viewerKey}: Props): Node {
  const tension = useFragment(tensionFragment, tensionKey)
  const viewer = useFragment(viewerFragment, viewerKey)

  const openAgendaItemForTacticalModal = () => openModal({tensionDatabaseId: tension.databaseId, processModal: 'AgendaTactical'})

  const openAgendaItemForGovernanceModal = () => openModal({tensionDatabaseId: tension.databaseId, processModal: 'AgendaGovernance'})

  const openActionModal = () => openModal({tensionDatabaseId: tension.databaseId, processModal: 'Action'})

  const openProjectModal = () => openModal({tensionDatabaseId: tension.databaseId, processModal: 'Project'})

  const done = () => deleteTension({tension, close, viewer})

  const governanceMeetingsEnabled = tension.impactedRole
    ? tension.impactedRole.governanceEnabled
    : viewer.hasCirclesWithGovernanceOn

  const getHelpSuggestion: SuggestionType = {
    texts: [
      {
        text: I18n.t('tensions.tension_processing.share_info_get_help.steps'),
        hints: [
          I18n.t('tensions.tension_processing.share_info_get_help.tactical_mtg_step'),
          I18n.t('tensions.tension_processing.share_info_get_help.reach_directly_step'),
          I18n.t('tensions.tension_processing.share_info_get_help.action_step'),
          I18n.t('tensions.tension_processing.share_info_get_help.not_sure_step'),
        ].filter(Boolean),
      },
    ],
    actions: [
      {
        label: I18n.t('tensions.tension_processing.actions.tactical'),
        action: openAgendaItemForTacticalModal,
      },
      {
        label: I18n.t('tensions.tension_processing.actions.action'),
        action: openActionModal,
      },
    ].filter(Boolean),
    separateActions: true,
  }

  const helpSection: SectionType = ({
    header: I18n.t('tensions.tension_processing.share_info_get_help.title'),
    suggestions: [getHelpSuggestion],
  })

  const doSomethingActionSuggestion: SuggestionType = {
    texts: [{text: I18n.t('tensions.tension_processing.do_something.simple_step')}],
    actions: [
      {label: I18n.t('tensions.tension_processing.actions.action'), action: openActionModal},
    ],
  }

  const doSomethingProjectSuggestion: SuggestionType = {
    texts: [{text: I18n.t('tensions.tension_processing.do_something.complex_step')}],
    actions: [
      {label: I18n.t('tensions.tension_processing.actions.project'), action: openProjectModal},
    ],
  }

  const doSomethingGovernanceSuggestion: SuggestionType = {
    texts: [{text: I18n.t('tensions.tension_processing.do_something.modify_role_step')}],
    actions: [
      {label: I18n.t('tensions.tension_processing.actions.gov'), action: openAgendaItemForGovernanceModal},
    ],
  }

  const doSomethingSection: SectionType = ({
    header: I18n.t('tensions.tension_processing.do_something.title'),
    suggestions: [
      doSomethingActionSuggestion,
      doSomethingProjectSuggestion,
      governanceMeetingsEnabled ? doSomethingGovernanceSuggestion : null,
    ].filter(Boolean),
  })

  const delegateAsOneStepSuggestion: SuggestionType = {
    texts: [{text: I18n.t('tensions.tension_processing.delegate.one_time_request_step')}],
    actions: [
      {
        label: I18n.t('tensions.tension_processing.actions.tactical'),
        action: openAgendaItemForTacticalModal,
      },
      {label: I18n.t('tensions.tension_processing.actions.action'), action: openActionModal},
    ].filter(Boolean),
  }

  const delegateAsRegularSuggestion: SuggestionType = {
    texts: [{text: I18n.t('tensions.tension_processing.delegate.regular_step')}],
    actions: [
      {label: I18n.t('tensions.tension_processing.actions.gov'), action: openAgendaItemForGovernanceModal},
    ],
  }

  const delegateSection: SectionType = ({
    header: I18n.t('tensions.tension_processing.delegate.title'),
    suggestions: [
      delegateAsOneStepSuggestion,
      governanceMeetingsEnabled ? delegateAsRegularSuggestion : null,
    ].filter(Boolean),
  })

  const footerFinalizeSuggestion: SuggestionType = {
    texts: [
      {text: I18n.t('tensions.tension_processing.nothing.already_done_step')},
      {text: I18n.t('tensions.tension_processing.nothing.handle_in_other_way_step')},
    ],
    hint: I18n.t('tensions.tension_processing.nothing.done_step'),
    actions: [
      {label: I18n.t('tensions.tension_processing.actions.done'), action: done},
    ],
  }

  const footerSection: SectionType = ({
    header: I18n.t('tensions.tension_processing.nothing.title'),
    suggestions: [footerFinalizeSuggestion],
  })

  const sections = [helpSection, doSomethingSection, delegateSection, footerSection]

  const renderSuggestionItem = useCallback((item: SuggestionTextType, key: number) => (
    <Suggestion text={item.text} key={key}>
      {item.hints && item.hints.map((hint, hintIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Hint key={hintIndex}>{hint}</Hint>
      ))}
    </Suggestion>
  ), [])

  const renderActionItem = useCallback((item: ActionType, key: number, renderSeparator: boolean) => (
    <Fragment key={key}>
      {renderSeparator && (<div className={styles.actionSpace} />)}
      <Action
        title={item.label}
        onClick={item.action}
      />
    </Fragment>
  ), [])

  const renderSuggestion = useCallback((suggestion: SuggestionType) => (
    <SuggestionRow>
      <Suggestions>
        {suggestion.texts.map((text: SuggestionTextType, textIndex) => renderSuggestionItem(text, textIndex))}
      </Suggestions>
      <Arrow />
      <Actions>
        {suggestion.hint && (<Hint done dashed={false}>{suggestion.hint}</Hint>)}
        {suggestion.actions.map((action, actionIndex) => (
          renderActionItem(action, actionIndex, !!suggestion.separateActions && actionIndex > 0)
        ))}
      </Actions>
    </SuggestionRow>
  ), [renderActionItem, renderSuggestionItem])

  const renderSection = useCallback((section: SectionType, key: number) => (
    <Card index={key.toString()} key={key}>
      <Header>
        <Prompt text={section.header} />
      </Header>
      <Body>
        <SuggestionSection>
          {section.suggestions.map((suggestion, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {index > 0 && (<div className={styles.actionSpace} />)}
              {renderSuggestion(suggestion)}
            </Fragment>
          ))}
        </SuggestionSection>
      </Body>
    </Card>
  ), [renderSuggestion])

  return (
    <Accordion>
      {sections.map((section, index) => (renderSection(section, index)))}
    </Accordion>
  )
}

export default TensionGuide
