/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PeopleSelector_people$ref: FragmentReference;
declare export opaque type PeopleSelector_people$fragmentType: PeopleSelector_people$ref;
export type PeopleSelector_people = $ReadOnlyArray<{|
  +id: string,
  +databaseId: string,
  +name: string,
  +$refType: PeopleSelector_people$ref,
|}>;
export type PeopleSelector_people$data = PeopleSelector_people;
export type PeopleSelector_people$key = $ReadOnlyArray<{
  +$data?: PeopleSelector_people$data,
  +$fragmentRefs: PeopleSelector_people$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PeopleSelector_people",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7a4411329f1d17814761a9644d0abfa4';

module.exports = node;
