/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChecklistList_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileChecklistsView_organization$ref: FragmentReference;
declare export opaque type ProfileChecklistsView_organization$fragmentType: ProfileChecklistsView_organization$ref;
export type ProfileChecklistsView_organization = {|
  +viewer: {|
    +id: string
  |},
  +$fragmentRefs: ChecklistList_organization$ref,
  +$refType: ProfileChecklistsView_organization$ref,
|};
export type ProfileChecklistsView_organization$data = ProfileChecklistsView_organization;
export type ProfileChecklistsView_organization$key = {
  +$data?: ProfileChecklistsView_organization$data,
  +$fragmentRefs: ProfileChecklistsView_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileChecklistsView_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChecklistList_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8782aae2471da328f4c130b0d8fe838a';

module.exports = node;
