// @flow

// TODO: should be moved to GQL resolver side
export default function getStatusText(status: string): any {
  const statuses = {
    current: I18n.t('projects.my_projects.statuses.current'),
    waiting: I18n.t('projects.my_projects.statuses.waiting'),
    future: I18n.t('projects.my_projects.statuses.future'),
    done: I18n.t('projects.my_projects.statuses.done'),
    archived: I18n.t('projects.my_projects.statuses.archived'),
    all: I18n.t('projects.my_projects.statuses.all'),
  }

  return statuses[status.toLowerCase()] || ''
}
