// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import Preload from 'components/ui/Preload'
import styles from './index.scss'

type Props = {
  children: Node,
  leftPadding: boolean,
  rightPadding: boolean,
  topPadding: boolean,
  bottomPadding: boolean,
}

function Body({
  children,
  leftPadding,
  rightPadding,
  topPadding,
  bottomPadding,
}: Props): Element<"div"> {
  const childrenArray = React.Children.toArray(children)
  const childrenExist = childrenArray.length > 0
  const bodyClass = classNames({
    [styles.leftPadding]: leftPadding,
    [styles.rightPadding]: rightPadding,
    [styles.topPadding]: topPadding,
    [styles.bottomPadding]: bottomPadding,
  })

  return (
    <div className={bodyClass}>
      {childrenExist
        ? childrenArray
        : <Preload />
      }
    </div>
  )
}

Body.defaultProps = {
  leftPadding: false,
  rightPadding: false,
  topPadding: false,
  bottomPadding: false,
}

export default Body
