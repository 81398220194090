/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TacticalOutputType = "ACTION" | "INFORMATION" | "PROJECT" | "TENSION" | "TRIGGER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputForm_output$ref: FragmentReference;
declare export opaque type OutputForm_output$fragmentType: OutputForm_output$ref;
export type OutputForm_output = {|
  +type: TacticalOutputType,
  +description: string,
  +privateToCircle: boolean,
  +trigger: ?string,
  +projectStatus: ?string,
  +owner: ?{|
    +databaseId: string
  |},
  +person: ?{|
    +databaseId: string
  |},
  +$refType: OutputForm_output$ref,
|};
export type OutputForm_output$data = OutputForm_output;
export type OutputForm_output$key = {
  +$data?: OutputForm_output$data,
  +$fragmentRefs: OutputForm_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "databaseId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputForm_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trigger",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '3bd1f5d32039970652bed4439d4d8d0f';

module.exports = node;
