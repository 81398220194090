// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import Button from './ToggleJiraButton'
import type {Organization} from './types'
import styles from './index.scss'

/* eslint react/no-danger: 0 */

type Props = {
  logoPath: string,
  organization: Organization,
}

function ConfigureJiraSectionInner({logoPath, organization}: Props): Node {
  return (
    <div className="row">
      <div className="col-xl-2">
        <img alt="Jira" src={logoPath} className={styles.logo} />
      </div>
      <div className="col-xl-10">
        {organization.onRestrictedPlan
          ? (
            <p>{I18n.t('admin.organizations.jira_integration.integration_upsell')}</p>
          ) : (
            <Fragment>
              <p>
                {organization.isJiraEnabled
                  ? I18n.t('admin.organizations.jira_integration.integration_enabled')
                  : I18n.t('admin.organizations.jira_integration.integration_disabled')
                }
                <br />
                <span
                  className="small text-muted"
                >
                  {I18n.t('admin.organizations.jira_integration.disclaimer')}
                </span>
              </p>
              <p>
                <Button organization={organization} />
              </p>
            </Fragment>
          )
      }
      </div>
    </div>
  )
}

export default (createFragmentContainer(ConfigureJiraSectionInner, {
  organization: graphql`
    fragment ConfigureJiraSectionInner_organization on Organization {
      isJiraEnabled
      onRestrictedPlan

      ...ToggleJiraButton_organization
    }
  `,
}): RelayFragmentContainer<typeof(ConfigureJiraSectionInner)>)
