import * as R from 'ramda'
import _ from 'underscore'
import jsSHA from 'jssha'
import {parseISO, format, isValid} from 'date-fns'
import {
  warn,
  info,
  warnErr,
  debug,
} from 'utils/logger'
import formatDate from './formatDate'

function error(...args) {
  warnErr(...args)

  throw new Error(args[0])
}

function toString(val) {
  return (_.isUndefined(val) || _.isNull(val) || _.isEqual(false, val)) ? '' :
    _.isString(val) ? val.trim() : val
}

function isLocalId(id) {
  return id && id.length == 36 && id[8] == '-' && id[13] == '-'
  // && lid[18] == '-' && lid[23] == '-';
  // return (_.isString(id) && id.indexOf('-')!==-1);
}

const timeTotals = {}

const savedDomState = {}

function sha1(str) {
  const hasher = new jsSHA('SHA-1', 'TEXT')
  hasher.update(str.toString())
  return hasher.getHash('HEX')
}

function sortObject(obj) {
  // Relies on the behavior of browsers of iterating over keys in the
  // order in which they were stored.
  // http://stackoverflow.com/a/29622653/61048
  return Object.keys(obj).sort().reduce((result, key) => {
    result[key] = obj[key]
    return result
  }, {})
}

function addToBucket(object, bucketName, items) {
  object[bucketName] = (object[bucketName] || []).concat(items)
}

export default {
  debug,
  info,
  warn,
  warnErr,
  error,
  formatDate,

  reportAndContinue(e, opts) {
    let options = opts || {},
      message = options.info || R.path(['message'], e) || 'unexpected error'
    warn(message, e.message, options.object)
    if (typeof newrelic !== 'undefined')
      newrelic.noticeError(e)
  },

  assert(condition, msg) {
    if (!condition)
      error(`assertion failed: ${msg}`)
  },

  isId(maybeId) {
    return isLocalId(maybeId) || _.isNumber(maybeId)
  },

  isLocalId,

  isBlank: R.either(R.isNil, R.isEmpty),

  toString,

  optionTags(options, selected) {
    const parts = []
    _(options).each((opt) => {
      parts.splice.apply(parts, [parts.length, 0, "<option value='", opt[0], "'",
        (opt[0] === selected ? " selected='selected'" : ''), '>', opt[1], '</option>',
      ])
    })
    return parts.join('')
  },

  saveDomState() {
    if (document.activeElement && document.activeElement.id)
      savedDomState.id = document.activeElement.id
  },

  restoreDomState() {
    $(`#${savedDomState.id}`).focus()
  },

  withModel(selector, fn) {
    return function (event) {
      const $container = $(event.target).closest(selector)
      const model = this.collection.get($container.data('id') || $container.data('cid'))
      return fn.call(this, model, event)
    }
  },

  unsupportedBrowserMessage() {
    return '<div>We see that you are using an older browser. This diagram can do much more for you if you ' +
      'view it in a modern browser with more functionality. We recommend <a href="https://www.google.com/intl/en/chrome/browser/#brand=CHMB‎">Chrome</a>' +
      ', <a href="http://www.mozilla.org/firefox">FireFox</a>, or <a href="http://support.apple.com/downloads/#safari‎">Safari</a> ' +
      'for an optimal experience.</div>'
  },

  multilineTruncate(str, maxCols, maxRows) {
    let words = str.split(/\s+/), // or hyphens..
      rows = [''],
      r = 0

    while (words.length > 0) {
      let word = words.shift()
      if (rows[r].length > 0 && rows[r].length + word.length > maxCols) {
        if (r < maxRows - 1) {
          r += 1
          rows[r] = ''
        } else {
          rows[r] += `${word.substring(0, maxCols - rows[r].length - 3)}...`
          return rows.slice(0, maxRows).join('').trim()
        }
      }
      if (word.length > maxCols) {
        let splitPoint = parseInt(word.length / 3.0),
          leftoverPart = word.substring(splitPoint)
        word = word.substring(0, splitPoint)
        words.unshift(leftoverPart)
        rows[r] += `${word}`
      } else {
        rows[r] += `${word} `
      }
    }
    return rows.slice(0, maxRows).join('').trim()
  },

  mergeBuckets(object, otherObject) {
    _.each(otherObject, (v, k) => {
      addToBucket(object, k, v)
    })
  },
  sha1,

  sortObject,

  stableSha1(object) {
    return sha1(JSON.stringify(sortObject(object)))
  },

  isFirefox() {
    return /Firefox/.test(navigator.userAgent) && /Mozilla/.test(navigator.userAgent)
  },

  preResolvedPromise() {
    return {
      then(success, err) { return success() },
    }
  },

  // from https://github.com/dashed/shallowequal
  // pulled in to fix a bug with react not providing shallowCompare consistently in addOns in 0.13 or something.
  shallowEqual(objA, objB, compare, compareContext) {
    const fetchKeys = _.keys

    var ret = compare ? compare.call(compareContext, objA, objB) : 0

    if (ret !== 0)
      return !!ret

    if (objA === objB)
      return true

    if (typeof objA !== 'object' || objA === null ||
      typeof objB !== 'object' || objB === null)
      return false

    const keysA = fetchKeys(objA)
    const keysB = fetchKeys(objB)

    const len = keysA.length
    if (len !== keysB.length)
      return false

    compareContext = compareContext || null

    // Test for A's keys different from B.
    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB)
    for (let i = 0; i < len; i++) {
      const key = keysA[i]
      if (!bHasOwnProperty(key))
        return false

      const valueA = objA[key]
      const valueB = objB[key]

      var ret = compare ? compare.call(compareContext, valueA, valueB, key) : 0
      if (ret === false || ret === 0 && valueA !== valueB)
        return false
    }

    return true
  },

  findIn: R.curry(function (matcher) {
    return R.find(matcher, R.reduce(R.concat, [], R.tail(arguments)))
  }),

  // See http://stackoverflow.com/a/8809472/61048
  generateUuid() {
    let d = new Date().getTime()
    if (window.performance && typeof window.performance.now === 'function') {
      // use high-precision timer if available
      d += performance.now()
    }
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
}
