// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  AddProjectInput as Input,
  AddProjectMutationResponse as Response,
} from './__generated__/AddProjectMutation.graphql'

const mutation = graphql`
  mutation AddProjectMutation($input: AddProjectInput!) {
    addProject(input: $input) {
      projectEdge {
        node {
          databaseId
          ...Project_project

          tension {
            processed
          }

          circle {
            supportedRole {
              ...TacticalMeetingProjectsView_role
            }
          }
        }
      }

      errors {
        path
        message
        severity
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<any> => {
  const variables = {input}

  return createMutationPromise({mutation, variables})
}

export type {Input, Response}
