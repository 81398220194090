/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type WithCurrentRoleSelector_person$ref: FragmentReference;
declare export opaque type WithCurrentRoleSelector_person$fragmentType: WithCurrentRoleSelector_person$ref;
export type WithCurrentRoleSelector_person = {|
  +roles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +circle: ?{|
          +supportedRole: ?{|
            +isDisplayedAsCircle: boolean
          |}
        |},
        +$fragmentRefs: RoleSelector_roles$ref,
      |}
    |}>
  |},
  +$refType: WithCurrentRoleSelector_person$ref,
|};
export type WithCurrentRoleSelector_person$data = WithCurrentRoleSelector_person;
export type WithCurrentRoleSelector_person$key = {
  +$data?: WithCurrentRoleSelector_person$data,
  +$fragmentRefs: WithCurrentRoleSelector_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithCurrentRoleSelector_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Circle",
                  "kind": "LinkedField",
                  "name": "circle",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "supportedRole",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDisplayedAsCircle",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "nameWith",
                      "value": "CIRCLE"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "RoleSelector_roles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd4339122a9532f25130a9a0cfd8f92d7';

module.exports = node;
