/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RuleValue = "CENTRALIZED" | "CUSTOM" | "EXISTING" | "HOLACRACY" | "MODULAR_ADOPTION_BY_CIRCLE" | "UNIVERSAL";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MetricList_organization$ref: FragmentReference;
declare export opaque type MetricList_organization$fragmentType: MetricList_organization$ref;
export type MetricList_organization = {|
  +id: string,
  +onRestrictedPlan: boolean,
  +rules: $ReadOnlyArray<{|
    +ruleKey: string,
    +ruleValue: RuleValue,
  |}>,
  +$refType: MetricList_organization$ref,
|};
export type MetricList_organization$data = MetricList_organization;
export type MetricList_organization$key = {
  +$data?: MetricList_organization$data,
  +$fragmentRefs: MetricList_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricList_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onRestrictedPlan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Rule",
      "kind": "LinkedField",
      "name": "rules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ruleKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ruleValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3acff47e4a9f2287cc369393daad8e53';

module.exports = node;
