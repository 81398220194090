// @flow
import React, {type Node, Suspense, useState} from 'react'
import Proposals from 'components/ui/icons/Proposals'
import ModalLoader from 'components/ui/ModalLoader'
import preloadedLazy from 'utils/MiscUtils/preloadedLazy'

import styles from './index.scss'

type Props = {
  organizationId: string,
  circleId: string,
  tensionId: string,
}

const LazyProposalCreator = preloadedLazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ProposalCreator" */
  'components/pages/Proposals/ProposalsView/ProposalCreator'
))

export default function StartProposalButton({organizationId, circleId, tensionId}: Props): Node {
  const [showModal, setShowModal] = useState(false)

  return (
    <div id="proposal-btn">
      <button className={styles.startProposalBtn} onClick={() => { setShowModal(true) }} type="submit">
        <div>
          {'Start a proposal'}
          {/* TODO translation */}
        </div>
        <Proposals />
      </button>

      { showModal && (
        <Suspense fallback={<ModalLoader />}>
          <LazyProposalCreator
            orgDatabaseId={organizationId}
            tensionDatabaseId={tensionId}
            toggle={() => setShowModal(false)}
            circleDatabaseId={circleId}
          />
        </Suspense>
      )}
    </div>
  )
}
