// @flow
import React, {type Node, useState, useEffect} from 'react'
import {
  createRefetchContainer,
  graphql,
  type RelayRefetchProp,
  type RelayRefetchContainer,
} from 'react-relay'
import getIDGenerator from 'utils/getIDGenerator'
import AppRouter from 'utils/AppRouter'
import ModularGrid from './OrgRules/ModularGrid'
import V4ConstitutionSection from './V4ConstitutionSection'
import type {ConstitutionTab_organization as Organization}
  from './__generated__/ConstitutionTab_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  readonly: boolean,
  relay: RelayRefetchProp,
  showDescription: boolean,
}>

const generateRandom = getIDGenerator()

function ConstitutionTab({organization, readonly, relay, showDescription}: Props): Node {
  const [refetchId, setRefetchId] = useState(null)
  const {isOnV4, isOnV5} = organization

  const updateRefetchId = () => setRefetchId(generateRandom())

  useEffect(() => {
    const migrationCompleteForSure = (
      organization.v4migrationStatus === 'COMPLETED'
      && organization.v5migrationStatus === 'COMPLETED'
    )

    if (refetchId && migrationCompleteForSure) {
      setRefetchId(null)
      return
    }

    if (refetchId) {
      relay.refetch({orgDatabaseId: organization.databaseId})
      setTimeout(updateRefetchId, 1000)
    }
    // We have to refetch only on refetchId update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchId])

  useEffect(() => {
    // Checks permissions for ModularGrid case.
    if (isOnV5 && organization.viewerCanViewConstitution)
      return

    // Checks permissions for V4ConstitutionSection and V5ConstitutionSection cases.
    if (organization.viewerCanEditConstitution)
      return

    // Otherwise, redirects to ...
    const redirectPath = organization.anchorRole.resourcePath
    AppRouter.instance.redirectTo(redirectPath)
  }, [organization, isOnV5])

  if (isOnV4)
    return (<V4ConstitutionSection organization={organization} updateRefetchId={updateRefetchId} />)

  if (isOnV5) {
    return (
      <ModularGrid
        organization={organization}
        readonly={readonly}
        showDescription={showDescription}
        updateRefetchId={updateRefetchId}
      />
    )
  }

  return null // Not possible case for now.
}

export default (createRefetchContainer(
  ConstitutionTab,
  {
    organization: graphql`
    fragment ConstitutionTab_organization on Organization {
      databaseId
      isOnV4
      isOnV5
      v4migrationStatus
      v5migrationStatus
      resourcePath
      viewerCanEditConstitution
      viewerCanViewConstitution

      anchorRole {
        resourcePath
      }
        
      ...V4ConstitutionSection_organization
      ...ModularGrid_organization
    }
  `,
  },
  graphql`
    query ConstitutionTabRefetchQuery($orgDatabaseId: String!) {
      organization(databaseId: $orgDatabaseId) {
        ...ConstitutionTab_organization
      }
    }
  `,
): RelayRefetchContainer<typeof(ConstitutionTab)>)
