// @flow strict
import {warnErr} from 'utils/logger'

class LocalStorageCell<Data> {
  constructor(key: string) {
    this.key = key
  }

  key: string

  get(): Data | null {
    try {
      return JSON.parse(localStorage.getItem(this.key) || 'null')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`LocalStorageCell: unable to parse localStorage@${this.key}`, e)
      return null
    }
  }

  set(data: Data) {
    const item = JSON.stringify(data)
    if (item === null || item === undefined)
      return

    try {
      localStorage.setItem(this.key, item)
    } catch (e) {
      warnErr(e)
    }
  }

  isPresent(): boolean {
    return localStorage.getItem(this.key) !== null
  }
}

export default LocalStorageCell
