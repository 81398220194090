import $ from 'jquery'
// Some global-used stuff

// eslint-disable-next-line func-names
$.fn.hasOverflow = function () {
  const $this = $(this)
  const $children = $this.find('*')
  const len = $children.length

  if (len) {
    let maxWidth = 0
    let maxHeight = 0
    // eslint-disable-next-line func-names
    $children.map(function () {
      maxWidth = Math.max(maxWidth, $(this).outerWidth(true))
      maxHeight = Math.max(maxHeight, $(this).outerHeight(true))

      return null
    })

    return maxWidth > $this.width() || maxHeight > $this.height()
  }

  return false
}

function setTipsy() {
  $('.tipsy').remove()
  $('.use-tipsy')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: $.fn.tipsy.autoWE,
    })
  $('.use-tipsy-html')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: $.fn.tipsy.autoWE,
      html: true,
    })
  $('.small-icon')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: 'w',
    })
  $('.use-tipsy-s')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: 's',
    })
  $('.use-tipsy-html-sw')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: 'sw',
      html: true,
    })
  $('.use-tipsy-html-n')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: 'n',
      html: true,
    })
  $('.use-tipsy-html-nw')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: 'nw',
      html: true,
    })
  $('.use-tipsy-html-sticky')
    .tipsy({
      live: true,
      opacity: 1.0,
      fade: false,
      gravity: $.fn.tipsy.autoWE,
      html: true,
      delayOut: 100,
      hoverlock: true,
    })
}

$(document).ready(() => {
  $('input, textarea, email, password, url').placeholder()
  setTipsy()
})

$(document).on('dialogopen', () => {
  $(window).resize(() => {
    $('.ui-dialog-content').dialog('option', 'position', 'center')
  })
})

// potentially generic delete-link handling thingy- lets you specify attr in the anchor telling you
// what DOM node to remove if the server response is successful.
$(document).on('ajax:success', 'a[data-on-success-remove]', (event) => {
  const selector = $(event.target).data('on-success-remove')
  const $parent = $(selector).parent()
  $(selector).remove()
  $parent.killWhitespace()
})

$(document).on('ajax:success', 'a[data-on-success-replace],form[data-on-success-replace]', (event, data) => {
  const selector = $(event.target).data('on-success-replace')
  $(selector).replaceWith(data)
  setTipsy()
})

$(document).on('ajax:success', 'a[data-on-success-append],form[data-on-success-append]', (event, data) => {
  const selector = $(event.target).data('on-success-append')
  $(selector).append(data)
})

$(document).on('ajax:success', 'a[data-on-success-before],form[data-on-success-before]', (event, data) => {
  const selector = $(event.target).data('on-success-before')
  $(selector).before(data)
})

$(document).on('ajax:success', 'a[data-on-success-invoke],form[data-on-success-invoke]', (event, data, object) => {
  const methodName = $(event.target).data('on-success-invoke')
  window[methodName](event, data, object)
})

$(document).on('ajax:success', 'form[data-on-complete-clear-input]', (event) => {
  const form = $(event.target)
  // eslint-disable-next-line func-names
  form.find('input[type=text], textarea').each(function () {
    $(this).val('')
  })
  // eslint-disable-next-line func-names
  form.find('input[type=checkbox]').each(function () {
    $(this).removeAttr('checked')
  })
})

if (!window.gf)
  window.gf = {} // poor man's namespace
