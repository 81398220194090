/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type OutputForm_circle$ref = any;
type OutputForm_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EditOutput_meeting$ref: FragmentReference;
declare export opaque type EditOutput_meeting$fragmentType: EditOutput_meeting$ref;
export type EditOutput_meeting = {|
  +circle: {|
    +$fragmentRefs: OutputForm_circle$ref
  |},
  +$fragmentRefs: OutputForm_meeting$ref,
  +$refType: EditOutput_meeting$ref,
|};
export type EditOutput_meeting$data = EditOutput_meeting;
export type EditOutput_meeting$key = {
  +$data?: EditOutput_meeting$data,
  +$fragmentRefs: EditOutput_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditOutput_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OutputForm_circle"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputForm_meeting"
    }
  ],
  "type": "TacticalMeeting",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '72bc371abcc478c33fb3217ff8e40ba6';

module.exports = node;
