// @flow
import type {Element, Node} from 'react'
import React from 'react'
import {UncontrolledTooltip} from 'reactstrap'
import useUniqueId from 'utils/hooks/useUniqueId'
import classnames from 'classnames'

type Props = $ReadOnly<{
  className: string,
  disabled: boolean,
  label: Node,
  placement: string,
  tooltipContent: string,
  href: string,
  target: ?string,
  rel: ?string,
}>

const tooltipModifiers = [{name: 'preventOverflow', options: {boundary: 'window'}}]
const tooltipDelay = {hide: 10}

function LinkWithTooltip({
  className: linkClassName,
  disabled,
  label,
  placement,
  tooltipContent,
  target, href, rel,
}: Props): Element<string> {
  const [id] = useUniqueId()

  const className = classnames(linkClassName, {disabled})

  const fakeDisabledLink = {
    className,
    id,
  }

  const realLinkProps = {
    ...fakeDisabledLink,
    target,
    href,
    rel,
  }

  const MaybeLink = disabled ? 'span' : 'a'
  const linkProps = disabled ? fakeDisabledLink : realLinkProps

  return (
    <MaybeLink {...linkProps}>
      <UncontrolledTooltip
        autohide={false}
        delay={tooltipDelay}
        modifiers={tooltipModifiers}
        placement={placement}
        target={id}
      >
        <span>{tooltipContent}</span>
      </UncontrolledTooltip>
      {label}
    </MaybeLink>
  )
}

LinkWithTooltip.defaultProps = {
  placement: 'left',
  disabled: false,
  target: null,
  rel: null,
  href: '',
}

export default LinkWithTooltip
