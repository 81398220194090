// @flow
import {graphql} from 'react-relay'

import ChartNodes from 'components/OrgNav/OrgChart/ChartNodes'
import requestSubscription from '../requestSubscription'

import type {
  OrganizationSubscriptionVariables as Variables,
  OrganizationSubscriptionResponse as Response,
} from './__generated__/OrganizationSubscription.graphql'

const subscription = graphql`
  subscription OrganizationSubscription($orgDatabaseId: String!) {
    organizationEvent(orgDatabaseId: $orgDatabaseId) {
      type: __typename

      ... on MeetingEndedEvent {
        meeting {
          id
          __typename
          joinMeetingPath

          circle {
            supportedRole {
              resourcePath
            }
          }
        }
      }
    }
  }
`

export default function OrganizationSubscription(
  variables: Variables,
  onNext: ?(Response) => void,
): any {
  return requestSubscription<Variables, Response>(
    subscription,
    variables,
    onNext,
    (store) => {
      const event = store.getRootField('organizationEvent')
      if (!event)
        return

      const isMeetingClosedEvent = event.getType() === 'MeetingEndedEvent'
      if (!isMeetingClosedEvent)
        return

      const meeting = event.getLinkedRecord('meeting')

      if (!meeting)
        return

      const isGovMeeting = meeting.getType() === 'GovernanceMeeting'
      store.invalidateStore()
      if (isGovMeeting)
        ChartNodes.destroyInstances(variables.orgDatabaseId)
    },
  )
}
