/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TacticalOutputType = "ACTION" | "INFORMATION" | "PROJECT" | "TENSION" | "TRIGGER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputAdditionalInfo_output$ref: FragmentReference;
declare export opaque type OutputAdditionalInfo_output$fragmentType: OutputAdditionalInfo_output$ref;
export type OutputAdditionalInfo_output = {|
  +type: TacticalOutputType,
  +trigger: ?string,
  +tensionBody: ?string,
  +$refType: OutputAdditionalInfo_output$ref,
|};
export type OutputAdditionalInfo_output$data = OutputAdditionalInfo_output;
export type OutputAdditionalInfo_output$key = {
  +$data?: OutputAdditionalInfo_output$data,
  +$fragmentRefs: OutputAdditionalInfo_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputAdditionalInfo_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trigger",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tensionBody",
      "storageKey": null
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fdb451829fc5821b765fc65d4e406e2b';

module.exports = node;
