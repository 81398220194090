/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type NotificationType = "DANGER" | "INFO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FlashNotification_notification$ref: FragmentReference;
declare export opaque type FlashNotification_notification$fragmentType: FlashNotification_notification$ref;
export type FlashNotification_notification = {|
  +id: string,
  +message: string,
  +timeout: number,
  +type: NotificationType,
  +closable: boolean,
  +action: ?{|
    +message: string,
    +callbackId: string,
  |},
  +$refType: FlashNotification_notification$ref,
|};
export type FlashNotification_notification$data = FlashNotification_notification;
export type FlashNotification_notification$key = {
  +$data?: FlashNotification_notification$data,
  +$fragmentRefs: FlashNotification_notification$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlashNotification_notification",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationAction",
          "kind": "LinkedField",
          "name": "action",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "callbackId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Notification",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d55c3a533e03323b9462bd49f4353cd';

module.exports = node;
