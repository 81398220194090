// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import {compareFunction} from 'utils/MiscUtils/latinizeString'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import AddMetricMutation, {type Response} from 'mutations/Metrics/AddMetricMutation'
import MetricFormModal from 'components/metrics/MetricFormModal'

import type {
  CreateMetricModalQuery as Query,
} from './__generated__/CreateMetricModalQuery.graphql'

type Props = $ReadOnly<{
  orgId: string,
  circleDatabaseId: string,
  close: () => void,
  onSubmit: Response => void,
}>

const query = graphql`
  query CreateMetricModalQuery($orgId: ID!, $circleDatabaseId: String!) {
    organization: node(id: $orgId) {
      ... on Organization {
        circle(databaseId: $circleDatabaseId) {
          id

          supportedRole {
            id
            localizedName

            parentRole {
              __typename
            }
          }

          roles {
            edges {
              node {
                id
                localizedName
              }
            }
          }
        }
        
        ...MetricFormModal_organization
      }
    }
  }
`

const initialValues = {
  description: '',
  roleId: null,
  frequency: 'Weekly',
  customFrequency: null,
  link: '',
}

function CreateMetricModal({
  orgId,
  circleDatabaseId,
  close,
  onSubmit,
}: Props): null | Node {
  const {organization} = useLazyLoadQuery<Query>(query, {
    orgId,
    circleDatabaseId,
  })
  const circle = organization?.circle

  if (!circle || !organization)
    return null

  const submit = async (values) => {
    // for some reason, Flow doesn't like passing values as is,
    //  so `{...values}` is kinda workaround to make Flow happy
    const response = await AddMetricMutation({...values}, circle.id)

    onSubmit(response)

    return response
  }

  const roles = [
    circle.supportedRole?.parentRole ? circle.supportedRole : null,
    ...liftNodes(circle.roles),
  ]
    .filter(Boolean)
    .sort((roleA, roleB) => compareFunction(roleA.localizedName, roleB.localizedName))

  return (
    <MetricFormModal
      initialValues={initialValues}
      close={close}
      submit={submit}
      organization={organization}
      roles={roles}
      modalTitle={I18n.t('metrics.add_metric')}
    />
  )
}

CreateMetricModal.defaultProps = {
  onSubmit: () => {},
}

export default CreateMetricModal
