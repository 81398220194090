/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomProfileFieldsList_person$ref: FragmentReference;
declare export opaque type CustomProfileFieldsList_person$fragmentType: CustomProfileFieldsList_person$ref;
export type CustomProfileFieldsList_person = {|
  +customSettings: {|
    +customProfileFields: $ReadOnlyArray<{|
      +name: string,
      +value: ?string,
    |}>
  |},
  +$refType: CustomProfileFieldsList_person$ref,
|};
export type CustomProfileFieldsList_person$data = CustomProfileFieldsList_person;
export type CustomProfileFieldsList_person$key = {
  +$data?: CustomProfileFieldsList_person$data,
  +$fragmentRefs: CustomProfileFieldsList_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomProfileFieldsList_person",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "scope",
          "value": "ORGANIZATION"
        }
      ],
      "concreteType": "CustomSettings",
      "kind": "LinkedField",
      "name": "customSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomProfileFieldMapping",
          "kind": "LinkedField",
          "name": "customProfileFields",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "customSettings(scope:\"ORGANIZATION\")"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0710e4c43b6e4a0a1dc9ce9856e4135d';

module.exports = node;
