/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChecklistItem_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChecklistsItemsSection_circle$ref: FragmentReference;
declare export opaque type ChecklistsItemsSection_circle$fragmentType: ChecklistsItemsSection_circle$ref;
export type ChecklistsItemsSection_circle = {|
  +$fragmentRefs: ChecklistItem_circle$ref,
  +$refType: ChecklistsItemsSection_circle$ref,
|};
export type ChecklistsItemsSection_circle$data = ChecklistsItemsSection_circle;
export type ChecklistsItemsSection_circle$key = {
  +$data?: ChecklistsItemsSection_circle$data,
  +$fragmentRefs: ChecklistsItemsSection_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChecklistsItemsSection_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChecklistItem_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '76b023a3b341bfce84e363a932ae11d1';

module.exports = node;
