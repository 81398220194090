// @flow
import OrgnavRouter from 'utils/OrgnavRouter'
import {offset} from '@floating-ui/dom'

export const tourOptions = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const buttonConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      tour.next()
    },
  },
])

type Props = {
  tour: Object,
  history: Object,
  roleDatabaseId: string,
  orgDatabaseId: string,
}

const tooltip = (linkText, tipKey, orgDatabaseId) => `<a href="/organizations/${orgDatabaseId}/dialogs/tooltip/${tipKey}" class="async-popover" data-container=".shepherd-element:visible">${linkText}</a>`

export const getSteps = ({tour, history, roleDatabaseId, orgDatabaseId}: Props): Object => ([
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: ''}))
      },
    },
    buttons: buttonConfig(tour),
    title: 'Guide to Circle and Role Pages',
    text: [`<div><p>This is a ${tooltip('circle', 'circle', orgDatabaseId)} or role page. </p> 
       <p>All information regarding the ${tooltip('circle', 'circle', orgDatabaseId)}
       or role can be found here.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-circle_name > div',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>This is the name of the ${tooltip('circle', 'circle', orgDatabaseId)} or 
        role.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-follow_button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    buttons: buttonConfig(tour),
    text: [`<div><p>Here you can follow or unfollow the 
        ${tooltip('circle', 'circle', orgDatabaseId)}/role.
        Following will ensure you get emails regarding any updates that occur.</p>
        <p>You automatically follow ${tooltip('circles', 'circle', orgDatabaseId)} and 
        roles you are a member of.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-tags_button',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    buttons: buttonConfig(tour),
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>Here you can hide and show the
        ${tooltip('circle', 'circle', orgDatabaseId)} or role's tags.
        Tags can be really handy in searches.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-threeDot',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>This menu allows you to manage various settings for the
        ${tooltip('circle', 'circle', orgDatabaseId)}/role and offers features such as printing 
        its details or ${tooltip('governance', 'governance', orgDatabaseId)}.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-meeting_trigger',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>From here you can start a meeting for the
        ${tooltip('circle', 'circle', orgDatabaseId)}.</p></div>`],
  },
  {
    attachTo: {
      element: '.chart-container svg',
      on: 'left',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>This section allows you to navigate your organization. You can click on
     ${tooltip('circles', 'circle', orgDatabaseId)} and roles in the diagram or on the names below to
     their page.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-role_browser',
      on: 'left',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>This area shows all of the
      ${tooltip('circles', 'circle', orgDatabaseId)} and roles that make up the
      ${tooltip('circle', 'circle', orgDatabaseId)} you are viewing.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-tab_navigation',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>Here you can navigate within the
      ${tooltip('circle', 'circle', orgDatabaseId)} or role.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'overview'}))
      },
    },
    attachTo: {
      element: '#Overview-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>The default tab is called 'Overview', and it shows general information 
      like who fills it and its ${tooltip('Purpose', 'purpose', orgDatabaseId)}.</p><div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'roles'}))
      },
    },
    attachTo: {
      element: '#Roles-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>The 'Roles' tab only shows up if you're looking at a
      ${tooltip('circle', 'circle', orgDatabaseId)}.</p>
      <p>The 'Roles' tab lists all roles within the 
      ${tooltip('circle', 'circle', orgDatabaseId)}, and is also where they are managed.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'members'}))
      },
    },
    attachTo: {
      element: '#Members-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>In 'Members' you will find a list of all
      ${tooltip('members', 'member', orgDatabaseId)} of the
      ${tooltip('circle', 'circle', orgDatabaseId)} as well as a link to email them all.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'policies'}))
      },
    },
    attachTo: {
      element: '#Policies-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>'Policies' lists all
      ${tooltip('policies', 'policies', orgDatabaseId)} that define the authority and special rules
      of the ${tooltip('circle', 'circle', orgDatabaseId)}.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'notes'}))
      },
    },
    attachTo: {
      element: '#Notes-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>And 'Notes' lists all ${tooltip('notes', 'notes', orgDatabaseId)}
        so you can better keep track and share useful information.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'projects'}))
      },
    },
    attachTo: {
      element: '#Projects-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>'Projects' offers a filterable list of all the
      ${tooltip('project', 'projects', orgDatabaseId)}s associated to the 
      ${tooltip('circle', 'circle', orgDatabaseId)}.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'checklists'}))
      },
    },
    attachTo: {
      element: '#Checklists-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['<div><p>Checklists offer a place to track recurring actions, such as \'payroll processed\'.</p></div>'],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'metrics'}))
      },
    },
    attachTo: {
      element: '#Metrics-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['<div><p>Metrics can capture data and reporting to track and share, like \'monthly revenue\'.</p></div>'],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'goals'}))
      },
    },
    attachTo: {
      element: '#Goals-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>If you organization is using the Goals & Targets feature in GlassFrog you'll be able to see
      them in the 'Goals' tab.</p></div>`],
  },
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'history'}))
      },
    },
    attachTo: {
      element: '#History-tab',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['<div><p>And finally, the \'History\' tab lists all meetings and meeting outputs.</p></div>'],
  },
])
