// @flow
import {useCallback} from 'react'
import environment from 'environment'
import flash from 'utils/MiscUtils/flash'

import UpdateProjectStatusMutation from 'mutations/UpdateProjectStatusMutation'
import {droppableIdToColumnDetails} from './ProjectsStatusColumn'

type DNDREndResult = $ReadOnly<{
  destination: ?$ReadOnly<{
    droppableId: string,
    ...
  }>,
  source: $ReadOnly<{
    droppableId: string,
    ...
  }>,
  draggableId: string,
  ...
}>

function getDetails(result: DNDREndResult) {
  const originalId = result.source.droppableId
  const destinationId = result.destination?.droppableId

  const nullResult = {
    destinationDetails: null,
    sourceDetails: null,
  }

  const destinationDetails = droppableIdToColumnDetails(destinationId)

  if (!destinationDetails)
    return nullResult

  const sourceDetails = droppableIdToColumnDetails(originalId)
  if (!sourceDetails)
    return nullResult

  return {destinationDetails, sourceDetails}
}

function getProjectDescription(projectId: string) {
  const project = environment.getStore().getSource().get(projectId)
  const projectDescription = project ? project['description(format:"MARKDOWN")'] : null

  if (typeof projectDescription !== 'string')
    return null

  if (projectDescription.length > 100)
    return `${projectDescription.substring(0, 100)}...`

  return projectDescription
}

export default function useMaybeProjectUpdate(
  setCurrentDestinationId: (string | null) => void,
  setCurrentFromId: (string | null) => void,
  finishDrag: () => void,
): ((result: DNDREndResult) => void) {
  return useCallback((result: DNDREndResult) => {
    finishDrag()
    setCurrentDestinationId(null)
    setCurrentFromId(null)

    const {
      destinationDetails,
      sourceDetails,
    } = getDetails(result)

    if (!destinationDetails || !sourceDetails)
      return

    if (destinationDetails.status === sourceDetails.status)
      return

    const input = {
      projectId: result.draggableId,
      status: destinationDetails.status,
    }

    UpdateProjectStatusMutation(input)

    if (input.status === 'archived') {
      const projectDescription = getProjectDescription(input.projectId)

      const revertProjectStatus = () => UpdateProjectStatusMutation({
        projectId: input.projectId, status: sourceDetails.status,
      })

      flash.notice(I18n.t('projects.archived_notification', {project: projectDescription}), {
        closable: true,
        placement: 'BOTTOM',
        action: {
          callback: revertProjectStatus,
          message: I18n.t('shared.undo'),
        },
      })
    }
  }, [setCurrentDestinationId, setCurrentFromId, finishDrag])
}
