// @flow
import type {Node} from 'react'
import React from 'react'

import TacticalMeetingHeader from './../../../ui/TacticalMeetingHeader'

type Props = $ReadOnly<{
  openCreator: () => void,
}>

function TacticalMeetingMetricsHeader({openCreator}: Props): Node {
  return (
    <TacticalMeetingHeader
      addTestId="tactical-add-metric"
      addButtonCaption={I18n.t('projects.my_projects.add_project')}
      openCreator={openCreator}
    />
  )
}

export default TacticalMeetingMetricsHeader
