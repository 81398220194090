// @flow
import util from './main'

// DEPRECATED
// Please, use some other way to provide the current org's id.
function currentOrgGid(): any | string {
  const id = window.gf.app.currentOrganizationGid()

  if (id)
    return id

  util.warn('There was an issue finding the current org\'s global id')
  return ''
}

export default currentOrgGid
