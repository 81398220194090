// this function sets our config once tinyMCE has been loaded (presumably from

export default () => {
  // assumes that you did `javascript_include_tag 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/4.3.2/tinymce.min.js'`
  // eslint-disable-next-line no-undef
  tinyMCE.init({
    selector: 'textarea',
    plugins: [
      'advlist autolink lists link image charmap preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table contextmenu paste code',
    ],
    toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
  })
}
