// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'
import AbstractHistoryList from 'components/ui/AbstractHistoryList'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import MeetingsList from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/HistoryBox/HistoryList/MeetingsList'
import type {
  OrganizationHistoryView_organization$key as Organization,
} from './__generated__/OrganizationHistoryView_organization.graphql'

const organizationFragment = graphql`
  fragment OrganizationHistoryView_organization on Organization @argumentDefinitions(
    count: { type: "Int" }, cursor: { type: "String" }
  ) {
    administrativeChanges(first: $count, after: $cursor) {
      count
    
      edges {
        node {
          ...MeetingsList_meetings
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`

type Props = {
  organization: Organization,
  updateCursor: string => void,
  pageSize: number,
  currentCursor: ?string,
}

function OrganizationHistoryView({
  organization: organizationKey,
  updateCursor,
  pageSize,
  currentCursor,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const meetingsConnection = organization.administrativeChanges
  const pageInfo = meetingsConnection.pageInfo
  const nextPage = pageInfo.hasNextPage ? pageInfo.endCursor : null

  const renderBody = useCallback(() => (
    <MeetingsList meetings={liftNodes(meetingsConnection)} />
  ), [meetingsConnection])

  return (
    <AbstractHistoryList
      updateCursor={updateCursor}
      currentCursor={currentCursor}
      pageSize={pageSize}
      totalCount={meetingsConnection.count}
      nextPage={nextPage}
      showPagination={!!pageInfo}
      renderBody={renderBody}
    />
  )
}

export default OrganizationHistoryView
