// @flow
import type {Element} from 'react'
import React from 'react'
import styles from './index.scss'

function UserSectionPlaceholder(): Element<"div"> {
  return (
    <div className={styles.placeholder}>
      <div className={styles.orgName} />
      <div className={styles.userName} />
    </div>
  )
}

export default UserSectionPlaceholder
