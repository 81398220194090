// @flow
import type {Element} from 'react'
import React, {useContext} from 'react'
import {useFragment, graphql} from 'react-relay'
import {isNil, isEmpty, either} from 'ramda'
import {Tooltip} from 'reactstrap'

import {useToggle} from 'utils/hooks'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import EditItemButton from 'components/ui/Inputs/EditItemButton'
import SourceIcon, {type Source} from 'components/ui/SourceIcon'
import PrivateIcon from 'components/ui/PrivateIcon'
import style from './style.scss'
import ReorderIcon from './ReorderIcon'
import type {AbstractMetric} from '../types'
import type {
  MetricItem_circle$key as Circle,
} from './__generated__/MetricItem_circle.graphql'
import {DragHandleContext} from '../ReorderableFrequencySubsection'

type Props = $ReadOnly<{
  item: AbstractMetric,
  circle: Circle,
  onEdit: ?() => void,
  disabled: boolean,
  source: Source,
  tooltip: string,
}>

const isBlank = either(isNil, isEmpty)

const circleFragment = graphql`
  fragment MetricItem_circle on Circle {
    roles(first: 100) {
      edges {
        node {
          localizedName

          people(first: 20) {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }

    members(first: 20) @connection(key: "MetricItem_members") {
      edges {
        node {
          name
        }
      }
    }
  }
`

function MetricItem({item, circle: circleKey, onEdit, disabled, tooltip, source}: Props): Element<"div"> {
  const circle = useFragment(circleFragment, circleKey)

  const [tooltipState, {toggle}] = useToggle(false)
  const maxNamesCountToShow = 20
  const id = `org-nav-role-metric-item-${item.databaseId}`
  const roleMemberNames = liftNodes(item.people).map((member) => member.name)
  const members = liftNodes(circle.members)

  const dragHandleProps = useContext(DragHandleContext)

  const globalItemMemberNames = () => {
    // TODO: this should be moved to a server side
    const globalItemRole = liftNodes(circle.roles)
      .find((role) => role.localizedName === item.localizedRoleName)

    return liftNodes(globalItemRole?.people).map((person) => person.name)
  }

  const peopleNames = () => {
    if (item.appliesToAllMembers)
      return members.map((member) => member.name)

    if (item.isGlobal) {
      const memberNames = globalItemMemberNames()

      return memberNames.length === 0 ? [I18n.t('org_chart.unfilled_role')] : memberNames
    }

    if (roleMemberNames.length === 0)
      return [I18n.t('org_chart.unfilled_role')]

    return roleMemberNames
  }

  const peopleNamesSample = peopleNames().slice(0, maxNamesCountToShow)

  const renderPlainDescription = () => (
    <div className="org-nav-role-metric-item__description">
      {item.description}
    </div>
  )

  const renderDescriptionWithLink = () => (
    <a
      className="org-nav-role-metric-item__description"
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {item.description}
    </a>
  )

  const renderDescription = () => (
    isBlank(item.link)
      ? renderPlainDescription()
      : renderDescriptionWithLink()
  )

  return (
    <div id={id} className="org-nav-role-metric-item">
      <div className={style.reorderControl}>
        {dragHandleProps && (
          <span title={I18n.t('nav.reorder')} {...dragHandleProps}>
            <ReorderIcon />
          </span>
        )}
      </div>
      <div className="org-nav-role-metric-item__content">
        <div className="org-nav-role-metric-item__title">
          <span id={`orgnav-role-metric-item-name-${id}`}>
            {item.localizedRoleName}
          </span>
          <Tooltip
            placement="left"
            isOpen={tooltipState}
            target={`orgnav-role-metric-item-name-${id}`}
            toggle={toggle}
            delay={{hide: 10}}
          >
            {peopleNamesSample.map((name) => (
              <span key={name}>
                {name}
                <br />
              </span>
            ))}
            {peopleNames.length > maxNamesCountToShow
              ? (
                <span>
                  {'...'}
                  <br />
                </span>
              )
              : null
            }
          </Tooltip>
        </div>
        <div className={style.descriptionContainer}>
          {renderDescription()}
          {item.privateToCircle && (
            <PrivateIcon privacyText={I18n.t('metrics.private_to_circle')} />
          )}
          <div className={style.sourceContainer}>
            <SourceIcon
              className={style.sourceIcon}
              source={source}
            />
            {source === 'internal' ? I18n.t('shared.enum.source.internal') : I18n.t('shared.enum.source.external')}
          </div>
        </div>
      </div>
      <div className={style.controlButtons}>
        {onEdit && (
          <EditItemButton
            onClick={onEdit}
            disabled={disabled}
            tooltip={{body: tooltip}}
            viewContext="tab"
          />
        )}
      </div>
    </div>
  )
}

MetricItem.defaultProps = {
  onEdit: null,
  disabled: false,
  tooltip: '',
}

export default MetricItem
