/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectFormModalView_tension$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalFragmented_tension$ref: FragmentReference;
declare export opaque type ProjectFormModalFragmented_tension$fragmentType: ProjectFormModalFragmented_tension$ref;
export type ProjectFormModalFragmented_tension = {|
  +$fragmentRefs: ProjectFormModalView_tension$ref,
  +$refType: ProjectFormModalFragmented_tension$ref,
|};
export type ProjectFormModalFragmented_tension$data = ProjectFormModalFragmented_tension;
export type ProjectFormModalFragmented_tension$key = {
  +$data?: ProjectFormModalFragmented_tension$data,
  +$fragmentRefs: ProjectFormModalFragmented_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalFragmented_tension",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectFormModalView_tension"
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '39e968cf4f424ca76810b0e438855fff';

module.exports = node;
