// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateAmendmentInput as Input,
  UpdateAmendmentMutationResponse as Response,
} from './__generated__/UpdateAmendmentMutation.graphql'

const mutation = graphql`
  mutation UpdateAmendmentMutation($input: UpdateAmendmentInput!) {
    updateAmendment(input: $input) {
      amendment {
        ...Section_amendment
      }
    }
  }
`

const UpdateAmendmentMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  return createMutationPromise({mutation, variables})
}

export default UpdateAmendmentMutation
