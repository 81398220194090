// @flow
export type Event = $ReadOnly<{
  title: string,
  description?: string,
  startDatetime: Date,
  endDatetime: Date,
  location: string,
}>

export type CalendarType = 'Google' | 'Outlook' | 'iCal'

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl'

export const CalendarTypes = {
  Google: 'Google',
  ICal: 'iCal',
  Outlook: 'Outlook',
}

export const AvailableCalendars = ['Google', 'iCal', 'Outlook']
