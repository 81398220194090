// @flow
import type {ComponentType, Node} from 'react'
import React, {lazy, useState, Suspense} from 'react'
import LoadingDots from 'components/ui/LoadingDots'
import styles from './index.scss'

const WYSIWYGTextarea: ComponentType<*> | string = lazy(() => import(
  /* webpackChunkName: "WYSIWYGTextarea" */
  '../WYSIWYGTextarea'
))

export type CKAutocompleteItem = $ReadOnly<{
  link: string,
  name: string,
  id: string,
}>;

export type Props = $ReadOnly<{
  mode?: 'classic' | 'inline',
  buttonSet?: 'font-styles-only'| 'basic' | 'full',
  fieldName: string,
  fieldValue: string,
  rows?: number,
  placeholder?: string,
  getFeed?: (string) => $ReadOnlyArray<CKAutocompleteItem>,
}>

function RailsWYSIWYG({mode, buttonSet, fieldName, fieldValue, rows, placeholder, getFeed}: Props): null | Node {
  const [newValue, setNewValue] = useState(fieldValue || '')

  const updateHiddenField = (event) => {
    setNewValue(event.target.value)
  }

  return (
    <div test-id={`RailsWYSIWYG-${fieldName}`}>
      <Suspense fallback={<LoadingDots />}>
        <WYSIWYGTextarea
          mode={mode}
          buttonSet={buttonSet}
          name={fieldName}
          onChange={updateHiddenField}
          rows={rows}
          value={fieldValue}
          placeholder={placeholder}
          getFeed={getFeed || (() => [])}
          tinyMarginBottom
        />
      </Suspense>
      <textarea
        className={styles.hidden}
        name={fieldName}
        readOnly
        value={newValue}
      />
    </div>
  )
}

RailsWYSIWYG.defaultProps = {
  mode: 'classic',
  placeholder: '',
  rows: 5,
}

export default RailsWYSIWYG
