// @flow
import OrgnavRouter from 'utils/OrgnavRouter'
import {offset} from '@floating-ui/dom'
import {dig} from 'utils/deepAssign'
import $ from 'jquery'

export const tourOptions = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const buttonConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      tour.next()
    },
  },
])

const buttonOpenThreeDotConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      $('#sjs-threeDot').click()
      $('body').addClass('shepherd-enabled')
      tour.next()
    },
  },
])

const buttonLinkToAddSubroleConfig = ({tour, history, roleDatabaseId}) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    action: () => {
      history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: 'roles?addRole'}))
      tour.next()
    },
  },
])

const buttonSaveSubrole = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      $('#sjs-addSubroleSubmitButton').click()
      tour.next()
    },
  },
])

const tooltip = (linkText, tipKey, orgDatabaseId) => `<a href="/organizations/${orgDatabaseId}/dialogs/tooltip/${tipKey}" class="async-popover" data-container=".shepherd-element:visible">${linkText}</a>`

type Props = {
  tour: Object,
  history: Object,
  roleDatabaseId: string,
  orgDatabaseId: string,
}

export const getSteps = ({tour, history, roleDatabaseId, orgDatabaseId}: Props): Object => ([
  {
    id: '1',
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: ''}))
      },
    },
    buttons: buttonConfig(tour),
    title: 'Adding Roles and Circles',
    text: [`<div class="tour-text-container">
      <p>To add a role or ${tooltip('circle', 'circle', orgDatabaseId)}, first navigate to the 
      ${tooltip('circle', 'circle', orgDatabaseId)} you'd like to add it to.</p>
      <p>For this example, we will use the ${tooltip('anchor circle', 'anchor_circle', orgDatabaseId)}.</p>
    </div>`],
  },
  {
    id: '2',
    attachTo: {
      element: '#sjs-threeDot',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    buttons: buttonOpenThreeDotConfig(tour),
    classes: 'headerless',
    text: [`<div><p>This is the ${tooltip('circle', 'circle', orgDatabaseId)} settings menu. While your 
        organization is in setup mode or when you are the only 
        ${tooltip('member', 'member', orgDatabaseId)} of a 
        ${tooltip('circle', 'circle', orgDatabaseId)}, you can add roles from here!</p></div>`],
  },
  {
    id: '3',
    when: {
      show() {
        $('body').addClass('shepherd-enabled')
      },
    },
    attachTo: {
      element: '#sjs-addSubrole',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    buttons: buttonLinkToAddSubroleConfig({tour, history, roleDatabaseId}),
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: ['To do so, select "Add Subrole" from the circle settings menu.'],
  },
  {
    id: '4',
    buttons: ([{
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action: () => tour.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Continue',
      action: () => {
        const setFieldFn = dig(window, 'gf', 'globalComponentExposures', 'NewRoleInlineEditor', 'formikSetFieldValue')
        setFieldFn('name', 'Website Manager')
        tour.next()
      },
    }]),
    classes: 'headerless',
    text: [`<div><p>Roles (and  ${tooltip('circles', 'circle', orgDatabaseId)}) have a definition made 
        up of several elements.</p></div>`],
  },
  {
    id: '5',
    buttons: ([{
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action: () => tour.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Continue',
      action: () => {
        $('#sjs-addSubroleNameInput input').prop('value', 'Website Manager')
        $('#sjs-addSubrolePurposeInput .ck-editor__editable p').html('Effective and engaging online presence')
        tour.next()
      },
    }]),
    attachTo: {
      element: '#sjs-addSubroleNameInput',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<p>The name represents the <b>title of the role</b>. It should be concise and descriptive, effectively
      conveying the primary function of the role. Bonus points whimsy and fun.</p>`],
  },
  {
    id: '6',
    buttons: ([{
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action: () => tour.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Continue',
      action: () => {
        $('#sjs-addSubroleDomainsInput .ck-editor__editable p').html('Website content, structure, and functionality')
        tour.next()
      },
    }]),
    attachTo: {
      element: '#sjs-addSubrolePurposeInput',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<p>The Purpose field outlines <b>the ultimate outcome or aspirational goal of the role</b>. This key
      element defines the role's authority and provides a clear vision of its primary objective and impact within the
      organization, articulated in one concise statement.</p>`],
  },
  {
    id: '7',
    buttons: ([{
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action: () => tour.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Continue',
      action: () => {
        $('#sjs-addSubroleAccountabilitiesInput .ck-editor__editable p').html('Updating, maintaining, and optimizing website structure and content')
        tour.next()
      },
    }]),
    attachTo: {
      element: '#sjs-addSubroleDomainsInput',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<p>A Domain is <b>any asset, process, or other item over which the role has exclusive control</b> for
        its purpose. It's important to minimize the number of Domains in your organization, as each
        Domain restricts what other roles can do, potentially slowing down the organization's overall speed and
        efficiency.</p>`],
  },
  {
    id: '8',
    buttons: buttonConfig(tour),
    attachTo: {
      element: '#sjs-addSubroleAccountabilitiesInput',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<p>Accountabilities are the <b>ongoing activities that the role will manage and execute</b> to serve other
        roles or support the role's Purpose.  These responsibilities usually start with '-ing' verbs like 'building',
        'monitoring' or 'reporting'.  Avoid using vague terms like 'ensuring' or 'managing' to maintain specificity
        and clarity.</p>`],
  },
  {
    id: '9',
    buttons: buttonSaveSubrole(tour),
    attachTo: {
      element: '#sjs-addSubroleSubmitButton',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>Once you've defined the role, click 'Save' to add it, or 'Cancel' if you change your 
        mind.</p></div>`],
  },
  {
    id: '10',
    buttons: buttonConfig(tour),
    attachTo: {
      element: '#sjs-threeDot',
      on: 'bottom',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    classes: 'headerless',
    text: [`<div><p>While in setup mode, you can delete any role or 
       ${tooltip('circle', 'circle', orgDatabaseId)} by navigating to it, clicking the
       role/${tooltip('circle', 'circle', orgDatabaseId)} settings menu, and then clicking 
       'Remove Role'.</p></div>`],
  },
])
