// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import IconButton from 'components/ui/Inputs/IconButton'
import DeleteTacticalOutputMutation from 'mutations/DeleteTacticalOutputMutation'
import {useVoidCallback} from 'utils/hooks'
import Pencil from 'components/ui/icons/fa/Pencil'
import Trashcan from 'components/ui/icons/fa/Trashcan'
import styles from './index.scss'

import type {
  OutputActions_output as Output,
} from './__generated__/OutputActions_output.graphql'
import type {
  OutputActions_meeting as Meeting,
} from './__generated__/OutputActions_meeting.graphql'

type Props = $ReadOnly<{
  output: Output,
  meeting: Meeting,
  startEditing: () => void,
}>

function OutputActions({output, meeting, startEditing}: Props): Node {
  const deleteItem = useVoidCallback(() => (
    DeleteTacticalOutputMutation({outputId: output.id}, meeting.id)
  ))

  return (
    <div className={styles.controlsContainer}>
      <IconButton
        onClick={startEditing}
        size="sm"
        tabIndex="-1"
        testId="output-edit-button"
        title={I18n.t('shared.edit')}
      >
        <Pencil />
      </IconButton>
      <IconButton
        onClick={deleteItem}
        size="sm"
        tabIndex="-1"
        testId="output-delete-button"
        title={I18n.t('shared.delete')}
      >
        <Trashcan />
      </IconButton>
    </div>
  )
}

export default (createFragmentContainer(OutputActions, {
  output: graphql`
    fragment OutputActions_output on TacticalOutput {
      id
    }
  `,
  meeting: graphql`
    fragment OutputActions_meeting on TacticalMeeting {
      id
    }
  `,
}): RelayFragmentContainer<typeof(OutputActions)>)
