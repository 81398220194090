// @flow
import type {Element} from 'react'
import React from 'react'
import LinkWithTooltip from 'components/ui/LinkWithTooltip'

export type UpsellType = 'Member' | 'Admin' | null

type Props = $ReadOnly<{
  accountId: number,
  upsell: UpsellType,
}>

function UpsellMessage({accountId, upsell}: Props): null | Element<"div"> {
  if (!upsell)
    return null

  let tooltipTitle = null
  let link = null

  if (upsell === 'Admin') {
    tooltipTitle = I18n.t('office_hours.upsell_tooltip_admin')
    link = `/accounts/${accountId}/edit`
  } else {
    tooltipTitle = I18n.t('office_hours.upsell_tooltip_non_admin')
    link = '#'
  }

  return (
    <div>
      <LinkWithTooltip
        href={link}
        tooltipContent={tooltipTitle}
        label={I18n.t('office_hours.upsell_button_text')}
        className="upsell tiny use-tipsy ml-2"
      />
    </div>
  )
}

export default UpsellMessage
