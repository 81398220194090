// @flow
import type {Element} from 'react'
import React from 'react'
import LinkWithTooltip from 'components/ui/LinkWithTooltip'

type Props = $ReadOnly<{
  linkUrl: string,
  buttonText: string,
  statusText: string,
  tooltip: string,
  disabled: boolean,
}>

function Button({linkUrl, buttonText, statusText, tooltip, disabled}: Props): Element<"div"> {
  return (
    <div className="office-hour-button">
      {statusText && (
        <span className="status test-status tiny-caption d-inline-block ml-2">
          {statusText}
        </span>
      )}
      <LinkWithTooltip
        label={buttonText}
        className="btn test-btn btn-primary btn-sm d-inline-block"
        href={linkUrl}
        target="_blank"
        disabled={disabled}
        rel="noopener noreferrer"
        tooltipContent={tooltip}
        placement="right"
      />
    </div>
  )
}

Button.defaultProps = {
  disabled: false,
  statusText: '',
}

export default Button
