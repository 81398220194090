// @flow
import React, {forwardRef, type Node} from 'react'
import classNames from 'classnames'

import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
  className?: ?string,
  'test-id'?: string,
}>

function InputContainer({children, className, 'test-id': testId}: Props, ref) {
  const inputContainerClassName = classNames(styles.container, className)

  return (
    <div
      test-id={testId}
      ref={ref}
      className={inputContainerClassName}
    >
      {children}
    </div>
  )
}

export default (forwardRef<Props, HTMLDivElement>(InputContainer): React$AbstractComponent<Props, HTMLDivElement>)
