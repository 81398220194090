/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PeopleSelector_people$ref = any;
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleAndPersonSelector_circle$ref: FragmentReference;
declare export opaque type RoleAndPersonSelector_circle$fragmentType: RoleAndPersonSelector_circle$ref;
export type RoleAndPersonSelector_circle = {|
  +individualAction: {|
    +id: string
  |},
  +supportedRole: ?{|
    +id: string,
    +isExpanded: boolean,
    +assignments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: {|
            +id: string,
            +$fragmentRefs: PeopleSelector_people$ref,
          |}
        |}
      |}>
    |},
    +$fragmentRefs: RoleSelector_roles$ref,
  |},
  +roles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +isIndividualAction: boolean,
        +assignments: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +person: {|
                +id: string,
                +$fragmentRefs: PeopleSelector_people$ref,
              |}
            |}
          |}>
        |},
        +$fragmentRefs: RoleSelector_roles$ref,
      |}
    |}>
  |},
  +members: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: PeopleSelector_people$ref,
      |}
    |}>
  |},
  +$refType: RoleAndPersonSelector_circle$ref,
|};
export type RoleAndPersonSelector_circle$data = RoleAndPersonSelector_circle;
export type RoleAndPersonSelector_circle$key = {
  +$data?: RoleAndPersonSelector_circle$data,
  +$fragmentRefs: RoleAndPersonSelector_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PeopleSelector_people"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RoleSelector_roles"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleAndPersonSelector_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "individualAction",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isExpanded",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeFocusRoles",
          "value": true
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isIndividualAction",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(includeFocusRoles:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea138aea882cc32590eaadbcc2bf642c';

module.exports = node;
