// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {QueryRenderer, graphql} from 'react-relay'
import environment from 'environment'
import withErrorCheck from 'decorators/withErrorCheck'
import OrganizationContextProviderFragment from './OrganizationContextProviderFragment'
import type {
  OrganizationContextProviderQueryVariables as Variables,
  OrganizationContextProviderQueryResponse as Response,
} from './__generated__/OrganizationContextProviderQuery.graphql'

const query = graphql`
  query OrganizationContextProviderQuery(
    $orgDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      ...OrganizationContextProviderFragment_organization
    }
  }
`
type Prop = {|
  orgDatabaseId: string,
  children: Node,
|}

export default function OrganizationContextProviderQuery({orgDatabaseId, children}: Prop): null | Node {
  const renderer = useCallback(({organization}: Response) => (
    <OrganizationContextProviderFragment organization={organization}>
      {/* don't render children until we have context; they may depend on context value */}
      {organization && children}
    </OrganizationContextProviderFragment>
  ), [children])

  const variables: Variables = {orgDatabaseId}

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={withErrorCheck(renderer)}
    />
  )
}
