import React from 'react'

import RenderDataComponent from 'legacy/components/RenderDataComponent'

// DEPRECATED
function data(...path) {
  // FIXME ? this getData call is here basically to validate the arguments before
  // passing on to the subcomponent.
  //
  // if an error here, we can easily find the calling component in the stack trace.
  // if the error occurs later inside RenderDataComponent getInitialData,
  // even finding what the calling component is is difficult, and tracing to the exact
  // line number seems impossible.
  //
  // at the very least, this should be contextual on environment.
  gf.db.getData(...path)

  return <RenderDataComponent path={path} />
}

const render = {data}

export default render
