/**
 * @flow
 * @relayHash ab40873a077e5cf8b3e609e216f22ecb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TensionEditor_tension$ref = any;
export type TensionMeetingType = "GOVERNANCE" | "TACTICAL";
export type UpdateTensionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  tensionId: string,
  meetingId?: ?string,
  roleId?: ?string,
  impactedRoleId?: ?string,
  body?: ?string,
  agendaLabel?: ?string,
  meetingType?: ?TensionMeetingType,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateTensionMutationVariables = {|
  input: UpdateTensionInput
|};
export type UpdateTensionMutationResponse = {|
  +updateTension: ?{|
    +tension: ?{|
      +id: string,
      +databaseId: string,
      +body: string,
      +agendaLabel: ?string,
      +processed: boolean,
      +impactedRole: ?{|
        +databaseId: string,
        +localizedName: string,
      |},
      +agendaItems: {|
        +count: number
      |},
      +$fragmentRefs: TensionEditor_tension$ref,
    |}
  |}
|};
export type UpdateTensionMutation = {|
  variables: UpdateTensionMutationVariables,
  response: UpdateTensionMutationResponse,
|};
*/


/*
mutation UpdateTensionMutation(
  $input: UpdateTensionInput!
) {
  updateTension(input: $input) {
    tension {
      id
      databaseId
      body
      agendaLabel
      processed
      impactedRole {
        databaseId
        localizedName
        id
      }
      agendaItems {
        count
      }
      ...TensionEditor_tension
    }
  }
}

fragment SelectRole_tension on Tension {
  availableRoles {
    edges {
      node {
        id
        nameWithCircleForCoreRoles: localizedName(with: CIRCLE_FOR_CORE_ROLES)
      }
    }
  }
  circle {
    supportedRole {
      id
      databaseId
      isExpanded
      nameWithCircleForCoreRoles: localizedName(with: CIRCLE_FOR_CORE_ROLES)
    }
    roles {
      edges {
        node {
          id
          nameWithCircleForCoreRoles: localizedName(with: CIRCLE_FOR_CORE_ROLES)
        }
      }
    }
    id
  }
}

fragment TensionEditor_tension on Tension {
  body
  databaseId
  id
  isAutogenerated
  impactedRole {
    id
  }
  ...SelectRole_tension
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agendaLabel",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processed",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AgendaItemConnection",
  "kind": "LinkedField",
  "name": "agendaItems",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": "nameWithCircleForCoreRoles",
  "args": [
    {
      "kind": "Literal",
      "name": "with",
      "value": "CIRCLE_FOR_CORE_ROLES"
    }
  ],
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RoleEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTensionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTensionPayload",
        "kind": "LinkedField",
        "name": "updateTension",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "impactedRole",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TensionEditor_tension"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTensionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTensionPayload",
        "kind": "LinkedField",
        "name": "updateTension",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tension",
            "kind": "LinkedField",
            "name": "tension",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "impactedRole",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAutogenerated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "availableRoles",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "supportedRole",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isExpanded",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ab40873a077e5cf8b3e609e216f22ecb",
    "metadata": {},
    "name": "UpdateTensionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb0006c486d4fb1f217acaa42778858a';

module.exports = node;
