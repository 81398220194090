// @flow
import React, {type Node} from 'react'
import {useFragment, graphql} from 'react-relay'
import TensionInfo from 'components/tensions/ui/TensionInfo'
import NoneTensionInfo from 'components/tensions/ui/NoneTensionInfo'
import type {
  AgendaItemGroup_agendaItem$key as AgendaItemKey,
} from './__generated__/AgendaItemGroup_agendaItem.graphql'
import type {
  AgendaItemGroup_meeting$key as MeetingKey,
} from './__generated__/AgendaItemGroup_meeting.graphql'

type Props = $ReadOnly<{
  agendaItemKey: AgendaItemKey | null,
  meetingKey: MeetingKey,
  outputList: ?Array<Node>,
  testId?: string
}>

const meetingFragment = graphql`
  fragment AgendaItemGroup_meeting on TacticalMeeting {
    organization {
      viewer {
        id
      }
      
      ...TensionInfo_organization
    }
    
    ...Output_meeting
  }
`

const agendaItemFragment = graphql`
  fragment AgendaItemGroup_agendaItem on AgendaItem {
    label
    createdAt
    
    tension {
      body
      
      impactedRole {
        localizedName
      }
    }

    person {
      id
      name
      isActive
    }
  }
`

function AgendaItemGroup({agendaItemKey, meetingKey, outputList, testId}: Props): Node {
  const agendaItem = useFragment(agendaItemFragment, agendaItemKey)
  const meeting = useFragment(meetingFragment, meetingKey)

  const personName = () => (agendaItem && agendaItem.person?.isActive ? agendaItem.person.name : I18n.t('shared.removed'))

  return (
    <div className="box box--list" test-id={testId}>
      {agendaItem && (
        <TensionInfo
          organization={meeting.organization}
          marginBottom="NONE"
          agendaLabel={agendaItem.label}
          personName={personName()}
          sensedByViewer={agendaItem.person?.id === meeting.organization.viewer.id}
          roleName={agendaItem.tension?.impactedRole?.localizedName}
          tensionCreatedAt={agendaItem.createdAt}
          tensionBody={agendaItem.tension?.body}
        />
      )}
      {!agendaItem && (
        <NoneTensionInfo marginBottom="NONE" />
      )}
      {outputList}
    </div>
  )
}

export default AgendaItemGroup
