// @flow
import React, {type Node} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import classNames from 'classnames'

import ButtonWithProcessing from 'components/ui/ButtonWithProcessing'
import Markdown from 'components/ui/Markdown'
import UpdateOrganizationMutation from 'mutations/UpdateOrganizationMutation'
import confirmTurnOffMessage from '../confirmTurnOffMessage'

import styles from './index.scss'

import type {Organization} from './types'

type Props = $ReadOnly<{
  organization: Organization,
}>

function SetupModeBanner({organization}: Props): Node {
  const disableSetupMode = () => {
    UpdateOrganizationMutation({
      organizationId: organization.id,
      setupModeEnabled: false,
    })
  }

  if (!organization.setupModeEnabled)
    return null

  const bannerClassName = classNames('banner', styles.setupModeBanner)
  const routes = organization.viewer.routes

  const bannerText = I18n.t('admin.setup_mode.org_in_setup_mode', {link: routes.setupModeArticleUrl})

  return (
    <div className={bannerClassName}>
      <div className="banner__content">
        <div>
          <Markdown
            disallowedTypes={['p']}
            unwrapDisallowed
            source={bannerText}
          />
        </div>
        {organization.viewerIsAdmin && (
          <ButtonWithProcessing
            color="reset"
            className={styles.launchButton}
            onClick={disableSetupMode}
            confirmMsg={confirmTurnOffMessage(organization.setupModeSuspendedInvitationsCount)}
          >
            {I18n.t('admin.setup_mode.launch_your_organization')}
          </ButtonWithProcessing>
        )}
      </div>
    </div>
  )
}

export default (createFragmentContainer(SetupModeBanner, {
  organization: graphql`
    fragment SetupModeBanner_organization on Organization {
      id
      viewerIsAdmin
      setupModeEnabled
      setupModeSuspendedInvitationsCount

      viewer {
        routes {
          editConstitutionPath
          setupModeArticleUrl
        }
      }
    }
  `,
}): RelayFragmentContainer<typeof(SetupModeBanner)>)
