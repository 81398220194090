// @flow
import type {Element} from 'react'
import React from 'react'

type Props = {
  textFieldContent: string,
}

function QueryInput({textFieldContent}: Props): Element<"div"> {
  return (
    <div className="app-search__query-input">
      <label className="sr-only" htmlFor="search">
        {I18n.t('nav.search')}
      </label>
      <input
        id="search"
        name="search"
        type="text"
        size="22"
        placeholder={I18n.t('nav.search')}
        className="app-search__text-field"
        aria-required="true"
        defaultValue={textFieldContent}
      />
    </div>
  )
}

export default QueryInput
