/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MeetingItem_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MeetingsList_meetings$ref: FragmentReference;
declare export opaque type MeetingsList_meetings$fragmentType: MeetingsList_meetings$ref;
export type MeetingsList_meetings = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: MeetingItem_meeting$ref,
  +$refType: MeetingsList_meetings$ref,
|}>;
export type MeetingsList_meetings$data = MeetingsList_meetings;
export type MeetingsList_meetings$key = $ReadOnlyArray<{
  +$data?: MeetingsList_meetings$data,
  +$fragmentRefs: MeetingsList_meetings$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MeetingsList_meetings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MeetingItem_meeting"
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
// prettier-ignore
(node/*: any*/).hash = 'd8730029739334888239f10669690267';

module.exports = node;
