import {withAuth} from '../authentication'

export default function registerSubscriptions(environment, subscriptions) {
  return fetch(
    '/api/edge/graphql/subscribe',
    withAuth({
      method: 'POST',
      body: JSON.stringify({subscriptions}),
    }),
  )
}
