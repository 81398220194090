// @flow
import type {Node} from 'react'
import React, {Fragment, useCallback} from 'react'
import {compareFunction} from 'utils/MiscUtils/latinizeString'

import Modal from 'components/ui/Modal'
import {useFormik, FormikProvider, Form, Field} from 'formik'
import Input from 'components/ui/forms/Input'
import Select from 'components/ui/forms/Select'
import Checkbox from 'components/ui/forms/Checkbox'
import FrequencySelector from 'components/ui/forms/FrequencySelector'

import WithCurrentRoleSelector from 'components/roles/WithCurrentRoleSelector'
import CircleSelector from 'components/circles/CircleSelector'

import {allCircleMembersId} from 'constants/fakeId'
import {extractFrequency, frequencyToOptionValue} from 'utils/MiscUtils/frequencyHelper'

import type {Frequency, CustomFrequency} from 'constants/frequencies'
import type {ChoosableFrequency} from 'utils/MiscUtils/frequencyHelper'

import type {Circle, SimpleCircle, Role, Organization} from './types'

export type ValidValues = {
  description: string,
  roleId: string,
  frequency: ?Frequency,
  customFrequencyId: ?string,
  reportedBy: 'CIRCLE_MEMBERS' | 'ROLE_FILLERS',
  link: ?string,
  privateToCircle?: boolean,
}

export type Values = {
  description: string,
  circleId?: ?string,
  roleId: ?string,
  frequency: ?Frequency,
  customFrequency: ?CustomFrequency,
  link: ?string,
  privateToCircle?: boolean,
}

type InternalValues = {
  description: string,
  circleId: ?string,
  roleId: ?string,
  link: ?string,
  intermediateFrequency: ChoosableFrequency | string | null,
  privateToCircle?: boolean,
}

const passedValuesToInternal = ({frequency, customFrequency, ...other}: Values) => ({
  ...other,
  intermediateFrequency: frequencyToOptionValue(frequency, customFrequency),
})

type Props<Response> = $ReadOnly<{
  includeEachCircleMember: boolean,
  initialValues: Values,
  modalTitle: string,
  circle?: Circle,
  circles?: $ReadOnlyArray<SimpleCircle>,
  selectCircle: boolean,
  disabledCircle: boolean,
  roles?: $ReadOnlyArray<Role>,
  organization: Organization,
  close: () => void,
  globalItem?: boolean,
  submit: (ValidValues, string) => Promise<Response>,
  renderLeftFooterButton: (boolean) => Node,
  viewer?: Object,
}>

const sortScope = (scope) => (
  scope.filter(Boolean)
    .sort((itemA, itemB) => compareFunction(itemA.localizedName, itemB.localizedName))
)

function BasicChecklistFormModal<Response>({
  includeEachCircleMember,
  initialValues,
  modalTitle,
  circle,
  circles,
  roles: rolesScope,
  organization,
  close,
  globalItem,
  submit,
  renderLeftFooterButton,
  selectCircle,
  disabledCircle,
  viewer,
}: Props<Response>): Node {
  const roles = rolesScope ? sortScope(rolesScope) : []

  const getRoleOptions = () => ([
    includeEachCircleMember && circle?.supportedRole?.isExpanded && {
      label: I18n.t('forms.all_circle_members'),
      value: allCircleMembersId,
    },
    ...roles.map(({id, localizedName}) => ({
      label: localizedName,
      value: id,
    })),
  ].filter(Boolean))

  const selectedRoleCircleId = (roleId) => {
    if (!roleId)
      return null

    const selectedRole = [...roles].find((r) => r.id === roleId) || null

    return selectedRole?.parentCircle?.id
  }

  const form = useFormik<InternalValues>({
    initialValues: passedValuesToInternal(initialValues),
    onSubmit: async ({roleId, intermediateFrequency, circleId, ...values}) => {
      const actualCircleId = selectedRoleCircleId(roleId) || circle?.id || circleId

      const currentCircle = circle || circles?.find((item) => (item.id === circleId))

      if (!currentCircle)
        return

      const actualRoleId = roleId === allCircleMembersId
        ? currentCircle.supportedRole?.id
        : roleId

      if (!actualRoleId || !actualCircleId)
        return

      const reportedBy = roleId === allCircleMembersId
        ? 'CIRCLE_MEMBERS'
        : 'ROLE_FILLERS'

      const frequencyData = intermediateFrequency ? extractFrequency(intermediateFrequency) : {}

      await submit({
        roleId: actualRoleId,
        reportedBy,
        frequency: frequencyData?.frequency,
        customFrequencyId: frequencyData?.customFrequencyId,
        ...values,
      }, actualCircleId)
      close()
    },
    validate: (values) => {
      const errors = {}

      const requiredMessage = I18n.t('errors.messages.required')

      if (!values.description)
        errors.description = requiredMessage
      if (selectCircle && !values.circleId)
        errors.circleId = requiredMessage
      if (!values.roleId)
        errors.roleId = requiredMessage
      if (!values.intermediateFrequency)
        errors.intermediateFrequency = requiredMessage

      return errors
    },
  })

  const handleRoleChange = useCallback(({circleValue, roleValue}) => {
    form.setValues({
      ...form.values,
      circleId: circleValue || form.values.circleId,
      roleId: roleValue,
    })
  }, [form])

  const renderRoleAndCircleSelect = () => {
    if (selectCircle) {
      return (
        <Fragment>
          <Field
            test-id="checklist-circle"
            label={I18n.t('projects.my_projects.circle')}
            as={CircleSelector}
            person={viewer}
            circles={circles}
            useAsValue="id"
            name="circleId"
            placeholder={I18n.t('projects.my_projects.select_circle')}
            warning={form.errors.circleId}
            disabled={disabledCircle}
          />
          <Field
            test-id="checklist-role"
            as={WithCurrentRoleSelector}
            circleValue={form.values.circleId}
            label={I18n.t('projects.my_projects.role')}
            useEachCircleMember
            onRoleChange={handleRoleChange}
            person={viewer}
            useAsValue="id"
            name="roleId"
            placeholder={I18n.t('projects.my_projects.select_role')}
            warning={form.errors.roleId}
          />
        </Fragment>
      )
    }
    return (
      <Field
        test-id="checklist-role"
        as={Select}
        circle={circle}
        label={I18n.t('forms.reported_by_label')}
        placeholder={I18n.t('forms.select_role')}
        name="roleId"
        options={getRoleOptions()}
        warning={form.errors.roleId}
      />
    )
  }

  return (
    <FormikProvider value={form}>
      <Modal
        close={close}
        size="md"
      >
        <Form>
          <Modal.Header
            close={close}
            title={modalTitle}
          />
          <Modal.Body>
            <Field
              as={Input}
              name="description"
              label={I18n.t('forms.description_label')}
              warning={form.errors.description}
            />
            {renderRoleAndCircleSelect()}
            <Field
              test-id="checklist-frequency"
              as={FrequencySelector}
              organization={organization}
              name="intermediateFrequency"
              label={I18n.t('forms.frequency_label')}
              warning={form.errors.intermediateFrequency}
            />
            <Field
              as={Input}
              name="link"
              label={I18n.t('forms.link_label')}
            />
            {!globalItem && (
              <Field
                as={Checkbox}
                name="privateToCircle"
                label="Private to circle"
                type="checkbox"
                disabled={organization.onRestrictedPlan}
                premiumCalloutType={organization.onRestrictedPlan ? 'checklist_item_privacy' : null}
              />
            )}
          </Modal.Body>
          <Modal.Footer
            rightBlock={(
              <button
                className="btn btn-primary btn-lg"
                disabled={!form.isValid || form.isSubmitting}
                type="submit"
              >
                {I18n.t('shared.save')}
              </button>
            )}
            leftBlock={renderLeftFooterButton(form.isSubmitting)}
          />
        </Form>
      </Modal>
    </FormikProvider>
  )
}

BasicChecklistFormModal.defaultProps = {
  renderLeftFooterButton: (): null => null,
  selectCircle: false,
  disabledCircle: false,
}

export default BasicChecklistFormModal
