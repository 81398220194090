/**
 * @flow
 * @relayHash df210b4bf20efae9fb7768474f7e8351
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChecklistItem_checklistItem$ref = any;
export type ChecklistReportedBy = "CIRCLE_MEMBERS" | "ROLE_FILLERS";
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type OwnerType = "CIRCLE_MEMBERS" | "FACILITATOR" | "LEAD_LINK" | "REP_LINK" | "ROLE_FILLERS" | "SECRETARY";
export type UpdateChecklistItemInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  checklistItemId: string,
  description?: ?string,
  frequency?: ?Frequency,
  customFrequencyId?: ?string,
  link?: ?string,
  roleId?: ?string,
  reportedBy: ChecklistReportedBy,
  privateToCircle?: ?boolean,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateChecklistItemMutationVariables = {|
  input: UpdateChecklistItemInput
|};
export type UpdateChecklistItemMutationResponse = {|
  +updateChecklistItem: ?{|
    +checklistItem: ?{|
      +appliesTo: OwnerType,
      +role: ?{|
        +localizedName: string
      |},
      +$fragmentRefs: ChecklistItem_checklistItem$ref,
    |}
  |}
|};
export type UpdateChecklistItemMutation = {|
  variables: UpdateChecklistItemMutationVariables,
  response: UpdateChecklistItemMutationResponse,
|};
*/


/*
mutation UpdateChecklistItemMutation(
  $input: UpdateChecklistItemInput!
) {
  updateChecklistItem(input: $input) {
    checklistItem {
      appliesTo
      role {
        localizedName
        id
      }
      ...ChecklistItem_checklistItem
      id
    }
  }
}

fragment ChecklistItem_checklistItem on ChecklistItem {
  frequency
  localizedFrequency
  localizedRoleName
  description
  link
  privateToCircle
  customFrequency {
    id
    name
    universalUnits
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appliesTo",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateChecklistItemPayload",
        "kind": "LinkedField",
        "name": "updateChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItem",
            "kind": "LinkedField",
            "name": "checklistItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChecklistItem_checklistItem"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateChecklistItemPayload",
        "kind": "LinkedField",
        "name": "updateChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItem",
            "kind": "LinkedField",
            "name": "checklistItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "frequency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedFrequency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedRoleName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomFrequency",
                "kind": "LinkedField",
                "name": "customFrequency",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "universalUnits",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "df210b4bf20efae9fb7768474f7e8351",
    "metadata": {},
    "name": "UpdateChecklistItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58001f3dc211684539d9af96fce7af8c';

module.exports = node;
