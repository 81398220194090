// @flow
import React, {type Node, Suspense} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import useModalsState from 'components/metrics/useModalsState'
import CreateMetricModal from 'components/metrics/CreateMetricModal'
import MetricsSection from 'components/metrics/MetricsSection'
import ModalLoader from 'components/ui/ModalLoader'
import TacticalMeetingMetricsHeader from './TacticalMeetingMetricsHeader'
import TacticalMeetingContainer from './../../ui/TacticalMeetingContainer'

import type {
  TacticalMeetingMetricsView_circle as Circle,
} from './__generated__/TacticalMeetingMetricsView_circle.graphql'

type Props = $ReadOnly<{
  circle: Circle,
}>

function TacticalMeetingMetricsView({circle}: Props): Node {
  const metrics = liftNodes(circle.metrics)
  const [modalsState, {closeModal, openCreator}] = useModalsState()

  return (
    <div test-id="tactical-metrics">
      <TacticalMeetingMetricsHeader
        openCreator={openCreator}
      />
      {modalsState.type === 'CREATE_METRIC' && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <CreateMetricModal
              orgId={circle.organization.id}
              circleDatabaseId={circle.databaseId}
              close={closeModal}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
      <TacticalMeetingContainer>
        <MetricsSection
          showNoItemsMessage
          metrics={metrics}
          circle={circle}
        />
      </TacticalMeetingContainer>
    </div>
  )
}

export default (createFragmentContainer(TacticalMeetingMetricsView, {
  circle: graphql`
    fragment TacticalMeetingMetricsView_circle on Circle {
      id
      databaseId
      
      organization {
        id
      }

      metrics(first: 100) @connection(key: "TacticalMeetingMetricsView_metrics") {
        edges {
          node {
            ...MetricsSection_metrics
          }
        }
      }
      
      ...MetricsSection_circle
    }
  `,
}): RelayFragmentContainer<typeof(TacticalMeetingMetricsView)>)
