/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RemovableItems_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleTemplateFormModal_organization$ref: FragmentReference;
declare export opaque type RoleTemplateFormModal_organization$fragmentType: RoleTemplateFormModal_organization$ref;
export type RoleTemplateFormModal_organization = {|
  +$fragmentRefs: RemovableItems_organization$ref,
  +$refType: RoleTemplateFormModal_organization$ref,
|};
export type RoleTemplateFormModal_organization$data = RoleTemplateFormModal_organization;
export type RoleTemplateFormModal_organization$key = {
  +$data?: RoleTemplateFormModal_organization$data,
  +$fragmentRefs: RoleTemplateFormModal_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleTemplateFormModal_organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemovableItems_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4fd4995be10ff528bff2b6464ef5dcf1';

module.exports = node;
