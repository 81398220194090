// @flow
import type {Node} from 'react'
import React from 'react'
import NoItemsMessage from 'components/ui/NoItemsMessage'

function NoItems(): Node {
  return <NoItemsMessage message={I18n.t('shared.none')} />
}

export default NoItems
