// @flow
import type {Node} from 'react'
import React from 'react'
import {DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap'
import {AvailableCalendars, CalendarTypes} from 'utils/calendarHelpers/types'
import GoogleEventButton from './GoogleEventButton'
import IcsEventButton from './IcsEventButton'

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl'

type Props = $ReadOnly<{
  startsAt: Date,
  endsAt: Date,
  title: string,
  location: string,
  label: string,
  size: ButtonSize,
  className: string,
}>

function AddEventToCalendarButton({
  startsAt, endsAt,
  title, location,
  label,
  size, className,
}: Props): Node {
  const event = {
    startDatetime: startsAt,
    endDatetime: endsAt,
    title,
    location,
  }

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle
        size={size}
        className={className}
        caret
      >
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {AvailableCalendars.map((item) => (item === CalendarTypes.Google ? (
          <GoogleEventButton
            key={item}
            event={event}
            label={item}
          />
        ) : (
          <IcsEventButton
            key={item}
            event={event}
            label={item}
          />
        )))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

AddEventToCalendarButton.defaultProps = {
  size: 'md',
  className: '',
}

export default AddEventToCalendarButton
