import React from 'react'
import createReactClass from 'create-react-class'
import {assoc} from 'ramda'
import GupsMixin from 'legacy_support/GupsMixin'

/* eslint-disable jsx-a11y/anchor-is-valid */

const HabitBannerComponent = createReactClass({
  displayName: 'HabitBannerComponent',

  mixins: [GupsMixin],

  getDataMap() {
    return {
      settings: ['people', gf.app.currentPersonId(), 'settings'],
    }
  },

  banner_state() {
    return !this.state.settings.habit_banner_1_hidden_at ? 1 : 2
  },

  hideBanner() {
    const timeStamp = Math.floor(Date.now() / 1000)
    const newSettings = assoc(`habit_banner_${this.banner_state()}_hidden_at`, timeStamp, this.state.settings)
    this.setState({bannerDismissed: true})
    this.toggleBanner(newSettings)
  },

  toggleBanner(settings) {
    return this.dispatch({
      type: 'DEFAULT_UPDATE',
      resourceType: 'people',
      resourceId: gf.app.currentPersonId(),
      data: {
        settings,
      },
    })
  },

  render() {
    if (!this.state.settings || this.state.bannerDismissed)
      return null

    return (
      <div className="banner">
        <div
          className="banner__content test-banner-content text-center"
        >
          {I18n.t(`holacracy_help.holacracy_habits.banner_notice${this.banner_state() === 2 ? '_2' : ''}`)}
          <a
            href="/lessons"
            className="banner-link pl-2 header--xsmall"
          >
            {I18n.t('holacracy_help.holacracy_habits.banner_notice_yes')}
          </a>
          <a
            href="#"
            onClick={this.hideBanner}
            className="banner-link pl-2 header--xsmall test-habit-banner-dismiss"
          >
            {I18n.t('holacracy_help.holacracy_habits.banner_notice_no')}
          </a>
        </div>
        <a
          href="#"
          onClick={this.hideBanner}
          className="banner__close test-banner-close"
        >
          <i className="fa fa-close" />
        </a>
      </div>
    )
  },
})

export default HabitBannerComponent
