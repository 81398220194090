// @flow
import React, {type Node, Suspense} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import ModalLoader from 'components/ui/ModalLoader'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import ChecklistsItemsSection from 'components/checklistItems/ChecklistsItemsSection'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import CreateChecklistItemModal from 'components/checklistItems/CreateChecklistItemModal'
import useModalsState from 'components/checklistItems/useModalsState'
import TacticalMeetingChecklistItemsHeader from './TacticalMeetingChecklistItemsHeader'
import TacticalMeetingContainer from './../../ui/TacticalMeetingContainer'
import type {
  TacticalMeetingChecklistItemsView_circle as Circle,
} from './__generated__/TacticalMeetingChecklistItemsView_circle.graphql'

type Props = $ReadOnly<{
  circle: Circle,
}>

function TacticalMeetingChecklistItemsView({circle}: Props): Node {
  const checklistItems = liftNodes(circle.checklistItems)
  const [modalsState, {closeModal, openCreator}] = useModalsState()

  return (
    <div test-id="tactical-checklists">
      {modalsState.type === 'CREATE_CHECKLIST_ITEM' && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <CreateChecklistItemModal
              orgId={circle.organization.id}
              circleDatabaseId={circle.databaseId}
              close={closeModal}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
      <TacticalMeetingChecklistItemsHeader
        openCreator={openCreator}
      />
      <TacticalMeetingContainer>
        <ChecklistsItemsSection
          showNoItemsMessage
          circle={circle}
          checklistItems={checklistItems}
        />
      </TacticalMeetingContainer>
    </div>
  )
}

export default (createFragmentContainer(TacticalMeetingChecklistItemsView, {
  circle: graphql`
    fragment TacticalMeetingChecklistItemsView_circle on Circle {
      databaseId
      
      organization {
        id
      }

      checklistItems(first: 100) @connection(key: "TacticalMeetingChecklistItemsView_checklistItems") {
        edges {
          node {
            ...ChecklistsItemsSection_checklistItems
          }
        }
      }

      ...ChecklistsItemsSection_circle
    }
  `,
}): RelayFragmentContainer<typeof(TacticalMeetingChecklistItemsView)>)
