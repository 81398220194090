import Backbone from 'backbone'
import _ from 'underscore'
import $ from 'jquery'

Backbone.$ = $

Backbone.Model.prototype._save = Backbone.Model.prototype.save
Backbone.Model.prototype.save = function () {
  const _this = this
  const args = _.toArray(arguments)

  if (this.isNew() && this.request) {
    // In the case that there is an outstanding ajax request,
    // wait p

    this.request.always(() => {
      Backbone.Model.prototype._save.apply(_this, args)
    })
  } else {
    this.request = Backbone.Model.prototype._save.apply(_this, args)
  }
}
