import React, {useRef, useEffect, useMemo} from 'react'
import classNames from 'classnames'
import SystemIcon from 'components/ui/icons/HammerAndWrench'
import Markdown from 'components/ui/Markdown'
import Message from '../Message'
import {type MessageType} from '../MessageType'
import {visibleTypes} from '../Message/renderableMessage'
import styles from './index.scss'

type Props = {
  messages: Array<MessageType>,
  ui: any,
  debugMode?: boolean,
  messagesPath: string,
};

function MessagesArea({messages, ui, debugMode, messagesPath}: Props) {
  const messagesArea = useRef()

  useEffect(() => {
    // scroll down
    messagesArea.current.scrollTop = messagesArea.current.scrollHeight
  }, [])

  const initialSystemMessages = []
  const restOfMessages = []
  let seenNonSystemMessage = false
  let filteredMsgs = messages

  if (!debugMode) {
    filteredMsgs = filteredMsgs.filter((msg) => {
      const hasVisibleRole = visibleTypes.includes(msg.role)
      const isFunctionCallOrErrorMsg = (msg.role === 'assistant' && msg.function_call) || msg.error_message
      return hasVisibleRole && !isFunctionCallOrErrorMsg
    })
  }

  filteredMsgs.forEach((message) => {
    if (!debugMode && !visibleTypes.includes(message.role))
      return

    if (message.role === 'system' && !seenNonSystemMessage) {
      initialSystemMessages.push(message)
    } else {
      seenNonSystemMessage = true
      restOfMessages.push(message)
    }
  })

  const lastMessage = restOfMessages[restOfMessages.length - 1]

  const images = useMemo(
    () => ({
      assistant: ui.frogbot ? (
        <img
          src={ui.frogbot.avatarUrl}
          alt={ui.frogbot.name}
          className={styles.avatar}
        />
      ) : null,
      user: ui.person ? (
        <img
          src={ui.person.avatarUrl}
          alt={ui.person.name}
          className={styles.avatar}
        />
      ) : null,
      system: <SystemIcon alt="system" className={styles.avatar} />,
    }),
    [ui.frogbot, ui.person],
  )

  const renderMessage = (message) => {
    const isLastMessage = message === lastMessage
    return (
      <Message
        key={message.id}
        message={message}
        messagesPath={messagesPath}
        images={images}
        className="message"
        chatData={message.data}
        isLastMessage={isLastMessage}
      />
    )
  }

  return (
    <div className={styles.messages} ref={messagesArea}>
      {initialSystemMessages.map((message) => renderMessage(message))}

      {ui.frogbot && (
        <div className={classNames(styles.message, styles.assistant)}>
          <img
            alt={ui.frogbot.name}
            className={styles.avatar}
            src={ui.frogbot.avatarUrl}
          />
          <div className={styles.text}>
            <Markdown source={I18n.t('ai.chat.disclaimer')} />
            <p>{I18n.t('ai.chat.how_can_i_help')}</p>
          </div>
        </div>
      )}

      {restOfMessages.map((message) => renderMessage(message))}
    </div>
  )
}

MessagesArea.defaultProps = {
  debugMode: false,
}
export type {MessageType}
export default MessagesArea
