// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import EditItemButton from 'components/ui/Inputs/EditItemButton'
import AccessibleList from 'components/ui/AccessibleList'
import ProjectDetails from 'components/projects/ProjectDetails'
import ProjectDescription from 'components/projects/ProjectDescription'
import StatusBadge from 'components/ui/StatusBadge'
import type {ViewContext} from 'components/types'
import styles from './index.scss'
import type {
  Project_project as ProjectType,
} from './__generated__/Project_project.graphql'
import type {Project_circle as Circle} from './__generated__/Project_circle.graphql'

export type {ProjectType}
export type OpenEditor = (string) => void

type Props = $ReadOnly<{
  project: ProjectType,
  showPersonName: boolean,
  showCircleName: boolean,
  showRoleName: boolean,
  viewContext: ViewContext,
  openEditor: OpenEditor,
  circle: null | Circle,
}>

function Project({
  project,
  showCircleName,
  showPersonName,
  showRoleName,
  viewContext,
  openEditor,
  circle,
}: Props): Node {
  const status = project.status.toLowerCase()

  const onClick = useCallback(
    () => {
      if (project.viewerCanUpdate)
        openEditor(project.id)
    },
    [openEditor, project],
  )

  return (
    <AccessibleList.ListRow
      rightSection={[
        project.viewerCanUpdate && (
          <EditItemButton
            key="edit-icon"
            onClick={onClick}
            viewContext={viewContext}
          />
        ),
      ]}
    >
      <AccessibleList.ListItem onClick={onClick}>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.description}><ProjectDescription project={project} /></div>
            <div className={styles.status}>
              <StatusBadge status={status} />
            </div>
          </div>
          <ProjectDetails
            project={project}
            showPersonName={showPersonName}
            showCircleName={showCircleName || project.role.isLinked}
            showRoleName={showRoleName}
            circle={circle}
          />
        </div>
      </AccessibleList.ListItem>
    </AccessibleList.ListRow>
  )
}

Project.defaultProps = {
  showCircleName: false,
  showPersonName: false,
  showRoleName: false,
  circle: null,
}

export default (createFragmentContainer(Project, {
  project: graphql`
    fragment Project_project on Project {
      id
      status
      viewerCanUpdate
      visibility
      privateToPerson

      role {
        isLinked
      }

      circle {
        databaseId
      }

      ...ProjectDetails_project
      ...ProjectDescription_project
    }
  `,
  circle: graphql`
    fragment Project_circle on Circle {
      ...ProjectDetails_circle
    }
  `,
}): RelayFragmentContainer<typeof(Project)>)
