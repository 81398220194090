// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './index.scss'

type Props = $ReadOnly<{
  title: React$Node,
  icon: React$Node,
  description: React$Node,
  button?: React$Node,
  marginRight: boolean,
}>

function Section({
  title,
  icon,
  description,
  button,
  marginRight,
}: Props): Element<"div"> {
  const containerClassName = classNames(styles.section, {
    [styles.sectionWithMarginRight]: marginRight,
  })
  return (
    <div className={containerClassName}>
      <div className={styles.icon}>
        {icon}
      </div>
      <h2 className={styles.title}>
        {title}
      </h2>
      <div className={styles.description}>
        {description}
      </div>
      {button && (
        <div className={styles.button}>
          {button}
        </div>
      )}
    </div>
  )
}

Section.defaultProps = {
  button: null,
  description: null,
  icon: null,
  title: null,
  marginRight: false,
}

export default Section
