// @flow
import type {Element, Node} from 'react'
import React from 'react'

import type {AddingButtonConfig, RenderButtons} from './Header'
import Header from './Header'

import Body from './Body'
import {box} from './index.scss'

type Props = {
  children: Node,
  id: ?string,
}

function Box({children, id}: Props): Element<"div"> {
  return (
    <div id={id} className={box}>
      {children}
    </div>
  )
}

Box.defaultProps = {
  id: null,
}

Box.Header = Header
Box.Body = Body

export default Box
export type {AddingButtonConfig, RenderButtons}
