// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import MetricsSection from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import NoItemsMessage from 'components/ui/NoItemsMessage'

import type {
  MetricsViewerSection_metrics$key as MerticKey,
} from './__generated__/MetricsViewerSection_metrics.graphql'

import PersonMetric from './PersonMetric'

type Props = $ReadOnly<{
  metrics: MerticKey,
  showNoItemsMessage: boolean,
}>

const metricsFragment = graphql`
  fragment MetricsViewerSection_metrics on Metric @relay(plural: true) {
    frequency
    localizedFrequency
    id

    appliesToAllMembers
    databaseId
    description
    link
    localizedRoleName
    isGlobal
    privateToCircle
    position

    organization {
      id
      onRestrictedPlan
    }

    customFrequency {
      id
      name
      universalUnits
    }

    ...PersonMetric_metric
  }
`

function MetricsViewerSection({
  metrics: metricsKey,
  showNoItemsMessage,
}: Props): Node {
  const metrics = useFragment(metricsFragment, metricsKey)

  if (showNoItemsMessage && metrics.length === 0)
    return <NoItemsMessage message={I18n.t('metrics.no_metrics')} />

  return (
    <MetricsSection
      hideIfEmpty
      items={metrics}
      reorderable={false}
      renderItem={(item) => (
        <PersonMetric
          key={item.id}
          item={item}
        />
      )}
    />
  )
}

export default MetricsViewerSection
