/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectDescription_project$ref = any;
type ProjectDetails_project$ref = any;
export type ProjectVisibility = "ALL" | "PRIVATE_TO_CIRCLE" | "PRIVATE_TO_PERSON";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Project_project$ref: FragmentReference;
declare export opaque type Project_project$fragmentType: Project_project$ref;
export type Project_project = {|
  +id: string,
  +status: string,
  +viewerCanUpdate: boolean,
  +visibility: ProjectVisibility,
  +privateToPerson: boolean,
  +role: {|
    +isLinked: boolean
  |},
  +circle: {|
    +databaseId: string
  |},
  +$fragmentRefs: ProjectDetails_project$ref & ProjectDescription_project$ref,
  +$refType: Project_project$ref,
|};
export type Project_project$data = Project_project;
export type Project_project$key = {
  +$data?: Project_project$data,
  +$fragmentRefs: Project_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Project_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToPerson",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLinked",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "databaseId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetails_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDescription_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0813837af995cf2ad86229ba790b6a24';

module.exports = node;
