// @flow
import {graphql} from 'react-relay'
import {ConnectionHandler} from 'relay-runtime'
import {nanoid} from 'nanoid'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  AddActionInput as RawInput,
  AddProjectActionMutationResponse as Response,
} from './__generated__/AddProjectActionMutation.graphql'

type Variables = $ReadOnly<{
  input: MutationInput<RawInput>,
}>

const mutation = graphql`
  mutation AddProjectActionMutation(
    $input: AddActionInput!
  ) {
    addAction(input: $input) {
      actionEdge {
        node {
          id
          description(format: HTML)
          rawDescription: description(format: PLAIN)
          position
          trigger
          privateToCircle
          isUpdating
          viewerCanUpdate

          circle {
            id
          }

          person {
            id
          }
        }
      }
    }
  }
`

type Input = $ReadOnly<{
  description: string,
  projectId: string,
  circleId: string,
  personId: ?string,
}>

const AddProjectAction = (input: Input): Promise<Response> => {
  const variables: Variables = {
    input: {
      ...input,
      trigger: false,
      privateToCircle: false,
    },
  }

  const configs = [{
    type: 'RANGE_ADD',
    parentID: input.projectId,
    connectionInfo: [{
      key: 'List_actions',
      rangeBehavior: 'prepend',
    }],
    edgeName: 'actionEdge',
  }]

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticUpdater: (store) => {
      const circleNode = store.get(input.circleId)
      const personNode = store.get(input.personId)
      const projectNode = store.get(input.projectId)

      const actionsConnection = ConnectionHandler.getConnection(
        projectNode,
        'List_actions',
      )
      if (!actionsConnection)
        return

      const existedActionsEdges = actionsConnection.getLinkedRecords('edges')
      if (!existedActionsEdges)
        return

      const existedActionsNodes = existedActionsEdges.filter(Boolean).map((edge) => edge.getLinkedRecord('node'))
      const existedActionsPositions = existedActionsNodes
        .map((node) => node && node.getValue('position'))
        .filter(Boolean)
        .sort()

      const lastPosition = existedActionsPositions[existedActionsPositions.length - 1]
      if (typeof lastPosition !== 'number')
        return

      const id = `client:Action:${nanoid()}`
      const actionNode = store.create(id, 'Action')
      actionNode.setValue(id, 'id')
      actionNode.setValue(input.description, 'description')
      actionNode.setValue(true, 'isUpdating')
      actionNode.setValue(false, 'privateToCircle')
      actionNode.setValue(false, 'trigger')
      actionNode.setValue(lastPosition + 1, 'position')
      actionNode.setLinkedRecord(circleNode, 'circle')
      actionNode.setLinkedRecord(personNode, 'person')

      const newActionEdge = store.create(
        `client:newEdge:${nanoid()}`,
        'ActionEdge',
      )
      newActionEdge.setLinkedRecord(actionNode, 'node')

      ConnectionHandler.insertEdgeAfter(actionsConnection, newActionEdge)
    },
  })
}

export default AddProjectAction
