/**
 * @flow
 * @relayHash d57077b5b1eb73365990018be78ce4b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditRoleTemplateModalView_organization$ref = any;
type EditRoleTemplateModalView_roleTemplate$ref = any;
export type EditRoleTemplateModalQueryVariables = {|
  roleTemplateId: string
|};
export type EditRoleTemplateModalQueryResponse = {|
  +roleTemplate: ?{|
    +organization?: {|
      +$fragmentRefs: EditRoleTemplateModalView_organization$ref
    |},
    +$fragmentRefs: EditRoleTemplateModalView_roleTemplate$ref,
  |}
|};
export type EditRoleTemplateModalQuery = {|
  variables: EditRoleTemplateModalQueryVariables,
  response: EditRoleTemplateModalQueryResponse,
|};
*/


/*
query EditRoleTemplateModalQuery(
  $roleTemplateId: ID!
) {
  roleTemplate: node(id: $roleTemplateId) {
    __typename
    ... on RoleTemplate {
      organization {
        ...EditRoleTemplateModalView_organization
        id
      }
      ...EditRoleTemplateModalView_roleTemplate
    }
    id
  }
}

fragment EditRoleTemplateModalView_organization on Organization {
  ...RoleTemplateFormModal_organization
}

fragment EditRoleTemplateModalView_roleTemplate on RoleTemplate {
  id
  name
  purpose
  domains
  accountabilities
}

fragment RemovableItem_organization on Organization {
  databaseId
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment RemovableItems_organization on Organization {
  ...RemovableItem_organization
}

fragment RoleTemplateFormModal_organization on Organization {
  ...RemovableItems_organization
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleTemplateId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "roleTemplateId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRoleTemplateModalQuery",
    "selections": [
      {
        "alias": "roleTemplate",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditRoleTemplateModalView_organization"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditRoleTemplateModalView_roleTemplate"
              }
            ],
            "type": "RoleTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditRoleTemplateModalQuery",
    "selections": [
      {
        "alias": "roleTemplate",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isWysiwygEnabled",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purpose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domains",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountabilities",
                "storageKey": null
              }
            ],
            "type": "RoleTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d57077b5b1eb73365990018be78ce4b2",
    "metadata": {},
    "name": "EditRoleTemplateModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6463488870d030e42253478acdc8d03d';

module.exports = node;
