// @flow
import type {Node} from 'react'
import React, {Fragment, useState} from 'react'
import {graphql, useFragment} from 'react-relay'
import CreateRoleTemplateModal from 'components/roleTemplates/CreateRoleTemplateModal'
import FrogTipBox from 'components/ui/FrogTipBox'
import EditRoleTemplateModal from 'components/roleTemplates/EditRoleTemplateModal'
import Button from 'components/ui/forms/Button'
import RoleTemplatesList from './RoleTemplatesList'
import style from './style.scss'
import type {
  RoleTemplatesSectionView_organization$key as Organization,
} from './__generated__/RoleTemplatesSectionView_organization.graphql'

export type Props = $ReadOnly<{
  organization: Organization,
}>

const organizationFragment = graphql`
  fragment RoleTemplatesSectionView_organization on Organization {
    ...RoleTemplatesList_organization
    ...CreateRoleTemplateModal_organization
  } 
`

type EmptyState = $ReadOnly<{state: null}>
type CreateState = $ReadOnly<{state: 'NEW'}>
type EditState = $ReadOnly<{state: 'EDIT', roleTemplateId: string}>

type ModalState = EmptyState | CreateState | EditState

const targetLabel = () => (
  <h2>{I18n.t('admin.organizations.form.tabs.role_templates')}</h2>
)

function RoleTemplatesSectionView({organization: organizationKey}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const [modalState, setModalState] = useState<ModalState>({state: null})

  const showCreate = () => setModalState({state: 'NEW'})
  const clearState = () => setModalState({state: null})
  const showEdit = (id) => setModalState({state: 'EDIT', roleTemplateId: id})

  return (
    <Fragment>
      <FrogTipBox
        helpFor={I18n.t('admin.organizations.form.tabs.role_templates')}
        tip={I18n.t('admin.organizations.form.role_template_tip')}
        target={targetLabel}
      />
      <RoleTemplatesList
        organization={organization}
        onEdit={showEdit}
      />
      <div className={style.buttonContainer}>
        <Button
          test-id="new-role-template"
          color="secondary"
          size="sm"
          onClick={showCreate}
        >
          {I18n.t('side_nav.settings_section.role_templates.add_role_template')}
        </Button>
      </div>
      {modalState.state === 'NEW' && (
        <CreateRoleTemplateModal
          close={clearState}
          organization={organization}
        />
      )}
      {modalState.state === 'EDIT' && (
        <EditRoleTemplateModal
          close={clearState}
          roleTemplateId={modalState.roleTemplateId}
        />
      )}
    </Fragment>
  )
}

export default RoleTemplatesSectionView
