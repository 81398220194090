// @flow
import OrgnavRouter from 'utils/OrgnavRouter'
import {offset} from '@floating-ui/dom'

export const tourOptions = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const buttonConfig = (tour) => ([
  {
    classes: 'shepherd-button-secondary',
    text: 'Back',
    action: () => tour.back(),
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Continue',
    action: () => {
      tour.next()
    },
  },
])

type Props = {
  tour: Object,
  history: Object,
  roleDatabaseId: string,
  orgDatabaseId: string,
}

const tooltip = (linkText, tipKey, orgDatabaseId) => `<a href="/organizations/${orgDatabaseId}/dialogs/tooltip/${tipKey}" class="async-popover" data-container=".shepherd-element:visible">${linkText}</a>`

export const getSteps = ({tour, history, roleDatabaseId, orgDatabaseId}: Props): Object => ([
  {
    when: {
      show() {
        history.push(OrgnavRouter.rolePath({roleDatabaseId, tabName: ''}))
      },
    },
    buttons: buttonConfig(tour),
    title: 'Getting around in GlassFrog',
    text: ['The left panel will help you get pretty much everywhere you need to go in GlassFrog.'],
  },
  {
    attachTo: {
      element: '#sjs-toggle_leftnav',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['Click here to show and hide the panel.'],
  },
  {
    attachTo: {
      element: '#orgName',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>Clicking your organization's name will bring you to the 
        ${tooltip('anchor circle', 'anchor_circle', orgDatabaseId)}.</p></div>`],
  },
  {
    attachTo: {
      element: '#profileLink',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: [`<div><p>Clicking on your name will take you to your profile page where you can see your</p>
       <ul>
         <li>roles and circles</li>
         <li>projects</li>
         <li>metrics</li>
         <li>checklists</li>
       </ul>
       <p>You can also click anyone's name in GlassFrog to see their profile and quickly view all of the above for 
       them.</p></div>`],
  },
  {
    attachTo: {
      element: '#sjs-sidebar_search',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['Search will query all roles, policies, notes, projects, and more across the entire organization.'],
  },
  {
    attachTo: {
      element: '#sidebar-tension-processing',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    buttons: buttonConfig(tour),
    text: [`<div><p>This is your 'Personal System' in GlassFrog.</p>
        <p>For example, 'Projects' will list all projects in GlassFrog across your 
        ${tooltip('circles', 'circle', orgDatabaseId)} and roles.</p></div>`],
  },
  {
    attachTo: {
      element: '#your-roles-section',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    buttons: buttonConfig(tour),
    modalOverlayOpeningPadding: 6,
    classes: 'headerless',
    text: [`<div><p>This areas links to all of the  ${tooltip('circles', 'circle', orgDatabaseId)} and 
      roles you are in.</p></div>`],
  },
  {
    attachTo: {
      element: '#your-organization-section',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['From here you can see general information about your organization like the rules and reporting.'],
  },
  {
    attachTo: {
      element: '.admin-section',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['If you are a GlassFrog administrator you will also see a section called \'Admin\' which allows you to '
      + 'change various GlassFrog settings.'],
  },

  {
    attachTo: {
      element: '.side-bar-org-link',
      on: 'right',
    },
    floatingUIOptions: {
      middleware: [offset({mainAxis: 12, crossAxis: 0})],
    },
    modalOverlayOpeningPadding: 6,
    buttons: buttonConfig(tour),
    classes: 'headerless',
    text: ['To return to main page you can click on the GlassFrog icon or your organization\'s name.'],
  },
])
