// @flow
import React, {type Node, useState} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import ConfigureOrganizationIntegrationMutation from 'mutations/Organizations/ConfigureOrganizationIntegrationMutation'
import type {Organization} from './types'

type Props = {
  organization: Organization,
}

const buttonText = (isJiraEnabled: boolean) => (
  isJiraEnabled
    ? I18n.t('admin.organizations.jira_integration.integration_button_disable')
    : I18n.t('admin.organizations.jira_integration.integration_button_enable')
)

const toggleEnabled = ({id, isJiraEnabled}: Organization, setMutationInFlight: Function) => async () => {
  setMutationInFlight(true)
  await ConfigureOrganizationIntegrationMutation({
    enabled: !isJiraEnabled,
    organizationId: id,
    type: 'JIRA',
  })
  setMutationInFlight(false)
}

function ToggleJiraButton({organization}: Props): Node {
  const [mutationInFlight, setMutationInFlight] = useState(false)

  return (
    <button
      className={`btn btn-${organization.isJiraEnabled ? 'warning' : 'primary'} ml-0`}
      disabled={mutationInFlight}
      onClick={toggleEnabled(organization, setMutationInFlight)}
      type="button"
    >
      {buttonText(organization.isJiraEnabled)}
    </button>
  )
}

export default (createFragmentContainer(ToggleJiraButton, {
  organization: graphql`
    fragment ToggleJiraButton_organization on Organization {
      id
      isJiraEnabled
    }
  `,
}): RelayFragmentContainer<typeof(ToggleJiraButton)>)
