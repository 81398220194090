// @flow
import React, {type Node, useContext, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import {CardContext} from 'components/ui/Accordion'
import AddAmendmentMutation from 'mutations/AddAmendmentMutation'
import AmendmentForm from '../AmendmentForm'
import type {
  CreateAmendmentBody_organization as Organization,
} from './__generated__/CreateAmendmentBody_organization.graphql'

type Props = $ReadOnly<{
  organization: Organization,
  disabled: boolean,
}>

function CreateAmendmentBody({organization, disabled}: Props): Node {
  const {toggleOpen: toggleCard} = useContext(CardContext)

  const initialValues = {
    title: '',
    body: '',
  }

  const submit = useCallback(
    async (values) => AddAmendmentMutation({organizationId: organization.id, ...values}),
    [organization],
  )

  return (
    <AmendmentForm
      organization={organization}
      initialValues={initialValues}
      disabled={disabled}
      submit={submit}
      close={toggleCard}
    />
  )
}

export default (createFragmentContainer(CreateAmendmentBody, {
  organization: graphql`
    fragment CreateAmendmentBody_organization on Organization {
      id

      ...AmendmentForm_organization
    }
  `,
}): RelayFragmentContainer<typeof(CreateAmendmentBody)>)
