// @flow
import React, {type Node, useEffect, useState} from 'react'

import logActivity from 'utils/logActivity'
import {tourOptions as addSubroleTourOptions, getSteps as addSubroleTourSteps} from 'utils/tours/addSubrole'
import {tourOptions as fillingARoleTourOptions, getSteps as fillingARoleTourSteps} from 'utils/tours/fillingARole'
import {tourOptions as usingLeftNavTourOptions, getSteps as usingLeftNavTourSteps} from 'utils/tours/usingLeftNav'
import {tourOptions as circleOverviewTourOptions, getSteps as circleOverviewTourSteps} from 'utils/tours/circleOverview'
import {tourOptions as inviteNewMembersTourOptions, getSteps as inviteNewMembersTourSteps} from 'utils/tours/inviteNewMembers'
import {useShepherdTour} from 'react-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import {useHistory} from 'react-router-dom'

const tourOptionMap = {
  fillingARole: fillingARoleTourOptions,
  addSubrole: addSubroleTourOptions,
  usingLeftNav: usingLeftNavTourOptions,
  circleOverview: circleOverviewTourOptions,
  inviteNewMembers: inviteNewMembersTourOptions,
}

const tourStepMap = {
  fillingARole: fillingARoleTourSteps,
  addSubrole: addSubroleTourSteps,
  usingLeftNav: usingLeftNavTourSteps,
  circleOverview: circleOverviewTourSteps,
  inviteNewMembers: inviteNewMembersTourSteps,
}

type Props = {
  tourName: string,
  tourStep: string,
  organizationId: string
}

function ShepherdTour({tourName, tourStep, organizationId}: Props): Node {
  const [tourStarted, setTourStarted] = useState(false)
  const history = useHistory()

  // this code handles inviteNewMembers tour
  // other tours may requires addition arguments (like roleDatabaseId).
  const tour = useShepherdTour({tourOptions: tourOptionMap[tourName], steps: []})
  tour.addSteps(tourStepMap[tourName]({
    tour,
    history,
    orgDatabaseId: organizationId,
  })).on('cancel', () => {
    logActivity(organizationId, {activity: 'tour-cancelled', target: tourName})
  }).on('complete', () => {
    logActivity(organizationId, {activity: 'tour-completed', target: tourName})
  })

  useEffect(() => {
    if (!tourStarted) {
      setTourStarted(true)
      tour.start()
      tour.show(tourStep)
    }
  }, [tour, tourStarted, tourStep])

  return (
    <span />
  )
}

export default ShepherdTour
