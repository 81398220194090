/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgendaProcessModal_tension$ref: FragmentReference;
declare export opaque type AgendaProcessModal_tension$fragmentType: AgendaProcessModal_tension$ref;
export type AgendaProcessModal_tension = {|
  +databaseId: string,
  +organization: {|
    +databaseId: string
  |},
  +$refType: AgendaProcessModal_tension$ref,
|};
export type AgendaProcessModal_tension$data = AgendaProcessModal_tension;
export type AgendaProcessModal_tension$key = {
  +$data?: AgendaProcessModal_tension$data,
  +$fragmentRefs: AgendaProcessModal_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgendaProcessModal_tension",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d67dc71e5cc0bf2f39077799778ff22';

module.exports = node;
