// @flow
import {commitLocalUpdate} from 'react-relay'
import environment from 'environment'
import {nanoid} from 'nanoid'

type NotificationType = 'DANGER' | 'INFO'
type NotificationPlacement = 'TOP' | 'BOTTOM'

type UpdateData = $ReadOnly<{
  timeout: number,
}>

const callbacks = {}

export function updateNotification(notificationId: string, data: UpdateData) {
  commitLocalUpdate(environment, (store) => {
    const notificationRecord = store.get(notificationId)
    if (notificationRecord)
      notificationRecord.setValue(data.timeout, 'timeout')
  })
}

export function deleteNotification(notificationId: string) {
  commitLocalUpdate(environment, (store) => {
    const notifications = store.getRoot().getLinkedRecords('localNotifications') || []

    const newNotifications = notifications.filter((notification) => {
      if (!notification)
        return true

      if (notification.getDataID() !== notificationId)
        return true

      const action = notification.getLinkedRecord('action')
      if (action) {
        delete callbacks[String(action.getValue('callbackId'))]
        store.delete(action.getDataID())
      }

      return false
    })

    store.delete(notificationId)

    store.getRoot().setLinkedRecords(newNotifications, 'localNotifications')
  })
}

export function getActionCallback(id: string): ?() => mixed {
  return callbacks[id]
}

type CreateData = $ReadOnly<{
  message: string,
  type: NotificationType,
}>

type Action = $ReadOnly<{
  callback: () => mixed,
  message: string,
}>

export type Options = $ReadOnly<{
  unique?: boolean,
  action?: Action,
  closable?: boolean,
  placement?: NotificationPlacement,
}>

export const defaultOptions: Options = {
  unique: false,
  closable: false,
  placement: 'TOP',
}

export function createNotification(data: CreateData, _options: Options = defaultOptions) {
  commitLocalUpdate(environment, (store) => {
    const options = {...defaultOptions, ..._options}
    const notifications = store.getRoot().getLinkedRecords('localNotifications') || []

    if (options.unique) {
      const notificationPresent = notifications.find((element) => element?.getValue('type') === data.type && element?.getValue('message') === data.message)
      if (notificationPresent)
        return
    }

    const dataId = `client:Notification:${nanoid()}`

    const newNotification = store.create(dataId, 'Notification')
    newNotification.setValue(dataId, 'id')
    newNotification.setValue(data.message, 'message')
    newNotification.setValue(5000, 'timeout')
    newNotification.setValue(data.type, 'type')

    newNotification.setValue(options.closable, 'closable')
    newNotification.setValue(options.placement, 'placement')

    if (options.action) {
      const actionId = `client:NotificationAction:${nanoid()}`

      const callbackId = nanoid()
      callbacks[callbackId] = options.action?.callback

      const action = store.create(actionId, 'NotificationAction')
      action.setValue(options.action?.message, 'message')
      action.setValue(callbackId, 'callbackId')
      newNotification.setLinkedRecord(action, 'action')
    }

    store.getRoot().setLinkedRecords([...notifications, newNotification], 'localNotifications')
  })
}
