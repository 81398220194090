// @flow
import type {Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import {ControlledShowMore as ShowMore} from 'components/ui/forms/ShowMore'
import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
  isSectionOpen: boolean,
  toggleSectionOpen: () => void,
}>

function AdvancedSettingsSection({children, isSectionOpen, toggleSectionOpen}: Props): Node {
  const showMoreClass = classNames(styles.advancedSection, {
    [styles.advancedSectionOpened]: isSectionOpen,
  })

  return (
    <ShowMore
      className={showMoreClass}
      onToggle={toggleSectionOpen}
      showMoreTitle={I18n.t('admin.organizations.org_rules.advanced_settings.toggle_button')}
      showLessTitle={I18n.t('admin.organizations.org_rules.advanced_settings.toggle_button')}
      togglePosition="right"
      isOpen={isSectionOpen}
    >
      {children}
    </ShowMore>
  )
}

export default AdvancedSettingsSection
