/**
 * @flow
 * @relayHash 1183557d71420201ed7a28d252238050
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ToggleJiraButton_organization$ref = any;
export type IntegrationType = "JIRA";
export type ConfigureOrganizationIntegrationInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  organizationId: string,
  enabled: boolean,
  type: IntegrationType,
  settings?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type ConfigureOrganizationIntegrationMutationVariables = {|
  input: ConfigureOrganizationIntegrationInput
|};
export type ConfigureOrganizationIntegrationMutationResponse = {|
  +configureOrganizationIntegration: ?{|
    +organization: {|
      +$fragmentRefs: ToggleJiraButton_organization$ref
    |}
  |}
|};
export type ConfigureOrganizationIntegrationMutation = {|
  variables: ConfigureOrganizationIntegrationMutationVariables,
  response: ConfigureOrganizationIntegrationMutationResponse,
|};
*/


/*
mutation ConfigureOrganizationIntegrationMutation(
  $input: ConfigureOrganizationIntegrationInput!
) {
  configureOrganizationIntegration(input: $input) {
    organization {
      ...ToggleJiraButton_organization
      id
    }
  }
}

fragment ToggleJiraButton_organization on Organization {
  id
  isJiraEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureOrganizationIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigureOrganizationIntegrationPayload",
        "kind": "LinkedField",
        "name": "configureOrganizationIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ToggleJiraButton_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfigureOrganizationIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfigureOrganizationIntegrationPayload",
        "kind": "LinkedField",
        "name": "configureOrganizationIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isJiraEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "1183557d71420201ed7a28d252238050",
    "metadata": {},
    "name": "ConfigureOrganizationIntegrationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a44084118f8097eb211e27236fc0438a';

module.exports = node;
