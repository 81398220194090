/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CircleAndRoleSelector_project$ref = any;
type RoleAndPersonSelector_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectOwnerSelector_project$ref: FragmentReference;
declare export opaque type ProjectOwnerSelector_project$fragmentType: ProjectOwnerSelector_project$ref;
export type ProjectOwnerSelector_project = {|
  +$fragmentRefs: RoleAndPersonSelector_project$ref & CircleAndRoleSelector_project$ref,
  +$refType: ProjectOwnerSelector_project$ref,
|};
export type ProjectOwnerSelector_project$data = ProjectOwnerSelector_project;
export type ProjectOwnerSelector_project$key = {
  +$data?: ProjectOwnerSelector_project$data,
  +$fragmentRefs: ProjectOwnerSelector_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectOwnerSelector_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RoleAndPersonSelector_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CircleAndRoleSelector_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f70d5ff256626d927028d1e0cc91f96e';

module.exports = node;
