// @flow
import type {Node} from 'react'
import React from 'react'
import environment from 'environment'
import {QueryRenderer, graphql} from 'react-relay'

import {withErrorCheck} from 'decorators'
import Preload from 'components/ui/Preload'
import AboutTab from './AboutTab'

import type {
  AboutQueryVariables as Variables,
  AboutQueryResponse as Response,
} from './__generated__/AboutQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

const aboutQuery = graphql`
  query AboutQuery(
    $orgDatabaseId: String!, $personDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      person(databaseId: $personDatabaseId) {
        ...AboutTab_person
      }

      ...AboutTab_organization
    }
  }
`

function About({orgDatabaseId, personDatabaseId}: Props): Node {
  const variables: Variables = {orgDatabaseId, personDatabaseId}

  return (
    <QueryRenderer
      environment={environment}
      query={aboutQuery}
      variables={variables}
      render={withErrorCheck((rendererProps: ?Response) => {
        const organization = rendererProps?.organization

        if (!organization)
          return <Preload />

        const person = organization.person

        if (!person)
          return <Preload />

        return <AboutTab person={person} organization={organization} />
      })}
    />
  )
}

export default About
