// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  DeleteAmendmentInput as Input,
  DeleteAmendmentMutationResponse as Response,
} from './__generated__/DeleteAmendmentMutation.graphql'

const mutation = graphql`
  mutation DeleteAmendmentMutation($input: DeleteAmendmentInput!) {
    deleteAmendment(input: $input) {
      deletedAmendmentId
    }
  }
`

const DeleteAmendmentMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedAmendmentId',
  }]

  const optimisticResponse = {
    deleteAmendment: {
      deletedAmendmentId: input.amendmentId,
    },
  }

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse,
  })
}

export default DeleteAmendmentMutation
