/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TacticalMeetingProjectsHeader_role$ref: FragmentReference;
declare export opaque type TacticalMeetingProjectsHeader_role$fragmentType: TacticalMeetingProjectsHeader_role$ref;
export type TacticalMeetingProjectsHeader_role = {|
  +databaseId: string,
  +organization: {|
    +projectsGridEnabled: boolean
  |},
  +innerCircle: ?{|
    +databaseId: string,
    +viewerCanAddProjects: boolean,
  |},
  +circle: ?{|
    +databaseId: string,
    +viewerCanAddProjects: boolean,
  |},
  +$refType: TacticalMeetingProjectsHeader_role$ref,
|};
export type TacticalMeetingProjectsHeader_role$data = TacticalMeetingProjectsHeader_role;
export type TacticalMeetingProjectsHeader_role$key = {
  +$data?: TacticalMeetingProjectsHeader_role$data,
  +$fragmentRefs: TacticalMeetingProjectsHeader_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerCanAddProjects",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TacticalMeetingProjectsHeader_role",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectsGridEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "innerCircle",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Role",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '38d397a215e877b21a59a1793c1d736f';

module.exports = node;
