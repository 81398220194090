/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectLinkIcon_project$ref: FragmentReference;
declare export opaque type ProjectLinkIcon_project$fragmentType: ProjectLinkIcon_project$ref;
export type ProjectLinkIcon_project = {|
  +link: ?any,
  +$refType: ProjectLinkIcon_project$ref,
|};
export type ProjectLinkIcon_project$data = ProjectLinkIcon_project;
export type ProjectLinkIcon_project$key = {
  +$data?: ProjectLinkIcon_project$data,
  +$fragmentRefs: ProjectLinkIcon_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectLinkIcon_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1f2910328c3c5df688604c49f0dc6a3d';

module.exports = node;
