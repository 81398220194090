/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Group_projects$ref = any;
type ProjectsStatusColumn_projects$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsGroupsOrGrid_projects$ref: FragmentReference;
declare export opaque type ProjectsGroupsOrGrid_projects$fragmentType: ProjectsGroupsOrGrid_projects$ref;
export type ProjectsGroupsOrGrid_projects = $ReadOnlyArray<{|
  +status: string,
  +description: string,
  +createdAt: any,
  +roi: ?number,
  +privateToPerson: boolean,
  +circle: {|
    +supportedRole: ?{|
      +id: string
    |},
    +id: string,
    +databaseId: string,
    +shortName: string,
  |},
  +role: {|
    +id: string,
    +localizedName: string,
    +nameWithLinkedTo: string,
    +nameWithLinkedFrom: string,
    +isSupported: boolean,
    +innerCircle: ?{|
      +id: string
    |},
  |},
  +person: ?{|
    +id: string,
    +name: string,
  |},
  +$fragmentRefs: Group_projects$ref & ProjectsStatusColumn_projects$ref,
  +$refType: ProjectsGroupsOrGrid_projects$ref,
|}>;
export type ProjectsGroupsOrGrid_projects$data = ProjectsGroupsOrGrid_projects;
export type ProjectsGroupsOrGrid_projects$key = $ReadOnlyArray<{
  +$data?: ProjectsGroupsOrGrid_projects$data,
  +$fragmentRefs: ProjectsGroupsOrGrid_projects$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectsGroupsOrGrid_projects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MARKDOWN"
        }
      ],
      "kind": "ScalarField",
      "name": "description",
      "storageKey": "description(format:\"MARKDOWN\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToPerson",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "databaseId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localizedName",
          "storageKey": null
        },
        {
          "alias": "nameWithLinkedTo",
          "args": [
            {
              "kind": "Literal",
              "name": "with",
              "value": "CIRCLE_LINKED_TO"
            }
          ],
          "kind": "ScalarField",
          "name": "localizedName",
          "storageKey": "localizedName(with:\"CIRCLE_LINKED_TO\")"
        },
        {
          "alias": "nameWithLinkedFrom",
          "args": [
            {
              "kind": "Literal",
              "name": "with",
              "value": "CIRCLE_LINKED_FROM"
            }
          ],
          "kind": "ScalarField",
          "name": "localizedName",
          "storageKey": "localizedName(with:\"CIRCLE_LINKED_FROM\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSupported",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Circle",
          "kind": "LinkedField",
          "name": "innerCircle",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Group_projects"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectsStatusColumn_projects"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ce0a17cbb159c01a733c9da3eb74a7c';

module.exports = node;
