import GlassFrogModel from 'legacy/GlassFrog/GlassFrogModel'

const Tension = GlassFrogModel.extend({}, {
  resourceName: 'tensions',
  defaults: {
    circle_id: null,
  },
})

export default Tension
