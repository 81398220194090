// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import AddViewerChecklistItemMutation from 'mutations/AddViewerChecklistItemMutation'
import ViewerChecklistFormModal from 'components/checklistItems/ViewerChecklistFormModal'

import type {
  CreateViewerChecklistItemQuery as Query,
} from './__generated__/CreateViewerChecklistItemQuery.graphql'

type Props = $ReadOnly<{
  orgId: string,
  close: () => void,
}>

const query = graphql`
  query CreateViewerChecklistItemQuery($orgId: ID!) {
    organization: node(id: $orgId) {
      ... on Organization {
        id
        onRestrictedPlan

        ...FrequencySelector_organization

        viewer {
          id
          ...ViewerChecklistFormModal_viewer
        }
      }
    }
  }
`

const initialValues = {
  description: '',
  circleId: null,
  roleId: null,
  frequency: 'Weekly',
  customFrequency: null,
  link: '',
  privateToCircle: false,
}

function CreateViewerChecklistItem({
  orgId,
  close,
}: Props): null | Node {
  const {organization} = useLazyLoadQuery<Query>(query, {orgId})
  const viewer = organization?.viewer

  if (!organization || !viewer)
    return null

  const submit = async (values, circleId): Promise<Object> => {
    const response = await AddViewerChecklistItemMutation({...values}, circleId, viewer.id)

    return response
  }

  return (
    <ViewerChecklistFormModal
      includeEachCircleMember
      initialValues={initialValues}
      close={close}
      submit={submit}
      modalTitle={I18n.t('metrics.add_checklist_item')}
      organization={organization}
      viewer={viewer}
    />
  )
}

export default CreateViewerChecklistItem
