// @flow
import pako from 'pako'
import {Buffer} from 'buffer/'

// https://stackoverflow.com/a/22675494/61048
export default (compressed: string): any => {
  // Decode base64 (convert ascii to binary)
  const bytes = atob(compressed)

  // Convert binary string to character-number array
  const charData = bytes.split('').map((x) => x.charCodeAt(0))

  // Turn number array into byte-array
  const binData = new Uint8Array(charData)

  // Pako magic (unzipping)
  const inflated = pako.inflate(binData)

  // Convert gunzipped byteArray to utf-8 string
  const strData = Buffer.from(inflated).toString('utf8')

  return JSON.parse(strData)
}
