// @flow strict
import checkImpossibleCase from 'utils/MiscUtils/checkImpossibleCase'

export type UseAsValue = 'databaseId' | 'id'

type Entity = $ReadOnly<{
  id: string,
  databaseId: string,
  ...
}>

export default function idOrDatabaseId<Item: Entity>(
  useAsValue: UseAsValue,
  item: Item,
): string {
  switch (useAsValue) {
    case 'databaseId':
      return item.databaseId
    case 'id':
      return item.id
    default:
      checkImpossibleCase(useAsValue)
      return item.id
  }
}
