// @flow
import type {Node} from 'react'
import React, {useState, useCallback, useEffect} from 'react'
import {QueryRenderer, graphql} from 'react-relay'

import environment from 'environment'
import withErrorCheck from 'decorators/withErrorCheck'
import ModalLoader from 'components/ui/ModalLoader'
import OrganizationContextProviderFragment from 'contexts/OrganizationContextProviderFragment'
import ProcessingModals from './ProcessingModals'
import {setGlobalControls, type ProcessModalType} from './globalControls'
import type {
  TensionProcessContainerQueryVariables as Variables,
  TensionProcessContainerQueryResponse as Response,
} from './__generated__/TensionProcessContainerQuery.graphql'

type ModalState = {
  tensionDatabaseId: ?string,
  processModal: ProcessModalType | null,
}

type Prop = $ReadOnly<{
  orgDatabaseId: string,
}>

const query = graphql`
  query TensionProcessContainerQuery(
    $orgDatabaseId: String!,
    $tensionDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      ...ProcessingModals_organization
      ...OrganizationContextProviderFragment_organization
      
      tension(databaseId: $tensionDatabaseId) {
        ...ProcessingModals_tension
      }

      viewer {
        ...ProcessingModals_viewer
      }
    }
  }
`

function TensionProcessContainer({orgDatabaseId}: Prop): null | Node {
  const [modalState, setModalState] = useState<ModalState>({
    tensionDatabaseId: null, processModal: null,
  })

  const closeModal = useCallback(() => setModalState({tensionDatabaseId: null, processModal: null}), [setModalState])

  const openModal = useCallback(({tensionDatabaseId, processModal}: ModalState) => {
    setModalState({tensionDatabaseId, processModal})
  }, [setModalState])

  const openTensionProcessingModal = useCallback((tensionDatabaseId: string) => {
    setModalState({tensionDatabaseId, processModal: null})
  }, [setModalState])

  useEffect(() => {
    setGlobalControls({openTensionProcessingModal, openModal})
  }, [openTensionProcessingModal, openModal])

  const isOpenModal = useCallback((modalName: ProcessModalType | null) => (
    modalState.processModal === modalName
  ), [modalState])

  const renderer = useCallback(({organization}: Response) => {
    const tension = organization?.tension
    const viewer = organization?.viewer

    if (!organization || !tension || !viewer)
      return <ModalLoader />

    return (
      <OrganizationContextProviderFragment organization={organization}>
        <ProcessingModals
          organization={organization}
          tension={tension}
          viewer={viewer}
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          openModal={openModal}
        />
      </OrganizationContextProviderFragment>
    )
  }, [closeModal, openModal, isOpenModal])

  if (!modalState.tensionDatabaseId)
    return null

  const variables: Variables = {
    orgDatabaseId,
    tensionDatabaseId: modalState.tensionDatabaseId,
  }

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={withErrorCheck(renderer)}
    />
  )
}

export default TensionProcessContainer
