import React, {lazy, Suspense} from 'react'

const OrgNavApp = lazy(() => import(/* webpackChunkName: "OrgNavApp" */ './index'))

function LazyOrgNavApp(props) {
  return (
    <Suspense fallback={null}>
      <OrgNavApp {...props} />
    </Suspense>
  )
}

export default LazyOrgNavApp
