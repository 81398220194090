import logger from 'utils/MiscUtils/main'

const jsonApiFetch = async (method, path, body) => {
  try {
    const response = await fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      logger.error(`Error fetching data: HTTP status:${response.status}`)
      return ''
    }
    return await response.json()
  } catch (error) {
    logger.error('Error fetching data:', error)
    return ''
  }
}

export default jsonApiFetch
