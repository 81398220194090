// @flow
import type {Node} from 'react'
import React, {Fragment, useMemo} from 'react'
import {graphql, useFragment} from 'react-relay'

import ProjectsGrid from 'components/projects/ProjectsGrid'
import useGroupedProjects from 'components/projects/useGroupedProjects'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import {type OpenEditor} from 'components/projects/Project'
import sortByFields from 'utils/projects/sortByFields'
import NoItemsMessage from 'components/ui/NoItemsMessage'
import type {SortBy} from 'components/pages/Projects/types'
import {type GroupBy} from 'components/projects/GroupProjectsBy'
import Group from './Group'
import type {
  ProjectsGroupsOrGrid_projects$key as Projects,
} from './__generated__/ProjectsGroupsOrGrid_projects.graphql'
import type {
  ProjectsGroupsOrGrid_circle$key as Circle,
} from './__generated__/ProjectsGroupsOrGrid_circle.graphql'

type Props = $ReadOnly<{
  groupBy: GroupBy,
  openEditor: OpenEditor,
  projects: Projects,
  roleNameMode: 'WITH_LINKED_TO'| 'WITH_LINKED_FROM',
  showAs: 'list' | 'grid',
  sortBy: SortBy,
  stickyHeaderOffset: number,
  circle: ?Circle,
  hideAvatars: boolean,
  hideSource: boolean,
  showCircleName: boolean,
  hidePersonName: boolean,
  showPersonalProjects: boolean,
}>

const projectsFragment = graphql`
  fragment ProjectsGroupsOrGrid_projects on Project @relay(plural: true) {
    status
    description(format: MARKDOWN)
    createdAt
    roi
    privateToPerson

    circle {
      supportedRole {
        id
      }

      id
      databaseId
      shortName
    }

    role {
      id
      localizedName
      nameWithLinkedTo: localizedName(with: CIRCLE_LINKED_TO)
      nameWithLinkedFrom: localizedName(with: CIRCLE_LINKED_FROM)
      isSupported

      innerCircle {
        id
      }
    }

    person {
      id
      name
    }

    ...Group_projects
    ...ProjectsStatusColumn_projects
  }
`

const circleFragment = graphql`
  fragment ProjectsGroupsOrGrid_circle on Circle {
    circleLeadRole {
      assignments {
        edges {
          node {
            person {
              id
              name
            }
          }
        }
      }
    }

    ...Group_circle
    ...ProjectsGrid_circle
  }
`

function ProjectsGroupsOrGrid({
  groupBy,
  openEditor,
  projects: projectsKey,
  roleNameMode,
  showAs,
  sortBy,
  stickyHeaderOffset,
  circle: circleKey,
  hideAvatars,
  hideSource,
  showCircleName,
  hidePersonName,
  showPersonalProjects,
}: Props): Node {
  const projects = useFragment(projectsFragment, projectsKey).filter(Boolean)
  const circle = useFragment(circleFragment, circleKey)

  const filteredProjects = projects.filter((project) => (showPersonalProjects ? true : !project.privateToPerson))

  const sortedProjects = useMemo(() => (
    sortByFields([sortBy, 'description'])(filteredProjects)
  ), [filteredProjects, sortBy])

  const circleLeads = liftNodes(circle?.circleLeadRole?.assignments).map((assignment) => assignment.person)

  const projectGroups = useGroupedProjects(sortedProjects, groupBy, roleNameMode, circleLeads)

  if (projects.length === 0 || projectGroups.length === 0)
    return <NoItemsMessage message={I18n.t('react.components.projects.no_projects')} />

  return (
    showAs === 'list'
      ? (
        <Fragment>
          {
            projectGroups.map(({key, title, items}) => (
              <Group
                title={title}
                key={key}
                projects={items}
                groupedBy={groupBy}
                openEditor={openEditor}
                circle={circle}
                showCircleName={showCircleName}
                hidePersonName={hidePersonName}
              />
            ))
          }
        </Fragment>
      )
      : (
        <ProjectsGrid
          stickyHeaderOffset={stickyHeaderOffset}
          groups={projectGroups}
          openEditor={openEditor}
          circle={circle}
          hideAvatars={hideAvatars}
          hideSource={hideSource}
        />
      )
  )
}

ProjectsGroupsOrGrid.defaultProps = {
  stickyHeaderOffset: 0,
  sortBy: 'status',
  hideAvatars: false,
  hideSource: false,
  showCircleName: false,
  hidePersonName: false,
  showPersonalProjects: false,
}

export default ProjectsGroupsOrGrid
