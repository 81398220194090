/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CircleAndRoleSelector_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectOwnerSelector_viewer$ref: FragmentReference;
declare export opaque type ProjectOwnerSelector_viewer$fragmentType: ProjectOwnerSelector_viewer$ref;
export type ProjectOwnerSelector_viewer = {|
  +$fragmentRefs: CircleAndRoleSelector_viewer$ref,
  +$refType: ProjectOwnerSelector_viewer$ref,
|};
export type ProjectOwnerSelector_viewer$data = ProjectOwnerSelector_viewer;
export type ProjectOwnerSelector_viewer$key = {
  +$data?: ProjectOwnerSelector_viewer$data,
  +$fragmentRefs: ProjectOwnerSelector_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectOwnerSelector_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CircleAndRoleSelector_viewer"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '86267c5dd6fc797178270ec1e7ade193';

module.exports = node;
