import $ from 'jquery'

window.origSelectize = $.fn.selectize
$.fn.selectizeNoLastpass = function () {
  const setIgnoreAttrs = function (event) {
    const $input = $(event.target).find(':input')
    if ($input[0]) {
      $input.attr('lpignore', true)
      $input.attr('data-lpignore', true)
    }
  }
  $(document).on('DOMNodeInserted', setIgnoreAttrs)
  // eslint-disable-next-line prefer-rest-params
  const retval = $.fn.selectize.apply(this, arguments)
  $(document).off('DOMNodeInserted', setIgnoreAttrs)
  return retval
}
