/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MeetingsList_meetings$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrganizationHistoryView_organization$ref: FragmentReference;
declare export opaque type OrganizationHistoryView_organization$fragmentType: OrganizationHistoryView_organization$ref;
export type OrganizationHistoryView_organization = {|
  +administrativeChanges: {|
    +count: number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: MeetingsList_meetings$ref
      |}
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +endCursor: ?string,
    |},
  |},
  +$refType: OrganizationHistoryView_organization$ref,
|};
export type OrganizationHistoryView_organization$data = OrganizationHistoryView_organization;
export type OrganizationHistoryView_organization$key = {
  +$data?: OrganizationHistoryView_organization$data,
  +$fragmentRefs: OrganizationHistoryView_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationHistoryView_organization",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        }
      ],
      "concreteType": "MeetingConnection",
      "kind": "LinkedField",
      "name": "administrativeChanges",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MeetingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MeetingsList_meetings"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8bdf299fa3bdbc7e0ae7df38ee91c45e';

module.exports = node;
