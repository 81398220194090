// @flow
import React, {type Node, Fragment, useState} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {useFormik, FormikProvider, Form, Field} from 'formik'
import classNames from 'classnames'

import {Accordion} from 'components/ui/Accordion'
import EditItemButton from 'components/ui/Inputs/EditItemButton'
import FrogTipBox from 'components/ui/FrogTipBox'
import PremiumCallout from 'components/ui/PremiumCallout'
import Input from 'components/ui/forms/Input'
import useToggle from 'utils/hooks/useToggle'
import UpdateOrganizationMutation from 'mutations/UpdateOrganizationMutation'
import AdvancedSettings from './AdvancedSettingsV5'
import RulesSections from './RulesSections'
import Amendments from './Amendments'
import styles from './index.scss'
import type {
  ModularGrid_organization as Organization,
} from './__generated__/ModularGrid_organization.graphql'

type Values = $ReadOnly<{
  constitutionLabel: string,
}>

type Props = $ReadOnly<{
  organization: Organization,
  readonly: boolean,
  showDescription: boolean,
  updateRefetchId: () => void,
}>

function ModularGrid({organization, readonly, showDescription, updateRefetchId}: Props): Node {
  const [amendmentFormId, setAmendmentFormId] = useState(null)

  const [isEditorOpen, {close: closeEditor, open: openEditor}] = useToggle()
  const onEdit = organization.viewerIsAdmin && !organization.onRestrictedPlan ? openEditor : null

  const initialValues = {
    constitutionLabel: organization.constitutionLabel,
  }

  const validate = (values: Values) => {
    const errors = {}

    if (!values.constitutionLabel)
      errors.roleName = I18n.t('errors.messages.required')

    return errors
  }

  const formik = useFormik<Values>({
    initialValues,
    onSubmit: async (values: Values) => {
      closeEditor()
      UpdateOrganizationMutation({
        organizationId: organization.id,
        ...values,
      })
    },
    validate,
  })

  const targetLabel = () => (
    isEditorOpen
      ? (
        <FormikProvider value={formik}>
          <Form>
            <div className={styles.editorContainer}>
              <div className={styles.itemsContainer}>
                <Field
                  autoFocus
                  as={Input}
                  name="constitutionLabel"
                  warning={formik.errors.constitutionLabel}
                  containerClassName={styles.inputContainer}
                />
              </div>
              <div className={styles.controlButtons}>
                <button
                  className={classNames(styles.controlButton, 'btn btn-secondary')}
                  disabled={formik.isSubmitting}
                  onClick={closeEditor}
                  type="button"
                >
                  {I18n.t('shared.cancel')}
                </button>
                <button
                  className={classNames(styles.controlButton, 'btn btn-primary')}
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                >
                  {I18n.t('buttons.save')}
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      ) : (
        <h2>
          {organization.constitutionLabel}
          {onEdit && (
            <div className={styles.editConstitutionLabel}>
              <EditItemButton onClick={onEdit} viewContext="tab" />
            </div>
          )}
        </h2>
      )
  )

  const frogBoxTip = () => (
    <div>
      {`${I18n.t('admin.organizations.org_rules.description')} `}
    </div>
  )

  const premiumCallout = () => (
    <PremiumCallout
      callout="admin_org_settings_rules"
      enabled
      darkIcon
    />
  )

  return (
    <Fragment>
      {showDescription && (
        <FrogTipBox
          helpFor={I18n.t('admin.organizations.form.tabs.constitution')}
          target={targetLabel}
          tip={frogBoxTip}
          additionalSection={organization.onRestrictedPlan ? premiumCallout : null}
          boxHeader
        />
      )}
      <Accordion>
        <RulesSections
          readonly={readonly}
          organization={organization}
        />
        <Amendments
          organization={organization}
          readonly={readonly}
          amendmentFormId={amendmentFormId}
          setAmendmentFormId={setAmendmentFormId}
        />
        {!readonly && (
          <AdvancedSettings
            organization={organization}
            updateRefetchId={updateRefetchId}
            setAmendmentFormId={setAmendmentFormId}
          />
        )}
        {readonly && (
          <div className={styles.readOnlyBottomMargin} />
        )}
      </Accordion>
    </Fragment>
  )
}

export default (createFragmentContainer(ModularGrid, {
  organization: graphql`
    fragment ModularGrid_organization on Organization {
      id
      constitutionLabel
      viewerIsAdmin
      onRestrictedPlan

      viewer {
        routes {
          constitutionUrl
        }
      }
      
      ...RulesSections_organization
      ...AdvancedSettingsV5_organization
      ...Amendments_organization
    }
  `,
}): RelayFragmentContainer<typeof(ModularGrid)>)
