// @flow
import type {ComponentType, Element, Node} from 'react'
import React from 'react'

import util from 'utils/MiscUtils'

const renderError = (error: Object) => {
  util.error('there was a problem', error)

  return (
    <div>{I18n.t('react.errors.talking_to_server')}</div>
  )
}

type Props = {
  props: Object,
  error: Object,
  ...
}

// eslint-disable-next-line react/function-component-definition
const index = (Wrapped: ComponentType<*>): ((Props) => Node | Element<"div">) => ({error, props, ...customProps}: Props) => (
  error
    ? renderError(error)
    : <Wrapped {...props} {...customProps} />
)

export default index
