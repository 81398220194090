// @flow
import {useCallback, useEffect, useRef} from 'react'

import type {CKAutocompleteItem} from 'components/ui/CKEditor'
import useRolesForAutocomplete, {type AutocompleteItem} from './useRolesForAutocomplete'

function toCKItemFormat(
  items: $ReadOnlyArray<AutocompleteItem>,
): $ReadOnlyArray<CKAutocompleteItem> {
  return items.map((item: AutocompleteItem) => ({
    link: item.link,
    id: `@${item.localizedName}`,
    name: item.name,
  }))
}

export default function useCKRolesFeed(
  orgDatabaseId: string,
  circleDatabaseId: string | null = null,
): (string) => $ReadOnlyArray<CKAutocompleteItem> {
  const state = useRolesForAutocomplete(orgDatabaseId, circleDatabaseId)
  const feedRef = useRef<$ReadOnlyArray<CKAutocompleteItem>>(toCKItemFormat(state))

  useEffect(() => {
    feedRef.current = toCKItemFormat(state)
  }, [state])

  return useCallback((queryString: string) => (
    feedRef.current.filter((item) => item.id.toLowerCase().includes(queryString.toLowerCase()))
  ), [feedRef])
}
