// @flow
import type {Element, Node} from 'react'
import React, {useCallback} from 'react'
import Box from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/Box'
import Pagination from 'components/ui/Pagination'

type Props = $ReadOnly<{
  updateCursor: string => void,
  currentCursor: ?string,
  pageSize: number,
  totalCount: number,
  nextPage: ?string,
  showPagination: boolean,
  renderBody: () => Node,
  header: ?string,
  paddings: boolean,
}>

function AbstractHistoryList({
  updateCursor,
  currentCursor,
  pageSize,
  totalCount,
  nextPage,
  showPagination,
  renderBody,
  header,
  paddings,
}: Props): Element<"div"> {
  const renderPagination = useCallback(() => {
    if (!showPagination)
      return null

    return (
      <Pagination
        currentPage={currentCursor}
        nextPage={nextPage}
        total={totalCount}
        onClick={updateCursor}
        pageSize={pageSize}
      />
    )
  }, [showPagination, nextPage, totalCount, currentCursor, updateCursor, pageSize])

  return (
    <div>
      <Box>
        {header && (
          <Box.Header>{I18n.t('shared.history')}</Box.Header>
        )}
        <Box.Body
          topPadding={paddings}
          bottomPadding={paddings}
        >
          {renderBody()}
        </Box.Body>
      </Box>
      {renderPagination()}
    </div>
  )
}

AbstractHistoryList.defaultProps = {
  header: null,
  paddings: false,
}

export default AbstractHistoryList
