/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreateProjectModal_circle$ref = any;
type CreateProjectModal_organization$ref = any;
type ProjectsGroupsOrGrid_circle$ref = any;
type ProjectsGroupsOrGrid_projects$ref = any;
type TacticalMeetingProjectsHeader_role$ref = any;
type UpdateProjectModal_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TacticalMeetingProjectsView_role$ref: FragmentReference;
declare export opaque type TacticalMeetingProjectsView_role$fragmentType: TacticalMeetingProjectsView_role$ref;
export type TacticalMeetingProjectsView_role = {|
  +databaseId: string,
  +organization: {|
    +databaseId: string,
    +isHotwiredProjectsEnabled: boolean,
    +$fragmentRefs: CreateProjectModal_organization$ref,
  |},
  +innerCircle: ?{|
    +id: string,
    +databaseId: string,
    +supportedRole: ?{|
      +id: string
    |},
    +$fragmentRefs: ProjectsGroupsOrGrid_circle$ref & CreateProjectModal_circle$ref,
  |},
  +projects: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: ProjectsGroupsOrGrid_projects$ref & UpdateProjectModal_project$ref,
      |}
    |}>
  |},
  +$fragmentRefs: TacticalMeetingProjectsHeader_role$ref,
  +$refType: TacticalMeetingProjectsView_role$ref,
|};
export type TacticalMeetingProjectsView_role$data = TacticalMeetingProjectsView_role;
export type TacticalMeetingProjectsView_role$key = {
  +$data?: TacticalMeetingProjectsView_role$data,
  +$fragmentRefs: TacticalMeetingProjectsView_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TacticalMeetingProjectsView_role",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHotwiredProjectsEnabled",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateProjectModal_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "innerCircle",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectsGroupsOrGrid_circle"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateProjectModal_circle"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 250
        },
        {
          "kind": "Literal",
          "name": "source",
          "value": "INTERNAL"
        }
      ],
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectsGroupsOrGrid_projects"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UpdateProjectModal_project"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(first:250,source:\"INTERNAL\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TacticalMeetingProjectsHeader_role"
    }
  ],
  "type": "Role",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '95cd2b8052987510566db989988302a0';

module.exports = node;
