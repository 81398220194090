import GlassFrogCollection from 'legacy/GlassFrog/GlassFrogCollection'
import AgendaItem from './AgendaItem'

const AgendaItemCollection = GlassFrogCollection.extend({
  model: AgendaItem,

  comparator(model) {
    return model.get('position')
  },

  initialize() {
    this.on('remove', this.hideModel, this)
  },

  hideModel(model) {
    model.trigger('hide')
  },
}, {

})

export default AgendaItemCollection
