// @flow
import React, {type Node, useEffect, useState} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import classNames from 'classnames'

import {deleteNotification, updateNotification, getActionCallback} from 'utils/localNotifications'
import Markdown from 'components/ui/Markdown'
import CloseButton from '../../CloseButton'
import styles from './index.scss'

import type {
  FlashNotification_notification as Notification,
} from './__generated__/FlashNotification_notification.graphql'

type Props = $ReadOnly<{
  notification: Notification,
}>

// should be consistent with CSS animation
const timeoutDelay = 500

function FlashNotification({notification}: Props): Node {
  const [fadeOut, setFadeOut] = useState(false)

  useEffect(() => {
    const startTimeout = new Date()

    const fadeOutTimeoutId = setTimeout(() => {
      setFadeOut(true)
    }, notification.timeout)

    const removeTimeoutId = setTimeout(() => {
      deleteNotification(notification.id)
    }, notification.timeout + timeoutDelay)

    return () => {
      clearTimeout(removeTimeoutId)
      clearTimeout(fadeOutTimeoutId)

      updateNotification(notification.id, {
        timeout: new Date() - startTimeout,
      })
    }
  }, [notification])

  const containerClass = classNames(styles.alert, {
    [styles.info]: notification.type === 'INFO',
    [styles.danger]: notification.type === 'DANGER',
    [styles.fadeOut]: fadeOut,
  })

  const buttonClass = classNames(styles.button, {
    [styles.infoButton]: notification.type === 'INFO',
    [styles.dangerButton]: notification.type === 'DANGER',
  })

  const closeNotification = () => {
    updateNotification(notification.id, {timeout: 0})
  }

  const actionCallBack = notification.action
    ? getActionCallback(notification.action.callbackId)
    : null

  const onButtonClick = actionCallBack ? () => {
    actionCallBack()
    closeNotification()
  } : () => {}

  const closeButtonClass = classNames(styles.closeButton, {
    [styles.closeButtonInfo]: notification.type === 'INFO',
    [styles.closeButtonDanger]: notification.type === 'DANGER',
  })

  return (
    <div className={containerClass}>
      <Markdown
        source={notification.message}
      />
      <div className={styles.additional}>
        {notification.action?.message && (
          <button onClick={onButtonClick} className={buttonClass} type="button">
            {notification.action.message}
          </button>
        )}
        {notification.closable
          ? <CloseButton onClick={closeNotification} className={closeButtonClass} />
          : null}
      </div>
    </div>
  )
}

export default (createFragmentContainer(FlashNotification, {
  notification: graphql`
    fragment FlashNotification_notification on Notification {
      id
      message
      timeout
      type
      closable

      action {
        message
        callbackId
      }
    }
  `,
}): RelayFragmentContainer<typeof(FlashNotification)>)
