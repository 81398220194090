// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import OrganizationSubscription from 'subscriptions/OrganizationSubscription'
import Preload from 'components/ui/Preload'
import TacticalMeetingProjectsView from './TacticalMeetingProjectsView'

import type {
  TacticalMeetingProjectsQuery as Query,
  TacticalMeetingProjectsQueryVariables as Variables,
} from './__generated__/TacticalMeetingProjectsQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  circleDatabaseId: string,
}>

const query = graphql`
  query TacticalMeetingProjectsQuery($circleDatabaseId: String!, $orgDatabaseId: String!) {
    organization(databaseId: $orgDatabaseId) {
      circle(databaseId: $circleDatabaseId) {
        supportedRole {
          databaseId
          ...TacticalMeetingProjectsView_role
        }
      }
    }
  }
`

function TacticalMeetingProjects({circleDatabaseId, orgDatabaseId}: Props): Node {
  const variables: Variables = {
    circleDatabaseId,
    orgDatabaseId,
  }

  const [{organization}, refetchQuery] = useRefetchableLazyLoadQuery<Query>(query, variables)
  const role = organization?.circle?.supportedRole
  const roleDatabaseId = role?.databaseId

  useSubscriptionRefetch(
    OrganizationSubscription,
    {orgDatabaseId},
    refetchQuery,
    {orgDatabaseId, roleDatabaseId},
    (data) => (data.organizationEvent?.type === 'OrganizationProjectChange'),
  )

  if (!role)
    return <Preload />

  return (
    <TacticalMeetingProjectsView roleKey={role} refetchQuery={refetchQuery} />
  )
}

export default TacticalMeetingProjects
