// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  DeleteProjectInput as Input,
} from './__generated__/DeleteProjectMutation.graphql'

const mutation = graphql`
  mutation DeleteProjectMutation(
    $input: DeleteProjectInput!
  ) {
    deleteProject(input: $input) {
      deletedProjectId
    }
  }
`

export default (input: MutationInput<Input>): Promise<mixed> => {
  const variables = {input}

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedProjectId',
  }]

  return createMutationPromise({
    mutation,
    variables,
    configs,
  })
}

export type {Input}
