/**
 * @flow
 * @relayHash cee4af8b480d0bbe5e46c2b5712a7424
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TacticalMeetingOutputsEventSubscriptionVariables = {|
  tacticalMeetingDatabaseId: string
|};
export type TacticalMeetingOutputsEventSubscriptionResponse = {|
  +tacticalMeetingEvent: ?{|
    +meeting?: {|
      +id: string
    |}
  |}
|};
export type TacticalMeetingOutputsEventSubscription = {|
  variables: TacticalMeetingOutputsEventSubscriptionVariables,
  response: TacticalMeetingOutputsEventSubscriptionResponse,
|};
*/


/*
subscription TacticalMeetingOutputsEventSubscription(
  $tacticalMeetingDatabaseId: String!
) {
  tacticalMeetingEvent(tacticalMeetingDatabaseId: $tacticalMeetingDatabaseId) {
    __typename
    ... on TacticalOutputAdded {
      meeting {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tacticalMeetingDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "tacticalMeetingDatabaseId",
    "variableName": "tacticalMeetingDatabaseId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TacticalMeeting",
      "kind": "LinkedField",
      "name": "meeting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TacticalOutputAdded",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TacticalMeetingOutputsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tacticalMeetingEvent",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TacticalMeetingOutputsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tacticalMeetingEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "cee4af8b480d0bbe5e46c2b5712a7424",
    "metadata": {},
    "name": "TacticalMeetingOutputsEventSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d3635a08443f11f37f50d6facef7f93';

module.exports = node;
