/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type MeetingType = "AdministrativeChange" | "GovernanceMeeting" | "GovernanceUpdate" | "TacticalMeeting";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MeetingBanner_meeting$ref: FragmentReference;
declare export opaque type MeetingBanner_meeting$fragmentType: MeetingBanner_meeting$ref;
export type MeetingBanner_meeting = {|
  +id: string,
  +joinMeetingPath: any,
  +type: MeetingType,
  +$refType: MeetingBanner_meeting$ref,
|};
export type MeetingBanner_meeting$data = MeetingBanner_meeting;
export type MeetingBanner_meeting$key = {
  +$data?: MeetingBanner_meeting$data,
  +$fragmentRefs: MeetingBanner_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeetingBanner_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "joinMeetingPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
// prettier-ignore
(node/*: any*/).hash = 'c30298e77648f676717d6f48e2d4f435';

module.exports = node;
