// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
// eslint-disable-next-line import/named
import Alert, {Severity} from 'components/ui/Alert'

type Props = $ReadOnly<{
  status: string,
  orgName: string,
  renderUpgradeButton: () => Node,
  renderVersionDescriptionBlock: () => Node,
}>

function Status({status, orgName, renderUpgradeButton, renderVersionDescriptionBlock}: Props): Node {
  const errorSeverity: Severity = Severity.Danger

  switch (status) {
    case 'NONE':
      return (
        renderVersionDescriptionBlock()
      )
    case 'REQUESTED':
    case 'RUNNING':
    case 'COMPLETED':
      return (
        renderVersionDescriptionBlock()
      )
    case 'ERROR':
      return (
        <Fragment>
          {I18n.t('admin.organizations.holacracy_version.problem', {orgName})}
          {renderUpgradeButton()}
        </Fragment>
      )
    default:
      return (
        <Alert
          message={I18n.t('errors.unhandled_exception')}
          severity={errorSeverity}
        />
      )
  }
}

export default Status
