import _ from 'underscore'
import * as R from 'ramda'
import util from 'utils/MiscUtils'

import DataRequest from './storeСoncerns/DataRequest'
import ActionHandlerUtils from './utills/ActionHandlerUtils'

function ApiFetcher() {
  this.initialize.apply(this, arguments)
}

_.extend(ApiFetcher.prototype, {
  initialize(opts) {
    this.apiAdapter = (opts && opts.apiAdapter) || util.error('apiAdapter required')
    this.store = (opts && opts.store) || util.error('store required')
  },

  fetchDataForArguments() {
    const dr = DataRequest.fromArguments(_.toArray(arguments), this.store)
    return this.fetchDataForRequest(dr)
  },

  fetchDataForRequest(dr) {
    switch (dr.requestType) {
      case DataRequest.requestType.FORWARD_LOOKUP:
        return this._fetchForwardLookup(dr)
      case DataRequest.requestType.REVERSE_LOOKUP:
        return this._fetchReverseLookup(dr)
      case DataRequest.requestType.SINGLE_RESOURCE:
        return this._fetchResource(dr.type, dr.id, dr.options)
      case DataRequest.requestType.COLLECTION:
        return this.fetchCollection(dr.type, dr.options)
      case DataRequest.requestType.UNDEFINED:
        return
      case DataRequest.requestType.NULL:
        return
      default:
        util.error('invalid requestType')
    }
  },

  _fetchForwardLookup(dr) {
    if (util.isLocalId(dr.id))
      return

    if (!this.store.hasResource(dr.type, dr.id))
      return this._fetchAndReFetch(dr)

    if (this.store.isRelationshipLoaded(dr.type, dr.id, dr.relName))
      return util.preResolvedPromise()

    if (dr.dataSpec == 'id' || dr.dataSpec == 'ids')
      return util.preResolvedPromise()

    const idOrIds = this.store._forwardLookupIds(dr)
    // eslint-disable-next-line no-nested-ternary
    const ids = _.isArray(idOrIds) ? idOrIds : (idOrIds ? [idOrIds] : [])

    ids.forEach((id) => {
      this._fetchResource(dr.relName, id)
    })

    // I think there's a bug in here where technically we should wait on all the
    // resource fetches- ie. return a promise that resolves when *all* those
    // requests/promises resolve.
    this.store.setRelationshipLoaded(dr.type, dr.id, dr.relName)
  },

  _fetchReverseLookup(dr) {
    if (util.isLocalId(dr.id))
      return

    if (!this.store.hasResource(dr.type, dr.id))
      return this._fetchAndReFetch(dr)

    if (this.store.isRelationshipLoaded(dr.type, dr.id, dr.relName))
      return util.preResolvedPromise()

    const normType = this.store.normalizeTypeToPlural(dr.relName)
    const params = R.merge(
      {include: ''},
      R.assoc(`${this.store.normalizeTypeToSingular(dr.type)}_id`, dr.id, dr.options.params),
    )
    const res = gf.app.apiFetcher.getWithFetch(normType, {params})
    res.then(() => {
      this.store.setRelationshipLoaded(dr.type, dr.id, dr.relName)
    })
    return res
  },

  _fetchAndReFetch(dr) {
    return this._fetchResource(dr.type, dr.id)
      .then(() => this.fetchDataForArguments.apply(this, dr.originalArgs))
  },

  _fetchResource(type, rawId, options) {
    if (util.isLocalId(rawId))
      return undefined

    if (this.store.hasResource(type, rawId))
      return util.preResolvedPromise()

    return this.getModelWithFetch(type, rawId, options)
  },

  // "fetch" because they return promises (not immediate values)
  fetchModel(type, id, options) {
    return this.getModelWithFetch(type, id, options).then(() => this.store.getModel(type, id))
  },

  fetchCollection(type, options) {
    if (this.store.isResourceTypeLoaded(type))
      return util.preResolvedPromise()

    return this.getWithFetch(type, _.extend({params: {include: ''}}, options || {}))
  },

  // "get" because there's an immediate return value
  //  "WithFetch" because an api call is also triggered
  getModelWithFetch(type, id, options) {
    const shouldCancel = function () {
      return !!this.store.hasResource(type, id)
    }.bind(this)

    return this.getWithFetch(type, id, _.extend({cancelIf: shouldCancel}, options))
  },

  // "get" because there's an immediate return value
  //  "WithFetch" because an api call is also triggered
  getWithFetch() {
    const args = _.toArray(arguments)
    const type = this.store.normalizeTypeToPlural(args.shift())

    args.unshift(type) // use the plural-normalized version
    const existingRequest = this.apiAdapter.apiFindRequest.apply(this.apiAdapter, ['GET'].concat(args))
      || this.apiAdapter.apiFindRequest('GET', type)

    let lastPromise

    if (existingRequest) {
      lastPromise = existingRequest.deferred
    } else {
      lastPromise = this.apiAdapter.apiGet.apply(this.apiAdapter, args)
      lastPromise.then(
        ActionHandlerUtils.handleWith.call(this.store, {type: 'DEFAULT_GET_SUCCESS'}),
      )
    }

    return lastPromise
  },
})

export default ApiFetcher
