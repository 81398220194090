/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AgendaItemGroup_agendaItem$ref = any;
type AgendaItemGroup_meeting$ref = any;
type Output_meeting$ref = any;
type Output_output$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgendaItemGroups_meeting$ref: FragmentReference;
declare export opaque type AgendaItemGroups_meeting$fragmentType: AgendaItemGroups_meeting$ref;
export type AgendaItemGroups_meeting = {|
  +databaseId: string,
  +agendaItems: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +databaseId: string,
        +hasTacticalOutputs: boolean,
        +$fragmentRefs: AgendaItemGroup_agendaItem$ref,
      |}
    |}>
  |},
  +tacticalOutputs: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +databaseId: string,
        +agendaItem: ?{|
          +databaseId: string
        |},
        +$fragmentRefs: Output_output$ref,
      |}
    |}>
  |},
  +$fragmentRefs: AgendaItemGroup_meeting$ref & Output_meeting$ref,
  +$refType: AgendaItemGroups_meeting$ref,
|};
export type AgendaItemGroups_meeting$data = AgendaItemGroups_meeting;
export type AgendaItemGroups_meeting$key = {
  +$data?: AgendaItemGroups_meeting$data,
  +$fragmentRefs: AgendaItemGroups_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "tacticalOutputs"
        ]
      }
    ]
  },
  "name": "AgendaItemGroups_meeting",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        }
      ],
      "concreteType": "AgendaItemConnection",
      "kind": "LinkedField",
      "name": "agendaItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AgendaItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AgendaItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasTacticalOutputs",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AgendaItemGroup_agendaItem"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "tacticalOutputs",
      "args": null,
      "concreteType": "TacticalOutputConnection",
      "kind": "LinkedField",
      "name": "__AgendaItemGroups_tacticalOutputs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TacticalOutputEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TacticalOutput",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AgendaItem",
                  "kind": "LinkedField",
                  "name": "agendaItem",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Output_output"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgendaItemGroup_meeting"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Output_meeting"
    }
  ],
  "type": "TacticalMeeting",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '24030c6a23c8e5aeca737c23ca14f4f2';

module.exports = node;
