// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import AddViewerMetricMutation from 'mutations/AddViewerMetricMutation'
import ViewerMetricFormModal from 'components/metrics/ViewerMetricFormModal'

import type {
  CreateViewerMetricQuery as Query,
} from './__generated__/CreateViewerMetricQuery.graphql'

type Props = $ReadOnly<{
  orgId: string,
  close: () => void,
}>

const query = graphql`
  query CreateViewerMetricQuery($orgId: ID!) {
    organization: node(id: $orgId) {
      ... on Organization {
        id
        onRestrictedPlan

        ...FrequencySelector_organization

        viewer {
          id
          ...ViewerMetricFormModal_viewer
        }
      }
    }
  }
`

const initialValues = {
  description: '',
  circleId: null,
  roleId: null,
  frequency: 'Weekly',
  customFrequency: null,
  link: '',
  privateToCircle: false,
}

function CreateViewerMetric({
  orgId,
  close,
}: Props): null | Node {
  const {organization} = useLazyLoadQuery<Query>(query, {orgId})
  const viewer = organization?.viewer

  if (!organization || !viewer)
    return null

  const submit = async (values): Promise<Object> => {
    const response = await AddViewerMetricMutation({...values}, viewer.id)

    return response
  }

  return (
    <ViewerMetricFormModal
      initialValues={initialValues}
      close={close}
      submit={submit}
      modalTitle={I18n.t('metrics.add_metric')}
      organization={organization}
      viewer={viewer}
    />
  )
}

export default CreateViewerMetric
