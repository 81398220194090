import util from 'utils/MiscUtils'
import ImportTensionsButtonsRefetcher from 'utils/ImportTensionsButtonsRefetcher'

const MeetingActions = {
  MEETING_HOST_SELECT(action) {
    const meeting = action.meetingId || util.error('meetingId required')
    const person = action.personId || util.error('personId required')
    const data = {
      links: {person, meeting},
    }
    return this.apiAdapter.apiPost('meeting_hosts', data).then(
      this.handleWith({...action, type: 'MEETING_HOST_SELECT_SUCCESS'}),
    )
  },

  MEETING_HOST_SELECT_SUCCESS(action) {
    util.assert(action.response.status == 200, 'MEETING_HOST_SELECT_SUCCESS cant deal with non 200 status...')
    this.mergeResponseDataIntoStore(action)
    this.store.setData(action.meetingType, action.meetingId, 'links', {current_host: action.personId})
    // Reload host-related Relay fragments
    ImportTensionsButtonsRefetcher.instance.call()
  },
}

export default MeetingActions
