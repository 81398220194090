// @flow
import React, {useEffect, useRef, useState, type Node} from 'react'
import Checkbox from 'components/ui/Inputs/Checkbox'
import {v4 as uuidv4} from 'uuid'
import className from 'classnames'
import styles from './index.scss'
import Feedback from '../Feedback'

type Props = {
  superUser: any,
  debugModeSelected: boolean,
  setDebugModeSelected: (any) => void,
  addMessages: (any) => void,
  postNewUserMessage: (any) => any,
}

function MessageInput({superUser, setDebugModeSelected,
  debugModeSelected, addMessages,
  postNewUserMessage}: Props): Node {
  const inputField = useRef()
  const [input, setInput] = useState('')

  const checkboxClasses = className(styles.debugModeToggle, {
    [styles.debugSelected]: debugModeSelected,
  })

  const sendMessage = async (event) => {
    event.preventDefault()
    const tmpUserMsgId = uuidv4()
    const tmpAssntMsgId = uuidv4()
    const userMessage = {content: input, role: 'user', id: tmpUserMsgId}

    addMessages([
      userMessage,
      {content: '__THINKING__', role: 'assistant', id: tmpAssntMsgId},
    ])
    setInput('')
    postNewUserMessage(input)
  }

  useEffect(() => {
    if (inputField.current)
      inputField.current.focus()
  })

  return (
    <form className={styles.input}>
      {superUser && (
        <div className={checkboxClasses}>
          <Checkbox
            id="debugMode"
            label="Debug mode"
            checked={debugModeSelected}
            onChange={() => { setDebugModeSelected(!debugModeSelected) }}
          />
        </div>
      )}
      <input
        value={input}
        onChange={(event) => setInput(event.target.value)}
        placeholder="Type a message"
        ref={inputField}
        type="text"
      />
      <button onClick={sendMessage} type="submit">
        <i className="fa fa-paper-plane" />
      </button>

      <Feedback />
    </form>
  )
}

export default MessageInput
