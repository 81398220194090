// @flow
import type {Element} from 'react'
import React from 'react'
import {contains} from 'ramda'

import EtcSelectorOption from './EtcSelectorOption'

import type {Option, Value} from './types'

type Props = {|
  options: Option[],
  selectedValues: $ReadOnlyArray<Value>,
  onSelect: Option => void,
|}

function EtcSelector({options, selectedValues, onSelect}: Props): Element<"div"> {
  return (
    <div className="etc-selector">
      {options.map((option) => (
        <div key={option.value} className="etc-selector__option">
          <EtcSelectorOption
            option={option}
            isSelected={contains(option.value, selectedValues)}
            onSelect={onSelect}
          />
        </div>
      ))}
    </div>
  )
}

export type {Option, Value}
export default EtcSelector
