/**
 * @flow
 * @relayHash 42c9595e1c20ea77228660a247535683
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChecklistItem_item$ref = any;
export type ChecklistReportedBy = "CIRCLE_MEMBERS" | "ROLE_FILLERS";
export type Frequency = "Monthly" | "Quarterly" | "Weekly";
export type AddChecklistItemInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  description: string,
  frequency?: ?Frequency,
  customFrequencyId?: ?string,
  roleId: string,
  reportedBy: ChecklistReportedBy,
  link?: ?string,
  privateToCircle?: ?boolean,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddChecklistItemMutationVariables = {|
  input: AddChecklistItemInput
|};
export type AddChecklistItemMutationResponse = {|
  +addChecklistItem: ?{|
    +checklistItemEdge: ?{|
      +node: ?{|
        +id: string,
        +frequency: ?Frequency,
        +role: ?{|
          +id: string
        |},
        +$fragmentRefs: ChecklistItem_item$ref,
      |}
    |}
  |}
|};
export type AddChecklistItemMutation = {|
  variables: AddChecklistItemMutationVariables,
  response: AddChecklistItemMutationResponse,
|};
*/


/*
mutation AddChecklistItemMutation(
  $input: AddChecklistItemInput!
) {
  addChecklistItem(input: $input) {
    checklistItemEdge {
      node {
        id
        frequency
        role {
          id
        }
        ...ChecklistItem_item
      }
    }
  }
}

fragment ChecklistItem_item on ChecklistItem {
  id
  databaseId
  description
  frequency
  localizedFrequency
  link
  localizedRoleName
  isGlobal
  appliesToAllMembers
  viewerCanSeeEditIcon
  privateToCircle
  position
  viewerCanReorder
  organization {
    id
    onRestrictedPlan
  }
  customFrequency {
    id
    name
    universalUnits
  }
  people(first: 30) {
    edges {
      node {
        name
        id
      }
    }
  }
  role {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddChecklistItemPayload",
        "kind": "LinkedField",
        "name": "addChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItemEdge",
            "kind": "LinkedField",
            "name": "checklistItemEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChecklistItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ChecklistItem_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddChecklistItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddChecklistItemPayload",
        "kind": "LinkedField",
        "name": "addChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecklistItemEdge",
            "kind": "LinkedField",
            "name": "checklistItemEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChecklistItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localizedFrequency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localizedRoleName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGlobal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appliesToAllMembers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanSeeEditIcon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privateToCircle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "position",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanReorder",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "onRestrictedPlan",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFrequency",
                    "kind": "LinkedField",
                    "name": "customFrequency",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "universalUnits",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 30
                      }
                    ],
                    "concreteType": "PersonConnection",
                    "kind": "LinkedField",
                    "name": "people",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "people(first:30)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "42c9595e1c20ea77228660a247535683",
    "metadata": {},
    "name": "AddChecklistItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89606a93dc2618dd720fb48c4106dfa6';

module.exports = node;
