/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Group_circle$ref = any;
type ProjectsGrid_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsGroupsOrGrid_circle$ref: FragmentReference;
declare export opaque type ProjectsGroupsOrGrid_circle$fragmentType: ProjectsGroupsOrGrid_circle$ref;
export type ProjectsGroupsOrGrid_circle = {|
  +circleLeadRole: ?{|
    +assignments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: {|
            +id: string,
            +name: string,
          |}
        |}
      |}>
    |}
  |},
  +$fragmentRefs: Group_circle$ref & ProjectsGrid_circle$ref,
  +$refType: ProjectsGroupsOrGrid_circle$ref,
|};
export type ProjectsGroupsOrGrid_circle$data = ProjectsGroupsOrGrid_circle;
export type ProjectsGroupsOrGrid_circle$key = {
  +$data?: ProjectsGroupsOrGrid_circle$data,
  +$fragmentRefs: ProjectsGroupsOrGrid_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsGroupsOrGrid_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "circleLeadRole",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentConnection",
          "kind": "LinkedField",
          "name": "assignments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Assignment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Group_circle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectsGrid_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ed00fa1d74a40fc31d9ea3d7b3cdf9f9';

module.exports = node;
