import Icon from 'components/ui/icons/fa/Reorder'
import classNames from 'classnames'
import {Tooltip} from 'reactstrap'
import React, {useState, useCallback} from 'react'

import type {Node} from 'react'
import style from './style.scss'

type Props = {
  disabled: boolean,
  disabledTooltip: string,
}

function ReorderIcon({disabled, disabledTooltip}: Props): Node {
  const className = classNames({
    [style.enabled]: !disabled,
    [style.disabled]: disabled,
  })

  const [tooltipState, setTooltipState] = useState(false)

  const toggle = useCallback(() => {
    setTooltipState(!tooltipState)
  }, [tooltipState])

  return (
    <div className={style.wrapper}>
      <Icon className={className} id="reorder-icon" />
      {disabled && tooltip && (
        <Tooltip placement="right" isOpen={tooltipState} target="reorder-icon" toggle={toggle}>
          {disabledTooltip}
        </Tooltip>
      )}
    </div>
  )
}

ReorderIcon.defaultProps = {
  disabled: false,
  disabledTooltip: '',
}

export default ReorderIcon
