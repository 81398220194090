// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import {
  type ConvertOrganizationToV5Input as Input,
} from './__generated__/ConvertOrganizationToV5Mutation.graphql'

const mutation = graphql`
  mutation ConvertOrganizationToV5Mutation($input: ConvertOrganizationToV5Input!) {
    convertOrganizationToV5(input: $input) {
      organization {
        id
        v5migrationStatus

        ...ConstitutionTab_organization
      }
    }
  }
`

const ConvertOrganizationToV5Mutation = (input: MutationInput<Input>): Promise<mixed> => (
  createMutationPromise({mutation, variables: {input}})
)

export default ConvertOrganizationToV5Mutation
