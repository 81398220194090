/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomProfileFieldsEditor_person$ref: FragmentReference;
declare export opaque type CustomProfileFieldsEditor_person$fragmentType: CustomProfileFieldsEditor_person$ref;
export type CustomProfileFieldsEditor_person = {|
  +id: string,
  +customSettings: {|
    +customProfileFields: $ReadOnlyArray<{|
      +name: string,
      +value: ?string,
      +appliedFrom: ?string,
    |}>
  |},
  +$refType: CustomProfileFieldsEditor_person$ref,
|};
export type CustomProfileFieldsEditor_person$data = CustomProfileFieldsEditor_person;
export type CustomProfileFieldsEditor_person$key = {
  +$data?: CustomProfileFieldsEditor_person$data,
  +$fragmentRefs: CustomProfileFieldsEditor_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomProfileFieldsEditor_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "scope",
          "value": "ORGANIZATION"
        }
      ],
      "concreteType": "CustomSettings",
      "kind": "LinkedField",
      "name": "customSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomProfileFieldMapping",
          "kind": "LinkedField",
          "name": "customProfileFields",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appliedFrom",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "customSettings(scope:\"ORGANIZATION\")"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bd5f7f8980fbac8f3c2167c0ca559d3a';

module.exports = node;
