// @flow
import type {Element, Node} from 'react'
import React, {Children, useEffect, useRef} from 'react'
import classNames from 'classnames'

import {useToggle} from 'utils/hooks'
import IconButton from 'components/ui/Inputs/IconButton'
import Close from 'components/ui/icons/md/Close'
import HelpIcon from 'components/ui/icons/md/Help'
import FrogBotIcon from 'components/ui/icons/FrogBotIcon'
import Box, {type AddingButtonConfig, type RenderButtons} from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/Box'

import MarkdownTip from './MarkdownTip'
import FrogBox from './FrogBox'
import styles from './index.scss'

type Props = $ReadOnly<{
  type?: 'help' | 'ai-help',
  target: string | () => Node,
  tip: string | () => Node,
  helpFor: string,
  editButton?: Node | null,
  additionalSection: ?() => Node,
  farRightSection?: null | () => Node,
  boxHeader: boolean,
  hideDivider: boolean,
  withBottomSpacing: boolean,
  dangerouslySetHTML?: boolean, // be sure the tip text is safe or sanitized before turning this on
  addingButtonConfig: ?AddingButtonConfig,
  renderButtons: ?RenderButtons,
}>

// eslint-disable-next-line max-len
function FrogTipBox({
  type,
  target,
  tip,
  helpFor,
  editButton,
  additionalSection,
  boxHeader,
  hideDivider,
  withBottomSpacing,
  dangerouslySetHTML,
  addingButtonConfig,
  renderButtons,
  farRightSection,
}: Props): Element<"div"> {
  const [
    isTipOpen,
    {
      toggle: toggleTipOpen,
      close: closeTip,
    },
  ] = useToggle(false)

  const openButtonText = I18n.t('ui.frog_help.show', {help_for: helpFor})
  const closeButtonText = I18n.t('ui.frog_help.hide', {help_for: helpFor})

  const buttonText = isTipOpen
    ? closeButtonText
    : openButtonText

  const questionClassName = classNames(styles.helpButton, {
    [styles.helpButtonActive]: isTipOpen,
  })

  const headerContainerClassName = classNames(styles.headerContainer, {
    [styles.withBottomMargin]: boxHeader,
  })

  const finalTip = () => {
    if (typeof tip === 'function')
      return tip()

    if (dangerouslySetHTML)
    // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{__html: tip}} />

    return tip
  }

  const displayTarget = () => {
    if (boxHeader) {
      return (
        <Box.Header
          addingButtonConfig={addingButtonConfig}
          underlined={false}
          spacing="none"
        >
          {typeof target === 'function' ? target() : target}
          {editButton && (
            <div className={styles.editButton}>
              {editButton}
            </div>
          )}
        </Box.Header>
      )
    }
    return (
      typeof target === 'function' ? target() : target
    )
  }

  const boxRef = useRef<?HTMLElement>(null)

  useEffect(() => {
    if (isTipOpen)
      boxRef.current?.focus()
  }, [isTipOpen])

  const renderIcon = () => {
    if (type === 'ai-help') {
      return (
        <div className={styles.iconFrogbot}>
          <FrogBotIcon />
        </div>
      )
    }

    return (
      <div className={styles.iconCircle}>
        <HelpIcon />
      </div>
    )
  }

  return (
    <div>
      <div className={headerContainerClassName}>
        <div className={styles.headerContent}>
          {displayTarget()}
          <IconButton
            onClick={toggleTipOpen}
            className={questionClassName}
            title={buttonText}
            size="md"
            type="button"
          >
            {renderIcon()}
          </IconButton>
          {additionalSection && additionalSection()}
        </div>
        {farRightSection && (
          <div className={styles.farRightSectionContainer}>
            {farRightSection()}
          </div>
        )}
        {renderButtons && (
          <div className={styles.buttonsContainer}>
            {Children.map(renderButtons(), (button) => (
              button && (
                <div className={styles.button}>
                  {button}
                </div>
              )
            ))}
          </div>
        )}
      </div>
      {!hideDivider && (
        <div className={styles.divider} />
      )}
      {isTipOpen && (
        <FrogBox
          ref={boxRef}
          withBottomSpacing={withBottomSpacing}
        >
          <div className={styles.tipContainer}>
            { finalTip() }
            <div className={styles.closeContainer}>
              <IconButton
                onClick={closeTip}
                title={closeButtonText}
                size="md"
              >
                <Close />
              </IconButton>
            </div>
          </div>
        </FrogBox>
      )}
    </div>
  )
}

FrogTipBox.defaultProps = {
  additionalSection: null,
  dangerouslySetHTML: false,
  boxHeader: false,
  hideDivider: false,
  withBottomSpacing: false,
  addingButtonConfig: null,
  renderButtons: null,
  type: 'help',
}

export default FrogTipBox
export {FrogBox, MarkdownTip}
