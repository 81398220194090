// @flow
import type {Node} from 'react'
import React from 'react'
import Tribute from 'react-tribute'
import escapeTextForBrowser from 'react-lib-escape-text-content-for-browser'

type TributeItem = $ReadOnly<{
  key: string,
  value: string,
}>

type TributeMeta = $ReadOnly<{
  index: number,
  original: TributeItem,
  score: number,
  string: string,
}>

type TributeOptions = $ReadOnly<{
  values: $ReadOnlyArray<TributeItem>,
  requireLeadingSpace: boolean,
  selectTemplate: TributeMeta => string,
  noMatchTemplate: void => string,
  menuItemTemplate: TributeMeta => string,
}>

type Props = $ReadOnly<{
  children: Node,
  items: $ReadOnlyArray<TributeItem>,
  onChange: SyntheticInputEvent<HTMLInputElement> => void,
}>

function MentionEngine({items: values, onChange, children}: Props): Node {
  const menuItemTemplate = (meta: TributeMeta) => escapeTextForBrowser(meta.original.key)
  const selectTemplate = (meta: TributeMeta) => meta.original.value
  const noMatchTemplate = () => ''

  const options: TributeOptions = {
    values,
    requireLeadingSpace: false,
    selectTemplate,
    noMatchTemplate,
    menuItemTemplate,
  }

  return (
    <Tribute onChange={onChange} options={options}>
      {children}
    </Tribute>
  )
}

export default MentionEngine
