/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SetupModeButton_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AdvancedSettingsV4_organization$ref: FragmentReference;
declare export opaque type AdvancedSettingsV4_organization$fragmentType: AdvancedSettingsV4_organization$ref;
export type AdvancedSettingsV4_organization = {|
  +id: string,
  +setupModeEnabled: boolean,
  +$fragmentRefs: SetupModeButton_organization$ref,
  +$refType: AdvancedSettingsV4_organization$ref,
|};
export type AdvancedSettingsV4_organization$data = AdvancedSettingsV4_organization;
export type AdvancedSettingsV4_organization$key = {
  +$data?: AdvancedSettingsV4_organization$data,
  +$fragmentRefs: AdvancedSettingsV4_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvancedSettingsV4_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupModeEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SetupModeButton_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '218b9de531793ef64bd219792da64838';

module.exports = node;
