// @flow
import React, {type Node, lazy, useCallback, Suspense} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import type {MeetingType} from 'components/agendaItems/EditAgendaItemModal'

import ModalLoader from 'components/ui/ModalLoader'
import flash from 'utils/MiscUtils/flash'
import type {
  AgendaProcessModal_tension as Tension,
} from './__generated__/AgendaProcessModal_tension.graphql'

const LazyEditAgendaItemModal = lazy(() => import(/* webpackChunkName: "EditAgendaItemModal" */ 'components/agendaItems/EditAgendaItemModal'))

const successCreating = () => flash.notice(I18n.t('tensions.react.process_into.success.agenda_item'))
const handleFailure = () => flash.danger('There was a problem on the server.')

type Props = {
  close: () => void,
  tension: Tension,
  meetingType: MeetingType,
}

function AgendaProcessModal({tension, close, meetingType}: Props): Node {
  const onSuccess = useCallback(async () => {
    try {
      successCreating()
    } catch (e) {
      handleFailure()
    }
  }, [])

  return (
    <Suspense fallback={<ModalLoader />}>
      <LazyEditAgendaItemModal
        onSuccess={onSuccess}
        orgDatabaseId={tension.organization.databaseId}
        tensionDatabaseId={tension.databaseId}
        close={close}
        meetingType={meetingType}
      />
    </Suspense>
  )
}

export default (createFragmentContainer(AgendaProcessModal, {
  tension: graphql`
    fragment AgendaProcessModal_tension on Tension {
      databaseId

      organization {
        databaseId
      }
    }
  `,
}): RelayFragmentContainer<typeof(AgendaProcessModal)>)
