// @flow
import type {Element} from 'react'
import React from 'react'
import classNames from 'classnames'

import Close from 'components/ui/icons/md/Close'
import type {Callback} from 'components/types'

type Props = {
  onClick: Callback,
  className: string,
}

function CloseButton({onClick, className}: Props): Element<"button"> {
  const buttonClass = classNames('btn-reset banner__close-icon test-banner-close', className)

  return (
    <button onClick={onClick} className={buttonClass} type="button" title={I18n.t('buttons.close')}>
      <Close />
    </button>
  )
}

CloseButton.defaultProps = {
  className: '',
}

export default CloseButton
