// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import AccessibleList from 'components/ui/AccessibleList'
import IconButton from 'components/ui/Inputs/IconButton'
import Pencil from 'components/ui/icons/fa/Pencil'
import Trashcan from 'components/ui/icons/fa/Trashcan'
import DeleteRoleTemplateMutation from 'mutations/DeleteRoleTemplateMutation'
import style from './style.scss'
import type {
  RoleTemplatesList_organization$key as Organization,
} from './__generated__/RoleTemplatesList_organization.graphql'

const organizationFragment = graphql`
  fragment RoleTemplatesList_organization on Organization {
    id

    roleTemplates(first: 100) @connection(key: "RoleTemplatesList_roleTemplates") {
      edges {
        node {
          id
          name
        }
      }
    }
    
    ...CreateRoleTemplateModal_organization
  }
`

type Props = {
  organization: Organization,
  onEdit: (id: string) => void,
}

function RoleTemplatesList({organization: organizationKey, onEdit}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)
  const roleTemplates = liftNodes(organization.roleTemplates)

  const getOnDelete = (roleTemplate) => () => {
    // eslint-disable-next-line no-alert,no-unused-expressions
    if (window.confirm(I18n.t('tags.edit.are_you_sure')))
      DeleteRoleTemplateMutation(roleTemplate.id, organization.id)
  }

  const getOnEdit = (roleTemplate) => () => {
    onEdit(roleTemplate.id)
  }

  return (
    <AccessibleList
      test-id="role-templates-list"
      items={roleTemplates}
      placeholder={I18n.t('react.components.listview.no_items')}
      renderItem={(item) => (
        <AccessibleList.ListItem
          test-id={item.id}
          onClick={getOnEdit(item)}
          onClickText="some_text"
        >
          <AccessibleList.ListRow
            rightSection={[
              (
                <IconButton
                  testId="edit-button"
                  title={I18n.t('shared.edit')}
                  onClick={getOnEdit(item)}
                >
                  <Pencil />
                </IconButton>
              ),
              (
                <IconButton
                  testId="delete-button"
                  title={I18n.t('shared.delete')}
                  onClick={getOnDelete(item)}
                >
                  <Trashcan />
                </IconButton>
              ),
            ]}
          >
            <div className={style.listItem}>
              {item.name}
            </div>
          </AccessibleList.ListRow>
        </AccessibleList.ListItem>
      )}
    />
  )
}

export default RoleTemplatesList
