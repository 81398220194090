// @flow
import {useCallback, useState, useRef} from 'react'
import {fetchQuery, useLazyLoadQuery} from 'react-relay'
import type {
  GraphQLTaggedNode,
  OperationType,
  VariablesOf,
} from 'relay-runtime'
import environment from 'environment'

function useRefetchableLazyLoadQuery<Query: OperationType>(
  query: GraphQLTaggedNode,
  queryVariables: VariablesOf<Query>,
): [Query['response'], () => Promise<null>] {
  const [fetchKey, setFetchKey] = useState(0)
  const refertchSubscriptionRef = useRef()

  const refreshQuery = useCallback(() => {
    if (refertchSubscriptionRef.current)
      refertchSubscriptionRef.current.unsubscribe()

    return new Promise((resolve, reject) => {
      const subscription = fetchQuery(environment, query, queryVariables).subscribe({
        complete: () => {
          setFetchKey((prev) => (prev + 1))
          resolve(null)
          refertchSubscriptionRef.current = null
        },
        error: reject,
      })
      refertchSubscriptionRef.current = subscription
    })
  }, [query, setFetchKey, queryVariables])

  const response = useLazyLoadQuery<Query>(
    query,
    queryVariables,
    {
      fetchKey,
      fetchPolicy: 'store-and-network',
    },
  )

  return [response, refreshQuery]
}

export default useRefetchableLazyLoadQuery
