import _ from 'underscore'
import util from 'utils/MiscUtils'

// you know, like the bits of a path?
const Path = {
  fromString(path) {
    return _.map(_.compact(path.split('/')), (s) => (s.match(/^\d+$/) ? parseInt(s, 10) : s))
  },
  fromName(name) {
    if (!name)
      util.error('name required')

    return _.map(name.split(/\[/), (key) => key.replace(']', ''))
  },
  toString(steps) {
    return [''].concat(_.compact(steps)).join('/')
  },
  toName(steps) {
    return `part${_.compact(_.map(_.drop(steps), (val) => {
      const strVal = String(val)
      return _.isEmpty(strVal) ? null
        : `[${strVal}]`
    })).join('')}`
  },
  toIds(steps) {
    return Path.toString(steps).match(/\d+/g)
  },
  toHash(steps) {
    return {
      resourceName: steps[0],
      resourceId: steps[1],
      attributeName: steps[2],
      bonus: steps[3],
    }
  },
  isDomainDescription(steps) {
    return /domains\/\d*\/description/.test(Path.toString(steps))
  },
  isAccountabilityDescription(steps) {
    return /accountabilities\/\d*\/description/.test(Path.toString(steps))
  },
}

export default Path
