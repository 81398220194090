import $ from 'jquery'

$(document).ready(() => {
  function setupRoleFillerEditClick() {
    $('.role-assign .role-filler-view').on('click', (event) => {
      if (!$(event.target).hasClass('remove-filler')) {
        const viewRow = $(event.target).closest('.role-filler-view')
        viewRow.hide()
        viewRow.next('.role-filler-edit').show()
      }
    })
  }

  $(document).ready(setupRoleFillerEditClick)
    .on('dialogopen', () => { setTimeout(setupRoleFillerEditClick, 1) })
    .on('ajax:success', setupRoleFillerEditClick)
})
