/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MeetingBanner_circle$ref = any;
type MeetingBanner_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TacticalMeetingBanner_circle$ref: FragmentReference;
declare export opaque type TacticalMeetingBanner_circle$fragmentType: TacticalMeetingBanner_circle$ref;
export type TacticalMeetingBanner_circle = {|
  +localizedName: string,
  +viewerIsMember: boolean,
  +activeTacticalMeeting: ?{|
    +checkinPath: any,
    +checklistsPath: any,
    +checkoutPath: any,
    +metricsPath: any,
    +projectsPath: any,
    +triagePath: any,
    +typeLabel: string,
    +$fragmentRefs: MeetingBanner_meeting$ref,
  |},
  +$fragmentRefs: MeetingBanner_circle$ref,
  +$refType: TacticalMeetingBanner_circle$ref,
|};
export type TacticalMeetingBanner_circle$data = TacticalMeetingBanner_circle;
export type TacticalMeetingBanner_circle$key = {
  +$data?: TacticalMeetingBanner_circle$data,
  +$fragmentRefs: TacticalMeetingBanner_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TacticalMeetingBanner_circle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsMember",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TacticalMeeting",
      "kind": "LinkedField",
      "name": "activeTacticalMeeting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkinPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checklistsPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkoutPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metricsPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectsPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "triagePath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "typeLabel",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MeetingBanner_meeting"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MeetingBanner_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e37cc3b46ccf446dee42f7138ad5b8f4';

module.exports = node;
