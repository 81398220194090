/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectEditForm_project$ref = any;
export type ProjectVisibility = "ALL" | "PRIVATE_TO_CIRCLE" | "PRIVATE_TO_PERSON";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalView_project$ref: FragmentReference;
declare export opaque type ProjectFormModalView_project$fragmentType: ProjectFormModalView_project$ref;
export type ProjectFormModalView_project = {|
  +visibility: ProjectVisibility,
  +$fragmentRefs: ProjectEditForm_project$ref,
  +$refType: ProjectFormModalView_project$ref,
|};
export type ProjectFormModalView_project$data = ProjectFormModalView_project;
export type ProjectFormModalView_project$key = {
  +$data?: ProjectFormModalView_project$data,
  +$fragmentRefs: ProjectFormModalView_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalView_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectEditForm_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9b8874f9ee0dd3b7c1c81024ac77f4df';

module.exports = node;
