// @flow
import React, {useRef} from 'react'
import type {Node} from 'react'

import BootstrapPopover from 'components/ui/BootstrapPopover'

type Props = $ReadOnly<{
  project: $ReadOnly<{
    databaseId: string,
    parentGoalOrTarget: ?string,
    parentGoalOrTargetDescription: ?string,
    circle: $ReadOnly<{
      localizedName: string,
      [string]: any,
    }>,
    // allow any other project fields fields we don't use
    [string]: any,
  }>,
  short?: boolean,
}>

function ParentGoalOrTarget({project, short}: Props): Node {
  const targetRef: { current: null | HTMLElement } = useRef(null)

  const goalOrTarget = project.parentGoalOrTarget
  if (!goalOrTarget)
    return null

  const icon = goalOrTarget.match(/goal/)
    ? <i className="fa fa-trophy" />
    : <i className="fa fa-bullseye" />
  const domId = `GoalPopover${project.databaseId}`
  const isGoal = goalOrTarget.match(/goal/)
  const supportedTypeLabel = isGoal ? I18n.t('goals.supported_goal') : I18n.t('goals.supported_target')
  const supportsTypeLabel = isGoal ? I18n.t('goals.supports_1_goal') : I18n.t('goals.supports_1_target')

  return (
    <React.Fragment>
      <span id={domId} ref={targetRef}>
        <span className="mr-1">{icon}</span>
        {short ? null : supportsTypeLabel}
      </span>
      <BootstrapPopover
        targetRef={targetRef}
        containerSelector="#projects-tab"
      >
        <div>
          <div className="label">
            { supportedTypeLabel }
          </div>
          <ul>
            <li>
              <b>{project.parentGoalOrTargetDescription}</b>
              <span>{` ${I18n.t('shared.role_in_circle_parens', {circle_name: project.circle.localizedName})}`}</span>
            </li>
          </ul>
        </div>
      </BootstrapPopover>
    </React.Fragment>
  )
}

export default ParentGoalOrTarget
