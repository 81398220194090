// @flow
// This is just a shim for the old review proposal page undo proposal response
declare var gf: Object

const updateGupsProposal = (proposalDatabaseId: string): any => (
  gf.app.apiAdapter.apiGet('proposals', proposalDatabaseId).then(({request, response}) => (
    gf.app.dispatcher.dispatch({
      type: 'DEFAULT_GET_SUCCESS',
      request,
      response,
    })
  ))
)

export default updateGupsProposal
