// This is a temporary shim to update the new hotwire tension row from react components
export default async function index(organizationId, viewerId, tensionId) {
  const updatedContent = await fetch(`/organizations/${organizationId}/person/${viewerId}/tensions/${tensionId}`)
    .then((r) => r.text())

  // Replace the inner HTML of the specific tension element
  const tensionElement = document.getElementById(`tension_${tensionId}`)
  if (tensionElement) {
    tensionElement.outerHTML = updatedContent

    // Dispatch the turbo:frame-load event
    const event = new Event('turbo:frame-load', {bubbles: true, cancelable: true})
    tensionElement.dispatchEvent(event)
  }
}
