/**
 * @flow
 * @relayHash 08e1c40aaf5807fecdf05eb94eda9c6b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteTensionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  tensionId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteTensionMutationVariables = {|
  input: DeleteTensionInput
|};
export type DeleteTensionMutationResponse = {|
  +deleteTension: ?{|
    +deletedTensionId: string
  |}
|};
export type DeleteTensionMutation = {|
  variables: DeleteTensionMutationVariables,
  response: DeleteTensionMutationResponse,
|};
*/


/*
mutation DeleteTensionMutation(
  $input: DeleteTensionInput!
) {
  deleteTension(input: $input) {
    deletedTensionId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteTensionPayload",
    "kind": "LinkedField",
    "name": "deleteTension",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedTensionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTensionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTensionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "08e1c40aaf5807fecdf05eb94eda9c6b",
    "metadata": {},
    "name": "DeleteTensionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acd5eb0ad87980c052c3b35ae8863629';

module.exports = node;
