// @flow
import React, {type Node} from 'react'
import classNames from 'classnames'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import DefaultAvatar from 'components/ui/icons/DefaultAvatar'
import VisuallyHidden from 'components/ui/VisuallyHidden'

import styles from './index.scss'
import type {Person} from './types'

type Props = $ReadOnly<{
  person: Person,
  size: 'small' | 'medium' | 'smaller'| 'xLarge',
  grayOverlay: boolean,
  redBorder: boolean,
}>

function PersonAvatar({person, size, redBorder, grayOverlay}: Props): Node {
  const classes = classNames(styles.container, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.smaller]: size === 'smaller',
    [styles.xLarge]: size === 'xLarge',
    [styles.redBorder]: redBorder,
  })
  return (
    <div className={classes}>
      {grayOverlay && (
        <div className={styles.overlay} />
      )}
      <VisuallyHidden>{person.name}</VisuallyHidden>
      {person.avatarUrl
        ? (
          <img className={styles.image} src={person.avatarUrl} alt="" />
        )
        : (
          <DefaultAvatar className={styles.image} />
        )
      }
    </div>
  )
}

PersonAvatar.defaultProps = {
  redBorder: false,
  grayOverlay: false,
}

export default (createFragmentContainer(PersonAvatar, {
  person: graphql`
    fragment PersonAvatar_person on Person {
      avatarUrl
      name
    }
  `,
}): RelayFragmentContainer<typeof(PersonAvatar)>)
