// @flow
import type {Node} from 'react'
import React, {useCallback, Fragment} from 'react'
import {type Option} from 'components/ui/SortDropdown'
import TinySelectDropdown from 'components/ui/TinySelectDropdown'
import FaIcon from 'components/ui/icons/FaIcon'
import styles from './index.scss'

type Props = {|
  enterOverride: 'NEWLINE' | 'NEW_ITEM',
  changeEnterOverride: ('NEWLINE' | 'NEW_ITEM') => void,
|}

function EnterNotice({enterOverride, changeEnterOverride}: Props): Node {
  const options: Option[] = [{
    value: 'NEWLINE',
    label: I18n.t('proposals.react.enter_override.new_line.enter'),
    subLabel: I18n.t('proposals.react.enter_override.new_line.shift_enter'),
  }, {
    value: 'NEW_ITEM',
    label: I18n.t('proposals.react.enter_override.new_item.enter'),
    subLabel: I18n.t('proposals.react.enter_override.new_item.shift_enter'),
  }]

  const changeValue = useCallback(({value}: Option) => {
    if (value === 'NEWLINE' || value === 'NEW_ITEM')
      changeEnterOverride(value)
  }, [changeEnterOverride])

  return (
    <TinySelectDropdown
      // eslint-disable-next-line react/no-unstable-nested-components
      label={(selectedOption: ?Option) => {
        const enterAction = selectedOption?.label || ''
        const altEnterAction = selectedOption?.subLabel || ''

        return (
          <Fragment>
            <span>{enterAction}</span>
            &nbsp;
            <span>{altEnterAction}</span>
          </Fragment>
        )
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      highlightedLabel={() => <FaIcon className={styles.icon} icon="cog" />}
      options={options}
      selectedValue={enterOverride}
      onSelect={changeValue}
    />
  )
}

export default EnterNotice
