import React from 'react'
import {useFragment, graphql} from 'react-relay'

import uniqueArrayById from 'utils/MiscUtils/uniqueArrayById'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import RoleSelector, {type OnRoleChange, type Role} from 'components/roles/RoleSelector'
import type {
  WithCurrentRoleSelector_person$key as PersonKey,
} from './__generated__/WithCurrentRoleSelector_person.graphql'
import type {
  WithCurrentRoleSelector_role$key as RoleKey,
} from './__generated__/WithCurrentRoleSelector_role.graphql'

type Props = $ReadOnly<{
  useOnlyRolesFromCircle: boolean,
  circleValue: string | null,
  supportedRoleValue: string | null,
  useIndividualActions: boolean,
  useEachCircleMember: boolean,
  role: RoleKey | null,
  person: PersonKey,
  label: string,
  onRoleChange: ?OnRoleChange,
  useAsValue: 'databaseId' | 'id',
  value: string | null,
  onChange: (FakeEvent, boolean | string | null) => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
  labelWrapper: ?(Role) => string,
  sortable: boolean,
}>

const personFragment = graphql`
  fragment WithCurrentRoleSelector_person on Person {
    roles {
      edges {
        node {
          id
          circle {
            supportedRole {
              isDisplayedAsCircle
            }
          }

          ...RoleSelector_roles @arguments(nameWith: CIRCLE)
        }
      }
    }
  }
`

const roleFragment = graphql`
  fragment WithCurrentRoleSelector_role on Role {
    id
    circle {
      supportedRole {
        isDisplayedAsCircle
      }
    }

    ...RoleSelector_roles @arguments(nameWith: CIRCLE)
  }
`

function WithCurrentRoleSelector({role: roleKey, person: personKey, ...roleSelectorProps} : Props) {
  const role = useFragment(roleFragment, roleKey)
  const person = useFragment(personFragment, personKey)

  const roles = uniqueArrayById(
    [role, ...liftNodes(person.roles)].filter(Boolean),
  ).filter((r) => r.circle?.supportedRole?.isDisplayedAsCircle)

  return (
    <RoleSelector
      roles={roles}
      {...roleSelectorProps}
    />
  )
}

export default WithCurrentRoleSelector
