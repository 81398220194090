// @flow
import type {AbstractComponent, Node} from 'react'
import React from 'react'
import {isNil, isEmpty} from 'ramda'
import {createFragmentContainer, graphql} from 'react-relay'
import type {$RelayProps} from 'react-relay'
import ParentGoalOrTarget from 'components/projects/ParentGoalOrTarget'

import ProjectRoi from 'components/projects/ProjectRoi'
import NoteTooltip from 'components/ui/NoteTooltip'
import ItemDetailRow from 'components/ui/ItemDetailRow'
import AgeComponent from 'components/ui/Age'
import ProjectPrivateIcon from 'components/projects/ProjectPrivateIcon'
import SourceIcon from 'components/ui/SourceIcon'
import type {Project} from './types'
import type {ProjectDetails_circle as Circle} from './__generated__/ProjectDetails_circle.graphql'
import styles from './index.scss'

type Props = $ReadOnly<{
  project: Project,
  showPersonName: boolean,
  showCircleName: boolean,
  showRoleName: boolean,
  circle: null | Circle,
}>

function ProjectDetails({
  project,
  showPersonName,
  showCircleName,
  showRoleName,
  circle: propsCircle,
}: Props): Node {
  const projectCircle = project.circle
  const role = project.role
  const roleName = showRoleName ? role.localizedName : null
  const circleName = showCircleName ? projectCircle.localizedName : null
  const roi = project.roi && project.organization.isROIEnabled
    ? <ProjectRoi roi={project.roi} />
    : null
  const personName = showPersonName ? project.person?.name : null

  const link = project.link && !isEmpty(project.link)
    ? (
      <a
        href={project.link}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {I18n.t('projects.my_projects.link')}
      </a>
    )
    : null

  const noteTooltip = (() => {
    const note = project.note

    if (!note || isNil(note) || isEmpty(note))
      return null

    return (
      <NoteTooltip
        note={note}
        organization={project.organization}
      />
    )
  })()

  const visibilityLabel = (() => {
    let label

    if (project.privateToCircle)
      label = I18n.t('projects.my_projects.private_to_circle')
    else if (project.privateToPerson)
      label = I18n.t('projects.my_projects.private_to_person')
    else
      return null

    return (
      <span>
        <ProjectPrivateIcon className={styles.icon} />
        {label}
      </span>
    )
  })()

  const source = (() => {
    if (!propsCircle)
      return null

    const internal = propsCircle.id === projectCircle.id

    return (
      <span>
        <SourceIcon
          source={internal ? 'internal' : 'external'}
          className={styles.icon}
        />
        {internal
          ? I18n.t('shared.enum.source.internal')
          : I18n.t('shared.enum.source.external')}
      </span>
    )
  })()

  return (
    <ItemDetailRow
      items={
        [
          roleName,
          personName,
          circleName,
          <AgeComponent createdAt={project.createdAt} />,
          visibilityLabel,
          source,
          roi,
          link,
          noteTooltip,
          <ParentGoalOrTarget project={project} />,
        ]
      }
    />
  )
}

export default (createFragmentContainer(ProjectDetails, {
  project: graphql`
    fragment ProjectDetails_project on Project {
      databaseId
      createdAt
      privateToCircle
      privateToPerson
      link
      note
      roi
      parentGoalOrTarget
      parentGoalOrTargetDescription
      
      organization {
        isROIEnabled
        
        ...NoteTooltip_organization
      }

      person {
        name
      }

      circle {
        localizedName
        id
      }

      role {
        localizedName
      }
    }
  `,
  circle: graphql`
    fragment ProjectDetails_circle on Circle {
      id
    }
  `,
}): AbstractComponent<
  $RelayProps<React$ElementConfig<typeof ProjectDetails>>,
>)
