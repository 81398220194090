/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MetricItem_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Metric_circle$ref: FragmentReference;
declare export opaque type Metric_circle$fragmentType: Metric_circle$ref;
export type Metric_circle = {|
  +databaseId: string,
  +organization: {|
    +databaseId: string
  |},
  +$fragmentRefs: MetricItem_circle$ref,
  +$refType: Metric_circle$ref,
|};
export type Metric_circle$data = Metric_circle;
export type Metric_circle$key = {
  +$data?: Metric_circle$data,
  +$fragmentRefs: Metric_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Metric_circle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricItem_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4771257a0017932c46c418a410eec45';

module.exports = node;
