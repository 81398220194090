/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AgendaTensionEditForm_agendaItem$ref = any;
type AgendaTensionEditForm_meeting$ref = any;
type TensionInfo_tension$ref = any;
type TensionInfo_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TacticalMeetingTension_meeting$ref: FragmentReference;
declare export opaque type TacticalMeetingTension_meeting$fragmentType: TacticalMeetingTension_meeting$ref;
export type TacticalMeetingTension_meeting = {|
  +databaseId: string,
  +organization: {|
    +databaseId: string,
    +viewer: {|
      +$fragmentRefs: TensionInfo_viewer$ref
    |},
  |},
  +currentAgendaItem: ?{|
    +id: string,
    +viewerCanCreateTension: boolean,
    +tension: ?{|
      +body: string,
      +viewerCanUpdate: boolean,
      +$fragmentRefs: TensionInfo_tension$ref,
    |},
    +$fragmentRefs: AgendaTensionEditForm_agendaItem$ref,
  |},
  +$fragmentRefs: AgendaTensionEditForm_meeting$ref,
  +$refType: TacticalMeetingTension_meeting$ref,
|};
export type TacticalMeetingTension_meeting$data = TacticalMeetingTension_meeting;
export type TacticalMeetingTension_meeting$key = {
  +$data?: TacticalMeetingTension_meeting$data,
  +$fragmentRefs: TacticalMeetingTension_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TacticalMeetingTension_meeting",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TensionInfo_viewer"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AgendaItem",
      "kind": "LinkedField",
      "name": "currentAgendaItem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanCreateTension",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tension",
          "kind": "LinkedField",
          "name": "tension",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewerCanUpdate",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TensionInfo_tension"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AgendaTensionEditForm_agendaItem"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgendaTensionEditForm_meeting"
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2910e380e7601f77ab656865503649d';

module.exports = node;
