// @flow
import type {ComponentType, ElementRef, Node} from 'react'
import React, {Suspense, lazy} from 'react'
import {useFragment, graphql} from 'react-relay'

import type {
  CKAutocompleteItem,
  FakeOnBlurEvent,
  FakeOnFocusEvent,
  FakeOnKeyDownEvent,
} from 'components/ui/CKEditor'
import type {
  WYSIWYGTextareaWithFeatureFlag_organization$key as OrganizationKey,
} from './__generated__/WYSIWYGTextareaWithFeatureFlag_organization.graphql'

const WYSIWYGTextarea: ComponentType<*> | string = lazy(() => import(
  /* webpackChunkName: "WYSIWYGTextarea" */
  '../WYSIWYGTextarea'
))

const organizationFragment = graphql`
  fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
    databaseId
    isWysiwygEnabled
  }
`

type Props<FallbackProps> = $ReadOnly<{
  mode?: 'classic' | 'inline',
  value: string,
  onChange: SyntheticInputEvent<HTMLInputElement> => void,
  label: Node,
  forceAllow?: boolean,
  hideWarning?: boolean,
  hideLabel?: boolean,
  name: string,
  autofocus?: boolean,
  innerRef?: ElementRef<typeof WYSIWYGTextarea | ComponentType<FallbackProps>>,
  disabled?: boolean,
  removed?: boolean,
  warning?: ?string,
  updated?: boolean,
  warningStatus?: 'warning' | 'error',
  paddingSize?: 'large' | 'medium',
  onBlur?: (FakeOnBlurEvent) => void,
  onFocus?: (FakeOnFocusEvent) => void,
  rows?: number,
  onKeyDown?: FakeOnKeyDownEvent => void,
  tinyMarginBottom?: boolean,
  getFeed?: (string) => $ReadOnlyArray<CKAutocompleteItem>,
  // for feature flag purpose
  organization: OrganizationKey,
  // to be able to fallback to Textarea when feature flag is off
  fallbackProps?: FallbackProps | {},
  fallbackComponent: ComponentType<FallbackProps> | string,
}>

function WYSIWYGTextareaWithFeatureFlag<FallbackProps>({
  mode,
  autofocus,
  value,
  onChange,
  name,
  disabled,
  removed,
  updated,
  label,
  forceAllow,
  hideLabel,
  hideWarning,
  warning,
  warningStatus,
  paddingSize,
  onBlur,
  onFocus,
  onKeyDown,
  rows,
  getFeed,
  tinyMarginBottom,
  organization: organizationKey,
  fallbackProps = {},
  fallbackComponent: FallbackComponent,
  innerRef,
  ...props
}: Props<FallbackProps>): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const fallback = (() => (
    <FallbackComponent
      autofocus={autofocus}
      rows={rows}
      disabled={disabled}
      {...fallbackProps}
      {...props}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      name={name}
      label={label}
      warning={warning}
      onBlur={onBlur}
      onFocus={onFocus}
      innerRef={innerRef}
    />
  ))()

  if (!organization.isWysiwygEnabled && !forceAllow)
    return fallback

  return (
    <Suspense fallback={fallback}>
      <WYSIWYGTextarea
        tinyMarginBottom={tinyMarginBottom}
        mode={mode}
        autofocus={autofocus}
        innerRef={innerRef}
        removed={removed}
        updated={updated}
        disabled={disabled}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        name={name}
        label={label}
        hideLabel={hideLabel}
        hideWarning={hideWarning}
        warning={warning}
        warningStatus={warningStatus}
        paddingSize={paddingSize}
        onBlur={onBlur}
        onFocus={onFocus}
        rows={rows}
        getFeed={getFeed}
      />
    </Suspense>
  )
}

export default WYSIWYGTextareaWithFeatureFlag
