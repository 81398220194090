// @flow
import type {Node} from 'react'
import React, {Fragment, useRef} from 'react'
import type {FormikProps as FormikGenerikProps} from 'formik'
import {Formik} from 'formik'
import {useFragment, graphql} from 'react-relay'
import useUniqueId from 'utils/hooks/useUniqueId'

import constants from 'utils/projects/constants'
import type {
  SubmitValues,
  Values,
  Mode,
  AllProjectStatuses,
  InitialProjectStatuses,
} from 'components/projects/ProjectEditForm'
import ProjectEditForm from 'components/projects/ProjectEditForm'
import Modal from 'components/ui/Modal'
import UpdateTensionInlineBox from 'components/tensions/UpdateTensionInlineBox'
import TensionInfo from 'components/tensions/TensionInfo'
import type {Alert} from 'components/types'

import type {
  ProjectFormModalView_circle$key as CircleKey,
} from './__generated__/ProjectFormModalView_circle.graphql'
import type {
  ProjectFormModalView_organization$key as OrganizationKey,
} from './__generated__/ProjectFormModalView_organization.graphql'
import type {
  ProjectFormModalView_viewer$key as ViewerKey,
} from './__generated__/ProjectFormModalView_viewer.graphql'
import type {
  ProjectFormModalView_tension$key as TensionKey,
} from './__generated__/ProjectFormModalView_tension.graphql'
import type {
  ProjectFormModalView_project$key as ProjectKey,
} from './__generated__/ProjectFormModalView_project.graphql'
import flash from '../../../../utils/MiscUtils/flash'

type FormikProps = FormikGenerikProps<Values>
type Props<SubmitResponse> = {
  circle: ?CircleKey,
  close: () => void,
  disabled: boolean,
  initialValues: Values,
  modalTitle: string,
  mode: Mode,
  submit: SubmitValues => Promise<SubmitResponse>,
  onSuccess?: () => Promise<void>,
  organization: OrganizationKey,
  renderLeftFooterButton: FormikProps => Node,
  tension: TensionKey | null,
  viewer: ViewerKey,
  statuses: InitialProjectStatuses | AllProjectStatuses,
  project: ProjectKey | null,
}

function validateForm(values: Values) {
  const errors = {}

  if (!values.supportedRoleId)
    errors.supportedRoleId = I18n.t('errors.messages.required')

  if (!values.roleId)
    errors.roleId = I18n.t('errors.messages.required')

  if (!values.description)
    errors.description = I18n.t('errors.messages.required')

  return errors
}

const organizationFragment = graphql`
  fragment ProjectFormModalView_organization on Organization {
    ...ProjectEditForm_organization
  }
`

const viewerFragment = graphql`
  fragment ProjectFormModalView_viewer on Person {
    ...ProjectEditForm_viewer
    ...UpdateTensionInlineBox_viewer
  }
`

const circleFragment = graphql`
  fragment ProjectFormModalView_circle on Circle {
    ...ProjectEditForm_circle
  }
`

const tensionFragment = graphql`
  fragment ProjectFormModalView_tension on Tension {
    ...UpdateTensionInlineBox_tension
    ...TensionInfo_tension
  }
`

const projectFragment = graphql`
  fragment ProjectFormModalView_project on Project {
    visibility

    ...ProjectEditForm_project
  }
`

function ProjectFormModalView<SubmitResponse: ?{+errors: $ReadOnlyArray<Alert>, ...}>({
  disabled,
  initialValues,
  submit,
  onSuccess,
  modalTitle,
  mode,
  organization: organizationKey,
  viewer: viewerKey,
  circle: circleKey,
  tension: tensionKey,
  renderLeftFooterButton,
  close,
  statuses,
  project: projectKey,
}: Props<SubmitResponse>): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const viewer = useFragment(viewerFragment, viewerKey)

  const circle = useFragment(circleFragment, circleKey)

  const tension = useFragment(tensionFragment, tensionKey)

  const project = useFragment(projectFragment, projectKey)

  const tensionFormRef = useRef()
  const [formId] = useUniqueId()

  const handleSubmit = async ({supportedRoleId, ...values}) => {
    if (disabled)
      return

    const roleType = values.roleId === constants.individualActionId
      ? 'INDIVIDUAL_INITIATIVE'
      : 'ROLE'

    if (roleType === 'INDIVIDUAL_INITIATIVE' && !supportedRoleId) {
      flash.danger('Can not use Individual initiative for non-circle project')
      return
    }

    const roleId = values.roleId === constants.individualActionId
      ? supportedRoleId
      : values.roleId

    if (!roleId) {
      flash.danger('Can not create or update project without role.')
      return
    }

    if (values.visibility !== project?.visibility && values.visibility === 'PRIVATE_TO_PERSON')
      flash.notice(I18n.t('projects.my_projects.private_to_person_notice'))

    const correctedValues = {
      ...values,
      accountabilityId:
        values.accountabilityId === constants.notSpecifiedId.toString()
          ? null
          : values.accountabilityId,
      roleId,
      roleType,
      value: values.value || null,
      effort: values.effort || null,
    }

    const response = await submit(correctedValues)

    if (response?.errors && response.errors.length > 0) {
      response.errors.forEach((error) => {
        flash.danger(error.message)
      })
    } else {
      if (onSuccess)
        onSuccess()

      close()
    }
  }

  const renderForm = (formikProps) => (
    <Fragment>
      <Modal.Header
        tooltip={I18n.t('projects.my_projects.add_project_tip')}
        close={close}
        title={modalTitle}
      />
      <Modal.Body>
        {tension && (
          <TensionInfo
            marginBottom="MEDIUM"
            renderTensionForm={(closeTensionForm) => (
              <UpdateTensionInlineBox
                onSuccess={closeTensionForm}
                onCancel={closeTensionForm}
                ref={tensionFormRef}
                tension={tension}
                viewer={viewer}
              />
            )}
            tension={tension}
          />
        )}
        <ProjectEditForm
          disabled={disabled}
          mode={mode}
          formId={formId}
          organization={organization}
          viewer={viewer}
          circle={circle}
          project={project}
          statuses={statuses}
          formikProps={formikProps}
        />
      </Modal.Body>
      {!disabled && (
        <Modal.Footer
          rightBlock={(
            <button
              className="btn btn-primary btn-lg"
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              type="submit"
              onClick={() => tensionFormRef.current && tensionFormRef.current.submit()}
              form={formId}
            >
              {I18n.t('buttons.save')}
            </button>
          )}
          leftBlock={renderLeftFooterButton(formikProps)}
        />
      )}
    </Fragment>
  )

  return (
    <Modal
      size="lg"
      test-id="project-form-modal"
      close={close}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateForm}
      >
        {renderForm}
      </Formik>
    </Modal>
  )
}

ProjectFormModalView.defaultProps = {
  disabled: false,
  renderLeftFooterButton: (): null => null,
  tension: null,
  circle: null,
  project: null,
  onSuccess: (): Promise<void> => Promise.resolve(),
}

export type {SubmitValues, Values, Mode, FormikProps, Props, TensionKey}

export default ProjectFormModalView
