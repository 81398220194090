// @flow strict

function uniqueArrayById<Item: {+id: string, ...}>(items: Item[]): Item[] {
  let itemsIds: $ReadOnlyArray<string | $FlowFixMe> = []
  return items.filter((item: Item) => {
    if (itemsIds.find((id) => id === item.id))
      return false

    itemsIds = [...itemsIds, item.id]
    return true
  })
}

export default uniqueArrayById
