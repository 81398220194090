// @flow
import React, {forwardRef} from 'react'
import {Field, useField} from 'formik'
import {useFragment, graphql} from 'react-relay'
import classNames from 'classnames'

import useCKRolesFeed from 'utils/hooks/useCKRolesFeed'
import Close from 'components/ui/icons/md/Close'
import IconButton from 'components/ui/IconButton'
import InputWithRoleAutocomplete from 'components/ui/forms/InputWithRoleAutocomplete'
import Textarea from 'components/ui/forms/Textarea'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import RemoveCheckbox from './RemoveCheckbox'
import styles from './index.scss'

import type {
  RemovableItem_organization$key as OrganizationKey,
} from './__generated__/RemovableItem_organization.graphql'

export type Item = $ReadOnly<{
  id: string,
  value: string,
  isNew: boolean,
  destroy: boolean,
  removable: boolean,
  autofocus?: boolean,
}>

type Props = $ReadOnly<{
  item: Item,
  name: string,
  onFocus: any,
  onKeyDown: any,
  organization: OrganizationKey,
  removeItem: () => ?Item,
  warning: ?string,
}>

const organizationFragment = graphql`
  fragment RemovableItem_organization on Organization {
    databaseId
    
    ...WYSIWYGTextareaWithFeatureFlag_organization
  }
`

function RemovableItem({
  item,
  name,
  onFocus,
  onKeyDown,
  organization: organizationKey,
  removeItem,
  warning,
}: Props, ref) {
  const organization = useFragment(organizationFragment, organizationKey)

  const rolesFeed = useCKRolesFeed(organization.databaseId)

  const [inputField, meta] = useField(`${name}.value`)
  const isChanged = meta.value !== meta.initialValue
  const inputClassName = classNames({
    [styles.removedItem]: item.destroy,
    [styles.changedItem]: isChanged,
  })

  const correctedRemoveItem = () => {
    removeItem()
  }

  return (
    <div className={styles.container}>
      <Field
        as={WYSIWYGTextareaWithFeatureFlag}
        mode="inline"
        organization={organization}
        fallbackProps={{
          autosize: true,
          input: Textarea,
          rows: 1,
          orgDatabaseId: organization.databaseId,
          inputRef: ref,
          className: inputClassName,
        }}
        innerRef={ref}
        removed={item.destroy}
        fallbackComponent={InputWithRoleAutocomplete}
        getFeed={rolesFeed}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        autofocus={item.isNew && item.autofocus}
        hideLabel
        label=""
        warning={warning}
        name={inputField.name}
        disabled={!item.removable || item.destroy}
      />
      {item.removable
        ? (
          <div className={styles.removeButtonContainer}>
            {item.isNew
              ? (
                <IconButton
                  className={styles.removeButton}
                  buttonText={I18n.t('shared.remove')}
                  onClick={correctedRemoveItem}
                  icon={<Close className={styles.removeIcon} />}
                />
              )
              : (
                <Field as={RemoveCheckbox} name={`${name}.destroy`} />
              )
            }
          </div>
        )
        : (
          <div className={styles.removeButtonPlaceholder} />
        )
      }
    </div>
  )
}

export default (forwardRef<Props, _>(RemovableItem): React$AbstractComponent<Props, any>)
