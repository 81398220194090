/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type WYSIWYGViewerWithFeatureFlag_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectDescription_project$ref: FragmentReference;
declare export opaque type ProjectDescription_project$fragmentType: ProjectDescription_project$ref;
export type ProjectDescription_project = {|
  +description: string,
  +organization: {|
    +$fragmentRefs: WYSIWYGViewerWithFeatureFlag_organization$ref
  |},
  +$refType: ProjectDescription_project$ref,
|};
export type ProjectDescription_project$data = ProjectDescription_project;
export type ProjectDescription_project$key = {
  +$data?: ProjectDescription_project$data,
  +$fragmentRefs: ProjectDescription_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDescription_project",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MARKDOWN"
        }
      ],
      "kind": "ScalarField",
      "name": "description",
      "storageKey": "description(format:\"MARKDOWN\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WYSIWYGViewerWithFeatureFlag_organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b22c588caaaaf489d5bf29470c5cd6b8';

module.exports = node;
