// @flow
import type {Node} from 'react'
import React from 'react'

import NoItemsMessage from 'components/ui/NoItemsMessage'

function NoMeetingsMessage(): Node {
  return <NoItemsMessage message={I18n.t('react.components.listview.no_items')} />
}

export default NoMeetingsMessage
