// @flow
import type {Element} from 'react'
import React, {useState} from 'react'
import {DragDropContext, Droppable, type DropResult} from 'react-beautiful-dnd'

import UpdateActionPositionMutation from 'mutations/Actions/UpdateActionPositionMutation'
import type {CollectionNode} from 'utils/GraphQL/types'
import Item from './Item'
import DraggableItem from '../DraggableItem'
import type {
  List_project as Project,
} from '../__generated__/List_project.graphql'

const newActionFakeId = 'NEW_ACTION'

type Action = CollectionNode<$PropertyType<Project, 'actions'>>
type Actions = $ReadOnlyArray<Action>

type Props = $ReadOnly<{
  actions: Actions,
  project: Project,
  expandable: boolean,
}>

function ListItems({actions, project, expandable}: Props): Element<"div"> {
  const [editingActionId, setEditingActionId] = useState<string | null>(null)

  const startEditAction = (actionId: string) => {
    setEditingActionId(actionId)
  }

  const finishEditAction = () => {
    setEditingActionId(null)
  }

  const updateItemPosition = (actionId: string, position: number) => {
    UpdateActionPositionMutation({actionId, position}, project.id)
  }

  const onDragEnd = ({draggableId, destination}: DropResult) => {
    if (!destination)
      return

    const actionId = draggableId
    const actionToReplace = actions[destination.index]

    updateItemPosition(actionId, actionToReplace.position || 1)
  }

  const focusOnNextItem = (actionId: string, direction: 'BACK' | 'FORWARD') => {
    const currentItemIndex = actions.findIndex((action) => action.id === actionId)
    const forwardItem = actions[currentItemIndex + 1] || actions[0]
    const backItem = actions[currentItemIndex - 1] || actions[actions.length - 1]
    const maybeNextItem = direction === 'BACK'
      ? backItem
      : forwardItem

    const nextItem = maybeNextItem || actions[0]

    startEditAction(nextItem.id)
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef}>
              {actions.map((item, index) => (
                <DraggableItem
                  index={index}
                  key={`action-${item.id}`}
                  actionId={item.id}
                >
                  <Item
                    isEditing={editingActionId === item.id}
                    startEditing={() => startEditAction(item.id)}
                    finishEditing={finishEditAction}
                    project={project}
                    action={item}
                    onTabPress={focusOnNextItem}
                    readonly={!item.viewerCanUpdate}
                  />
                </DraggableItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {expandable && (
        <Item
          isEditing={editingActionId === newActionFakeId}
          startEditing={() => startEditAction(newActionFakeId)}
          finishEditing={finishEditAction}
          project={project}
          key="action-newItem"
          action={null}
        />
      )}
    </div>
  )
}

export default ListItems
