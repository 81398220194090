// @flow
import type {Element, Node} from 'react'
import React from 'react'
import {UncontrolledDropdown, DropdownToggle, DropdownMenu} from 'reactstrap'

import EtcSelector, {type Option, type Value} from 'components/ui/EtcMenu/EtcSelector'
import styles from './index.scss'

type Props = {|
  'test-id': ?string,
  label: (Option[]) => Node,
  highlightedLabel: (Option[]) => Node,
  onSelect: Option => void,
  options: Option[],
  selectedValues: Value[],
  caret: boolean,
|}

function TinyMultiSelectDropdown({
  label,
  highlightedLabel,
  onSelect,
  options,
  selectedValues,
  caret,
  'test-id': testId,
}: Props): Element<"div"> {
  const selectedOptions: Option[] = options.filter((option) => selectedValues.includes(option.value))

  return (
    <div className={styles.container}>
      <UncontrolledDropdown
        className="etc-menu-button"
      >
        <DropdownToggle
          test-id={testId}
          caret={caret}
          className={styles.dropdownButton}
          color="link"
        >
          {label(selectedOptions)}
          &nbsp;
          <span className={styles.highlightedLabel}>
            {highlightedLabel(selectedOptions)}
          </span>
        </DropdownToggle>
        <DropdownMenu
          className={styles.dropdownMenu}
          modifiers={[{name: 'preventOverflow', options: {boundary: document.body}}]}
        >
          <EtcSelector
            options={options}
            selectedValues={selectedValues}
            onSelect={onSelect}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

TinyMultiSelectDropdown.defaultProps = {
  caret: false,
  'test-id': null,
}

export default TinyMultiSelectDropdown
