// @flow

// Should be used for calculating ROI during project creation only
// For displaying ROI of existed project should be used project.roi GQL field
export default function calcRoi(value: ?number, effort: ?number): ?(number | string) {
  if (effort === null || effort === undefined)
    return value

  if (value === null || value === undefined)
    return null

  let result = value / effort

  if (result > 0) {
    const decimals = (result >= 10) ? 0 : 1
    result = result.toFixed(decimals)
  }

  if (Number.isNaN(result))
    return null

  return result
}
