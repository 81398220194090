/**
 * @flow
 * @relayHash 07a7915426af0d122139ab3b4e989a82
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RoleTemplatesSectionView_organization$ref = any;
export type RoleTemplatesSectionQueryVariables = {|
  orgDatabaseId: string
|};
export type RoleTemplatesSectionQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: RoleTemplatesSectionView_organization$ref
  |}
|};
export type RoleTemplatesSectionQuery = {|
  variables: RoleTemplatesSectionQueryVariables,
  response: RoleTemplatesSectionQueryResponse,
|};
*/


/*
query RoleTemplatesSectionQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...RoleTemplatesSectionView_organization
    id
  }
}

fragment CreateRoleTemplateModal_organization on Organization {
  id
  ...RoleTemplateFormModal_organization
}

fragment RemovableItem_organization on Organization {
  databaseId
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment RemovableItems_organization on Organization {
  ...RemovableItem_organization
}

fragment RoleTemplateFormModal_organization on Organization {
  ...RemovableItems_organization
}

fragment RoleTemplatesList_organization on Organization {
  id
  roleTemplates(first: 100) {
    edges {
      node {
        id
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...CreateRoleTemplateModal_organization
}

fragment RoleTemplatesSectionView_organization on Organization {
  ...RoleTemplatesList_organization
  ...CreateRoleTemplateModal_organization
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleTemplatesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoleTemplatesSectionView_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleTemplatesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RoleTemplateConnection",
            "kind": "LinkedField",
            "name": "roleTemplates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleTemplateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "roleTemplates(first:100)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "RoleTemplatesList_roleTemplates",
            "kind": "LinkedHandle",
            "name": "roleTemplates"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWysiwygEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "07a7915426af0d122139ab3b4e989a82",
    "metadata": {},
    "name": "RoleTemplatesSectionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '346c1817e018bfc31879064d27ebaf07';

module.exports = node;
