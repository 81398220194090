/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type WYSIWYGTextareaWithFeatureFlag_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectEditForm_organization$ref: FragmentReference;
declare export opaque type ProjectEditForm_organization$fragmentType: ProjectEditForm_organization$ref;
export type ProjectEditForm_organization = {|
  +databaseId: string,
  +isGoalsEnabled: boolean,
  +isROIEnabled: boolean,
  +supportableGoalsAndTargets: $ReadOnlyArray<any>,
  +onRestrictedPlan: boolean,
  +showAccountabilities: boolean,
  +$fragmentRefs: WYSIWYGTextareaWithFeatureFlag_organization$ref,
  +$refType: ProjectEditForm_organization$ref,
|};
export type ProjectEditForm_organization$data = ProjectEditForm_organization;
export type ProjectEditForm_organization$key = {
  +$data?: ProjectEditForm_organization$data,
  +$fragmentRefs: ProjectEditForm_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectEditForm_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGoalsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isROIEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportableGoalsAndTargets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onRestrictedPlan",
      "storageKey": null
    },
    {
      "alias": "showAccountabilities",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "accountabilities"
        }
      ],
      "kind": "ScalarField",
      "name": "featureEnabled",
      "storageKey": "featureEnabled(name:\"accountabilities\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WYSIWYGTextareaWithFeatureFlag_organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4e35d07cc03ef9d51c980cf057167ba5';

module.exports = node;
