// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from 'mutations/createMutationPromise'
import type {
  AddMetricMutationResponse as Response,
  AddMetricInput as Input,
} from './__generated__/AddMetricMutation.graphql'

const mutation = graphql`
  mutation AddMetricMutation($input: AddMetricInput!) {
    addMetric(input: $input) {
      metricEdge {
        node {
          role {
            id
          }
          
          ...Metric_metric
        }
      }
    }
  }
`

const AddMetricMutation = (input: MutationInput<Input>, circleId: string): Promise<Response> => {
  const variables = {input}

  const configs = [
    {
      type: 'RANGE_ADD',
      parentID: circleId,
      connectionInfo: [{
        key: 'Circle_metrics',
        rangeBehavior: 'append',
      }],
      edgeName: 'metricEdge',
    },
    {
      type: 'RANGE_ADD',
      parentID: input.roleId,
      connectionInfo: [{
        key: 'Role_metrics',
        rangeBehavior: 'append',
        filters: {
          global: 'NON_GLOBAL_ONLY',
        },
      }],
      edgeName: 'metricEdge',
    },
    {
      type: 'RANGE_ADD',
      parentID: circleId,
      connectionInfo: [{
        key: 'TacticalMeetingMetricsView_metrics',
        rangeBehavior: 'append',
      }],
      edgeName: 'metricEdge',
    },
  ]

  return createMutationPromise({mutation, variables, configs})
}

export type {Response}
export default AddMetricMutation
