// @flow
import type {Element, Node} from 'react'
import React, {useState, useCallback} from 'react'
import {Tooltip} from 'reactstrap'

import useUniqueId from 'utils/hooks/useUniqueId'
import Pencil from 'components/ui/icons/fa/Pencil'
import type {ViewContext} from 'components/types'
import IconButton from '../IconButton'
import styles from './index.scss'

type TooltipMessage = {
  body: ?string,
}

type Props = $ReadOnly<{
  onClick: () => void,
  disabled: boolean,
  viewContext: ViewContext,
  tooltip: TooltipMessage,
  children: Node,
}>

function EditItemButton({onClick, disabled, viewContext, tooltip, children}: Props): Element<"div"> {
  const [id] = useUniqueId()
  const [tooltipState, setTooltipState] = useState(false)

  const toggle = useCallback(() => {
    setTooltipState(!tooltipState)
  }, [tooltipState])

  const iconSize = viewContext === 'page' ? 'md' : 'sm'

  return (
    <div className="ui-edit-item-button">
      <div className="ui-edit-item-button__icon" id={id}>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          size={iconSize}
          testId="edit-button"
          title={I18n.t('nav.edit')}
        >
          {children || <Pencil className={disabled ? styles.icon : ''} />}
        </IconButton>
        {disabled && tooltip.body && (
          <Tooltip placement="right" isOpen={tooltipState} target={id} toggle={toggle}>
            {tooltip.body}
          </Tooltip>
        )}
      </div>
    </div>
  )
}

EditItemButton.defaultProps = {
  disabled: false,
  tooltip: {body: null},
  children: null,
}

export default EditItemButton
