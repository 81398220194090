// @flow
import React, {type Element, useRef} from 'react'
import {graphql, useFragment} from 'react-relay'

import {Formik} from 'formik'
import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import TacticalMeetingOutputsEventSubscription from 'subscriptions/TacticalMeetingOutputsEventSubscription'

import TacticalMeetingTension from './TacticalMeetingTension'
import AgendaItemGroups from './AgendaItemGroups'
import NewOutputForm from './NewOutputForm'
import type {
  TacticalMeetingTriageView_meeting$key as MeetingKey,
} from './__generated__/TacticalMeetingTriageView_meeting.graphql'

type Props = $ReadOnly<{
  meetingKey: MeetingKey,
  refetchQuery: () => Promise<null>,
}>

const meetingFragment = graphql`
  fragment TacticalMeetingTriageView_meeting on Meeting {
    databaseId

    ...NewOutputForm_meeting
    ...AgendaItemGroups_meeting
    ...TacticalMeetingTension_meeting

    circle {
      ...NewOutputForm_circle
    }
  }
`

function TacticalMeetingTriageView({meetingKey, refetchQuery}: Props): Element<"div"> {
  const meeting = useFragment(meetingFragment, meetingKey)
  const circle = meeting.circle

  const tensionFormRef: mixed & { current: Function } = useRef<?React$ElementRef<typeof Formik>>()

  const beforeOutputSave = async () => {
    if (tensionFormRef.current)
      await tensionFormRef.current.submit()
  }

  useSubscriptionRefetch(
    TacticalMeetingOutputsEventSubscription,
    {tacticalMeetingDatabaseId: meeting.databaseId},
    refetchQuery,
    {},
  )

  return (
    <div id="tactical-meeting">
      <div className="section">
        <div test-id="tactical-current-agenda">
          <TacticalMeetingTension
            ref={tensionFormRef}
            meetingKey={meeting}
            refetchQuery={refetchQuery}
          />
        </div>
      </div>
      <div className="section">
        <NewOutputForm
          beforeSave={beforeOutputSave}
          circle={circle}
          meeting={meeting}
        />
      </div>
      <div className="section">
        <div className="section__header">
          <h3 className="header--medium">
            {I18n.t('tactical_meetings.current.meeting_outputs')}
          </h3>
        </div>
        <div className="section__body">
          <AgendaItemGroups
            meetingKey={meeting}
          />
        </div>
      </div>
    </div>
  )
}

export default TacticalMeetingTriageView
