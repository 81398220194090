/**
 * @flow
 * @relayHash 88c1accff0f7b275ee304b013a62c4da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteRoleTemplateInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  roleTemplateId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteRoleTemplateMutationVariables = {|
  input: DeleteRoleTemplateInput
|};
export type DeleteRoleTemplateMutationResponse = {|
  +deleteRoleTemplate: ?{|
    +deletedRoleTemplateId: string
  |}
|};
export type DeleteRoleTemplateMutation = {|
  variables: DeleteRoleTemplateMutationVariables,
  response: DeleteRoleTemplateMutationResponse,
|};
*/


/*
mutation DeleteRoleTemplateMutation(
  $input: DeleteRoleTemplateInput!
) {
  deleteRoleTemplate(input: $input) {
    deletedRoleTemplateId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteRoleTemplatePayload",
    "kind": "LinkedField",
    "name": "deleteRoleTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedRoleTemplateId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRoleTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteRoleTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "88c1accff0f7b275ee304b013a62c4da",
    "metadata": {},
    "name": "DeleteRoleTemplateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd098520ac2c2072cd65ee23b4c2f6ed9';

module.exports = node;
