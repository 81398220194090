// @flow
import type {Element, Node} from 'react'
import React from 'react'
import {subMinutes, isAfter, isBefore, parseISO, differenceInMinutes} from 'date-fns'
import usePeriodicRerender from 'utils/hooks/usePeriodicRerender'
import Button from './Button'
import UpsellMessage, {type UpsellType} from './UpsellMessage'

const isWithinRange = (date, dateStart, dateEnd) => (
  isAfter(date, dateStart) && isBefore(date, dateEnd)
)

type Props = $ReadOnly<{
  startsAt: string,
  endsAt: string,
  url: string,
  accountId: number,
  upsell: UpsellType,
  joinWindowInMinutes: number,
}>

function OfficeHourButtonComponent({
  startsAt: startsAtStr, endsAt: endsAtStr,
  url,
  accountId,
  upsell,
  joinWindowInMinutes,
}: Props): Element<"div"> | Node {
  usePeriodicRerender()

  const currentDate = new Date()
  const startsAt = parseISO(startsAtStr)
  const endsAt = parseISO(endsAtStr)
  const minutesLeft = differenceInMinutes(startsAt, currentDate)

  if (upsell) {
    return (
      <UpsellMessage accountId={accountId} upsell={upsell} />
    )
  }

  if (isBefore(currentDate, subMinutes(startsAt, joinWindowInMinutes))) {
    return (
      <Button
        linkUrl="#"
        buttonText={I18n.t('office_hours.distant_future_button_text')}
        tooltip={I18n.t('office_hours.distant_future_tooltip')}
        disabled
      />
    )
  }

  if (isWithinRange(currentDate, subMinutes(startsAt, joinWindowInMinutes), startsAt)) {
    return (
      <Button
        linkUrl="https://zoom.us/test"
        buttonText={I18n.t('office_hours.starts_soon_button_text')}
        statusText={I18n.t('office_hours.starts_soon_status', {minutes: minutesLeft})}
        tooltip={I18n.t('office_hours.starts_soon_tooltip')}
      />
    )
  }

  if (isWithinRange(currentDate, startsAt, endsAt)) {
    return (
      <Button
        linkUrl={url}
        buttonText={I18n.t('office_hours.in_progress_button_text')}
        statusText={I18n.t('office_hours.in_progress_status')}
        tooltip={I18n.t('office_hours.in_progress_tooltip')}
      />
    )
  }

  return (
    <div className="status test-status tiny-caption ml-2">
      {I18n.t('office_hours.ended_status')}
    </div>
  )
}

OfficeHourButtonComponent.defaultProps = {
  joinWindowInMinutes: 10,
}

export default OfficeHourButtonComponent
