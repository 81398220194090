// @flow
import {graphql} from 'react-relay'

import type {ID} from './types'
import createMutationPromise from './createMutationPromise'

const mutation = graphql`
  mutation TryDownloadableReportMutation(
    $input: TryDownloadableReportInput!
  ) {
    tryDownloadableReport(input: $input) {
      asyncRequired
      downloadUrl
      message
    }
  }
`

export type Input = {
  organizationId: ID,
  options: Object,
  reportName: string,
  roleId: ID,
}

export default (input: Input): Promise<any> => {
  const variables = {
    input,
  }

  return createMutationPromise({
    mutation,
    variables,
  })
}
