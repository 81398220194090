// @flow
import {omit} from 'ramda'

import logger from 'utils/MiscUtils/main'
import type {ActionFunction} from 'components/types'
import LocalStorageCell from 'utils/LocalStorageCell'
import {walk} from './D3OrgChart/lib/tree'
import {nodeId} from './D3OrgChart/lib/node'
import {withAuth} from '../../../authentication'
import recursiveMerge from './recursiveMerge'

const CHILDREN_OMITTED = null
let instances = {}
let lastAccessedOrganizationId = null

export type ChartNode = $ReadOnly<{
  children?: ChartNode[],
  id: string,
  name: string,
  parent?: ChartNode,
  type: 'circle' | 'role' | 'label',
  root?: number,
  ...
}>

const chartTreeToAnchorLookup = (chartTree: ChartNode): { [string]: ChartNode, ... } => {
  const anchorLookup = {}

  walk(chartTree, (node: ChartNode, parent: ?ChartNode) => {
    anchorLookup[nodeId(node)] = {
      parent: parent ? anchorLookup[nodeId(parent)] : null,
      ...node,
    }

    return node
  })

  return anchorLookup
}

type ChartUpdateCallback = ({ data?: ChartNode, error?: Error }) => void

class ChartNodes {
  static instance(organizationId: string): ChartNodes {
    lastAccessedOrganizationId = organizationId
    if (!instances[organizationId])
      instances[organizationId] = new ChartNodes(organizationId)
    return instances[organizationId]
  }

  static destroyInstances(organizationId: string) {
    instances = omit([organizationId], instances)
  }

  static lastAccessedInstance(): ?ChartNodes {
    return lastAccessedOrganizationId
      ? ChartNodes.instance(lastAccessedOrganizationId)
      : null
  }

  constructor(organizationId: string) {
    this.organizationDatabaseId = organizationId
    this.chartTree = null
    this.anchorLookup = null
    this.onUpdateCallbacks = []
    this.onReadyCallbacks = []
    this.localStorage = new LocalStorageCell(`orgChartData-${this.organizationDatabaseId}-v3`)
  }

  localStorage: Object
  organizationDatabaseId: string
  chartTree: ?ChartNode
  anchorLookup: ?{ [string]: ChartNode, ... }
  onUpdateCallbacks: ChartUpdateCallback[]
  onReadyCallbacks: ActionFunction[]

  async chartData(): Promise<?ChartNode> {
    if (!this.chartTree) {
      if (this.localStorage.isPresent())
        this.writeData(this.localStorage.get())

      if (this.chartTree)
        this.reFetchData()
      else
        await this.reFetchData()
    }
    return this.chartTree
  }

  async reFetchData() {
    await this.fetchData()
      .then(this.writeData)
  }

  async fetchData(): Promise<any> {
    const response = await fetch(
      this.chartUrl,
      withAuth(),
    )
    return response.json()
  }

  subscribe: ((callback: ChartUpdateCallback) => void) = (callback: ChartUpdateCallback) => {
    this.chartData()
      .then((data) => {
        if (data)
          callback({data})

        this.onUpdate(callback)
      })
  }

  writeData: ((data: ChartNode) => void) = (data: ChartNode) => {
    if (!data.root)
      return

    this.chartTree = data
    this.localStorage.set(data)
    this.anchorLookup = chartTreeToAnchorLookup(data)
    this.notifyReady()
    this.notifyUpdates()
  }

  patch: ((newData: ChartNode) => void) = (newData: ChartNode) => {
    if (!this.chartTree) {
      this.writeData(newData)
      return
    }

    const isAnchorCircleUpdate = (newData.id === this.chartTree.id)

    if (isAnchorCircleUpdate) {
      this.writeData(recursiveMerge(newData, this.chartTree))
      return
    }

    let parentNode = null
    walk(this.chartTree, (node, parent) => {
      if (node.id === newData.id)
        parentNode = parent
    })

    if (!parentNode || !parentNode.children) {
      logger.warn('parent node not found')
      return
    }

    const childIndex = parentNode.children.findIndex((n) => n.id === newData.id)

    if (childIndex === -1) {
      logger.warn('node not found in parent.children')
      return
    }
    const mergedData = recursiveMerge(newData, parentNode.children[childIndex])
    parentNode.children[childIndex] = mergedData

    if (this.chartTree)
      this.writeData(this.chartTree)
  }

  get storageKey(): string {
    return `orgChartData-${this.organizationDatabaseId}`
  }

  get chartUrl(): string {
    return `${document.location.origin}/organizations/${this.organizationDatabaseId}/chart_json`
  }

  notifyUpdates: (() => void) = () => {
    this.onUpdateCallbacks.forEach((callback) => {
      if (this.chartTree)
        callback({data: this.chartTree})
    })
  }

  notifyReady: (() => void) = () => {
    this.onReadyCallbacks.forEach((callback) => callback())
  }

  onUpdate: ((onUpdateCallback: ChartUpdateCallback) => void) = (onUpdateCallback: ChartUpdateCallback) => {
    this.onUpdateCallbacks.push(onUpdateCallback)
  }

  onReady: ((onReadyCallback: ActionFunction) => void) = (onReadyCallback: ActionFunction) => {
    if (this.chartTree && this.anchorLookup)
      onReadyCallback()

    this.onReadyCallbacks.push(onReadyCallback)
  }

  find: ((roleDatabaseId: string) => ?ChartNode) = (roleDatabaseId: string): ?ChartNode => {
    const anchorLookup = this.anchorLookup || {}
    const foundNode = anchorLookup[`role-${roleDatabaseId}`]

    return foundNode || this.chartTree
  }
}

export default ChartNodes
export {
  CHILDREN_OMITTED,
}
