// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import FrogTipBox from 'components/ui/FrogTipBox'
import Section from 'components/organizationSettings/ui/Section'
import ButtonWithProcessing from 'components/ui/ButtonWithProcessing'
import HolacracyMarkBlue from 'components/ui/icons/HolacracyMarkBlue'
import ConvertOrganizationToV5Mutation
  from 'mutations/Organizations/ConvertOrganizationToV5Mutation'
import type {V4ConstitutionSection_organization as Organization}
  from './__generated__/V4ConstitutionSection_organization.graphql'
import Status from '../Status'
import AdvancedSettings from './AdvancedSettingsV4'

type Props = $ReadOnly<{
  organization: Organization,
  updateRefetchId: () => void,
}>

const iconSize = {
  height: 45,
  width: 45,
}

const v5ConstitutionURL = 'https://www.holacracy.org/constitution/5'

const targetLabel = () => (
  <h2>{I18n.t('admin.organizations.form.tabs.constitution')}</h2>
)

function V4ConstitutionSection({organization, updateRefetchId}: Props): Node {
  const convertOrganizationToV5 = () => (
    ConvertOrganizationToV5Mutation({organizationId: organization.id}).then(updateRefetchId)
  )

  const convertToV5ConfirmMsg = [
    I18n.t(
      'admin.organizations.holacracy_version.confirm_upgrade.are_you_sure',
      {orgName: organization.name},
    ),
    I18n.t('admin.organizations.holacracy_version.confirm_upgrade.low_traffic'),
  ].join(' ')

  const renderUpgradeToV5Button = () => (
    <ButtonWithProcessing color="primary" onClick={convertOrganizationToV5} confirmMsg={convertToV5ConfirmMsg}>
      {I18n.t('admin.organizations.holacracy_version.upgrade_button_label')}
    </ButtonWithProcessing>
  )

  return (
    <Fragment>
      <FrogTipBox
        helpFor={I18n.t('admin.organizations.form.tabs.constitution')}
        target={targetLabel}
        tip={I18n.t('admin.organizations.holacracy_version.tab_help_text.about_this_page')}
      />
      <Section
        title={I18n.t('admin.organizations.holacracy_version.v4.title')}
        icon={<HolacracyMarkBlue {...iconSize} />}
        description={(
          <Fragment>
            <p>
              {I18n.t('admin.organizations.holacracy_version.v4.current')}
            </p>
            <div>
              {I18n.t('admin.organizations.holacracy_version.more_info')}
              <a href={organization.viewer.routes.constitutionUrl} target="_blank" rel="noopener noreferrer">
                {I18n.t('admin.organizations.holacracy_version.v4.constitution')}
              </a>
              {'.'}
            </div>
          </Fragment>
        )}
      />
      <Section
        title={I18n.t('admin.organizations.holacracy_version.upgrade_title')}
        icon={<HolacracyMarkBlue {...iconSize} />}
        description={(
          <Fragment>
            <p>
              {I18n.t('admin.organizations.holacracy_version.adopt_via_modular')}
              {' '}
              {I18n.t('admin.organizations.holacracy_version.v5.click_to_upgrade', {
                orgName: organization.name,
              })}
            </p>
            <div>
              {I18n.t('admin.organizations.holacracy_version.more_info')}
              <a href={v5ConstitutionURL} target="_blank" rel="noopener noreferrer">
                {I18n.t('admin.organizations.holacracy_version.v5.constitution')}
              </a>
              {'.'}
            </div>
          </Fragment>
        )}
        button={(
          <Status
            status={organization.v5migrationStatus}
            orgName={organization.name}
            renderUpgradeButton={renderUpgradeToV5Button}
            renderVersionDescriptionBlock={renderUpgradeToV5Button}
          />
        )}
      />
      <AdvancedSettings organization={organization} />
    </Fragment>
  )
}

export default (createFragmentContainer(V4ConstitutionSection, {
  organization: graphql`
    fragment V4ConstitutionSection_organization on Organization {
      id
      name
      v5migrationStatus
      setupModeEnabled
      
      viewer {
        routes {
          constitutionUrl
        }
      }
      
      ...AdvancedSettingsV4_organization
    }
  `,
}): RelayFragmentContainer<typeof(V4ConstitutionSection)>)
