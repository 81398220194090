// @flow
import type {Node} from 'react'
import React, {Fragment, useCallback} from 'react'
import {FormikProvider, Form, useFormik, Field} from 'formik'
import {graphql, useFragment} from 'react-relay'

import Input from 'components/ui/forms/Input'
import Textarea from 'components/ui/forms/Textarea'
import {Body} from 'components/ui/Accordion'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import SectionHeader from '../../../RulesSections/Section/SectionHeader'
import styles from './index.scss'

import type {
  AmendmentForm_organization$key as OrganizationKey,
} from './__generated__/AmendmentForm_organization.graphql'

type Values = {
  title: string,
  body: string,
}

type Props<SubmitResponse> = $ReadOnly<{
  organization: OrganizationKey,
  initialValues: Values,
  disabled: boolean,
  submit: Values => Promise<SubmitResponse>,
  remove?: ?() => void,
  close: () => void,
}>

const organizationFragment = graphql`
  fragment AmendmentForm_organization on Organization {
    ...WYSIWYGTextareaWithFeatureFlag_organization
    ...SectionHeader_organization
  }
`

function AmendmentForm<SubmitResponse>({
  organization: organizationKey,
  initialValues,
  disabled,
  submit,
  remove,
  close,
}: Props<SubmitResponse>): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const option = {
    label: I18n.t('admin.organizations.org_rules.amendments.description'),
    value: 'CUSTOM',
    description: '',
  }

  const validate = (values) => {
    const errors = {}

    if (!values.title)
      errors.title = I18n.t('errors.messages.required')

    if (!values.body)
      errors.body = I18n.t('errors.messages.required')

    return errors
  }

  const onSubmit = useCallback(async (values) => {
    const response = await submit(values)
    close()

    return response
  }, [submit, close])

  const form = useFormik<Values>({
    initialValues,
    validate,
    onSubmit,
  })

  const formTitle = initialValues.title === ''
    ? I18n.t('admin.organizations.org_rules.amendments.default_title')
    : form.values.title

  const deleteButtonTitle = remove
    ? I18n.t('buttons.remove')
    : I18n.t('buttons.cancel')

  return (
    <Fragment>
      <SectionHeader
        organization={organization}
        title={formTitle}
        ruleOption={option}
        description={form.values.body}
        disabled={disabled}
      />
      <FormikProvider value={form}>
        <Form>
          <Body>
            <Field
              as={Input}
              name="title"
              label={I18n.t('shared.title')}
              warning={form.errors.title}
              className="form-control"
            />
            <Field
              as={WYSIWYGTextareaWithFeatureFlag}
              fallbackComponent={Textarea}
              organization={organization}
              name="body"
              label={I18n.t('shared.body')}
              warning={form.errors.body}
              className="form-control"
            />
            <div className={styles.controlButtons}>
              <button
                className="btn btn-secondary btn-lg"
                disabled={form.isSubmitting || disabled || !remove}
                onClick={remove}
                type="button"
              >
                {deleteButtonTitle}
              </button>
              <button
                className="btn btn-primary btn-lg"
                type="submit"
                disabled={form.isSubmitting || !form.isValid || disabled}
              >
                {I18n.t('buttons.save')}
              </button>
            </div>
          </Body>
        </Form>
      </FormikProvider>
    </Fragment>
  )
}

AmendmentForm.defaultProps = {
  remove: null,
}

export default AmendmentForm
