// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import HomeLink from './HomeLink'
import OpenCloseButton from './OpenCloseButton'
import styles from './index.scss'

type Props = $ReadOnly<{
  homePath: string,
  isOpen: boolean,
  toggleSidebar: () => void,
  renderOrgSelect: () => Node,
  renderUncollapsed: () => ?Node,
  renderSettingsSection: () => Node,
}>

function Container({
  homePath,
  isOpen,
  toggleSidebar,
  renderUncollapsed,
  renderSettingsSection,
  renderOrgSelect,
}: Props): Element<"nav"> {
  const collapsibleBlockClass = classNames(styles.collapsibleBlock, {
    [styles.collapsibleBlockClosed]: !isOpen,
  })

  const collapsibleBlockContentClass = classNames(styles.collapsibleBlockContent, {
    [styles.collapsibleBlockContentClosed]: !isOpen,
  })

  return (
    <nav aria-label={I18n.t('org_nav.tabs.nav_description')} className={styles.sideBar}>
      <div className={styles.staticBlock}>
        <div>
          <HomeLink path={homePath} />
          <OpenCloseButton
            toggleSidebar={toggleSidebar}
            isSideBarOpen={isOpen}
          />
          {renderOrgSelect()}
        </div>
        <div>
          {renderSettingsSection()}
        </div>
      </div>
      <div className={collapsibleBlockClass} test-id="side-bar-collapsible-block">
        <div className={collapsibleBlockContentClass}>
          {renderUncollapsed()}
        </div>
      </div>
    </nav>
  )
}

export default Container
