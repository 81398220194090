// @flow
import type {Node} from 'react'
import React from 'react'
import {ModalBody} from 'reactstrap'

type Props = $ReadOnly<{
  children: Node,
}>

function Body({children}: Props): Node {
  return (
    <ModalBody className="edit-dialog-modal-body">
      {children}
    </ModalBody>
  )
}

export default Body
