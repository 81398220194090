// @flow
import React from 'react'
import type {Node} from 'react'

type Props = {
  children: Node,
  disabled: boolean,
  onClick: () => void,
}

function SaveButton({children, disabled, onClick}: Props): Node {
  return (
    <button
      className="btn btn-primary btn-lg"
      type="submit"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

SaveButton.defaultProps = {
  disabled: false,
}

export default SaveButton
