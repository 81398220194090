// @flow
import React, {type Node, Fragment} from 'react'
import classNames from 'classnames'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import styles from './index.scss'
import type {Output} from './types'

type Props = {|
  output: Output,
|}

function OutputOwner({output}: Props): Node {
  const ownerClassName = classNames({
    [styles.individualAction]: output.owner?.isIndividualAction,
  })

  return output.type !== 'TENSION' && (
    <Fragment>
      <div className="label">
        {I18n.t('shared.role')}
      </div>
      <div className={ownerClassName}>
        {output.owner?.localizedName}
      </div>
    </Fragment>
  )
}

export default (createFragmentContainer(OutputOwner, {
  output: graphql`
    fragment OutputOwner_output on TacticalOutput {
      type

      owner {
        localizedName
        isIndividualAction
      }
    }
  `,
}): RelayFragmentContainer<typeof(OutputOwner)>)
