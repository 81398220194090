// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import ProfileMetricsView from './ProfileMetricsView'

import type {
  ProfileMetricsQuery as Query,
} from './__generated__/ProfileMetricsQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

const profileMetricsQuery = graphql`
query ProfileMetricsQuery(
  $orgDatabaseId: String!,
  $personDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      ...ProfileMetricsView_organization
      person(databaseId: $personDatabaseId) {
        ...ProfileMetricsView_person
      }
    }
  }
`

function ProfileMetrics({orgDatabaseId, personDatabaseId} : Props): null | Node {
  const queryVariables = {orgDatabaseId, personDatabaseId}
  const [{organization}] = useRefetchableLazyLoadQuery<Query>(profileMetricsQuery, queryVariables)
  const person = organization?.person

  if (!organization || !person)
    return null

  return (
    <ProfileMetricsView
      organization={organization}
      person={person}
    />
  )
}

export default ProfileMetrics
