// @flow strict
type Args = $ReadOnlyArray<string | number | Error>

export function warn(...args: Args) {
  // eslint-disable-next-line no-console
  console.warn(...['gf: '].concat(args))
}

export function info(...args: Args) {
  // eslint-disable-next-line no-console
  console.log(...['gf: '].concat(args))
}

export function warnErr(...args: Args) {
  // eslint-disable-next-line no-console
  console.error(...['gf: '].concat(args))
}

export function debug(...args: Args) {
  if (process.env.NODE_ENV === 'development')
    info(...args)
}
