// @flow
import type {Element} from 'react'
import React, {Suspense, useCallback, useMemo} from 'react'
import {useFragment, graphql} from 'react-relay'
import {useContainerQuery} from 'react-container-query'

import useSubscriptionRefetch from 'utils/hooks/useSubscriptionRefetch'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import ProjectsGroupsOrGrid from 'components/projects/ProjectsGroupsOrGrid'
import CreateProjectModal from 'components/projects/CreateProjectModal'
import UpdateProjectModal from 'components/projects/UpdateProjectModal'
import useModalsState from 'components/projects/useModalsState'
import ModalLoader from 'components/ui/ModalLoader'
import AddProjectMutation from 'mutations/AddProjectMutation'
import UpdateProjectMutation from 'mutations/UpdateProjectMutation'
import DeleteProjectMutation from 'mutations/DeleteProjectMutation'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import usePersistentState from 'utils/hooks/usePersistentState'
import containerQueryConfig from 'components/projects/containerQueryConfig'
import OrganizationSubscription from 'subscriptions/OrganizationSubscription'
import type {SortBy} from 'components/pages/Projects/types'
import {type GroupBy} from 'components/projects/GroupProjectsBy'

import type {
  ProfileProjectsView_organization$key as Organization,
} from './__generated__/ProfileProjectsView_organization.graphql'
import type {
  ProfileProjectsView_person$key as Person,
} from './__generated__/ProfileProjectsView_person.graphql'

import ProjectsHeader from '../ProjectsHeader'

type Props = $ReadOnly<{
  organization: Organization,
  person: Person,
  refetch: () => Promise<null>,
}>

const orgFragment = graphql`
  fragment ProfileProjectsView_organization on Organization {
    databaseId
    projectsGridEnabled

    ...CreateProjectModal_organization
  }
`

const personFragment = graphql`
  fragment ProfileProjectsView_person on Person {
    id
    databaseId
    viewerCanUpdate

    projects(first: 250, includeUnfilledAsCircleLead: true) {
      edges {
        node {
          id

          ...UpdateProjectModal_project
          ...ProjectsGroupsOrGrid_projects
        }
      }
    }
  }
`

function ProfileProjectsView({organization: orgKey, person: personKey, refetch} : Props): Element<"div"> {
  const organization = useFragment(orgFragment, orgKey)
  const person = useFragment(personFragment, personKey)

  const [groupBy, setGroupBy] = usePersistentState<GroupBy>(
    'all',
    `${person.databaseId}-profile-projects-group-by`,
  )

  const [showAs, setShowAs] = usePersistentState<'list' | 'grid'>(
    'list',
    `${person.databaseId}-profile-projects-show-as`,
  )

  const [sortBy, setSortBy] = usePersistentState<SortBy>(
    'created_at',
    `${person.databaseId}-profile-projects-sort-by`,
  )

  const [containerQuery, containerQueryRef] = useContainerQuery(containerQueryConfig)

  const showAsWithGuard = containerQuery.canShowGridView
    ? showAs
    : 'list'

  const sortByWithGuard = useMemo(() => {
    if (showAsWithGuard === 'grid' && sortBy === 'status') {
      setSortBy('created_at')
      return 'created_at'
    }

    return sortBy
  }, [sortBy, setSortBy, showAsWithGuard])

  useSubscriptionRefetch(
    OrganizationSubscription,
    {orgDatabaseId: organization.databaseId},
    refetch,
    {},
    (data) => (
      data.organizationEvent?.type === 'OrganizationProjectChange'
    ),
  )

  const [modalsState, {openEditor, openCreator, closeModal}] = useModalsState()
  const projects = liftNodes(person.projects)
  const editingProject = projects.find((project) => (
    modalsState.type === 'EDITING_PROJECT' && project.id === modalsState.projectId
  ))

  const addMutation = useCallback(async (values) => {
    const response = await AddProjectMutation(values)
    return response.addProject
  }, [])

  const updateMutation = useCallback(async (values) => {
    const response = await UpdateProjectMutation(values)
    return response.updateProject
  }, [])

  return (
    <div ref={containerQueryRef}>
      <ProjectsHeader
        sortBy={sortByWithGuard}
        setSortBy={setSortBy}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        showAs={showAsWithGuard}
        setShowAs={setShowAs}
        projectsGridEnabled={organization.projectsGridEnabled}
        canShowGridView={containerQuery.canShowGridView}
        openCreator={openCreator}
        viewerCanAddProject={person.viewerCanUpdate}
      />
      <ProjectsGroupsOrGrid
        openEditor={openEditor}
        projects={projects}
        roleNameMode="WITH_LINKED_FROM"
        sortBy={sortBy}
        groupBy={groupBy}
        showAs={showAsWithGuard}
        circle={null}
        hideAvatars
        hideSource
        showCircleName
        hidePersonName
        showPersonalProjects
      />
      {modalsState.type === 'CREATE_PROJECT' && (
        <CreateProjectModal
          organization={organization}
          mode="CircleAndRole"
          addMutation={addMutation}
          close={closeModal}
          initialValues={{personId: person.id}}
        />
      )}
      {modalsState.type === 'EDITING_PROJECT' && editingProject && (
        <Suspense fallback={<ModalLoader />}>
          <RootErrorBoundary>
            <UpdateProjectModal
              mode="CircleAndRole"
              initialValues={{personId: person.id}}
              updateMutation={updateMutation}
              deleteMutation={DeleteProjectMutation}
              project={editingProject}
              close={closeModal}
            />
          </RootErrorBoundary>
        </Suspense>
      )}
    </div>
  )
}

export default ProfileProjectsView
