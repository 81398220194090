// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  ReorderMetricInput as Input,
  ReorderMetricMutationResponse as Response,
} from './__generated__/ReorderMetricMutation.graphql'
import type {AbstractMetric} from '../components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection/types'

const mutation = graphql`
  mutation ReorderMetricMutation(
    $input: ReorderMetricInput!
  ) {
    reorderMetric(input: $input) {
      reorderedList {
        __typename
        id
        position
      }
    }
  }
`

export default <T: AbstractMetric>(input: MutationInput<Input>, optimisticList: Array<T>): Promise<Response> => {
  const variables = {input}

  const optimisticResponse = {
    reorderMetric: {
      reorderedList: optimisticList.map((item) => ({
        __typename: 'Metric',
        id: item.id,
        position: item.position,
      })),
    },
  }

  return createMutationPromise({
    mutation,
    variables,
    optimisticResponse,
  })
}
