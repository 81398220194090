// @flow
import React, {type Node, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {UncontrolledTooltip} from 'reactstrap'

import useUniqueId from 'utils/hooks/useUniqueId'
import Age from 'components/ui/Age'
import TruncateString from 'components/ui/text/TruncateString'
import ProjectRoi from 'components/projects/ProjectRoi'
import PersonAvatar from 'components/people/PersonAvatar'
import ProjectLinkIcon from 'components/projects/ProjectLinkIcon'
import ProjectPrivateIcon from 'components/projects/ProjectPrivateIcon'
import SourceIcon from 'components/ui/SourceIcon'
import ProjectDescription from 'components/projects/ProjectDescription'
import ParentGoalOrTarget from 'components/projects/ParentGoalOrTarget'
import Card from './Card'
import styles from './index.scss'

import type {Project, Circle} from './types'

type Props = $ReadOnly<{
  project: Project,
  circle: null | Circle,
  hideAvatars: ?boolean,
  hideSource: ?boolean,
}>

function ProjectCard({project, circle, hideAvatars, hideSource}: Props): Node {
  const [personTooltipId] = useUniqueId()

  return (
    <Card
      status={project.status}
      description={(
        <ProjectDescription project={project} />
      )}
      link={(
        <ProjectLinkIcon project={project} />
      )}
      role={(
        <TruncateString clamp={1}>
          {project.role.localizedName}
        </TruncateString>
      )}
      age={(
        <Age createdAt={project.createdAt} />
      )}
      roi={project.roi && project.organization.isROIEnabled && (
        <ProjectRoi roi={project.roi} />
      )}
      privateToCircle={project.privateToCircle && (
        <ProjectPrivateIcon className={styles.projectIcon} />
      )}
      source={!hideSource && (
        <SourceIcon
          source={(!!circle && circle.id === project.circle.id) ? 'internal' : 'external'}
          className={styles.projectIcon}
        />
      )}
      avatar={
        project.person && !hideAvatars && (
          <Fragment>
            <div id={personTooltipId}>
              <PersonAvatar size="smaller" person={project.person} />
            </div>
            <UncontrolledTooltip
              target={personTooltipId}
              placement="top"
            >
              {project.person.name}
            </UncontrolledTooltip>
          </Fragment>
        )
      }
      test-id={`project-card-${project.databaseId}`}
      parentGoalOrTarget={(
        <ParentGoalOrTarget project={project} short />
  )}
    />
  )
}

ProjectCard.defaultProps = {
  hideAvatars: false,
  hideSource: false,
}

export default (createFragmentContainer(ProjectCard, {
  project: graphql`
    fragment ProjectCard_project on Project {
      databaseId
      status
      createdAt
      description(format: MARKDOWN)
      privateToCircle
      viewerCanUpdate
      roi
      parentGoalOrTarget
      parentGoalOrTargetDescription

      role {
        localizedName
      }

      organization {
        isROIEnabled
      }

      person {
        name
        ...PersonAvatar_person
      }

      circle {
        id
        localizedName
      }

      ...ProjectLinkIcon_project
      ...ProjectDescription_project
    }
  `,
  circle: graphql`
    fragment ProjectCard_circle on Circle {
      id
    }
  `,
}): RelayFragmentContainer<typeof(ProjectCard)>)
