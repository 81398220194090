// @flow
import type {Element} from 'react'
import React from 'react'
import Search from 'components/ui/icons/Search'

type Props = {
  isSubmittingAllowed: boolean,
}

function SubmitButton({isSubmittingAllowed}: Props): Element<"button"> {
  return (
    <button
      className="app-search__button"
      type={isSubmittingAllowed ? 'submit' : 'button'}
      title={I18n.t('nav.search')}
    >
      <Search />
    </button>
  )
}

export default SubmitButton
