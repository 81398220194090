/**
 * @flow
 * @relayHash 019a6c37d5a612d218981cbb7548b852
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteProjectInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  projectId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type DeleteProjectMutationVariables = {|
  input: DeleteProjectInput
|};
export type DeleteProjectMutationResponse = {|
  +deleteProject: ?{|
    +deletedProjectId: string
  |}
|};
export type DeleteProjectMutation = {|
  variables: DeleteProjectMutationVariables,
  response: DeleteProjectMutationResponse,
|};
*/


/*
mutation DeleteProjectMutation(
  $input: DeleteProjectInput!
) {
  deleteProject(input: $input) {
    deletedProjectId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteProjectPayload",
    "kind": "LinkedField",
    "name": "deleteProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedProjectId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "019a6c37d5a612d218981cbb7548b852",
    "metadata": {},
    "name": "DeleteProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c756f98a74a87689ac702bf61c5d65af';

module.exports = node;
