// @flow
import React, {type Node, useContext, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'

import UpdateAmendmentMutation from 'mutations/UpdateAmendmentMutation'
import DeleteAmendmentMutation from 'mutations/DeleteAmendmentMutation'
import {CardContext} from 'components/ui/Accordion'
import AmendmentForm from '../AmendmentForm'
import type {
  EditAmendmentBody_amendment as Amendment,
} from './__generated__/EditAmendmentBody_amendment.graphql'

type Props = $ReadOnly<{
  amendment: Amendment,
  disabled: boolean,
}>

function EditAmendmentBody({amendment, disabled}: Props): Node {
  const {toggleOpen: toggleCard} = useContext(CardContext)

  const initialValues = {
    title: amendment.title,
    body: amendment.body,
  }

  const submit = useCallback(async (values) => UpdateAmendmentMutation({
    amendmentId: amendment.id,
    ...values,
  }), [amendment])

  const remove = useCallback(() => {
    DeleteAmendmentMutation({amendmentId: amendment.id}).then(toggleCard)
  }, [amendment, toggleCard])

  return (
    <AmendmentForm
      organization={amendment.organization}
      initialValues={initialValues}
      disabled={disabled}
      submit={submit}
      remove={remove}
      close={toggleCard}
    />
  )
}

export default (createFragmentContainer(EditAmendmentBody, {
  amendment: graphql`
    fragment EditAmendmentBody_amendment on Amendment {
      id
      title
      body

      organization {
        ...AmendmentForm_organization
      }
    }
  `,
}): RelayFragmentContainer<typeof(EditAmendmentBody)>)
