// @flow
import type {Element} from 'react'
import React from 'react'
import {UncontrolledTooltip} from 'reactstrap'
import {graphql, useFragment, RelayEnvironmentProvider} from 'react-relay'

import NoteIcon from 'components/ui/icons/md/Note'
import environment from 'environment'
import useUniqueId from 'utils/hooks/useUniqueId'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import style from './index.scss'

import type {
  NoteTooltip_organization$key as OrganizationKey,
} from './__generated__/NoteTooltip_organization.graphql'

type Props = $ReadOnly<{
  note: string,
  organization: OrganizationKey,
}>

const organizationFragment = graphql`
  fragment NoteTooltip_organization on Organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
  }
`

function NoteTooltip({
  note,
  organization: organizationKey,
}: Props): Element<"span"> {
  const [id] = useUniqueId()

  const organization = useFragment(organizationFragment, organizationKey)

  return (
    <span className={style.wrapper} test-id="note-tooltip">
      <span
        id={id}
        className={style.iconContainer}
      >
        <NoteIcon />
      </span>
      <UncontrolledTooltip
        autohide={false}
        placement="right"
        target={id}
        className={style.tooltip}
      >
        <RelayEnvironmentProvider environment={environment}>
          <WYSIWYGViewerWithFeatureFlag
            value={note}
            organization={organization}
            fallbackProps={{children: note}}
            fallbackComponent="div"
            dark
          />
        </RelayEnvironmentProvider>
      </UncontrolledTooltip>
    </span>
  )
}

export default NoteTooltip
