import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import type {
  PersonChecklistItem_item$key as ChecklistItemKey,
} from './__generated__/PersonChecklistItem_item.graphql'

import PersonItemWrapper from './PersonItemWrapper'

type Props = $ReadOnly<{
  item: ChecklistItemKey,
}>

const itemFragment = graphql`
  fragment PersonChecklistItem_item on ChecklistItem {
    id
    description
    link
    localizedRoleName
    isGlobal
    privateToCircle

    viewerCanSeeEditIcon

    circle {
      id
      databaseId
      localizedName

      supportedRole {
        isDisplayedAsCircle
      }

      organization {
        databaseId
      }
    }
  }
`

function PersonChecklistItem({item: itemKey}: Props): Node {
  const item = useFragment(itemFragment, itemKey)
  const circle = item.circle

  return (
    <PersonItemWrapper
      key={`${circle.id}-${item.id}`}
      item={item}
      circle={circle}
    />
  )
}

export default PersonChecklistItem
