/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RuleValue = "CENTRALIZED" | "CUSTOM" | "EXISTING" | "HOLACRACY" | "MODULAR_ADOPTION_BY_CIRCLE" | "UNIVERSAL";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Section_rule$ref: FragmentReference;
declare export opaque type Section_rule$fragmentType: Section_rule$ref;
export type Section_rule = {|
  +id: string,
  +title: string,
  +frogTip: string,
  +ruleKey: string,
  +ruleValue: RuleValue,
  +centralizedText: ?string,
  +universalText: ?string,
  +viewerCanUpdate: boolean,
  +$refType: Section_rule$ref,
|};
export type Section_rule$data = Section_rule;
export type Section_rule$key = {
  +$data?: Section_rule$data,
  +$fragmentRefs: Section_rule$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Section_rule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frogTip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ruleKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ruleValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "centralizedText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "universalText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    }
  ],
  "type": "Rule",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2a818f1229c24f00a41f7140c51b20e8';

module.exports = node;
