// @flow
import type {AbstractComponent} from 'react'
import React, {forwardRef, useImperativeHandle} from 'react'
import {Form, FormikProvider, useFormik} from 'formik'
import {createFragmentContainer, graphql} from 'react-relay'
import type {$RelayProps} from 'react-relay'

import UpdateTensionInlineBoxContainer from 'components/tensions/UpdateTensionInlineBoxContainer'
import UpdateTensionMutation from 'mutations/UpdateTensionMutation'
import type {Values} from 'components/tensions/TensionEditForm'
import TensionEditForm from 'components/tensions/TensionEditForm'
import updateRailsViewTensionRow from 'utils/MiscUtils/updateRailsViewTensionRow'

import type {
  UpdateTensionInlineBox_tension as Tension,
} from './__generated__/UpdateTensionInlineBox_tension.graphql'
import type {
  UpdateTensionInlineBox_viewer as Viewer,
} from './__generated__/UpdateTensionInlineBox_viewer.graphql'

type Props = $ReadOnly<{
  tension: Tension,
  viewer: Viewer,
  onSuccess: () => void,
  onCancel: () => void,
}>

const UpdateTensionInlineBox = forwardRef(({tension, viewer, onSuccess, onCancel}: Props, ref) => {
  const initialValues: Values = {
    body: tension.body,
    impactedRoleId: tension.impactedRole?.id || null,
  }

  const submit = async (values) => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      const response = await UpdateTensionMutation(
        tension.id,
        null,
        values,
      )

      // Temporary workaround until this form can be converted to Turbo/Hotwire
      if (response.updateTension)
        await updateRailsViewTensionRow(tension.organization.databaseId, viewer.databaseId, tension.databaseId)
    }

    onSuccess()
  }

  const cancel = () => { onCancel() }

  const validate = (values: Values) => {
    const errors = {}

    if (!values.body)
      errors.body = I18n.t('errors.messages.required')

    return errors
  }

  // $FlowFixMe[prop-missing]
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: submit,
    onCancel: cancel,
  })

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await formik.submitForm()
      return formik.values
    },
  }))

  return (
    <FormikProvider value={formik}>
      <Form>
        <UpdateTensionInlineBoxContainer disableSubmit={!formik.isValid || formik.isSubmitting}>
          <TensionEditForm
            bodyRows={2}
            viewer={viewer}
            organization={tension.organization}
            formikProps={formik}
          />
        </UpdateTensionInlineBoxContainer>
      </Form>
    </FormikProvider>
  )
})

export default (createFragmentContainer(UpdateTensionInlineBox, {
  tension: graphql`
    fragment UpdateTensionInlineBox_tension on Tension {
      id
      databaseId
      body

      organization {
        databaseId
        ...TensionEditForm_organization
      }

      impactedRole {
        id
      }
    }
  `,
  viewer: graphql`
    fragment UpdateTensionInlineBox_viewer on Person {
      databaseId
      ...TensionEditForm_viewer
    }
  `,
}): AbstractComponent<
  $RelayProps<
    React$ElementConfig<
      React$AbstractComponent<Props, { submit: () => Promise<$FlowFixMe> }>,
    >,
  >,
  { submit: () => Promise<$FlowFixMe> },
>)
