// @flow

// This component is used to display a list of supported projects in the Goals page.
// It runs a top level graphql query to fetch the data then calls the ProjectsGroupsOrGrid component.

import React, {Suspense, useCallback} from 'react'
import {graphql, QueryRenderer} from 'react-relay'
import type {GraphQLTaggedNode} from 'react-relay'
import environment from 'environment'
import type {Node} from 'react'
import ProjectsGroupsOrGrid from 'components/projects/ProjectsGroupsOrGrid'
import useModalsState from 'components/projects/useModalsState'
import UpdateProjectMutation from 'mutations/UpdateProjectMutation'
import RootErrorBoundary from 'components/OrgNav/OrgNavApp/RootErrorBoundary'
import UpdateProjectModal from 'components/projects/UpdateProjectModal'
import ModalLoader from 'components/ui/ModalLoader'
import DeleteProjectMutation from 'mutations/DeleteProjectMutation'

export const supportingProjectsListQuery: GraphQLTaggedNode = graphql`
  query SupportingProjectsListQuery($goalId: ID!, $circleId: ID!) {
    goal: node(id: $goalId) {
      ... on Goal {
        id
        supportingProjects {
          nodes {
            id
            ...ProjectsGroupsOrGrid_projects
            ...UpdateProjectModal_project
          }
        }
        targetSupportingProjects {
          nodes {
            id
            ...ProjectsGroupsOrGrid_projects
            ...UpdateProjectModal_project
          }
        }
      }
    }
    circle: node(id: $circleId) {
      ... on Circle {
        id
        ...ProjectsGroupsOrGrid_circle
      }
    }
  }
`

type Props = {
  goalId: string,
  circleId: string,
}

function SupportingProjectsList({goalId, circleId}: Props): Node {
  const variables = {
    goalId,
    circleId,
  }

  const updateMutation = useCallback(async (values) => {
    const response = await UpdateProjectMutation(values)
    return response.updateProject
  }, [])

  const [modalsState, {openEditor, closeModal}] = useModalsState()

  return (
    <QueryRenderer
      environment={environment}
      variables={variables}
      query={supportingProjectsListQuery}
      render={({error, props: renderProps}) => {
        if (error)
          return <div>{error.message}</div>

        if (!renderProps || !renderProps.goal || !renderProps.circle)
          return <div>{'Loading...'}</div>

        const {goal, circle} = renderProps
        const allProjects = goal.supportingProjects.nodes.concat(
          goal.targetSupportingProjects.nodes,
        )
        const editingProject = allProjects.find((project) => (
          modalsState.type === 'EDITING_PROJECT' && project.id
                === modalsState.projectId
        ))

        return (
          <React.Fragment>
            {goal.supportingProjects.nodes.length > 0 && (
            <React.Fragment>
              <div className="label mt-5">{I18n.t('goals.goal_supporting_projects')}</div>
              <ProjectsGroupsOrGrid
                openEditor={openEditor}
                roleNameMode="WITH_LINKED_FROM"
                      // sortBy={sortBy}
                groupBy="all"
                showAs="list"
                circle={circle}
                projects={goal.supportingProjects.nodes}
              />
            </React.Fragment>
            )}
            {goal.targetSupportingProjects.nodes.length > 0 && (
            <React.Fragment>
              <div className="label mt-5">{I18n.t('goals.target_supporting_projects')}</div>
              <ProjectsGroupsOrGrid
                openEditor={openEditor}
                roleNameMode="WITH_LINKED_FROM"
                      // sortBy={sortBy}
                groupBy="all"
                showAs="list"
                circle={circle}
                projects={goal.targetSupportingProjects.nodes}
              />
            </React.Fragment>
            )}

            {editingProject && (
              <Suspense fallback={<ModalLoader />}>
                <RootErrorBoundary>
                  <UpdateProjectModal
                    mode="RoleAndPerson"
                    updateMutation={updateMutation}
                    deleteMutation={DeleteProjectMutation}
                    project={editingProject}
                    close={closeModal}
                  />
                </RootErrorBoundary>
              </Suspense>
            )}
          </React.Fragment>
        )
      }}
    />
  )
}

export default SupportingProjectsList
