// @flow
import type {RecordSourceSelectorProxy} from 'relay-runtime'
import {ConnectionHandler} from 'relay-runtime'
import {warnErr} from 'utils/logger'

type RangeAddUpdaterConfig = $ReadOnly<{
  connectionInfo: $ReadOnly<{
    key: string,
    filters?: any,
  }>,
  parentId: string,
  edgeName: string,
  rootNode: string,
  addLocation?: 'before' | 'after',
}>

const rangeAddUpdater = ({
  connectionInfo,
  parentId,
  edgeName,
  rootNode,
  addLocation,
}: RangeAddUpdaterConfig): ((store: RecordSourceSelectorProxy) => void) => (
  store: RecordSourceSelectorProxy,
) => {
  const connectionName = connectionInfo.key
  const parentNode = store.get(parentId)

  if (!parentNode) {
    warnErr(`Invalid RangeAddUpdater config. Can't find parent node "${parentId}"`)
    return
  }

  const connection = ConnectionHandler.getConnection(
    parentNode,
    connectionName,
    connectionInfo.filters,
  )

  const payload = store.getRootField(rootNode)

  if (!payload) {
    warnErr(`Invalid RangeAddUpdater config. Can't get payload for root node "${rootNode}"`)
    return
  }

  const newEdge = payload.getLinkedRecord(edgeName)

  if (!newEdge) {
    warnErr("Invalid RangeAddUpdater config. Can't get edge from root node")
    return
  }

  const newNode = newEdge.getLinkedRecord('node')

  if (!newNode) {
    warnErr("Can't perform RangeAddUpdater. Range add mutation should have edge { node { ... } } structure")
    return
  }

  const newNodeId = newNode.getDataID()

  if (!connection) {
    warnErr(`Invalid RangeAddUpdater config. Can't find connection "${connectionName}" for node ${parentId}`)
    return
  }

  if (!newEdge)
    return

  const connectionEdges = connection.getLinkedRecords('edges')

  if (!connectionEdges) {
    warnErr(`Can't perform RangeAddUpdater. Connection "${connectionName}" for node ${parentId} doesn't have edges.`)
    return
  }

  const connectionNodes = connectionEdges.filter(Boolean).map((edge) => edge.getLinkedRecord('node'))

  const newNodeFromConnection = connectionNodes.find((node) => node && node.getDataID() === newNodeId)
  if (newNodeFromConnection)
    return

  if (addLocation === 'after')
    ConnectionHandler.insertEdgeAfter(connection, newEdge)
  else
    ConnectionHandler.insertEdgeBefore(connection, newEdge)
}

export default rangeAddUpdater
