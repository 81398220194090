// @flow
import type {Node} from 'react'
import React from 'react'
import AppLink from 'components/ui/AppLink'
import AppRouter from 'utils/AppRouter'
import OrgnavRouter from 'utils/OrgnavRouter'

type Props = {
  roleDatabaseId: string,
  tabName: string,
  children: Node,
  ...
}

const scrollToPageTop = () => {
  const topElement = document.querySelector('.app-container') || document.getElementById('pageContent')

  if (topElement)
    topElement.scrollIntoView()
}

const onClick = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation()
  if (!(e.altKey || e.metaKey || e.ctrlKey))
    scrollToPageTop()
}

const href = (roleDatabaseId: string, tabName: string) => {
  if (AppRouter.instance.isReady && AppRouter.instance.baseUrl)
    return `${AppRouter.instance.baseUrl}/roles/${roleDatabaseId}/${tabName}`

  return `/roles/${roleDatabaseId}`
}

function RoleTabLink({roleDatabaseId, tabName, ...props}: Props): Node {
  const tabNameWithDefault = tabName || OrgnavRouter.instance.tabName || 'overview'
  return (
    <AppLink
      {...props}
      href={href(roleDatabaseId, tabNameWithDefault)}
      onClick={onClick}
    />
  )
}

RoleTabLink.defaultProps = {
  tabName: '',
}

export default RoleTabLink
