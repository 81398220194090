// @flow
import React, {type Element} from 'react'
import {graphql, useFragment} from 'react-relay'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import NoItemsMessage from 'components/ui/NoItemsMessage'
import AgendaItemGroup from './AgendaItemGroup'
import Output from './Output'

import type {
  AgendaItemGroups_meeting$key as MeetingKey,
} from './__generated__/AgendaItemGroups_meeting.graphql'

type Props = $ReadOnly<{
  meetingKey: MeetingKey,
}>

const meetingOutputsFragment = graphql`
  fragment AgendaItemGroups_meeting on TacticalMeeting 
    @argumentDefinitions(
    count: { type: "Int", defaultValue: 100 },
    cursor: { type: "String" }
  ){
    databaseId

    agendaItems(first: $count, after: $cursor) {
      edges {
        node {
          databaseId
          hasTacticalOutputs
          
          ...AgendaItemGroup_agendaItem
        }
      }
    }
    
    tacticalOutputs(first: $count, after: $cursor) @connection(key: "AgendaItemGroups_tacticalOutputs"){
      edges {
        node {
          id
          databaseId
          
          agendaItem {
            databaseId
          }
          
          ...Output_output
        }
      }
    }

    ...AgendaItemGroup_meeting
    ...Output_meeting
  }
`

function AgendaItemGroups({meetingKey}: Props): Element<"div"> {
  const meeting = useFragment(meetingOutputsFragment, meetingKey)

  const agendaItems = liftNodes(meeting.agendaItems).filter((a) => a.hasTacticalOutputs).reverse()

  const meetingOutputs = liftNodes(meeting.tacticalOutputs)

  if (meetingOutputs.length === 0)
    return <div><NoItemsMessage message={I18n.t('tactical_meetings.current.no_outputs_yet')} /></div>

  const tacticalOutputs = (agendaItemID) => {
    const outputs = liftNodes(meeting.tacticalOutputs)

    if (agendaItemID === null)
      return outputs.filter((o) => o.agendaItem === null)

    return meetingOutputs.filter((o) => o.agendaItem?.databaseId === agendaItemID)
  }

  const outputList = (agendaItemID) => {
    const outputs = tacticalOutputs(agendaItemID)

    if (outputs.length === 0)
      return null

    return outputs.map((output) => (
      <Output key={output.id} outputKey={output} meetingKey={meeting} />
    ))
  }

  const outputListForNoAgendaItem = outputList(null)

  return (
    <div>
      { agendaItems.map((agendaItem) => {
        const key = agendaItem.databaseId

        return (
          <div key={key}>
            <AgendaItemGroup
              agendaItemKey={agendaItem}
              meetingKey={meeting}
              outputList={outputList(agendaItem.databaseId)}
              testId={`tactical-agenda-item-group-item-${key}`}
            />
          </div>
        )
      }) }
      {outputListForNoAgendaItem && (
        <div key="empty-agenda-item">
          <AgendaItemGroup
            agendaItemKey={null}
            meetingKey={meeting}
            outputList={outputListForNoAgendaItem}
            testId="tactical-agenda-item-group-item-null"
          />
        </div>
      )}
    </div>
  )
}

export default AgendaItemGroups
