import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import util from 'utils/MiscUtils'
import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import HotwireLink from 'components/ui/HotwireLink'

const UP_CLASS = 'fa fa-thumbs-up'
const DOWN_CLASS = 'fa fa-thumbs-down'
const FEEDBACK_CLASS = 'feedback'

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */

const VotingComponent = createReactClass({
  displayName: 'VotingComponent',

  propTypes: {
    organization_id: PropTypes.number,
    vote_id: PropTypes.number,
    votable_type: PropTypes.string.isRequired,
    votable_id: PropTypes.number.isRequired,
  },

  getInitialState() {
    return {
      liked: false,
      disliked: false,
      vote_id: this.props.vote_id ? this.props.vote_id : null,
    }
  },

  componentDidMount() {
    if (this.state.vote_id)
      this.getData()
  },

  getData() {
    if (this.state.vote_id)
      this.apiCall('GET', `/api/edge/${this.props.votable_type}/${this.props.votable_id}/votes/${this.state.vote_id}`)
  },

  apiCall(method, url, data) {
    $.ajax({
      type: method,
      url,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
    })
      .done(this.apiCallSuccess)
      .fail(this.apiCallFailure)
  },

  upClass() {
    return this.state.liked ? `${UP_CLASS} selected` : UP_CLASS
  },

  downClass() {
    return this.state.disliked ? `${DOWN_CLASS} selected` : DOWN_CLASS
  },

  feedbackClass() {
    return this.state.disliked ? FEEDBACK_CLASS : `${FEEDBACK_CLASS} hidden`
  },

  supportURL() {
    return `/organizations/${this.props.organization_id}/dialogs/feedback?feature=habits&meta=lesson-${this.props.votable_id}`
  },

  postData(voteFlag) {
    const method = this.state.vote_id ? 'PATCH' : 'POST'
    this.apiCall(
      method,
      `/api/edge/${this.props.votable_type}/${this.props.votable_id}/votes`,
      {
        data: {
          type: 'votes',
          id: this.state.vote_id,
          attributes: {
            vote_flag: voteFlag,
          },
        },
      },
    )
  },

  apiCallSuccess(data) {
    if (data) {
      this.setState({
        vote_id: data.data.id,
        liked: data.data.attributes['vote-flag'],
        disliked: !data.data.attributes['vote-flag'],
      })
    }
  },

  apiCallFailure(data) {
    util.error(
      JSON.parse(data.responseText).message,
    )
  },

  render() {
    return (
      <div className="voting-widget">
        <div className="tagline">
          { I18n.t('votes.helpful') }
        </div>
        <div className="vote-icons">
          <div className="vote up">
            <i className={this.upClass()} {...accessibleOnClickProps(this.postData.bind(this, true))} />
          </div>
          <div className="vote down">
            <i className={this.downClass()} {...accessibleOnClickProps(this.postData.bind(this, false))} />
          </div>
        </div>
        <div className={this.feedbackClass()}>
          <HotwireLink frame="remote-modal" href={this.supportURL()}>
            { I18n.t('votes.feedback') }
          </HotwireLink>
        </div>
      </div>
    )
  },
})

export default VotingComponent
