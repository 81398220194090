import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import type {
  PersonMetric_metric$key as MetricKey,
} from './__generated__/PersonMetric_metric.graphql'

import PersonMetricItemWrapper from './PersonMetricItemWrapper'

type Props = $ReadOnly<{
  item: MetricKey,
}>

const itemFragment = graphql`
  fragment PersonMetric_metric on Metric {
    id
    description
    link
    localizedRoleName
    isGlobal
    privateToCircle

    viewerCanSeeEditIcon

    circle {
      id
      databaseId
      localizedName

      supportedRole {
        isDisplayedAsCircle
      }

      organization {
        databaseId
      }
    }
  }
`

function PersonMetric({item: itemKey}: Props): Node {
  const item = useFragment(itemFragment, itemKey)
  const circle = item.circle

  return (
    <PersonMetricItemWrapper
      key={`${circle.id}-${item.id}`}
      item={item}
      circle={circle}
    />
  )
}

export default PersonMetric
