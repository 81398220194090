/**
 * @flow
 * @relayHash 70d8c909a521cc1e10b66103822f00bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PrintableGovernanceForm_organization$ref = any;
export type PrintableGovernancePageQueryVariables = {|
  orgDatabaseId: string
|};
export type PrintableGovernancePageQueryResponse = {|
  +organization: ?{|
    +id: string,
    +onRestrictedPlan: boolean,
    +$fragmentRefs: PrintableGovernanceForm_organization$ref,
  |}
|};
export type PrintableGovernancePageQuery = {|
  variables: PrintableGovernancePageQueryVariables,
  response: PrintableGovernancePageQueryResponse,
|};
*/


/*
query PrintableGovernancePageQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    id
    onRestrictedPlan
    ...PrintableGovernanceForm_organization
  }
}

fragment PrintableGovernanceForm_organization on Organization {
  id
  onRestrictedPlan
  isGoalsEnabled
  viewerIsAdminOrHigher
  isPdfReportGenerationRestricted
  customRoles {
    edges {
      node {
        id
        isDisplayedAsCircle
        name: localizedName
        ...RoleSelector_roles
      }
    }
  }
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onRestrictedPlan",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrintableGovernancePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PrintableGovernanceForm_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrintableGovernancePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGoalsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsAdminOrHigher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPdfReportGenerationRestricted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleConnection",
            "kind": "LinkedField",
            "name": "customRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDisplayedAsCircle",
                        "storageKey": null
                      },
                      {
                        "alias": "name",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localizedName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFocus",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "governanceEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": "nameWithMode",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "with",
                            "value": "CIRCLE_FOR_CORE_ROLES"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "localizedName",
                        "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "circle",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "supportedRole",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "70d8c909a521cc1e10b66103822f00bd",
    "metadata": {},
    "name": "PrintableGovernancePageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d30afdc75c71d50b7aff37d99f7e67d';

module.exports = node;
