import '@hotwired/turbo-rails'
import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill'
import 'core-js'
import 'regenerator-runtime/runtime'
import RelayRuntime from 'relay-runtime'
import ReactRelay from 'react-relay'
import createReactClass from 'create-react-class'

import setupGF from '../legacy/GlassFrog/setup'

import {enableWYSIWYG, disableWYSIWYG} from '../utils/MiscUtils/wysiwygFeatureFlag'
import getCurrentLocale from '../utils/getCurrentLocale'

import Glassfrog from '../Glassfrog'
import '../legacy_support/index'
import '../styles/glassfrog.scss'

// Disable Turbolinks by default, and selectively enabled them with this attribute: `<a data-turbo="true">`
Turbo.session.drive = false

setupGF()

window.Glassfrog = Glassfrog
// expose these for learning purposes
window.RelayRuntime = RelayRuntime
window.ReactRelay = ReactRelay
window.createReactClass = createReactClass

window.enableWYSIWYG = enableWYSIWYG
window.disableWYSIWYG = disableWYSIWYG

I18n.t = Glassfrog.utils.translateWithMissedKeysDetection(I18n.t)
I18n.locale = getCurrentLocale()
