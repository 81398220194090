// @flow
import type {Frequency, CustomFrequency} from 'constants/frequencies'
import {frequencyToUnits} from 'constants/frequencies'
import {type Option} from 'components/types'
import {ascend, prop, sortWith} from 'ramda'

export type ChoosableFrequency = $ReadOnly<{
  frequency: ?Frequency,
  customFrequencyId: ?string,
}>

type FrequencyType = 'custom' | 'standard'

export type UnifiedFrequency = $ReadOnly<{
  id: string,
  name: string,
  units: number,
  type: FrequencyType,
}>

export function unifyFrequencies(
  frequencies: $ReadOnlyArray<Frequency>,
  customFrequencies: $ReadOnlyArray<CustomFrequency>,
): $ReadOnlyArray<UnifiedFrequency> {
  return [
    ...frequencies.map((f) => ({
      id: f,
      name: I18n.t(`shared.enum.frequency.${f}`),
      units: frequencyToUnits[f],
      type: 'standard',
    })),
    ...customFrequencies.map((cf) => ({
      id: cf.id,
      name: cf.name,
      units: cf.universalUnits,
      type: 'custom',
    })),
  ]
}

export const sortFrequencies: any = sortWith([ascend(prop('units'))])

export const frequencyToOptionValue = (
  frequency: ?Frequency,
  customFrequency: ?CustomFrequency,
): ChoosableFrequency => ({
  frequency: customFrequency ? null : frequency,
  customFrequencyId: customFrequency?.id || null,
})

const optionsFromFrequency = (frequency: UnifiedFrequency) => ({
  label: frequency.name,
  value: JSON.stringify({
    frequency: frequency.type === 'standard' ? frequency.id : null,
    customFrequencyId: frequency.type === 'custom' ? frequency.id : null,
  }),
})

export function getFrequencyOptions(
  constantFrequencies: $ReadOnlyArray<Frequency>,
  customFrequencies: $ReadOnlyArray<CustomFrequency>,
): $ReadOnlyArray<Option> {
  return sortFrequencies(
    unifyFrequencies(constantFrequencies, customFrequencies),
  ).map(optionsFromFrequency)
}

function parseFrequency(option: string) {
  try {
    const values = JSON.parse(option) || {}
    return {frequency: values.frequency, customFrequencyId: values.customFrequencyId}
  } catch {
    return null
  }
}

export function extractFrequency(option: string | ChoosableFrequency | null): ?ChoosableFrequency {
  if (typeof option === 'string')
    return parseFrequency(option)

  return option
}

export function convertFrequencyToOptionValue(freq: ?ChoosableFrequency): string {
  if (!freq)
    return ''

  return JSON.stringify(freq)
}
