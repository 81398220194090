// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'
import type {
  ProfileProjectsQuery as Query,
} from './__generated__/ProfileProjectsQuery.graphql'

import ProfileProjectsView from './ProfileProjectsView'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

const profileProjectsQuery = graphql`
  query ProfileProjectsQuery(
    $orgDatabaseId: String!,
    $personDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      ...ProfileProjectsView_organization

      person(databaseId: $personDatabaseId) {
        ...ProfileProjectsView_person
      }
    }
  }
`

function ProfileProjects({orgDatabaseId, personDatabaseId} : Props): null | Node {
  const queryVariables = {orgDatabaseId, personDatabaseId}
  const [{organization}, refreshQuery] = useRefetchableLazyLoadQuery<Query>(profileProjectsQuery, queryVariables)
  const person = organization?.person

  if (!organization || !person)
    return null

  return (
    <ProfileProjectsView
      organization={organization}
      person={person}
      refetch={refreshQuery}
    />
  )
}

export default ProfileProjects
