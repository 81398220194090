// @flow
import type {Element, Node} from 'react'
import React from 'react'
import Header from './Header'
import Items from './Items'
import Item from './Item'
import NoHeaderItem from './NoHeaderItem'
import styles from './index.scss'

type Props = $ReadOnly<{
  children?: Node,
  collapsed: boolean,
}>

function SectionPlaceholder({collapsed, children}: Props): Element<"div"> {
  return collapsed
    ? (
      <div className={styles.collapsedSection}>
        <div className={styles.icon} />
      </div>
    )
    : (
      <div className={styles.section}>
        {children}
      </div>
    )
}

SectionPlaceholder.defaultProps = {
  children: null,
  collapsed: false,
}

SectionPlaceholder.Header = Header
SectionPlaceholder.Item = Item
SectionPlaceholder.Items = Items
SectionPlaceholder.NoHeaderItem = NoHeaderItem

export default SectionPlaceholder
