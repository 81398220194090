// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise from './createMutationPromise'

const mutation = graphql`
  mutation DeleteChecklistItemMutation(
    $input: DeleteChecklistItemInput!
  ) {
    deleteChecklistItem(input: $input) {
      deletedChecklistItemId
    }
  }
`

export default (checklistItemId: string, organizationId: ?string): Promise<mixed> => {
  const variables = {
    input: {
      checklistItemId,
    },
  }

  const configs = [{
    type: 'NODE_DELETE',
    deletedIDFieldName: 'deletedChecklistItemId',
  }, organizationId && {
    type: 'RANGE_DELETE',
    parentID: organizationId,
    connectionKeys: [{
      key: 'GlobalChecklistItems_checklistItems',
      filters: {
        global: 'GLOBAL_ONLY',
      },
    }],
    pathToConnection: ['organization', 'checklistItems'],
    deletedIDFieldName: 'deletedChecklistItemId',
  }].filter(Boolean)

  return createMutationPromise({
    mutation,
    variables,
    configs,
    optimisticResponse: {
      deleteChecklistItem: {
        deletedChecklistItemId: checklistItemId,
      },
    },
    onCompleted: (response: Object) => {
      if (response.deleteChecklistItem)
        util.info('Success!')
      else
        util.warn('there was a problem')
    },
    onError: (e: Error) => util.error(e),
  })
}
