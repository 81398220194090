import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import RenderDateComponent from '../RenderDateComponent'

const RenderDateRangeComponent = createReactClass({
  displayName: 'RenderDateRangeComponent',

  propTypes: {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    locale: PropTypes.string,
    separator: PropTypes.string,
  },

  getDefaultProps() {
    return {
      locale: 'en',
      separator: '-',
    }
  },

  render() {
    if (!this.props.startDate || !this.props.endDate)
      return null

    return (
      <div className="date_range">
        <RenderDateComponent
          rawDate={this.props.startDate}
          locale={this.props.locale}
          format="MMMM d Y hh:mm a"
        />
        {this.props.separator}
        <RenderDateComponent
          rawDate={this.props.endDate}
          locale={this.props.locale}
          format="h:mm a zzz"
        />
      </div>

    )
  },
})

export default RenderDateRangeComponent
