/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleSelector_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type WithCurrentRoleSelector_role$ref: FragmentReference;
declare export opaque type WithCurrentRoleSelector_role$fragmentType: WithCurrentRoleSelector_role$ref;
export type WithCurrentRoleSelector_role = {|
  +id: string,
  +circle: ?{|
    +supportedRole: ?{|
      +isDisplayedAsCircle: boolean
    |}
  |},
  +$fragmentRefs: RoleSelector_roles$ref,
  +$refType: WithCurrentRoleSelector_role$ref,
|};
export type WithCurrentRoleSelector_role$data = WithCurrentRoleSelector_role;
export type WithCurrentRoleSelector_role$key = {
  +$data?: WithCurrentRoleSelector_role$data,
  +$fragmentRefs: WithCurrentRoleSelector_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithCurrentRoleSelector_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDisplayedAsCircle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "nameWith",
          "value": "CIRCLE"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RoleSelector_roles"
    }
  ],
  "type": "Role",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '19bb21cad8236a8499ce5a1a7fc788fe';

module.exports = node;
