/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputPerson_output$ref: FragmentReference;
declare export opaque type OutputPerson_output$fragmentType: OutputPerson_output$ref;
export type OutputPerson_output = {|
  +person: ?{|
    +name: string
  |},
  +$refType: OutputPerson_output$ref,
|};
export type OutputPerson_output$data = OutputPerson_output;
export type OutputPerson_output$key = {
  +$data?: OutputPerson_output$data,
  +$fragmentRefs: OutputPerson_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputPerson_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3e530919201563bbbe2ce11b2d582b2e';

module.exports = node;
