// @flow
import type {Node} from 'react'
import React, {Fragment, useContext} from 'react'
import {graphql, useFragment} from 'react-relay'

import {Header, CardContext} from 'components/ui/Accordion'
import Close from 'components/ui/icons/md/Close'
import InlineMarkdown from 'components/ui/InlineMarkdown'
import EditButton from 'components/ui/Inputs/EditItemButton'
import FrogTipBox from 'components/ui/FrogTipBox'
import WYSIWYGViewerWithFeatureFlag from 'components/ui/WYSIWYGViewerWithFeatureFlag'
import styles from '../../../index.scss'
import FormattedOption from '../FormattedOption'

import type {
  SectionHeader_organization$key as OrganizationKey,
} from './__generated__/SectionHeader_organization.graphql'

type Props = {
  organization: OrganizationKey,
  title: string,
  frogTip?: string,
  ruleOption: Object,
  description: Object,
  disabled: boolean,
}

const organizationFragment = graphql`
  fragment SectionHeader_organization on Organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
  }
`

function SectionHeader({
  organization: organizationKey,
  title,
  frogTip,
  ruleOption,
  description,
  disabled,
}: Props): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const {isOpen} = useContext(CardContext)
  const renderIcon = ({toggleOpen}) => (
    <EditButton onClick={toggleOpen} viewContext="page">
      {isOpen && (<Close />)}
    </EditButton>
  )
  const sectionLabel = () => (
    <h2 className={styles.title}>{title}</h2>
  )
  const sectionTitle = () => {
    if (!frogTip)
      return sectionLabel()

    const frogBoxTip = () => (
      <div>{frogTip}</div>
    )
    return (
      <FrogTipBox
        hideDivider
        helpFor={title}
        target={sectionLabel}
        tip={frogBoxTip}
      />
    )
  }

  return (
    <Header disabled={disabled} passive renderIcon={renderIcon}>
      <Fragment>
        {sectionTitle()}
        {isOpen || (
          <FormattedOption isOpen={isOpen} selected option={ruleOption}>
            <WYSIWYGViewerWithFeatureFlag
              value={description}
              organization={organization}
              fallbackProps={{
                text: description,
              }}
              fallbackComponent={InlineMarkdown}
            />
          </FormattedOption>
        )}
      </Fragment>
    </Header>
  )
}
export default SectionHeader
