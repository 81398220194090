import _ from 'underscore'
import util from 'utils/MiscUtils'

// Intention: provide self-standing pre-digestion of gups getData() arguments, as used by
// both legacy gups store, and gduxStore compatibility layer
// FIXME: factor out as many store dependencies as possible.
const DataRequest = {
  requestType: {
    FORWARD_LOOKUP: 'FORWARD_LOOKUP',
    REVERSE_LOOKUP: 'REVERSE_LOOKUP',
    SINGLE_RESOURCE: 'SINGLE_RESOURCE',
    COLLECTION: 'COLLECTION',
    UNDEFINED: 'UNDEFINED',
    NULL: 'NULL',
  },

  fromArguments(originalArgs, store) {
    const args = _.clone(originalArgs)
    // let's just not do relations in lists for now
    // first argument is always type
    const rawType = args.shift() || util.error('need a type here')
    const type = gf.app.store.normalizeTypeToPlural(rawType)

    // if last argument is a hash, it's options
    const options = _.isObject(args[args.length - 1]) && !_.isArray(args[args.length - 1])
      ? args.pop()
      : {}

    // if there's no more args, or the next arg is an array or '*', there's no id.
    // otherwise the next arg should be a valid id
    const id = (args.length == 0 || _.isArray(args[0]) || args[0] == '*' || args[0] == 'ids')
      ? null
      : args.shift() || (options.allowMissingId ? undefined : util.error('missing id in data request', originalArgs))

    let relName = args.length > 1 ? args.shift() : undefined
    let dataSpec = args.shift() || undefined

    if (_.isArray(dataSpec) && _.any(dataSpec, (x) => x && x.indexOf && x.indexOf('.') !== -1))
      util.error("sorry attr lists don't support looking up relations. try asking for the relation lookup separately")

    if (dataSpec && dataSpec.indexOf && dataSpec.indexOf('.') !== -1) {
      const parts = dataSpec.split('.')
      relName = parts[0]
      dataSpec = parts[1]
    }

    const dataRequest = {
      type,
      options,
      id,
      relName,
      dataSpec,
      originalArgs,
    }

    if (args.length == 1 && util.isId(dataRequest.dataSpec)) {
      dataRequest.relId = dataRequest.dataSpec
      dataRequest.dataSpec = args.shift()
    }

    if (args.length > 0)
      dataRequest.additionalArguments = args

    dataRequest.requestType = this.getType(dataRequest, store)
    return dataRequest
  },

  getType(dr, store) {
    if (dr.id && store.getMetadata(dr.type, dr.id, 'removed'))
      return DataRequest.requestType.NULL
    if (dr.id && dr.relName) {
      const resource = store.getResource(dr.type, dr.id, dr.options)
      const forwardLookupRequested = dr.options.reverseLookup === false
      const reverseLookupRequested = dr.options.reverseLookup === true
      const forwardLookupAvailable = resource && gf.r.hasRelationship(dr.relName, resource)
      const shouldDoForwardLookup = forwardLookupRequested
        || (forwardLookupAvailable && !reverseLookupRequested)

      return shouldDoForwardLookup
        ? DataRequest.requestType.FORWARD_LOOKUP
        : DataRequest.requestType.REVERSE_LOOKUP
    } if (dr.id === undefined) {
      // this means an id parameter was passed, but was null or undefined,
      // and allowMissingId options was set. we know we can't find the data for this case,
      // so just return undefined
      return DataRequest.requestType.UNDEFINED
    } if (dr.id)
      return DataRequest.requestType.SINGLE_RESOURCE
    if (dr.id === null)
      return DataRequest.requestType.COLLECTION

    return DataRequest.requestType.UNDEFINED
  },
}

export default DataRequest
