import React, {useRef, useMemo} from 'react'
import classNames from 'classnames'
import styles from './index.scss'

type Props = {
  chatId: string,
  chats: Array<any>,
  switchChats: (string) => void,
  newChat: () => void
}

function ChatsArea({chats, chatId, newChat, switchChats}: Props) {
  const chatsArea = useRef()

  const groupedChatsByDate = useMemo(() => {
    const today = new Date().setHours(0, 0, 0, 0)
    const sevenDaysAgo = new Date(today)
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
    const thirtyDaysAgo = new Date(today)
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const groupedChats = {
      Today: [],
      'Previous 7 days': [],
      'Previous 30 days': [],
      Older: [],
    }

    chats.forEach((chat) => {
      const chatDate = new Date(chat.date).getTime()

      switch (true) {
        case chatDate >= today:
          groupedChats.Today.push(chat)
          break
        case chatDate >= sevenDaysAgo:
          groupedChats['Previous 7 days'].push(chat)
          break
        case chatDate >= thirtyDaysAgo:
          groupedChats['Previous 30 days'].push(chat)
          break
        default:
          groupedChats.Older.push(chat)
      }
    })

    return groupedChats
  }, [chats])

  return (
    <div className={styles.chats} ref={chatsArea}>
      <button
        className={classNames(styles.chat, styles.newChat, chatId === null ? styles.active : null)}
        onClick={newChat}
        type="button"
      >
        <i className="fa fa-plus" />
        {'New Chat'}
      </button>
      {Object.entries(groupedChatsByDate).map(([group, chatsInGroup]) => chatsInGroup.length > 0 && (
        <div key={group} test-id={`${group}`}>
          <div className={styles.timing}>
            {group}
          </div>
          {chatsInGroup.map((chat) => (
            <button
              key={chat.id}
              className={classNames(styles.chat, chatId === chat.id ? styles.active : null)}
              onClick={() => switchChats(chat.id)}
              type="button"
            >
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.title} dangerouslySetInnerHTML={{__html: chat.initial_message}} />
              {/* <div className="small text-muted">{chat.date}</div> */}
            </button>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ChatsArea
