// @flow
import {graphql} from 'react-relay'
import {ConnectionHandler} from 'relay-runtime'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  UpdateActionPositionMutationResponse as Response,
  UpdateActionPositionInput as Input,
} from './__generated__/UpdateActionPositionMutation.graphql'

type Variables = $ReadOnly<{
  input: MutationInput<Input>,
}>

const mutation = graphql`
  mutation UpdateActionPositionMutation(
    $input: UpdateActionPositionInput!
  ) {
    updateActionPosition(input: $input) {
      action {
        project {
          actions {
            edges {
              node {
                id
                position
              }
            }
          }
        }
      }
    }
  }
`

const UpdateActionPositionMutation = (
  input: MutationInput<Input>,
  projectId: string,
): Promise<Response> => {
  const variables: Variables = {input}

  const optimisticUpdater = (store) => {
    const projectNode = store.get(projectId)
    const movedActionNode = store.get(input.actionId)
    const previousMovedActionPosition = movedActionNode.getValue('position')
    const movementRange = [input.position, previousMovedActionPosition].sort()

    const movement = input.position > previousMovedActionPosition
      ? 'UP'
      : 'DOWN'

    const actionsConnection = ConnectionHandler.getConnection(
      projectNode,
      'List_actions',
    )
    if (!actionsConnection)
      return

    const actionsEdges = actionsConnection.getLinkedRecords('edges')
    if (!actionsEdges)
      return

    const actionNodes = actionsEdges.filter(Boolean).map((edge) => edge.getLinkedRecord('node'))

    actionNodes.forEach((node) => {
      if (!node)
        return

      const previousPosition = node.getValue('position')
      const nodeId = node.getDataID()

      if (typeof previousPosition !== 'number')
        return

      const nodeInReplacementRange = previousPosition >= movementRange[0]
        && previousPosition <= movementRange[1]
      if (!nodeInReplacementRange)
        return

      if (nodeId === input.actionId)
        return

      if (movement === 'UP')
        node.setValue(previousPosition - 1, 'position')
      if (movement === 'DOWN')
        node.setValue(previousPosition + 1, 'position')
    })

    movedActionNode.setValue(input.position, 'position')
  }

  return createMutationPromise({
    mutation,
    variables,
    optimisticUpdater,
  })
}

export default UpdateActionPositionMutation
