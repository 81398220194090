// @flow
import type {Node} from 'react'
import React, {Fragment} from 'react'
import {graphql, useFragment} from 'react-relay'
import {Field, type FormikProps} from 'formik'

import useCKRolesFeed from 'utils/hooks/useCKRolesFeed'
import InputWithRoleAutocomplete from 'components/ui/forms/InputWithRoleAutocomplete'
import Textarea from 'components/ui/forms/Textarea'
import PersonRoleSelector from 'components/roles/PersonRoleSelector'
import WYSIWYGTextareaWithFeatureFlag from 'components/ui/forms/WYSIWYGTextareaWithFeatureFlag'
import type {
  TensionEditForm_viewer$key as Viewer,
} from './__generated__/TensionEditForm_viewer.graphql'
import type {
  TensionEditForm_organization$key as Organization,
} from './__generated__/TensionEditForm_organization.graphql'

export type Values = $ReadOnly<{
  body: string,
  impactedRoleId: ?string,
}>

type Props = {
  viewer: Viewer,
  organization: Organization,
  formikProps: FormikProps<Values>,
  bodyRows: number,
}

const viewerFragment = graphql`
  fragment TensionEditForm_viewer on Person {
    ...PersonRoleSelector_person
  }
`

const organizationFragment = graphql`
  fragment TensionEditForm_organization on Organization {
    databaseId
    
    ...WYSIWYGTextareaWithFeatureFlag_organization
  }
`

function TensionEditForm({
  viewer: viewerKey,
  organization: organizationKey,
  formikProps,
  bodyRows,
}: Props): Node {
  const viewer = useFragment(viewerFragment, viewerKey)

  const organization = useFragment(organizationFragment, organizationKey)

  const rolesFeed = useCKRolesFeed(organization.databaseId)

  return (
    <Fragment>
      <WYSIWYGTextareaWithFeatureFlag
        mode="inline"
        rows={bodyRows}
        onChange={formikProps.handleChange}
        label={I18n.t('forms.description_label')}
        name="body"
        getFeed={rolesFeed}
        organization={organization}
        value={formikProps.values.body}
        fallbackComponent={InputWithRoleAutocomplete}
        fallbackProps={{
          input: Textarea,
          orgDatabaseId: organization.databaseId,
          placeholder: I18n.t('tensions.react.labels.your_tension'),
        }}
        warning={formikProps.errors.body}
      />
      <Field
        test-id="tension-role"
        person={viewer}
        useAsValue="id"
        as={PersonRoleSelector}
        label={I18n.t('tensions.react.labels.role')}
        name="impactedRoleId"
        placeholder={I18n.t('tensions.react.role_selector_placeholder')}
      />
    </Fragment>
  )
}

TensionEditForm.defaultProps = {
  bodyRows: 6,
}

export default TensionEditForm
