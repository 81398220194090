// @flow
import type {Node, Element} from 'react'
import React, {useState, useContext} from 'react'
import classNames from 'classnames'
import styles from './index.scss'
import Header from './Header'

/* The functionality of this component is fairly generic and could theoretically be reused.
   The styling however is fairly specific to TensionGuide, so some work would likely need to be done
   to allow overriding styles if we wanted to use it elsewhere.
 */

type BodyProps = $ReadOnly<{
  children?: Node,
  render?: ({isOpen: boolean, toggleOpen: () => void}) => Node,
}>

type CardProps = $ReadOnly<{
  children?: Node,
  index: string,
  'test-id'?: string,
  disabled?: boolean,
}>

const AccordionContext: React$Context<any> = React.createContext<Object>({
  openIndex: -1,
  setOpenIndex: null,
})

function Accordion({children}: { children: Node }): Node {
  // index = key of the open card
  // TODO: rename index
  const [openIndex, setOpenIndex] = useState(-1)

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AccordionContext.Provider value={{openIndex, setOpenIndex}}>
      <div className={styles.container}>
        {children}
      </div>
    </AccordionContext.Provider>
  )
}

const CardContext: React$Context<any> = React.createContext<Object>({index: '', isOpen: false, toggleOpen: () => null})

function Card({index, children, 'test-id': testId, disabled = false}: CardProps): Node {
  const {openIndex, setOpenIndex} = useContext(AccordionContext)
  const isOpen = disabled
    ? false
    : openIndex === index
  const toggleOpen = () => setOpenIndex(isOpen ? -1 : index)
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CardContext.Provider value={{isOpen, toggleOpen}}>
      <div test-id={testId} className={classNames(styles.card, {[styles.selected]: isOpen})}>
        {children}
      </div>
    </CardContext.Provider>
  )
}

function Body({render, children}: BodyProps): null | Element<"div"> {
  const {isOpen, toggleOpen} = useContext(CardContext)
  if (!isOpen)
    return null

  return (
    <div className={styles.body}>
      <div className={styles.bodyContent}>
        { render ? render({isOpen, toggleOpen}) : children }
      </div>
    </div>
  )
}

export {
  Accordion,
  Card,
  Header,
  Body,
  CardContext,
  AccordionContext,
}
