// @flow
import React, {type Node, useCallback} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import classNames from 'classnames'
import {Droppable} from 'react-beautiful-dnd'

import {type OpenEditor} from 'components/projects/Project'
import ArchiveBackground from './ArchiveBackground'
import DraggableProjectCard from './DraggableProjectCard'
import styles from './index.scss'
import type {
  ProjectsStatusColumn_projects as Projects,
  ProjectsStatusColumn_projects$key as ProjectsBeforeFragment,
} from './__generated__/ProjectsStatusColumn_projects.graphql'
import type {
  ProjectsStatusColumn_circle as Circle,
} from './__generated__/ProjectsStatusColumn_circle.graphql'

export type {ProjectsBeforeFragment}

type Props = $ReadOnly<{
  hidden: boolean,
  status: 'current' | 'waiting' | 'done' | 'future' | 'archived',
  projects: Projects,
  currentDestinationId: ?string,
  currentFromId: ?string,
  groupKey: string,
  openEditor: OpenEditor,
  circle: null | Circle,
  hideAvatars: ?boolean,
  hideSource: ?boolean,
}>

type ColumnDetails = {
  groupKey: string,
  status: string,
}

// this function generates droppableId that should be unique
export function columnDetailsToDroppableId(details: ColumnDetails): string {
  return JSON.stringify(details)
}

export function droppableIdToColumnDetails(stringifiedDetails: ?string): ?ColumnDetails {
  if (!stringifiedDetails)
    return null

  const maybeDetails = JSON.parse(stringifiedDetails)

  // Both hasGroupKey and hasStatus required cuz stringifiedDetails (and parsed maybeDetails)
  //  can contain any random data.
  // To be sure that maybeDetails is really Details we have to check:
  //  - is key exists
  //  - is key is a string
  // typeof object.key === 'string' do both things
  const hasGroupKey = typeof maybeDetails?.groupKey === 'string'
  const hasStatus = typeof maybeDetails?.status === 'string'
  if (hasGroupKey && hasStatus)
    return maybeDetails

  return null
}

function ProjectsStatusColumn({
  hidden,
  status,
  groupKey,
  projects,
  currentDestinationId,
  openEditor,
  currentFromId,
  circle,
  hideAvatars,
  hideSource,
}: Props): Node {
  const selfDestinationId = columnDetailsToDroppableId({groupKey, status})

  const fromDetails = droppableIdToColumnDetails(currentFromId)

  const projectsWithThisStatus = projects.filter((project) => project && project.status.toLowerCase() === status)
  const renderColumn = useCallback((provided) => (
    <div
      className={styles.items}
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {projectsWithThisStatus.map((project, index) => (
        <DraggableProjectCard
          key={project.id}
          project={project}
          index={index}
          openEditor={openEditor}
          circle={circle}
          hideAvatars={hideAvatars}
          hideSource={hideSource}
        />
      ))}
      {provided.placeholder}
    </div>
  ), [openEditor, projectsWithThisStatus, circle, hideAvatars, hideSource])

  const isDragOver = selfDestinationId === currentDestinationId
    && selfDestinationId !== currentFromId
  const canDrop = fromDetails?.groupKey === groupKey && selfDestinationId !== currentFromId

  const columnClassName = classNames(styles.column, {
    [styles.columnHidden]: hidden,
    [styles.columnDragOver]: isDragOver,
    [styles.columnDragTarget]: canDrop && !isDragOver,
  })

  return (
    <div className={columnClassName}>
      <Droppable
        droppableId={selfDestinationId}
        direction="vertical"
      >
        {renderColumn}
      </Droppable>
      {!hidden && status === 'archived' && (
        <div className={styles.background}>
          <ArchiveBackground />
        </div>
      )}
    </div>
  )
}

ProjectsStatusColumn.defaultProps = {
  hidden: false,
}

export default (createFragmentContainer(ProjectsStatusColumn, {
  projects: graphql`
    fragment ProjectsStatusColumn_projects on Project @relay(plural: true) {
      id
      status

      ...DraggableProjectCard_project
    }
  `,
  circle: graphql`
    fragment ProjectsStatusColumn_circle on Circle {
      ...DraggableProjectCard_circle
    }
  `,
}): RelayFragmentContainer<typeof(ProjectsStatusColumn)>)
