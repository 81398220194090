/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AgendaItemGroups_meeting$ref = any;
type NewOutputForm_circle$ref = any;
type NewOutputForm_meeting$ref = any;
type TacticalMeetingTension_meeting$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TacticalMeetingTriageView_meeting$ref: FragmentReference;
declare export opaque type TacticalMeetingTriageView_meeting$fragmentType: TacticalMeetingTriageView_meeting$ref;
export type TacticalMeetingTriageView_meeting = {|
  +databaseId: string,
  +circle: {|
    +$fragmentRefs: NewOutputForm_circle$ref
  |},
  +$fragmentRefs: NewOutputForm_meeting$ref & AgendaItemGroups_meeting$ref & TacticalMeetingTension_meeting$ref,
  +$refType: TacticalMeetingTriageView_meeting$ref,
|};
export type TacticalMeetingTriageView_meeting$data = TacticalMeetingTriageView_meeting;
export type TacticalMeetingTriageView_meeting$key = {
  +$data?: TacticalMeetingTriageView_meeting$data,
  +$fragmentRefs: TacticalMeetingTriageView_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TacticalMeetingTriageView_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewOutputForm_circle"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewOutputForm_meeting"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgendaItemGroups_meeting"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TacticalMeetingTension_meeting"
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
// prettier-ignore
(node/*: any*/).hash = 'a8726186ad77afa2b3cacec7bb9dbe08';

module.exports = node;
