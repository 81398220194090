/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomGovernanceCircles_circles$ref: FragmentReference;
declare export opaque type CustomGovernanceCircles_circles$fragmentType: CustomGovernanceCircles_circles$ref;
export type CustomGovernanceCircles_circles = $ReadOnlyArray<{|
  +id: string,
  +localizedName: string,
  +resourcePath: any,
  +isGovernanceEnabled: boolean,
  +$refType: CustomGovernanceCircles_circles$ref,
|}>;
export type CustomGovernanceCircles_circles$data = CustomGovernanceCircles_circles;
export type CustomGovernanceCircles_circles$key = $ReadOnlyArray<{
  +$data?: CustomGovernanceCircles_circles$data,
  +$fragmentRefs: CustomGovernanceCircles_circles$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CustomGovernanceCircles_circles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourcePath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGovernanceEnabled",
      "storageKey": null
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5192cf3a20b841a94abc9357bebf1a93';

module.exports = node;
