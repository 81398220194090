// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import {ascend, sortWith} from 'ramda'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import type {CollectionNode} from 'utils/GraphQL/types'
import ShowMore from 'components/ui/forms/ShowMore'
import ListItems from './ListItems'
import type {
  List_project as Project,
  List_project$key as ProjectKey,
} from './__generated__/List_project.graphql'
import style from './index.scss'

type Action = CollectionNode<$PropertyType<Project, 'actions'>>
type Actions = $ReadOnlyArray<Action>

type Props = $ReadOnly<{
  project: ProjectKey,
  foldable: boolean,
  expandable: boolean,
}>

const sortByPosition: Actions => Actions = sortWith([
  ascend((action: Action) => action.position),
])

const projectFragment = graphql`
    fragment List_project on Project {
      id

      actions(first: 100) @connection(key: "List_actions") {
        edges {
          node {
            id
            position
            description(format: HTML)
            rawDescription: description(format: PLAIN)
            viewerCanUpdate

            ...Item_action
          }
        }
      }

      ...Item_project
    }
`

function List({project: projectKey, foldable, expandable}: Props): null | Node {
  const project = useFragment(projectFragment, projectKey)
  const actions = sortByPosition(liftNodes(project.actions))

  if (!actions.length && !expandable)
    return null

  const renderBody = () => (
    <ListItems
      actions={actions}
      project={project}
      expandable={expandable}
    />
  )

  if (foldable) {
    const showMoreTitle = I18n.t('projects.actions', {count: actions.length})

    return (
      <ShowMore
        showMoreTitle={showMoreTitle}
        showLessTitle={showMoreTitle}
        className={style.actionsMoreLabel}
      >
        {renderBody()}
      </ShowMore>
    )
  }

  return renderBody()
}

List.defaultProps = {
  foldable: false,
  expandable: true,
}

export default List
