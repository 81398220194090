// @flow
import type {Node} from 'react'
import React, {useMemo} from 'react'
import {useFragment, graphql} from 'react-relay'

import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import RoleSelector, {type OnRoleChange, type Role} from 'components/roles/RoleSelector'

import type {
  PersonRoleSelector_person$key as Person,
} from './__generated__/PersonRoleSelector_person.graphql'

type Props = $ReadOnly<{
  useOnlyRolesFromCircle: boolean,
  circleValue: string | null,
  supportedRoleValue: string | null,
  useIndividualActions: boolean,
  person: Person,
  label: string,
  onRoleChange: ?OnRoleChange,
  useAsValue: 'databaseId' | 'id',
  value: string | null,
  onChange: (FakeEvent, boolean | string | null) => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
  labelWrapper: ?(Role) => string,
  sortable: boolean,
}>

const personFragment = graphql`
  fragment PersonRoleSelector_person on Person {
    roles {
      edges {
        node {
          circle {
            supportedRole {
              isDisplayedAsCircle
            }
          }

          ...RoleSelector_roles @arguments(nameWith: CIRCLE)
        }
      }
    }
  }
`

function PersonRoleSelector({
  person: personKey,
  ...personSelectorProps
}: Props): Node {
  const person = useFragment(personFragment, personKey)

  const roles = useMemo(() => (
    liftNodes(person.roles).filter((role) => role.circle?.supportedRole?.isDisplayedAsCircle)
  ), [person])

  return (
    <RoleSelector
      roles={roles}
      {...personSelectorProps}
    />
  )
}

export default PersonRoleSelector
