// @flow
import {graphql} from 'react-relay'

import requestSubscription from './requestSubscription'
import type {
  TacticalMeetingOutputsEventSubscriptionVariables as Variables,
  TacticalMeetingOutputsEventSubscriptionResponse as Response,
} from './__generated__/TacticalMeetingOutputsEventSubscription.graphql'

const subscription = graphql`
  subscription TacticalMeetingOutputsEventSubscription($tacticalMeetingDatabaseId: String!) {
    tacticalMeetingEvent(tacticalMeetingDatabaseId: $tacticalMeetingDatabaseId) {
      ... on TacticalOutputAdded {
        meeting {
          id
        }
      }
    }
  }
`

function TacticalMeetingOutputsEventSubscription(variables: Variables, onNext: ?(Response) => void): any {
  return requestSubscription<Variables, Response>(subscription, variables, onNext)
}

export default TacticalMeetingOutputsEventSubscription
