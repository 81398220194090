// @flow
import type {Node} from 'react'
import React from 'react'
import {Draggable} from 'react-beautiful-dnd'

type Props = {
  actionId: string,
  index: number,
  children: Node,
}

function DraggableItem({
  index,
  actionId,
  children,
}: Props): Node {
  return (
    <Draggable
      index={index}
      draggableId={actionId}
    >
      {(provided) => (
        <div
          className="project__action__draggable-wrapper"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          role={undefined}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

export default DraggableItem
