// @flow
import type {Node} from 'react'
import React, {useMemo, useCallback} from 'react'
import {curry} from 'ramda'
import {useFragment, graphql} from 'react-relay'

import sortByProp from 'utils/MiscUtils/sortByProp'
import idOrDatabaseId, {type UseAsValue} from 'utils/MiscUtils/idOrDatabaseId'
import constants from 'utils/projects/constants'
import {individualInitiativeText} from 'utils/MiscUtils/constitution'
import {allCircleMembersId} from 'constants/fakeId'
import Select from 'components/ui/forms/Select'
import {type FakeEvent} from 'utils/forms/fakeInputEvent'
import type {
  RoleSelector_roles as Roles,
  RoleSelector_roles$key as RolesKey,
} from './__generated__/RoleSelector_roles.graphql'

export type Role = Roles[number]
type Circle = Role['circle']
type SupportedRole = Circle['supportedRole']

export type OnRoleChange = ({
  roleValue: string | null,
  role: Role | null,
  circleValue: string | null,
  supportedRoleValue: string | null,
  supportedRole: SupportedRole | null,
}) => void

type Props = $ReadOnly<{
  useOnlyRolesFromCircle: boolean,
  includeFocusRoles: boolean,
  circleValue: string | null,
  supportedRoleValue: string | null,
  label: string,
  roles: RolesKey,
  useIndividualActions: boolean,
  useEachCircleMember: boolean,
  onRoleChange: ?OnRoleChange,
  useAsValue: UseAsValue,
  value: string | null,
  onChange: (FakeEvent, boolean | string | null) => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
  disabled: boolean,
  labelWrapper: ?(Role) => string,
  sortable: boolean,
}>

const roleToOption = (useAsValue: UseAsValue, labelWrapper) => (role: Role) => ({
  value: idOrDatabaseId(useAsValue, role),
  label: labelWrapper ? labelWrapper(role) : role.nameWithMode,
})

const getRolesToUse = (
  roles: Roles,
  circleValue: string | null,
  supportedRoleValue: string | null,
  useOnlyRolesFromCircle: boolean,
  includeFocusRoles: boolean,
  objectToValue: (Circle | SupportedRole) => string,
) => {
  const rolesToUse = includeFocusRoles
    ? roles
    : roles.filter((role) => !role.isFocus)

  if (!circleValue && !supportedRoleValue && useOnlyRolesFromCircle)
    return []

  if (supportedRoleValue) {
    return rolesToUse.filter(({circle}) => (
      // $FlowFixMe[incompatible-call]
      circle?.supportedRole && objectToValue(circle.supportedRole) === supportedRoleValue
    ))
  }

  return circleValue
    ? rolesToUse.filter(({circle}) => circle && objectToValue(circle) === circleValue)
    : rolesToUse
}

const sortByName = sortByProp('nameWithMode')

const rolesFragment = graphql`
  fragment RoleSelector_roles on Role @relay(plural: true) @argumentDefinitions(
    nameWith: {type: "RoleLocalizedNameMode", defaultValue: CIRCLE_FOR_CORE_ROLES }
  ) {
    id
    isDisplayedAsCircle
    isFocus
    databaseId
    governanceEnabled
    nameWithMode: localizedName(with: $nameWith)

    circle {
      id
      databaseId

      supportedRole {
        id
        databaseId
      }
    }
  }
`

function RoleSelector({
  useOnlyRolesFromCircle,
  includeFocusRoles,
  circleValue,
  supportedRoleValue,
  onRoleChange,
  useAsValue,
  roles: rolesKey,
  onChange,
  useIndividualActions,
  useEachCircleMember,
  disabled,
  labelWrapper,
  sortable,
  ...selectProps
}: Props): Node {
  const roles = useFragment(rolesFragment, rolesKey)

  const objectToValue = useMemo(() => (
    curry(idOrDatabaseId)(useAsValue)
  ), [useAsValue])

  const individualAction = useMemo(() => ({
    value: constants.individualActionId,
    label: individualInitiativeText(window.gf.app.orgOnV5()),
  }), [])

  const initialValue = useIndividualActions
    ? individualAction.value
    : null

  const options = useMemo(() => {
    const rolesToUse = getRolesToUse(
      roles,
      circleValue,
      supportedRoleValue,
      useOnlyRolesFromCircle,
      includeFocusRoles,
      objectToValue,
    )
    const sortedRoles = sortable ? sortByName(rolesToUse) : rolesToUse

    const gqlRolesOptions = sortedRoles.map(roleToOption(useAsValue, labelWrapper))

    if (useIndividualActions && useAsValue === 'databaseId') {
      return [
        individualAction,
        ...gqlRolesOptions,
      ]
    }

    if (useIndividualActions && useAsValue === 'id') {
      return [
        {
          value: '0',
          label: individualInitiativeText(window.gf.app.orgOnV5()),
        },
        ...gqlRolesOptions,
      ]
    }

    if (useEachCircleMember && circleValue) {
      return [
        {
          label: I18n.t('forms.all_circle_members'),
          value: allCircleMembersId,
        },
        ...gqlRolesOptions,
      ]
    }

    return gqlRolesOptions
  }, [
    useIndividualActions, individualAction, roles, useAsValue, circleValue, useOnlyRolesFromCircle,
    includeFocusRoles, objectToValue, supportedRoleValue, labelWrapper, sortable, useEachCircleMember,
  ])

  const handleChange = useCallback((event, newValue) => {
    const selectedRole = roles.find((role) => {
      const roleValue = objectToValue(role)

      return roleValue === newValue
    })
    const circle = selectedRole?.circle
    const supportedRole = circle?.supportedRole

    onChange(event, newValue)
    if (onRoleChange) {
      onRoleChange({
        roleValue: newValue,
        role: selectedRole || null,
        circleValue: circle
          ? objectToValue(circle)
          : null,
        supportedRoleValue: supportedRole
          ? objectToValue(supportedRole)
          : null,
        supportedRole,
      })
    }
  }, [onChange, roles, onRoleChange, objectToValue])

  return (
    <Select
      initialValue={initialValue}
      disabled={disabled}
      options={options}
      onChange={handleChange}
      {...selectProps}
    />
  )
}

RoleSelector.defaultProps = {
  useOnlyRolesFromCircle: false,
  includeFocusRoles: false,
  circleValue: null,
  supportedRoleValue: null,
  disabled: false,
  clearable: false,
  'test-id': null,
  labelWrapper: null,
  sortable: true,
  useEachCircleMember: false,
}

export default RoleSelector
