// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'

import styles from './index.scss'

type Props = {
  children: Node,
  noMargin?: boolean,
}

function Content({children, noMargin}: Props): Element<"div"> {
  const className = classNames(styles.content, {[styles.noMargin]: noMargin})

  return (
    <div className={className}>
      {children}
    </div>
  )
}

Content.defaultProps = {
  noMargin: false,
}
export default Content
