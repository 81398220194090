import _ from 'underscore'

import ActionHandlerUtils from 'legacy/GlassFrog/utills/ActionHandlerUtils'
import util from 'utils/MiscUtils'
import Path from './Path'

/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-use-before-define */

const Instruction = (function () {
  const types = {
    attributes: 'attributes',
    trashcan: 'trashcan',
    removeRole: 'removeRole',
    removeCircle: 'removeCircle',
    collapseCircle: 'collapseCircle',
    removePolicy: 'removePolicy',
    restructureCircle: 'restructureCircle',
    election: 'election',
    movePolicies: 'movePolicies',
    moveRoles: 'moveRoles',
    expandRole: 'expandRole',
    addPolicy: 'addPolicy',
    editPolicy: 'editPolicy',
    domains: 'domains',
    accountabilities: 'accountabilities',
  }

  function filterCancellingAddRemoveInstructions(instructions) {
    const domainOrAccountabilityPaths = {}

    _.each(instructions, (inst) => {
      if (inst.path.match(/domains|accountabilities/)) {
        const key = _.first(Path.fromString(inst.path), 4).join('/')

        domainOrAccountabilityPaths[key] = domainOrAccountabilityPaths[key] || {}
        domainOrAccountabilityPaths[key][inst.op] = true
      }
    })

    return _.filter(instructions, (inst) => {
      if (inst.path.match(/domains|accountabilities/)) {
        const key = _.first(Path.fromString(inst.path), 4).join('/')
        const seen = domainOrAccountabilityPaths[key]
        if (seen.add && seen.remove)
          return false
      }
      return true
    })
  }

  function getStepMap(inst) {
    return Path.toHash(Path.fromString(inst.path))
  }

  function getModel(inst) {
    const map = getStepMap(inst)
    return gf.db.getModel(map.resourceName, map.resourceId)
  }

  function runAdd(inst) {
    const stepMap = getStepMap(inst)
    const isFullApiResponse = inst.value.linked || _.isArray(inst.value[stepMap.resourceName])

    this.store = gf.app.store

    if (isFullApiResponse) {
      Object.keys(inst.value).forEach((key) => {
        if (!inst.value.hasOwnProperty(key))
          return

        if (key === 'linked') {
          ActionHandlerUtils.mergeLinkedDataIntoStore.call(
            this,
            stepMap.resourceName,
            [],
            inst.value.linked,
            {
              skipIfChangedSince: inst.requested_at,
            },
          )
        } else {
          this.store.setData(key, inst.value[key], {skipIfChangedSince: inst.requested_at})
        }
      })
    } else {
      this.store.setData(
        stepMap.resourceName,
        [inst.value],
        {skipIfChangedSince: inst.requested_at},
      )
    }
  }

  function runReplace(inst) {
    const stepMap = getStepMap(inst)
    const data = {}

    data[stepMap.attributeName] = inst.value

    const haveData = gf.app.store.getData(stepMap.resourceName, stepMap.resourceId, {fetch: true})
    if (haveData) {
      gf.app.store.setData(
        stepMap.resourceName,
        stepMap.resourceId,
        data,
        {skipIfChangedSince: inst.requested_at},
      )
    }
  }

  function runRemove(inst) {
    const stepMap = getStepMap(inst)
    gf.app.store.setData(stepMap.resourceName, stepMap.resourceId, null)
  }

  function run(inst) {
    const method = {
      add: runAdd,
      replace: runReplace,
      remove: runRemove,
    }[inst.op]

    return method.call(this, inst)
  }

  function getRelativeInstruction(inst) {
    return inst.context && inst.context.related_instruction
  }

  function getImpliedInstruction(inst) {
    return inst.context && inst.context.implied_instruction
  }

  function getResourceTypeFlags(inst, flags, step) {
    return {
      forRole: step === 'roles' && !flags.forAssignment,
      forCircle: step === 'circles',
      forPolicy: step === 'policies',
    }
  }

  function getOpTestFlags(inst, flags) {
    const f = flags
    const action = f.forRole ? 'role_edit'
      : f.forCircle ? 'circle_restructure'
        : f.forAssignment ? 'role_elect'
          : f.forPolicy ? 'policy_edit'
            : util.error('Action not found for instruction ', inst)

    return {
      action,
    }
  }

  function getOpReplaceFlags(inst, flags) {
    const f = flags
    const action = f.forRoleElect ? 'role_elect'
      : f.forRole ? 'role_edit'
        : f.forPolicy ? 'policy_edit'
          : util.error('Action not found for instruction ', inst)

    return {
      action,
    }
  }

  function getOpAddFlags(inst, flags) {
    const f = flags
    const steps = f.pathSteps
    const isStepCount2 = steps.length === 2
    const isRoleAdd = f.forRole && isStepCount2
    const isPolicyAdd = f.forPolicy && isStepCount2
    const isRoleExpand = f.forSupportingCircle
    const relInst = getRelativeInstruction(inst)

    const relFlags = relInst
      ? getFlags(relInst)
      : null

    const forRoleAdd = (f.forRoleSubresource || f.forSupportingCircle)
      ? (relInst && relFlags.isRoleAdd)
      : f.forRole

    const action = forRoleAdd ? 'role_add'
      : f.forRole ? 'role_edit'
        : f.forPolicy ? 'policy_add'
          : util.error('Action not found for instruction ', inst)

    return {
      action,
      isRoleAdd,
      isPolicyAdd,
      isRoleExpand,
      forRoleAdd,
    }
  }

  function getOpMoveFlags(inst, flags) {
    let f = flags
    const steps = f.pathSteps
    const relInst = getRelativeInstruction(inst)

    const relFlags = relInst
      ? getFlags(relInst)
      : null

    const forExpandCollapse = relInst && !relFlags.opTest
    const forCircleRestructure = relInst && relFlags.opTest

    const fromSteps = Path.fromString(inst.from)
    const isMoveUp = fromSteps && fromSteps.length > steps.length
    const longerSteps = isMoveUp ? fromSteps : steps
    const relSteps = relInst && Path.fromString(relInst.path)

    const sourceSteps = (relInst && !relFlags.opTest)
      ? Path.fromString(relInst.path)
      : longerSteps

    const moveToFromObjectId = longerSteps[1]
    const moveSpecificTypeFlags = getResourceTypeFlags(inst, flags, sourceSteps[0])

    f = _.extend(f, moveSpecificTypeFlags)

    const movingType = steps[steps.length - 2]
    const movingId = f.lastStep

    const roleId = relSteps && relSteps[0] === 'roles' ? relSteps[1]
      : longerSteps[0] === 'roles' ? longerSteps[1]
        : null

    const circleId = relSteps && relSteps[0] === 'circles' ? relSteps[1]
      : longerSteps[0] === 'circles' ? longerSteps[1]
        : null

    const objectId = forCircleRestructure
      ? circleId
      : roleId

    const forRoleAdd = relInst && relFlags.isRoleAdd

    const action = forCircleRestructure ? 'circle_restructure'
      : forRoleAdd ? 'role_add'
        : f.forRole ? 'role_edit' // remove case also falls under edit
          : util.error('Action not found for instruction ', inst)

    return _.extend(moveSpecificTypeFlags, {
      action,
      isMoveUp,
      forExpandCollapse,
      forCircleRestructure,
      moveToFromCircleId: moveToFromObjectId,
      movingType,
      movingId,
      isMovingPolicy: movingType === 'policies',
      isMovingRole: movingType === 'roles',
      fromSteps,
      circleId,
      roleId,
      objectId,
    })
  }

  function getOpRemoveFlags(inst, flags) {
    const f = flags
    const implInst = getImpliedInstruction(inst)
    const implflags = implInst && getFlags(implInst)
    const isStepCount2 = f.pathSteps.length === 2
    const isRoleCollapse = f.forRole && f.forSupportingCircle
    const isCircleRemove = f.forRole && isStepCount2 && implInst && implflags.isRoleCollapse
    const isRoleRemove = f.forRole && isStepCount2 && !isCircleRemove

    const relInst = getRelativeInstruction(inst)
    const relFlags = relInst
      ? getFlags(relInst)
      : null

    const forRoleAdd = relInst && relFlags.opAdd

    const action = forRoleAdd ? 'role_add'
      : f.forRole ? 'role_edit'
        : f.forPolicy ? 'policy_edit'
          : util.error('Action not found for instruction ', inst)

    return {
      action,
      isCircleRemove,
      isRoleCollapse,
      forRoleAdd,
      isRoleRemove,
    }
  }

  function _getFlags(inst) {
    const steps = Path.fromString(inst.path)
    const forAssignment = !!inst.path.match(/assignment/)
    const forSupportingCircle = steps.length === 3 && steps[2] === 'supporting_circle'
    const forDomain = inst.path.match(/domain/)
    const forAccountability = inst.path.match(/accountabilities/)
    let flags = {
      opAdd: inst.op === 'add',
      opMove: inst.op === 'move',
      opRemove: inst.op === 'remove',
      opReplace: inst.op === 'replace',
      opTest: inst.op === 'test',

      forAssignment,
      forRoleElect: forAssignment,
      forSupportingCircle,
      forDomain,
      forAccountability,
      forRoleSubresource: forDomain || forAccountability,
      roleId: steps[2],

      pathSteps: steps,
      lastStep: _.last(steps),
      objectId: steps[1],
    }

    flags = _.extend(flags, getResourceTypeFlags(inst, flags, steps[0]))

    const typeSpecificFlags
      = flags.opTest ? getOpTestFlags(inst, flags)
        : flags.opAdd ? getOpAddFlags(inst, flags)
          : flags.opMove ? getOpMoveFlags(inst, flags)
            : flags.opReplace ? getOpReplaceFlags(inst, flags)
              : flags.opRemove ? getOpRemoveFlags(inst, flags)
                : util.error('invalid op for instruction: ', inst, flags)
    flags = _.extend(flags, typeSpecificFlags)

    const partKey = ['part', flags.action, flags.objectId].join('-')
    flags = _.extend(flags, {
      partKey,
    })

    return flags
  }

  function getCacheKey(inst) {
    const related = inst.context ? inst.context.related_instruction : null
    const relatedString = related
      ? ['rel', related.op, related.path].join(':')
      : ''

    return [inst.op, inst.from, inst.path, inst.value, relatedString].join(';')
  }

  const getFlags = _.memoize(_getFlags, getCacheKey)

  function getPartKey(inst) {
    util.assert(inst)
    return getFlags(inst).partKey
  }

  function noConflicts(inst, currentValue) {
    const originalValue = inst.original_value
    const flags = this.getFlags(inst)
    if (flags.opMove)
      return true

    if (
      flags.opAdd && _.isEmpty(currentValue)
      || flags.opRemove && _.isEmpty(originalValue)
      || currentValue == originalValue
    )
      return true

    return false
  }

  return {
    type: types,
    getFlags,
    getPartKey,
    getModel,
    run,
    filterCancellingAddRemoveInstructions,
    noConflicts,
  }
}())

export default Instruction
