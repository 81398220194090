import React, {lazy, Suspense} from 'react'

const GraphqlExplorer = lazy(() => import(/* webpackChunkName: "GraphqlExplorer" */ './index'))

function LazyGraphqlExplorer(props) {
  return (
    <Suspense fallback={null}>
      <GraphqlExplorer {...props} />
    </Suspense>
  )
}

export default LazyGraphqlExplorer
