/**
 * @flow
 * @relayHash 324685fbe0d2cda7325c870cd8c5054c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CombinedMeetingEventSubscriptionVariables = {|
  meetingDatabaseId: string
|};
export type CombinedMeetingEventSubscriptionResponse = {|
  +combinedMeetingEvent: ?{|
    +person?: {|
      +id: string
    |},
    +type: "AgendaItemChanged",
    +meeting?: {|
      +currentAgendaItem: ?{|
        +id: string,
        +databaseId: string,
      |}
    |},
    +agendaItem?: {|
      +isCompleted: boolean
    |},
  |}
|};
export type CombinedMeetingEventSubscription = {|
  variables: CombinedMeetingEventSubscriptionVariables,
  response: CombinedMeetingEventSubscriptionResponse,
|};
*/


/*
subscription CombinedMeetingEventSubscription(
  $meetingDatabaseId: String!
) {
  combinedMeetingEvent(meetingDatabaseId: $meetingDatabaseId) {
    __typename
    ... on AgendaItemChanged {
      person {
        id
      }
      type: __typename
    }
    ... on AgendaItemAdded {
      type: __typename
    }
    ... on CurrentAgendaItemChanged {
      type: __typename
      meeting {
        __typename
        currentAgendaItem {
          id
          databaseId
        }
        id
      }
    }
    ... on AgendaItemUpdated {
      type: __typename
    }
    ... on AgendaItemCompleted {
      agendaItem {
        isCompleted
        id
      }
    }
    ... on InviteesUpdatedEvent {
      type: __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "meetingDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "meetingDatabaseId",
    "variableName": "meetingDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "type": "AgendaItemChanged",
  "abstractKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "AgendaItemAdded",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AgendaItem",
  "kind": "LinkedField",
  "name": "currentAgendaItem",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "AgendaItemUpdated",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCompleted",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "InviteesUpdatedEvent",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CombinedMeetingEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "combinedMeetingEvent",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "meeting",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CurrentAgendaItemChanged",
            "abstractKey": null
          },
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgendaItem",
                "kind": "LinkedField",
                "name": "agendaItem",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AgendaItemCompleted",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CombinedMeetingEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "combinedMeetingEvent",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "meeting",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CurrentAgendaItemChanged",
            "abstractKey": null
          },
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgendaItem",
                "kind": "LinkedField",
                "name": "agendaItem",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AgendaItemCompleted",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "324685fbe0d2cda7325c870cd8c5054c",
    "metadata": {},
    "name": "CombinedMeetingEventSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c1fd2252df0ec80846adb53461347f8';

module.exports = node;
