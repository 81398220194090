// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdatePersonInput as Input,
  UpdatePersonMutationResponse as Response,
} from './__generated__/UpdatePersonMutation.graphql'

const mutation = graphql`
  mutation UpdatePersonMutation($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        ...AboutTab_person
      }
    }
  }
`

const UpdatePersonMutation = (input: MutationInput<Input>): Promise<Response> => {
  const variables = {
    input,
  }

  return createMutationPromise({mutation, variables})
}

export default UpdatePersonMutation
