// @flow
import type {Element} from 'react'
import React, {useEffect, useRef} from 'react'
import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'

type Props = $ReadOnly<{
  actionId: ?string,
  editing: boolean,
  startEditing: () => void,
  finishEditing: () => void,
  value: string,
  placeholder: string,
  commitChange: (string) => void,
  activeClassName: string,
  validate: (string) => boolean,
  onKeyPress: ?(KeyboardEvent) => void,
}>

function InlineEdit({
  editing,
  startEditing,
  finishEditing,
  value,
  placeholder,
  commitChange,
  validate,
  activeClassName,
  onKeyPress,
}: Props): Element<"div"> {
  const inputRef = useRef<?HTMLInputElement>(null)

  const finishEditingAndCommit = () => {
    const inputValue = inputRef.current?.value

    if (inputValue && validate(inputValue) && value !== inputValue)
      commitChange(inputValue)

    finishEditing()
  }

  const onKeyDown = (event) => {
    const {key} = event

    if (key === 'Enter' || key === 'Tab')
      finishEditingAndCommit()

    if (key === 'Escape')
      finishEditing()

    if (onKeyPress)
      onKeyPress(event)
  }

  const focusOnInput = () => {
    const input = inputRef.current

    if (!input)
      return

    input.focus()
    input.setSelectionRange(input.value.length, input.value.length)
    input.scrollLeft = input.scrollWidth
  }

  useEffect(() => {
    if (!editing || !inputRef.current)
      return

    inputRef.current.value = value
    focusOnInput()
  }, [value, editing])

  return (
    <div>
      {editing
        ? (
          <input
            onKeyDown={onKeyDown}
            onBlur={finishEditingAndCommit}
            className={activeClassName}
            defaultValue={value}
            name="action-description"
            ref={inputRef}
          />
        )
        : (
          <span
            role="button"
            tabIndex={-1}
            test-id="action-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: placeholder}}
            {...accessibleOnClickProps(startEditing)}
          />
        )
      }
    </div>
  )
}

export default InlineEdit
