/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectsGroupsOrGrid_projects$ref = any;
type UpdateProjectModal_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileProjectsView_person$ref: FragmentReference;
declare export opaque type ProfileProjectsView_person$fragmentType: ProfileProjectsView_person$ref;
export type ProfileProjectsView_person = {|
  +id: string,
  +databaseId: string,
  +viewerCanUpdate: boolean,
  +projects: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: UpdateProjectModal_project$ref & ProjectsGroupsOrGrid_projects$ref,
      |}
    |}>
  |},
  +$refType: ProfileProjectsView_person$ref,
|};
export type ProfileProjectsView_person$data = ProfileProjectsView_person;
export type ProfileProjectsView_person$key = {
  +$data?: ProfileProjectsView_person$data,
  +$fragmentRefs: ProfileProjectsView_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileProjectsView_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 250
        },
        {
          "kind": "Literal",
          "name": "includeUnfilledAsCircleLead",
          "value": true
        }
      ],
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UpdateProjectModal_project"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectsGroupsOrGrid_projects"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(first:250,includeUnfilledAsCircleLead:true)"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fec894720d24703b881a5174a98d92b2';

module.exports = node;
