/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectsStatusColumn_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsGrid_circle$ref: FragmentReference;
declare export opaque type ProjectsGrid_circle$fragmentType: ProjectsGrid_circle$ref;
export type ProjectsGrid_circle = {|
  +$fragmentRefs: ProjectsStatusColumn_circle$ref,
  +$refType: ProjectsGrid_circle$ref,
|};
export type ProjectsGrid_circle$data = ProjectsGrid_circle;
export type ProjectsGrid_circle$key = {
  +$data?: ProjectsGrid_circle$data,
  +$fragmentRefs: ProjectsGrid_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsGrid_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectsStatusColumn_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '625cfebedc6f0c2dd3ff9f269f908845';

module.exports = node;
