// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'

import Heading from 'components/ui/Heading'
import Add from 'components/ui/icons/md/Add'
import IconButton from 'components/ui/IconButton'
import styles from './index.scss'

type Props = $ReadOnly<{
  addButtonCaption: string,
  openCreator: ?() => void,
  renderMiddleSection: ?() => Node[],
  addTestId: ?string,
}>

function TacticalMeetingHeader({
  openCreator,
  addButtonCaption,
  renderMiddleSection,
  addTestId,
}: Props): Node {
  const rightButton = useCallback(() => (
    openCreator
      ? (
        <IconButton
          test-id={addTestId}
          className={styles.addButton}
          onClick={openCreator}
          buttonText={addButtonCaption}
          icon={<Add />}
        />
      )
      : null
  ), [openCreator, addButtonCaption, addTestId])

  return (
    <Heading
      spacing="xSmall"
      underlined
      middleSection={renderMiddleSection}
      rightButton={rightButton}
    />
  )
}

TacticalMeetingHeader.defaultProps = {
  openCreator: null,
  renderMiddleSection: null,
  addTestId: null,
}

export default TacticalMeetingHeader
