/**
 * @flow
 * @relayHash 49b8024b52cbf1bc088d81dffe13fe43
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RulesSections_organization$ref = any;
type Section_rule$ref = any;
export type RuleValue = "CENTRALIZED" | "CUSTOM" | "EXISTING" | "HOLACRACY" | "MODULAR_ADOPTION_BY_CIRCLE" | "UNIVERSAL";
export type SetOrganizationRuleInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  organizationId: string,
  rule: OrganizationRuleInputObject,
  constitutionRatifiers?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type OrganizationRuleInputObject = {|
  ruleKey: string,
  ruleValue: RuleValue,
  customText?: ?string,
|};
export type SetOrganizationRuleMutationVariables = {|
  input: SetOrganizationRuleInput
|};
export type SetOrganizationRuleMutationResponse = {|
  +setOrganizationRule: ?{|
    +organization: {|
      +$fragmentRefs: RulesSections_organization$ref
    |},
    +rule: {|
      +$fragmentRefs: Section_rule$ref
    |},
  |}
|};
export type SetOrganizationRuleMutation = {|
  variables: SetOrganizationRuleMutationVariables,
  response: SetOrganizationRuleMutationResponse,
|};
*/


/*
mutation SetOrganizationRuleMutation(
  $input: SetOrganizationRuleInput!
) {
  setOrganizationRule(input: $input) {
    organization {
      ...RulesSections_organization
      id
    }
    rule {
      ...Section_rule
      id
    }
  }
}

fragment CustomGovernanceCircles_circles on Circle {
  id
  localizedName
  resourcePath
  isGovernanceEnabled
}

fragment RulesSections_organization on Organization {
  name
  constitutionRatifiers
  onRestrictedPlan
  isModularAdoptionByCircleEnabled
  circles {
    edges {
      node {
        isGovernanceEnabled
        ...CustomGovernanceCircles_circles
        id
      }
    }
  }
  rules {
    ruleKey
    ruleValue
    ...Section_rule
    id
  }
  viewer {
    routes {
      constitutionUrl
      articleTargets {
        article1
        article2
        article3
        article4
        article5
      }
    }
    id
  }
  ...Section_organization
}

fragment SectionBody_organization on Organization {
  constitutionRatifiers
  ...WYSIWYGViewerWithFeatureFlag_organization
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment SectionHeader_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment Section_organization on Organization {
  id
  constitutionRatifiers
  ...SectionBody_organization
  ...SectionHeader_organization
}

fragment Section_rule on Rule {
  id
  title
  frogTip
  ruleKey
  ruleValue
  centralizedText
  universalText
  viewerCanUpdate
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleKey",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frogTip",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "centralizedText",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universalText",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetOrganizationRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationRulePayload",
        "kind": "LinkedField",
        "name": "setOrganizationRule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RulesSections_organization"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rule",
            "kind": "LinkedField",
            "name": "rule",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Section_rule"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetOrganizationRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationRulePayload",
        "kind": "LinkedField",
        "name": "setOrganizationRule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constitutionRatifiers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onRestrictedPlan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isModularAdoptionByCircleEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGovernanceEnabled",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "resourcePath",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Rule",
                "kind": "LinkedField",
                "name": "rules",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Routes",
                    "kind": "LinkedField",
                    "name": "routes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "constitutionUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConstitutionArticleTargets",
                        "kind": "LinkedField",
                        "name": "articleTargets",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article3",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article4",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "article5",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isWysiwygEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rule",
            "kind": "LinkedField",
            "name": "rule",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "49b8024b52cbf1bc088d81dffe13fe43",
    "metadata": {},
    "name": "SetOrganizationRuleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bcf483ae6fe040d7be1325804bb6316';

module.exports = node;
