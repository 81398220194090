/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonChecklistItem_item$ref: FragmentReference;
declare export opaque type PersonChecklistItem_item$fragmentType: PersonChecklistItem_item$ref;
export type PersonChecklistItem_item = {|
  +id: string,
  +description: string,
  +link: ?any,
  +localizedRoleName: string,
  +isGlobal: boolean,
  +privateToCircle: boolean,
  +viewerCanSeeEditIcon: boolean,
  +circle: ?{|
    +id: string,
    +databaseId: string,
    +localizedName: string,
    +supportedRole: ?{|
      +isDisplayedAsCircle: boolean
    |},
    +organization: {|
      +databaseId: string
    |},
  |},
  +$refType: PersonChecklistItem_item$ref,
|};
export type PersonChecklistItem_item$data = PersonChecklistItem_item;
export type PersonChecklistItem_item$key = {
  +$data?: PersonChecklistItem_item$data,
  +$fragmentRefs: PersonChecklistItem_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonChecklistItem_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedRoleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateToCircle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanSeeEditIcon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localizedName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDisplayedAsCircle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChecklistItem",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b992e7a8172ce79776e1650176c94a7c';

module.exports = node;
