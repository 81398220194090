// @flow
import type {Node, Element} from 'react'
import React from 'react'
import {useContainerQuery} from 'react-container-query'
import classNames from 'classnames'

import AppLink from 'components/ui/AppLink'
import FaIcon from 'components/ui/icons/FaIcon'
import InlineMarkdown from 'components/ui/InlineMarkdown'
import {widthMinGrid} from 'styles/variables-breakpoints.scss'
import styles from './index.scss'

type Children = $ReadOnly<{
  className: ?string,
  children: Node,
}>

function SuggestionSection({className, children}: Children): Element<"div"> {
  return (
    <div className={classNames(styles.suggestionSection, className)}>
      {children}
    </div>
  )
}

SuggestionSection.defaultProps = {className: null}

function SuggestionRow({className, children}: Children): Element<"div"> {
  const [containerClasses, containerRef] = useContainerQuery({
    [styles.suggestionRowSmall]: {
      maxWidth: widthMinGrid,
    },
  })

  const rowClasses = classNames(styles.suggestionRow, containerClasses, className)

  return (
    <div className={rowClasses} ref={containerRef}>
      {children}
    </div>
  )
}

SuggestionRow.defaultProps = {className: null}

function Suggestions({className, children}: Children): Element<"div"> {
  return (
    <div className={classNames(styles.suggestions, className)}>
      {children}
    </div>
  )
}

Suggestions.defaultProps = {className: null}

function Suggestion({text, children}: {text: string, children?: Object}): Element<"div"> {
  return (
    <div className={styles.suggestion}>
      <InlineMarkdown text={text} />
      {children}
    </div>
  )
}

Suggestion.defaultProps = {children: null}

function Actions({className, children}: Children): Element<"div"> {
  return (
    <div className={classNames(styles.actions, className)}>
      {children}
    </div>
  )
}

Actions.defaultProps = {className: null}

function Arrow({className}: $ReadOnly<{className: ?string}>): Node {
  return <FaIcon icon="arrow-right" className={classNames(styles.arrow, className)} />
}

Arrow.defaultProps = {className: null}

function Action({title, onClick}: { title: Node, onClick: ?() => Promise<void> }): Element<"div"> {
  return (
    <div className={styles.action}>
      <button
        className={classNames(styles.button, 'btn', 'btn-primary', 'btn-md')}
        disabled={!onClick}
        onClick={onClick}
        type="button"
      >
        {title}
      </button>
    </div>
  )
}

function ActionLink({title, href}: { title: Node, href: string }): Element<"div"> {
  return (
    <div className={styles.action}>
      <AppLink className={styles.actionLink} href={href}>
        {title}
      </AppLink>
    </div>
  )
}

function Hint({children, done, dashed}: {children: Node, dashed?: boolean, done?: boolean}): Element<"div"> {
  return (
    <div className={classNames(styles.hint, {[styles.doneHint]: done})}>
      {dashed && '- '}
      {children}
    </div>
  )
}

Hint.defaultProps = {
  done: false,
  dashed: true,
}

export {
  SuggestionSection,
  SuggestionRow,
  Suggestions,
  Suggestion,
  Arrow,
  Actions,
  ActionLink,
  Action,
  Hint,
}
