// @flow
import React, {forwardRef, type Node} from 'react'
import classNames from 'classnames'
import GFIcon from 'components/ui/icons/GFIcon'
import styles from './index.scss'

type Props = $ReadOnly<{
  children: Node,
  withBottomSpacing: boolean,
}>

function FrogBoxWithoutForwardRef({
  children,
  withBottomSpacing,
}: Props, ref) {
  const wrapper = classNames(styles.frogBox, {
    [styles.marginBottom]: withBottomSpacing,
  })

  return (
    <div
      className={wrapper}
      ref={ref}
      tabIndex={-1}
    >
      <div className={styles.frogIcon}>
        <GFIcon />
      </div>
      <div className={styles.frogContent}>
        {children}
      </div>
    </div>
  )
}

const FrogBox: React$AbstractComponent<Props, HTMLElement> = forwardRef<Props, HTMLElement>(FrogBoxWithoutForwardRef)

// $FlowFixMe[prop-missing]
FrogBox.defaultProps = {
  withBottomSpacing: false,
}

export default FrogBox
