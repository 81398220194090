// @flow
import type {Element} from 'react'
import React from 'react'
import getStatusText from 'utils/projects/getStatusText'
import classNames from 'classnames'
import styles from './index.scss'

type Props = $ReadOnly<{
  status: string,
}>

const statuses = ['current', 'future', 'waiting', 'done']

function StatusBadge({status}: Props): null | Element<"div"> {
  if (!statuses.includes(status))
    return null

  const className = classNames(styles.badge, styles[status])

  return (
    <div className={className}>
      {getStatusText(status)}
    </div>
  )
}

export default StatusBadge
