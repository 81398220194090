// @flow
import type {Element, Node} from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './index.scss'

type Size = 'sm' | 'md' | 'lg'

type Props = $ReadOnly<{
  size: Size,
  children: Node,
}>

function IconContainer({size, children}: Props): Element<"div"> {
  const iconClass = classNames(
    styles.sidebarIconContainer,
    'sidebar-icon-container',
    styles[size],
  )
  return (
    <div className={iconClass}>
      {children}
    </div>
  )
}

export default IconContainer
