// @flow

import React, {useCallback, useEffect, useState, type Node} from 'react'
import classNames from 'classnames'
import jsonApiFetch from 'utils/jsonApiFetch'
import {RelayEnvironmentProvider} from 'react-relay'
import OrganizationContextProviderQuery
  from 'contexts/OrganizationContextProviderQuery'
import environment from 'environment'
import AiChatContextProvider from 'contexts/AiChatContextProvider'
import ChatsArea from '../ChatsArea'
import MessagesArea from '../MessagesArea'
import MessageInput from '../MessageInput'
import styles from './index.scss'

type Props = {
  chatsPath: string,
  messagesPath: string,
  superUser: boolean,
  close: (boolean) => null,
  organizationId: string,
};

function ChatWindow({
  chatsPath,
  messagesPath,
  superUser,
  close,
  organizationId,
}: Props): Node {
  const [chatId, setChatId] = useState(null)
  const [chats, setChats] = useState([])
  const [debugModeSelected, setDebugModeSelected] = useState(false)
  const [messages, setMessages] = useState([])
  const [ui, setUi] = useState({})
  const [isMaximized, setIsMaximized] = useState(false)

  const newChat = async () => {
    setChatId(null)
    setMessages([])
  }

  const switchChats = useCallback(
    async (newChatId) => {
      setChatId(newChatId)
      const messageIndex = `${messagesPath}?ai_chat_id=${newChatId}`
      const data = await jsonApiFetch('GET', messageIndex)
      if (data.messages)
        setMessages(data.messages)
    },
    [messagesPath, setMessages, setChatId],
  )

  const getChats = useCallback(async () => {
    const data = await jsonApiFetch('GET', chatsPath)

    setChats(data.chats)
    setUi(data.setup)

    if (data.chats.length > 0)
      await switchChats(data.chats[0].id)
  }, [switchChats, chatsPath])

  const addMessages = useCallback(
    (newMessages) => setMessages([...messages, ...newMessages]),
    [messages],
  )

  const postNewUserMessage = useCallback(async (input) => {
    const data = await jsonApiFetch('POST', messagesPath, {
      ai_chat_id: chatId,
      message: {content: input},
    })

    setMessages(data.messages)

    // Refresh on new chat
    if (!chats.map((chat) => chat.id).includes(chatId))
      await getChats()
  }, [messagesPath, chatId, chats, getChats])

  useEffect(() => {
    getChats()
  }, [getChats])

  const closeButton = () => (
    <button type="button" onClick={close} test-id="close-chat-button">
      <i className="fa fa-times" />
    </button>
  )
  const minMaxToggleButton = () => (
    <button
      onClick={() => {
        setIsMaximized(!isMaximized)
      }}
      type="button"
      test-id="toggle-min-max-button"
    >
      <i
        className={`fa ${isMaximized
          ? 'fa-chevron-down'
          : 'fa-chevron-up'}`}
      />
    </button>
  )

  const windowClasses = classNames(
    'd-flex flex-column',
    styles.chatDialog,
    {
      [styles.maximized]: isMaximized,
    },
  )

  return (
    <RelayEnvironmentProvider environment={environment}>
      <OrganizationContextProviderQuery orgDatabaseId={organizationId}>
        <AiChatContextProvider
          chatId={chatId}
          messages={messages}
          setMessages={setMessages}
        >
          <div className={windowClasses} test-id="chat-window">
            <div className={styles.header}>
              <h3>{'FrogBot'}</h3>
              <div className={styles.headerButtons}>
                {minMaxToggleButton()}
                {closeButton()}
              </div>
            </div>
            <div className={classNames('d-flex flex-row', styles.dialogBody)}>
              <ChatsArea
                chatId={chatId}
                newChat={newChat}
                chats={chats}
                switchChats={switchChats}
              />
              <div className={styles.messagesColumn}>
                <MessagesArea
                  messages={messages}
                  ui={ui}
                  debugMode={debugModeSelected}
                  organizationId={organizationId}
                  messagesPath={messagesPath}
                />
                <MessageInput
                  superUser={superUser}
                  debugModeSelected={debugModeSelected}
                  setDebugModeSelected={setDebugModeSelected}
                  postNewUserMessage={postNewUserMessage}
                  addMessages={addMessages}
                />
              </div>
            </div>
          </div>
        </AiChatContextProvider>
      </OrganizationContextProviderQuery>
    </RelayEnvironmentProvider>
  )
}

export default ChatWindow
