/**
 * @flow
 * @relayHash 8a3a552c34711917f42484a1fee4a3c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActionsList_actions$ref = any;
export type UpdateActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  actionId: string,
  circleId: string,
  parentGoalOrTarget?: ?string,
  projectId?: ?string,
  roleId?: ?string,
  personId: string,
  description: string,
  note?: ?string,
  privateToCircle: boolean,
  tagNames?: ?$ReadOnlyArray<string>,
  trigger: boolean,
  triggerEvent?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UpdateActionMutationVariables = {|
  input: UpdateActionInput
|};
export type UpdateActionMutationResponse = {|
  +updateAction: ?{|
    +action: ?{|
      +$fragmentRefs: ActionsList_actions$ref
    |}
  |}
|};
export type UpdateActionMutation = {|
  variables: UpdateActionMutationVariables,
  response: UpdateActionMutationResponse,
|};
*/


/*
mutation UpdateActionMutation(
  $input: UpdateActionInput!
) {
  updateAction(input: $input) {
    action {
      ...ActionsList_actions
      id
    }
  }
}

fragment ActionDetails_action on Action {
  databaseId
  createdAt
  completedAt
  privateToCircle
  note
  role {
    localizedName
    id
  }
  circle {
    localizedName
    id
  }
  project {
    description
    id
  }
}

fragment ActionEditForm_action on Action {
  circle {
    id
    ...CircleSelector_circles
  }
  role {
    ...WithCurrentRoleSelector_role
    id
  }
}

fragment ActionFormModalView_action on Action {
  ...ActionEditForm_action
}

fragment ActionFormModal_action on Action {
  ...ActionFormModalView_action
}

fragment Action_action on Action {
  description(format: MARKDOWN)
  trigger
  triggerEvent
  ...ActionDetails_action
}

fragment ActionsList_actions on Action {
  id
  ...Action_action
  ...CompletionCheckbox_action
  ...UpdateActionModal_action
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment CompletionCheckbox_action on Action {
  id
  completedAt
  actionType
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment UpdateActionModal_action on Action {
  id
  rawDescription: description(format: RAW)
  mdDescription: description(format: MARKDOWN)
  note
  trigger
  triggerEvent
  parentGoalOrTarget
  privateToCircle
  project {
    id
  }
  circle {
    id
    organization {
      isWysiwygEnabled
      id
    }
  }
  role {
    id
  }
  ...ActionFormModal_action
}

fragment WithCurrentRoleSelector_role on Role {
  id
  circle {
    supportedRole {
      isDisplayedAsCircle
      id
    }
    id
  }
  ...RoleSelector_roles_4EQjyB
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MARKDOWN"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActionPayload",
        "kind": "LinkedField",
        "name": "updateAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActionsList_actions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActionPayload",
        "kind": "LinkedField",
        "name": "updateAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"MARKDOWN\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trigger",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "triggerEvent",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateToCircle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Circle",
                    "kind": "LinkedField",
                    "name": "circle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "supportedRole",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFocus",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "governanceEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": "nameWithMode",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "with",
                        "value": "CIRCLE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "localizedName",
                    "storageKey": "localizedName(with:\"CIRCLE\")"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Circle",
                "kind": "LinkedField",
                "name": "circle",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isWysiwygEnabled",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGovernanceEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "supportedRole",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actionType",
                "storageKey": null
              },
              {
                "alias": "rawDescription",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "RAW"
                  }
                ],
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"RAW\")"
              },
              {
                "alias": "mdDescription",
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "description",
                "storageKey": "description(format:\"MARKDOWN\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentGoalOrTarget",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8a3a552c34711917f42484a1fee4a3c2",
    "metadata": {},
    "name": "UpdateActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36b86816f6a4f3c7b183a4066340fa59';

module.exports = node;
