// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import {Form, FormikProvider, useFormik, Field} from 'formik'
import Modal from 'components/ui/Modal'
import Input from 'components/ui/forms/Input'
import {type Item} from 'components/ui/forms/RemovableItem'
import RemovableItems from 'components/ui/forms/RemovableItems'
import style from './style.scss'
import type {
  RoleTemplateFormModal_organization$key as Organization,
} from './__generated__/RoleTemplateFormModal_organization.graphql'

type Values = $ReadOnly<{
  name: string,
  purpose: string,
  domains: $ReadOnlyArray<string>,
  accountabilities: $ReadOnlyArray<string>,
}>

type IntermediateValues = $ReadOnly<{
  name: string,
  purpose: string,
  domains: $ReadOnlyArray<Item>,
  accountabilities: $ReadOnlyArray<Item>,
}>

type Props<Response> = $ReadOnly<{
  modalTitle: string,
  initialValues: Values,
  organization: Organization,
  close: () => void,
  submit: (values: Values) => Promise<Response>,
  renderLeftFooterButton: (boolean) => Node,
}>

const mapNameToItem = (name: string, index: number) => ({
  id: index.toString(),
  value: name,
  isNew: false,
  destroy: false,
  removable: true,
})

const getName = ({value}: Item) => value
const notDeleted = ({destroy}: Item) => !destroy

const organizationFragment = graphql`
  fragment RoleTemplateFormModal_organization on Organization {
    ...RemovableItems_organization
  }
`

function RoleTemplateFormModal<T>({
  organization: organizationKey,
  modalTitle,
  initialValues,
  close,
  submit,
  renderLeftFooterButton,
}: Props<T>): Node {
  const organization = useFragment(organizationFragment, organizationKey)

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      domains: initialValues.domains.map(mapNameToItem),
      accountabilities: initialValues.accountabilities.map(mapNameToItem),
    },
    validate: (values: IntermediateValues) => {
      const errors = {}

      if (!values.name)
        errors.name = I18n.t('errors.messages.required')

      return errors
    },
    onSubmit: async (values: IntermediateValues) => {
      await submit({
        ...values,
        domains: values.domains.filter(notDeleted).map(getName).filter(Boolean),
        accountabilities: values.accountabilities.filter(notDeleted).map(getName).filter(Boolean),
      })

      close()
    },
  })

  return (
    <FormikProvider value={formik}>
      <Modal
        close={close}
        size="lg"
        test-id="role-template-modal"
      >
        <Form>
          <Modal.Header
            title={modalTitle}
            close={close}
          />
          <Modal.Body>
            <Field
              as={Input}
              name="name"
              label={I18n.t('forms.name_label')}
              warning={formik.errors.name}
            />
            <Field
              as={Input}
              name="purpose"
              label={I18n.t('role.purpose')}
            />
            <div className={style.itemsContainer}>
              <div className="label">{I18n.t('role.domains')}</div>
              <RemovableItems
                name="domains"
                items={formik.values.domains}
                organization={organization}
                itemsErrors={null}
                buttonInline
              />
            </div>
            <div className={style.itemsContainer}>
              <div className="label">{I18n.t('role.role_accountabilities')}</div>
              <RemovableItems
                name="accountabilities"
                items={formik.values.accountabilities}
                organization={organization}
                itemsErrors={null}
                buttonInline
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            rightBlock={(
              <button
                className="btn btn-primary btn-lg"
                disabled={!formik.isValid || formik.isSubmitting}
                type="submit"
              >
                {I18n.t('shared.save')}
              </button>
            )}
            leftBlock={renderLeftFooterButton(formik.isSubmitting)}
          />
        </Form>
      </Modal>
    </FormikProvider>
  )
}

RoleTemplateFormModal.defaultProps = {
  renderLeftFooterButton: (): null => null,
}

export default RoleTemplateFormModal
