// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import {
  type ConfigureOrganizationIntegrationInput as Input,
} from './__generated__/ConfigureOrganizationIntegrationMutation.graphql'

const mutation = graphql`
  mutation ConfigureOrganizationIntegrationMutation($input: ConfigureOrganizationIntegrationInput!) {
    configureOrganizationIntegration(input: $input) {
      organization {
        ...ToggleJiraButton_organization
      }
    }
  }
`

const ConfigureOrganizationIntegrationMutation = (input: MutationInput<Input>): Promise<mixed> => (
  createMutationPromise({mutation, variables: {input}})
)

export default ConfigureOrganizationIntegrationMutation
