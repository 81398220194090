// @flow
import type {Element} from 'react'
import React, {useContext, Fragment, type Node} from 'react'
import classNames from 'classnames'
import ArrowUp from 'components/ui/icons/ios/ArrowUp'
import ArrowDown from 'components/ui/icons/ios/ArrowDown'
import styles from './index.scss'
import {CardContext} from '..'

type RenderProps = $ReadOnly<{
  isOpen: boolean,
  toggleOpen: () => void,
}>

type Props = $ReadOnly<{
  render?: (RenderProps) => Node,
  children?: Node,
  renderIcon?: (RenderProps) => Node,
  passive: boolean,
  disabled: boolean,
}>

const renderDefaultIcon = ({isOpen}: RenderProps) => (
  isOpen ? <ArrowUp /> : <ArrowDown />
)

function Header({render, children, renderIcon, passive, disabled}: Props): Element<"button"> | Element<"div"> {
  const {isOpen, toggleOpen} = useContext(CardContext)

  const renderParams = {isOpen, toggleOpen}

  const renderBody = (arrowsVisible) => (
    <Fragment>
      <div className={styles.childrenContainer}>
        {render ? render(renderParams) : children}
      </div>
      {arrowsVisible ? (
        <div className={classNames(styles.arrow, {[styles.arrowInteractive]: !passive})}>
          {renderIcon ? renderIcon(renderParams) : renderDefaultIcon(renderParams)}
        </div>
      ) : (
        <div className={styles.arrow} />
      )}
    </Fragment>
  )

  if (disabled || passive) {
    return (
      <div className={styles.header}>
        {renderBody(!disabled)}
      </div>
    )
  }

  return (
    <button
      className={classNames('btn-reset', styles.header, {[styles.selected]: isOpen})}
      type="button"
      onClick={toggleOpen}
    >
      {renderBody(true)}
    </button>
  )
}

Header.defaultProps = {
  passive: false,
  disabled: false,
}

export default Header
