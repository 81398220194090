/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DraggableProjectCard_circle$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsStatusColumn_circle$ref: FragmentReference;
declare export opaque type ProjectsStatusColumn_circle$fragmentType: ProjectsStatusColumn_circle$ref;
export type ProjectsStatusColumn_circle = {|
  +$fragmentRefs: DraggableProjectCard_circle$ref,
  +$refType: ProjectsStatusColumn_circle$ref,
|};
export type ProjectsStatusColumn_circle$data = ProjectsStatusColumn_circle;
export type ProjectsStatusColumn_circle$key = {
  +$data?: ProjectsStatusColumn_circle$data,
  +$fragmentRefs: ProjectsStatusColumn_circle$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsStatusColumn_circle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraggableProjectCard_circle"
    }
  ],
  "type": "Circle",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ad4356a26d3b6ad585b1f5f914e12b62';

module.exports = node;
