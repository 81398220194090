// @flow

function confirmTurnOffMessage(setupModeSuspendedInvitationsCount: number): null | string {
  if (setupModeSuspendedInvitationsCount === 0)
    return null

  return I18n.t(
    'admin.organizations.setup_mode.off.confirmation',
    {count: setupModeSuspendedInvitationsCount},
  )
}

export default confirmTurnOffMessage
