import {curry, pathOr, path, pathEq, over, lensProp, not, or, isNil, isEmpty, merge, compose, pathSatisfies} from 'ramda'

const setupNamespace = () => {
  if (!window.gf)
    window.gf = {} // poor man's namespace

  if (!window.GF)
    window.GF = {}

  gf.r = {
    relationshipData: curry((relationshipName, resource) => path(['links', relationshipName], resource)),
    relationshipDataSatisfies: curry((fn, relationshipName, resource) => {
      if (!pathOr(false, ['links', relationshipName], resource))
        return false

      return pathSatisfies(fn, ['links', relationshipName], resource)
    }),
    relationshipDataEq: curry((relationshipName, value, resource) => pathEq(['links', relationshipName], value, resource)),
    hasRelationship(relationshipName, resource) {
      return (resource.links || {}).hasOwnProperty(relationshipName)
    },
    overRelationshipData: over(lensProp('links')),
  }

  gf.r = merge(gf.r, {
    relationshipPresent: gf.r.relationshipDataSatisfies(
      compose(not, or(isNil, isEmpty)),
    ),
  })

  gf.r = merge(gf.r, {
    roleId: gf.r.relationshipData('role'),

    circleId: gf.r.relationshipData('circle'),

    personIdEq: gf.r.relationshipDataEq('person'),
    personIdsEq: gf.r.relationshipDataEq('people'),
    personIdsPresent: gf.r.relationshipPresent('people'),

    supportingCircleId: gf.r.relationshipData('supporting_circle'),
    supportingCircleIdEq: gf.r.relationshipDataEq('supporting_circle'),
    supportingCirclePresent: gf.r.relationshipPresent('supporting_circle'),

    tensionId: gf.r.relationshipData('tension'),
    proposalId: gf.r.relationshipData('proposal'),
  })

  gf.r = merge(gf.r, {
    // aliases
    isSupported: gf.r.supportingCirclePresent,
    isFilled: gf.r.personIdsPresent,
    // custom properties/lookups.  note these may require non-standard arguments
    // beyond just the resource itself
  })
}

const setup = () => {
  setupNamespace()
}

export default setup
