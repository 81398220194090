/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CircleRoleSelector_circle$ref = any;
type WYSIWYGTextareaWithFeatureFlag_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgendaTensionEditForm_meeting$ref: FragmentReference;
declare export opaque type AgendaTensionEditForm_meeting$fragmentType: AgendaTensionEditForm_meeting$ref;
export type AgendaTensionEditForm_meeting = {|
  +circle: {|
    +supportedRole: ?{|
      +id: string
    |},
    +organization: {|
      +databaseId: string,
      +$fragmentRefs: WYSIWYGTextareaWithFeatureFlag_organization$ref,
    |},
    +$fragmentRefs: CircleRoleSelector_circle$ref,
  |},
  +$refType: AgendaTensionEditForm_meeting$ref,
|};
export type AgendaTensionEditForm_meeting$data = AgendaTensionEditForm_meeting;
export type AgendaTensionEditForm_meeting$key = {
  +$data?: AgendaTensionEditForm_meeting$data,
  +$fragmentRefs: AgendaTensionEditForm_meeting$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgendaTensionEditForm_meeting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Circle",
      "kind": "LinkedField",
      "name": "circle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "supportedRole",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "databaseId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WYSIWYGTextareaWithFeatureFlag_organization"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CircleRoleSelector_circle"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
// prettier-ignore
(node/*: any*/).hash = '29b73ad337c70d2e00b56a3e25cce9ce';

module.exports = node;
