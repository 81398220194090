import $ from 'jquery'

$(() => {
  const autoFillIgnoreElements = document.querySelectorAll('[data-prevent-autofill]')

  Array.from(autoFillIgnoreElements).forEach((input) => {
    input.addEventListener('change', (event) => {
      if (!event.isTrusted) {
        requestAnimationFrame(() => {
          event.target.value = event.target.defaultValue
          event.preventDefault()
        })
      }
    })
  })
})
