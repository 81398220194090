// @flow
import type {Element} from 'react'
import React, {useCallback, useState, useEffect, useRef} from 'react'
import {graphql, useFragment} from 'react-relay'
import {DragDropContext} from 'react-beautiful-dnd'
import classNames from 'classnames'

import getStatusText from 'utils/projects/getStatusText'
import {type OpenEditor} from 'components/projects/Project'
import ProjectsStatusColumn, {
  type ProjectsBeforeFragment,
} from './ProjectsStatusColumn'
import useMaybeProjectUpdate from './useMaybeProjectUpdate'
import styles from './index.scss'
import {useToggle} from '../../../utils/hooks'
import type {
  ProjectsGrid_circle$key as CircleKey,
} from './__generated__/ProjectsGrid_circle.graphql'

type Group<Projects> = $ReadOnly<{
  title?: string,
  key: string,
  items: Projects,
}>

type Props<Projects> = $ReadOnly<{
  groups: $ReadOnlyArray<Group<Projects>>,
  openEditor: OpenEditor,
  stickyHeaderOffset: number,
  circle: ?CircleKey,
  hideAvatars: ?boolean,
  hideSource: ?boolean,
}>

const circleFragment = graphql`
  fragment ProjectsGrid_circle on Circle {
    ...ProjectsStatusColumn_circle
  }
`

const statuses = ['current', 'waiting', 'done', 'future']

// ProjectsGrid should be able to render multiple groups as they will be in
//  same DND context to be able to change project role/person by DND
function ProjectsGrid<Projects: ProjectsBeforeFragment>({
  groups,
  openEditor,
  stickyHeaderOffset,
  circle: circleKey,
  hideAvatars,
  hideSource,
}: Props<Projects>): Element<"div"> {
  const [currentDragGroup, setCurrentDragGroup] = useState(null)
  const [isDragInProgress, {open: startDrag, close: finishDrag}] = useToggle()
  const [currentDestinationId, setCurrentDestinationId] = useState<?string>(null)
  const [currentFromId, setCurrentFromId] = useState<?string>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  const circle = useFragment(circleFragment, circleKey)

  const elementToObserve: mixed & { current: Function } = useRef()
  useEffect(() => {
    if (!elementToObserve.current)
      return () => {}

    if (!window.IntersectionObserver)
      return () => {}

    const observer = new IntersectionObserver((entries) => {
      if (!entries[0])
        return

      setIsIntersecting(entries[0].isIntersecting)
    })
    observer.observe(elementToObserve.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  const onDestinationChange = useCallback((dragUpdate) => {
    setCurrentDestinationId(dragUpdate.destination?.droppableId)
  }, [setCurrentDestinationId])

  const onDragStart = useCallback((dragUpdate) => {
    setCurrentFromId(dragUpdate.source.droppableId)
  }, [setCurrentFromId])

  const onBeforeDragStart = (key) => () => {
    setCurrentDragGroup(key)
    startDrag()
  }

  const maybeUpdateProject = useMaybeProjectUpdate(
    setCurrentDestinationId,
    setCurrentFromId,
    finishDrag,
  )
  const headerClasses = classNames(styles.gridLayout, styles.header, {
    [styles.floatingHeader]: !isIntersecting,
  })

  const archiveAriaHidden = (key) => !isDragInProgress || currentDragGroup !== key
  const archiveAriaClassName = (key) => classNames(styles.archiveArea, {
    [styles.archiveAreaHidden]: archiveAriaHidden(key),
    [styles.archiveAreaShown]: !archiveAriaHidden(key),
  })

  const showEmptySpaceAfterGrid = groups.length > 0 && !archiveAriaHidden(groups[groups.length - 1].key)

  return (
    <div className={styles.container}>
      <div
        ref={elementToObserve}
        className={styles.fakeHeader}
        style={{top: -stickyHeaderOffset}}
      />
      <div className={headerClasses} style={{top: stickyHeaderOffset}}>
        {statuses.map((status) => (
          <div key={status} className={classNames(styles[status], styles.statusHeader)}>
            <div className={styles.statusDot} />
            <div>{getStatusText(status)}</div>
          </div>
        ))}
      </div>
      {groups.map(({title, key, items: projects}) => (
        <DragDropContext
          onBeforeCapture={onBeforeDragStart(key)}
          onDragUpdate={onDestinationChange}
          key={key}
          onDragEnd={maybeUpdateProject}
          onDragStart={onDragStart}
        >
          <section test-id={key} className={styles.bodySection}>
            {title && (
              <h3>{title}</h3>
            )}
            <div className={styles.gridLayout}>
              {statuses.map((status) => (
                <ProjectsStatusColumn
                  groupKey={key}
                  currentDestinationId={currentDestinationId}
                  currentFromId={currentFromId}
                  key={status}
                  status={status}
                  projects={projects}
                  openEditor={openEditor}
                  circle={circle}
                  hideAvatars={hideAvatars}
                  hideSource={hideSource}
                />
              ))}
            </div>
            <div className={archiveAriaClassName(key)}>
              <ProjectsStatusColumn
                hidden={archiveAriaHidden(key)}
                groupKey={key}
                currentDestinationId={currentDestinationId}
                currentFromId={currentFromId}
                status="archived"
                projects={[]}
                openEditor={openEditor}
                circle={circle}
                hideAvatars={hideAvatars}
                hideSource={hideSource}
              />
            </div>
          </section>
        </DragDropContext>
      ))}
      {showEmptySpaceAfterGrid && (
        <div className={styles.emptySpace} />
      )}
    </div>
  )
}

export default ProjectsGrid
