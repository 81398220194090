// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import {
  type ConvertOrganizationToV4Input as Input,
} from './__generated__/ConvertOrganizationToV4Mutation.graphql'

const mutation = graphql`
  mutation ConvertOrganizationToV4Mutation($input: ConvertOrganizationToV4Input!) {
    convertOrganizationToV4(input: $input) {
      organization {
        id
        v4migrationStatus

        ...ConstitutionTab_organization
      }
    }
  }
`

const ConvertOrganizationToV4Mutation = (input: MutationInput<Input>): Promise<mixed> => (
  createMutationPromise({mutation, variables: {input}})
)

export default ConvertOrganizationToV4Mutation
