import * as R from 'ramda'
import getIDGenerator from 'utils/getIDGenerator'
import util from 'utils/MiscUtils'

const generateID = getIDGenerator()

const ActionActions = {
  ACTION_OPTIMISTIC_CREATE(action) {
    const data = action.data
    const localId = generateID()

    const payload = R.merge(data, {
      id: localId,
      isBeingSaved: true,
    })

    this.store.setData('actions', payload)

    return this.apiAdapter.apiPost('actions', data, {localId}).then(
      this.handleWith({
        type: 'ACTION_OPTIMISTIC_CREATE_SUCCESS',
      }),
    )
  },

  ACTION_OPTIMISTIC_CREATE_SUCCESS(action) {
    const responseData = action.response.data
    const serverId = responseData.actions[0].id
    const localId = action.request.options.localId || util('localId required')

    // hack for changing model's id in backbone store
    action.request.id = serverId

    this.store.setData('actions', localId, {
      id: serverId,
      isBeingSaved: false,
    })

    return this.mergeResponseDataIntoStore(action)
  },

  ACTION_OPTIMISTIC_UPDATE(action) {
    const id = action.resourceId || util.error('resourceId required')
    const data = action.data
    const payload = R.merge(data, {isBeingSaved: true})

    this.store.setData('actions', id, payload)

    return this.apiAdapter.apiPatch('actions', id, data).then(
      this.handleWith({
        type: 'ACTION_OPTIMISTIC_UPDATE_SUCCESS',
      }),
    )
  },

  ACTION_OPTIMISTIC_UPDATE_SUCCESS(action) {
    const id = action.request.id || util.error('action must include request.id')

    this.store.setData('actions', id, {isBeingSaved: false})
  },

  ACTION_OPTIMISTIC_DESTROY(action) {
    const id = action.resourceId || util.error('resourceId required')

    this.store.setData('actions', id, null)

    return this.apiAdapter.apiDelete('actions', id)
  },

  ACTION_COMPLETE(action) {
    const actionId = action.actionId || util.error('actionId is required')

    this.store.setData('actions', actionId, {completed_at: Date()})

    return this.apiAdapter.apiPost(`actions/${actionId}/completion`, {}).then(
      this.handleWith({
        type: 'ACTION_COMPLETION_SUCCESS',
        actionId,
      }),
    )
  },

  ACTION_UNCOMPLETE(action) {
    const actionId = action.actionId || util.error('actionId is required')

    this.store.setData('actions', actionId, {completed_at: null})

    return this.apiAdapter.apiDelete('actions', actionId, {action: 'completion'}).then(
      this.handleWith({
        type: 'ACTION_COMPLETION_SUCCESS',
        actionId,
      }),
    )
  },

  ACTION_COMPLETION_SUCCESS(action) {
    const actionId = action.actionId || util.error('actionId is required')
    const completionTime = action.response.data.completion.completed_at

    return this.store.setData('actions', actionId, {completed_at: completionTime})
  },

  ACTIONS_ARCHIVE() {
    return this.apiAdapter.apiDelete('actions', 'archive').then(
      this.handleWith({
        type: 'ACTIONS_ARCHIVE_SUCCESS',
      }),
    )
  },

  ACTIONS_ARCHIVE_SUCCESS(action) {
    const actions = action.response.data.actions
    const actionIds = R.map(R.prop('id'), actions)

    R.forEach((id) => {
      if (this.store.getData('actions', id))
        this.store.setData('actions', id, null)
    }, actionIds)
  },
}

export default ActionActions
