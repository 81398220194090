// @flow
import type {Node} from 'react'
import React, {useCallback, useState, useEffect} from 'react'
import jsonApiFetch from 'utils/jsonApiFetch'
import FrogBotIcon from 'components/ui/icons/FrogBotIcon'

import Thinking from 'components/ai/Thinking'
import styles from './index.scss'

export type AiSuggestProps = {
  promptKey: string,
  setValue?: string => void,
  context: {
    type: string,
    id: string,
  },
  data: Object,
}

export function SuggestionLabel({children}: {children?: Node}): Node {
  return (
    <div className={styles.suggestionLabel}>
      <FrogBotIcon />
      {I18n.t('ai.suggested')}
      {children}
    </div>
  )
}

export function Suggestion({children}: {children: Node}): Node {
  return (
    <div className={styles.suggestion}>
      {children}
    </div>
  )
}

type ButtonProps = {
  onAcceptSuggestion: () => Promise<void>,
  refreshSuggestion: () => void,
  onClose: () => void,
}

export function Buttons({
  onAcceptSuggestion,
  refreshSuggestion,
  onClose,
}: ButtonProps): Node {
  return (
    <div className={styles.buttonContainer}>
      <button
        type="button"
        className={styles.suggestionButton}
        onClick={onAcceptSuggestion}
        aria-label={I18n.t('ai.accept_suggestion')}
        title={I18n.t('ai.accept_suggestion')}
      >
        <span className="mr-1">
          {I18n.t('ai.accept_suggestion')}
        </span>
        <i className="fa fa-check" />
      </button>
      <button
        type="button"
        aria-label={I18n.t('ai.new_suggestion')}
        title={I18n.t('ai.new_suggestion')}
        className={styles.suggestionButton}
        onClick={refreshSuggestion}
      >
        <i className="fa fa-refresh" />
      </button>
      <button
        type="button"
        className={styles.suggestionButton}
        onClick={onClose}
        aria-label={I18n.t('ai.cancel_suggestion')}
        title={I18n.t('ai.cancel_suggestion')}
      >
        <i className="fa fa-times" />
      </button>
    </div>
  )
}

type FetchSuggestionProps = {
  orgDatabaseId: string,
  promptKey: string,
  resuggest: boolean,
  context: {
    type: string,
    id: string,
  },
  data: Object,
  retrieveOnly?: boolean,
}

type FetchSuggestionResponse = {
  suggestion: string,
  ai_message_id: string,
  ai_chat_id: string,
}

export async function fetchAiSuggestion({
  orgDatabaseId,
  promptKey,
  resuggest,
  retrieveOnly,
  context,
  data,
}: FetchSuggestionProps): Promise<FetchSuggestionResponse> {
  const action = retrieveOnly ? 'retrieve' : 'generate'
  const response = await jsonApiFetch(
    'POST',
    `/org/${orgDatabaseId}/ai_suggestions/${action}`,
    {
      prompt_key: promptKey,
      resuggest,
      context,
      data,
    },
  )
  return response
}

type Props = $ReadOnly<{
  onClose: () => void,
  cancelClose: () => void,
  queClose: () => void,
  orgDatabaseId: string,
  promptKey: string,
  context: {
    type: string,
    id: string,
  },
  setValue: string => void,
  data: Object,
}>

function AiSuggestion({
  onClose,
  context,
  cancelClose,
  queClose,
  orgDatabaseId,
  promptKey,
  setValue,
  data,
}: Props): Node {
  const [suggestion, setSuggestion] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const [chatId, setChatId] = useState(null)

  const onAcceptSuggestion = useCallback(async () => {
    if (!messageId || !chatId)
      return
    setValue(suggestion || '')
    setSuggestion('')

    // FIXME: DRY This with the code in ThumbsUp
    const messagesPath = `/org/${orgDatabaseId}/ai_messages`
    const messagePath = `${messagesPath}/${messageId}?ai_chat_id=${chatId}`
    await jsonApiFetch('PATCH', messagePath, {
      id: messageId,
      ai_chat_id: chatId,
      message: {user_rating: 1},
    })
    onClose()
  }, [messageId, chatId, setValue, suggestion, orgDatabaseId, onClose])

  const fetchSuggestion = useCallback((async (resuggest) => {
    setFetching(true)
    const response = await fetchAiSuggestion({
      orgDatabaseId,
      promptKey,
      resuggest,
      context,
      data,
    })
    setFetching(false)
    setSuggestion(response.suggestion)
    setMessageId(response.ai_message_id)
    setChatId(response.ai_chat_id)
  }), [orgDatabaseId, promptKey, context, data])

  const refreshSuggestion = useCallback(() => {
    if (fetching)
      return
    setFetching(true)
    fetchSuggestion(true)
  }, [fetchSuggestion, fetching])

  useEffect(() => {
    if (!suggestion)
      fetchSuggestion(false)
  }, [suggestion, fetchSuggestion])

  if (!suggestion)
    return null

  return (
    <div
          // we want the suggestion to stay open if the user clicks on it
          // (even though it will trigger an onBlur on the input)
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onFocus={cancelClose}
      onBlur={queClose}
      className={styles.suggestionContainer}
    >
      <SuggestionLabel />
      <Suggestion>
        {fetching ? <Thinking /> : suggestion}
      </Suggestion>
      <Buttons
        onAcceptSuggestion={onAcceptSuggestion}
        refreshSuggestion={refreshSuggestion}
        onClose={onClose}
      />
    </div>
  )
}

export default AiSuggestion
