// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  DeleteTacticalOutputInput as Input,
  DeleteTacticalOutputMutationResponse as Response,
} from './__generated__/DeleteTacticalOutputMutation.graphql'

const mutation = graphql`
  mutation DeleteTacticalOutputMutation($input: DeleteTacticalOutputInput!) {
    deleteTacticalOutput(input: $input) {
      deletedOutputId
    }
  }
`

const DeleteTacticalOutputMutation = (
  input: MutationInput<Input>,
  meetingId: string,
): Promise<Response> => {
  const variables = {
    input,
  }
  const configs = [{
    type: 'RANGE_DELETE',
    parentID: meetingId,
    connectionKeys: [{
      key: 'AgendaItemGroups_tacticalOutputs',
    }],
    pathToConnection: ['meeting', 'tacticalOutputs'],
    deletedIDFieldName: 'deletedOutputId',
  }]

  const optimisticResponse = {
    deleteTacticalOutput: {
      deletedOutputId: input.outputId,
    },
  }

  return createMutationPromise({mutation, optimisticResponse, variables, configs})
}

export default DeleteTacticalOutputMutation
