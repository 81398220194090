// @flow
import type {Element} from 'react'
import React from 'react'

type Props = {
  locale: string,
}

function LocaleHiddenInput({locale}: Props): null | Element<"input"> {
  if (!locale)
    return null

  return (
    <input
      id="locale"
      name="locale"
      type="hidden"
      value={locale}
    />
  )
}

export default LocaleHiddenInput
