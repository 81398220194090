// @flow
import type {Node} from 'react'
import React, {useCallback} from 'react'
import {graphql, useFragment} from 'react-relay'

import type {Input as UpdateInput} from 'mutations/UpdateProjectMutation'
import type {Input as DeleteInput} from 'mutations/DeleteProjectMutation'
import type {MutationInput} from 'mutations/createMutationPromise'
import ProjectFormModal from 'components/projects/ProjectFormModal'
import type {FormikProps, SubmitValues, Mode} from 'components/projects/ProjectFormModal'
import type {AllProjectStatuses} from 'components/projects/ProjectEditForm'
import type {Alert} from 'components/types'
import type {
  UpdateProjectModal_project$key as Project,
  UpdateProjectModal_project$key as EditedProject,

} from './__generated__/UpdateProjectModal_project.graphql'

export type {EditedProject}

type InitialValues = $ReadOnly<{
  personId?: string,
}>

type Props<UpdateResponse, DeleteResponse> = $ReadOnly<{
  updateMutation: (MutationInput<UpdateInput>, ?string) => Promise<UpdateResponse>,
  deleteMutation: (MutationInput<DeleteInput>) => Promise<DeleteResponse>,
  initialValues?: InitialValues,
  mode: Mode,
  project: Project,
  close: () => void,
}>

const projectFragment = graphql`
  fragment UpdateProjectModal_project on Project {
    id
    databaseId
    description(format: MARKDOWN)
    rawDescription: description(format: RAW)
    link
    visibility
    value
    effort
    status
    note
    parentGoalOrTarget
    viewerCanAddActions
    viewerCanUpdate
    waitingOnWho
    waitingOnWhat

    accountability {
      id
    }

    circle {
      databaseId
      supportedRole {
        id
      }

      organization {
        isWysiwygEnabled
      }
    }

    person {
      id
    }

    role {
      id
    }
  }
`

function UpdateProjectModal<
  UpdateResponse: ?{+errors: $ReadOnlyArray<Alert>, ...},
  DeleteResponse
>({
  updateMutation,
  deleteMutation,
  initialValues,
  mode,
  project: projectKey,
  close,
}: Props<UpdateResponse, DeleteResponse>): Node {
  const project = useFragment(projectFragment, projectKey)

  const isWysiwygEnabled = project.circle.organization.isWysiwygEnabled

  const initialValuesToUse = {
    accountabilityId: project.accountability?.id,
    description: isWysiwygEnabled
      ? project.description
      : project.rawDescription,
    personId: project.person?.id,
    supportedRoleId: project.circle.supportedRole?.id,
    roleId: project.role.id,
    parentGoalOrTarget: project.parentGoalOrTarget,
    link: project.link,
    visibility: project.visibility,
    value: project.value,
    effort: project.effort,
    status: project.status,
    note: project.note,
    waitingOnWho: project.waitingOnWho,
    waitingOnWhat: project.waitingOnWhat,
    ...initialValues,
  }

  const isDisabled = !project.viewerCanUpdate
  const modalTitle = isDisabled
    ? I18n.t('projects.project_info')
    : I18n.t('projects.edit_project')

  const submit = useCallback((submitValues: SubmitValues) => (
    updateMutation({
      projectId: project.id,
      ...submitValues,
    })
  ), [updateMutation, project])

  const deleteProject = useCallback(async () => {
    /* eslint no-alert: 0 */
    if (!window.confirm(I18n.t('projects.delete_confirmation')))
      return

    await deleteMutation({projectId: project.id})

    close()
  }, [close, deleteMutation, project])

  const statuses: AllProjectStatuses = ['Current', 'Future', 'Waiting', 'Done', 'Archived']

  return (
    <ProjectFormModal
      disabled={isDisabled}
      close={close}
      initialValues={initialValuesToUse}
      submit={submit}
      modalTitle={modalTitle}
      mode={mode}
      statuses={statuses}
      projectId={project.id}
      circleDatabaseId={project.circle.databaseId}
      renderLeftFooterButton={(formikProps: FormikProps) => (
        <button
          className="btn btn-secondary btn-lg"
          disabled={formikProps.isSubmitting}
          onClick={deleteProject}
          type="button"
        >
          {I18n.t('react.components.edit_dialog.delete')}
        </button>
      )}
    />
  )
}

export default UpdateProjectModal
