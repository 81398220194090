// @flow
import type {Node, Element} from 'react'
import React from 'react'
import classNames from 'classnames'
import AppLink from 'components/ui/AppLink'
import IconContainer from 'components/SideBar/ui/IconContainer'
import styles from './index.scss'

type Size = 'xs' | 'sm' | 'md' | 'lg'
type Theme = 'light' | 'dark' | 'darkBlue'

type ElementOnClick = (SyntheticMouseEvent<*>) => void

type Props = $ReadOnly<{
  alt: string,
  onClick: ?ElementOnClick,
  href: ?string,
  target: ?string,
  size: Size,
  theme: Theme,
  children: Node,
  submit: boolean,
  tension: boolean,
}>

function IconButton({
  alt,
  onClick,
  href,
  target,
  size,
  theme,
  children,
  submit,
  tension,
}: Props): null | Element<"button"> | Node {
  const wrappedOnClick = (e: SyntheticMouseEvent<*>) => {
    e.currentTarget.blur()
    if (onClick)
      onClick(e)
  }

  const linkClass = classNames(
    styles.iconButton,
    styles[size],
    {
      [styles.light]: theme === 'light',
      [styles.dark]: theme === 'dark',
      [styles.darkBlue]: theme === 'darkBlue',
      [styles.tensionIconButton]: tension,
      [styles.darkTensionIconButton]: (tension && theme === 'dark'),
    },
  )

  const buttonClass = `btn-reset ${linkClass}`

  const iconSize: 'sm' | 'md' | 'lg' = size === 'xs' || size === 'sm'
    ? 'sm'
    : size

  const renderIcon = () => (
    <IconContainer size={iconSize}>
      {children}
    </IconContainer>
  )

  if (href) {
    return (
      <AppLink
        aria-label={alt}
        title={alt}
        href={href}
        target={target}
        onClick={wrappedOnClick}
        className={linkClass}
      >
        {renderIcon()}
      </AppLink>
    )
  }

  if (onClick) {
    return (
      <button
        aria-label={alt}
        title={alt}
        onClick={onClick}
        className={buttonClass}
        id="sjs-toggle_leftnav"
        type={submit ? 'submit' : 'button'}
      >
        {renderIcon()}
      </button>
    )
  }

  return null
}

IconButton.defaultProps = {
  size: 'lg',
  theme: 'light',
  children: null,
  onClick: null,
  target: null,
  href: null,
  submit: false,
  tension: false,
}

export default IconButton
