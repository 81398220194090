// @flow
import type {Node} from 'react'
import React, {useRef, Fragment} from 'react'
import classNames from 'classnames'

import accessibleOnClickProps from 'utils/MiscUtils/accessibleOnClickProps'
import VisuallyHidden from 'components/ui/VisuallyHidden'

import styles from './index.scss'

type Props = $ReadOnly<{
  'test-id': string | null,
  onClick: ?(e: SyntheticEvent<HTMLButtonElement>) => void,
  onClickText: ?string,
  children: Node,
  additionalSection: Node,
  cssVersion: 'classic' | 'workspace',
}>

/* eslint jsx-a11y/no-static-element-interactions: 0 */
function ListItem({'test-id': testId, children, onClick, onClickText, additionalSection, cssVersion}: Props):
  Node {
  const buttonRef = useRef(null)

  const onContainerClick = onClick
    ? () => buttonRef.current?.click()
    : null

  const containerClass = classNames(styles.container, {
    [styles.clickableContainer]: !!onClick,
    [styles.classicCssVersion]: cssVersion === 'classic',
    [styles.workspaceCssVersion]: cssVersion === 'workspace',
  })

  return (
    <Fragment>
      <div
        className={containerClass}
        test-id={testId}
        {...accessibleOnClickProps(onContainerClick)}
      >
        <VisuallyHidden>
          <button type="button" ref={buttonRef} {...accessibleOnClickProps(onClick)}>
            {onClickText}
          </button>
        </VisuallyHidden>
        {children}
      </div>
      {additionalSection && (
        <div className={styles.additionalSection}>
          {additionalSection}
        </div>
      )}
    </Fragment>
  )
}

ListItem.defaultProps = {
  additionalSection: null,
  'test-id': null,
  onClick: null,
  onClickText: null,
  cssVersion: 'classic',
}

export default ListItem
