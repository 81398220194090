// @flow

import type {Node} from 'react'
import React, {useState} from 'react'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import {graphql, useFragment} from 'react-relay'
import {useFormik, FormikProvider, Form, Field} from 'formik'
import {isEmpty} from 'ramda'

import TryDownloadableReportMutation from 'mutations/TryDownloadableReportMutation'
import PremiumCallout from 'components/ui/PremiumCallout'
import RoleSelector from 'components/roles/RoleSelector'
import Checkbox from 'components/ui/forms/Checkbox'
import styles from './index.scss'

type Props = $ReadOnly<{
  closeModal?: () => void,
  organization: Object,
  roleId?: ?string,
}>

const orgFragment = graphql`
  fragment PrintableGovernanceForm_organization on Organization {
    id
    onRestrictedPlan
    isGoalsEnabled
    viewerIsAdminOrHigher
    isPdfReportGenerationRestricted

    customRoles {
      edges {
        node {
          id
          isDisplayedAsCircle
          name: localizedName

          ...RoleSelector_roles
        }
      }
    }
  }
`

export default function PrintableGovernanceForm({closeModal, organization, roleId}: Props): Node {
  const org = useFragment(orgFragment, organization)
  const roles = liftNodes(org.customRoles)

  const [recursiveDisabled, setRecursiveDisabled] = useState<boolean>(false)

  const includes = () => {
    const baseIncludes = ['role_name', 'purpose', 'accountabilities', 'domains', 'role_fillers', 'policies',
      'strategies', 'custom_sections', 'circle_members', 'projects', 'checklists', 'metrics', 'notes']
    if (org.isGoalsEnabled || org.onRestrictedPlan)
      baseIncludes.push('goals_and_targets')
    return baseIncludes
  }

  const modifiers = ['include_toc', 'enable_links', 'exclude_private_to_circle', 'exclude_private_to_person', 'only_me']

  const formik = useFormik({
    initialValues: {
      roleId,
      accountabilities: true,
      domains: true,
      purpose: true,
      role_fillers: true,
      role_name: true,
    },
    onSubmit: async (values) => {
      const mutationInput = {
        options: values,
        organizationId: organization.id,
        reportName: 'printable_governance',
        roleId: values.roleId,
      }
      const data = await TryDownloadableReportMutation(mutationInput)
      const response = data.tryDownloadableReport

      if (response.asyncRequired)
        /* eslint-disable-next-line no-alert */
        alert(response.message)
      else
        window.open(response.downloadUrl)

      if (closeModal)
        closeModal()
    },
    validate: (values) => {
      /* eslint-disable-next-line prefer-const */
      let errors = {}

      if (!values.roleId)
        errors.roleId = {value: 'required'}

      return errors
    },
  })

  const handleRoleChange = (setFieldValue, e) => {
    if (e.role.isDisplayedAsCircle) {
      setRecursiveDisabled(false)
    } else {
      setFieldValue('recursive', false)
      setRecursiveDisabled(true)
    }
  }

  const toggleIncludes = (setFieldValue, state) => {
    includes().forEach((key) => setFieldValue(key, state))
  }

  const canGenerateBasicReports = !org.isPdfReportGenerationRestricted || org.viewerIsAdminOrHigher
  const canChangeSelections = !org.onRestrictedPlan && canGenerateBasicReports

  return (
    <FormikProvider value={formik}>
      <Form>
        {!canGenerateBasicReports && (
          <div className="alert alert-warning" test-id="printable-gov-form-restricted-to-admins-message">
            {I18n.t('reports.printable_governance.restricted_to_admins')}
          </div>
        )}
        <div className={styles.reportOptions}>
          <fieldset>
            <legend className={styles.legend}>
              {I18n.t('reports.printable_governance.start_from')}
              {':'}
            </legend>
            <Field
              as={RoleSelector}
              containerClassName={styles.roleSelector}
              name="roleId"
              onRoleChange={(e) => handleRoleChange(formik.setFieldValue, e)}
              roles={roles}
              placeholder={I18n.t('reports.printable_governance.placeholder')}
              test-id="role_id"
              useAsValue="id"
              warning={formik.errors.roleId}
              disabled={!canGenerateBasicReports}
            />
            <Field
              as={Checkbox}
              containerClassName={styles.inclusiveContainer}
              disabled={!canChangeSelections || recursiveDisabled}
              label={I18n.t('reports.printable_governance.recursive')}
              name="recursive"
              test-id="recursive"
            />
          </fieldset>
          <hr />
          <fieldset>
            <legend className={styles.legend}>
              {I18n.t('reports.printable_governance.include')}
              {':'}
              {org.onRestrictedPlan && (
                <PremiumCallout
                  callout="printable_governance"
                  enabled
                  darkIcon
                />
              )}
              {canChangeSelections && (
                <span className={styles.bulkSelect}>
                  <button
                    className="btn btn-link"
                    onClick={() => toggleIncludes(formik.setFieldValue, true)}
                    type="button"
                  >
                    {I18n.t('reports.printable_governance.select_all')}
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() => toggleIncludes(formik.setFieldValue, false)}
                    type="button"
                  >
                    {I18n.t('reports.printable_governance.deselect_all')}
                  </button>
                </span>
              )}
            </legend>
            <div className={styles.includesArea}>
              {includes().map((key) => (
                <Field
                  key={`includes--${key}`}
                  as={Checkbox}
                  containerClassName={styles.checkboxContainer}
                  disabled={!canChangeSelections}
                  label={I18n.t(`reports.printable_governance.${key}`)}
                  name={key}
                  test-id={`printable-gov-form-checkbox--${key}`}
                />
              ))}
            </div>
            <br />
            <div className="small text-muted">{I18n.t('reports.printable_governance.output_note')}</div>
          </fieldset>
          <hr />
          <fieldset>
            <legend className={styles.legend}>
              {I18n.t('reports.printable_governance.options')}
              {':'}
            </legend>
            {modifiers.map((key) => (
              <Field
                key={`modifiers--${key}`}
                as={Checkbox}
                containerClassName={styles.checkboxContainer}
                disabled={!canChangeSelections}
                label={I18n.t(`reports.printable_governance.${key}`)}
                name={key}
              />
            ))}
          </fieldset>
        </div>
        <button
          className="btn btn-primary"
          disabled={formik.isSubmitting || !isEmpty(formik.errors) || !canGenerateBasicReports}
          type="submit"
          test-id="printable-gov-form-submit-button"
        >
          {I18n.t('reports.printable_governance.generate')}
        </button>
      </Form>
    </FormikProvider>
  )
}
