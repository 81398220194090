/**
 * @flow
 * @relayHash 2744e8b637880f03426c1b66c84ca205
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompletionCheckbox_action$ref = any;
export type UncompleteActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  actionId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type UncompleteActionMutationVariables = {|
  input: UncompleteActionInput
|};
export type UncompleteActionMutationResponse = {|
  +uncompleteAction: ?{|
    +action: {|
      +$fragmentRefs: CompletionCheckbox_action$ref
    |}
  |}
|};
export type UncompleteActionMutation = {|
  variables: UncompleteActionMutationVariables,
  response: UncompleteActionMutationResponse,
|};
*/


/*
mutation UncompleteActionMutation(
  $input: UncompleteActionInput!
) {
  uncompleteAction(input: $input) {
    action {
      ...CompletionCheckbox_action
      id
    }
  }
}

fragment CompletionCheckbox_action on Action {
  id
  completedAt
  actionType
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UncompleteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UncompleteActionPayload",
        "kind": "LinkedField",
        "name": "uncompleteAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompletionCheckbox_action"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UncompleteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UncompleteActionPayload",
        "kind": "LinkedField",
        "name": "uncompleteAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actionType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2744e8b637880f03426c1b66c84ca205",
    "metadata": {},
    "name": "UncompleteActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd08f5f92c3377b65b7fbbc4cf988a77';

module.exports = node;
