// @flow
import React, {type Node, useMemo, Fragment} from 'react'
import {type RelayFragmentContainer, createFragmentContainer, graphql} from 'react-relay'
import {UncontrolledTooltip} from 'reactstrap'

import liftNodes from 'utils/GraphQL/typedLiftNodes'
import constants from 'utils/projects/constants'
import useUniqueId from 'utils/hooks/useUniqueId'
import {type FakeEvent} from 'utils/forms/fakeInputEvent'

import Select from 'components/ui/forms/Select'
import type {
  RoleAccountabilitySelector_role as Role,
} from './__generated__/RoleAccountabilitySelector_role.graphql'

type Props = $ReadOnly<{
  role: Role,
  disabled: boolean,
  label: string,
  value: string | null,
  initialValue: string | null,
  onChange: FakeEvent => void,
  warning: string | null,
  clearable: boolean,
  placeholder: string,
  name: string,
  'test-id': ?string,
}>

const notSpecifiedAccountability = () => ({
  label: I18n.t('projects.my_projects.not_specified'),
  value: constants.notSpecifiedId.toString(),
})

function RoleAccountabilitySelector({role, ...selectProps}: Props): Node {
  const [id] = useUniqueId()

  const options = useMemo(() => {
    const opts = liftNodes(role.accountabilities)
      .map(({id: value, description: label}) => ({value, label}))

    return role.purpose
      ? [notSpecifiedAccountability(), ...opts]
      : opts
  }, [role])

  return (
    <Fragment>
      <div id={id}>
        <Select
          {...selectProps}
          disabled={role.isLinked}
          options={options}
        />
      </div>
      {role.isLinked && (
        <UncontrolledTooltip
          delay={{show: 0, hide: 0}}
          modifiers={[{name: 'preventOverflow', boundary: 'window'}]}
          placement="bottom"
          target={id}
        >
          {I18n.t('org_nav.projects.disabled_accountabilities')}
        </UncontrolledTooltip>
      )}
    </Fragment>
  )
}

RoleAccountabilitySelector.defaultProps = {
  disabled: false,
  clearable: false,
  'test-id': null,
}

export default (createFragmentContainer(RoleAccountabilitySelector, {
  role: graphql`
    fragment RoleAccountabilitySelector_role on Role {
      isLinked
      purpose
      accountabilities {
        edges {
          node {
            id
            description(format: PLAIN)
          }
        }
      }
    }
  `,
}): RelayFragmentContainer<typeof(RoleAccountabilitySelector)>)
