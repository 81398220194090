/**
 * @flow
 * @relayHash 6995720b1361cc5f088468b50a88d040
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MeetingBanners_organization$ref = any;
export type AsyncBannersViewQueryVariables = {|
  orgDatabaseId: string
|};
export type AsyncBannersViewQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: MeetingBanners_organization$ref
  |}
|};
export type AsyncBannersViewQuery = {|
  variables: AsyncBannersViewQueryVariables,
  response: AsyncBannersViewQueryResponse,
|};
*/


/*
query AsyncBannersViewQuery(
  $orgDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...MeetingBanners_organization
    id
  }
}

fragment GovernanceMeetingBanner_circle on Circle {
  localizedName
  ...MeetingBanner_circle
  activeGovernanceMeeting {
    ...MeetingBanner_meeting
    id
  }
}

fragment MeetingBanner_circle on Circle {
  databaseId
}

fragment MeetingBanner_meeting on Meeting {
  __isMeeting: __typename
  id
  joinMeetingPath
  type
}

fragment MeetingBanners_organization on Organization {
  databaseId
  viewer {
    circles(canAttendMeetings: true) {
      edges {
        node {
          id
          ...GovernanceMeetingBanner_circle
          ...TacticalMeetingBanner_circle
        }
      }
    }
    id
  }
}

fragment TacticalMeetingBanner_circle on Circle {
  localizedName
  viewerIsMember
  activeTacticalMeeting {
    checkinPath
    checklistsPath
    checkoutPath
    metricsPath
    projectsPath
    triagePath
    typeLabel
    ...MeetingBanner_meeting
    id
  }
  ...MeetingBanner_circle
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "joinMeetingPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Meeting",
  "abstractKey": "__isMeeting"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AsyncBannersViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MeetingBanners_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AsyncBannersViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "canAttendMeetings",
                    "value": true
                  }
                ],
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GovernanceMeeting",
                            "kind": "LinkedField",
                            "name": "activeGovernanceMeeting",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerIsMember",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TacticalMeeting",
                            "kind": "LinkedField",
                            "name": "activeTacticalMeeting",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "checkinPath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "checklistsPath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "checkoutPath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "metricsPath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectsPath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "triagePath",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "typeLabel",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "circles(canAttendMeetings:true)"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "6995720b1361cc5f088468b50a88d040",
    "metadata": {},
    "name": "AsyncBannersViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfe27fe7b509f76510f5ec3da4032df5';

module.exports = node;
