import $ from 'jquery'
import _ from 'underscore'

function triggerChange(e) {
  $(e.target).trigger('change')
}

_(gf).extend({
  setupAutocomplete() {
    $('[data-gf-autocomplete]').each(function () {
      $(this).autocomplete({
        source: $(this).data('gf-autocomplete'),
        appendTo: 'body',
        change: triggerChange,
        select: triggerChange,
      })
    })
    $('[data-gf-triggered-autocomplete]').each(function () {
      $(this).triggeredAutocomplete({
        source: $(this).data('gf-triggered-autocomplete'),
        trigger: '@',
        appendTo: 'body',
        change: triggerChange,
        select: triggerChange,
      })
    })
    $('[data-gf-simple-triggered-autocomplete]').each(function () {
      $(this).triggeredAutocomplete({
        source: $(this).data('gf-simple-triggered-autocomplete'),
        trigger: '@',
        appendTo: 'body',
        change: triggerChange,
      })
    })
  },
})

$(document).ready(gf.setupAutocomplete)
  .on('dialogopen', gf.setupAutocomplete)
  .on('ajax:success', gf.setupAutocomplete)
