// @flow
import type {Node} from 'react'
import React from 'react'
import InternalSource from 'components/ui/icons/fa/InternalSource'
import ExternalSource from 'components/ui/icons/fa/ExternalSource'

export type Source = 'internal' | 'external'

type Props = $ReadOnly<{
  source: Source,
  className: string,
}>

function SourceIcon({source, className}: Props): Node {
  if (source === 'internal')
    return <InternalSource className={className} />

  return <ExternalSource className={className} />
}

export default SourceIcon
