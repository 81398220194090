// @flow
import type {Node} from 'react'
import React, {Fragment, useEffect} from 'react'
import {LocalQueryRenderer, graphql} from 'react-relay'
import flash from 'utils/MiscUtils/flash'

import environment from 'environment'
import CollapsibleContainer from './CollapsibleContainer'
import FlashNotification from './FlashNotification'
import type {
  FlashNotificationsQueryVariables as Variables,
  FlashNotificationsQueryResponse as Response,
} from './__generated__/FlashNotificationsQuery.graphql'

const variables: Variables = Object.freeze({})

type RendererProps = $ReadOnly<{
  // eslint-disable-next-line react/no-unused-prop-types
  props: ?Response,
  ...
}>

const query = graphql`
  query FlashNotificationsQuery {
    ... on Query { __typename }

    localNotifications {
      id
      placement
      ...FlashNotification_notification
    }
  }
`

function FlashNotifications(): Node {
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const serverSideNotifications = window.__serverSideNotifications || []

    serverSideNotifications.forEach(({alert, message}) => {
      if (alert)
        flash.danger(message)
      else
        flash.notice(message)
    })
  }, [])

  return (
    <LocalQueryRenderer
      environment={environment}
      query={query}
      render={({props: rendererProps}: RendererProps) => {
        const localNotifications = rendererProps?.localNotifications || []
        const notifications = (placement) => localNotifications.filter(Boolean)
          .filter((notification) => notification.placement === placement)

        const placements = ['top', 'bottom']

        const renderNotifications = (placement) => (
          <CollapsibleContainer placement={placement}>
            {notifications(placement.toUpperCase()).map((notification) => (
              <FlashNotification
                key={notification.id}
                notification={notification}
              />
            ))}
          </CollapsibleContainer>
        )

        return (
          <Fragment>
            {placements.map((placement) => renderNotifications(placement))}
          </Fragment>
        )
      }}
      variables={variables}
    />
  )
}

export default FlashNotifications
