// @flow
import {graphql} from 'react-relay'

import util from 'utils/MiscUtils'
import createMutationPromise, {type MutationInput} from './createMutationPromise'
import type {
  UpdateChecklistItemMutationResponse as Response,
  UpdateChecklistItemInput as Input,
} from './__generated__/UpdateChecklistItemMutation.graphql'

export type {
  Response,
}

const mutation = graphql`
  mutation UpdateChecklistItemMutation(
    $input: UpdateChecklistItemInput!
  ) {
    updateChecklistItem(input: $input) {
      checklistItem {
        appliesTo

        role {
          localizedName
        }
        
        ...ChecklistItem_checklistItem
      }
    }
  }
`

export default (input: MutationInput<Input>): Promise<Response> => {
  const variables = {input}

  return createMutationPromise({
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors)
        util.warn('there was a problem', errors)
    },
  })
}
