// @flow
import React, {type Node} from 'react'

type Props = {
  children: Node,
  href: string,
  frame: string,
  src?: string,
}

function HotwireLink({src, href, frame, children}: Props): Node {
  return (
    <turbo-frame id="goals-and-targets" src={src || ''} complete="">
      <a data-turbo-frame={frame} href={href}>{children}</a>
    </turbo-frame>
  )
}

export default HotwireLink
