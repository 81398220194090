/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectEditForm_viewer$ref = any;
type UpdateTensionInlineBox_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectFormModalView_viewer$ref: FragmentReference;
declare export opaque type ProjectFormModalView_viewer$fragmentType: ProjectFormModalView_viewer$ref;
export type ProjectFormModalView_viewer = {|
  +$fragmentRefs: ProjectEditForm_viewer$ref & UpdateTensionInlineBox_viewer$ref,
  +$refType: ProjectFormModalView_viewer$ref,
|};
export type ProjectFormModalView_viewer$data = ProjectFormModalView_viewer;
export type ProjectFormModalView_viewer$key = {
  +$data?: ProjectFormModalView_viewer$data,
  +$fragmentRefs: ProjectFormModalView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFormModalView_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectEditForm_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateTensionInlineBox_viewer"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5fa272f1f0163319a5e3969561198313';

module.exports = node;
