/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OutputProjectStatus_output$ref: FragmentReference;
declare export opaque type OutputProjectStatus_output$fragmentType: OutputProjectStatus_output$ref;
export type OutputProjectStatus_output = {|
  +projectStatus: ?string,
  +$refType: OutputProjectStatus_output$ref,
|};
export type OutputProjectStatus_output$data = OutputProjectStatus_output;
export type OutputProjectStatus_output$key = {
  +$data?: OutputProjectStatus_output$data,
  +$fragmentRefs: OutputProjectStatus_output$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputProjectStatus_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStatus",
      "storageKey": null
    }
  ],
  "type": "TacticalOutput",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '26b1b08d7eb52a0ccaca3161c4c57fb9';

module.exports = node;
