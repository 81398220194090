// @flow
import type {Node} from 'react'
import React from 'react'
import {useFragment, graphql} from 'react-relay'

import ChecklistItem from 'components/checklistItems/ChecklistItem'
import MetricsSection from 'components/OrgNav/OrgNavApp/RoleContainer/Sections/ui/MetricsSection'
import NoItemsMessage from 'components/ui/NoItemsMessage'

import type {Source} from 'components/ui/SourceIcon'
import ReorderChecklistItemMutation from 'mutations/ReorderChecklistItemMutation'
import type {
  ChecklistsItemsSection_checklistItems$key as ChecklistItemsKey,
} from './__generated__/ChecklistsItemsSection_checklistItems.graphql'
import type {
  ChecklistsItemsSection_circle$key as CircleKey,
} from './__generated__/ChecklistsItemsSection_circle.graphql'

type Props = $ReadOnly<{
  circle: CircleKey,
  checklistItems: ChecklistItemsKey,
  showNoItemsMessage: boolean,
  source: Source,
}>

const checklistItemsFragment = graphql`
  fragment ChecklistsItemsSection_checklistItems on ChecklistItem @relay(plural: true) {
    databaseId
    link
    description
    frequency
    localizedFrequency
    id
    localizedRoleName
    appliesToAllMembers
    isGlobal
    privateToCircle
    position
    viewerCanReorder

    organization {
      id
      onRestrictedPlan
    }

    customFrequency {
      id
      name
      universalUnits
    }

    people(first: 30) {
      edges {
        node {
          name
        }
      }
    }

    ...ChecklistItem_item
  }
`

const circleFragment = graphql`
  fragment ChecklistsItemsSection_circle on Circle {
    ...ChecklistItem_circle
  }
`

function ChecklistsItemsSection({
  circle: circleKey,
  checklistItems: checklistItemsKey,
  showNoItemsMessage,
  source,
}: Props): Node {
  const checklistItems = useFragment(checklistItemsFragment, checklistItemsKey)
  const circle = useFragment(circleFragment, circleKey)

  if (showNoItemsMessage && checklistItems.length === 0)
    return <NoItemsMessage message={I18n.t('metrics.no_checklist_items')} />

  const onReorder = (checklistItemId, newPosition, optimisticList) => (
    ReorderChecklistItemMutation({checklistItemId, newPosition}, optimisticList)
  )

  return (
    <MetricsSection
      hideIfEmpty
      items={checklistItems}
      reorderable={source === 'internal'}
      onReorder={onReorder}
      renderItem={(item) => (
        <ChecklistItem
          item={item}
          key={item.id}
          circle={circle}
          source={source}
        />
      )}
    />
  )
}

ChecklistsItemsSection.defaultProps = {
  source: 'internal',
}

export default ChecklistsItemsSection
