// @flow
import type {Node} from 'react'
import React, {Suspense} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'

import environment from 'environment'
import Preload from 'components/ui/Preload'

import ProfileGoals from './ProfileGoals'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

function GoalsTab({orgDatabaseId, personDatabaseId}: Props): Node {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<Preload />}>
        <ProfileGoals
          orgDatabaseId={orgDatabaseId}
          personDatabaseId={personDatabaseId}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default GoalsTab
