// @flow
import {requestSubscription as requestSubscriptionRelay} from 'react-relay'
import type {GraphQLTaggedNode, RecordSourceSelectorProxy} from 'relay-runtime'

import environment from 'environment'
import registerSubscriptions from 'subscriptions/registerSubscriptions'
import flash from 'utils/MiscUtils/flash'
import logger from 'utils/MiscUtils/main'

type Subscriptions = {
  [string]: {
    onNextCallbacks: Array<?(any) => void>,
    updaters: Array<?(RecordSourceSelectorProxy) => void>,
  },
  ...
}
const subscriptions: Subscriptions = {}

function getSubscriptionKey<Variables: {...}>(
  // TODO: replace any with GraphQLTaggedNode when it will get fixed by Flow or Relay
  subscription: any,
  variables: Variables,
) {
  // GraphQLTaggedNode type provided by relay does not contain `hash` key, which in fact is present in an object
  // Flow isn't correctly processing the GraphQLTaggedNode type, when trying to union it with a hash
  // After any relay upgrade GraphQLTaggedNode may change, so be careful
  return `${subscription.hash || ''}-${JSON.stringify(variables)}`
}

function getSubscriptionArray<Variables: {...}>(
  // TODO: replace any with GraphQLTaggedNode when it will get fixed by Flow or Relay
  subscription: any,
  variables: Variables,
): [Object] {
  // Relay does contain the `id` and `text` keys in RequestParameterstype of ConcreteRequest of GraphQLTaggedNode type
  // But Flow isn't correctly processing this complex type
  // After any relay upgrade GraphQLTaggedNode may change, so be careful
  const {id, text: query} = subscription.params

  return [{
    ...variables,
    id,
    query,
    variables,
  }]
}

function subscribe<Variables: {...}>(
  subscription: GraphQLTaggedNode,
  variables: Variables,
  subscriptionKey: string,
) {
  const subscriptionArray: [Object] = getSubscriptionArray(subscription, variables)
  registerSubscriptions(environment, subscriptionArray)
    .then(() => {
      requestSubscriptionRelay(
        environment,
        {
          subscription,
          variables,
          onCompleted: () => { /* server closed the subscription */ },
          onError: (error) => {
            flash.danger(I18n.t('errors.messages.problem_setting_up_subscription'))
            logger.warn('requestSubscription error:', error)
          },
          onNext: (data) => {
            subscriptions[subscriptionKey].onNextCallbacks.forEach((callback) => {
              if (callback)
                callback(data)
            })
          },
          updater: (store) => {
            subscriptions[subscriptionKey].updaters.forEach((subscriptionUpdater) => {
              if (subscriptionUpdater)
                subscriptionUpdater(store)
            })
          },
        },
      )
    })
    .catch((error) => {
      flash.danger(I18n.t('errors.messages.problem_setting_up_subscription'))
      logger.warn('registerSubscriptions error:', error)
    })
}

export default function requestSubscription<Variables: {...}, Response>(
  subscription: GraphQLTaggedNode,
  variables: Variables,
  onNext: ?(Response) => void = null,
  updater: ?(RecordSourceSelectorProxy) => void = null,
): (() => void) {
  const subscriptionKey = getSubscriptionKey(subscription, variables)
  if (subscriptions[subscriptionKey]) {
    subscriptions[subscriptionKey].onNextCallbacks.push(onNext)
    subscriptions[subscriptionKey].updaters.push(updater)
  } else {
    subscriptions[subscriptionKey] = {
      onNextCallbacks: [onNext],
      updaters: [updater],
    }
    subscribe(subscription, variables, subscriptionKey)
  }

  return () => {
    const onNextIndex = subscriptions[subscriptionKey].onNextCallbacks
      .findIndex((callback) => callback === onNext)

    if (onNextIndex !== -1)
      subscriptions[subscriptionKey].onNextCallbacks.splice(onNextIndex, 1)
  }
}
