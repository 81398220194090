import classNames from 'classnames'

import {walk, walkAncestors} from './tree'

function nodeId(d) {
  const type = d.type === 'circle' ? 'role' : d.type
  return `${type}-${d.id}`
}

const classesForNode = (d) => classNames(
  nodeId(d),
  d.type,
  {
    parent: d.children,
    child: !d.children,
    filled: d.type === 'role' && d.filled,
    unfilled: d.type === 'role' && !d.filled,
    'core-role': d.core_role,
  },
)

function comparator(a, b) {
  if (a.type === 'label')
    return 1
  if (b.type === 'label')
    return -1
  if (a.type === 'circle' && b.type === 'role')
    return 1
  if (b.type === 'circle' && a.type === 'role')
    return -1

  return 0
}

function visuallyHidden(node) {
  return gf.app.orgOnV5() && !!node.core_role
}

const filterNodelist = ({nodes, node, zoomFactor, viewportDepth, minCircleSize, neighborDepth}) => {
  const includeIds = new Set()
  const addId = (d) => { includeIds.add(nodeId(d)) }
  const maxDepth = viewportDepth + 2
  const addSubtreeNode = (currentNode, parent, currentDepth) => {
    // since we need circle labels from one level deeper than the
    // circles/roles themselves, we'll traverse to viewPortDepth + 1
    // and filter out non labels at the last level
    const isLabel = currentNode.type === 'label'
    const radiusIsSmall = zoomFactor * currentNode.r < minCircleSize
    const almostMaxDepth = (maxDepth - currentDepth) === 1
    const shouldIncludeNonLabel = !radiusIsSmall && !almostMaxDepth

    if (isLabel || shouldIncludeNonLabel)
      addId(currentNode)
  }

  // from the node, get the entire subtree do viewportDepth
  walk(node, addSubtreeNode, null, maxDepth)

  if (neighborDepth > 0 && node.parent)
    walk(node.parent, addId, null, neighborDepth)

  walkAncestors(node, addId)

  return nodes.filter((d) => includeIds.has(nodeId(d)))
}

export {
  classesForNode,
  comparator,
  filterNodelist,
  nodeId,
  visuallyHidden,
}
